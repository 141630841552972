import { useEffect, useState } from "react";
import "./Dashboard.css";
import RoomCard from "../../components/RoomCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";

import {
  getRoomsAction,
  setCurrentViewMode,
  setRoomOnRestartIce,
} from "../../actions/roomActions";
import { getEnvironmentsAction } from "../../actions/environmentActions";
import { getSupervisorActions } from "../../actions/supervisorActions";
import { setTroubleshootAction } from "../../actions/troubleshootActions";
import AddRoomCard from "../../components/AddRoomCard";
import useInterval from "../../hooks/useInterval";
import Header from "./../../components/Header";
import ModalComponent from "./../../components/Modal";
import { CLOSE_MODAL, OPEN_MODAL } from "../../actions/actionTypes";
import { Modal } from "react-bootstrap";
import Switch from "react-switch";
import { getCameraBackground } from "../../actions/userActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllUsersCountByRoom } from "../../services/rooms";
import { checkForSupManAdmin } from "../../utils/checkForSupAdmin";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setActivityModalOpen,
  setActivityParentId,
} from "../../actions/activityChannelAction";
import { setViaAppFalse } from "../../actions/loginActions";
import { getPendingVerificationAction } from "../../actions/faceVerificationAction";
import { getToken } from "../../services/user";
import {
  getAudioPermission,
  getVideoPermission,
} from "../../utils/AudioVideoDeviceUtils";
import { getUsersLocation } from "../../utils/LocationUtils";
import axios from "axios";
import { toast } from "react-toastify";
import { getIPAddressStatus } from "../../services/adminSettings";

const colors = [
  { bg: "#E0F4F4", color: "#479797" },
  { bg: "#FFE8F9", color: "#C9408D" },
  { bg: "#FFF3F1", color: "#FF3726" },
  { bg: "#EEEBFF", color: "#0E2549" },
];

interface IRoomListProps {
  filteredRooms: any[];
  roomLength: number;
}
interface supervisorInterface {
  _id: string;
  username: string;
  role: string;
  team: string;
  grouprooms: string;
}

var url_string = window.location.href;
var url = new URL(url_string);
var redir = url.searchParams.get("redirectFrom");

if(redir) {
  try {
    getAudioPermission();
    getVideoPermission();
  } catch (e) {
    toast("Please give Audio and Video permissions.");
  }
}

const RoomList = (props: IRoomListProps) => {
  const { filteredRooms } = props;
  const [allUserCount, setAllUserCount] = useState<any>({});
  const dispatch = useDispatch();
  const currentUserRole = useSelector(
    (state: RootState) => state.userDetails.role
  );
  const viaapp = useSelector((state: RootState) => state.login.viaApp);

  const getAllUsersCount = async () => {
    let val = await getAllUsersCountByRoom();
    if (val.success) {
      setAllUserCount(val.allUsersByRoom);
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (viaapp && filteredRooms.length) {
      dispatch(
        setCurrentViewMode(filteredRooms[0].roomtype, filteredRooms[0].roomname)
      );
      dispatch(setViaAppFalse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRooms, viaapp]);

  useEffect(() => {
    if (checkForSupManAdmin(currentUserRole, "equalTo")) {
      getAllUsersCount();
    }
  }, [currentUserRole]);

  if (filteredRooms && filteredRooms.length) {
    return (
      <ul>
        {filteredRooms.map((room: any, index: number) => {
          const colorIndex = index % colors.length;
          return (
            <RoomCard
              key={index}
              name={room.roomname}
              members={room.userDetails}
              background={colors[colorIndex].bg}
              colors={colors[colorIndex].color}
              creator={room.creator}
              duration={room.duration}
              tempRoom={room.tempRoom}
              roomLength={props.roomLength}
              image={room.imageName}
              viewMode={room.roomtype}
              attendanceDetail={room.attendanceDetail}
              userCount={
                allUserCount[room.roomname] ? allUserCount[room.roomname] : 0
              }
              screenScale={room.screenScale}
              videoScale={room.videoScale}
              curentRoomViewMode={filteredRooms[0].roomtype}
              audioAllowedInRoom={room.audioAllowedInRoom}
              allowPrivateCallinSilentRoom={room.allowPrivateCallinSilentRoom}
            />
          );
        })}
        <AddRoomCard />
      </ul>
    );
  } else {
    return <AddRoomCard />;
  }
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rooms = useSelector((state: RootState) => state.roomsData.rooms);

  const allSupervisors = useSelector(
    (state: RootState) => state.supervisorForManager.allSupervisor
  );
  const troubleshoot = useSelector(
    (state: RootState) => state.troubleshootReducer.troubleshoot
  );
  const roomLeaveName = useSelector(
    (state: RootState) => state.roomsData.roomLeaveName
  );
  const environments = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  );
  const userRole = useSelector((state: RootState) => state.userDetails.role);
  const passwordUpdatedOn = useSelector(
    (state: RootState) => state.userDetails.passwordUpdatedOn
  );
  const isComplexPassword = useSelector(
    (state: RootState) => state.userDetails.isComplexPassword
  );
  const complexPasswordEnteredOnLogin = useSelector(
    (state: RootState) => state.login.isComplexPassword
  );
  const username = useSelector(
    (state: RootState) => state.userDetails.userName
  );

  const faceVerification = useSelector(
    (state: RootState) => state.userDetails.faceVerification
  );
  const isUserRegistered = useSelector(
    (state: RootState) => state.userDetails.isUserRegistered
  );
  const [searchText, setSearchText] = useState("");
  const [filteredRooms, setFilteredRooms] = useState<any>([]);

  const handleSearchRooms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const [searchParams] = useSearchParams();

  let redirectFrom = searchParams.get('redirectFrom');

  useInterval(() => {
    dispatch(getRoomsAction());
    dispatch(getPendingVerificationAction());
  }, 5000);

  useEffect(() => {
    if (faceVerification && !isUserRegistered && userRole === "Agent") {
      navigate("/face_registration");
    }
  }, [faceVerification, isUserRegistered, navigate, userRole]);

  useEffect(() => {
    dispatch(getEnvironmentsAction());
    dispatch(getRoomsAction());
    dispatch(getPendingVerificationAction());
    dispatch(getSupervisorActions());
    dispatch(setActivityParentId("dashboard_container"));
  }, [dispatch]);


  // const getPermissions = async () => {
  //   await getAudioPermission();
  //   await getVideoPermission();
  // }
  const fetchToken = async () => {
    const IPstatusData = await getIPAddressStatus();
    let geoResp = await axios(
      "https://ipgeolocation.abstractapi.com/v1/?api_key=a1bc4a8fc5c54801b0849e4a77fe84a1",
      {
        timeout: 2000,
        method: "GET",
      }
    );

    let geoLocationDetails:any = {
      latitude: "",
      longitude: "",
      ip: geoResp.data ? geoResp.data.ip_address  : "",
      ipdata: geoResp.data ? {data: geoResp.data} : null,
      fromApi: false
    }
    try {
      let geo:any = await getUsersLocation();
      geoLocationDetails = {
        latitude: geo.coords.latitude,
        longitude: geo.coords.longitude,
        ip: IPstatusData.adminsettings.status && geoResp.data ? geoResp.data.ip_address  : "",
        ipdata: IPstatusData.adminsettings.status && geoResp.data ? {data: geoResp.data} : null,
        fromApi: false
      }

    } catch (e) {

      if( geoResp.data) {
      geoLocationDetails = {
        latitude: geoResp.data.latitude,
        longitude: geoResp.data.longitude,
        ip: IPstatusData.adminsettings.status && geoResp.data.ip_address,
        ipdata: IPstatusData.adminsettings.status && geoResp.data ? {data: geoResp.data} : null,
        fromApi: true
      }
    }
    }
    let tokenResponse = await getToken(geoLocationDetails);
      console.log(tokenResponse);
      if(tokenResponse.status && tokenResponse.token) {
        localStorage.setItem("token", tokenResponse.token);
      }
  }

  if (
    environments.REACT_APP_COMPLEX_PASSWORD &&
    environments.REACT_APP_COMPLEX_PASSWORD.status
  ) {
    let today: any = new Date();
    let pwdDate: any = new Date(passwordUpdatedOn);
    const diffTime = Math.abs(today - pwdDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (
      (environments.REACT_APP_COMPLEX_PASSWORD.value !== 0 &&
        diffDays > environments.REACT_APP_COMPLEX_PASSWORD.value) ||
      isComplexPassword === false ||
      complexPasswordEnteredOnLogin === false
    ) {
      navigate("/change_password?expires=true");
    }
  }

  useEffect(() => {
    if (username) {
      dispatch(getCameraBackground(username));
    }
  }, [dispatch, username]);

  useEffect(() => {
    if (rooms && rooms.length > 0) {
      const filteredRooms = rooms.filter((room: any) => {
        const roomName = room.roomname.toLowerCase();
        const searchTextLowerCased = searchText.toLowerCase();
        return roomName.startsWith(searchTextLowerCased);
      });
      setFilteredRooms(filteredRooms);
    }
  }, [searchText, rooms]);

  useEffect(() => {
    if(redirectFrom && redirectFrom==="okta") {
      fetchToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const openForgetPasswordModal = () => {
    dispatch({
      type: OPEN_MODAL,
    });
  };

  const handleModalCloseSupervisor = () => {
    dispatch({
      type: CLOSE_MODAL,
    });
  };

  const handleChange = (checked: boolean) => {
    dispatch(setTroubleshootAction(checked));
  };

  const handleModalClose = () => {
    dispatch(setRoomOnRestartIce(""));
  };

  const activityFeedAction = () => {
    dispatch(setActivityModalOpen(true));
    dispatch(setActivityParentId("dashboard_container"));
  };

  return (
    <>
      <div className="container-flex" id="dashboard_container">
        <div className="header-container">
          <Header />
        </div>
        <div className="team_con scroll">
          <div className="search">
            <input
              value={searchText}
              type="search"
              name="search-rooms"
              placeholder="Search Team/Room"
              onChange={handleSearchRooms}
            />
            {userRole === "Manager" ? (
              <button
                onClick={openForgetPasswordModal}
                className="btn-link-a button_supervisor"
              >
                Show All Supervisors
              </button>
            ) : null}{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="link mt-3"
              onClick={() => activityFeedAction()}
            >
              <FontAwesomeIcon icon={faWindowMaximize} /> &nbsp; Open Activity
              Feed
            </button>
            <label style={{ float: "right" }}>
              <span
                className={`toggle-label ${troubleshoot && "toggle-enabled"}`}
              >
                Connection TroubleShoot {troubleshoot ? "Enabled" : "Disabled"}
              </span>
              <Switch
                onChange={handleChange}
                checked={troubleshoot}
                className="react-switch"
              />
            </label>
          </div>
          <RoomList filteredRooms={filteredRooms} roomLength={rooms.length} />
        </div>
      </div>

      <ModalComponent>
        <Modal.Header
          className="input-modal-popup-header"
          style={{ position: "relative" }}
        >
          <button
            className="modal-close-btn"
            onClick={handleModalCloseSupervisor}
          >
            X
          </button>
          <Modal.Title>All supervisor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_body_supervisor">
            {allSupervisors.length > 0 ? (
              <>
                <div
                  className="all_supervisor_modal"
                  style={{
                    marginBottom: "20px",
                    borderBottom: "1px solid lightgrey",
                  }}
                >
                  <div>
                    <h5>Username</h5>
                  </div>
                  <div>
                    <h5>Room</h5>
                  </div>
                  <div>
                    <h5>Group Room</h5>
                  </div>
                </div>
                {allSupervisors.map((value: supervisorInterface) => (
                  <div key={value._id} className="all_supervisor_modal">
                    <div>
                      <h6>{value.username}</h6>
                    </div>
                    <div>
                      <h6>{value.team}</h6>
                    </div>
                    <div>
                      <h6>{value.grouprooms}</h6>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div>
                <h2>There is no supervisor</h2>
              </div>
            )}
          </div>
        </Modal.Body>
      </ModalComponent>

      {roomLeaveName !== "" ? (
        <Modal
          show={roomLeaveName !== "" ? true : false}
          onHide={handleModalClose}
          // className="feedback_modal_container"
          style={{ border: "0px solid black", width: "40%", margin: "auto" }}
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "rgb(38, 163, 219)",
              margin: "0",
              padding: "6px",
            }}
          >
            <Modal.Title
              style={{ color: "black", fontSize: "1.1rem", fontWeight: "bold" }}
            >
              Network Disconnected
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="feedback_modal_main">
              <div className="message_export_data">
                <div className="filters-container">
                  <h5>Network Disconnected Please Enter room again</h5>
                </div>
              </div>

              <div
                className="mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <button
                  className="link"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleModalClose();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}

      {/* {openActivityFeedModel && <ActivityChannel openActivityFeedModel modalStateChanger={setOpenActivityFeedModel} parentId="dashboard_container" />} */}
    </>
  );
};

export default Dashboard;
