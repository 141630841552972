import { SET_TROUBLESHOOT, CLEAR_REDUX_DATA } from "../actions/actionTypes";

const initialState = {
  troubleshoot: false,
};
interface IAction {
  type: string;
  payload: any;
}

const troubleshootReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_TROUBLESHOOT: {
      return {
        ...state,
        troubleshoot: action.payload.troubleshoot,
      };
    }
    case CLEAR_REDUX_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default troubleshootReducer;
