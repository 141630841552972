import NameAvatar from "./../../components/NameAvatar";
import Video from "./../../components/Video";
import ParticipantCard from "./../../components/ParticipantCard";
import Audio from "./../../components/Audio";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import RemotePeer from "./RemotePeer";
import { useEffect, useState } from "react";
import AudioVolumeMeter from "../../components/AudioVolumeMeter";

// @ts-ignore
const StudentScreen = (props: any) => {
  const {
    largeVideo,
    smallVideo,
    secondScreen,
    instructor,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    presenter,
    localVideoPaused,
    localVideoStream,
    localAudioPaused,
    pauseUnPauseVideo,
    socketId,
    swapScreen,
    pauseUnPauseAudio,
    shareUnshareScreen,
    shareUnshareScreenTwo,
    localScreenStream,
    localScreenTwoStream,
    screenStopped,
    screenTwoStopped,
    remotePeers,
    localAudioStream,
    signalStrength,
    amISuperVisor,
    roomname,
    sideBarOpen,
    addStreamOfAudioFile,
    addStreamOfVideoFile,
    audioAllowedInRoom,
  } = props;

  const [screenChanged, setScreenChanged] = useState(0);
  const handleSwapScreen = (Id: string) => {
    if (screenChanged === 0) setScreenChanged(1);
    else if (screenChanged === 1) setScreenChanged(2);
    else setScreenChanged(0);
    swapScreen(instructor.peerSessionId);
  };
  const firstName = useSelector(
    (state: RootState) => state.userDetails.firstName
  );

  const lastName = useSelector(
    (state: RootState) => state.userDetails.lastName
  );

  const reconnectionStatus = useSelector(
    (state: RootState) => state.reconnectionReducer.reconnectionStatus
  );

  useEffect(() => {
    if (reconnectionStatus) {
      setScreenChanged(0);
      console.log("reconnection status in studentScreen.tsx");
    }
  }, [reconnectionStatus]);

  const noOp = () => {};

  const name = `${firstName} ${lastName}`;
  const currentUserRole = useSelector(
    (state: RootState) => state.userDetails.role
  );
  return (
    <div className={sideBarOpen ? "student-container" : "student-containerb"}>
      <div
        className="student-screen-section"
        title={!screenChanged ? "screen 1" : "screen 2"}
      >
        {props.presenterForAgent === socketId ? (
          <p className="presenter" style={{ color: "red" }}>
            <b>You are currently the presenter</b>
          </p>
        ) : instructor ? (
          <p className="presenter">{instructor.peerName} is the presenter</p>
        ) : null}
        {largeVideo ? (
          <>
            <Video
              id={instructor.peerSessionId}
              name={instructor.peerUsername}
              srcObject={largeVideo}
              peer={instructor}
              className="student-screen-inst-video white-border"
            />
            {!instructor.peerAudioPaused ? (
              <AudioVolumeMeter id={instructor.peerSessionId} view="col" />
            ) : null}
          </>
        ) : (
          <>
            <NameAvatar
              className="student-screen-instructor-avatar"
              text={instructor ? instructor.peerName : "Supervisor is Offline"}
            />
            {instructor ? (
              !instructor.peerAudioPaused ? (
                <AudioVolumeMeter id={instructor.peerSessionId} view="col" />
              ) : null
            ) : null}
          </>
        )}
        <div className="small-video">
          {secondScreen && (
            <div
              title={screenChanged ? "screeen1" : "screen2"}
              className="small-video-inner-one"
            >
              <Video
                id={instructor.peerSessionId}
                name={instructor.peerUsername}
                srcObject={secondScreen}
                peer={instructor}
              />
            </div>
          )}
          {smallVideo ? (
            <Video
              id={instructor.peerSessionId}
              name={instructor.peerUsername}
              className="small-video-inner-two"
              srcObject={smallVideo}
              peer={instructor}
            />
          ) : null}
        </div>
      </div>

      {remotePeers.map((remotePeer: RemotePeer) => {
        return remotePeer.peerAudioStream ? (
          <Audio
            key={remotePeer.peerSessionId}
            srcObject={remotePeer.peerAudioStream}
          />
        ) : null;
      })}

      {remotePeers.map((remotePeer: RemotePeer) => {
        return remotePeer.peerAudioFileStream ? (
          <Audio
            key={remotePeer.peerSessionId}
            srcObject={remotePeer.peerAudioFileStream}
          />
        ) : null;
      })}

      {remotePeers.map((remotePeer: RemotePeer) => {
        return remotePeer.screenAudioStream ? (
          <Audio
            key={remotePeer.peerSessionId}
            srcObject={remotePeer.screenAudioStream}
          />
        ) : null;
      })}
      <div>
        <ParticipantCard
          segmentationConfig={props.segmentationConfig}
          tflite={props.tflite}
          privateCallPeerOne={""}
          name={name}
          role={currentUserRole}
          shouldShowNameRole={false}
          shouldShowAvatar={true}
          pauseUnpauseVideo={pauseUnPauseVideo}
          pauseUnPauseAudio={pauseUnPauseAudio}
          addStreamOfAudioFile={addStreamOfAudioFile}
          addStreamOfVideoFile={addStreamOfVideoFile}
          audioPaused={localAudioPaused}
          videoPaused={localVideoPaused}
          id={socketId}
          swapScreen={handleSwapScreen}
          shouldShowSwapScreen={false}
          showVideo={localVideoStream && !localVideoPaused}
          shareUnshareScreen={shareUnshareScreen}
          shareUnshareScreenTwo={shareUnshareScreenTwo}
          smallRightVideo={localScreenStream}
          smallLeftVideo={localScreenTwoStream}
          screenStopped={screenStopped}
          screenTwoStopped={screenTwoStopped}
          audioStream={localAudioStream}
          videoStream={localVideoStream}
          alignControlsToRight={true}
          shouldShowExitButton={true}
          changeViewMode={noOp}
          isPrivateCallButtonVisible={amISuperVisor}
          signalStrength={signalStrength}
          shouldShowSignalStrength={true}
          //hardcoding as false as we dont need face detection on instructor screen
          isFaceDetectionEnabled={true}
          islocalVideo={true}
          roomname={roomname}
          audioAllowedInRoom={audioAllowedInRoom}
        />
      </div>
    </div>
  );
};

export default StudentScreen;
