const notificationByType = {
  multipleFaces: "Multiple Faces",
  leaveEnter: "Notifications Room",
  cellPhone: "Cellphone",
  restrictedApps: "Restricted Apps",
  berightback: "Be Right back",
  lunchTime: "Lunch",
  breakTime: "Break",
  camera: "Camera",
  handRaised:"Hand Raised",
  TechnicalIssue:"Technical Issue"
};

const roomViewModeConst = {
  collabMode: "meeting",
  lectureMode: "supervisor",
};

const socketConnectiontype = {
  media: "media",
  activityChannel: "activityChannel",
};

const mediaTypeConstant = {
  video: "VIDEO",
  screen: "SCREEN",
  screentwo: "SCREENTWO",
};

const resolutionConstant = {
  high: "HIGH",
  mid: "MID",
  low: "LOW",
  verylow: "VERYLOW",
};

const ABSTRACT_API_URL = "https://ipgeolocation.abstractapi.com/v1/?api_key=a1bc4a8fc5c54801b0849e4a77fe84a1";

export {
  notificationByType,
  roomViewModeConst,
  socketConnectiontype,
  mediaTypeConstant,
  resolutionConstant,
  ABSTRACT_API_URL
};
