import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import "./modalAudioVideo.css";
import UploadFile from "./uploadFile";

interface modalAudioVideoProps {
  show: boolean;
  handleShow: any;
  shareUnshareScreen: any;
  peerId: any;
  addStreamOfAudioFile: any;
  pauseUnPauseAudio: any;
  isAudioPaused: boolean;
  addStreamOfVideoFile: any;
  localAudioStream: any;
  localVideoStream: any;
}

const ModalAudioVideo = (props: modalAudioVideoProps) => {
  const isAudioFile = useSelector(
    (state: RootState) => state.audioVideoFileReducer.isAudioFile
  );
  const isVideoFile = useSelector(
    (state: RootState) => state.audioVideoFileReducer.isVideoFile
  );

  const handleModalClose = () => {
    props.handleShow();
    if (isAudioFile) {
      props.addStreamOfAudioFile(props.peerId, "audiosss");
    }
    if (isVideoFile) {
      props.addStreamOfVideoFile(props.peerId);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={handleModalClose}
      // className="feedback_modal_container"
      style={{ border: "0px solid black", width: "40%", margin: "auto" }}
    >
      <Modal.Header
        
        style={{
          backgroundColor: "rgb(38, 163, 219)",
          margin: "0",
          padding: "6px",
        }}
      >
         <button className="modal-close-btn" onClick={handleModalClose}>
            X
          </button>
        <Modal.Title
          style={{ color: "black", fontSize: "1.1rem", fontWeight: "bold" }}
        >
          Upload Video and Audio
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="feedback_modal_main">
          <div className="message_export_data">
            <div className="filters-container"></div>
          </div>

          <UploadFile
            shareUnshareScreen={props.shareUnshareScreen}
            peerId={props.peerId}
            addStreamOfAudioFile={props.addStreamOfAudioFile}
            pauseUnPauseAudio={props.pauseUnPauseAudio}
            isAudioPaused={props.isAudioPaused}
            addStreamOfVideoFile={props.addStreamOfVideoFile}
            localAudioStream={props.localAudioStream}
            localVideoStream={props.localVideoStream}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAudioVideo;
