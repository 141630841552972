import React, { useState, useEffect, useRef, SyntheticEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { SourcePlayback } from "../../helpers/sourceHelper";
import SocketService from "../../services/socket";
import "./video.css";
import RemotePeer from "../../Pages/RoomPage/RemotePeer";
import {
  socketConnectiontype,
  mediaTypeConstant,
  resolutionConstant,
  roomViewModeConst,
} from "../../utils/constant";
import { checkForSupManAdmin } from "../../utils/checkForSupAdmin";
interface Props {
  srcObject: MediaStream | null;
  muted?: boolean;
  className?: string;
  id?: string;
  name?: string;
  onLoad?: (sourcePlayback: SourcePlayback) => void;
  screenControl?: boolean;
  peer?: RemotePeer;
}

const socketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

const Video: React.FC<Props> = (props) => {
  const currentUserName = useSelector(
    (state: RootState) => state.userDetails.userName
  );

  const currentUserRole = useSelector(
    (state: RootState) => state.userDetails.role
  );

  const viewMode = useSelector((state: RootState) => state.roomsData.viewMode);

  const {
    srcObject,
    muted = false,
    className = "",
    id,
    name = "",
    peer,
  } = props;

  let mediaType: String;
  // let [videoResolution, setResolution] = useState("");
  // const [resolution, ] = React.useState("");

  if (peer?.peerVideoStream === srcObject) {
    mediaType = mediaTypeConstant.video;
  } else if (peer?.peerScreenStream === srcObject) {
    mediaType = mediaTypeConstant.screen;
  } else if (peer?.peerScreenTwoStream === srcObject) {
    mediaType = mediaTypeConstant.screentwo;
  }

  // @ts-ignore
  // const volume = useSelector((state: RootState) => state.peerVolume[id]);

  const [self, setSelf] = useState<HTMLVideoElement | null>(null);

  const openWindows = useRef([] as any);

  useEffect(() => {
    if (self != null) {
      self.srcObject = srcObject;
    }
  }, [srcObject, self]);

  useEffect(() => {
    let openwindoscurrent = openWindows.current;
    return () => {
      for (let index = 0; index < openwindoscurrent.length; index++) {
        const myWindow = openwindoscurrent[index];
        myWindow?.close();
      }
    };
  }, []);

  const handleDoubleClick = (id: any) => {
    if (props.screenControl) return;

    if (
      (checkForSupManAdmin(currentUserRole, "equalTo") &&
        viewMode === roomViewModeConst.lectureMode)
    ) {
      socketInstance.sendMessage(
        "SETTING_SCALE_RESOLUTION_DOWN_FOR_SELECTED_MEDIA_TYPE",
        {
          peerSessionId: id,
          resolution: resolutionConstant.high,
          mediaType,
          currentUserName,
        }
      );
    }

    const myWindow = window.open(
      "",
      "MsgWindow" + id + new Date(),
      "width=1000,height=1000"
    );

    myWindow?.addEventListener("unload", function (e) {
      if (
        (checkForSupManAdmin(currentUserRole, "equalTo") &&
          viewMode === roomViewModeConst.lectureMode)
      ) {
        socketInstance.sendMessage(
          "SETTING_SCALE_RESOLUTION_DOWN_FOR_SELECTED_MEDIA_TYPE",
          {
            peerSessionId: id,
            resolution: resolutionConstant.low,
            mediaType,
            currentUserName,
          }
        );
      }
    });

    const wrapper = document.createElement("div");
    if (myWindow) {
      myWindow.document.body.appendChild(wrapper);
      myWindow.document.title = name ? name : currentUserName;
    }

    var style = myWindow?.document.createElement("style");
    if (style) {
      style.innerText = `.videoInsert {
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }`;
      myWindow?.document.head.appendChild(style);
    }
    const videlem = document.createElement("video");
    videlem.className = "videoInsert";
    videlem.srcObject = srcObject;
    videlem.height = 600;
    videlem.width = 800;
    videlem.autoplay = true;
    videlem.muted = true;
    videlem.controls = true;
    videlem.playsInline = true;
    wrapper.appendChild(videlem);

    openWindows.current.push(myWindow);
  };

  const handleVideoLoad = (event: SyntheticEvent) => {
    const video = event.target as HTMLVideoElement;
    if (props.onLoad) {
      props.onLoad({
        htmlElement: video,
        width: video.videoWidth,
        height: video.videoHeight,
      });
    }
  };

  // const getVIdeoDimensions = (event: SyntheticEvent) => {
  //   const video = event.target as HTMLVideoElement;
  //   videoResolution = video.videoWidth + " x " + video.videoHeight;
  //   setResolution(videoResolution.toString());
  // };

  // const clearDimensionVar = () => {
  //   setResolution("");
  // };

  return (
    <>
      <video
        className={className}
        ref={setSelf}
        autoPlay
        playsInline
        muted={muted}
        id={id}
        onDoubleClick={() => handleDoubleClick(id)}
        onLoadedData={handleVideoLoad}
        // onMouseOver={getVIdeoDimensions}
        // onMouseLeave = {clearDimensionVar}
      >
        Your browser does not support the video tag.
      </video>
      {/* <span>{videoResolution}</span> */}
    </>
  );
};

export default Video;
