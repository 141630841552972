import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Header from "../../components/Header";


import {
  getPendingVerificationAction,
  verifyUsersAction,
  rejectUsersAction,
  updateNotificationCount
} from "../../actions/faceVerificationAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { Alert } from "react-bootstrap";



export default function FaceVerification() {
  const pendingUsers = useSelector(
    (state: RootState) => state.faceVerification.users
  );

  const count = useSelector((state: RootState) => state.verifyNotificationReducer.count);
  const [userNames, setUsernames] = React.useState<Array<string>>([]);
  const dispatch = useDispatch();
  const [isAllSelected, setIsAllSelected] = React.useState<boolean>(false);
  const [users,setUsers] = React.useState<Array<any>>([]);
  const [notificationCount, setNotificationCount] = React.useState<number>(0);
  function getExtractedString(str: string) {
    return str.replace(/^\(|\)$/g, "");
  }

  function handleImageClick(e: any, ele: any) {
    setIsAllSelected(false);
    const extractedString = getExtractedString(
      e.target.previousSibling.innerText
    );

    // console.log({ ele });

    if (!userNames.includes(ele.username)) {
      e.target.classList.add(styles.border);
    } else {
      e.target.classList.remove(styles.border);
    }
    console.log(extractedString);
    // removeSelect(e.target);
    setUsernames((prev) => {
      if (!prev.includes(ele.username)) {
        return [...prev, ele.username];
      } else {
        return prev.filter((elem) => elem !== ele.username);
      }
    });
  }

  function handleDeSelectAll(e: any) {
    setIsAllSelected(false);

    console.log(document.querySelector("#images_container")?.children);
    // let usernames: string[] = [];

    const parentElement = document.querySelector("#images_container");

    const childElements = parentElement?.children;

    if (childElements && childElements.length > 0) {
      for (let i = 0; i < childElements?.length; i++) {
        childElements[i]?.children[1].classList.remove(styles.border);
      }
    }
    // }

    setUsernames([]);
  }

  function handleSelectAll(e: any) {
    setIsAllSelected(true);
    console.log(document.querySelector("#images_container")?.children);
    let usernames: string[] = [];

    const parentElement = document.querySelector("#images_container");

    const childElements = parentElement?.children;

    if (childElements && childElements.length > 0) {
      for (let i = 0; i < childElements?.length; i++) {
        childElements[i]?.children[1].classList.add(styles.border);
        usernames.push(
          // @ts-ignore
          getExtractedString(childElements[i]?.children[0].innerText)
        );
      }
    }
    // }

    setUsernames(usernames);
  }


  useEffect(() => {
    setNotificationCount(count);
  }, [count])

  async function verifyAllUsers() {
    if (userNames.length < 0) {
      return;
    }

    const newCount = notificationCount - userNames.length
    dispatch(verifyUsersAction(userNames));
    dispatch(updateNotificationCount(newCount))
    setUsernames([])
    setIsAllSelected(false);
  }

  async function rejectAllUsers() {
    if (userNames.length < 0) {
      return;
    }
    const newCount = notificationCount - userNames.length
    dispatch(rejectUsersAction(userNames));
    dispatch(updateNotificationCount(newCount))
    setUsernames([])
    setIsAllSelected(false);
  }

  useEffect(() => {
    setUsers(pendingUsers);
  }, [pendingUsers]);


  useEffect(()=>{
    return () => {
      setUsers([]);
    }
  },[])

  useEffect(() => {
    dispatch(getPendingVerificationAction());
  }, [dispatch]);



  useEffect(() => {
    console.log({ userNames });
  }, [userNames]);

  return (
    <>
      <Header />
      <div className={styles.container}>
        <h1 className={styles.heading}>Admin Verification</h1>
        <Alert variant="success" className={styles.para_info}>
          Welcome to the Admin Verification portal. Kindly click on the image of your choice to select it for verification, or opt for the <span>Select All</span> button to choose multiple images for verification.
        </Alert>
        <div className={styles.btn_cont}>
          {!isAllSelected ? (
            <button style={{
              opacity: pendingUsers.length <= 0 ? 0.7 : 1,
              cursor: pendingUsers.length <= 0 ? "auto" : "pointer",
            }} className={styles.select_btn} onClick={handleSelectAll} disabled={pendingUsers.length <= 0}>
              SELECT ALL
            </button>
          ) : (
            <button style={{
              opacity: userNames.length <= 0 ? 0.7 : 1,
              cursor: userNames.length <= 0 ? "auto" : "pointer",
            }} className={styles.select_btn} onClick={handleDeSelectAll} disabled={userNames.length <= 0}>
              DE-SELECT ALL
            </button>
          )}

          <button
            onClick={verifyAllUsers}
            disabled={userNames.length <= 0}
            style={{
              opacity: userNames.length <= 0 ? 0.7 : 1,
              cursor: userNames.length <= 0 ? "auto" : "pointer",
            }}
            className={styles.verify_btn}
          >
            Verify
          </button>
          <button
            onClick={rejectAllUsers}
            disabled={userNames.length <= 0}
            style={{
              opacity: userNames.length <= 0 ? 0.7 : 1,
              cursor: userNames.length <= 0 ? "auto" : "pointer",
            }}
            className={styles.verify_btn}
          >
            Reject
          </button>
        </div>
        <div id="images_container" className={styles.images_container}>
          {users && users.length > 0
            ? users.map((ele: any) => {
              return (
                <div key={ele._id} className={styles.card}>
                  <p>({ele.username})</p>
                  <img
                    id={`image-${ele._id}`}
                    src={ele.faceVerification && ele.faceVerification.image}
                    alt="user-img"
                    onClick={(e) => handleImageClick(e, ele)}
                  />
                </div>
              );
            })
            : ""}
        </div>
      </div>
    </>
  );
}
