import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import SocketService from "../../services/socket";
import { socketConnectiontype } from "../../utils/constant";
import { getConnectionDetailsForActivityChannel } from "../../services/activitychannel.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import "./styles.css";
import {
  getAllFeeds,
  getSubscribedTopics,
  subscribeTopic,
  getAllTopicsExtended,
  unsubscribeTopic,
  editFeed,
} from "../../services/activitychannel.service";
import { toast } from "react-toastify";
import { timeZoneFormat } from "../../utils/datetimeformat";
import moment from "moment";
import {
  faPlus,
  faMinus,
  faEye,
  faPen,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faEdit,
  faTag,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from '../CustomAvatar'
import Button from "react-bootstrap/Button";
import { Editor } from "@tinymce/tinymce-react";
import config from "../../config";
import { setActivityUnreadPostCount } from "../../actions/activityChannelAction";
import { playChatNotification } from "../../utils/AudioVideoDeviceUtils";
import { notifyMe } from "../../utils/desktopNotification";
import { getPersonalizationAction } from "../../actions/personalizationActions";
import { getToken } from "../../utils/Token";
import { FILE_UPLOADS_TOPICS } from "../../Pages/RoomPage/constants";

function useStateRef(initialValue: any) {
  const [value, setValue] = useState(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
}
const ActivityMain = (props: any) => {
  let messageSubscriberAC: any;
  const dispatch = useDispatch();
  const username = useSelector(
    (state: RootState) => state.userDetails.userName
  );
  const firstName = useSelector(
    (state: RootState) => state.userDetails.firstName
  );
  const lastName = useSelector(
    (state: RootState) => state.userDetails.lastName
  );
  const currentUserRole = useSelector(
    (state: RootState) => state.userDetails.role
  );
  let unreadPostCount = useRef(0);
  unreadPostCount.current = useSelector(
    (state: RootState) => state.activityChannelReducer.unreadPostsCount
  );
  let isTopicMinimized = useRef("");
  isTopicMinimized.current = useSelector(
    (state: RootState) => state.activityChannelReducer.isActivityModalMinimize
  );
  const [allTopics, setAllTopics] = useState<any[]>([]);
  const [topicFetched, setTopicFetched] = useState(false);
  const [subscribedTopics, setSubscribedTopics, subscribedTopicsRef] =
    useStateRef([]);
  const [postTitle, setPostTitle] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("Select a Topic");
  const [selectedFeedTopic, setSelectedFeedTopic, selectedFeedTopicRef] =
    useStateRef("ALL FEEDS");
  const [selectedFeedTopicId, setSelectedFeedTopicId, selectedFeedTopicIdRef] =
    useStateRef("");
  const [selectedTopicId, setSelectedTopicId] = useState("");
  const [feeds, setFeeds, feedsRef] = useStateRef([]);
  const [subscribedTopicIds, setSubscribedTopicIds, subscribedTopicIdsRef] =
    useStateRef([]);
  const [pageNo, setPageNo] = useState(1);
  const [feedsLoading, setFeedsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [titleCount, setTitleCount] = useState(0);
  const [feedType, setFeedType] = useState("normal");

  const [isEditingPost, setIsEditingPost] = useState(false);
  const [editorError, setEditorError] = useState(false);
  const [editorHtml, setEditorHtml] = useState("");
  const [editPostId, setEditPostId] = useState("");
  const [loading, setLoading] = useState(true);

  const maxCharLimit = 1000;
  // const quillRef = useRef<ReactQuill | null>(null);
  const editorRef = useRef<any>(null);
  const [titleError, setTitleError] = React.useState<boolean>(false);
  const [descriptionError, setDescriptionError] =
    React.useState<boolean>(false);
  const [topicError, setTopicError] = React.useState<boolean>(false);

  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowNewPost, setModalShowNewPost] = React.useState(false);
  const [MyPostsClicked, setMyPostsClicked] = React.useState(false);
  const topicsPersonalization = useRef<any>(null);
  topicsPersonalization.current = useSelector(
    (state: RootState) =>
      state.personalizationReducer.settings?.settings?.activity
  );

  const environments = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  );

  const activityChannelServerUrl = environments.ACTIVITY_CHANNEL_SERVER_URL
    ? environments.ACTIVITY_CHANNEL_SERVER_URL.value
    : process.env.REACT_APP_SOCEKT_ACTIVITY_CHANNEL_SERVER_URL;

  const tinyMCEAPIKey = environments.REACT_APP_TINYMCE_API_KEY
    ? environments.REACT_APP_TINYMCE_API_KEY.value
    : config.tinyMCE;

  const fileUploadAccessForTopics = useRef<any>(null);
  fileUploadAccessForTopics.current = environments.FILE_UPLOADS_TOPICS
    ? environments.FILE_UPLOADS_TOPICS.status
    : FILE_UPLOADS_TOPICS;

  const clientName = useRef<any>(null);
  clientName.current = environments.REACT_APP_COMPANY_NAME
  ? environments.REACT_APP_COMPANY_NAME.value
  : process.env.REACT_APP_COMPANY_NAME;

    const commonPluginsForEditor = [
      "advlist",
      "autolink",
      "lists",
      "charmap",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      "help",
      "wordcount",
    ];

    const allowedPlugins = [...commonPluginsForEditor];
    if (fileUploadAccessForTopics.current) {
      allowedPlugins.push("image", "link");
    }
  // const descriptionPlaceholder = "What's on your mind, " + username;

    const setup = (editor:any) => {
      // Register custom icon
      editor.ui.registry.addIcon('customLinkIcon', '<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/></svg>');

      // Add a custom button
      editor.ui.registry.addButton('customLink', {
        icon: 'customLinkIcon',
        tooltip: 'Insert/edit link',
        onAction: function () {
          // Call the link plugin functionality or your custom function
          editor.execCommand('mceLink');
        }
      });
    };

  const socketInstanceAC = new SocketService(
    socketConnectiontype.activityChannel
  ).getInstance(socketConnectiontype.activityChannel);

  const handleEditorChange = (content: any) => {
    if (editorRef.current) {
      var some = editorRef.current.getContent();
      some.replace(/<\/?[^>]+(>|$)/g, "");
      setEditorHtml(some);
    }

    // content.replace(/<\/?[^>]+(>|$)/g, "");
    // console.log(content);
    // if (sanitizedHtml.length <= maxCharLimit) {
    // setEditorHtml(content);
    // } else {
    //   quillRef.current?.blur(); // Remove focus from the editor
    // }
    setDescriptionError(false);
    setEditorError(false);
  };

  // const handleKeyPress = (e: any) => {
  //   if (e.key.length === 1) {
  //     const sanitizedHtml = (editorHtml + e.key).replace(/<\/?[^>]+(>|$)/g, "");
  //     if (sanitizedHtml.length > maxCharLimit) {
  //       e.preventDefault(); // Prevent typing when limit is reached
  //     }
  //   }
  // };

  useEffect(() => {
    const fetchConnectionDetails = async () => {
      const connectionDetails = await getConnectionDetailsForActivityChannel(
        username
      );

      let hostnameAC = window.location.origin;

      if (hostnameAC.includes("http://localhost")) {
        hostnameAC = "http://localhost:8087";
      } else if (hostnameAC.includes("https://localhost")) {
        hostnameAC = "https://localhost:8087";
      } else {
        hostnameAC = activityChannelServerUrl;
      }

      console.log(
        "Connection details in dashboard",
        hostnameAC,
        connectionDetails.path,
        connectionDetails.payload,
        connectionDetails.iv,
        connectionDetails.digest
      );

      socketInstanceAC.connectToSocket(
        hostnameAC,
        connectionDetails.path,
        connectionDetails.payload,
        connectionDetails.iv,
        connectionDetails.digest,
        ""
      );
    };

    fetchConnectionDetails();
    getAllTopicslist();
    getSubscriptions();

    dispatch(getPersonalizationAction(username));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    messageSubscriberAC = socketInstanceAC
      .getMessages()
      .subscribe((message: string) => {
        processMessageFromACservice(JSON.parse(message));
      });
    return () => {
      console.log(
        "Unsubscribing Activity channel listener & Disconnecting Socket"
      );
      messageSubscriberAC.unsubscribe();
      socketInstanceAC.closeSocketConnection();
    };
  }, []);

  const processMessageFromACservice = async (message: any) => {
    switch (message.type) {
      case "CHANNEL_SOCKET_CONNECTED":
        console.log("Successfully connected to socket through redis");
        break;

      case "CHANNEL_SOCKET_DISCONNECTED":
        console.log("DISCONNECTED FROM SOCKET SERVER");
        break;

      case "NEW_POST_ACTIVITY_CHANNEL":
        const feed = message.feed;
        if (
          isTopicMinimized.current &&
          subscribedTopicIdsRef.current.includes(feed.topicId) &&
          hasPermission(feed.topicId, currentUserRole, "read")
        ) {
          dispatch(setActivityUnreadPostCount(unreadPostCount.current + 1));
        }
        if (
          (selectedFeedTopicRef.current === "ALL FEEDS" &&
            subscribedTopicIdsRef.current.includes(feed.topicId)) ||
          selectedFeedTopicIdRef.current === feed.topicId
        ) {
          setFeeds((current: any) => [feed, ...current]);
          if (feed.username !== username) {
            // Don't show notifications to self
            showNewPostNotification(feed.username);
          }
        }

        if (
          selectedFeedTopicRef.current === "MY POSTS" &&
          username === feed.username
        ) {
          setFeeds((current: any) => [feed, ...current]);
          if (feed.username !== username) {
            // Don't show notifications to self
            showNewPostNotification(feed.username);
          }
        }
        if (
          feed.type === "urgent" &&
          feed.username !== username &&
          subscribedTopicIdsRef.current.includes(feed.topicId) &&
          hasPermission(feed.topicId, currentUserRole, "read")
        ) {
          setModalShowNewPost(true);
        }
        break;
      case "UPDATE_FROM_ADMIN":
        if (message.msg === "FEED_DELETED") {
          const updatedFeeds = removeDeletedFeedFromState(message.id);
          setFeeds(updatedFeeds);
        } else {
          getAllTopicslist();
          getSubscriptions();
          setSelectedTopic("Select a Topic");
          setSelectedTopicId("");
          if (message.id === selectedFeedTopicIdRef.current) {
            setSelectedFeedTopic("ALL FEEDS");
            setSelectedFeedTopicId("");
          } else {
            if (selectedFeedTopicRef.current !== "ALL FEEDS") {
              if (selectedFeedTopicRef.current === "MY POSTS") {
                getFeeds(subscribedTopicIdsRef.current, pageNo, true);
              } else {
                getFeeds([selectedFeedTopicIdRef.current], pageNo, false);
              }
            }
          }
        }
        break;
      default:
        break;
    }
  };

  const removeDeletedFeedFromState = (idToRemove: any) => {
    return feedsRef.current.filter(
      (obj: any) => obj._id.toString().trim() !== idToRemove.toString().trim()
    );
  };

  useEffect(() => {
    if (selectedFeedTopic === "ALL FEEDS") {
      setPageNo(1);
      setFeedsLoading(true);
      getFeeds(subscribedTopicIds, 1, false);
    }

    if (selectedFeedTopic === "MY POSTS") {
      setPageNo(1);
      setFeedsLoading(true);
      getFeeds(subscribedTopicIds, 1, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedTopicIds]);

  const getAllTopicslist = async () => {
    const allTopics = await getAllTopicsExtended(username);
    if (allTopics.success) {
      setAllTopics(allTopics.topics);
      setSubscribedTopicIds(allTopics.subscribedTopicIds);
      setTopicFetched(true);
    } else {
      toast("Unable to fetch All topics");
      setTopicFetched(false);
    }
  };

  const getFeeds = async (
    topicIds: string[],
    pageNo: Number,
    fetchMyPostsOnly: boolean
  ) => {
    const allFeeds = await getAllFeeds(topicIds, pageNo, fetchMyPostsOnly);
    if (allFeeds.success && allFeeds.feeds) {
      if (allFeeds.pageNo === 1) {
        setFeeds(allFeeds.feeds);
      } else {
        if (allFeeds.feeds.length > 0)
          setFeeds((current: any) => [...current, ...allFeeds.feeds]);
      }
    } else {
      toast("Unable to fetch Feed");
    }
    setFeedsLoading(false);
  };

  const subscribe = async (topicId: any, topicName: string) => {
    const subscribe = await subscribeTopic(username, topicId);
    if (subscribe.success) {
      getAllTopicslist();
      getSubscriptions();
      toast("Successfully SUbscribed to " + topicName);
    } else {
      toast("Unable to Subscribe " + topicName);
    }
  };

  const unsubscribe = async (topicId: any, topicName: string) => {
    const subscribe = await unsubscribeTopic(username, topicId);
    if (subscribe.success) {
      getAllTopicslist();
      getSubscriptions();
      if (topicName === selectedTopic) {
        setSelectedTopic("Select a Topic");
        setSelectedTopicId("");
      }
      if (topicName === selectedFeedTopic) {
        handleSelectedTopicFeeds("ALL FEEDS", "");
        const feedsListContainer = document.querySelector(
          ".feeds-list-container"
        );
        const elements = feedsListContainer?.children;

        if (elements) {
          for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.classList.contains("active_feed")) {
              element.classList.remove("active_feed");
            }
          }
        }
        const feedElm = document.querySelector(
          "#all-feeds-li"
        ) as HTMLInputElement;
        feedElm.classList.add("active_feed");
      }
      toast("Unsubscribed from " + topicName);
    } else {
      toast("Unable to Unsubscribe from " + topicName);
    }
  };

  const getSubscriptions = async () => {
    const subscriptions = await getSubscribedTopics(username);
    if (subscriptions.success) {
      setSubscribedTopics(subscriptions.topics);
    } else {
      toast("Unable to fetch subscribed topics");
    }
  };

  const handleTopicSelect = (topic: string, topicId: any) => {
    setTopicError(false);
    setSelectedTopic(topic);
    setSelectedTopicId(topicId);
  };

  const handleSelectedTopicFeeds = (topic: string, topicId: any) => {
    setSelectedFeedTopic(topic);
    setSelectedFeedTopicId(topicId);

    setFeedsLoading(true);
    setPageNo(1);

    if (topic === "ALL FEEDS") {
      setMyPostsClicked(false);
      getFeeds(subscribedTopicIds, 1, false);
    } else if (topic === "MY POSTS") {
      setMyPostsClicked(true);
      getFeeds(subscribedTopicIds, 1, true);
    } else {
      setMyPostsClicked(false);
      getFeeds([topicId], 1, false);
    }
  };

  const resetFields = () => {
    setPostTitle("");
    setSelectedTopic("Select a Topic");
    setSelectedTopicId("");
    setTitleCount(0);
    setEditorHtml("");
    setTopicError(false);
    setTitleError(false);
    setDescriptionError(false);
  };

  const handlePost = async () => {
    const finedString = editorHtml
      .replace(/(<([^>]+)>)/gi, "")
      .trim()
      .replace(/&nbsp;/g, " ");
    console.log({ finedString, editorHtml });

    if (
      postTitle.trim() === "" &&
      finedString === "" &&
      selectedTopicId === ""
    ) {
      setTopicError(true);
      setTitleError(true);
      setDescriptionError(true);
      return;
    }

    if (
      postTitle.trim() === "" ||
      finedString === "" ||
      /^ *$/.test(finedString) ||
      selectedTopicId === ""
    ) {
      postTitle.trim() === "" && setTitleError(true);
      finedString === "" && setDescriptionError(true);
      /^ *$/.test(finedString) && setDescriptionError(true);

      selectedTopicId === "" && setTopicError(true);
      return;
    }

    if (editorHtml && finedString.length > 1000) {
      setEditorError(true);
      return;
    }

    if (isEditingPost) {
      try {
        await editFeed(
          editPostId,
          selectedTopicId,
          postTitle,
          editorHtml,
          feedType
        );
        toast("Post Updated");
        setEditPostId("");
        setIsEditingPost(false);
      } catch (e: any) {
        toast("Unable to update Post: ", e);
        setEditPostId("");
        setIsEditingPost(false);
        setModalShow(false);
      }
    } else {
      socketInstanceAC.sendMessage("NEW_POST_ACTIVITY_CHANNEL", {
        username: username,
        title: postTitle,
        description: editorHtml,
        topicId: selectedTopicId,
        firstname: firstName,
        lastname: lastName,
        type: feedType,
      });
      toast("Post Created Successfully");
    }
    resetFields();
    setModalShow(false);
  };

  const gettime = (date: any) => {
    const { format } = timeZoneFormat();
    return moment(date).format(format);
  };

  const handleScrollState = async (e: any) => {
    const target = e.target;

    if (feedsLoading) return;

    if (
      Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) < 1
    ) {
      setFeedsLoading(true);
      if (selectedFeedTopic === "ALL FEEDS") {
        getFeeds(subscribedTopicIds, pageNo + 1, false);
      } else if (selectedFeedTopic === "MY POSTS") {
        getFeeds(subscribedTopicIds, pageNo + 1, true);
      } else {
        getFeeds([selectedFeedTopicId], pageNo + 1, false);
      }
      setPageNo(pageNo + 1);
    }
  };

  const handleOpenInputClick = () => {
    setModalShow(true);
  };

  const handleEditPost = (
    selectedTopic: string,
    topicId: string,
    postTitle: string,
    postDescription: string,
    postId: string,
    type: string
  ) => {
    setIsEditingPost(true);
    setSelectedTopic(selectedTopic);
    setSelectedTopicId(topicId);
    setPostTitle(postTitle);
    setFeedType(type);
    setEditorHtml(postDescription);
    setTitleCount(postTitle.length);
    setEditPostId(postId);
    setModalShow(true);
  };

  const handleTopicSelectionChange = (e: any, tpc_topic: any, tpcid: any) => {
    const feedsListContainer = document.querySelector(".feeds-list-container");
    const elements = feedsListContainer?.children;

    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (element.classList.contains("active_feed")) {
          element.classList.remove("active_feed");
        }
      }
    }
    // @ts-ignore
    e.target.classList.add("active_feed");
    handleSelectedTopicFeeds(tpc_topic, tpcid);
  };

  const handleModalCloseNewPost = () => {
    setModalShowNewPost(false);
  };

  // Check if user has SPECIFIC permission for an TOPIC (permissionToCheck can be 'read', 'write' or 'none')
  const hasPermission = (
    topicId: string,
    currentRole: string,
    permissionToCheck: string
  ): boolean => {
    // Find the topic with the given ID
    const topic = subscribedTopicsRef.current.find(
      (topic: any) => topic._id === topicId
    );
    console.log("TOPIC", topic);
    // If found, check if the currentRole has SPECIFIC permission
    if (topic) {
      const rolePermissions = topic.permissions[currentRole];
      console.log(rolePermissions.includes(permissionToCheck.toLowerCase()));
      return rolePermissions.includes(permissionToCheck.toLowerCase());
    }
    // Return false if topic not found or read SPECIFIC not present
    return false;
  };

  const showNewPostNotification = (username: string) => {
    // Play audio notification if needed
    if (topicsPersonalization.current?.audio) {
      playChatNotification();
    }

    // Show toast notification if needed
    if (topicsPersonalization.current?.toast) {
      const toastId = "5";
      const toastMessage = `New Post in Activity Channel from ${username}`;
      toast(toastMessage, { toastId });
    }

    // Show desktop notification if needed
    if (topicsPersonalization.current?.desktop) {
      const notificationMessage = `New Post in Activity Channel from ${username}`;
      notifyMe(notificationMessage);
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
    setIsEditingPost(false);
    setEditPostId("");
    resetFields();
  };

  const uploadImageHandler: any = async (
    blobInfo: any,
    success: (url: string) => void,
    failure: (err: string) => void,
    progress: (percent: number) => void
  ) => {
    if (fileUploadAccessForTopics.current) {
      // Allowed image MIME types
      const allowedImageTypes = ["image/png", "image/jpeg", "image/gif"];

      // Get the blob and its MIME type
      const blob = blobInfo.blob();
      const mimeType = blob.type;

      // Check if the MIME type is allowed
      if (!allowedImageTypes.includes(mimeType)) {
        toast("Only image files (PNG, JPEG, GIF) are allowed.");
        return; // Exit
      }

      // FormData to hold the file
      const formData = new FormData();
      formData.append("file", blob, blobInfo.filename());

      try {
        setLoading(true);

        const response = await fetch(
          `${activityChannelServerUrl}/TrainingRoom/upload`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: getToken(),
            },
          }
        );

        // Check if the request was successful
        if (response.ok) {
          const data = await response.json();
          if (data && data.location) {
            success(data.location);
          } else {
            failure("The server did not return an image URL.");
          }
          setLoading(false);
        } else {
          setLoading(false);
          // If the response is not ok, throw an error that will be caught by the catch block
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        setLoading(false);
        // On error, call the failure callback with the error message
        failure(
          `Image upload failed: ${
            error instanceof Error ? error.message : String(error)
          }`
        );
      }
    } else {
      toast("Module Disabled by Admin");
    }
  };

  function Spinner() {
    return (
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

  return (
    <div className="content-grid">
      <div className="feed-column">
        <div className="post-header mb-10">
          <div className="select-feeds-container">
            <ul className="feeds-list-container">
              <li
                className="feed-list-item"
                onClick={(e) => handleTopicSelectionChange(e, "MY POSTS", "")}
              >
                My Posts
              </li>
              <li
                id="all-feeds-li"
                className="feed-list-item active_feed"
                onClick={(e) => handleTopicSelectionChange(e, "ALL FEEDS", "")}
              >
                All Feeds
              </li>
              {subscribedTopicsRef.current &&
                subscribedTopicsRef.current.map((tpc: any) =>
                  tpc.permissions &&
                  tpc.permissions[currentUserRole].includes("read") ? (
                    <li
                      onClick={(e) => {
                        handleTopicSelectionChange(e, tpc.topic, tpc._id);
                      }}
                      className="feed-list-item"
                    >
                      {tpc.topic}
                    </li>
                  ) : null
                )}
            </ul>
          </div>
        </div>
      </div>

      <div
        className="column-left"
        style={{
          width: `${isDrawerOpen ? "50%" : "70%"}`,
          overflowX: "hidden",
        }}
        onScroll={(e: any) => {
          handleScrollState(e);
        }}
      >
        <div className="content-area">
          <div
            className="post-header post-input-header"
            style={{
              height: "100px",
              marginBottom: "10px",
              paddingBottom: "20px",
              paddingTop: "20px",
              position: "sticky",
              top: "0",
              background: "#fff",
            }}
          >
            <Avatar name={firstName + " " + lastName} size={40} />{" "}
            &nbsp;
            <button className="post-popup-btn" onClick={handleOpenInputClick}>
              <p>Start a post</p>
            </button>
          </div>
          {/*  */}

          <div className="feeds">
            {feeds?.map((post: any) =>
              subscribedTopicsRef.current
                .filter((tpc: any) => tpc._id === post.topicId)
                .map((topic: any) => {
                  return (
                    topic.permissions &&
                    (topic.permissions[currentUserRole].includes("read") ||
                      MyPostsClicked) && (
                      <div className="a_card post">
                        <div className="post-header">
                          <div className="post-author-info">
                            {/* <img
                              src={`https://ui-avatars.com/api/?name=${post.username}&background=random`}
                              className="prefix-img"
                              title={post.username}
                              alt=""
                            /> */}
                            {post.firstname && post.lastname ? (
                              <Avatar
                                name={post.firstname + " " + post.lastname}
                                size={35}
                                style={{ marginRight: "10px" }}
                              />
                            ) : (
                              <Avatar
                                name={post.username}
                                size={35}
                                style={{ marginRight: "10px" }}
                              />
                            )}
                            &nbsp;
                            <div>
                              <div>
                                <p
                                  className="author-name"
                                  style={{ margin: 0 }}
                                >
                                  <p
                                    style={{
                                      width: "78%",
                                      margin: 0,
                                      fontWeight: 500,
                                    }}
                                    id={post.createdAt + post._id}
                                  >
                                    {" "}
                                    {post.title}
                                  </p>
                                </p>
                                <p className="userAC" style={{ margin: 0 }}>
                                  Author:{" "}
                                  <span
                                    style={{ color: "#111", fontWeight: 600 }}
                                  >
                                    {/* {post.username} */}
                                    {post.firstname + " " + post.lastname}
                                  </span>{" "}
                                  Topic:{" "}
                                  <span
                                    id={post._id + "-topicLabel"}
                                    style={{ color: "#111", fontWeight: 600 }}
                                  >
                                    {topic.topic}
                                  </span>
                                </p>

                                {/* {checkForSupManAdmin(currentUserRole, "equalTo") && <i className="verified-icon"></i>} */}
                              </div>
                              <div className="details">
                                <span className="post-date">
                                  {gettime(post.createdAt)} &nbsp;
                                  {/* Currently only Admin have access to activity channel in admin module. In future
                                   * if permission given to other roles like Supervisor, Director or Manager then change
                                   * accordingly here and use checkForSupManAdmin function
                                   */}
                                  {post.edited &&
                                    (post.editorRole === "Admin"
                                      ? "[last Edited by Admin]"
                                      : "[last Edited by Author]")}
                                </span>
                                {/* <span> · </span> */}
                                <i className="post-settings-icon"></i>
                              </div>
                            </div>
                          </div>
                          {post.username === username && (
                            <FontAwesomeIcon
                              color="#26a3db"
                              id={post.createdAt + "edit"}
                              className="editTopicIcon"
                              icon={faEdit}
                              size="lg"
                              onClick={() =>
                                handleEditPost(
                                  topic.topic,
                                  topic.topicId,
                                  post.title,
                                  post.description,
                                  post._id,
                                  post.type ? post.type : "normal"
                                )
                              }
                            />
                          )}
                          <FontAwesomeIcon
                            color={post.type === "normal" ? "#26a3db" : "red"}
                            id={post.createdAt + "tag"}
                            className="topic-type"
                            title={
                              post.type === "urgent"
                                ? "This post is marked as urgent"
                                : "This post is marked as normal"
                            }
                            icon={faTag}
                            size="lg"
                          />
                          {/* <i className="post-menu-icon"></i>  */}
                        </div>

                        <p
                          className="post-body"
                          id={post._id + post.createdAt}
                          dangerouslySetInnerHTML={{
                            __html: post.description,
                          }}
                        />

                        {/* <div className="post-reactions">
                            <div className="reactions">
                                <div className="emojis">
                                    <img src={like} />
                                </div>
                                <span>366</span>
                            </div>
                            <div className="comment-share">
                                <div>
                                    <span>121</span>
                                    <span>Comments</span>
                                </div>
                            </div>
                            </div>
                            <div className="post-actions">
                            <div className="actions">
                                <div className="action">
                                    <i className="like-icon"></i>
                                    <span>Like</span>
                                </div>
                                <div className="action">
                                    <i className="comment-icon"></i>
                                    <span>Comment</span>
                                </div>
                            </div>
                            </div> */}
                      </div>
                    )
                  );
                })
            )}
          </div>
        </div>
      </div>

      <div className="icon-close-tab">
        {isDrawerOpen ? (
          <FontAwesomeIcon
            color="#26a3db"
            icon={faAngleDoubleRight}
            className="double"
            onClick={() => setIsDrawerOpen(false)}
            style={{ fontSize: "25px" }}
            title="Close Tab"
          />
        ) : (
          <FontAwesomeIcon
            color="#26a3db"
            icon={faAngleDoubleLeft}
            className="double"
            onClick={() => setIsDrawerOpen(true)}
            style={{ fontSize: "25px" }}
            title="Open Tab"
          />
        )}
      </div>

      {topicFetched && isDrawerOpen && (
        <div
          className="column-right"
          style={{
            width: "38%",
          }}
        >
          <div className="contacts">
            <div className="header">
              <span className="title list-title">All Topics</span>
            </div>
            <ul className="contacts-list">
              {allTopics.map((tpc) =>
                tpc.permissions &&
                !tpc.permissions[currentUserRole].includes("none") ? (
                  <li className="contacts-item">
                    {/* <img
                      src={`https://ui-avatars.com/api/?name=${tpc.topic}&background=random`}
                      className="prefix-img"
                      title={tpc.topic}
                      alt=""
                    /> */}
                    <Avatar name={tpc.topic} size={42}  /> &nbsp;
                    <span className="topic-name-list">
                      {tpc.topic}
                      {tpc.permissions &&
                      tpc.permissions[currentUserRole].includes("read") ? (
                        <FontAwesomeIcon
                          className="ml-5"
                          size="xs"
                          icon={faEye}
                          title="can view feeds"
                        />
                      ) : (
                        ""
                      )}
                      {tpc.permissions &&
                      tpc.permissions[currentUserRole].includes("write") ? (
                        <FontAwesomeIcon
                          className="ml-5"
                          size="xs"
                          icon={faPen}
                          title="can post feeds"
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <div className="subUnsubBtn">
                      {" "}
                      &nbsp;
                      {subscribedTopicIds.includes(tpc._id) ? (
                        <>
                          <button
                            onClick={() => unsubscribe(tpc._id, tpc.topic)}
                            className="link btn-width subscribed"
                            type="button"
                          >
                            {" "}
                            <FontAwesomeIcon icon={faMinus} /> Unsubcsribe
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => subscribe(tpc._id, tpc.topic)}
                            className="link"
                            type="button"
                          >
                            <FontAwesomeIcon icon={faPlus} /> Subscribe
                          </button>
                        </>
                      )}
                    </div>
                  </li>
                ) : null
              )}
            </ul>
          </div>
        </div>
      )}
      <Modal
        show={modalShow}
        onHide={handleModalClose}
        //  @ts-ignore
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ padding: "10px !important" }}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header
          className="input-modal-popup-header"
          style={{ position: "relative" }}
        >
          <button className="modal-close-btn" onClick={handleModalClose}>
            X
          </button>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="post-type">
              <Avatar
                name={firstName + " " + lastName}
                size={42}

              />{" "}
              &nbsp;
              <div className="selector">
                <p className="selector_username">{username}</p>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="input-popup-label">
            *Select a Topic before filling Title or Description.
          </p>
          <div className="a_card input-popup-card">
            <div className="new-post-action">
              <div className="input-title-container">
                <div className="drop_down_container">
                  <Dropdown
                    className="topic-dd"
                    style={{ border: "solid 1px #ccc", padding: "6px" }}
                  >
                    <Dropdown.Toggle
                      className="topic-dd"
                      variant="success"
                      id="dropdown-basic"
                    >
                      {selectedTopic.length > 15
                        ? selectedTopic.substring(0, 10) + "..."
                        : selectedTopic}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {subscribedTopics &&
                        subscribedTopicsRef.current.map((tpc: any) =>
                          tpc.permissions &&
                          tpc.permissions[currentUserRole].includes("write") ? (
                            <Dropdown.Item
                              onClick={() =>
                                handleTopicSelect(tpc.topic, tpc._id)
                              }
                            >
                              {tpc.topic}
                            </Dropdown.Item>
                          ) : null
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                  {topicError && (
                    <span className="err-AC">*Please Select a Topic</span>
                  )}
                </div>
                <div className="inp_title_container">
                  <div className="title-input">
                    <input
                      name="postTitle"
                      id="postTitle"
                      value={postTitle}
                      onChange={(e) => {
                        setPostTitle(e.target.value);
                        setTitleError(false);
                        setTitleCount(e.target.value.length);
                      }}
                      placeholder="Post Title"
                      maxLength={50}
                      className="topic-input"
                    />
                    <span className="count-span">{`(${titleCount}/50)`}</span>
                  </div>

                  {titleError && (
                    <span className="err-AC">*Title is Mandatory</span>
                  )}
                </div>
              </div>
              <div className="input-title-container">
                <div className="type-catgory">
                  <label>Normal</label>
                  <input
                    name="feed_type"
                    id="normal"
                    type="radio"
                    value="normal"
                    placeholder="Post Title"
                    onChange={(e) => {
                      setFeedType(e.target.value);
                    }}
                    checked={feedType === "normal"}
                  />
                </div>
                <div className="type-catgory">
                  <label>Urgent</label>
                  <input
                    name="feed_type"
                    id="urgent"
                    type="radio"
                    value="urgent"
                    placeholder="Post Title"
                    onChange={(e) => {
                      setFeedType(e.target.value);
                    }}
                    checked={feedType === "urgent"}
                  />
                  <FontAwesomeIcon
                    className="urgent-tooltip"
                    title="urgent post will broadcast immediately on all user terminals"
                    icon={faCircleQuestion}
                  />
                </div>
                {/* {titleError && (
                    <span className="err-AC">*Title is Mandatory</span>
                  )} */}
              </div>
              {/* <ReactQuill
                theme="snow"
                value={editorHtml}
                onChange={handleEditorChange}
                placeholder={descriptionPlaceholder}
                style={{ fontSize: "15px" }}
                ref={quillRef}

                // onKeyPress={handleKeyPress}
              /> */}
              {loading && (
                <div
                  style={{
                    position: "fixed",
                    left: "50%",
                    top: "50px",
                    transform: "translateX(-50%)",
                    zIndex: "9999 !important",
                  }}
                >
                  <Spinner />
                </div>
              )}
              <Editor
                apiKey={tinyMCEAPIKey}
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                  setLoading(false);
                }}
                value={editorHtml}
                onEditorChange={handleEditorChange}
                init={{
                  branding: false,
                  height: 300,
                  menubar: false,
                  plugins: allowedPlugins,
                  setup: setup,
                  toolbar:
                    "undo redo blocks bold italic forecolor alignleft aligncenter alignright alignjustify image customLink | bullist numlist outdent indent removeformat help",
                  image_title: true,
                  automatic_uploads: true,
                  image_uploadtab: false,
                  image_description: false,
                  image_dimensions: false,
                  image_prepend_url: false,
                  image_caption: true,
                  images_upload_handler: uploadImageHandler,
                  file_picker_types: "file image",
                  file_picker_callback: function (cb, value, meta) {
                    if (fileUploadAccessForTopics.current) {
                      const input = document.createElement("input");
                      input.setAttribute("type", "file");
                      input.setAttribute(
                        "accept",
                        "image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf"
                      );

                      input.onchange = async (e) => {
                        const file = (e.target as HTMLInputElement).files?.[0];
                        if (!file) {
                          return;
                        }

                        // Check if the file type is allowed
                        const allowedTypes = [
                          "image/png",
                          "image/jpeg",
                          "image/gif",
                          "application/pdf",
                          "application/msword",
                          "application/vnd.ms-excel",
                          "application/vnd.ms-powerpoint",
                          "text/plain",
                          "application/rtf",
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                        ];
                        if (!allowedTypes.includes(file.type)) {
                          toast("Only image and document files are allowed.");
                          return; // Exit
                        }

                        // FormData to hold the file
                        const formData = new FormData();
                        formData.append("file", file);
                        formData.append("clientname", clientName.current);

                        const token = getToken();

                        try {
                          setLoading(true);

                          const response = await fetch(
                            `${activityChannelServerUrl}/TrainingRoom/upload`,
                            {
                              method: "POST",
                              body: formData,
                              headers: {
                                Authorization: token,
                              },
                            }
                          );

                          if (response.ok) {
                            const data = await response.json();

                            if (data && data.location) {
                              cb(data.location, { title: file.name });
                            } else {
                              throw new Error(
                                "The server did not return a file URL."
                              );
                            }
                            setLoading(false);
                          } else {
                            setLoading(false);
                            throw new Error(
                              `HTTP error! status: ${response.status}`
                            );
                          }
                        } catch (error) {
                          setLoading(false);
                          console.error("File upload failed:", error);
                          // Handle error case - maybe call cb with empty string or show notification
                          cb("", { title: "Upload failed" });
                        }
                      };

                      input.click();
                    } else {
                      toast("Module disabled by Admin");
                    }
                  },
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </div>
            <p
              style={{ margin: "0" }}
              className={`${
                editorHtml
                  .replace(/(<([^>]+)>)/gi, "")
                  .trim()
                  .replace(/&nbsp;/g, " ").length > 1000
                  ? "msg__popup"
                  : "normal"
              }`}
            >
              *Character count:{" "}
              {
                editorHtml
                  .replace(/(<([^>]+)>)/gi, "")
                  .trim()
                  .replace(/&nbsp;/g, " ").length
              }
              /{maxCharLimit}
            </p>
            {descriptionError && (
              <span className="err-AC">*Description is Mandatory</span>
            )}

            {editorError && (
              <span className="err-AC">
                *Maximum Character limit for description is 1000
              </span>
            )}
            <div className="new-post-types">
              <Button
                variant="info"
                onClick={() => handlePost()}
                className={`link mt-3  post-btn
                    `}
                // disabled={
                //   selectedTopicId === "" ||
                //   postTitle.trim() === "" ||
                //   editorHtml.replace(/<\/?[^>]+(>|$)/g, "").trim() === "" ||
                //   editorHtml.replace(/<\/?[^>]+(>|$)/g, "").length > 1000
                //     ? true
                //     : false
                // }
              >
                {isEditingPost ? "UPDATE POST" : "POST"}
              </Button>

              {/* <button


                type="button"
              >

              </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalShowNewPost}
        onHide={handleModalCloseNewPost}
        //  @ts-ignore
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ padding: "10px !important" }}
        backdrop="static"
      >
        <Modal.Header
          className="input-modal-popup-header"
          style={{ position: "relative" }}
        >
          <button className="modal-close-btn" onClick={handleModalCloseNewPost}>
            X
          </button>
          <Modal.Title id="contained-modal-title-vcenter">
            New Post Alert
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="new-post-modal">Urgent! You have new post.</p>
          <div className="a_card input-popup-card">
            <div className="new-post-action">
              <div className="input-title-container">
                <div className="drop_down_container"></div>
                <div className="inp_title_container"></div>
              </div>
            </div>

            <div className="new-post-types">
              <Button
                variant="info"
                onClick={handleModalCloseNewPost}
                className={`link mt-3  post-btn`}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ActivityMain;
