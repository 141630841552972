import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  OTP_FAILURE,
  OTP_SUCCESS,
  SET_MFA,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CLEAR_REDUX_DATA,
  SET_VIA_APP_TRUE,
  SET_VIA_APP_FALSE,
  SET_AUTO_LOGIN_LOADING_FALSE, SET_COMPLEX_PASSWORD,
} from "../actions/actionTypes";

const initialState = {
  isLoggedIn: false,
  isOTPtest:false,
  isMFAEnable:false,
  Loginusername:"",
  error: "",
  isLoading: true,
  autoLoginLoading: true,
  isComplexPassword: true,
  viaApp: false,
  token: localStorage.token ? localStorage.token : "",
};

interface IAction {
  type: string;
  payload: any;
}

const loginReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        isOTPtest: false,
        isLoading: false,
        isComplexPassword: action.payload.isComplexPassword,
        token: action.payload.token
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        isLoggedIn: false,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case OTP_SUCCESS: {
      return {
        ...state,
        isOTPtest: true,
        isLoggedIn: true,
        isLoading: false,
      };
    }
    case OTP_FAILURE: {
      return {
        ...state,
        isOTPtest: false,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case SET_MFA: {

      //console.log('mfa Cast => Login Re '+action.payload.mfa);

      return {
        ...state,
        isMFAEnable:action.payload.mfa,
        //isMFAEnable: true,
        //Loginusername:"",
      };
    }

    case LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false,
        isOTPtest: false,
        isLoading: false,
      };
    }
    case LOGOUT_FAILURE: {
      return {
        ...state,
        isLoggedIn: true,
      //  isOTPtest: true,
        isLoading: false,
      };
    }
    case SET_VIA_APP_TRUE: {
      return {
        ...state,
        viaApp: true,
      };
    }
    case SET_VIA_APP_FALSE: {
      return {
        ...state,
        viaApp: false,
      };
    }
    case SET_AUTO_LOGIN_LOADING_FALSE: {
      return {
        ...state,
        autoLoginLoading: false,
      };
    }
    case SET_COMPLEX_PASSWORD: {
      return {
        ...state,
        isComplexPassword: action.payload.isComplexPassword
      }
    }
    case CLEAR_REDUX_DATA: {
      return {...initialState, isLoading: false};
    }
    default: {
      return state;
    }
  }
};

export default loginReducer;
