import React, { useEffect, useState } from "react";
import "./Login.css";
// @ts-ignore
import Logo from "../../assets/images/logo.svg";
import Input from "./../../components/Input";
import ModalComponent from "./../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  handleLogin,
  handleOTP,
  setLoginError,
} from "../../actions/loginActions";
import { RootState } from "../../redux";
import { Alert } from "react-bootstrap";
import Loader from "./../../components/Loader";

import {
  getAudioPermission,
  getVideoPermission,
} from "../../utils/AudioVideoDeviceUtils";
import { getUsersLocation } from "../../utils/LocationUtils";
import { toast } from "react-toastify";
import { Navigate, useSearchParams } from "react-router-dom";
import { getLogoAction } from "../../actions/appLogo";
import { sendResetPwdLink } from "../../services/forgotPassword";
import { getOktaEnv } from "../../services/environment";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [resetEmail, setResetEmail] = React.useState("");
  const [resetEmailError, setResetEmailError] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [OTP, setOTP] = React.useState("");
  const [, setLoginusername] = React.useState("");
  const [isForgotPassword, setIsForgotPassword] = React.useState(false);
  const [validationError,setValidationError] = React.useState(false);
  const [isOktaActive, setOktaActive] = React.useState(false)
  const [saml, setSaml] = React.useState("SAML");
  const [searchParams] = useSearchParams();

  let m = searchParams.get('m');

  const showToast = (message: string) => {
    toast(message);
  };
  React.useEffect(() => {
    const requestAudioDevicePermissions = async () => {
      try {
        dispatch(getLogoAction());
        await getAudioPermission();
        await getVideoPermission();
      } catch (err) {
        showToast("Please provide mic and video device access");
        console.error(err);
      }
    };
    const requestLocationAccessPermission = async () => {
      try {
        await getUsersLocation();
      } catch (err) {
        console.log(err);
      }
    };
    requestAudioDevicePermissions();
    requestLocationAccessPermission();
  }, [dispatch]);

  const loginError = useSelector((state: RootState) => state.login.error);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const savedLogo = useSelector(
    (state: RootState) => state.logoReducer.logoUrl
  );


  const setOkta = async () => {
    let oktaResponse = await getOktaEnv();
    if(oktaResponse.status) {
      setSaml(oktaResponse.value);
      setOktaActive(oktaResponse.okta);
    }
  }
  const [logo, setLogo] = useState(Logo);

  useEffect(() => {
    if (savedLogo) {
      setLogo(savedLogo);

      var link = document.querySelector("link[rel~='apple-touch-icon']");
      // @ts-ignore
      link.href = savedLogo;
    }
  }, [savedLogo, logo]);

  useEffect(() => {
    setOkta();
  },[])
  // const isOTPVerified = useSelector(
  //   (state: RootState) => state.login.isOTPtest
  // );
  const isMFAEnable = useSelector(
    (state: RootState) => state.login.isMFAEnable
  );
  //const Loginusername = useSelector((state: RootState) => state.login.Loginusername);
  function checkpassword(password: any) {
    let strength = 0;
    if (password.match(/[a-z]+/)) {
      strength = strength + 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength = strength + 1;
    }
    if (password.match(/[0-9]+/)) {
      strength = strength + 1;
    }
    if (password.match(/[$@#&!]+/)) {
      strength = strength + 1;
    }
    if (password.length >= 8) {
      strength = strength + 1;
    }

    if (strength * 20 >= 100) {
      return true;
    }
    return false;
  }
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError(false)
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError(false);
    setPassword(e.target.value);
  };

  const handleLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    dispatch(setLoginError(""));
    e.preventDefault();

    if((!email && !password) || !email || !password) {
      setValidationError(true);
      return;
    }

    let isComplexPassword = checkpassword(password);
    dispatch(handleLogin(email, password, isComplexPassword));
  };

  const oktaLoginRedirect = () => {
    window.location.href = process.env.REACT_APP_API_URL+ "/okta";
  }

  const handleResetEmailChange = (e: any) => {
    if (resetEmail) {
      setResetEmailError(false);
    }
    setResetEmail(e.target.value);
  };
  const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOTP(e.target.value);
  };

  const handleLoginUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginusername(e.target.value);
  };

  const handleOTPSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(handleOTP(email, OTP));
  };

  const handleResetLinkSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // localStorage.setItem("resetEmail", resetEmail);
      const response = await sendResetPwdLink(resetEmail);

      if (response.success) {
        toast("You will receive a reset email if user with that email exist");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error sending reset link: ", error);
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard" replace={true}  />;
  }

  //if (isOTPVerified){
  //return <Redirect to="/dashboard" />;
  //}

  ///////  IF SUCCESSFULLY LOGIN & MFA=1 THEN SHOW OTP SCREEN ELSE SHOW DASHBOARD ///////////
  if (isMFAEnable === "true" || isMFAEnable === true) {
    return (
      <>
        <div className="login-page">
          <div className="bg-box"></div>
          <div className="form">
            <img src={logo} alt="Training Room Logo" />
            <h4>Login</h4>
            <p>Enter your OTP to login our dashboard.</p>
            {loginError && <Alert variant="danger">{loginError}</Alert>}
            <form onSubmit={handleOTPSubmit}>
              <h6>OTP</h6>
              <Input
                type="text"
                name="otp-field"
                placeholder="Please enter Your OTP"
                value={OTP}
                onChange={handleOTPChange}
              />
              <Input
                type="hidden"
                name="hiddenusername-field"
                placeholder="Loginusername"
                value={email}
                onChange={handleLoginUserChange}
              />

              <button className="link" autoFocus type="submit" title="Sign In">
                Verify OTP
              </button>
            </form>
          </div>
        </div>
        <ModalComponent>
          <Modal.Header closeButton>
            <Modal.Title>Forget Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <h6>User Id / Email Id</h6>
              <input type="text" name="" placeholder="Email Id." />
              <button className="link mt-3">Submit</button>
            </form>
          </Modal.Body>
        </ModalComponent>
      </>
    );
  }

  if (isForgotPassword) {
    return (
      <>
        <div className="login-page">
          <div className="bg-box"></div>
          <div className="form">
            <img src={logo} alt="Training Room Logo" />
            <h4>Forgot Password</h4>
            <p>Please Enter your email to continue</p>
            {loginError && <Alert variant="danger">{loginError}</Alert>}
            <form onSubmit={handleResetLinkSubmit}>
              <h6>Email</h6>
              <input
                type="email"
                name="reset-email-field"
                placeholder="Please enter Your Email"
                value={resetEmail}
                onChange={handleResetEmailChange}
                required
              />
              {resetEmailError && (
                <p style={{ color: "red", marginTop: "0" }}>
                  Please Provide email to reset the password.
                </p>
              )}
              <button className="link" autoFocus type="submit" title="Sign In">
                Send Link
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="login-page">
        <div className="bg-box"></div>
        <div className="form">
          <img src={logo} alt="Training Room Logo" />
          <h4>Login</h4>
          <p>Enter your username and password to login our dashboard.</p>
          {loginError && <Alert variant="danger">{loginError}</Alert>}
          {m && <Alert variant="danger">{m}</Alert>}
          <form onSubmit={handleLoginSubmit}>
            <h6>Username</h6>
            <Input
              type="text"
              name="email-field"
              placeholder="username"
              value={email}
              onChange={handleEmailChange}
            />
            <h6>Password</h6>
            <Input
              type="password"
              name="password-field"
              placeholder="Enter password"
              value={password}
              onChange={handlePasswordChange}
            />
            {validationError && <p style={{color:"red",fontSize:"0.7rem"}}>Please Provide Username and Password</p>}
            <button className="link" autoFocus type="submit" title="Sign In">
              Sign In
            </button>
          </form>
          {
              isOktaActive ? 
                <button className="link" autoFocus type="submit" title="Sign In"
                    onClick= {oktaLoginRedirect}
                >
                  Login with {saml}
            </button>
              : null
            }
          {/* commented out by Sameer Maini till we add forgot password functionality */}
          <button
            onClick={() => {
              dispatch(setLoginError(""));
              setIsForgotPassword(true);
            }}
            className="btn-link-a"
          >
            Forget Password?
          </button>
        </div>
      </div>
      {/* <ModalComponent>
        <Modal.Header closeButton>
          <Modal.Title>Forget Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <h6>User Id / Email Id</h6>
            <input type="text" name="" placeholder="Email Id." />
            <button className="link mt-3">Submit</button>
          </form>
        </Modal.Body>
      </ModalComponent> */}
    </>
  );
};

export default Login;
