import { Dispatch } from "react";
import { getPersonalization, savePersonalization } from "../services/chatEnhancement";
import { setLoading } from "./loaderActions";
import {
  GET_PERSONALIZATION_SUCCESS,
  GET_PERSONALIZATION_FAILURE,
  SAVE_PERSONALIZATION_SUCCESS,
  SAVE_PERSONALIZATION_FAILURE,
} from "./actionTypes";
import { toast } from "react-toastify";

export const savePersonalizationAction = (specification: any) => {
  return async (dispatch: Dispatch<any>) => {
    setLoading(true);
    try {
      const response = await savePersonalization(specification);
      if (response.status) {
        dispatch(saveChatSpecificationSuccess(response.status,""));
        toast(response.message);
      } else {
        dispatch(
          saveChatSpecificationFailure(
            response.status,
            "Cannot Save Specification"
          )
        );
      }
    } catch (err: any) {
      dispatch(saveChatSpecificationFailure(false, err.message));
    } finally {
      setLoading(false);
    }
  };
};

export const getPersonalizationAction = (username: any) => {
  return async (dispatch: Dispatch<any>) => {
    setLoading(true);
    try {
      const response = await getPersonalization(username);
      if (response.status) {
        dispatch(
          getChatSpecificationSuccess(response.status, response.settings)
        );
      } else {
        dispatch(
          getChatSpecificationFailure(
            response.status,
            "Cannot Save Specification"
          )
        );
      }
    } catch (err: any) {
      dispatch(getChatSpecificationFailure(false, err.message));
    } finally {
      setLoading(false);
    }
  };
};

const saveChatSpecificationSuccess = (status:boolean,message:string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
          type: SAVE_PERSONALIZATION_SUCCESS,
          payload: {
            status,
            message,
          },
        });
      };
};
const saveChatSpecificationFailure = (status:boolean,message:string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
          type: SAVE_PERSONALIZATION_FAILURE,
          payload: {
            status,
            message,
          },
        });
      };
};
export const getChatSpecificationSuccess = (status: boolean, settings: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_PERSONALIZATION_SUCCESS,
      payload: {
        status,
        settings,
      },
    });
  };
};


const getChatSpecificationFailure = (status: boolean, message: string) => {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: GET_PERSONALIZATION_FAILURE,
        payload: {
          status,
          message,
        },
      });
    };
  };
  