import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import NotificationType from "./notificationType";

interface notiProps {
  username: string;
}

const Notification = (props: notiProps) => {
  const [expanded, setExpanded] = useState(false);
  const allNotification: any = useSelector(
    (state: RootState) => state.allNotification
  );
  return (
    <article className="notification_article">
      <header className="notification_article_header">
        <h4
          onClick={() => setExpanded(!expanded)}
          className="notification_article-title"
        >
          {(allNotification?.notiUserfullname?.[props.username]?.fullname ? allNotification?.notiUserfullname?.[props.username]?.fullname : props.username)?.toUpperCase()}
        </h4>
        <button className="btn_noti" onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <FontAwesomeIcon icon={faMinus} />
          ) : (
            <FontAwesomeIcon icon={faPlus} />
          )}
        </button>
      </header>
      {expanded &&
        Object.keys(allNotification.notifications[props.username]).map(
          (value: string, index: number) => {
            return (
              <NotificationType
                key={value}
                notiType={value}
                username={props.username}
              />
            );
          }
        )}
    </article>
  );
};

export default Notification;
