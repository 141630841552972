import React from 'react';

interface AvatarProps {
  imageUrl?: string;
  name?: string; //
  size?: number;
  alt?: string;
  backgroundColor?: string;
  style?:any;
}

const getRandomColor = (): string => {

  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};


const getInitials = (name: string): string => {
  const words = name.split(' ').slice(0, 2);;
  return words.map((word) => word.charAt(0).toUpperCase()).join('');
};


const Avatar: React.FC<AvatarProps> = ({ imageUrl, name, size = 40, alt = 'Avatar', backgroundColor,style }) => {
  const avatarBackgroundColor = backgroundColor || getRandomColor();

  if (imageUrl) {
    return (
      <img
        src={imageUrl}
        alt={alt}
        style={{ width: size, height: size, borderRadius: '50%', backgroundColor: avatarBackgroundColor }}
      />
    );
  }

  if (name) {

    const displayedInitials = getInitials(name);


    return (
      <div
        style={{
          width: size,
          height: size,
          borderRadius: '50%',
          backgroundColor: '#048fd4',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#FFFFFF',
          fontSize:'0.9rem',
          ...style
        }}
      >
        {displayedInitials}
      </div>
    );
  }

  return null;
};

export default Avatar;
