import {
  SET_AUDIO_DEVICES,
  SET_VIDEO_DEVICES,
  SELECT_AUDIO_DEVICE,
  SELECT_VIDEO_DEVICE,
} from "./actionTypes";

export const setAudioDevices = (devices: MediaDeviceInfo[]) => {
  return {
    type: SET_AUDIO_DEVICES,
    payload: devices,
  };
};

export const setVideoDevices = (devices: MediaDeviceInfo[]) => {
  return {
    type: SET_VIDEO_DEVICES,
    payload: devices,
  };
};

export const setActiveAudioId = (device: string) => {
  return {
    type: SELECT_AUDIO_DEVICE,
    payload: device,
  };
};

export const setActiveVideoId = (device: string) => {
  return {
    type: SELECT_VIDEO_DEVICE,
    payload: device,
  };
};
