import {
  GET_PENDING_USERS,
  SET_VERIFICATION_SUCCESS,
  SET_VERIFICATION_FAILURE,
  GET_PENDING_USERS_FAILURE,
  SET_REJECTION_SUCCESS,
  SET_REJECTION_FAILURE
} from "../actions/actionTypes";

const initialState = {
  users: [],
  userVerificationSuccess: false,
  userVerificationFailure: false,
  userRejectionSuccess: false,
  userRejectionFailure: false,
  error: "",
};

interface IAction {
  type: string;
  payload: any;
}

const faveVerificationReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_PENDING_USERS: {
      return {
        ...state,
        users: action.payload.users,
      };
    }
    case GET_PENDING_USERS_FAILURE:{
      return {
        ...state,
        pendingUsersStatus:action.payload.status,
        error:action.payload.message
      }
    }
    case SET_VERIFICATION_SUCCESS: {
      return {
        ...state,
        userVerificationSuccess: action.payload.status,
        users: [...state.users.filter((ele:any) => !action.payload.users.includes(ele?.username))]
      };
    }

    case SET_VERIFICATION_FAILURE: {
      return {
        ...state,
        userVerificationFailure: action.payload.status,
        error: action.payload.error,
      };
    }
    case SET_REJECTION_SUCCESS: {
      return {
        ...state,
        userRejectionSuccess: action.payload.status,
        users: [...state.users.filter((ele:any) => !action.payload.users.includes(ele?.username))]
      };
    }

    case SET_REJECTION_FAILURE: {
      return {
        ...state,
        userRejectionFailure : action.payload.status,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default faveVerificationReducer;
