function computeDist(p1, p2) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

class Point {
  /**
   * @type {number}
   */
  #x;
  get x() {
    return this.#x;
  }

  /**
   * @type {number}
   */
  #y;
  get y() {
    return this.#y;
  }

  /**
   * @type {Point}
   */
  static #lastKnownPos = new Point(0, 0);
  static get lastKnownPos() {
    return Point.#lastKnownPos;
  }

  /**
   * @param {number} x
   * @param {number} y
   */
  constructor(x, y) {
    this.#x = x;
    this.#y = y;
  }

  get isZeroZero() {
    return this.#x === 0 && this.#y === 0;
  }

  /**
   * Get a Point object from an event
   * @param {event} e
   * @param {string} [idName=""]
   * @returns {Point}
   */
  static fromEvent(e, idName) {
    if (!idName) {
      idName = "mouseOverlay";
    }
    // the epsilon hack is required to detect touches
    const epsilon = 0.0001;
    let x = (e.offsetX || e.pageX - e.target.offsetLeft) + epsilon;
    let y = (e.offsetY || e.pageY - e.target.offsetTop) + epsilon;
    let elementId = document.getElementById(idName);
    if (
      Number.isNaN(x) ||
      Number.isNaN(y) ||
      (x === epsilon && y === epsilon)
    ) {
      // if it's a touch actually
      if (e.touches && e.touches.length && e.touches.length > 0) {
        const touch = e.touches[0];
        x = touch.clientX - document.getElementById(idName).offsetLeft;
        y = touch.clientY - document.getElementById(idName).offsetTop;
      } else {
        // if it's a touchend event
        return Point.#lastKnownPos;
      }
    }

    let finalX =
      x - epsilon < 5
        ? 5
        : x - epsilon >= elementId.clientWidth - 50
        ? elementId.clientWidth - 50
        : x - epsilon;

    let finalY =
      y - epsilon < 5
        ? 5
        : y - epsilon >= elementId.clientHeight - 50
        ? elementId.clientHeight - 50
        : y - epsilon;

    Point.#lastKnownPos = new Point(finalX, finalY);
    return Point.#lastKnownPos;
  }

  /**
   * Compute euclidean distance between points
   *
   * @param {Point} otherPoint
   * @returns {number}
   */
  distTo(otherPoint) {
    return computeDist(this, otherPoint);
  }
}

export default Point;
