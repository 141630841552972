import {
  inputResolutions,
  SegmentationConfig,
} from "../../helpers/segmentationHelper";
import { SourcePlayback } from "../../helpers/sourceHelper";
import { TFLite } from "../../Pages/RoomPage/interfaces";

export function buildCanvas2dPipeline(
  sourcePlayback: SourcePlayback,
  segmentationConfig: SegmentationConfig,
  canvas: HTMLCanvasElement,
  tflite: TFLite,
  addFrameEvent: () => void,
  imageLink: string,
  videoOption: string
) {
  const ctx = canvas.getContext("2d")!;

  canvas.width = sourcePlayback.width;
  canvas.height = sourcePlayback.height;

  const [segmentationWidth, segmentationHeight] =
    inputResolutions[segmentationConfig.inputResolution];
  const segmentationPixelCount = segmentationWidth * segmentationHeight;
  const segmentationMask = new ImageData(segmentationWidth, segmentationHeight);
  const segmentationMaskCanvas = document.createElement("canvas");
  segmentationMaskCanvas.width = segmentationWidth;
  segmentationMaskCanvas.height = segmentationHeight;
  const segmentationMaskCtx = segmentationMaskCanvas.getContext("2d")!;

  const inputMemoryOffset = tflite._getInputMemoryOffset() / 4;
  const outputMemoryOffset = tflite._getOutputMemoryOffset() / 4;

  async function render() {
    resizeSource();
    addFrameEvent();
    runTFLiteInference();
    addFrameEvent();
    runPostProcessing();
  }

  function cleanUp() {
    // Nothing to clean up in this rendering pipeline
  }

  function resizeSource() {
    segmentationMaskCtx.drawImage(
      sourcePlayback.htmlElement,
      0,
      0,
      sourcePlayback.width,
      sourcePlayback.height,
      0,
      0,
      segmentationWidth,
      segmentationHeight
    );

    const imageData = segmentationMaskCtx.getImageData(
      0,
      0,
      segmentationWidth,
      segmentationHeight
    );

    for (let i = 0; i < segmentationPixelCount; i++) {
      tflite.HEAPF32[inputMemoryOffset + i * 3] = imageData.data[i * 4] / 255;
      tflite.HEAPF32[inputMemoryOffset + i * 3 + 1] =
        imageData.data[i * 4 + 1] / 255;
      tflite.HEAPF32[inputMemoryOffset + i * 3 + 2] =
        imageData.data[i * 4 + 2] / 255;
    }
  }

  function runTFLiteInference() {
    tflite._runInference();

    for (let i = 0; i < segmentationPixelCount; i++) {
      const background = tflite.HEAPF32[outputMemoryOffset + i * 2];
      const person = tflite.HEAPF32[outputMemoryOffset + i * 2 + 1];
      const shift = Math.max(background, person);
      const backgroundExp = Math.exp(background - shift);
      const personExp = Math.exp(person - shift);

      // Sets only the alpha component of each pixel
      segmentationMask.data[i * 4 + 3] =
        (255 * personExp) / (backgroundExp + personExp); // softmax
    }
    segmentationMaskCtx.putImageData(segmentationMask, 0, 0);
  }

  function runPostProcessing() {
    ctx.globalCompositeOperation = "copy";
    ctx.filter = "blur(8px)"; // FIXME Does not work on Safari

    drawSegmentationMask();
    ctx.globalCompositeOperation = "source-in";
    ctx.filter = "none";

    ctx.drawImage(sourcePlayback.htmlElement, 0, 0);

    if (videoOption === "background") {
      backgroundChange();
    } else if (videoOption === "blur") {
      blurBackground();
    }
  }

  function drawSegmentationMask() {
    ctx.drawImage(
      segmentationMaskCanvas,
      0,
      0,
      segmentationWidth,
      segmentationHeight,
      0,
      0,
      sourcePlayback.width,
      sourcePlayback.height
    );
  }

  function blurBackground() {
    ctx.globalCompositeOperation = "destination-over";
    ctx.filter = "blur(30px)"; // FIXME Does not work on Safari
    // console.log('=============', sourcePlayback.htmlElement, sourcePlayback)
    ctx.drawImage(sourcePlayback.htmlElement, 0, 0);
  }

  function backgroundChange() {
    ctx.globalCompositeOperation = "destination-over";

    const image = new Image(); // Using optional size for image

    image.src = imageLink;
    image.crossOrigin = "anonymous";
    var scale = Math.max(
      canvas.width / image.width,
      canvas.height / image.height
    );
    var x = canvas.width / 2 - (image.width / 2) * scale;
    var y = canvas.height / 2 - (image.height / 2) * scale;

    ctx.drawImage(image, x, y, image.width * scale, image.height * scale);
  }

  return { render, cleanUp };
}
