import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'

// import Avatar from "react-avatar";
import Avatar from '../../components/CustomAvatar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { getChatSpecificationSuccess, getPersonalizationAction, savePersonalizationAction } from '../../actions/personalizationActions';
import Modal from 'react-bootstrap/Modal';
import { checkForSupManAdmin } from '../../utils/checkForSupAdmin';
type Options = {
    chat: {
        audio: boolean;
        toast: boolean;
        desktop: boolean;
    };
    activity: {
        audio: boolean;
        toast: boolean;
        desktop: boolean;
    };
    mentions: {
        audio: boolean;
        toast: boolean;
        desktop: boolean;
    };
    agentHandRaiseandTechsupport: {
        audio: boolean;
        toast: boolean;
        desktop: boolean;
    };
};

interface IProps {
    show: boolean;
    onHide: () => void;
}

type Category = keyof Options;

type CheckboxItemProps = {
    label: string;
    value: keyof Options[Category];
    checked: boolean;
    onChange: (value: keyof Options[Category], category: Category) => void;
};

const CheckboxItem: React.FC<CheckboxItemProps> = ({ label, value, checked, onChange }) => {
    return (
        <div>
            <input
                type="checkbox"
                value={value as string}
                checked={checked}
                onChange={() => onChange(value, 'chat')}
            />
            <p>{label}</p>
        </div>
    );
};

const UserPersonalization = (props: IProps) => {

    const dispatch = useDispatch();
    const settings = useSelector((state: RootState) => state.personalizationReducer.settings?.settings)
    const userName = useSelector((state: RootState) => state.userDetails.userName)
    const firstName = useSelector((state: RootState) => state.userDetails.firstName)
    const lastName = useSelector((state: RootState) => state.userDetails.lastName)
    const currentUserRole = useSelector((state: RootState) => state.userDetails.role)
    const [options, setOptions] = useState<Options>({
        chat: {
            audio: false,
            toast: false,
            desktop: false,
        },
        activity: {
            audio: false,
            toast: false,
            desktop: false,
        },
        mentions: {
            audio: false,
            toast: false,
            desktop: false,
        },
        agentHandRaiseandTechsupport: {
            audio: false,
            toast: false,
            desktop: false,
        },
    });




    useEffect(() => {
        dispatch(getPersonalizationAction(userName));

        return () => {
            dispatch(getChatSpecificationSuccess(true, {}));
        }
    }, [dispatch, userName]);


    useEffect(() => {
        if (settings) {
            setOptions( (prev)=>{
                return {...prev, ...settings}
            });
        }
    }, [settings])

    const handleCheckboxChange = (option: keyof Options[Category], category: Category) => {
        setOptions({
            ...options,
            [category]: {
                ...options[category],
                [option]: !options[category][option],
            },
        });
    };



    const handleSubmit = (e: any) => {
        const specifications = {
            username: userName,
            settings: options
        }
        dispatch(savePersonalizationAction(specifications))
        props.onHide();
    }
    return (
        <div>
            {/*     <Header /> */}
            <Modal
                {...props}

                className={styles.modal_cont}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header 
                  style={{ position: "relative" }}
                >
                    <button className="modal-close-btn" onClick={props.onHide}>
                        X
                    </button>
                    <Modal.Title
                        style={{ color: "black", fontSize: "1.4rem", fontWeight: "500", fontFamily: "sans-serif  " }}
                    >
                        User Personalization
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.modal_body}>
                    <div className={styles.container}>
                        <div className={styles.left_container}>
                            <Avatar name={userName} size={60} />
                            <p>{`${firstName} ${lastName}(${userName})`}</p>
                        </div>
                        <div className={styles.right_container}>
                            <div className={styles.right_content_container}>
                                <div>
                                    <h4>Chat Specification</h4>
                                    <div className={styles.selector_container}>
                                        <CheckboxItem
                                            label="Audio"
                                            value="audio"
                                            checked={options.chat.audio}
                                            onChange={(value, category) => handleCheckboxChange(value, 'chat')}
                                        />
                                        <CheckboxItem
                                            label="Visual Notification"
                                            value="toast"
                                            checked={options.chat.toast}
                                            onChange={(value, category) => handleCheckboxChange(value, 'chat')}
                                        />
                                        <CheckboxItem
                                            label="Desktop Notification"
                                            value="desktop"
                                            checked={options.chat.desktop}
                                            onChange={(value, category) => handleCheckboxChange(value, 'chat')}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <h4>Activity Channel</h4>
                                    <div className={styles.selector_container}>
                                        <CheckboxItem
                                            label="Audio"
                                            value="audio"
                                            checked={options.activity.audio}
                                            onChange={(value, category) => handleCheckboxChange(value, 'activity')}
                                        />
                                        <CheckboxItem
                                            label="Visual Notification"
                                            value="toast"
                                            checked={options.activity.toast}
                                            onChange={(value, category) => handleCheckboxChange(value, 'activity')}
                                        />
                                        <CheckboxItem
                                            label="Desktop Notification"
                                            value="desktop"
                                            checked={options.activity.desktop}
                                            onChange={(value, category) => handleCheckboxChange(value, 'activity')}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <h4>Mentions</h4>
                                    <div className={styles.selector_container}>
                                        <CheckboxItem
                                            label="Audio"
                                            value="audio"
                                            checked={options.mentions.audio}
                                            onChange={(value, category) => handleCheckboxChange(value, 'mentions')}
                                        />
                                        <CheckboxItem
                                            label="Visual Notification"
                                            value="toast"
                                            checked={options.mentions.toast}
                                            onChange={(value, category) => handleCheckboxChange(value, 'mentions')}
                                        />
                                        <CheckboxItem
                                            label="Desktop Notification"
                                            value="desktop"
                                            checked={options.mentions.desktop}
                                            onChange={(value, category) => handleCheckboxChange(value, 'mentions')}
                                        />
                                    </div>
                                </div>
                                {checkForSupManAdmin(currentUserRole, "equalTo") && <div>
                                    <h4>Agent Hand Raise and Technical Issue</h4>
                                    <div className={styles.selector_container}>
                                        <CheckboxItem
                                            label="Audio"
                                            value="audio"
                                            checked={options?.agentHandRaiseandTechsupport.audio}
                                            onChange={(value, category) => handleCheckboxChange(value, 'agentHandRaiseandTechsupport')}
                                        />
                                        <CheckboxItem
                                            label="Visual Notification"
                                            value="toast"
                                            checked={options?.agentHandRaiseandTechsupport?.toast}
                                            onChange={(value, category) => handleCheckboxChange(value, 'agentHandRaiseandTechsupport')}
                                        />
                                        <CheckboxItem
                                            label="Desktop Notification"
                                            value="desktop"
                                            checked={options?.agentHandRaiseandTechsupport?.desktop}
                                            onChange={(value, category) => handleCheckboxChange(value, 'agentHandRaiseandTechsupport')}
                                        />
                                    </div>
                                </div>}
                            </div>

                            <button className={styles.submit_btn} onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default UserPersonalization