import {
  SET_MESSAGE_LIST,
  SET_TYPER_NAME,
  SET_FULL_MESSAGE_LIST,
 SET_SELECTED_CHAT_USER,
  PREPEND_MESSAGE_LIST,
  CLEAR_CHAT_DATA,
  SET_RECONNECTION_STATUS_FOR_CHAT
} from "./actionTypes";
import { Dispatch } from "react";

export const setMessageList = (messageList: any) => {
  return {
    type: SET_MESSAGE_LIST,
    payload: messageList,
  };
};

export const clearChatData = () => {
  return {
    type: CLEAR_CHAT_DATA,
  };
};

export const setFullMessageList = (messageList: any[]) => {
  return {
    type: SET_FULL_MESSAGE_LIST,
    payload: messageList,
  };
};

export const setReconnectioStatusForChat = (status: boolean) => {
  return {
    type: SET_RECONNECTION_STATUS_FOR_CHAT,
    payload: status,
  };
};

export const setTyperName = (typerName: {}) => {
  return {
    type: SET_TYPER_NAME,
    payload: typerName,
  };
};





export const setSelectedChatUser = (
  peerSessionId: string | null,
  peerUserName: string | null,
  peerName: string | null,
  chatType: string
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_SELECTED_CHAT_USER,
      payload: { peerSessionId, peerUserName, peerName, chatType },
    });
  };
};

export const prependMessageList = (messageList: any) => {
  return {
    type: PREPEND_MESSAGE_LIST,
    payload: messageList,
  };
};


