import {
    SAVE_LAYOUT_SETTING_FAILURE,
    SAVE_LAYOUT_SETTING_SUCCESS,
    GET_LAYOUT_SETTING_SUCCESS,
    GET_LAYOUT_SETTING_FAILURE,
    CLEAR_LAYOUT_SETTING
} from '../actions/actionTypes'

const initialState = {
    layoutSettings : {},
    isLayoutSettingSaved:false,
    saveLayoutError:"",
    getLayoutError:""
}

interface ActionDataType{
    type:string;
    payload:any;
}

const settingLayoutReducer = (state=initialState,action:ActionDataType) => {
    switch (action.type){
        case GET_LAYOUT_SETTING_SUCCESS : {
                return {
                    ...state,
                    layoutSettings:[...action.payload][0]
                }
        }

        case SAVE_LAYOUT_SETTING_SUCCESS:{
            return {
                ...state,
                isLayoutSettingSaved: action.payload
            }
        }

        case GET_LAYOUT_SETTING_FAILURE:{
            return {
                ...state,
                getLayoutError: action.payload
            }
        }

        case SAVE_LAYOUT_SETTING_FAILURE:{
            return {
                ...state,
                saveLayoutError: action.payload
            }
        }

        case CLEAR_LAYOUT_SETTING:{
            return{
                ...state,
                layoutSettings:{}
            }
        }
        
        default:{
            return state;
        }
    }
}

export default settingLayoutReducer;