import { Dispatch } from "react";
import { SET_CONTROL_ID, CLEAR_CONTROL_ID } from "./actionTypes";

export const setControlId = (peerId: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_CONTROL_ID,
      payload: { peerId },
    });
  };
};

export const clearControlId = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: CLEAR_CONTROL_ID,
    });
  };
};
