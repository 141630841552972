import { SET_CONTROL_ID,CLEAR_CONTROL_ID,CLEAR_REDUX_DATA } from "../actions/actionTypes";

const initialState = {
  openentPeerId:"",
};
interface IAction {
  type: string;
  payload: any;
}

const screenControlReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_CONTROL_ID: {
      return {
        ...state,
        openentPeerId:action.payload.peerId,
      };
    }
    case CLEAR_CONTROL_ID: {
      return initialState
    }
    case CLEAR_REDUX_DATA:{
      return initialState
    }
    default: {
      return state;
    }
  }
};

export default screenControlReducer;
