import request from "./index";
import qs from "querystring";

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getBackgroundActivate = async (username: string) => {
  const reqBody = qs.stringify({
    username,
  });
  const backgroundActivateData = await request.post(
    "/getBackgroundActivate",
    reqBody,
    config
  );

  return backgroundActivateData.data;
};

export const setBackgroundActivate = async (
  username: string,
  backgroundType: string,
  url: string
) => {
  const reqBody = qs.stringify({
    username,
    backgroundType,
    url,
  });
  const backgroundActivateData = await request.post(
    "/setBackgroundActivate",
    reqBody,
    config
  );
  return backgroundActivateData.data;
};
