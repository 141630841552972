import React from "react";

const AudioFile = (props: any) => {
  return (
    <>
      <audio controls id="audiosss">
        <source src={props.blob}></source>
      </audio>
    </>
  );
};

export default AudioFile;
