import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import moment from 'moment';
import { timeZoneFormat } from "../../utils/datetimeformat";
import { getMinuteDiffFromTwoTimeStamps } from "../../utils/getMinutesDifferenceFromTime";

interface notiTypeProps {
  notiType: string;
  username: string;
}

interface notifications {
  numfaces: number;
  startTime: number;
  endTime: number;
  msg: string;
  username: string;
  durType: boolean;
  fullname:string;
}

const NotificationType = (props: notiTypeProps) => {
  const {format} = timeZoneFormat();
  const [expanded, setExpanded] = useState(false);
  const allNotification: any = useSelector(
    (state: RootState) => state.allNotification
  );
  return (
    <article className="notification_article">
      <header className="notification_article_header">
        <h4
          onClick={() => setExpanded(!expanded)}
          className="notification_article-title"
        >
          {props.notiType}
        </h4>
        <button className="btn_noti" onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <FontAwesomeIcon icon={faMinus} />
          ) : (
            <FontAwesomeIcon icon={faPlus} />
          )}
        </button>
      </header>
      {expanded &&
        allNotification.notifications[props.username][props.notiType].map(
          (value: notifications, index: number) => {
            let startTime = new Date(value.startTime);
            console.log({startTime})
            return (
              <div key={index} className="sec new">
                <div className="txt">{value.msg}</div>
                <div className="txt sub">
                  <h6>{(value?.fullname ? value?.fullname : value.username)?.toUpperCase()}</h6>
                  <div>

                    {moment(startTime).format(format)}
                    {value.durType ? (
                      <>
                        {" "}
                        dur{" "}
                        {value.endTime > 0
                          ?
                          getMinuteDiffFromTwoTimeStamps(value.startTime, value.endTime)
                          : 0 }
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          }
        )}
    </article>
  );
};

export default NotificationType;
