import {
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_COUNT
} from "../actions/actionTypes";

const initialState = {
  count: 0,
  isGotNotificationSuccess: false,
};

interface IAction {
  type: string;
  payload: any;
}

const verifyNotificationReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case GET_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        count: action.payload.count,
        isGotNotificationSuccess: action.payload.success,
      };
    }

    case GET_NOTIFICATION_FAILURE: {
      return {
        ...state,
        count: 0,
        isGotNotificationSuccess: false,
      };
    }

    case UPDATE_NOTIFICATION_COUNT:{
      return{
        ...state,
        count:action.payload.count
      }
    }

    default:{
        return state;
    }
  }
};

export default verifyNotificationReducer;