import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import "./style.css";

interface Props {
  id: string;
  view: string;
}

const AudioVolumeMeter: React.FC<Props> = (props) => {
  // @ts-ignore
  const volume = useSelector((state: RootState) => state.peerVolume[props.id]);
  let threshold = -50;

  if (!volume) {
    return <></>;
  }

  let level: number;
  if (volume <= threshold) {
    level = 5;
  } else {
    level = Math.floor(Math.abs(volume) / 10);
  }

  let getClassName = (): string => {
    let classString =
      level <= 1
        ? "meter-5"
        : level <= 2
        ? "meter-4"
        : level <= 3
        ? "meter-3"
        : level <= 4
        ? "meter-2"
        : "";
    return classString;
  };

  let getHeight = (getColRow: string, bar: string): string => {
    let hei = "10%";

    if (level <= 1) {
      hei = getColRow === "col" ? "70%" : bar === "second" ? "40%" : "30%";
    } else if (level <= 2) {
      hei = getColRow === "col" ? "50%" : bar === "second" ? "30%" : "25%";
    } else if (level <= 3) {
      hei = getColRow === "col" ? "40%" : bar === "second" ? "25%" : "17%";
    } else if (level <= 4) {
      hei = getColRow === "col" ? "30%" : bar === "second" ? "20%" : "12%";
    }

    return hei;
  };

  return (
    <div
      className="audio-progress"
      style={
        props.view === "col"
          ? {
              flexDirection: "row",
              position: "absolute",
              right: "5px",
              bottom: "24%",
              height: "50%",
            }
          : { flexDirection: "row", height: "25px" }
      }
    >
      <div
        className={`meter-container ${getClassName()}`}
        style={{ height: getHeight(props.view, "first") }}
      ></div>

      {props.view === "row" ? (
        <>
          <div
            className={`meter-container ${getClassName()}`}
            style={{ height: getHeight(props.view, "second") }}
          ></div>
          <div
            className={`meter-container ${getClassName()}`}
            style={{ height: getHeight(props.view, "third") }}
          ></div>
        </>
      ) : null}
    </div>
  );
};

export default AudioVolumeMeter;
