import "./styles.css";
import PlusIcon from "../../assets/images/plus-icon.svg";
import { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import ModalComponent from "./../../components/Modal";
import { OPEN_MODAL } from "../../actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { addRoom } from "../../actions/roomActions";
import { RootState } from "../../redux";

const AddRoomCard = () => {
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => state.userDetails.role);

  const openAddRoomModal = () => {
    dispatch({
      type: OPEN_MODAL,
    });
};

  const [roomName, setRoomName] = useState("");
  const [maxParticipants, setMaxParticipants] = useState(0);
  // const [startDate, setStartDate] = useState("");
  // const [duration, setDuration] = useState("");

  const handleAddRoom = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(addRoom(roomName, maxParticipants));
  };

  const handleRoomNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoomName(e.target.value);
  };

  const handleMaxParticipantsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaxParticipants(Number(e.target.value));
  };

  if (role === "Disabled") {
    return (
      <Fragment>
        <li>
          <div className="box add cursor-pointer" onClick={openAddRoomModal}>
            <div className="center">
              <img src={PlusIcon} alt="plus" />
              <h4>Add New Room</h4>
            </div>
          </div>
        </li>
        <ModalComponent>
          <Modal.Header closeButton>
            <Modal.Title>Add New Room</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddRoom}>
              <h6>Room Name</h6>
              <input
                type="text"
                name="room-name"
                placeholder="Room Name"
                value={roomName}
                onChange={handleRoomNameChange}
                pattern="[^\s]+"
              />
              <h6>Max. Number of Participants</h6>
              <input
                type="number"
                name="max-participants"
                placeholder=""
                value={maxParticipants}
                onChange={handleMaxParticipantsChange}
                min={5}
                max={100}
              />

              <button className="link mt-3" type="submit">
                Submit
              </button>
            </form>
          </Modal.Body>
        </ModalComponent>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default AddRoomCard;
