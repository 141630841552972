import {
  SET_AUDIO_DEVICES,
  SET_VIDEO_DEVICES,
  SELECT_AUDIO_DEVICE,
  SELECT_VIDEO_DEVICE,
  CLEAR_REDUX_DATA
} from "../actions/actionTypes";

const initialState = {
  videoDevices: [],
  audioDevices: [],
  activeAudioDevice: "",
  activeVideoDevice: "",
};
interface IAction {
  type: string;
  payload: any;
}

const deviceReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_AUDIO_DEVICES: {
      return {
        ...state,
        audioDevices: action.payload,
      };
    }
    case SET_VIDEO_DEVICES: {
      return {
        ...state,
        videoDevices: action.payload,
      };
    }
    case SELECT_AUDIO_DEVICE: {
      return {
        ...state,
        activeAudioDevice: action.payload,
      };
    }
    case SELECT_VIDEO_DEVICE: {
      return {
        ...state,
        activeVideoDevice: action.payload,
      };
    }
    case CLEAR_REDUX_DATA:{
      return initialState
    }
    default: {
      return state;
    }
  }
};

export default deviceReducer;
