import "./launchApp.css";

const DownloadApp = (props: any) => {
  const downloadScreenControlApp = () => {
    window.location.href =
      "https://collaborationcdn.nyc3.cdn.digitaloceanspaces.com/Remote/Collaborationroom-Setup-3.5.0.exe";
    setTimeout(() => window.close(), 1000);
  };

  return (
    <>
      <div className="Download_app_page">
        <div className="download_header">
          <h1>
            Welcome to{" "}
            <b style={{ textDecoration: "underline" }}>CollaborationRoom</b>
          </h1>
        </div>
        <div className="download_msg">
          <h4>Click On Download To Download app</h4>
          <h6>If you have alreday installed Please Click on close button</h6>
        </div>
        <div className="download_form">
          <button
            className="link"
            onClick={downloadScreenControlApp}
            title="Download App"
          >
            Download
          </button>

          <button className="link" onClick={() => window.close()} title="Close">
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default DownloadApp;
