import {
  SET_REGISTRATION_FAILURE,
  SET_REGISTRATION_SUCCESS,
  SET_REGISTRATION_STATUS_SUCCESS,
  SET_REGISTRATION_STATUS_FAILURE,
  SET_REJECTED_STATUS_SUCCESS,
  SET_REJECTED_STATUS_FAILURE,
  SET_REGISTER_COUNT
} from "../actions/actionTypes";

const initialState = {
  isRegistered: false,
  registrationSuccess: "",
  registrationFailure: "",
  registrationError:"",
  isRejected:"",
  falseRegisterCount:0
};

interface IAction {
  type: string;
  payload: any;
}

const faceRegistrationReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_REGISTRATION_STATUS_SUCCESS: {
      return {
        ...state,
        isRegistered: action.payload.isRegistered,
      };
      
    }

    case SET_REGISTRATION_STATUS_FAILURE: {
      return {
        ...state,
        isRegistered: action.payload.isRegistered,
        registrationError: action.payload.message,
      };
    }

    case SET_REGISTRATION_SUCCESS: {
      return {
        ...state,
        registrationSuccess: action.payload.status,
        isRegistered: action.payload.is_registered
      };
    }

    case SET_REGISTRATION_FAILURE: {
      return {
        ...state,
        registrationFailure: action.payload,
      };
      
    }
    case SET_REJECTED_STATUS_SUCCESS:{
      return{
        ...state,
        isRejected:action.payload.isRejected
      }
    }
    case SET_REJECTED_STATUS_FAILURE:{
      return{
        ...state,
        isRejected:action.payload.isRejected,
        registrationError:action.payload.message
      }
    }

    case SET_REGISTER_COUNT:{
      return{
        ...state,
        falseRegisterCount:action.payload.count
      }
    }
    default:{
        return state;
    }
  }
};
export default faceRegistrationReducer;
