import request from "./index";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getUserDetails = async (roomname: any = null) => {
  const requestBody = JSON.stringify({
    room: roomname,
  });

  const userDetailsResponse = await request.post(
    "/getUserDetails",
    requestBody,
    config
  );
  return userDetailsResponse.data;
};

export const getToken = async (geoLocationDetails:any) => { 
  const requestBody = JSON.stringify(geoLocationDetails);
  const tokenResponse = await request.post('/getToken', requestBody, config);
  return tokenResponse.data;
}