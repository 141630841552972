import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App/App";
// import reportWebVitals from './reportWebVitals';
import { createReduxStore } from "./redux";
import { Provider } from "react-redux";
import ErrorBoundary from "./components/ErrorBoundary";
import serviceWorker from "./serviceWorker";

const store = createReduxStore();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

serviceWorker();
