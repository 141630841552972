import { Dispatch } from "react";
import { SET_VIDEO_OPTION } from "./actionTypes";

export const setVideoOption = (videoOption: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_VIDEO_OPTION,
      payload: videoOption,
    });
  };
};
