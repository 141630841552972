import { Dispatch } from "react";
import {
  ADD_COLLAB_SCREEN_PEER,
  REMOVE_COLLAB_SCREEN_PEER,
  REMOVE_COLLAB_SCREEN_PEER_FROM_ARRAY,
  EMPTY_COLLAB_DATA,
  ADD_COLLAB_SCREEN_PEER_FROM_ARRAY,
  ADD_COLLAB_DATA_ON_SOCKET_CONNECTED,
} from "../actions/actionTypes";
// import { toast } from "react-toastify";

export const addCollabScreenPeer = (peerId: string, peerUsername: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ADD_COLLAB_SCREEN_PEER,
      payload: { peerId, peerUsername },
    });
    return Promise.resolve();
  };
};

export const addCollabScreenPeerFromArray = (
  peerId: string,
  peerUsername: string
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ADD_COLLAB_SCREEN_PEER_FROM_ARRAY,
      payload: { peerId, peerUsername },
    });
    return Promise.resolve();
  };
};

export const addAllCollabScreenPeerWhileConnected = (
  scrrenPeer: Array<{ peerId: string; peerUsername: string }>
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: ADD_COLLAB_DATA_ON_SOCKET_CONNECTED,
      payload: scrrenPeer,
    });
  };
};

export const removeCollabScreenPeer = (peerId: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: REMOVE_COLLAB_SCREEN_PEER,
      payload: { peerId },
    });
    return Promise.resolve();
  };
};

export const removeCollabScreenPeerFromArray = (peerId: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: REMOVE_COLLAB_SCREEN_PEER_FROM_ARRAY,
      payload: { peerId },
    });
    return Promise.resolve();
  };
};

export const clearCollabScreenData = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: EMPTY_COLLAB_DATA,
    });
  };
};
