import io from "socket.io-client";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { socketConnectiontype } from "../utils/constant";
import { toast } from "react-toastify";
import { notifyMe } from "../utils/desktopNotification";

const callSocketOptions = {
  reconnection: false,
  // reconnectionDelay: 500,
  // reconnectionAttempts: 20,
  // reconnectionDelayMax: 5000,
  // randomizationFactor: 0.2,
  // timeout: 2000,
  rememberUpgrade: true,
  transports: ["websocket"],
} as SocketIOClient.ConnectOpts;

class SocketService {
  messageObservable: BehaviorSubject<string>;
  socket: SocketIOClient.Socket | undefined;
  intervalData: any;
  ifReconnect: boolean = false;
  disconnectSocketId: string = "";
  socketTypeName: string;
  attemptNo = 0;
  isReconnectionOnce: boolean = false;
  allConnectData = {
    hostName: "",
    serverIpHash: "",
    payload: "",
    iv: "",
    digest: "",
    viewMode: "",
    isWhiteboard: false,
    screenShare: false,
  };
  constructor(socketType: string) {
    this.messageObservable = new BehaviorSubject(
      JSON.stringify({ type: "DEFAULT" })
    );

    this.socketTypeName = socketType;
  }

  getRandomString() {
    return Math.random().toString(36).substring(2, 15);
  }

  async isOnline() {
    if (!window.navigator.onLine) return false;

    // avoid CORS errors with a request to your own origin
    const url = new URL(window.location.origin);

    // random value to prevent cached responses
    url.searchParams.set("rand", this.getRandomString());

    try {
      const response = await fetch(url.toString(), { method: "HEAD" });

      return response.ok;
    } catch {
      return false;
    }
  }

  reconnectFlushState = () => {
    this.isReconnectionOnce = false;

    if (this.intervalData) {
      clearInterval(this.intervalData);
    }
    this.ifReconnect = false;
    this.disconnectSocketId = "";

    this.attemptNo = 0;
  };

  connectAfterOffline = async () => {
    this.attemptNo++;

    if (this.attemptNo >= 10) {
      this.attemptNo = 0;
      toast("You lost connection, please re-enter the room");
      window.location.href = "/dashboard";
    }
    const checkOnline = await this.isOnline();
    if (checkOnline && !this.isReconnectionOnce) {
      this.isReconnectionOnce = true;
      this.connectToSocket(
        this.allConnectData.hostName,
        this.allConnectData.serverIpHash,
        this.allConnectData.payload,
        this.allConnectData.iv,
        this.allConnectData.digest,
        this.allConnectData.viewMode,
        this.allConnectData.isWhiteboard,
        this.allConnectData.screenShare,
        this.disconnectSocketId,
        true
      );

      clearInterval(this.intervalData);
      this.ifReconnect = true;
      this.attemptNo = 0;
    }
  };

  disconnectEventCheck = () => {
    if (this.socketTypeName === socketConnectiontype.media) {
      if (this.intervalData) {
        clearInterval(this.intervalData);
      }
      console.log(this.disconnectSocketId, "####");

      this.closeSocketConnection();

      this.intervalData = setInterval(() => {
        this.connectAfterOffline();
      }, 5000);
    }
  };

  connectToSocket = (
    hostName: string,
    serverIpHash: string,
    payload: string,
    iv: string,
    digest: string,
    viewMode: string = "",
    isWhiteboard: boolean = false,
    screenShare: boolean = false,
    reconnectSocketId: string = "",
    isReconnected: boolean = false
  ) => {
    callSocketOptions.query = {
      payload,
      iv,
      digest,
      viewMode,
      isWhiteboard,
      screenShare,
      reconnectSocketId,
      isReconnected,
    };

    this.allConnectData = {
      hostName,
      serverIpHash,
      payload,
      iv,
      digest,
      viewMode,
      isWhiteboard,
      screenShare,
    };
    callSocketOptions.path = "/TrainingRoomSocketServer/" + serverIpHash;
    this.socket = io(hostName, callSocketOptions);

    this.socket.on("connect", () => {
      console.log("SOCKET connection is established");
    });

    this.socket.on("disconnect", (reason: string, details: any) => {
      console.log(
        "####",
        reason,
        this.socketTypeName,
        details,
        this.socket?.connected,
        this.socket,
        "socketcheck"
      );
      this.isReconnectionOnce = false;

      if (reason === "transport close") {
        // toast("Your internet is disconnected, reconnecting.....");
        // notifyMe(`Your internet is disconnected, reconnecting.....`);
        this.disconnectEventCheck();
      } else if (reason === "io server disconnect") {
        this.messageObservable.next(
          JSON.stringify({
            type: "error",
            message: "socket disconnect from server",
          })
        );
        console.log("disconnected from server");
      }
      if (reason === "io client disconnect") {
        this.messageObservable.next(
          JSON.stringify({
            type: "error",
            message: "socket disconnect from client",
          })
        );
        console.log("disconnected from client");
      }
      if (reason === "ping timeout") {
        this.ifReconnect = true;
        console.log("ping timeout");
      }

      // if (reason === "transport close") {
      //   // toast("Your internet is disconnected, reconnecting.....");
      //   // notifyMe(`Your internet is disconnected, reconnecting.....`);

      //   this.disconnectEventCheck();
      // } else if (reason === "ping timeout") {
      //   // toast("lost connection,reconnect.....");
      //   // notifyMe(`lost connection, reconnecting.....`);
      //   console.log("ping timeout");
      //   this.disconnectEventCheck();
      // } else if (reason === "io server disconnect") {
      //   this.messageObservable.next(
      //     JSON.stringify({
      //       type: "error",
      //       message: "socket disconnect from server",
      //     })
      //   );
      //   console.log("disconnected from server");
      // } else if (reason === "io client disconnect") {
      //   this.messageObservable.next(
      //     JSON.stringify({
      //       type: "error",
      //       message: "socket disconnect from client",
      //     })
      //   );
      //   console.log("disconnected from client");
      // } else {
      //   // toast("Your connection is lost from room please exit and re-enter in room again");
      //   // notifyMe(`Your connection is lost from room please exit and re-enter in room again`);
      //   console.log(
      //     "Your connection is lost from room please exit and re-enter in room again"
      //   );
      // }
    });

    this.socket.on("error", (error: any) => {
      console.error("####Socket error:", error);
    });
    this.socket.on("ping", () => {
      console.error("####ping called");
    });

    this.socket.on("reconnect", () => {
      console.log("####reconnected successfully");
      this.messageObservable.next(JSON.stringify({ type: "RECONNECTED" }));
    });
    this.socket.on("reconnecting", (attemptNumber: number) => {
      console.log("####reconnecting successfully", attemptNumber);
      this.messageObservable.next(JSON.stringify({ type: "RECONNECTED" }));
    });
    this.socket.on("reconnect_attempt", (attemptNumber: number) => {
      console.log("####now reconnect_attempt", attemptNumber);
      if (attemptNumber >= 20) {
        this.messageObservable.next(
          JSON.stringify({ type: "MAX_RECONNECTION_ATTEMPT_REACHED" })
        );
      } else {
        this.messageObservable.next(JSON.stringify({ type: "RECONNECTING" }));
      }
    });

    this.socket.on("reconnect_error", () => {
      console.log("####reconnect_error successfully");
      // this.messageObservable.next(JSON.stringify({ type: "RECONNECTED" }));
    });

    this.socket.on("reconnect_failed", () => {
      console.log("####reconnect_failed successfully");
      // this.messageObservable.next(JSON.stringify({ type: "RECONNECTED" }));
    });
    this.socket.on("onMessage", (messageRcvd: string) => {
      console.log(`Message in onMessage Hook ${messageRcvd}`);
      // const message = JSON.parse(messageRcvd);
      this.messageObservable.next(messageRcvd);
      this.messageObservable.next(JSON.stringify({ type: "DEFAULT" }));
    });

    this.socket.on("connect_error", (error: Error) => {
      console.log(error);
      this.messageObservable.next(
        JSON.stringify({
          type: "error",
          message: "socket connect error",
        })
      );
    });
    this.socket.on("connect_timeout", (timeout: string) => {
      console.log(timeout);
    });
  };

  timeoutCallback = (callback: Function) => {
    let called = false;

    const interval = setTimeout(() => {
      if (called) return;
      called = true;
      callback("", new Error("Request timed out"));
    }, 15000);

    return (...args: any) => {
      if (called) return;
      called = true;
      clearTimeout(interval);

      callback(...args);
    };
  };

  _sendMessage = (eventType: string, dataObject: Record<string, unknown>) => {
    return new Promise((resolve, reject) => {
      this.socket?.emit(
        eventType,
        JSON.stringify(dataObject),
        this.timeoutCallback((response: string, err: Error) => {
          if (err) reject(err);
          else {
            console.log(`data recvd for ${eventType} is: ${response}`);
            resolve(JSON.parse(response));
          }
        })
      );
    });
  };

  sendMessage = async (
    eventType: string,
    dataObject: Record<string, unknown>
  ) => {
    console.log(
      `data sent for event type: ${eventType}, dataObject: ${JSON.stringify(
        dataObject
      )}`
    );
    const requestRetries = 3;
    if (
      true ||
      eventType === "FACE_DETECTED" ||
      eventType === "WHITEBOARD_ACTION" ||
      eventType === "REMOVE_PEER"
    ) {
      return new Promise((resolve) => {
        this.socket?.emit(
          eventType,
          JSON.stringify(dataObject),
          (dataRcvd: string) => {
            if (dataRcvd) {
              console.log(`data recvd is: ${dataRcvd}`);
              resolve(JSON.parse(dataRcvd));
            }
          }
        );
      });
    }
    for (let tries = 0; tries < requestRetries; tries++) {
      try {
        return await this._sendMessage(eventType, dataObject);
      } catch (error) {
        console.log(
          'sendRequest() | timeout, retrying [attempt:"%s"] [eventType: "%s"]',
          tries,
          eventType
        );
      }
    }
  };

  getMessages = () => this.messageObservable.asObservable();

  closeSocketConnection = () => {
    if (this.socket) {
      this.socket.close();
    }
  };
}

export default class SocketServiceSingleton {
  static instance: SocketService;
  static activityChannel: SocketService;

  constructor(socketType: string) {
    if (
      socketType === socketConnectiontype.activityChannel &&
      !SocketServiceSingleton.activityChannel
    ) {
      console.log("activity Channel constructror in Socket.ts");
      SocketServiceSingleton.activityChannel = new SocketService(socketType);
    } else if (!SocketServiceSingleton.instance) {
      console.log("media constructror in Socket.ts");
      SocketServiceSingleton.instance = new SocketService(socketType);
    }
  }

  getInstance(socketType: string) {
    if (socketType === socketConnectiontype.activityChannel) {
      // console.log("activityChannel instance");
      return SocketServiceSingleton.activityChannel;
    } else {
      console.log("media instance");
      return SocketServiceSingleton.instance;
    }
  }
}
