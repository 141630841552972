export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAILURE = "OTP_FAILURE";
export const SET_MFA = "SET_MFA";
export const SET_COMPLEX_PASSWORD = "SET_COMPLEX_PASSWORD";
export const SET_AUTO_LOGIN_LOADING_FALSE = "SET_AUTO_LOGIN_LOADING_FALSE";
export const SET_VIA_APP_TRUE = "SET_VIA_APP_TRUE";
export const SET_VIA_APP_FALSE = "SET_VIA_APP_FALSE";
export const SET_LOADING = "SET_LOADING";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";
export const GET_ROOMS_SUCCESS = "GET_ROOMS_SUCCESS";
export const GET_ROOMS_FAILURE = "GET_ROOMS_SUCCESS";
export const SET_VIEW_MODE = "SET_VIEW_MODE";
export const SET_CURRENT_VIEW_MODE = "SET_CURRENT_VIEW_MODE";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS";
export const CREATE_ROOM_FAILURE = "CREATE_ROOM_FAILURE";
export const SET_CONNECTION_DETAILS = "SET_CONNECTION_DETAILS";
export const SET_AUDIO_DEVICES = "SET_AUDIO_DEVICES";
export const SET_VIDEO_DEVICES = "SET_VIDEO_DEVICES";
export const SELECT_AUDIO_DEVICE = "SELECT_AUDIO_DEVICE";
export const SELECT_VIDEO_DEVICE = "SELECT_VIDEO_DEVICE";
export const PREPEND_MESSAGE_LIST = "PREPEND_MESSAGE_LIST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";
export const GET_LOGO_SUCCESS = "GET_LOGO_SUCCESS";
export const GET_LOGO_FAILURE = "GET_LOGO_FAILURE";
export const CLEAR_ADDLOGO_DATA = "CLEAR_ADDLOGO_DATA";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const GET_ALL_USER_DETAILS_SUCCESS = "GET_ALL_USER_DETAILS_SUCCESS";
export const GET_ALL_USER_DETAILS_FAILURE = "GET_ALL_USER_DETAILS_FAILURE";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const SET_PEER_VOLUME = "SET_PEER_VOLUME";
export const REMOVE_PEER = "REMOVE_PEER";
export const SET_SOCKETID = "SET_SOCKETID";
export const GET_SUPERVISOR_MANAGER_SUCCESS = "GET_SUPERVISOR_MANAGER_SUCCESS";
export const GET_SUPERVISOR_MANAGER_FAILURE = "GET_SUPERVISOR_MANAGER_FAILURE";
export const SET_AUDIO_ALLOWED = "SET_AUDIO_ALLOWED"
export const SET_PRIVATE_CALL_ALLOWED = "SET_PRIVATE_CALL_ALLOWED"

// chat Type for chat tabs
export const SET_MESSAGE_LIST = "SET_MESSAGE_LIST";
export const SET_TYPER_NAME = "SET_TYPER_NAME";
export const SET_FULL_MESSAGE_LIST = "SET_FULL_MESSAGE_LIST";
export const SET_SELECTED_CHAT_USER = "SET_SELECTED_CHAT_USER";
export const CLEAR_CHAT_DATA = "CLEAR_CHAT_DATA";
export const SET_RECONNECTION_STATUS_FOR_CHAT = "SET_RECONNECTION_STATUS_FOR_CHAT";


// notifications for user
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_END_TIME = "SET_NOTIFICATIONS_END_TIME";
export const SET_NOTIFICATIONS_FOR_SINGLE = "SET_NOTIFICATIONS_FOR_SINGLE";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

export const SET_REM_TEMP_ROOM_USER = "SET_TEMP_ROOM_USER";
export const CLEAR_REM_TEMP_ROOM_USER = "CLEAR_REM_TEMP_ROOM_USER";

// Video Editing Option
export const SET_VIDEO_OPTION = "SET_VIDEO_OPTION";

//Troubleshoot Connecion
export const SET_TROUBLESHOOT = "SET_TROUBLESHOOT";

// Scrren control id
export const SET_CONTROL_ID = "SET_CONTROL_ID";
export const CLEAR_CONTROL_ID = "CLEAR_CONTROL_ID";

// Audio or video file for stream
export const SET_VIDEO_FILES_TRUE = "SET_VIDEO_FILES_TRUE";
export const SET_VIDEO_FILES_FALSE = "SET_VIDEO_FILES_FALSE";
export const SET_AUDIO_FILES_TRUE = "SET_AUDIO_FILES_TRUE";
export const SET_AUDIO_FILES_FALSE = "SET_AUDIO_FILES_FALSE";
export const ADD_HIGH_RES_GET_USER = "ADD_HIGH_RES_GET_USER";
export const REMOVE_HIGH_RES_GET_USER = "REMOVE_HIGH_RES_GET_USER";
export const REMOVE_HIGH_RES_GET_USER_STREAM_TYPE =
  "REMOVE_HIGH_RES_GET_USER_STREAM_TYPE";
export const CLEAR_AUDIO_VIDEO_FILES = "CLEAR_AUDIO_VIDEO_FILES";

// Background Image change
export const SET_STATIC_IMAGE_LINKS = "SET_STATIC_IMAGE_LINKS";
export const SET_IMAGE_LINK = "SET_IMAGE_LINK";
export const SET_BACKGROUND_MODAL_OPEN = "SET_BACKGROUND_MODAL_OPEN";
export const SET_BACKGROUND_MODAL_CLOSE = "SET_BACKGROUND_MODAL_CLOSE";

// Collab Mode
export const ADD_COLLAB_SCREEN_PEER = "ADD_COLLAB_SCREEN_PEER";
export const REMOVE_COLLAB_SCREEN_PEER = "REMOVE_COLLAB_SCREEN_PEER";
export const REMOVE_COLLAB_SCREEN_PEER_FROM_ARRAY =
  "REMOVE_COLLAB_SCREEN_PEER_FROM_ARRAY";
export const ADD_COLLAB_DATA_ON_SOCKET_CONNECTED =
  "ADD_COLLAB_DATA_ON_SOCKET_CONNECTED";
export const ADD_COLLAB_SCREEN_PEER_FROM_ARRAY =
  "ADD_COLLAB_SCREEN_PEER_FROM_ARRAY";
export const EMPTY_COLLAB_DATA = "EMPTY_COLLAB_DATA";
// Room leave on restart ice
export const SET_ROOM_LEAVE_ON_ICE_RESTART = "SET_ROOM_LEAVE_ON_ICE_RESTART";

// Clearing redux data
export const CLEAR_REDUX_DATA = "CLEAR_REDUX_DATA";

// Environment
export const GET_ENVIRONMENTS_SUCCESS = "GET_ENVIRONMENTS_SUCCESS";
export const GET_ENVIRONMENTS_FAILURE = "GET_ENVIRONMENTS_FAILURE";

// activity channel
export const SET_ACTIVITY_CHANNEL_MODAL_MINIMIZING =
  "SET_ACTIVITY_CHANNEL_MODAL_MINIMIZING";
export const SET_ACTIVITY_CHANNEL_MODAL_OPEN_AND_CLOSE =
  "SET_ACTIVITY_CHANNEL_MODAL_OPEN_AND_CLOSE";
export const SET_ACTIVITY_CHANNEL_PARENT_ID = "SET_ACTIVITY_CHANNEL_PARENT_ID";
export const CLEAR_ACTIVITY_CHANNEL = "CLEAR_ACTIVITY_CHANNEL";
export const SET_UNREAD_POSTS_COUNT = "SET_UNREAD_POSTS_COUNT";

// Set Camera Background
export const SET_CAMERA_BACKGROUND = "SET_CAMERA_BACKGROUND";
export const GET_CAMERA_BACKGROUND = "GET_CAMERA_BACKGROUND";

// reconnection Reducer
export const SET_RECONNECTION_STATUS = "SET_RECONNECTION_STATUS";

// Setting up the Layouts
export const SAVE_LAYOUT_SETTING_SUCCESS = "SAVE_LAYOUT_SETTING_SUCCESS";
export const GET_LAYOUT_SETTING_SUCCESS = "GET_LAYOUT_SETTING_SUCCESS";
export const SAVE_LAYOUT_SETTING_FAILURE = "SAVE_LAYOUT_SETTING_FAILURE";
export const GET_LAYOUT_SETTING_FAILURE = "GET_LAYOUT_SETTING_FAILURE";
export const CLEAR_LAYOUT_SETTING = "CLEAR_LAYOUT_SETTING";

// Setting Resolution
export const SET_SCALE_RESOLUTION = "SET_SCALE_RESOLUTION";
export const REMOVE_SCALE_RESOLUTION = "REMOVE_SCALE_RESOLUTION";
//FACE RECOGNITION
export const SET_REGISTRATION_STATUS_SUCCESS =
  "SET_REGISTRATION_STATUS_SUCCESS";
export const SET_REGISTRATION_STATUS_FAILURE =
  "SET_REGISTRATION_STATUS_FAILURE";
export const SET_REGISTRATION_SUCCESS = "SET_REGISTRATION_SUCCESS";
export const SET_REGISTRATION_FAILURE = "SET_REGISTRATION_FAILURE";

// FACE VERIFICATION
export const GET_PENDING_USERS = "GET_PENDING_USERS";
export const SET_VERIFICATION_SUCCESS = "SET_VERIFICATION_SUCCESS";
export const SET_VERIFICATION_FAILURE = "SET_VERIFICATION_FAILURE";
export const GET_PENDING_USERS_FAILURE = "GET_PENDING_USERS_FAILURE";
export const SET_REJECTION_SUCCESS = "SET_REJECTION_SUCCESS";
export const SET_REJECTION_FAILURE = "SET_REJECTION_FAILURE";
export const SET_REJECTED_STATUS_SUCCESS = "SET_REJECTED_STATUS_SUCCESS";
export const SET_REJECTED_STATUS_FAILURE = "SET_REJECTED_STATUS_FAILURE";
export const SET_VERIFY_FACE = "SET_VERIFY_FACE";
export const SET_RECURRENT_CHECK = "SET_RECURRENT_CHECK";
export const SET_WRONG_PERSON = "SET_WRONG_PERSON";
export const SET_USERDETAILS_REGISTERED = "SET_USERDETAILS_REGISTERED";

/// CHAT SPECIFICATION
export const GET_PERSONALIZATION_SUCCESS = "GET_PERSONALIZATION_SUCCESS";
export const GET_PERSONALIZATION_FAILURE = "GET_PERSONALIZATION_FAILURE";
export const SAVE_PERSONALIZATION_SUCCESS = "SAVE_PERSONALIZATION_SUCCESS";
export const SAVE_PERSONALIZATION_FAILURE = "SAVE_PERSONALIZATION_FAILURE";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_sUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";
export const UPDATE_NOTIFICATION_COUNT = "UPDATE_NOTIFICATION_COUNT";
export const SET_REGISTER_COUNT = "SET_REGISTER_COUNT";
