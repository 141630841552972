import request from "./index";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getPersonalization = async (username: string) => {
  const body = {
    username,
  };

  const response = await request.post("/getPersonalization", body, config);
  return response.data;
};

export const savePersonalization = async (
  specification:any
) => {
  const body = specification;

  const response = await request.post("/savePersonalization", body, config);
  return response.data;
};
