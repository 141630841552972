import request from "./index";
// import qs from "querystring";
import axios from "axios";

export const upload = async (blob: any, username: string, numfaces: string) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const data = new FormData();
  data.append("file", blob);
  data.append("username", username);
  data.append("numfaces", numfaces);
  const uploadResponse = await request.post("/upload", data, config);
  return uploadResponse.data;
};

export const uploadFeedbackImg = async (
  blob: any,
  agentUsername: string,
  parentRole: string,
  parentUsername: string,
  room: string,
  comment: string
) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const data = new FormData();
  data.append("file", blob);
  data.append("parentUsername", parentUsername);
  data.append("roomname", room);
  data.append("agentUsername", agentUsername);
  data.append("parentRole", parentRole);
  data.append("comment", comment);
  const uploadResponse = await request.post("/uploadFeedbackImg", data, config);
  return uploadResponse.data;
};

// storeImage:any,
export const detect = async (
  url:any,
  username: any,
  image: any,
  client: any,
  features: any,
  token: any
) => {
  const requests = axios.create({
    baseURL:url,
    // baseURL: 'http://216.153.51.94:5000',
  });
  
  const config = {
    headers: {
      // "Content-Type": "multipart/form-data",
      // "Content-Type": "application/x-www-form-urlencoded"
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  // const data = new FormData();
  // data.append("id", id);
  // data.append("file", image);
  // const reqBody = qs.stringify({
  //   id: id,
  //   // file: image,
  //   images: image
  // });
  const reqBody = {
    username: username,
    features: features,
    client: client,
    // file: image,
    image: image,
  };
  try {
    const detectResponse = await requests.post("/detect", reqBody, config);
    return detectResponse.data;
  } catch (error: any) {
    throw new Error(`Unable to detect: ${error.message}`);
  }
};
