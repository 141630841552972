import { Dispatch } from "react";
import {
  CLEAR_REM_TEMP_ROOM_USER,
  SET_REM_TEMP_ROOM_USER,
} from "./actionTypes";

interface breakPart {
  peerId: string;
  username: string;
}

export const setorRemoveTempRoomUser = (
  peerSessionId: string,
  peerUsername: string,
  breakParticipant: Array<breakPart>
) => {
  return (dispatch: Dispatch<any>) => {
    let participant = breakParticipant.map((value) => value);

    let existParticipant = breakParticipant.find(
      (val: breakPart) => val.peerId === peerSessionId
    );

    if (existParticipant) {
      participant = breakParticipant.filter(
        (val: breakPart) => val.peerId !== peerSessionId
      );
    } else {
      participant = [
        ...participant,
        { peerId: peerSessionId, username: peerUsername },
      ];
    }

    dispatch({
      type: SET_REM_TEMP_ROOM_USER,
      payload: participant,
    });
  };
};

export const clearTempRoomUser = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: CLEAR_REM_TEMP_ROOM_USER,
    });
  };
};
