import {
  SET_MESSAGE_LIST,
  SET_TYPER_NAME,
  SET_FULL_MESSAGE_LIST,
  SET_SELECTED_CHAT_USER,
  CLEAR_REDUX_DATA,
  PREPEND_MESSAGE_LIST,
  CLEAR_CHAT_DATA,
  SET_RECONNECTION_STATUS_FOR_CHAT
} from "../actions/actionTypes";

interface IselectedChatUser {
  peerSessionId: string | null;
  chatType: string;
  peerName: string | null;
  peerUserName: string | null;
}

interface IchatInterface {
  messageList: [];
  typerData: {};
  selectedChatUser: IselectedChatUser;
  reconnect: boolean
}

const initialState: IchatInterface = {
  messageList: [],
  typerData: {},
  selectedChatUser: {
    peerSessionId: null,
    chatType: "EVERYONE",
    peerName: null,
    peerUserName: null,
  },
  reconnect: false
};
interface IAction {
  type: string;
  payload: any;
}

const chatReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_MESSAGE_LIST: {
      return {
        ...state,
        messageList: [...state.messageList, action.payload],
      };
    }

    case PREPEND_MESSAGE_LIST: {
      return {
        ...state,
        messageList: [...action.payload, ...state.messageList],
      };
    }
    case SET_FULL_MESSAGE_LIST: {
      return {
        ...state,
        messageList: action.payload,
      };
    }
    case SET_TYPER_NAME: {
      return {
        ...state,
        typerData: action.payload,
      };
    }


    case SET_SELECTED_CHAT_USER: {
      return {
        ...state,
        selectedChatUser: action.payload,
      };
    }
    case SET_RECONNECTION_STATUS_FOR_CHAT: {
      return {
        ...state,
        reconnect: action.payload,
      };
    }
    case CLEAR_CHAT_DATA: {
      return initialState;
    }
    case CLEAR_REDUX_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default chatReducer;
