import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import Loader from "../Loader";
import ActivityChannel from "../ActivityChannel";

const PrivateRoute = ({ children }: any) => {
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const isLoading = useSelector((state: RootState) => state.login.isLoading);
  const isModalActivityOpen = useSelector(
    (state: RootState) => state.activityChannelReducer.isActivityModalOpen
  );
  // const isOTPVerified = useSelector((state: RootState) => state.login.isOTPtest);
  // const isMFAEnable = useSelector((state: RootState) => state.login.isMFAEnable);

  return isLoading ? (
    <Loader />
  ) : !isLoggedIn && !isLoading ? (
    <Navigate to="/login" replace={true} />
  ) : isLoggedIn ? (
    <>
    {children}
    {isModalActivityOpen ? <ActivityChannel/> : null}
    </>
  ) : (
    <></>
  );
};
export default PrivateRoute;
