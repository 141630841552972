import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import reducers from "./../reducers";
import thunk from "redux-thunk";

const rootReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof rootReducer>;

export const createReduxStore = () => {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
  return store;
};
