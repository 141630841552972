import WaveSvg from "../../assets/images/wave.svg";
import VideoSvg from "../../assets/images/video.svg";
import LogoutImage from "../../assets/images/logout.svg";
import Profile from "./../../components/Profile";
import { logOutAction } from "../../actions/loginActions";
import { getWeblinkStatus } from "../../services/adminSettings";
import { Dropdown, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsAction } from "../../actions/userActions";
import { getUserDevicesInfo } from "../../utils/AudioVideoDeviceUtils";
import { toast } from "react-toastify";
import { RootState } from "../../redux";
import {
  setAudioDevices,
  setVideoDevices,
  setActiveAudioId,
  setActiveVideoId,
} from "./../../actions/selectDeviceActions";
import "./header.css";
import { Link, useLocation } from "react-router-dom";
import { REACT_APP_VERSION } from "../../Pages/RoomPage/constants";
// import app from "../../App/App";
import Logo from "../../assets/images/logo.svg";
import { getLogoAction } from "../../actions/appLogo";
import { checkForSupManAdmin } from "../../utils/checkForSupAdmin";
import UserPersonalization from "../../Pages/UserPersonalization";
// UserPersonalization


interface IHeaderProps {
  handleActiveAudioDeviceChange?: (deviceId: string) => void;
  handleActiveVideoDeviceChange?: (deviceId: string) => void;
}

const Header = (props: IHeaderProps) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [webLinkStatus, setWebLinkStatus] = useState(false);
  const [webLinkAddress, setWebLinkAddress] = useState('');
  const audioDevices = useSelector(
    (state: RootState) => state.devicesData.audioDevices
  );
  const environments = useSelector((state: RootState) => state.environmentsReducers.environments);
  const savedLogo = useSelector((state: RootState) => state.logoReducer.logoUrl);
  const appVersion = environments.REACT_APP_VERSION ? environments.REACT_APP_VERSION.value : REACT_APP_VERSION;
  const videoDevices = useSelector(
    (state: RootState) => state.devicesData.videoDevices
  );

  const faceVerifcationEnabled = useSelector((state: RootState) => state.userDetails.faceVerification);
  const count = useSelector((state: RootState) => state.verifyNotificationReducer.count);

  const [logo, setLogo] = useState(Logo);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (savedLogo) {
      setLogo(savedLogo);

      var link = document.querySelector("link[rel~='apple-touch-icon']");
      // @ts-ignore
      link.href = savedLogo;
    }
  }, [savedLogo, logo])

  const showToast = (message: string) => {
    toast(message);
  };

  useEffect(() => {

    dispatch(getLogoAction());
    dispatch(getUserDetailsAction());
    const getMediaDevicesInfo = async () => {
      try {
        try {
          const mediaDevicesInfo = await getUserDevicesInfo();
          const audioDevices = mediaDevicesInfo.filter(
            (mediaDeviceInfo) => mediaDeviceInfo.kind === "audioinput"
          );
          dispatch(setAudioDevices(audioDevices));
          if (audioDevices.length) {
            dispatch(setActiveAudioId(audioDevices[0].deviceId));
          }

          const videoDevices = mediaDevicesInfo.filter(
            (mediaDeviceInfo) => mediaDeviceInfo.kind === "videoinput"
          );
          dispatch(setVideoDevices(videoDevices));
          if (videoDevices.length) {
            dispatch(setActiveVideoId(videoDevices[0].deviceId));
          }
        } catch (err) {
          showToast("Could not list media devices");
          console.error(err);
        }
      } catch (err) {
        showToast("Please provide mic and video device access");
        console.error(err);
      }
    };
    getMediaDevicesInfo();
    navigator.mediaDevices.addEventListener("devicechange", (event) => {
      getMediaDevicesInfo();
    });
    weblinkStatus();
  }, [dispatch]);

  const userDetails = useSelector((state: RootState) => state.userDetails);
  async function weblinkStatus() {
    try {
      const statusData = await getWeblinkStatus();
      if (statusData) {
        setWebLinkAddress(statusData.adminsettings.value);
        setWebLinkStatus(statusData.adminsettings.status);
      }
    } catch (error) {
      console.log('Error fetching weblink status: ', error);
    }
  }

  const handleShowModal = (e:any) => {
    setModalShow(true);
  }

  const location = useLocation();
  const logOut = () => {
    if (location.pathname === "/dashboard") {
      dispatch(logOutAction());
    } else {
      dispatch(logOutAction());
      // history.push("/dashboard");
    }
    localStorage.removeItem("webrtcipfetched");
  };

  const selectAudioDevice = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    await setActiveAudioId(e.target.value);
    if (props.handleActiveAudioDeviceChange) {
      props.handleActiveAudioDeviceChange(e.target.value);
    }
  };

  const selectVideoDevice = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setActiveVideoId(e.target.value));
    if (props.handleActiveVideoDeviceChange) {
      props.handleActiveVideoDeviceChange(e.target.value);
    }
  };


  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <header id="myHeader">
      <div className="flex align-items-center space-between ftop">
        <div className="f1">
          <div className="left-box">
            <ul>
              <li className="logo">
                <Link to="/">
                  <img src={logo} alt="logo2" className="header-logo" />
                </Link>
              </li>
              <li>
                <h4>Dashboard</h4>
              </li>
            </ul>
          </div>
        </div>
        <div className="f2">
          <div className="center-box">
            <ul>
              <li className="flex mr-4">
                <img
                  src={WaveSvg}
                  alt="wave"
                  width="20"
                  height="20"
                  className="icon-img"
                />
                <select onChange={selectAudioDevice}>
                  {audioDevices &&
                    audioDevices.length &&
                    audioDevices.map((audioDevice: MediaDeviceInfo) => {
                      return (
                        <option
                          value={audioDevice?.deviceId}
                          key={audioDevice.deviceId}
                        >
                          {audioDevice?.label}
                        </option>
                      );
                    })}
                </select>
              </li>
              <li className="flex mr-4">
                <img
                  src={VideoSvg}
                  alt="video"
                  width="20"
                  height="20"
                  className="icon-img"
                />
                <select onChange={selectVideoDevice}>
                  {videoDevices &&
                    videoDevices.length &&
                    videoDevices.map((videoDevice: MediaDeviceInfo) => {
                      return (
                        <option
                          value={videoDevice?.deviceId}
                          key={videoDevice.deviceId}
                        >
                          {videoDevice?.label}
                        </option>
                      );
                    })}
                </select>
              </li>
            </ul>
          </div>
        </div>
        <div className="f3">
          <div className="right-box">
            <ul>
              <li>
                <Profile name={(`${userDetails.firstName} ${userDetails.lastName}`)?.toUpperCase()} role={userDetails.role} />
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="menu-dropdown">
                    Menu
                    {count > 0 && <span></span>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>

                    <Link className="dropdown-item" to="/" > Dashboard </Link>
                    <Link className="dropdown-item" to="/change_password" > Change Password </Link>
                    {checkForSupManAdmin(userDetails.role, 'notEqualTo') && <Link className={`${faceVerifcationEnabled ? "dropdown-item" : "dropdown-item disabled-item"}`} to="/face_registration" >Face Registration </Link>}
                    <p className="dropdown-item" onClick={handleShowModal} >User Personalization </p>
                    {checkForSupManAdmin(userDetails.role, "equalTo") && <Link className="dropdown-item verification_item" to="/face_verfication" > {count > 0 && <span className="verification_span"></span>}Face Verification </Link>}

                    {
                      webLinkStatus && userDetails.role === 'Admin' &&
                      <Link className="dropdown-item" to={ webLinkAddress } target="_blank">
                        Admin Login
                      </Link>
                    }
                    <Dropdown.Item onClick={() => setShowModal(true)}>
                      About
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="logout" onClick={logOut}>
                <img src={LogoutImage} alt="logout" title="Log out" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <UserPersonalization show={modalShow}
                onHide={() => setModalShow(false)}   />
      <Modal size="sm" show={showModal} onHide={handleModalClose}>
        <Modal.Header  style={{ position: "relative" }}>
        <button className="modal-close-btn" onClick={handleModalClose}>
            X
          </button>
          Version No: {appVersion}
        </Modal.Header>
      </Modal>
    </header>
  );
};

export default Header;
