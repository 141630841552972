import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const FeedbackModal = (props: any) => {
  const [textValue, setTextValue] = useState("");

  const handleModalClose = () => {
    setTextValue("");
    props.handleShow((value: boolean) => !value);
    props.handleDropdown(true);
  };

  const submitButton = () => {
    if (textValue === "") {
      toast("value can't be empty, Please fill a value");
      return;
    }
    props.handleSubmit(textValue);
    setTextValue("");
  };

  return (
    <Modal
      show={props.show}
      onHide={handleModalClose}
      className="feedback_modal_container"
      style={{
        top: ((props.posValue.y + 50) * 100) / window.screen.height + "%",
        left: ((props.posValue.x + 20) * 100) / window.screen.width + "%",
      }}
    >
      <Modal.Body>
        <div className="feedback_modal_main">
          <div className="message_export_data">
            <div className="filters-container">
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    as="textarea"
                    placeholder="Enter Your feedback"
                    rows={5}
                    style={{ resize: "none", width: "100%" }}
                    value={textValue}
                    onChange={(e: any) => setTextValue(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
            className="modal_link"
          >
            <button
              className="link"
              onClick={(e) => {
                e.stopPropagation();
                handleModalClose();
              }}
            >
              Cancel
            </button>
            <button
              className="link"
              onClick={(e) => {
                e.stopPropagation();
                submitButton();
              }}
              disabled={textValue === "" ? true : false}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FeedbackModal;
