import { Dispatch } from "react";
import {
  CLEAR_ACTIVITY_CHANNEL,
  SET_ACTIVITY_CHANNEL_MODAL_MINIMIZING,
  SET_ACTIVITY_CHANNEL_MODAL_OPEN_AND_CLOSE,
  SET_ACTIVITY_CHANNEL_PARENT_ID,
  SET_UNREAD_POSTS_COUNT,
} from "./actionTypes";

export const setActivityModalMinimize = (minimizeValue: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_ACTIVITY_CHANNEL_MODAL_MINIMIZING,
      payload: minimizeValue,
    });
  };
};

export const setActivityModalOpen = (value: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_ACTIVITY_CHANNEL_MODAL_OPEN_AND_CLOSE,
      payload: value,
    });
  };
};

export const setActivityParentId = (value: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_ACTIVITY_CHANNEL_PARENT_ID,
      payload: value,
    });
  };
};

export const clearActivityReducer = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: CLEAR_ACTIVITY_CHANNEL,
    });
  };
};

export const setActivityUnreadPostCount = (value: number) => {
  console.log('SETTING COUNTER')
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_UNREAD_POSTS_COUNT,
      payload: value,
    });
  };
};
