import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import { login, verifyOTP, loginActiveTime, logout } from "../services/login";
import { getIPAddressStatus } from "../services/adminSettings";
import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  OTP_SUCCESS,
  OTP_FAILURE,
  SET_MFA,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  CLEAR_REDUX_DATA,
  SET_VIA_APP_TRUE,
  SET_VIA_APP_FALSE,
  SET_AUTO_LOGIN_LOADING_FALSE,
  SET_COMPLEX_PASSWORD,
} from "./actionTypes";
import { getUsersLocation } from "../utils/LocationUtils";
// import {dispatch} from "rxjs/internal-compatibility";

export const handleLogin = (
  username?: string,
  password?: string,
  isComplexPassword?: boolean
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    // let geo = false;
    try {
      let geoRes:any = await getUsersLocation();
      var geoLocationDetails:any =  {
        latitude: geoRes.coords.latitude,
        longitude: geoRes.coords.longitude,
        fromApi: false
      };
      // geo = true;
    } catch (err) {
      geoLocationDetails = {
        latitude :"",
        longitude :"",
        fromApi: false,
      };
      // geo = false;
    }

    try {
      if (username && password) {
        let responseData: any;
        let resIP: any;
        let IPAddressval = '';


        ////////// START GETTING IP ADDRESS AND GEO LOCATION OF AGENTS START //////
        const IPstatusData = await getIPAddressStatus(); // GET IP ADMIN SETTING
        if (IPstatusData.adminsettings.status) {
          try {
            // @ts-ignore
              resIP = await axios(
                "https://ipgeolocation.abstractapi.com/v1/?api_key=a1bc4a8fc5c54801b0849e4a77fe84a1",
                {
                  timeout: 2000,
                  method: "GET",
                }
              );
              IPAddressval = resIP.data.ip_address; //get IP address

              // if(!geo) {
              //   geoLocationDetails.latitude = resIP.data.latitude; //get IP Lat
              //   geoLocationDetails.longitude = resIP.data.longitude; //get IP Lon
              //   geoLocationDetails.fromApi = true;
              // }
          } catch (e: any) {
            responseData = false;
            responseData = await login(
              username,
              password,
              "",
              geoLocationDetails,
              ""
            );
            if (responseData.success) {
              ///////// if mfa /////
              localStorage.setItem("token", responseData.token);
              dispatch(setSuccess({ isComplexPassword: isComplexPassword, token: responseData.token}));
            } else {
              if (responseData.otpverify) {
                /// verify OTP
                dispatch(setMFAEnable());
              } else {
                dispatch(setLoginError(responseData.message));
              }
            }
            return;
          }
        } else {
          IPAddressval = "";
          resIP="";
        }
        ////////// ENDS GETTING IP ADDRESS OF AGENTS ENDS //////
        responseData = await login(
          username,
          password,
          IPAddressval,
          geoLocationDetails,
          resIP
        );

        if (responseData.success) {
          ///////// if mfa /////
          localStorage.setItem("token", responseData.token);
          dispatch(setSuccess({ isComplexPassword: isComplexPassword, token: responseData.token}));
        } else {
          if (responseData.otpverify) {
            /// verify OTP
            dispatch(setMFAEnable());
          } else {
            // dispatch(setLoginError("Incorrect Username/password"));
            dispatch(setLoginError(responseData.message));
          }
        }

        if (password === "autologin") {
          dispatch({
            type: SET_AUTO_LOGIN_LOADING_FALSE,
          });
        }
        return responseData;
      } else {
        const responseData = await login();
        if (responseData.success) {
          dispatch(setSuccess({ isComplexPassword: isComplexPassword }));
          await loginActiveTime();
        } else {
          dispatch(setLoginError(""));
        }
        return responseData;
      }
    } catch (error: any) {
      console.log(error);
      if (username && password) {
        if (error.message && error.message.message) {
          dispatch(setLoginError(error.message.message));
        } else {
          dispatch(setLoginError("Incorrect Username/password"));
        }
      } else {
        dispatch(setLoginError(""));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const handleOTP = (username?: string, OTP?: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      if (username && OTP) {
        const responseData = await verifyOTP(username, OTP);
        if (responseData.success) {
          localStorage.setItem("token", responseData.token);
          dispatch(setOTPSuccess());
        } else {
          dispatch(setOTPError("Incorrect OTP, Please try again"));
        }

        return responseData;
      } else {
        /*
        const responseData = await login();
        if (responseData.success) {
          dispatch(setSuccess());
          await loginActiveTime();
        } else {
          dispatch(setLoginError(""));
        }
        return responseData;
        */
      }
    } catch (error: any) {
      if (OTP) {
        if (error.message && error.message.message) {
          dispatch(setLoginError(error.message.message));
        } else {
          dispatch(setLoginError("Incorrect OTP, Please try again"));
        }
      } else {
        dispatch(setLoginError(""));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const setComplexPasswordAction = (isComplexPassword: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_COMPLEX_PASSWORD,
      payload: { isComplexPassword: isComplexPassword },
    });
  };
};
export const setViaAppTrue = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_VIA_APP_TRUE,
    });
  };
};

export const setViaAppFalse = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_VIA_APP_FALSE,
    });
  };
};

const setSuccess = (loginData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: loginData,
    });
  };
};

const setMFAEnable = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_MFA,
      payload: {
        mfa: true,
      },
    });
  };
};

const setOTPSuccess = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: OTP_SUCCESS,
    });
  };
};

export const setLoginError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: LOGIN_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

const setOTPError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: OTP_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

const setLogoutError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: LOGOUT_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

const setLogout = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  };
};

export const logOutAction = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const logoutData = await logout();
      if (logoutData) {
        dispatch(setLogout());
        dispatch({ type: CLEAR_REDUX_DATA });
      } else {
        dispatch(setLogoutError("Could not logout. Please try again"));
      }
      return logoutData;
    } catch (error: any) {
      setLogoutError(
        error?.message || "Could Could not logout. Please try again"
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};
