import React, { useState } from "react";
import { Dropdown, Form, Modal } from "react-bootstrap";
import SocketService from "../../services/socket";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import {
  createTempRoom,
  shiftToExistingRoomApi,
} from "../../services/tempRoom";
import { clearTempRoomUser } from "../../actions/tempRoomUser";
import { roomViewModeConst, socketConnectiontype } from "../../utils/constant";
import { checkForSupManAdmin } from "../../utils/checkForSupAdmin";

const socketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

interface breakPart {
  peerId: string;
  username: string;
}

interface tempRoom {
  show: boolean;
  participantList: Array<breakPart>;
  handleShow: any;
  roomname: string;
}

const TempRoomModal = (props: tempRoom) => {
  const [breakOutOrExist, setBreakOutOrExist] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [existingRoom, setExistingRoom] = useState("");
  const [viewMode, setViewMode] = useState("");
  const [roomName, setRoomName] = useState("");
  const [duration, setDuration] = useState("");
  const [warningExistingRoom, setWarningExistingRoom] = useState("");
  const [warning, setWarning] = useState({
    roomName: "",
    duration: "",
    supervisor: "",
  });
  const rooms = useSelector((state: RootState) => state.roomsData.rooms);
  const role = useSelector((state: RootState) => state.userDetails.role);

  const creatorUsername = useSelector(
    (state: RootState) => state.userDetails.userName
  );

  const dispatch = useDispatch();

  const handleModalClose = () => {
    props.handleShow((value: boolean) => !value);
    setSupervisor("");
    setBreakOutOrExist("");
  };

  const handleNameChange = (s: any) => {
    setSupervisor(s);
  };

  const handleDuration = (value: string) => {
    setDuration(value);
  };

  const shiftToExistingRoom = async () => {
    setWarning({ roomName: "", duration: "", supervisor: "" });
    let flag = false;
    if (existingRoom === "") {
      flag = true;
      setWarningExistingRoom(
        "Roomname can't be empty and must be selected one of the room"
      );
    }

    if (flag) {
      return;
    }

    try {
      let response = await shiftToExistingRoomApi(
        existingRoom,
        props.participantList
      );
      if (response.success) {
        toast(
          "The participants have been moved to " + existingRoom + "successfully"
        );

        socketInstance.sendMessage("SHIFT_TO_ANOTHER_ROOM", {
          roomname: existingRoom,
          viewMode: viewMode,
          userList: props.participantList,
        });
        dispatch(clearTempRoomUser());
        setWarningExistingRoom("");
        handleModalClose();
      } else {
        toast(response.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async () => {
    var reg = new RegExp(/^(3[0]|[12][0-9]|[1-9])$/);
    const regex = /\s+/;
    setWarning({ roomName: "", duration: "", supervisor: "" });
    let flag = false;
    if (roomName === "" || roomName.length > 20) {
      flag = true;
      setWarning((value) => ({
        ...value,
        roomName: "Roomname can't be empty and must be less the 20 characters",
      }));
    }
    if(roomName !== "" && roomName.length < 20 && regex.test(roomName)) {
      flag = true;
      setWarning((value) => ({
        ...value,
        roomName: "Spaces are not allowed in Roomname.",
      }));
    }
    if (duration === "" || !reg.test(duration)) {
      flag = true;
      setWarning((value) => ({
        ...value,
        duration: "Duration must be an integer value between 1 and 30",
      }));
    }
    if (supervisor === "") {
      flag = true;
      setWarning((value) => ({
        ...value,
        supervisor: "Please select a supervisor",
      }));
    }

    if (flag) {
      return;
    }

    let bussUnit = rooms.filter(
      (value: any) => value.roomname === props.roomname
    )[0].bussunit;

    try {
      let response = await createTempRoom(
        roomName,
        bussUnit,
        duration,
        props.participantList,
        supervisor,
        creatorUsername,
        props.roomname
      );
      if (response.success) {
        toast("Breakout room has been created successfully");

        socketInstance.sendMessage("SHIFT_TO_ANOTHER_ROOM", {
          roomname: props.roomname + "." + roomName,
          viewMode: roomViewModeConst.lectureMode,
          userList: props.participantList,
        });
        dispatch(clearTempRoomUser());
        setWarning({ roomName: "", duration: "", supervisor: "" });
        handleModalClose();
      } else {
        toast(response.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={handleModalClose}
      // className="feedback_modal_container"
      style={{ border: "0px solid black", width: "40%", margin: "auto" }}
    >
      <Modal.Header
        style={{
          backgroundColor: "rgb(38, 163, 219)",
          margin: "0",
          padding: "6px",
        }}
      >
        <button className="modal-close-btn" onClick={handleModalClose}>
            X
          </button>
        <Modal.Title
          style={{ color: "black", fontSize: "1.1rem", fontWeight: "bold" }}
        >
          {breakOutOrExist === "breakoutroom"
            ? "Move To Breakout Room"
            : breakOutOrExist === "moveexitingroom"
            ? "Move To Existing Room"
            : "Move Participant"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {breakOutOrExist === "breakoutroom" ? (
          <div className="feedback_modal_main">
            <div className="message_export_data">
              <div className="filters-container">
                <Form
                  style={{ width: "20rem" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  {warning.roomName !== "" ? (
                    <p className="warning_temproommodal">*{warning.roomName}</p>
                  ) : null}
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="input"
                      value={roomName}
                      placeholder="Enter room name"
                      style={{ width: "100%" }}
                      onChange={(e: any) => setRoomName(e.target.value.trim())}
                    />
                  </Form.Group>

                  {warning.duration !== "" ? (
                    <p className="warning_temproommodal">*{warning.duration}</p>
                  ) : null}
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="input"
                      value={duration}
                      placeholder="duration for room in days (1 - 30)"
                      style={{ width: "100%" }}
                      onChange={(e: any) => {
                        handleDuration(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>

                <div className="dropdown-container">
                  {warning.supervisor !== "" ? (
                    <p className="warning_temproommodal">
                      *{warning.supervisor}
                    </p>
                  ) : null}
                  <Dropdown className="select-item">
                    <Dropdown.Toggle
                      variant="success"
                      className="menu-dropdown-name"
                      style={{
                        width: "100%",
                        padding: "8px",
                        overflow: "hidden",
                        borderRadius: "5px",
                        borderColor: "#26a3db",
                      }}
                    >
                      {supervisor ? supervisor : "Select Supervisor"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-inner-container name_menu">
                      {props.participantList.map((value: breakPart) => (
                        <Dropdown.Item
                          key={value.peerId}
                          onClick={() => {
                            handleNameChange(value.username);
                          }}
                        >
                          {value.username}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div
              className="mt-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "60%",
              }}
            >
              <button
                className="link"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSubmit();
                }}
              >
                Submit
              </button>
              <button
                className="link"
                onClick={(e) => {
                  e.stopPropagation();
                  setBreakOutOrExist("");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : breakOutOrExist === "moveexitingroom" ? (
          <div className="feedback_modal_main">
            <div className="message_export_data">
              <div className="filters-container">
                <div className="dropdown-container">
                  {warningExistingRoom !== "" ? (
                    <p className="warning_temproommodal">
                      *{warningExistingRoom}
                    </p>
                  ) : null}
                  <Dropdown className="select-item">
                    <Dropdown.Toggle
                      variant="success"
                      className="menu-dropdown-name"
                      style={{
                        width: "100%",
                        padding: "8px",
                        overflow: "hidden",
                        borderRadius: "5px",
                        borderColor: "#26a3db",
                      }}
                    >
                      {existingRoom ? existingRoom : "Select Room"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-inner-container name_menu">
                      {rooms
                        .filter(
                          (value: any) => value.roomname !== props.roomname
                        )
                        .map((value: any) => (
                          <Dropdown.Item
                            key={value.roomname}
                            onClick={() => {
                              setExistingRoom(value.roomname);
                              setViewMode(value.roomtype);
                            }}
                          >
                            {value.roomname}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div
              className="mt-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "60%",
              }}
            >
              <button
                className="link"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  shiftToExistingRoom();
                }}
              >
                Submit
              </button>
              <button
                className="link"
                onClick={(e) => {
                  e.stopPropagation();
                  setBreakOutOrExist("");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="feedback_modal_main">
            <div
              className="mt-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                margin: "2rem 0",
              }}
            >
              {props.participantList.length > 1 ? (
                <button
                  className="link"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setBreakOutOrExist("breakoutroom");
                  }}
                >
                  Move To Breakout Room
                </button>
              ) : null}

              {
              checkForSupManAdmin(role,"equalTo")
               ? (
                <button
                  className="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    setBreakOutOrExist("moveexitingroom");
                  }}
                >
                  Move To Existing Room
                </button>
              ) : null}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TempRoomModal;
