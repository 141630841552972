import {
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CLEAR_REDUX_DATA, CHANGE_PASSWORD_RESET
} from "../actions/actionTypes";

  const initialState = {
    isPasswordChanged: false,
    error: "",
  };
  interface IAction {
    type: string;
    payload: any;
  }

  const changePasswordReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
      case CHANGE_PASSWORD_SUCCESS: {
        return {
          ...state,
          isPasswordChanged: true,
        };
      }
      case CHANGE_PASSWORD_RESET: {
        return {
          ...state,
          isPasswordChanged: false,
        };
      }
      case CHANGE_PASSWORD_FAILURE: {
        return {
          ...state,
          isPasswordChanged: false,
          error: action.payload.error,
        };
      }
      case CLEAR_REDUX_DATA:{
        return initialState
      }

      default: {
        return state;
      }
    }
  };

  export default changePasswordReducer;
