import request from "./index";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const createTempRoom = async (
  roomname: string,
  bussunit: string,
  duration: string,
  usersList: any,
  supervisor: string,
  creator: string,
  parentRoom: string
) => {
  const requestBody = JSON.stringify({
    isTemp: true,
    roomname,
    bussunit,
    duration,
    usersList,
    supervisor,
    creator,
    parentRoom,
  });

  const response = await request.post("/createRoom", requestBody, config);

  return response.data;
};

export const shiftToExistingRoomApi = async (
  roomname: string,
  usersList: any
) => {
  const requestBody = JSON.stringify({
    roomname,
    usersList,
  });

  const response = await request.post(
    "/shiftToExistingRoom",
    requestBody,
    config
  );

  return response.data;
};
