import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Howl, Howler } from "howler";

// Setup the new Howl.
const sound = new Howl({
  src: ["https://cdn.collaborationroom.ai/tones/gotitem.mp3"],
  loop: true,
});

interface ISideBarProps {
  //   peers: RemotePeer[];.
  //   makePrivateCall: (id: any) => void;
  callModalOpenAndClose: () => void;
  callAccepted: (id: string) => void;
  isSideBarOpen:boolean;
  callRejected: (id: string, typeAction: string) => void;
  isPrivateCallOn: boolean;
  openentCallerPerson: string;
  makePrivateCall: (id: string) => void;
  callModalOption: {
    isCallModal: boolean;
    idForMakingCall: string;
    messageForCall: string;
    callAcceptedButton: boolean;
    firstLetter: string;
    peerSessionIdRemote: string;
  };
}

const CallModal = (props: ISideBarProps) => {
  const [callModalTopLeft, setCallModalTopLeft] = useState({ top: 0, left: 0 });
  const [isDraggable, setIsDraggable] = useState(false);
  const [playbackRate,] = useState(true);

  let timeout = useRef() as any;

  useEffect(() => {
    if (props.callModalOption.callAcceptedButton) {
      timeout.current = setTimeout(() => {
        handleRing();
        props.callRejected(
          props.callModalOption.idForMakingCall as string,
          "CALL_DID_NOT_PICKUP"
        );
      }, 25000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.callModalOption.callAcceptedButton]);

  useEffect(() => {
    if (timeout.current) {
      return () => {
        clearTimeout(timeout.current);
      };
    }
  }, []);

  const handlePointerDown = () => {
    setIsDraggable(true);
  };

  const handlePointerUp = () => {
    setIsDraggable(false);
  };
  useEffect(() => {
    if (playbackRate && props.callModalOption.callAcceptedButton) {
      sound.play();
      Howler.volume(1);
    } else {
      sound.stop();
    }
    return () => {
      handleRing();
    };
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [playbackRate, props.callModalOption.callAcceptedButton]);
  const handlePointerMove = (e: any) => {
    if (isDraggable) {
      setCallModalTopLeft({
        left: callModalTopLeft.left + e.movementX,
        top: callModalTopLeft.top + e.movementY,
      });
    }

    // onPointerMove(e);
  };

  const handleRing = () => {
    sound.stop();
    Howler.volume(0);
  };
  return (
    <div
      className="modal_call"
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerMove={handlePointerMove}
      onPointerLeave={handlePointerUp}
      style={{
        transform: `translateX(${callModalTopLeft.left}px) translateY(${callModalTopLeft.top}px)`, right:`${props.isSideBarOpen ? "55%" :"45%"}`
      }}
    >
      {/* <h1 className="modal__title">Sammer</h1> */}
      <div className="modal_first_letter">
        <p>{props.callModalOption.firstLetter}</p>
      </div>
      <p className="modal__text">{props.callModalOption.messageForCall}</p>
      {!props.isPrivateCallOn && !props.callModalOption.callAcceptedButton ? (
        <p className="calling_modal">calling</p>
      ) : null}
      <div className="modal_btn_container">
        {props.callModalOption.callAcceptedButton ? (
          <button
            className="modal__btn"
            style={{ background: "#08ba08" }}
            onClick={() => {
              handleRing();
              clearTimeout(timeout.current);
              props.callAccepted(
                props.callModalOption.idForMakingCall as string
              );
            }}
          >
            <FontAwesomeIcon icon={faPhone} style={{ fontSize: "16px" }} />
          </button>
        ) : null}
        <button
          className="modal__btn"
          style={{ background: "#ff0000" }}
          onClick={() => {
            handleRing();
            if (props.isPrivateCallOn) {
              props.makePrivateCall(props.openentCallerPerson);
            } else if (props.callModalOption.peerSessionIdRemote !== "") {
              props.callRejected(
                props.callModalOption.peerSessionIdRemote,
                "CALL_CUT_BY_CALLER"
              );
            } else {
              clearTimeout(timeout.current);
              props.callRejected(
                props.callModalOption.idForMakingCall as string,
                "CALL_REJECTED"
              );
            }
          }}
        >
          <FontAwesomeIcon icon={faPhone} style={{ fontSize: "16px" }} />
        </button>
      </div>

      {/* Commenting for removing the cross icon that minimizes the private call modal  */}
      {/* {props.isPrivateCallOn ? (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href="!#"
          className="link-2"
          onClick={(e) => {
            handleRing();
            e.preventDefault();
            props.callModalOpenAndClose();
          }}
        ></a>
      ) : null} */}
    </div>
  );
};

export default CallModal;
