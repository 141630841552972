import { IProfileProps } from "./interface";
import NameAvatar from "./../NameAvatar";

import "./styles.css";

const Profile = (props: IProfileProps) => {
  const { name = "", role = "" } = props;
  return (
    <div className="profile">
      <NameAvatar text={name} className="profile-avatar"/>
      <div className="text">
        <h6>{name}</h6>
        <p>{role}</p>
      </div>
    </div>
  );
};

export default Profile;
