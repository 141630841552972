import { IRoomCardProps } from "./interface";
import NameAvatar from "./../NameAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { IConnectionDetails } from "../../Pages/RoomPage/interfaces";
import { getConnectionDetails } from "../../services/rooms";
import { setAudioAllowed, setPrivateCallAllowed, setScaleResolution, setViewMode } from "../../actions/roomActions";
import { REACT_APP_SCREENCONTROL } from "../../Pages/RoomPage/constants";
import { checkForSupManAdmin } from "../../utils/checkForSupAdmin";
let electronOpened = false;

interface IMembers {
  name?: string;
}

interface IMemberList {
  members: IMembers[];
}

const MemberList = (members: IMemberList) => {
  if (members && members.members && members.members.length) {
    return (
      <Fragment>
        {members.members.slice(0, 5).map((member: any, index: number) => {
          return (
            <NameAvatar
              key={index}
              text={member.name}
              className="dashboard-avatar"
            />
          );
        })}
        {members.members.length > 5 ? (
          <div className="dashboard-avatar">
            <p className={`centered`}>+{members.members.length - 5}</p>
          </div>
        ) : null}
      </Fragment>
    );
  } else {
    return <Fragment></Fragment>;
  }
};

const RoomCard = (props: IRoomCardProps) => {
  const {
    name = "",
    members = [],
    background,
    colors,
    image,
    duration,
    creator,
    tempRoom,
    // roomLength,
    viewMode,
  } = props;


  const username = useSelector(
    (state: RootState) => state.userDetails.userName
  );
  const role = useSelector((state: RootState) => state.userDetails.role);
  const environments = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  );
  const screenControl = environments.REACT_APP_SCREENCONTROL
    ? environments.REACT_APP_SCREENCONTROL.status
    : REACT_APP_SCREENCONTROL;

  const enableDesktopApp = useSelector(
    (state: RootState) => state.userDetails.enableDesktopApp
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUserRole = useSelector(
    (state: RootState) => state.userDetails.role
  );
  const currentRoomname = useSelector(
    (state: RootState) => state.roomsData.currentRoom
  );

  const imageUrl =
    process.env.REACT_APP_API_URL + "/getscreenshotimage/" + image;

  useEffect(() => {
    let tempRoom = localStorage.getItem(username);
    let tempRoomViewMode = localStorage.getItem(
      username + "viewMode"
    ) as string;
    if (name === tempRoom?.toUpperCase()) {
      dispatch(setViewMode(tempRoomViewMode));
      dispatch(setScaleResolution(props.screenScale, props.videoScale));

      if (enableDesktopApp || screenControl) {
        electronConfig(name);
      } else {
        navigate(`/room/${name}`);
      }

      localStorage.removeItem(username);
      localStorage.removeItem(username + "viewMode");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, username, dispatch, navigate]);

  useEffect(() => {
    // @ts-ignore
    if (currentRoomname === name) {
      electronConfig(currentRoomname);
      dispatch(setScaleResolution(props.screenScale, props.videoScale));
      // dispatch(setViaAppFalse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomname]);

  const openElectronApp = async (action: string) => {

    dispatch(setAudioAllowed(props.audioAllowedInRoom))
    dispatch(setPrivateCallAllowed(props.allowPrivateCallinSilentRoom))
    // Case: Auto enter in room

    // if (viaapp) {
    //   dispatch(setViewMode(currentViewMode));
    // } else {
    dispatch(setScaleResolution(props.screenScale, props.videoScale));
    dispatch(setViewMode(viewMode));
    // }

    if (
      currentUserRole === "Agent" &&
      !electronOpened &&
      viewMode === "supervisor"
    ) {
      electronOpened = true;
      electronConfig(name);
    }
    // Manual click handle
    if (action === "click" && currentUserRole === "Agent") {
      electronConfig(name);
    }

    // Other than Agent Role Case
    if (action === "click" && currentUserRole !== "Agent") {
      navigate(`/room/${name}`);
    }
  };

  const electronConfig = async (roomname: string) => {
    // set viewMode in redux (supervisor or meeting)

    if (role === "Agent") {
      const connectionDetails: IConnectionDetails = await getConnectionDetails(
        name
      );

      let hostname = window.location.origin;

      if (hostname.includes("http://localhost")) {
        hostname = "http://localhost:8080";
      }

      if (hostname.includes("https://localhost")) {
        hostname = "https://localhost:8080";
      }

      let inProtocol =
        "electron-fiddle://" +
        hostname +
        "/" +
        connectionDetails.path +
        "/" +
        connectionDetails.iv +
        "/" +
        connectionDetails.digest +
        "/" +
        connectionDetails.payload;
      if (screenControl || enableDesktopApp) {
        window.location.href = inProtocol; //Launch alternative, typically app download.
      }
    }

    // if( name === currentRoomname){
    navigate(`/room/${roomname}`);
    // }
  };

  return (
    <li>
      {/* <Link to={`/room/${name}`} onClick={() => openElectronApp}> */}
      <div className="roomcardlink" onClick={() => openElectronApp("click")}>
        {checkForSupManAdmin(currentUserRole, "equalTo") ? (
          <div className="attendance_main">
            <div className="attendance_main_inside">
              <h6>Attendance</h6>
              <p>
                {props.attendanceDetail.countusers
                  ? props.attendanceDetail.countusers
                  : 0}{" "}
                out of {props.userCount}
              </p>
            </div>
            <div className="attendance_main_inside">
              <h6>System Issue</h6>
              <p>
                {props.attendanceDetail.technicalissue
                  ? props.attendanceDetail.technicalissue
                  : 0}
              </p>
            </div>
            <div className="attendance_main_inside">
              <h6>Lunch</h6>
              <p>
                {props.attendanceDetail.lunch
                  ? props.attendanceDetail.lunch
                  : 0}
              </p>
            </div>
            <div className="attendance_main_inside">
              <h6>Break</h6>
              <p>
                {props.attendanceDetail.break
                  ? props.attendanceDetail.break
                  : 0}
              </p>
            </div>
          </div>
        ) : null}
        <div
          className="box roomcard_main"
          style={{ backgroundColor: background, color: colors }}
        >
          <div className="imgContainer">
            <h4 style={{ color: colors }}>
              {name}
              {tempRoom ? (
                <div className="creator_room">
                  <span>created by ({creator})</span>
                </div>
              ) : null}
            </h4>
            {tempRoom ? (
              <div id="countdown">
                <ul className="countdown_ul">
                  <li className="duration_list">
                    <span id="days">{duration.split(":")[0]}</span>days
                  </li>
                  <li className="duration_list">
                    <span id="hours">{duration.split(":")[1]}</span>Hours
                  </li>
                  <li className="duration_list">
                    <span id="minutes">{duration.split(":")[2]}</span>Minutes
                  </li>
                  <li className="duration_list">
                    <span id="seconds">{duration.split(":")[3]}</span>Seconds
                  </li>
                </ul>
              </div>
            ) : null}

            {image && <img className="imgSize" src={imageUrl} alt="new" />}
          </div>
          <div className="line"></div>
          <div className="img_usr">
            <MemberList members={members} />
            <div className="ib">{members.length}</div>
          </div>
          <div className="vcm">
            <span>
              <FontAwesomeIcon icon={faVideo} />
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default RoomCard;
