"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.qrtcStop = exports.qrtcRun = exports.persistentStart = exports.persistentEnd = exports.registerStateListener = exports.registerOnStatsListener = exports.disconnect = exports.connect = exports.mapTrack = exports.mapStream = exports.addEvent = exports.enableDataCollection = exports.disableDataCollection = exports.addKeys = exports.setUserRating = exports.setConfig = exports.initSDK = exports.sdkLogWithoutTrace = exports.sdkLog = void 0;
var watchrtcsocket_1 = require("./watchrtcsocket");
var watchrtchttp_1 = require("./watchrtchttp");
var utils_1 = require("./utils");
var backoff_1 = require("./backoff");
var version_1 = require("./version");
var cp_ips_1 = require("./cp-ips");
var API_VERSION = "v1";
var isBrowser = typeof window !== "undefined";
var globalContext = isBrowser ? window : global;
var standardGetstats = true;
var isFirefox = isBrowser ? !!globalContext.mozRTCPeerConnection : false;
var isSafari = isBrowser
    ? !isFirefox && globalContext.RTCPeerConnection && !globalContext.navigator.webkitGetUserMedia
    : false;
// Data structure for RTCPeerConnection related stuff we need
var openChannels = {};
var peerconnectioncounter = 0;
var getStatsCounter = 0;
var connectionData = {};
var watchrtcIdentifiers = {
    rtcRoomId: undefined,
    rtcPeerId: undefined,
    projectId: undefined,
};
var watchrtcConfig = null;
var http = null;
var socket = null;
var trace;
var lastConnectionOpen = 0; // so we know when was the last active connection seen
var getStatsInterval;
var tryingToConnectSocket = false;
var hardwareInfo;
var currentAudioOutputId;
var authFailed = false;
var lostConnectionTs = 0;
var isManualConnect = false;
var isManualDisconnect = false;
var getStatsCallback = null;
var stateListener = null;
var freshConnection = true;
var exponentialBackoff = new backoff_1.default();
var candidatePairIPsExtracted = false;
var connectionStateReason = "";
exports.sdkLog = (0, utils_1.getSdkLogger)(function () { return watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.logLevel; });
exports.sdkLogWithoutTrace = (0, utils_1.getSdkLogWithoutTrace)(function () { return watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.logLevel; });
var debugTrack = function (track) {
    if (track) {
        return "".concat(track.kind, ":").concat(track.id, " state:").concat(track.readyState, " muted:").concat(track.muted, " label:").concat(track.label);
    }
    return "no track";
};
// Data structure for storing and applying mapTrack
var mappedTrack = {};
var maybeOpenWebsocketConnection = function (_a) {
    var _b;
    var _c;
    var _d = _a.forceRecreate, forceRecreate = _d === void 0 ? false : _d, _e = _a.reconnecting, reconnecting = _e === void 0 ? false : _e, _f = _a.pcId, pcId = _f === void 0 ? "PC_unknown" : _f, _g = _a.nailUp, nailUp = _g === void 0 ? false : _g;
    if (socket === null || socket === void 0 ? void 0 : socket.isDisabledDataCollection()) {
        (0, exports.sdkLog)("debug", "maybeOpenWebsocketConnection. disabledDataCollection, ignore [".concat(pcId, "]"));
        return;
    }
    (0, exports.sdkLog)("debug", "maybeOpenWebsocketConnection called: [".concat(pcId, "]"), {
        forceRecreate: forceRecreate,
        reconnecting: reconnecting,
        isManualDisconnect: isManualDisconnect,
        isManualConnect: isManualConnect,
        tryingToConnectSocket: tryingToConnectSocket,
        watchrtcConfig: JSON.stringify(watchrtcConfig),
        disabled: socket === null || socket === void 0 ? void 0 : socket.isDisabledDataCollection(),
        openChannels: JSON.stringify(openChannels),
    });
    var opened = ((_c = socket === null || socket === void 0 ? void 0 : socket.connection) === null || _c === void 0 ? void 0 : _c.readyState) === globalContext.WebSocket.OPEN;
    if (!isManualConnect && isManualDisconnect && !opened) {
        (0, exports.sdkLog)("debug", "maybeOpenWebsocketConnection. WS connection closed and should not be reopened. [".concat(pcId, "]"));
        return;
    }
    isManualDisconnect = false;
    if (opened) {
        var roomIdOrPeerIdChanged = (0, utils_1.isRoomIdOrPeerIdChanged)(watchrtcIdentifiers, watchrtcConfig);
        if (roomIdOrPeerIdChanged && forceRecreate) {
            (0, exports.sdkLog)("debug", "maybeOpenWebsocketConnection. Closing WS connection. [".concat(pcId, "]"));
            socket === null || socket === void 0 ? void 0 : socket.close();
        }
        else {
            (0, exports.sdkLog)("debug", "maybeOpenWebsocketConnection. WS connection already opened [".concat(pcId, "]"));
            return;
        }
    }
    var connectionCount = (0, utils_1.countOfValidConnections)(openChannels);
    if (!isManualConnect && connectionCount < 1 && !tryingToConnectSocket) {
        (0, exports.sdkLog)("debug", "maybeOpenWebsocketConnection. WS connection not opened - previous connect call not finished or missing peer connection [".concat(pcId, "]"), {
            openChannels: JSON.stringify(openChannels),
            connectionCount: connectionCount,
            tryingToConnectSocket: tryingToConnectSocket,
            isManualConnect: isManualConnect,
        });
        return;
    }
    var canConnect = (0, utils_1.validateConfig)(watchrtcConfig);
    var id = Object.keys(openChannels)[connectionCount - 1]; // not very critical, but for consistency with trace
    if (!canConnect) {
        tryingToConnectSocket = false;
        (0, exports.sdkLog)("debug", "maybeOpenWebsocketConnection. WS connection not opened - invalid config [".concat(pcId, "]"), {
            watchrtcConfig: watchrtcConfig,
        });
        // send a disconnected event with the reason 'clientRejectedNoRetry'
        if (stateListener) {
            connectionStateReason = "clientRejectedNoRetry";
            stateListener({ connectionStatus: "disconnected", reason: connectionStateReason });
        }
        return;
    }
    if (watchrtcConfig.keys) {
        Object.keys(watchrtcConfig.keys || {}).forEach(function (k) {
            if (typeof watchrtcConfig.keys[k] === "string") {
                watchrtcConfig.keys[k] = [watchrtcConfig.keys[k]];
            }
        });
    }
    var useToken = !!watchrtcConfig.rtcToken;
    var wsConnectionData = (0, utils_1.getConnectionData)("ws", useToken ? watchrtcConfig.rtcToken : watchrtcConfig.rtcApiKey, watchrtcConfig.proxyUrl);
    if (!socket) {
        (0, exports.sdkLog)("debug", "maybeOpenWebsocketConnection. WS socket wasn't initialized [".concat(pcId, "]"));
    }
    tryingToConnectSocket = true;
    (0, exports.sdkLog)("debug", "maybeOpenWebsocketConnection. Opening websocket connection [".concat(pcId, "]"));
    startStatsCollection(nailUp);
    var wsOpeningTimestamp = Date.now();
    var queryParams = (_b = {},
        _b[useToken ? "token" : "apiKey"] = wsConnectionData.key,
        _b.timestamp = Date.now().toString(),
        _b.apiVersion = API_VERSION,
        _b.sessionId = globalContext.watchRTCSessionId,
        _b);
    var querystring = Object.entries(queryParams)
        .map(function (_a) {
        var k = _a[0], v = _a[1];
        return "".concat(k, "=").concat(v);
    })
        .join("&");
    socket === null || socket === void 0 ? void 0 : socket.connect({
        url: "".concat(wsConnectionData.url, "?").concat(querystring),
        options: { cleanOldTraces: freshConnection },
        onData: function (data) {
            var _a;
            for (var _i = 0, _b = Object.entries(data); _i < _b.length; _i++) {
                var _c = _b[_i], key = _c[0], value = _c[1];
                connectionData[key] = value;
            }
            watchrtcIdentifiers.projectId = data.projectId;
            tryingToConnectSocket = false;
            watchrtcConfig.allowBrowserLogCollection = Boolean(data.collectConsoleLogEnabled);
            if (!watchrtcConfig.allowBrowserLogCollection) {
                (0, utils_1.restoreOriginalConsoleMethods)();
                // clean up collected logs from socket buffer
                if (socket === null || socket === void 0 ? void 0 : socket.buffer) {
                    socket.buffer = socket.buffer.filter(function (data) { return data[0] !== "log"; });
                }
            }
            else {
                if (!((_a = watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.console) === null || _a === void 0 ? void 0 : _a.override) && data.collectConsoleLogLevel) {
                    (0, utils_1.setConsoleLevel)(data.collectConsoleLogLevel, trace);
                }
            }
            (0, exports.sdkLog)("info", "Connection established. watchRTCConnectionId: ".concat(data.connectionId, " sdkVersion:").concat(version_1.default, " [").concat(pcId, "]"));
            if (data.interval !== watchrtcConfig.collectionInterval) {
                var oldInterval = watchrtcConfig.collectionInterval;
                watchrtcConfig.collectionInterval = data.interval;
                resetConnectionState();
                startStatsCollection();
                (0, exports.sdkLog)("error", "Collection interval mismatch - connection state reset");
                trace({ data: ["collectionIntervalChange", null, { oldInterval: oldInterval }] });
            }
            trace({ data: ["watchrtc", id, __assign(__assign(__assign({}, watchrtcConfig), data), { sdkVersion: version_1.default })] });
            if (reconnecting) {
                (0, exports.sdkLogWithoutTrace)("debug", "websocket onData reconnecting");
                trace({ data: ["reconnect", null, null] });
            }
            if (hardwareInfo) {
                trace({ data: ["hardware", null, hardwareInfo] });
            }
            trace({ data: ["sessionId", null, globalContext.watchRTCSessionId] });
        },
        onError: function (_, type) {
            (0, exports.sdkLogWithoutTrace)("debug", "websocket onError ".concat(type));
            if (type === "auth") {
                authFailed = true;
                tryingToConnectSocket = false;
                connectionStateReason = "serverRejectedNoRetry";
            }
            else if (type === "connection") {
                connectionStateReason = "cantConnectToServer";
            }
            else if (type === "timeout") {
                connectionStateReason = "connectedNotResponding";
            }
        },
        onOpen: function () {
            freshConnection = false;
            connectionStateReason = "";
            if (stateListener) {
                stateListener({ connectionStatus: "connected" });
            }
            if (reconnecting) {
                var reconnectDuration = Date.now() - lostConnectionTs;
                trace({ data: ["reconnectDuration", null, reconnectDuration] });
            }
            authFailed = false;
            var delta = Date.now() - wsOpeningTimestamp;
            (0, exports.sdkLog)("debug", "maybeOpenWebsocketConnection. Connection opened. Opening time - ".concat(delta, " ms [").concat(pcId, "]").concat(exponentialBackoff.attempts > 0 ? " - retried ".concat(exponentialBackoff.attempts, " times}") : ""));
            // reset exponential backoff
            exponentialBackoff.reset();
        },
        onClose: function (closeEvent) {
            connectionStateReason = connectionStateReason || "disconnectedPrematurely";
            if (stateListener) {
                // If no existing reason, it means a connection lost event. Otherwise, takes the existing reason from the error.
                stateListener({ connectionStatus: "disconnected", reason: connectionStateReason });
            }
            (0, exports.sdkLog)("info", "maybeOpenWebsocketConnection. Connection closed. watchRTCConnectionId: ".concat((connectionData === null || connectionData === void 0 ? void 0 : connectionData.connectionId) || "null", " - ").concat(connectionStateReason, " [").concat(pcId, "]"));
            var code = closeEvent.code, reason = closeEvent.reason, wasClean = closeEvent.wasClean;
            (0, exports.sdkLogWithoutTrace)("debug", "close event. authFailed: ".concat(authFailed, " code: ").concat(code, " reason: ").concat(reason, " wasClean: ").concat(wasClean, " connectionStateReason: ").concat(connectionStateReason), { authFailed: authFailed, code: code, reason: reason, wasClean: wasClean });
            if (authFailed) {
                (0, exports.sdkLogWithoutTrace)("debug", "websocket authFailed");
            }
            else if (!isManualDisconnect && !["applicationDisconnected"].includes(connectionStateReason)) {
                var timeToWait = exponentialBackoff.next();
                (0, exports.sdkLogWithoutTrace)("debug", "websocket onClose reconnecting - attempt: ".concat(exponentialBackoff.attempts, " after: ").concat(timeToWait, "ms"));
                lostConnectionTs = Date.now();
                setTimeout(function () {
                    maybeOpenWebsocketConnection({ reconnecting: true, pcId: pcId });
                }, timeToWait);
            }
        },
    });
};
var startStatsCollection = function (nailUp) {
    if (nailUp === void 0) { nailUp = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var collectStats, _loop_1, _i, _a, pcInfo;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    collectStats = function () { return __awaiter(void 0, void 0, void 0, function () {
                        var stats, _i, _a, pcInfo, _b, peer, streams;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    if (!(!isManualConnect && (0, utils_1.countOfValidConnections)(openChannels) === 0)) return [3 /*break*/, 1];
                                    if (watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.logGetStats) {
                                        (0, exports.sdkLog)("debug", "getStatsInterval. No valid connections at this time");
                                    }
                                    // if we don't have any connection for 20 sec we can close the socket
                                    if (lastConnectionOpen && lastConnectionOpen + 20000 < Date.now()) {
                                        freshConnection = true;
                                        tryingToConnectSocket = false;
                                        globalContext.clearInterval(getStatsInterval);
                                        connectionStateReason = "applicationDisconnected";
                                        socket === null || socket === void 0 ? void 0 : socket.close();
                                        (0, exports.sdkLog)("info", "Last connection closed. watchRTCConnectionId: ".concat(connectionData === null || connectionData === void 0 ? void 0 : connectionData.connectionId, " sdkVersion: ").concat(version_1.default));
                                    }
                                    return [3 /*break*/, 6];
                                case 1:
                                    lastConnectionOpen = Date.now();
                                    stats = { connections: {}, streams: {} };
                                    _i = 0, _a = Object.values(openChannels);
                                    _c.label = 2;
                                case 2:
                                    if (!(_i < _a.length)) return [3 /*break*/, 5];
                                    pcInfo = _a[_i];
                                    if (watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.logGetStats) {
                                        (0, exports.sdkLogWithoutTrace)("debug", "getStatsInterval. ".concat(pcInfo.id, " signalingState: ").concat(pcInfo.pc.signalingState));
                                    }
                                    if (!(pcInfo.pc.signalingState !== "closed")) return [3 /*break*/, 4];
                                    return [4 /*yield*/, getStats(pcInfo)];
                                case 3:
                                    _b = _c.sent(), peer = _b.peer, streams = _b.streams;
                                    stats.connections = __assign(__assign({}, stats.connections), peer);
                                    stats.streams = __assign(__assign({}, stats.streams), streams);
                                    _c.label = 4;
                                case 4:
                                    _i++;
                                    return [3 /*break*/, 2];
                                case 5:
                                    if (getStatsCallback) {
                                        getStatsCallback(stats);
                                    }
                                    _c.label = 6;
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); };
                    if (!!getStatsInterval) return [3 /*break*/, 2];
                    return [4 /*yield*/, collectStats()];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    globalContext.clearInterval(getStatsInterval);
                    _b.label = 3;
                case 3:
                    if (nailUp) {
                        (0, exports.sdkLogWithoutTrace)("debug", "startStatsCollection - nail up");
                        _loop_1 = function (pcInfo) {
                            if (pcInfo.pc.signalingState !== "closed") {
                                pcInfo.pc.getStats(null).then(function (res) {
                                    var now = {};
                                    if (standardGetstats || isFirefox || isSafari) {
                                        if (isFirefox) {
                                            res.forEach(function (report) {
                                                now["".concat(report.type, "_").concat(report.id)] = report;
                                            });
                                        }
                                        else {
                                            now = (0, utils_1.map2obj)(res);
                                        }
                                    }
                                    else {
                                        now = (0, utils_1.mangleChromeStats)(pcInfo.pc, res);
                                    }
                                    pcInfo.nailUpRef = JSON.parse(JSON.stringify(now));
                                });
                            }
                        };
                        // collect the new reference reports to use
                        for (_i = 0, _a = Object.values(openChannels); _i < _a.length; _i++) {
                            pcInfo = _a[_i];
                            _loop_1(pcInfo);
                        }
                    }
                    getStatsInterval = globalContext.setInterval(function () {
                        return __awaiter(this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, collectStats()];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        });
                    }, watchrtcConfig.collectionInterval);
                    return [2 /*return*/];
            }
        });
    });
};
var getStats = function (pcInfo) {
    return new Promise(function (resolve, __reject) {
        if (pcInfo) {
            var id_1 = pcInfo.id, pc_1 = pcInfo.pc, prev_1 = pcInfo.prev, nailUpRef_1 = pcInfo.nailUpRef;
            if (standardGetstats || isFirefox || isSafari) {
                pc_1.getStats(null).then(function (res) {
                    if (watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.logGetStats) {
                        (0, exports.sdkLogWithoutTrace)("debug", "getStats res", { res: res });
                    }
                    var now = {};
                    if (isFirefox) {
                        res.forEach(function (report) {
                            now["".concat(report.type, "_").concat(report.id)] = report;
                        });
                    }
                    else {
                        now = (0, utils_1.map2obj)(res);
                    }
                    var base = JSON.parse(JSON.stringify(now)); // our new prev
                    now = (0, utils_1.applyPatchForRTT)(prev_1, now);
                    if (nailUpRef_1) {
                        now = (0, utils_1.subtractNailUpReferenceStats)(nailUpRef_1, now);
                    }
                    if (!candidatePairIPsExtracted) {
                        var ips = (0, cp_ips_1.extractSuccessCandidatePairsIPs)(now);
                        if (ips) {
                            candidatePairIPsExtracted = true;
                            trace({ data: ["candidatePairIPs", id_1, ips] });
                        }
                    }
                    var data = (0, utils_1.deltaCompression)(prev_1, now);
                    data.getStatsId = ++getStatsCounter;
                    if (watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.logGetStats) {
                        (0, exports.sdkLogWithoutTrace)("debug", "getStats(null) [".concat(id_1, "]"), { data: data });
                    }
                    if ((data === null || data === void 0 ? void 0 : data.timestamp) !== null && (data === null || data === void 0 ? void 0 : data.timestamp) !== -Infinity) {
                        trace({ data: ["getstats", id_1, data] });
                    }
                    else {
                        trace({ data: ["nostats", null, null] });
                    }
                    pcInfo.prev = base;
                    resolve((0, utils_1.exposeApplicationStatsForPC)(id_1, prev_1, now, mappedTrack));
                });
            }
            else {
                pc_1.getStats(function (res) {
                    var now = (0, utils_1.mangleChromeStats)(pc_1, res);
                    var base = JSON.parse(JSON.stringify(now)); // our new prev
                    if (nailUpRef_1) {
                        now = (0, utils_1.subtractNailUpReferenceStats)(nailUpRef_1, now);
                    }
                    if (!candidatePairIPsExtracted) {
                        var ips = (0, cp_ips_1.extractSuccessCandidatePairsIPs)(now);
                        if (ips) {
                            candidatePairIPsExtracted = true;
                            trace({ data: ["candidatePairIPs", id_1, ips] });
                        }
                    }
                    var data = (0, utils_1.deltaCompression)(prev_1, now);
                    data.getStatsId = ++getStatsCounter;
                    if (watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.logGetStats) {
                        (0, exports.sdkLogWithoutTrace)("debug", "getStats() [".concat(id_1, "]"), { data: data });
                    }
                    if ((data === null || data === void 0 ? void 0 : data.timestamp) !== null && (data === null || data === void 0 ? void 0 : data.timestamp) !== -Infinity) {
                        trace({ data: ["getstats", id_1, data] });
                    }
                    else {
                        trace({ data: ["nostats", null, null] });
                    }
                    pcInfo.prev = base;
                    resolve((0, utils_1.exposeApplicationStatsForPC)(id_1, prev_1, now, mappedTrack));
                });
            }
        }
    });
};
var getHardware = function () {
    setTimeout(function () {
        // if get hardware take more than 50 ms then do not save it
        var getHardwareStartTime = Date.now();
        (0, utils_1.getHardwareInfo)()
            .then(function (hwInfo) {
            var getHardwareTime = Date.now() - getHardwareStartTime;
            if (getHardwareTime <= 50000) {
                hardwareInfo = hwInfo;
                (0, exports.sdkLog)("debug", "getHardware", { hardwareInfo: hardwareInfo });
            }
            else {
                (0, exports.sdkLog)("debug", "getHardware failure: getHardwareTime: ".concat(getHardwareTime), { hardwareInfo: hardwareInfo });
            }
        })
            .catch(function (err) {
            (0, exports.sdkLog)("error", "Error. Get hardware info: ".concat(err.message));
        });
    }, 0);
};
/**
 * Initialize SDK.
 * @param watchrtc
 * @param prefixesToWrap
 * @param services
 */
var initSDK = function (watchrtc, prefixesToWrap, services) {
    var _a, _b;
    var initialized = globalContext.watchRTCInitialized;
    if (initialized) {
        (0, exports.sdkLogWithoutTrace)("info", "init. watchRTC SDK already has been initialized");
        return;
    }
    if (!globalContext.RTCPeerConnection) {
        (0, exports.sdkLogWithoutTrace)("info", "init. RTCPeerConnection does not exist in global globalContext");
        return;
    }
    var isRTCPeerConnectionNative = globalContext.RTCPeerConnection.toString().indexOf("[native code]") !== -1;
    if (!isRTCPeerConnectionNative && isBrowser) {
        (0, exports.sdkLog)("info", "init. RTCPeerConnection object has been already overridden");
    }
    globalContext.watchRTCInitialized = true;
    globalContext.watchRTCSessionId = (0, utils_1.generateID)();
    getHardware();
    if (isBrowser) {
        var urlParams = new URLSearchParams(location.search);
        if (urlParams.has("watchrtc") && urlParams.get("watchrtc") === "debug") {
            watchrtc.logLevel = "debug";
        }
    }
    socket = (services === null || services === void 0 ? void 0 : services.socketService) || new watchrtcsocket_1.default();
    http = (services === null || services === void 0 ? void 0 : services.httpService) || new watchrtchttp_1.default();
    watchrtc.collectionInterval = (_a = watchrtc.collectionInterval) !== null && _a !== void 0 ? _a : 8000;
    //watchrtc.logLevel = watchrtc.logLevel || "debug";
    // change default SDK logs level to info, debug too chatty.
    watchrtc.logLevel = watchrtc.logLevel || "info";
    watchrtcConfig = watchrtc;
    watchrtcIdentifiers.rtcRoomId = watchrtcConfig.rtcRoomId;
    watchrtcIdentifiers.rtcPeerId = watchrtcConfig.rtcPeerId;
    trace = socket.trace;
    if (watchrtcConfig.wsUrl) {
        (0, exports.sdkLog)("info", "\"wsUrl\" config property is deprecated. Use \"proxyUrl\" instead of it");
    }
    if (watchrtcConfig.proxyUrl) {
        (0, exports.sdkLog)("info", "\"proxyUrl\" is used");
    }
    if (watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.debug) {
        (0, exports.sdkLog)("info", "\"debug\" config property is deprecated. Use \"logLevel\" instead of it");
    }
    prefixesToWrap.forEach(function (prefix) {
        if (!globalContext[prefix + "RTCPeerConnection"]) {
            (0, exports.sdkLogWithoutTrace)("debug", "RTCPeerConnection prefixes override failed");
            return;
        }
        var origPeerConnection = globalContext[prefix + "RTCPeerConnection"];
        var peerconnection = function (config, constraints) {
            if (config === null || config === void 0 ? void 0 : config.watchrtc) {
                watchrtcConfig = __assign(__assign({}, watchrtcConfig), config.watchrtc);
                watchrtcIdentifiers.rtcRoomId = watchrtcConfig.rtcRoomId;
                watchrtcIdentifiers.rtcPeerId = watchrtcConfig.rtcPeerId;
            }
            var pc = new origPeerConnection(config, constraints);
            var id = "PC_" + peerconnectioncounter++;
            pc.__rtcStatsId = id;
            openChannels[id] = {
                id: id,
                pc: pc,
                validConnection: false,
            };
            if (!config) {
                config = { nullConfig: true };
            }
            config = JSON.parse(JSON.stringify(config)); // deepcopy
            // don't log credentials
            ((config && config.iceServers) || []).forEach(function (server) {
                delete server.credential;
            });
            if (config === null || config === void 0 ? void 0 : config.watchrtc) {
                delete config.watchrtc;
            }
            if (isFirefox) {
                config.browserType = "moz";
            }
            else {
                config.browserType = "webkit";
            }
            (0, exports.sdkLog)("debug", "new RTCPeerConnection called.", {
                config: JSON.stringify(config),
                constraints: constraints,
            });
            trace({ data: ["create", id, config] });
            // TODO: do we want to log constraints here? They are chrome-proprietary.
            // http://stackoverflow.com/questions/31003928/what-do-each-of-these-experimental-goog-rtcpeerconnectionconstraints-do
            if (constraints) {
                trace({ data: ["constraints", id, constraints] });
            }
            pc.addEventListener("icecandidate", function (e) {
                (0, exports.sdkLogWithoutTrace)("debug", "onicecandidate id:[".concat(id, "], candidate:[").concat(e.candidate, "]"));
                trace({ data: ["onicecandidate", id, e.candidate] });
            });
            pc.addEventListener("icecandidateerror", function (e) {
                (0, exports.sdkLogWithoutTrace)("debug", "onicecandidateerror id:[".concat(id, "], error:[").concat(e.errorCode, ":").concat(e.errorText, "]"));
                trace({ data: ["onicecandidateerror", id, "".concat(e.errorCode, ":").concat(e.errorText)] });
            });
            pc.addEventListener("addstream", function (e) {
                trace({
                    data: [
                        "onaddstream",
                        id,
                        e.stream.id +
                            " " +
                            e.stream.getTracks().map(function (t) {
                                return t.kind + ":" + t.id;
                            }),
                    ],
                });
            });
            pc.addEventListener("track", function (e) {
                trace({
                    data: [
                        "ontrack",
                        id,
                        debugTrack(e.track) +
                            " " +
                            e.streams.map(function (stream) {
                                return "stream:" + stream.id;
                            }),
                    ],
                });
                // https://redmine.testrtc.com/issues/7166
                e.track.addEventListener("ended", function () {
                    trace({
                        data: [
                            "ontrack",
                            id,
                            debugTrack(e.track) +
                                " " +
                                e.streams.map(function (stream) {
                                    return "stream:" + stream.id;
                                }),
                        ],
                    });
                });
                // Disable mute/umute tracks events: https://redmine.testrtc.com/issues/9240
                // e.track.addEventListener("mute", () => {
                //   trace({
                //     data: [
                //       "ontrack",
                //       id,
                //       debugTrack(e.track) +
                //         " " +
                //         e.streams.map((stream: any) => {
                //           return "stream:" + stream.id;
                //         }),
                //     ],
                //   });
                // });
                //
                // e.track.addEventListener("unmute", () => {
                //   trace({
                //     data: [
                //       "ontrack",
                //       id,
                //       debugTrack(e.track) +
                //         " " +
                //         e.streams.map((stream: any) => {
                //           return "stream:" + stream.id;
                //         }),
                //     ],
                //   });
                // });
            });
            pc.addEventListener("removestream", function (e) {
                trace({
                    data: [
                        "onremovestream",
                        id,
                        e.stream.id +
                            " " +
                            e.stream.getTracks().map(function (t) {
                                return t.kind + ":" + t.id;
                            }),
                    ],
                });
            });
            pc.addEventListener("signalingstatechange", function () {
                if (openChannels[id] && !openChannels[id].validConnection) {
                    openChannels[id].validConnection = true;
                    setTimeout(function () {
                        // only open websocket connection for the first valid connection
                        (0, exports.sdkLogWithoutTrace)("debug", "signalingstatechage. forceRecreate websocket connection[".concat(id, "]"), {
                            openChannels: JSON.stringify(openChannels),
                        });
                        maybeOpenWebsocketConnection({ forceRecreate: true, pcId: id });
                    }, 3000);
                }
                else {
                    (0, exports.sdkLog)("debug", "signalingstatechage. WS connection opening not triggered - peer connection not in channels or was already opened [".concat(id, "]"), { openChannels: JSON.stringify(openChannels) });
                }
                trace({ data: ["onsignalingstatechange", id, pc.signalingState] });
            });
            pc.addEventListener("iceconnectionstatechange", function () {
                (0, exports.sdkLogWithoutTrace)("debug", "oniceconnectionstatechange id:[".concat(id, "], state:[").concat(pc.iceConnectionState, "]"));
                trace({ data: ["oniceconnectionstatechange", id, pc.iceConnectionState] });
            });
            pc.addEventListener("icegatheringstatechange", function () {
                (0, exports.sdkLogWithoutTrace)("debug", "onicegatheringstatechange id:[".concat(id, "], state:[").concat(pc.iceGatheringState, "]"));
                trace({ data: ["onicegatheringstatechange", id, pc.iceGatheringState] });
            });
            pc.addEventListener("connectionstatechange", function () {
                (0, exports.sdkLogWithoutTrace)("debug", "onconnectionstatechange id:[".concat(id, "], state:[").concat(pc.connectionState, "]"));
                trace({ data: ["onconnectionstatechange", id, pc.connectionState] });
            });
            pc.addEventListener("negotiationneeded", function () {
                (0, exports.sdkLogWithoutTrace)("debug", "onnegotiationneeded id:[".concat(id, "]"));
                trace({ data: ["onnegotiationneeded", id, undefined] });
            });
            pc.addEventListener("datachannel", function (event) {
                (0, exports.sdkLogWithoutTrace)("debug", "ondatachannel id:[".concat(id, "], data:[").concat(event.channel.id, " ").concat(event.channel.label, "]"));
                trace({ data: ["ondatachannel", id, [event.channel.id, event.channel.label]] });
            });
            // https://redmine.testrtc.com/issues/6529
            // pc.addEventListener("iceconnectionstatechange", () => {
            //   if (pc.iceConnectionState === "connected") {
            //     getStats(openChannels[id]);
            //   }
            // });
            (0, exports.sdkLogWithoutTrace)("debug", "RTCPeerConnection override complete [".concat(id, "]."));
            return pc;
        };
        if ("HTMLMediaElement" in globalContext && "setSinkId" in HTMLMediaElement.prototype) {
            var nativeMethod_1 = HTMLMediaElement.prototype.setSinkId;
            HTMLMediaElement.prototype.setSinkId = function () {
                var selectedDeviceId = arguments[0];
                navigator.mediaDevices
                    .enumerateDevices()
                    .then(function (devices) {
                    var currentDevice = devices.find(function (_a) {
                        var deviceId = _a.deviceId;
                        return deviceId === selectedDeviceId;
                    });
                    if (currentDevice && currentDevice.deviceId !== currentAudioOutputId) {
                        trace({ data: ["audioOutputChange", null, currentDevice.label] });
                    }
                    currentAudioOutputId = selectedDeviceId;
                })
                    .catch(function (error) {
                    (0, exports.sdkLog)("debug", error.message, { error: error });
                });
                return nativeMethod_1.apply(this, arguments);
            };
        }
        // wrap RTCRtpTransceiver.setCodecPreferences
        if ("RTCRtpTransceiver" in globalContext && "setCodecPreferences" in globalContext.RTCRtpTransceiver.prototype) {
            var origRTCRtpTransceiver = globalContext.RTCRtpTransceiver;
            var nativeMethod_2 = origRTCRtpTransceiver.prototype.setCodecPreferences;
            origRTCRtpTransceiver.prototype.setCodecPreferences = function () {
                trace({ data: ["setCodecPreferences", this.__pcId, arguments] });
                return nativeMethod_2.apply(this, arguments);
            };
        }
        // wrap RTCRtpSender.setParameters
        if ("RTCRtpSender" in globalContext && "setParameters" in globalContext.RTCRtpSender.prototype) {
            var origRTCRtpSender = globalContext.RTCRtpSender;
            var nativeMethod_3 = origRTCRtpSender.prototype.setParameters;
            origRTCRtpSender.prototype.setParameters = function () {
                trace({ data: ["setParameters", this.__pcId, arguments] });
                return nativeMethod_3.apply(this, arguments);
            };
        }
        // wrap RTCRtpSender.replaceTrack
        if ("RTCRtpSender" in globalContext && "replaceTrack" in globalContext.RTCRtpSender.prototype) {
            var origRTCRtpSender = globalContext.RTCRtpSender;
            var nativeMethod_4 = origRTCRtpSender.prototype.replaceTrack;
            origRTCRtpSender.prototype.replaceTrack = function () {
                var track = arguments[0];
                if (track) {
                    trace({
                        data: ["replaceTrack", this.__pcId, debugTrack(track)],
                    });
                    // Check if the label is not the same as previous one - to detect a device change
                    var originalTrack = this.track;
                    if (originalTrack && originalTrack.label !== track.label) {
                        trace({ data: ["".concat(track.kind, "InputChange"), this.__pcId, track.label] });
                    }
                }
                else {
                    trace({ data: ["replaceTrack", this.__pcId, null] });
                }
                return nativeMethod_4.apply(this, arguments);
            };
        }
        // adding pc id to sender for above logic
        ["addTransceiver"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var _this_1 = this;
                    var streams = "";
                    if (arguments[1] && arguments[1].streams) {
                        streams = arguments[1].streams.map(function (s) { return "stream:" + s.id; }).join(";");
                    }
                    var trackOrKind = typeof arguments[0] === "string"
                        ? arguments[0]
                        : arguments[0].kind + ":" + arguments[0].id + " " + arguments[0].label;
                    var init = arguments[1] ? __assign(__assign({}, arguments[1]), { streams: streams }) : null;
                    trace({ data: [method, this.__rtcStatsId, [trackOrKind, init]] });
                    var transceiver = nativeMethod.apply(this, arguments);
                    transceiver.sender.__pcId = this.__rtcStatsId;
                    // https://redmine.testrtc.com/issues/7166
                    var track = arguments[0];
                    if (typeof track === "object") {
                        trace({
                            data: ["onlocaltrack", this.__rtcStatsId, debugTrack(track)],
                        });
                        track.addEventListener("ended", function () {
                            trace({
                                data: ["onlocaltrack", _this_1.__rtcStatsId, debugTrack(track)],
                            });
                        });
                        // Disable mute/umute tracks events: https://redmine.testrtc.com/issues/9240
                        // track.addEventListener("mute", () => {
                        //   trace({
                        //     data: ["onlocaltrack", this.__rtcStatsId, debugTrack(track)],
                        //   });
                        // });
                        //
                        // track.addEventListener("unmute", () => {
                        //   trace({
                        //     data: ["onlocaltrack", this.__rtcStatsId, debugTrack(track)],
                        //   });
                        // });
                    }
                    return transceiver;
                };
            }
        });
        ["createDataChannel", "restartIce"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    trace({ data: [method, this.__rtcStatsId, arguments] });
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["close"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    trace({ data: [method, this.__rtcStatsId, arguments] });
                    delete openChannels[this.__rtcStatsId];
                    (0, exports.sdkLog)("debug", "on RTCPeerConnection(".concat(this.__rtcStatsId, ") close"));
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["addStream", "removeStream"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var _this_1 = this;
                    var stream = arguments[0];
                    var streamInfo = stream
                        .getTracks()
                        .map(function (t) {
                        return t.kind + ":" + t.id;
                    })
                        .join(",");
                    trace({ data: [method, this.__rtcStatsId, stream.id + " " + streamInfo] });
                    // https://redmine.testrtc.com/issues/7166
                    if (method === "addStream") {
                        stream.getTracks().map(function (track) {
                            trace({
                                data: ["onlocaltrack", _this_1.__rtcStatsId, debugTrack(track) + " " + stream.id],
                            });
                            track.addEventListener("ended", function () {
                                trace({
                                    data: ["onlocaltrack", _this_1.__rtcStatsId, debugTrack(track) + " " + stream.id],
                                });
                            });
                            // Disable mute/umute tracks events: https://redmine.testrtc.com/issues/9240
                            // track.addEventListener("mute", () => {
                            //   trace({
                            //     data: ["onlocaltrack", this.__rtcStatsId, debugTrack(track) + " " + stream.id],
                            //   });
                            // });
                            //
                            // track.addEventListener("unmute", () => {
                            //   trace({
                            //     data: ["onlocaltrack", this.__rtcStatsId, debugTrack(track) + " " + stream.id],
                            //   });
                            // });
                        });
                    }
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["addTrack"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var _this_1 = this;
                    var track = arguments[0];
                    var streams = [].slice.call(arguments, 1);
                    trace({
                        data: [
                            method,
                            this.__rtcStatsId,
                            debugTrack(track) +
                                " " +
                                (streams
                                    .map(function (s) {
                                    return "stream:" + s.id;
                                })
                                    .join(";") || "-"),
                        ],
                    });
                    // https://redmine.testrtc.com/issues/7166
                    trace({
                        data: [
                            "onlocaltrack",
                            this.__rtcStatsId,
                            debugTrack(track) +
                                " " +
                                streams.map(function (stream) {
                                    return "stream:" + stream.id;
                                }),
                        ],
                    });
                    track.addEventListener("ended", function () {
                        trace({
                            data: [
                                "onlocaltrack",
                                _this_1.__rtcStatsId,
                                debugTrack(track) +
                                    " " +
                                    streams.map(function (stream) {
                                        return "stream:" + stream.id;
                                    }),
                            ],
                        });
                    });
                    // Disable mute/umute tracks events: https://redmine.testrtc.com/issues/9240
                    // track.addEventListener("mute", () => {
                    //   trace({
                    //     data: [
                    //       "onlocaltrack",
                    //       this.__rtcStatsId,
                    //       debugTrack(track) +
                    //         " " +
                    //         streams.map((stream: any) => {
                    //           return "stream:" + stream.id;
                    //         }),
                    //     ],
                    //   });
                    // });
                    //
                    // track.addEventListener("unmute", () => {
                    //   trace({
                    //     data: [
                    //       "onlocaltrack",
                    //       this.__rtcStatsId,
                    //       debugTrack(track) +
                    //         " " +
                    //         streams.map((stream: any) => {
                    //           return "stream:" + stream.id;
                    //         }),
                    //     ],
                    //   });
                    // });
                    var sender = nativeMethod.apply(this, arguments);
                    sender.__pcId = this.__rtcStatsId;
                    // Associate the peer id to the existing transceivers
                    var transceivers = this.getTransceivers();
                    if (transceivers) {
                        transceivers.forEach(function (transceiver) {
                            transceiver.__pcId = _this_1.__rtcStatsId;
                        });
                    }
                    return sender;
                };
            }
        });
        ["removeTrack"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var track = arguments[0].track;
                    trace({
                        data: [method, this.__rtcStatsId, track ? debugTrack(track) : "null"],
                    });
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["createOffer", "createAnswer"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var rtcStatsId = this.__rtcStatsId;
                    var args = arguments;
                    var opts;
                    if (arguments.length === 1 && typeof arguments[0] === "object") {
                        opts = arguments[0];
                    }
                    else if (arguments.length === 3 && typeof arguments[2] === "object") {
                        opts = arguments[2];
                    }
                    trace({ data: [method, this.__rtcStatsId, opts] });
                    return nativeMethod.apply(this, opts ? [opts] : undefined).then(function (description) {
                        trace({ data: [method + "OnSuccess", rtcStatsId, description] });
                        if (args.length > 0 && typeof args[0] === "function") {
                            args[0].apply(null, [description]);
                            return undefined;
                        }
                        return description;
                    }, function (err) {
                        trace({ data: [method + "OnFailure", rtcStatsId, err.toString()] });
                        if (args.length > 1 && typeof args[1] === "function") {
                            args[1].apply(null, [err]);
                            return;
                        }
                        throw err;
                    });
                };
            }
        });
        ["setLocalDescription", "setRemoteDescription", "addIceCandidate"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var rtcStatsId = this.__rtcStatsId;
                    var args = arguments;
                    var _this = this;
                    // because of setLocalDescription(null) sometimes we don't have SDP value
                    // fippo suggested: Access pc.localDescription.sdp in the successcallback
                    var needToHandleSDPInSuccessCallback = method === "setLocalDescription" && (!args[0] || (args[0] && !args[0].sdp));
                    trace({
                        data: [method, this.__rtcStatsId, needToHandleSDPInSuccessCallback ? { parameterless: true } : args[0]],
                    });
                    return nativeMethod.apply(this, [args[0]]).then(function () {
                        trace({
                            data: [
                                method + "OnSuccess",
                                rtcStatsId,
                                needToHandleSDPInSuccessCallback ? _this === null || _this === void 0 ? void 0 : _this.localDescription : undefined,
                            ],
                        });
                        if (args.length >= 2 && typeof args[1] === "function") {
                            args[1].apply(null, []);
                            return undefined;
                        }
                        return undefined;
                    }, function (err) {
                        trace({ data: [method + "OnFailure", rtcStatsId, err.toString()] });
                        if (args.length >= 3 && typeof args[2] === "function") {
                            args[2].apply(null, [err]);
                            return undefined;
                        }
                        throw err;
                    });
                };
            }
        });
        // wrap static methods. Currently just generateCertificate.
        if (origPeerConnection.generateCertificate) {
            Object.defineProperty(peerconnection, "generateCertificate", {
                get: function () {
                    return arguments.length
                        ? origPeerConnection.generateCertificate.apply(null, arguments)
                        : origPeerConnection.generateCertificate;
                },
            });
        }
        globalContext[prefix + "RTCPeerConnection"] = peerconnection;
        globalContext[prefix + "RTCPeerConnection"].prototype = origPeerConnection.prototype;
        (0, exports.sdkLogWithoutTrace)("debug", "RTCPeerConnection prefixes override complete");
    });
    if (isBrowser) {
        // getUserMedia wrappers
        prefixesToWrap.forEach(function (prefix) {
            var name = prefix + (prefix.length ? "GetUserMedia" : "getUserMedia");
            if (!navigator[name]) {
                return;
            }
            var origGetUserMedia = navigator[name].bind(navigator);
            var gum = function () {
                trace({ data: ["getUserMedia", null, arguments[0]] });
                var cb = arguments[1];
                var eb = arguments[2];
                origGetUserMedia(arguments[0], function (stream) {
                    // we log the stream id, track ids and tracks readystate since that is ended GUM fails
                    // to acquire the cam (in chrome)
                    trace({ data: ["getUserMediaOnSuccess", null, (0, utils_1.dumpStream)(stream)] });
                    if (cb) {
                        cb(stream);
                    }
                }, function (err) {
                    var errorData = ["getUserMediaOnFailure", null, err.name];
                    trace({ data: errorData });
                    httpReportError(errorData);
                    if (eb) {
                        eb(err);
                    }
                });
            };
            navigator[name] = gum.bind(navigator);
        });
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            var origGetUserMedia_1 = navigator.mediaDevices.getUserMedia.bind(navigator.mediaDevices);
            var gum = function () {
                trace({ data: ["navigator.mediaDevices.getUserMedia", null, arguments[0]] });
                return origGetUserMedia_1.apply(navigator.mediaDevices, arguments).then(function (stream) {
                    trace({ data: ["navigator.mediaDevices.getUserMediaOnSuccess", null, (0, utils_1.dumpStream)(stream)] });
                    return stream;
                }, function (err) {
                    var errorData = ["navigator.mediaDevices.getUserMediaOnFailure", null, err.name];
                    trace({ data: errorData });
                    httpReportError(errorData);
                    return Promise.reject(err);
                });
            };
            navigator.mediaDevices.getUserMedia = gum.bind(navigator.mediaDevices);
        }
        // getDisplayMedia
        if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
            var origGetDisplayMedia_1 = navigator.mediaDevices.getDisplayMedia.bind(navigator.mediaDevices);
            var gdm = function () {
                trace({ data: ["navigator.mediaDevices.getDisplayMedia", null, arguments[0]] });
                return origGetDisplayMedia_1.apply(navigator.mediaDevices, arguments).then(function (stream) {
                    trace({ data: ["navigator.mediaDevices.getDisplayMediaOnSuccess", null, (0, utils_1.dumpStream)(stream)] });
                    return stream;
                }, function (err) {
                    var errorData = ["navigator.mediaDevices.getDisplayMediaOnFailure", null, err.name];
                    trace({ data: errorData });
                    httpReportError(errorData);
                    return Promise.reject(err);
                });
            };
            navigator.mediaDevices.getDisplayMedia = gdm.bind(navigator.mediaDevices);
        }
    }
    if ((_b = watchrtc.console) === null || _b === void 0 ? void 0 : _b.level) {
        (0, utils_1.setConsoleLevel)(watchrtc.console.level, trace);
    }
};
exports.initSDK = initSDK;
var setConfig = function (newWatchrtcConfig) {
    var _a;
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "SDK is not initialized. Use 'init' function first.");
        return;
    }
    var collectConsoleDisabled = (watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.allowBrowserLogCollection) === false;
    if (!collectConsoleDisabled && ((_a = newWatchrtcConfig === null || newWatchrtcConfig === void 0 ? void 0 : newWatchrtcConfig.console) === null || _a === void 0 ? void 0 : _a.override) === true && newWatchrtcConfig.console.level) {
        (0, utils_1.setConsoleLevel)(newWatchrtcConfig.console.level, trace);
    }
    if ("collectionInterval" in newWatchrtcConfig) {
        delete newWatchrtcConfig.collectionInterval;
    }
    watchrtcConfig = __assign(__assign({}, watchrtcConfig), newWatchrtcConfig);
    watchrtcIdentifiers.rtcRoomId = watchrtcConfig.rtcRoomId;
    watchrtcIdentifiers.rtcPeerId = watchrtcConfig.rtcPeerId;
    (0, exports.sdkLog)("debug", "setConfig", {
        newWatchrtcConfig: JSON.stringify(newWatchrtcConfig),
        watchrtcConfig: JSON.stringify(watchrtcConfig),
    });
    maybeOpenWebsocketConnection({});
};
exports.setConfig = setConfig;
/**
 * Set user rating and/or comment for peer session
 * @param rating number from 1 to 5
 * @param comment string
 */
var setUserRating = function (
/** 1 | 2 | 3 | 4 | 5 */
rating, ratingComment) {
    var _a;
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "SDK is not initialized. Use 'init' function first.");
        return Promise.resolve({ error: "SDK is not initialized. Use 'init' function first." });
    }
    var isValidRating = (0, utils_1.validateRating)(rating);
    if (!isValidRating) {
        return Promise.resolve({ error: "Rating is invalid" });
    }
    if (connectionStateReason === "serverRejectedNoRetry") {
        (0, exports.sdkLog)("error", "SDK connection has been rejected - Rating can't be sent. Please check your SDK parameters.");
        return Promise.resolve({
            error: "SDK connection has been rejected. Rating can't be sent. Please check your SDK parameters.",
        });
    }
    var opened = ((_a = socket === null || socket === void 0 ? void 0 : socket.connection) === null || _a === void 0 ? void 0 : _a.readyState) === globalContext.WebSocket.OPEN;
    var data = ["userRating", null, { rating: rating, ratingComment: ratingComment }];
    return new Promise(function (resolve, reject) {
        var options = { promiseFuncs: { resolve: resolve, reject: reject } };
        if (opened) {
            trace({ data: data, options: options });
        }
        else {
            httpTrace.apply(void 0, data).then(function () { return resolve({}); })
                .catch(function (error) { return resolve({ error: error }); });
        }
    });
};
exports.setUserRating = setUserRating;
/**
 * Add keys for peer session
 * @param keys
 */
var addKeys = function (
/** { "key1": "value1", "key2": ["value2_1", "value2_2"]} */
keys) {
    var _a;
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "SDK is not initialized. Use 'init' function first.");
        return;
    }
    Object.keys(keys || {}).forEach(function (k) {
        if (typeof keys[k] === "string") {
            keys[k] = [keys[k]];
        }
    });
    var data = ["keys", null, keys];
    var opened = ((_a = socket === null || socket === void 0 ? void 0 : socket.connection) === null || _a === void 0 ? void 0 : _a.readyState) === globalContext.WebSocket.OPEN;
    var wasConnected = socket === null || socket === void 0 ? void 0 : socket.wasConnected;
    return new Promise(function (resolve, reject) {
        var options = { promiseFuncs: { resolve: resolve, reject: reject } };
        if (opened) {
            trace({ data: data, options: options });
        }
        else if (!wasConnected) {
            trace({ data: data, options: options });
        }
        else {
            httpTrace.apply(void 0, data).then(function () { return resolve({}); })
                .catch(function (error) { return resolve({ error: error }); });
        }
    });
};
exports.addKeys = addKeys;
var disableDataCollection = function () {
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "SDK is not initialized. Use 'init' function first.");
        return;
    }
    trace({ data: ["disableDataCollection", null, null] });
    socket === null || socket === void 0 ? void 0 : socket.disableDataCollection();
};
exports.disableDataCollection = disableDataCollection;
var enableDataCollection = function () {
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "SDK is not initialized. Use 'init' function first.");
        return;
    }
    trace({ data: ["enableDataCollection", null, null] });
    socket === null || socket === void 0 ? void 0 : socket.enableDataCollection();
};
exports.enableDataCollection = enableDataCollection;
var addEvent = function (event) {
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "addEvent error. SDK is not initialized. Use 'init' function first.");
        return;
    }
    // const opened = socket?.connection?.readyState === WS.OPEN;
    // if (!opened && socket?.wasConnected) {
    //   sdkLog("error", `addEvent error. Connection has been closed.`);
    //   return;
    // }
    var isEventValid = (0, utils_1.validateEvent)(event, openChannels);
    if (!isEventValid) {
        return;
    }
    // Don't keep `pc` information in event, only extract the id
    var validatedEvent = __assign({}, event);
    var source = null;
    if (validatedEvent.pc) {
        source = validatedEvent.pc.__rtcStatsId || null;
        delete validatedEvent.pc;
    }
    var data = ["event", source, validatedEvent];
    return new Promise(function (resolve, reject) {
        var options = { promiseFuncs: { resolve: resolve, reject: reject } };
        trace({ data: data, options: options });
    });
};
exports.addEvent = addEvent;
var mapStream = function (id, name) {
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "mapStream error. SDK is not initialized. Use 'init' function first.");
        return;
    }
    var isDataValid = !!id && !!name;
    if (!isDataValid) {
        return;
    }
    var data = ["mapStream", null, { id: id, name: name }];
    (0, exports.sdkLog)("info", "mapStream method is deprecated. Please use mapTrack instead.");
    trace({ data: data });
};
exports.mapStream = mapStream;
var mapTrack = function (id, name) {
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "mapTrack error. SDK is not initialized. Use 'init' function first.");
        return;
    }
    var isDataValid = !!id && !!name;
    if (!isDataValid) {
        return;
    }
    mappedTrack[id] = name;
    var data = ["mapTrack", null, { id: id, name: name }];
    trace({ data: data });
};
exports.mapTrack = mapTrack;
var connect = function () {
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "connect error. SDK is not initialized. Use 'init' function first.");
        return;
    }
    freshConnection = true;
    isManualConnect = true;
    // Reset exponential backoff to try to connect/reconnect immediately
    exponentialBackoff.reset();
    (0, exports.sdkLog)("debug", "manual connect");
    maybeOpenWebsocketConnection({});
};
exports.connect = connect;
var disconnect = function () {
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "disconnect error. SDK is not initialized. Use 'init' function first.");
        return;
    }
    isManualConnect = false;
    isManualDisconnect = true;
    connectionStateReason = "applicationDisconnected";
    socket === null || socket === void 0 ? void 0 : socket.close();
    (0, exports.sdkLog)("debug", "manual disconnect");
};
exports.disconnect = disconnect;
var registerOnStatsListener = function (listener) {
    getStatsCallback = listener;
};
exports.registerOnStatsListener = registerOnStatsListener;
var registerStateListener = function (listener) {
    stateListener = listener;
};
exports.registerStateListener = registerStateListener;
var httpTrace = function () {
    var _a;
    var data = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        data[_i] = arguments[_i];
    }
    if (!watchrtcIdentifiers.rtcRoomId || !watchrtcIdentifiers.rtcPeerId) {
        var message = "Missing configuration parameters rtcPeerId and rtcRoomId to connect";
        if (!watchrtcIdentifiers.rtcRoomId && watchrtcIdentifiers.rtcPeerId) {
            message = "Missing configuration parameter rtcRoomId to connect";
        }
        else if (!watchrtcIdentifiers.rtcPeerId && watchrtcIdentifiers.rtcRoomId) {
            message = "Missing configuration parameter rtcPeerId to connect";
        }
        (0, exports.sdkLog)("error", message);
        return Promise.reject(message);
    }
    if (!watchrtcIdentifiers.projectId && !watchrtcConfig.rtcApiKey) {
        var message = "Missing apiKey to enable trace before connection establishment";
        (0, exports.sdkLog)("info", message);
        return Promise.reject(message);
    }
    var httpConnectionData = (0, utils_1.getConnectionData)("http", watchrtcConfig.rtcApiKey, watchrtcConfig.proxyUrl);
    return http
        ? http.trace.apply(http, __spreadArray(["".concat(httpConnectionData.url, "/trace"), globalContext.watchRTCSessionId, (_a = watchrtcIdentifiers.projectId) !== null && _a !== void 0 ? _a : httpConnectionData.key, watchrtcIdentifiers.rtcRoomId,
            watchrtcIdentifiers.rtcPeerId], data, false)) : Promise.reject("Invalid configuration of http service");
};
var httpReportError = function (data) {
    var _a;
    var apiKey = watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcApiKey;
    var rtcRoomId = watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcRoomId;
    var rtcPeerId = watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcPeerId;
    if (!apiKey || !rtcRoomId || !rtcPeerId) {
        (0, exports.sdkLog)("error", "Cannot report an error. Please provide rtcApiKey, rtcRoomId and rtcPeerId ");
        return;
    }
    var httpConnectionData = (0, utils_1.getConnectionData)("http", watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcApiKey, watchrtcConfig.proxyUrl);
    data = data || (socket === null || socket === void 0 ? void 0 : socket.buffer) || [];
    http === null || http === void 0 ? void 0 : http.trace("".concat(httpConnectionData.url, "/error"), globalContext.watchRTCSessionId, (_a = watchrtcIdentifiers.projectId) !== null && _a !== void 0 ? _a : httpConnectionData.key, rtcRoomId, rtcPeerId, data);
};
var persistentEnd = function (nailUpCallEnd) {
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "persistentEnd error. SDK is not initialized. Use 'init' function first.");
        return;
    }
    clearInterval(getStatsInterval);
    isManualConnect = false;
    isManualDisconnect = true;
    connectionStateReason = "applicationDisconnected";
    socket === null || socket === void 0 ? void 0 : socket.close(nailUpCallEnd);
    (0, exports.sdkLog)("debug", "persistentEnd. sessionId: ".concat(globalContext.watchRTCSessionId));
};
exports.persistentEnd = persistentEnd;
var resetConnectionState = function () {
    var _a;
    (0, exports.sdkLogWithoutTrace)("debug", "resetConnectionState sessionId: ".concat(globalContext.watchRTCSessionId));
    for (var _i = 0, _b = Object.values(openChannels); _i < _b.length; _i++) {
        var channel = _b[_i];
        channel.prev = null;
    }
    if ((_a = socket === null || socket === void 0 ? void 0 : socket.buffer) === null || _a === void 0 ? void 0 : _a.length) {
        socket.buffer = socket.buffer.filter(function (_a) {
            var traceType = _a[0];
            return traceType !== "getstats";
        });
    }
};
var persistentStart = function (roomId, peerId) {
    var initialized = globalContext.watchRTCInitialized;
    if (!initialized) {
        (0, exports.sdkLog)("error", "persistentStart error. SDK is not initialized. Use 'init' function first.");
        return;
    }
    watchrtcConfig = __assign(__assign({}, watchrtcConfig), { rtcRoomId: roomId, rtcPeerId: peerId });
    watchrtcIdentifiers.rtcRoomId = roomId;
    watchrtcIdentifiers.rtcPeerId = peerId;
    isManualConnect = true;
    isManualDisconnect = false;
    resetConnectionState();
    maybeOpenWebsocketConnection({ nailUp: true });
    (0, exports.sdkLog)("debug", "persistentStart. sessionId: ".concat(globalContext.watchRTCSessionId), { watchrtcConfig: watchrtcConfig });
};
exports.persistentStart = persistentStart;
// (globalContext as any).setUserRating = setUserRating;
// (globalContext as any).addTags = addTags;
// (globalContext as any).addKeys = addKeys;
// (globalContext as any).init = initSDK;
// (globalContext as any).addEvent = addEvent;
// (globalContext as any).wrtcConnect = connect;
// (globalContext as any).wrtcDisconnect = disconnect;
/* QRTC SDK part */
var loadJS = function (url) {
    return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = url;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
};
var loadConfig = function (url) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch(url)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var qrtcStopRequest = false;
/*
    const answer = await watchRTC.qualityrtc.run({
      options: {
        // run: "Location",

        // if missing, will use default unpkg.com values
        codeUrl: `http://localhost:8081/lib/main.bundle.js`,

        // should not be passed, and will read from watchRTC server, passing this for development testing
        configUrl: `https://niceincontact.testrtc.com/.netlify/functions/get-config`
      },
      progressCallback});

 */
var qrtcRun = function (_a) {
    var options = _a.options, progressCallback = _a.progressCallback;
    return __awaiter(void 0, void 0, void 0, function () {
        var answer, codeUrl, queryParams_1, httpConnectionData, isNetlifyRequest, configUrl, _b, configAnswer, codeLoad, qualityRTC, progressCallbackWrapper, configData, netlifyBaseUrl, config;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!isBrowser) {
                        (0, exports.sdkLogWithoutTrace)("info", "Cannot run qualityRTC test in non-browser environment");
                        return [2 /*return*/];
                    }
                    qrtcStopRequest = false;
                    // disable WatchRTC SDK data collection while QRTC SDK working
                    (0, exports.disableDataCollection)();
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, , 4, 5]);
                    codeUrl = (options === null || options === void 0 ? void 0 : options.codeUrl) || "https://qualityrtc-sdk.s3.amazonaws.com/".concat(version_1.default, "/main.bundle.js");
                    queryParams_1 = {};
                    if (options) {
                        Object.keys(options || {}).forEach(function (key) {
                            if (typeof options[key] === "string") {
                                queryParams_1[key] = options[key];
                            }
                        });
                    }
                    httpConnectionData = (0, utils_1.getConnectionData)("http", watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcApiKey, watchrtcConfig.proxyUrl);
                    isNetlifyRequest = !!(options === null || options === void 0 ? void 0 : options.configUrl);
                    configUrl = isNetlifyRequest
                        ? "".concat(options.configUrl, "/.netlify/functions/get-config")
                        : httpConnectionData.url +
                            "/get-qualityrtc-config?apiKey=".concat(httpConnectionData.key, "&").concat(new URLSearchParams(queryParams_1));
                    console.log("runNetworkTest: request to run, loading lib and config", {
                        codeUrl: codeUrl,
                        configUrl: configUrl,
                    });
                    return [4 /*yield*/, Promise.all([loadConfig(configUrl), loadJS(codeUrl)])];
                case 2:
                    _b = _c.sent(), configAnswer = _b[0], codeLoad = _b[1];
                    console.log("runNetworkTest:JS code & config loaded", {
                        codeLoad: codeLoad,
                        configAnswer: configAnswer,
                    });
                    qualityRTC = globalContext.__qualityRTC;
                    if (!qualityRTC) {
                        throw new Error("runNetworkTest:Failed to load qualityRTC, __qualityRTC is undefined");
                    }
                    progressCallbackWrapper = function (progress) {
                        if (qrtcStopRequest) {
                            return false;
                        }
                        else if (progressCallback) {
                            progressCallback(progress);
                        }
                        return true;
                    };
                    configData = isNetlifyRequest ? configAnswer.config : configAnswer.jsonConfig.config;
                    netlifyBaseUrl = isNetlifyRequest ? options === null || options === void 0 ? void 0 : options.configUrl : new URL(configAnswer.url).origin;
                    config = __assign(__assign({}, configData), { isProbe: true, options: __assign(__assign(__assign({}, configData.options), options), { wakeLock: true, netlifyBaseUrl: netlifyBaseUrl }) });
                    console.log("runNetworkTest: code & config loaded, running test", {
                        config: config,
                        configData: configData,
                        qualityRTC: qualityRTC,
                        netlifyBaseUrl: netlifyBaseUrl,
                    });
                    return [4 /*yield*/, qualityRTC.run({
                            config: config,
                            progressCallback: progressCallbackWrapper,
                        })];
                case 3:
                    answer = _c.sent();
                    console.log("runNetworkTest:JS Test completed", { answer: answer });
                    return [3 /*break*/, 5];
                case 4:
                    (0, exports.enableDataCollection)();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/, answer];
            }
        });
    });
};
exports.qrtcRun = qrtcRun;
var qrtcStop = function () {
    if (!isBrowser) {
        (0, exports.sdkLogWithoutTrace)("info", "Cannot run qualityRTC test in non-browser environment");
        return;
    }
    qrtcStopRequest = true;
};
exports.qrtcStop = qrtcStop;
