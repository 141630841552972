import { Dispatch } from "react";
import {
  SET_AUDIO_FILES_FALSE,
  SET_AUDIO_FILES_TRUE,
  SET_VIDEO_FILES_FALSE,
  SET_VIDEO_FILES_TRUE,
  ADD_HIGH_RES_GET_USER,
  REMOVE_HIGH_RES_GET_USER,
  REMOVE_HIGH_RES_GET_USER_STREAM_TYPE,
  CLEAR_AUDIO_VIDEO_FILES,
} from "./actionTypes";

export const setAudioFile = (value: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: value ? SET_AUDIO_FILES_TRUE : SET_AUDIO_FILES_FALSE,
    });
  };
};

export const setVideoFile = (value: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: value ? SET_VIDEO_FILES_TRUE : SET_VIDEO_FILES_FALSE,
    });
  };
};

//  Add user who is geeting high res of user
export const addHighResUser = (username: string, streamType: Array<string>) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ADD_HIGH_RES_GET_USER,
      payload: { username, streamType },
    });
  };
};

//  Remove user stream type (screen, video and screen2)
export const removeHighResUserStreamType = (
  username: string,
  streamType: Array<string>
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: REMOVE_HIGH_RES_GET_USER_STREAM_TYPE,
      payload: { username, streamType },
    });
  };
};

// Remove user entry if there is last stream type left
export const removeHighResUser = (username: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: REMOVE_HIGH_RES_GET_USER,
      username,
    });
  };
};

export const clearControlAudioVideoFile = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: CLEAR_AUDIO_VIDEO_FILES,
    });
  };
};
