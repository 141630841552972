import modalReducer from "./modalReducer";
import loginReducer from "./loginReducer";
import userDetailsReducer from "./userDetailsReducer";
import roomsReducer from "./roomsReducer";
import deviceReducer from "./deviceReducer";
import chatReducer from "./chatReducer";
import peerVolumeReducer from "./peerVolumeReducer";
import loaderReducer from "./loaderReducer";
import changePasswordReducer from "./changePasswordReducer";
import videoOptionReducer from "./videoEditOption";
import supervisorReducer from "./supervisorReducer";
import notificationsReducer from "./notificationReducer";
import tempRoomUserReducer from "./tempRoomUserReducer";
import screenControlReducer from "./screenControlReducer";
import audioVideoFileReducer from "./audioVideoFileReducer";
import backgroundReducer from "./backgroundReducer";
import troubleshootReducer from "./troubleshootReducer";
import environmentReducer from "./environmentReducer";
import collabReducer from "./collabReducer";
import logoReducer from "./logoReducer";
import settingLayoutReducer from "./saveLayoutReducer";
import activityChannelReducer from "./activityChannelReducer";
import faceRegistrationReducer from "./faceRegistrationReducer";
import faveVerificationReducer from "./faceVerificationReducer";
import reconnectionReducer from "./reconnectionReducer";
import verifyFaceReducer from "./verifyFaceReducer";

import personalizationReducer from "./personalizationReducer";
import verifyNotificationReducer from "./verifyNotificationReducer";

const reducers = {
  modal: modalReducer,
  login: loginReducer,
  changePassword: changePasswordReducer,
  userDetails: userDetailsReducer,
  roomsData: roomsReducer,
  devicesData: deviceReducer,
  chatData: chatReducer,
  loading: loaderReducer,
  peerVolume: peerVolumeReducer,
  videoOption: videoOptionReducer,
  supervisorForManager: supervisorReducer,
  allNotification: notificationsReducer,
  tempRoomUserReducer: tempRoomUserReducer,
  screenControlReducer: screenControlReducer,
  audioVideoFileReducer: audioVideoFileReducer,
  backgroundReducer: backgroundReducer,
  troubleshootReducer: troubleshootReducer,
  collabReducer: collabReducer,
  environmentsReducers: environmentReducer,
  logoReducer: logoReducer,
  layoutReducer: settingLayoutReducer,
  activityChannelReducer: activityChannelReducer,
  faceRegistration: faceRegistrationReducer,
  faceVerification: faveVerificationReducer,
  reconnectionReducer: reconnectionReducer,
  verifyFaceReducer: verifyFaceReducer,
  personalizationReducer: personalizationReducer,
  verifyNotificationReducer:verifyNotificationReducer
};

export default reducers;
