import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const changePassword = async (email?: string, password?: string) => {
  const requestBody = qs.stringify({
    username: email,
    password: password
  });

  const response = await request.post("/pwdupdate", requestBody, config);

  return response.data;
};

