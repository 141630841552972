"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DEFAULT_TIMEOUT_MS = 1000; // default timeout of 1 second
var MAX_TIMEOUT_MS = 128 * 1000; // Maximum timeout of 128 seconds
var MAX_RANDOM_MS = 3000; // maximum random of 3 seconds added to each backoff
var BACKOFF_COEFFICIENT = 2; // Exponential backoff coefficient
var MAX_RETRIES_FIRST_CONNECTION = 15; // Maximum number of retries for the first connection
var next = function (exponentialRetries) {
    return DEFAULT_TIMEOUT_MS * exponentialRetries + Math.floor(Math.random() * MAX_RANDOM_MS);
};
var Backoff = /** @class */ (function () {
    function Backoff() {
        this.backoff_delay = DEFAULT_TIMEOUT_MS; // default to 1 second
        this.exponentialRetries = 0;
        this.numRetries = 0; // count the number of retries done
    }
    Object.defineProperty(Backoff.prototype, "delayMs", {
        get: function () {
            return this.backoff_delay;
        },
        enumerable: false,
        configurable: true
    });
    Backoff.prototype.reset = function () {
        this.backoff_delay = DEFAULT_TIMEOUT_MS;
        this.exponentialRetries = 0;
        this.numRetries = 0;
    };
    Backoff.prototype.next = function () {
        // First, compute the exponential backoff using a coefficient to increase the timeout exponentially (1, 2, 4, 8, 16, 32, 64, 128, 256, 512, ...).
        this.exponentialRetries = !this.exponentialRetries ? 1 : this.exponentialRetries * BACKOFF_COEFFICIENT;
        // Then, compute the backoff delay based on the exponential backoff and add a random number of milliseconds to avoid a thundering herd problem.
        this.backoff_delay = Math.min(MAX_TIMEOUT_MS, next(this.exponentialRetries));
        this.numRetries++;
        return this.backoff_delay;
    };
    Object.defineProperty(Backoff.prototype, "attempts", {
        get: function () {
            return this.numRetries;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Backoff.prototype, "MAX_RETRIES_FOR_FIRST_CONNECTION", {
        get: function () {
            return MAX_RETRIES_FIRST_CONNECTION;
        },
        enumerable: false,
        configurable: true
    });
    return Backoff;
}());
exports.default = Backoff;
