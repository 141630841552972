import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_RESET,
  CHANGE_PASSWORD_FAILURE,
} from "./actionTypes";
import { changePassword } from "../services/changePassword";

export const handleChangePassword = (username?: string, password?: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      if (username && password) {
        const responseData = await changePassword(username, password);
        if (responseData.success) {
          dispatch(setSuccess());
        } else {
          dispatch(setChangePasswordError(responseData.message.message));
        }
        return responseData;
      } else {
        const responseData = await changePassword();
        if (responseData.success) {
          dispatch(setSuccess());
        }
        return responseData;
      }
    } catch (error: any) {
      if (username && password) {
        if (error.message && error.message.message) {
          dispatch(setChangePasswordError(error.message.message));
        } else {
          dispatch(setChangePasswordError("Incorrect Fields"));
        }
      } else {
        dispatch(setChangePasswordError(""));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const changePasswordReset = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(setChangePasswordError(""));
    dispatch({
      type: CHANGE_PASSWORD_RESET,
    });
  };
};

const setSuccess = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(setChangePasswordError(""));
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
    });
  };
};

export const setChangePasswordError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: CHANGE_PASSWORD_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};
