import {
  GET_PERSONALIZATION_SUCCESS,
  GET_PERSONALIZATION_FAILURE,
  SAVE_PERSONALIZATION_SUCCESS,
  SAVE_PERSONALIZATION_FAILURE,
} from "../actions/actionTypes";

interface IAction {
  type: string;
  payload: any;
}

const initialState = {
  settings: [],
  isSettingsSaved: false,
  isGetSavedSettings: false,
  errorMessage: "",
};

const personalizationReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_PERSONALIZATION_SUCCESS: {
      return {
        ...state,
        settings: action.payload.settings,
        isGetSavedSettings: action.payload.status,
      };
    }
    case GET_PERSONALIZATION_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload.message,
        isGetSavedSettings: action.payload.status,
      };
    }
    case SAVE_PERSONALIZATION_SUCCESS: {
      return {
        ...state,
        errorMessage: action.payload.message,
        isSettingsSaved: action.payload.status,
      };
    }
    case SAVE_PERSONALIZATION_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload.message,
        isSettingsSaved: action.payload.status,
      };
    }

    default: {
      return state;
    }
  }
};

export default personalizationReducer;
