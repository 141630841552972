import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getAgentToAgentStatus = async () => {
  const reqBody = qs.stringify({
    variable: "AGENTTOAGENT",
  });
  const agentStatusResponse = await request.post(
    "/getadminsetting",
    reqBody,
    config
  );
  return agentStatusResponse.data;
};

export const getWeblinkStatus = async () => {
  const reqBody = qs.stringify({
    variable: "WEBLINK",
  });
  const weblinkStatusResponse = await request.post(
    "/getadminsetting",
    reqBody,
    config
  );
  return weblinkStatusResponse.data;
};

export const getMultipleSupervisorsStatus = async () => {
  const reqBody = qs.stringify({
    variable: "MULTIPLE_SUPERVISORS",
  });
  const MultipleSupervisorsResponse = await request.post(
    "/getadminsetting",
    reqBody,
    config
  );
  return MultipleSupervisorsResponse.data;
};

export const getIPAddressStatus = async () => {
  const reqBody = qs.stringify({
    variable: "IPADDRESS",
  });
  const IPAddressResponse = await request.post(
    "/getIPadminsetting",
    reqBody,
    config
  );
  return IPAddressResponse.data;
};
