import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import "./backgroundModal.css";
import { SegmentationConfig } from "../../../helpers/segmentationHelper";
import {
  backgroundModalClose,
  setBackgroundImage,
  setStaticImageLinks,
} from "../../../actions/backgroundAction";
import { setVideoOption } from "../../../actions/videoEditOption";
// import { REACT_APP_SCREENCONTROL } from "../../../Pages/RoomPage/constants";
import { setCameraBackground } from "../../../actions/userActions";

interface backgroundProps {
  canvasDisplayRef: any;
  segmentationConfig?: SegmentationConfig;
}

const BackgroundModal = (props: backgroundProps) => {
  const isBackgroundModalOpen = useSelector(
    (state: RootState) => state.backgroundReducer.isBackgroundModalOpen
  );

  const environments = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  );
  const companyName = environments.REACT_APP_COMPANY_NAME
    ? environments.REACT_APP_COMPANY_NAME.value
    : process.env.REACT_APP_COMPANY_NAME;

  // const videoOption = useSelector(
  //   (state: RootState) => state.videoOption.videoOption
  // );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setStaticImageLinks(companyName as string));
  }, [companyName, dispatch]);

  const handleModalClose = () => {
    dispatch(backgroundModalClose());
  };

  const allStaticImagesLink = useSelector(
    (state: RootState) => state.backgroundReducer.staticBackground
  );

  const userName = useSelector(
    (state: RootState) => state.userDetails.userName
  );

  const handleSelectBackground = (videoOption: string, url: string) => {
    dispatch(setBackgroundImage(url));
    dispatch(setVideoOption(videoOption));
    dispatch(setCameraBackground(userName, videoOption, url));
  };

  return (
    <Modal
      show={isBackgroundModalOpen}
      onHide={handleModalClose}
      // className="feedback_modal_container"
      style={{ border: "0px solid black", width: "50%", margin: "auto" }}
    >
      <Modal.Header

        style={{
          backgroundColor: "rgb(38, 163, 219)",
          margin: "0",
          padding: "6px",
        }}
      >
        <button className="modal-close-btn" onClick={handleModalClose}>
          X
        </button>
        <Modal.Title
          style={{ color: "black", fontSize: "1.1rem", fontWeight: "bold" }}
        >
          Background
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="mySlides">
            {/* <div className="numbertext">1 / 6</div>
            {(videoOption === "blur" || videoOption === "background") &&
            stream !== "" ? (
              // @ts-ignore
              <video src={stream} style={{width:"100%"}} />
            ) : null} */}
          </div>

          <div className="caption-container">
            <p id="caption">Choose one of them</p>
          </div>

          <div className="row">
            {allStaticImagesLink.map((value: any) => (
              <div className="column" key={value._id}>
                <img
                  className="demo cursor"
                  src={value.files.spacesUrl}
                  style={{ width: "95%" }}
                  alt={value.files.filename}
                  onClick={() => {
                    handleSelectBackground("background", value.files.spacesUrl);
                  }}
                />
              </div>
            ))}
          </div>
          {/* <div
            className="mt-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <button
              className="link"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleModalClose();
              }}
            >
              Ok
            </button>
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BackgroundModal;
