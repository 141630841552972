import React from "react";

const VideoFile = (props: any) => {
  return (
    <>
      <video
        id="videosss"
        src={props.blob}
        controls
        // onPlay={() => this.startScreen()}
        style={{
          width: "90%",
          height: "75%",
        }}
      />
    </>
  );
};

export default VideoFile;
