import {
  SET_STATIC_IMAGE_LINKS,
  SET_IMAGE_LINK,
  CLEAR_REDUX_DATA,
  SET_BACKGROUND_MODAL_CLOSE,
  SET_BACKGROUND_MODAL_OPEN,
} from "../actions/actionTypes";

const initialState = {
  staticBackground: [],
  selectedBackground: "",
  isBackgroundModalOpen: false,
};
interface IAction {
  type: string;
  payload: any;
}

const backgroundReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_STATIC_IMAGE_LINKS: {
      return {
        ...state,
        staticBackground: action.payload,
      };
    }

    case SET_IMAGE_LINK: {
      return {
        ...state,
        selectedBackground: action.payload,
      };
    }
    case SET_BACKGROUND_MODAL_OPEN: {
      return {
        ...state,
        isBackgroundModalOpen: true,
      };
    }
    case SET_BACKGROUND_MODAL_CLOSE: {
      return {
        ...state,
        isBackgroundModalOpen: false,
      };
    }
    case CLEAR_REDUX_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default backgroundReducer;
