import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { CLOSE_MODAL } from "../../actions/actionTypes";
import { RootState } from "../../redux";
import { IModalComponent } from "./interface";

const ModalComponent = (props: IModalComponent) => {
  const modalShow = useSelector((state: RootState) => state.modal.isOpen);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch({
      type: CLOSE_MODAL,
    });
  };
  return (
    <Modal show={modalShow} onHide={handleModalClose}>
      {props.children}
    </Modal>
  );
};

export default ModalComponent;
