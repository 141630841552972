import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import "./index.css";
import Notification from "./Notification";

const Notifications = () => {
  const allNotification: any = useSelector(
    (state: RootState) => state.allNotification
  );

  return (
    <div className="info noti_container_overflow">
      {allNotification.notiArrayParam.map((value: string) => (
        <Notification key={value} username={value} />
      ))}
    </div>
  );
};

export default Notifications;
