import React, { useState, useEffect } from "react";
import "./styles.css";
import Input from "./../../components/Input";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import Logo from "../../assets/images/logo.svg";
import { checkTokenValid, changePassword } from "../../services/forgotPassword";
import { toast } from "react-toastify";
import { Alert } from "react-bootstrap";
interface Props {}

const PasswordReset: React.FC<Props> = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
  const navigate = useNavigate();

  // @ts-ignore
  const { resetPasswordToken } = useParams();
  const savedLogo = useSelector(
    (state: RootState) => state.logoReducer.logoUrl
  );
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    // console.log(checkPwd(password));
  };
  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const checkTokenIsValid = async () => {
    const email = localStorage.getItem("resetEmail");
    try {
      // @ts-ignore
      const res = await checkTokenValid(email, resetPasswordToken);
      if (res.success) {
        setShow(true);
        setMessage("Validating....");
      } else {
        setShow(false);
        setMessage(res.message);
      }
    } catch (err: any) {
      setShow(false);
      setMessage("Password reset token is invalid or has expired.");
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     history.push("/");
  //   }, 3000);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showAlert]);
  const handlePasswordRegister = async (e: any) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Password didn't match.");
      return;
    } else {
      setConfirmPasswordError("");
    }

    try {
      const res = await changePassword(resetPasswordToken, password);
      if (res.success) {
        toast(res.message);
        setPassword("");
        setConfirmPassword("");
        setShowAlert(true);
        setTimeout(()=>{
          navigate('/')
        },3000)
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      console.log("Error in Changing Password", err);
    }
  };

  useEffect(() => {
    checkTokenIsValid();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [logo, setLogo] = useState(Logo);

  useEffect(() => {
    if (savedLogo) {
      setLogo(savedLogo);

      var link = document.querySelector("link[rel~='apple-touch-icon']");
      // @ts-ignore
      link.href = savedLogo;
    }
  }, [savedLogo, logo]);

  return (
    <div className="cont">
      <img src={logo} alt="logo2" className="header-logo" />
      {show ? (
        <>
          {showAlert && (
            <Alert variant="success" className="green">
              Your password updated successfully. You will be redirected to
              dashboard in 3 Seconds
            </Alert>
          )}
          <section className="add_user">
            <div className="container">
              <div className="flex">
                <div className="flex-1">
                  <form className="add_form" onSubmit={handlePasswordRegister}>
                    <h1>
                      <span>Reset Password</span>
                    </h1>
                    <p>Password * &nbsp;</p>
                    <Input
                      type="password"
                      name="password-field"
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />

                    <p>Confirm Password *</p>
                    <Input
                      type="password"
                      name="confirm-password-field"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    <p style={{ color: "red", fontSize: "0.7rem" }}>
                      {confirmPasswordError}
                    </p>

                    <button className="link marg-up-10" value="register">
                      Change Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div>
          <h1 className="msg">{message}</h1>
        </div>
      )}
    </div>
  );
};

export default PasswordReset;
