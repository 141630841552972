const toBoolean = (string: string | undefined) => string === "true";

const cdnUrl = "https://collaborationcdn.nyc3.cdn.digitaloceanspaces.com";
const config = {
  audioToAllPeers: toBoolean(process.env.REACT_APP_AUDIO_TO_ALL),
  isRecordingEnabled: toBoolean(process.env.REACT_APP_RECORDING_ENABLED),
  centralAudioOptions: {
    sampleRate: 48000, // default : 96khz / will not eat that much bandwith thanks to opus
    channelCount: 1, // default : 1 / usually mics are mono so this saves bandwidth
    volume: 1.0, // default : 1.0
    sampleSize: 16, // default : 16
    opusStereo: false, // default : false / usually mics are mono so this saves bandwidth
    opusDtx: true, // default : true / will save bandwidth
    opusFec: true, // default : true / forward error correction
    opusPtime: 20, // default : 20 / minimum packet time (3, 5, 10, 20, 40, 60, 120)
    opusMaxPlaybackRate: 96000,
  },
  frameRate: 15,
  screenSharingFrameRate: 5,
  //Human api configuration
  humanApi: {
    warmup: "none",
    backend: "wasm",
    wasmPath:
      "https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@3.9.0/dist/",
    modelBasePath: `${cdnUrl}/models`,
    filter: { enabled: false },
    gesture: { enabled: false },
    face: {
      enabled: true,
      detector: {
        minConfidence: 0.5,
      },
      mesh: {
        enabled: false,
      },
      iris: {
        enabled: false,
      },
      description: {
        enabled: false,
      },
      emotion: {
        enabled: toBoolean(process.env.REACT_APP_EMOTION_DETECTION),
      },
    },
    body: { enabled: false },
    hand: { enabled: false },
    object: { enabled: false },
    segmentation: { enabled: false },
  },
  pwa: {
    enabled: true,
    cacheName: "CollabRoomHuman",
    scriptFile: "/sw.js",
    cacheModels: true,
    cacheWASM: true,
    cacheOther: false,
  },
  cdnUrl: cdnUrl,
  tinyMCE: process.env.REACT_APP_TINYMCE_API_KEY
};

export default config;
