import { SET_VIDEO_OPTION,CLEAR_REDUX_DATA } from "../actions/actionTypes";

const initialState = {
  videoOption: "normal",
};
interface IAction {
  type: string;
  payload: any;
}

const videoOptionReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_VIDEO_OPTION: {
      return {
        ...state,
        videoOption: action.payload,
      };
    }
    case CLEAR_REDUX_DATA:{
      return initialState
    }

    default: {
      return state;
    }
  }
};

export default videoOptionReducer;
