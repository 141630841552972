import AppRouter from "./AppRouter";
import { isSessionActive, logoutActiveTime } from "../services/login";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

function App() {
  useEffect(() => {
    try {
    var sessionActiveInterval = setInterval(async () => {
      await isSessionActive();
    }, 1000 * 60 * 10);
    window.addEventListener("beforeunload", function (e) {
      // e.preventDefault();
      logoutActiveTime();
      console.log('test unload')
    });
  } catch (e:any) {
    console.log(e.message);
  }
    return () => {
      clearTimeout(sessionActiveInterval);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
