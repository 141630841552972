import "./launchApp.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import Loader from "../../components/Loader";
import { Navigate, useParams } from "react-router-dom";
import { handleLogin, setViaAppTrue } from "../../actions/loginActions";

type Params = {username: string};

const AutoLogin = (props: any) => {
  const dispatch = useDispatch();
  let {username} = useParams<keyof Params>() as Params;
  username = username.replace("---", "/");
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const isLoading = useSelector((state: RootState) => state.login.isLoading);
  const autoLoginLoading = useSelector(
    (state: RootState) => state.login.autoLoginLoading
  );

  if (isLoggedIn) { 
    dispatch(setViaAppTrue());
    console.log("isLoged in", isLoggedIn, isLoading, autoLoginLoading);
    return <Navigate to="/dashboard" replace={true}  />;
  } else if (!isLoggedIn && !isLoading && !autoLoginLoading) {
    console.log(
      "isLoggedIn  isLoading  autoLoginLoading",
      isLoggedIn,
      isLoading,
      autoLoginLoading
    );
    return <Navigate to="/login" replace={true}  />;
  } else if (!isLoggedIn && !isLoading) {
    console.log("else", isLoggedIn, isLoading, autoLoginLoading);
    dispatch(handleLogin(username, "autologin"));
  }

  return (
    <>
      <div className="Download_app_page">
        <div className="download_header">
          <h1>
            Welcome to{" "}
            <b style={{ textDecoration: "underline" }}>CollaborationRoom</b>
          </h1>
        </div>
        <div className="download_msg">
          <h4>Please wait we are logging you to the application</h4>
        </div>
        <Loader />
      </div>
    </>
  );
};

export default AutoLogin;
