// import base64ToFile from "../utils/base64tofile";
import axios from "axios";
import request from "./index";

const config = {
  headers: {
    Authorization: "Bearer WHqrZTwfigjsh291xMtgGWsTVSx67DGg",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "mode":"no-cors"
  },
};
// const registerConfig = {
//   headers: {
//     "x-functions-key":
//       "qJA2p9_cvnPORiSkf67uuOrbtCgPUqh5Y50FHHhy595PAzFuwVRGkg==",
//     "Content-Type": "application/json",
//     // "Access-Control-Allow-Origin": "*",
//     // "mode":"no-cors"
//   },
// };

export const faceRegistration = async (
  url: string,
  username: string,
  client: string,
  image: any,
  remove_existing: boolean
) => {
  const faceRequest = axios.create({
    baseURL: url,
    // withCredentials: true,
  });

  // let usernameChars:string[] = username.split('_');
  // let newUsername:string = usernameChars[0].concat(usernameChars[1])
  const body = {
    username: username,
    client,
    image: image,
    remove_existing: remove_existing.toString(),
  };

  const response = await faceRequest.post("/faceconfiguration", body, config);
  console.log({ response });
  return response.data;
};

export const verifyRegister = async (username: string) => {
  const body = {
    username,
  };

  console.log({ body });
  const response = await request.post("/checkIsRegistered", body);
  console.log({ response });
  return response.data;
};

export const saveFace = async (url: string,file:any,companyName:string) => {
  // const body = {
  //   imageUrl: url,
  // };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const data = new FormData();
  data.append("file", file);
  data.append("imageUrl", url);
  data.append('companyName',companyName)
  const response = await request.post("/saveface", data,config);
  return response.data;
};

export const getPendingVerification = async () => {
  // const body = {
  //   imageUrl: url,
  // };

  const response = await request.post("/getpendingverification", {});
  return response.data;
};
// verifyusers

export const verifyUsers = async (usernames: string[]) => {
  const body = {
    users: usernames,
  };

  const response = await request.post("/verifyusers", body);
  return response.data;
};

// rejectUsers
export const rejectUsers = async (usernames: string[]) => {
  const body = {
    users: usernames,
  };

  const response = await request.post("/rejectusers", body);
  return response.data;
};

export const isUserRejected = async (username: string) => {
  const body = {
    username: username,
  };

  const response = await request.post("/checkIsRejected", body);
  return response.data;
};
