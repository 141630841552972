import {
  SET_AUDIO_FILES_FALSE,
  SET_AUDIO_FILES_TRUE,
  SET_VIDEO_FILES_FALSE,
  SET_VIDEO_FILES_TRUE,
  CLEAR_AUDIO_VIDEO_FILES,
  CLEAR_REDUX_DATA,
  ADD_HIGH_RES_GET_USER,
  REMOVE_HIGH_RES_GET_USER_STREAM_TYPE,
  REMOVE_HIGH_RES_GET_USER,
} from "../actions/actionTypes";

interface highResUserInterface {
  [key: string]: Array<string>;
}

const initialState = {
  isVideoFile: false,
  isAudioFile: false,
  highResUser: {} as highResUserInterface,
};
interface IAction {
  type: string;
  payload: any;
}

const audioVideoFileReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_AUDIO_FILES_TRUE: {
      return {
        ...state,
        isAudioFile: true,
      };
    }
    case SET_AUDIO_FILES_FALSE: {
      return {
        ...state,
        isAudioFile: false,
      };
    }
    case SET_VIDEO_FILES_FALSE: {
      return {
        ...state,
        isVideoFile: false,
      };
    }
    case SET_VIDEO_FILES_TRUE: {
      return {
        ...state,
        isVideoFile: true,
      };
    }
    case ADD_HIGH_RES_GET_USER: {
      return {
        ...state,
        highResUser: {
          ...state.highResUser,
          [action.payload.username]: action.payload.streamType,
        },
      };
    }
    case REMOVE_HIGH_RES_GET_USER_STREAM_TYPE: {
      return {
        ...state,
        highResUser: {
          ...state.highResUser,
          [action.payload.username]: action.payload.streamType,
        },
      };
    }
    case REMOVE_HIGH_RES_GET_USER: {
      return {
        ...state,
        isVideoFile: true,
      };
    }

    case CLEAR_AUDIO_VIDEO_FILES: {
      return initialState;
    }
    case CLEAR_REDUX_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default audioVideoFileReducer;
