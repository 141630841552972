import {
  GET_ENVIRONMENTS_SUCCESS,
  GET_ENVIRONMENTS_FAILURE,
} from "../actions/actionTypes";
import { environmentsType } from "../Pages/RoomPage/interfaces";

const initialState = {
  environments: {} as environmentsType,
  successMessage: "",
  failureMessage: "",
};

interface IAction {
  type: string;
  payload: any;
}

const environmentReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_ENVIRONMENTS_SUCCESS: {
      return {
        ...state,
        environments: action.payload,
      };
    }

    case GET_ENVIRONMENTS_FAILURE: {
      return {
        ...state,
        failureMessage: action.payload?.errorMessage,
      };
    }
    default: {
      return state;
    }
  }
};

export default environmentReducer;
