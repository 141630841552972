import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import { getUserDetails } from "../services/user";
import {
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  SET_SOCKETID,
  SET_CAMERA_BACKGROUND,
  GET_CAMERA_BACKGROUND,
} from "../actions/actionTypes";
import {
  getBackgroundActivate,
  setBackgroundActivate,
} from "../services/backgroundActivate";

const setUserDetails = (userData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_USER_DETAILS_SUCCESS,
      payload: userData,
    });
  };
};

const setError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_USER_DETAILS_FAILURE,
      payload: errorMessage,
    });
  };
};

export const getUserDetailsAction = (roomname: any = null) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const userData = await getUserDetails(roomname);
      if (userData) {
        dispatch(setUserDetails(userData));
      } else {
        dispatch(setError("Could not fetch user details. Please try again"));
      }
      return userData;
    } catch (error: any) {
      setError(
        error?.message || "Could not fetch user details. Please try again"
      );
    } finally {
      setLoading(false);
    }
  };
};

export const setCameraBackground = (
  username: string,
  backgroundType: string,
  url: string
) => {
  return async (dispatch: Dispatch<any>) => {
    await setBackgroundActivate(username, backgroundType, url);
    dispatch({
      type: SET_CAMERA_BACKGROUND,
      payload: { backgroundType, url },
    });
  };
};

export const getCameraBackground = (username: string) => {
  return async (dispatch: Dispatch<any>) => {
    const result: any = await getBackgroundActivate(username);

    dispatch({
      type: GET_CAMERA_BACKGROUND,
      payload: { ...result.userBackground },
    });
  };
};

export const setMySocketId = (socketId: string) => ({
  type: SET_SOCKETID,
  payload: { socketId },
});
