import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import AudioFile from "./audioFile";
import VideoFile from "./videoFile";
import {
  faMicrophone,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface uploadFileType {
  shareUnshareScreen: any;
  peerId: string;
  addStreamOfAudioFile: any;
  pauseUnPauseAudio: any;
  isAudioPaused: boolean;
  addStreamOfVideoFile: any;
  localAudioStream: any;
  localVideoStream: any;
}

const UploadFile = (props: uploadFileType) => {
  const [file, setFile] = useState("");
  const [isVideoOrAudio, setIsVideoOrAudio] = useState("");

  const isAudioFile = useSelector(
    (state: RootState) => state.audioVideoFileReducer.isAudioFile
  );
  const isVideoFile = useSelector(
    (state: RootState) => state.audioVideoFileReducer.isVideoFile
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // TODO: do something with -> this.state.file
    if (isVideoOrAudio === "video") {
      props.addStreamOfVideoFile(props.peerId);
    } else if (isVideoOrAudio === "audio") {
      props.addStreamOfAudioFile(props.peerId, "audiosss");
    }
  };

  // const playAudio = (e: any) => {
  //   e.preventDefault();
  //   if (props.localAudioStream && !props.localVideoStream) {
  //     props.addStreamOfAudioFile(props.peerId, "audiosss");
  //   }
  //   if (props.localVideoStream) {
  //     console.log(props.localVideoStream, "videostream");
  //     props.addStreamOfAudioFile(props.peerId, "audiosss");
  //     props.addStreamOfVideoFile(props.peerId);
  //   }
  // };

  const handleImageChange = (e: any) => {
    e.preventDefault();
    let fileVal = e.target.files[0];

    if (fileVal.type.includes("audio")) {
      setIsVideoOrAudio("audio");
    } else if (fileVal.type.includes("video")) {
      setIsVideoOrAudio("video");
    }

    let blobURL = URL.createObjectURL(fileVal);
    setFile(blobURL);
  };

  const handleBack = () => {
    if (isAudioFile) {
      props.addStreamOfAudioFile(props.peerId, "audiosss");
    }
    if (isVideoFile) {
      props.addStreamOfVideoFile(props.peerId);
    }
    setIsVideoOrAudio("");
    setFile("");
  };

  return (
    <div className="previewComponent">
      {isVideoOrAudio === "video" ? <VideoFile blob={file} /> : null}
      {isVideoOrAudio === "audio" ? <AudioFile blob={file} /> : null}

      {isVideoOrAudio === "" ? (
        <form>
          <input
            className="fileInput"
            type="file"
            onChange={(e) => handleImageChange(e)}
          />
        </form>
      ) : (
        <div className="upload_file_container">
          {!isAudioFile ? (
            <button
              className="submitButton"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Share To All
            </button>
          ) : null}

          {/* {(props.localAudioStream || props.localVideoStream) &&
          !isAudioFile &&
          !isVideoFile ? (
            <button
              className="submitButton"
              type="submit"
              onClick={(e) => playAudio(e)}
            >
              Mute Audio
            </button>
          ) : null} */}
          <button
            className="submitButton"
            type="submit"
            onClick={(e) => handleBack()}
          >
            Cancel
          </button>

          <button
            onClick={() => props.pauseUnPauseAudio(props.peerId)}
            className="submitButton"
            style={{
              borderRadius: "100%",
              width: "47px",
              height: "47px",
              backgroundColor: "rgb(38, 163, 219)",
              outlineColor: "none",
            }}
          >
            <FontAwesomeIcon
              icon={props.isAudioPaused ? faMicrophoneSlash : faMicrophone}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default UploadFile;
