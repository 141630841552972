import {
  SET_REGISTRATION_FAILURE,
  SET_REGISTRATION_SUCCESS,
  SET_REGISTRATION_STATUS_SUCCESS,
  SET_REGISTRATION_STATUS_FAILURE,
  SET_REJECTED_STATUS_SUCCESS,
  SET_REJECTED_STATUS_FAILURE,
  SET_USERDETAILS_REGISTERED,
  SET_REGISTER_COUNT,
} from "../actions/actionTypes";
import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  faceRegistration,
  isUserRejected,
  saveFace,
  verifyRegister,
} from "../services/faceRegistration";
import { toast } from "react-toastify";
import resizeImageAndReturnFileAndURL from "../utils/base64tofile";
import generateUrl from "../utils/generateImageUrl";

// import FaceRegistration from "../components/FaceRegistration";

async function returnFile(url: string) {
  try {
    const imageUrl = url;
    const targetWidth = 640;
    const targetHeight = 480;

    const { file, modifiedImageUrl } = await resizeImageAndReturnFileAndURL(
      imageUrl,
      targetWidth,
      targetHeight
    );
    return { file, modifiedImageUrl };

    // You can use the 'file' object here or return it from your function as needed.
  } catch (error) {
    console.error(error);
  }
}


let localCount = 0;
export const setFaceRegistrationAction = (
  url: string,
  username: string,
  client: string,
  image: any,
  remove_existing: boolean,
  spacesConfig:any,
  companyName:string
  ) => {
  if(localCount >= 3){
    localCount = 0;
  }
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      // @ts-ignore
      const { file, modifiedImageUrl } = await returnFile(image);
      console.log(modifiedImageUrl)
      // console.log({username})
      const registerResponse = await faceRegistration(
        url,
        username,
        client,
        modifiedImageUrl,
        remove_existing
      );
      if (registerResponse.is_registered) {
        dispatch({
          type:SET_REGISTER_COUNT,
          payload:{
            count:0
          }
        })
        
        // @ts-ignore
        const saveResponse = await saveFace(generateUrl(username,spacesConfig,companyName),file,companyName);
        if (saveResponse.success) {
          dispatch(setRegistrationStatus(registerResponse));
          dispatch({
            type:SET_USERDETAILS_REGISTERED,
            payload:true
          })
        } else {
          dispatch(setRegistrationFailure(saveResponse.message.toUpperCase()));
          toast.error(saveResponse.message.toUpperCase());
        }
      } else {
        dispatch({
          type:SET_REGISTER_COUNT,
          payload:{
            count:++localCount
          }
        })
        dispatch(setRegistrationFailure(registerResponse?.errors));
        Object.keys(registerResponse?.errors).forEach((key: string) => {
          toast.error(registerResponse?.errors[key]);
        });
        // dispatch(setLoading(true));
      }
    } catch (err: any) {
      dispatch(setRegistrationFailure(err.message));
      // setLoading(false);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setRegistrationStatus = (resp: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_REGISTRATION_SUCCESS,
      payload: resp,
    });
  };
};

const setRegistrationFailure = (message: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_REGISTRATION_FAILURE,
      payload: message,
    });
  };
};

export const checkIsRegistered = (username: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const regResponse = await verifyRegister(username);
      console.log({ regResponse });
      if (regResponse.status) {
        dispatch(setCheckRegisterSuccess(regResponse.isRegistered));
      } else {
        dispatch(setCheckRegisterFailure(regResponse.isRegistered, ""));
      }
    } catch (err: any) {
      dispatch(setCheckRegisterFailure(false, err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setCheckRegisterSuccess = (status: boolean) => {
  console.log("I called here!!", { status });
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_REGISTRATION_STATUS_SUCCESS,
      payload: {
        isRegistered: status,
      },
    });
  };
};

const setCheckRegisterFailure = (status: boolean, message: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_REGISTRATION_STATUS_FAILURE,
      payload: {
        isRegistered: status,
        message: message,
      },
    });
  };
};

export const userRejectedAction = (username: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const regResponse = await isUserRejected(username);
      // console.log({regResponse})
      if (regResponse.status) {
        dispatch(setRejectedActionSuccess(regResponse.isRejected));
      } else {
        dispatch(setRejectedActionFailure(regResponse.isRejected, ""));
      }
    } catch (err: any) {
      dispatch(setRejectedActionFailure(false, err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setRejectedActionSuccess = (status: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_REJECTED_STATUS_SUCCESS,
      payload: {
        isRejected: status,
      },
    });
  };
};

const setRejectedActionFailure = (status: boolean, message: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_REJECTED_STATUS_FAILURE,
      payload: {
        isRejected: status,
        message: message,
      },
    });
  };
};
