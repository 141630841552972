import {
  SET_NOTIFICATIONS,
  CLEAR_REDUX_DATA,
  SET_NOTIFICATIONS_END_TIME,
  SET_NOTIFICATIONS_FOR_SINGLE,
  CLEAR_NOTIFICATIONS,
} from "../actions/actionTypes";

interface notiType {
  [key: string]: Array<{
    numfaces: Number;
    startTime: Number;
    endTime: Number;
    msg: string;
    username: string;
    fullname: string;
    durType: boolean;
  }>;
}

interface notiUsername {
  [key: string]: notiType;
}

interface notifullName {
  fullname: string;
}

interface notiUserFullname {
  [key: string]: notifullName;
}

interface noti {
  notifications: notiUsername;
  notiArrayParam: Array<string>;
  notiUserfullname : notiUserFullname
}

const initialState: noti = {
  notifications: {},
  notiArrayParam: [],
  notiUserfullname:{}
};

interface IAction {
  type: string;
  payload: any;
}

const notificationsReducer = (state = initialState, action: IAction) => {
  let notificationState;

  let notificationStatePeer;
  let notiUserfullnameState;

  let notificationStatePeerNotiType;

  if (action.payload) {
    notificationState = state.notifications;

    notificationStatePeer = notificationState[action.payload.username];
    notiUserfullnameState = state.notiUserfullname;

    notificationStatePeerNotiType = notificationStatePeer
      ? notificationStatePeer[action.payload.notiType]
      : null;
  }
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      return {
        notiArrayParam:
          state.notiArrayParam.length > 0
            ? [
                action.payload.username,
                ...state.notiArrayParam.filter(
                  (value) => value !== action.payload.username
                ),
              ]
            : [action.payload.username],
        notiUserfullname: {...notiUserfullnameState,[action.payload.username]: {fullname:action.payload?.fullname}},

        notifications: {
          ...notificationState,
          [action.payload.username]: {
            ...notificationStatePeer,
            [action.payload.notiType]:
              notificationStatePeerNotiType &&
              notificationStatePeerNotiType.length > 0
                ? [
                    {
                      numfaces: action.payload.numfaces,
                      startTime: action.payload.startTime,
                      endTime: action.payload.endTime,
                      msg: action.payload.msg,
                      username: action.payload.username,
                      fullname: action.payload?.fullname,
                    },
                    ...notificationStatePeerNotiType,
                  ]
                : [
                    {
                      numfaces: action.payload.numfaces,
                      startTime: action.payload.startTime,
                      endTime: action.payload.endTime,
                      msg: action.payload.msg,
                      username: action.payload.username,
                      fullname: action.payload?.fullname,
                    },
                  ],
          },
        },
      };
    }

    case SET_NOTIFICATIONS_END_TIME: {
      return {
        ...state,
        notifications: {
          ...notificationState,
          [action.payload.username]: {
            ...notificationStatePeer,
            [action.payload.notiType]: !notificationStatePeerNotiType
              ? []
              : notificationStatePeerNotiType.map((value) => {
                  if (
                    value.username === action.payload.username &&
                    value.endTime === 0
                  ) {
                    return { ...value, durType: true, endTime: action.payload.endTime };
                  } else {
                    return { ...value };
                  }
                }),
          },
        },
      };
    }

    case SET_NOTIFICATIONS_FOR_SINGLE: {
      return {
        notiArrayParam:
          state.notiArrayParam.length > 0
            ? [
                action.payload.username,
                ...state.notiArrayParam.filter(
                  (value) => value !== action.payload.username
                ),
              ]
            : [action.payload.username],

          notiUserfullname: {...notiUserfullnameState,[action.payload.username]: {fullname:action.payload?.fullname}},

        notifications: {
          ...notificationState,
          [action.payload.username]: {
            ...notificationStatePeer,
            [action.payload.notiType]:
              notificationStatePeerNotiType &&
              notificationStatePeerNotiType.length > 0
                ? [
                    {
                      startTime: action.payload.startTime,
                      endTime: action.payload.startTime,
                      msg: action.payload.msg,
                      username: action.payload.username,
                      fullname: action.payload?.fullname,
                    },
                    ...notificationStatePeerNotiType,
                  ]
                : [
                    {
                      startTime: action.payload.startTime,
                      endTime: action.payload.startTime,
                      msg: action.payload.msg,
                      username: action.payload.username,
                      fullname: action.payload?.fullname,
                    },
                  ],
          },
        },
      };
    }

    case CLEAR_REDUX_DATA:
    case CLEAR_NOTIFICATIONS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default notificationsReducer;
