export const VIDEO_SIMULCAST_ENCODINGS = [
  // { maxBitrate: 100000 },
  { maxBitrate: 300000 },
  { maxBitrate: 600000 },
];

// Used for VP9 webcam video.
export const VIDEO_KSVC_ENCODINGS = [{ scalabilityMode: "S3T3_KEY" }];
// Export for Simulcast per Sameer
export const VIDEO_SIMULCAST_ENCODINGS_SM = [
  {
    rid: "r0",
    maxBitrate: 200000,
    scaleResolutionDownBy: 4,
    scalabilityMode: "L1T3",
  },
  {
    rid: "r1",
    maxBitrate: 600000,
    scaleResolutionDownBy: 1,
    scalabilityMode: "L1T3",
  },
  // {
  //   rid: "r2",
  //   maxBitrate: 200000,
  //   scalabilityMode: "S1T3",
  // },
];

export const SCREEN_SHARING_SIMULCAST_ENCODINGS = [
  // { dtx: true, maxBitrate: 20000 },
  // { dtx: true, maxBitrate: 600000 },
  {
    rid: "r0",
    maxBitrate: 200000,
    scaleResolutionDownBy: 4,
    // scalabilityMode: "L1T3",
  },
  {
    rid: "r1",
    maxBitrate: 1000000,
    scaleResolutionDownBy: 1,
    // scalabilityMode: "L1T3",
  },
];

export const NETWORK_MONITORING_INTERVAL = 10 * 1000;
export const AGENT_TO_AGENT_CHAT = true;
export const AGENT_TO_AGENT_PRIVATE_CALL = true;

export const SUPERVISOR_CAN_OBSERVE = true;
export const OTHER_CAMERA_ACCESS_TO_SUPERVISOR = true;
export const AGENT_CAM_AUTO_ON = false;
export const AGENT_MIC_ACCESS = true;
export const AGENT_SCREENS_ACCESS = true
export const EMOTION_DETECTION_ADMIN_CONTROL = true
export const REACT_APP_HEAD_POSE = true;
export const AGENT_CAM_ALWAYS_ON = false;
export const VIDEO_BACKGROUND_BLUR = true;
export const VIDEO_BACKGROUND_IMAGES = true;
export const LUNCH_TIME_LIMIT_MINUTES = { value: "30", status: false };
export const BREAK_TIME_LIMIT_MINUTES = { value: "30", status: false };
export const BRB_TIME_LIMIT_MINUTES = { value: "10", status: false };
export const FILE_UPLOADS_CHAT = true;
export const FILE_UPLOADS_TOPICS = true;
export const SCREEN_SHARE_HEIGHT = 1080;
export const SCREEN_SHARE_WIDTH = 1920;

export const LOW_RESOLUTION_PARTICIPANT_COUNT = 3; // Lower the resolution of every video when participants reaches this count
export const REACT_APP_SCREENCONTROL = false;
export const REACT_APP_VERSION = 3.4;
export const REACT_APP_EMOTION_DETECTION = false;
export const REACT_APP_CELLPHONE_DETECTION = false;
export const REACT_APP_FACE_DETECTION = false;
export const REACT_APP_FACE_VERIFICATION = false;

export const REACT_APP_DETECTION_TOKEN = "WHqrZTwfigjsh291xMtgGWsTVSx67DGg";
export const STORE_DETECTION_IMAGES = { value: "dev", status: "false" };
export const REACT_APP_COMPLEX_PASSWORD = false;




