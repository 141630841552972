import React, { SetStateAction, useEffect, useRef, useState } from "react";
import Submit from "./../../assets/images/submit.svg";
import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faInfoCircle, faPaperclip, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../utils/Token";
import PdfIcon from "./../../assets/images/pdf-icon.svg";
import PptIcon from "./../../assets/images/powerpoint-icon.svg";
import ExcelIcon from "./../../assets/images/excel-icon.svg";
import defaultIcon from "./../../assets/images/default-icon.svg";
import txtIcon from "./../../assets/images/txt-icon.svg";
import wordIcon from "./../../assets/images/word-icon.svg";
import csvIcon from "./../../assets/images/csv-icon.svg";
import { toast } from "react-toastify";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FILE_UPLOADS_CHAT } from "../../Pages/RoomPage/constants";
import SocketServiceSingleton from "../../services/socket";
import { socketConnectiontype } from "../../utils/constant";
library.add(faSpinner);

const socketInstance = new SocketServiceSingleton(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

interface chatInputProps {
  sendMessage: (value: string) => void;
  onlineUsers: any;
}

interface Attachment {
  url: string;
  type: string;
  name: string;
}

const Chatinput = (props: chatInputProps) => {
  const [input, setInput] = useState("");
  const [inputFiles, setInputFiles] = useState("");
  const [imageUploading, setImageUploading] = useState(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const selectedChatUser = useSelector(
    (state: RootState) => state.chatData.selectedChatUser
  );
  const userData = useSelector((state: RootState) => state.userDetails);
  const chatData = useSelector((state:RootState) => state.chatData);
  const typingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const environments = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  );
  const fileUploadAccessForChat = environments.FILE_UPLOADS_CHAT
  ? environments.FILE_UPLOADS_CHAT.status
  : FILE_UPLOADS_CHAT;

  const clientName =  environments.REACT_APP_COMPANY_NAME
  ? environments.REACT_APP_COMPANY_NAME.value
  : process.env.REACT_APP_COMPANY_NAME;

  const checkEnterKey = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      //Stops enter from creating a new line
      e.preventDefault();
      if (inputFiles.trim().length !== 0 && input.trim().length !== 0) {
        props.sendMessage(inputFiles + input);
      } else if (inputFiles.trim().length !== 0) {
        props.sendMessage(inputFiles);
      } else {
        props.sendMessage(input);
      }
      setInput("");
      setInputFiles("");
      setAttachments([]);
      return true;
    }
  };
  const sendStopTypingStatus = () => {
    let dataTosend = {
        peerName: userData.firstName + ' ' + userData.lastName,
        TypingType: chatData.selectedChatUser.chatType,
        receiverUsername: ""
    }
    if (chatData.selectedChatUser.chatType === "PRIVATE") {
        dataTosend.receiverUsername = chatData.selectedChatUser.peerName
    }
    socketInstance.sendMessage("STOP_TYPING", dataTosend)
}

    // Function to handle input change
   const handleChangeInput = (e: { target: { value: SetStateAction<string> } }) => {
    setInput(e.target.value);

    // Clear existing timeout and set a new one
    if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
    }

    if (!typingTimeoutRef.current) {
        sendTypingStatus();
    }

    typingTimeoutRef.current = setTimeout(() => {
        sendStopTypingStatus();
        typingTimeoutRef.current = null;
    }, 700);  // Adjust debounce delay as needed
};

const sendTypingStatus = () => {

  let dataTosend = {
    peerName: userData.firstName + ' ' + userData.lastName,
    TypingType: chatData.selectedChatUser.chatType,
    receiverUsername: ""
}
if (chatData.selectedChatUser.chatType === "PRIVATE") {
    dataTosend.receiverUsername = chatData.selectedChatUser.peerUserName
}
  socketInstance.sendMessage("TYPING", dataTosend)
};

  useEffect(() => {
    if (selectedChatUser.chatType === "EVERYONE") {
        setInput('');
        setInputFiles("");
        setAttachments([]);
    }
  }, [selectedChatUser.chatType]);

  function extractOnlinePeerUsernames(props: any): SuggestionDataItem[] {
    if (selectedChatUser.chatType === "EVERYONE") {
      return props.onlineUsers.map((peer: any) => ({
        id: peer.peerUsername,
        display: peer.peerUsername,
      }));
    } else {
      return [
        {
          id: selectedChatUser.peerUserName,
          display: selectedChatUser.peerUserName,
        },
      ];
    }
  }

  const handleFileUploadInChat = async (e: any) => {
    if (attachments.length === 0) {
      // Allow Single upload at a time
      const file = e.target.files[0];
      if (file) {
        // Define allowed file types
        const allowedImageTypes: string[] = [
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/svg+xml",
        ];
        const allowedDocumentTypes: string[] = [
          "application/pdf",
          "application/msword",
          "application/vnd.ms-excel",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "text/plain",
          "text/csv",
          "application/rtf", // RTF (Rich Text Format)
          "application/json", // JSON
        ];

        // Check if the file type is not in allowed types
        if (
          !allowedImageTypes.includes(file.type) &&
          !allowedDocumentTypes.includes(file.type)
        ) {
          toast("Only images and documents uploads are allowed.");
          return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("clientname", clientName);
        // formData.append("roomname", roomname);

        const token = getToken();
        try {
          setImageUploading(true);
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/uploadfiletochat`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          });
          setImageUploading(false);

          if (response.data && response.data.location) {
            const fileURL = response.data.location;

            setAttachments([
              ...attachments,
              {
                url: fileURL,
                type: file.type,
                name: file.name,
              },
            ]);

            // Check if the file is an image
            if (file.type.startsWith("image/")) {
              // Image
              setInputFiles(
                `<a href="${fileURL}" download><img src="${fileURL}" alt="Uploaded image" style="max-width:250px;" /></a> <br />`
              );
            } else {
              // For non-image files, determine the file type and display the corresponding icon
              let icon;
              if (file.type === "application/pdf") {
                icon = `<img src=${PdfIcon} alt="PDF icon" style="width:30px;"/>`;
              } else if (file.type === "application/vnd.ms-powerpoint") {
                icon = `<img src=${PptIcon} alt="PowerPoint icon" style="width:30px;"/>`;
              } else if (
                file.type === "application/vnd.ms-excel" ||
                file.type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ) {
                icon = `<img src=${ExcelIcon} alt="Excel icon" style="width:30px;"/>`;
              } else if (file.type === "text/plain") {
                icon = `<img src=${txtIcon} alt="txt icon" style="width:30px;"/>`;
              } else if (
                file.type === "application/msword" ||
                file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                icon = `<img src=${wordIcon} alt="doc icon" style="width:30px;"/>`;
              } else if (file.type === "text/csv") {
                icon = `<img src=${csvIcon} alt="doc icon" style="width:30px;"/>`;
              } else {
                icon = `<img src=${defaultIcon} alt="default icon" style="width:30px;"/>`;
              }
              setInputFiles(`
                <div style="max-width:250px; cursor: pointer; border: 1px solid #f3f3f3; padding: 10px; margin: 10px; box-shadow: 2px 2px 5px rgba(0,0,0,0.2);">
    <a href="${fileURL}" download="${file.name}" style="text-decoration: none; color: #000; font-weight: bold;">
    <span style="font-size: 30px; color: #333; margin-right: 10px;">${icon}</span>
        ${file.name}
    </a>
</div><br /> `);
            }
          }
        } catch (error) {
          setImageUploading(false);
          console.error("Error uploading file:", error);
        } finally {
          e.target.value = null;
        }
      }
    } else {
      toast("Please send selected file first");
      e.target.value = null;
    }
  };

  const removeAttachment = (attachment: any) => {
    // TODO: Also remove file from Digital Ocean Spaces
    setAttachments(attachments.filter((a) => a.url !== attachment.url));
    setInputFiles("");
  };

  function truncateAttachmentName(attachmentName: string) {
    const maxLength = 25;
    if (attachmentName.length > maxLength) {
      return attachmentName.substring(0, maxLength) + "...";
    }
    return attachmentName;
  }

  return (
    <>
      {imageUploading && (
        <div className="uploading-file-indicator">
          <FontAwesomeIcon icon="spinner" spin />
          <strong> Uploading File...</strong>
        </div>
      )}

      {attachments.map((attachment) => (
        <div key={attachment.url} className="attachment-preview">
          {attachment.type.startsWith("image/") ? (
            <img
              src={attachment.url}
              alt={attachment.name}
              className="image-thumbnail"
            />
          ) : (
            <>
              <span style={{ fontSize: "30px" }}>
                {attachment.type === "application/pdf" ? (
                  <img src={PdfIcon} alt="PDF icon" style={{ width: "30px" }} />
                ) : attachment.type === "application/vnd.ms-powerpoint" ? (
                  <img
                    src={PptIcon}
                    alt="PowerPoint icon"
                    style={{ width: "30px" }}
                  />
                ) : attachment.type === "application/vnd.ms-excel" ||
                  attachment.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                  <img
                    src={ExcelIcon}
                    alt="Excel icon"
                    style={{ width: "30px" }}
                  />
                ) : attachment.type === "text/plain" ? (
                  <img src={txtIcon} alt="txt icon" style={{ width: "30px" }} />
                ) : attachment.type === "application/msword" ||
                  attachment.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                  <img
                    src={wordIcon}
                    alt="txt icon"
                    style={{ width: "30px" }}
                  />
                ) : attachment.type === "text/csv" ? (
                  <img src={csvIcon} alt="txt icon" style={{ width: "30px" }} />
                ) : (
                  <img
                    src={defaultIcon}
                    alt="txt icon"
                    style={{ width: "30px" }}
                  />
                )}
              </span>{" "}
              <span
                style={{ maxWidth: "250px", color: "blue" }}
              >
                {truncateAttachmentName(attachment.name)}
              </span>
            </>
          )}
          <button
            onClick={() => removeAttachment(attachment)}
            aria-label="Remove attachment"
            className="remove-attachment-button"
          >
            <FontAwesomeIcon
              style={{ fontSize: "21px", cursor: "pointer" }}
              icon={faTrashCan}
            />
          </button>
        </div>
      ))}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (inputFiles.trim().length !== 0 && input.trim().length !== 0) {
            props.sendMessage(inputFiles + input);
          } else if (inputFiles.trim().length !== 0) {
            props.sendMessage(inputFiles);
          } else {
            props.sendMessage(input);
          }
          setInput("");
          setInputFiles("");
          setAttachments([]);
        }}
        className="chat-form"
      >
        <MentionsInput
          value={input}
          onChange={handleChangeInput}
          onKeyDown={checkEnterKey}
          placeholder="Write your message"
          className="chat_textarea"
        >
          <Mention
            trigger="@"
            data={extractOnlinePeerUsernames(props)}
            renderSuggestion={(
              suggestion,
              search,
              highlightedDisplay,
              index,
              focused
            ) => (
              <div className={focused ? "focused" : ""}>
                {highlightedDisplay}
              </div>
            )}
          />
        </MentionsInput>
        {fileUploadAccessForChat &&
        <>
        <>
            <input
              type="file"
              onChange={handleFileUploadInChat}
              style={{ display: "none" }}
              id="fileInput"
            />
            <label htmlFor="fileInput" className="attach-btn">
              <FontAwesomeIcon
                style={{ fontSize: "21px", margin: "10px", cursor: "pointer" }}
                icon={faPaperclip}
                title="click to upload image or document"
              />
            </label>
        </>
        <>
              <FontAwesomeIcon
                style={{ fontSize: "21px", margin: "10px" }}
                icon={faInfoCircle}
                title={`Allowed Image Types:
                        - JPEG (.jpg, .jpeg)
                        - PNG (.png)
                        - GIF (.gif)
                        - SVG (.svg)

                        Allowed Document Types:
                        - PDF (.pdf)
                        - Word (.doc, .docx)
                        - Excel (.xls, .xlsx)
                        - PowerPoint (.ppt, .pptx)
                        - Text (.txt)
                        - CSV (.csv)
                        - Rich Text Format (.rtf)
                        - JSON (.json)
                        `
                      }
                />

        </>
        </>
        }
        <button type="submit" className="submit-btn">
          <img src={Submit} alt="submit message" className="submit-image" />
        </button>
      </form>
    </>
  );
};

export default React.memo(Chatinput);
