import { useState, useEffect } from "react";
import "./ChangePassword.css";
import Header from "./../../components/Header";
import Input from "./../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import React from "react";
import {
  changePasswordReset,
  handleChangePassword,
  setChangePasswordError,
} from "../../actions/changePasswordActions";
import { Alert } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom"
import {REACT_APP_COMPLEX_PASSWORD} from "../RoomPage/constants";
import {getUserDetailsAction} from "../../actions/userActions";
import {getEnvironmentsAction} from "../../actions/environmentActions";
import {setComplexPasswordAction} from "../../actions/loginActions";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const environment = useSelector((state: RootState) => state.environmentsReducers.environments)
  const changePasswordErrors = useSelector(
    (state: RootState) => state.changePassword.error
  );
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [complexPassword, setComplexPassword] = useState(
    environment.REACT_APP_COMPLEX_PASSWORD ?
      environment.REACT_APP_COMPLEX_PASSWORD.status :
      REACT_APP_COMPLEX_PASSWORD
  );
  const [complexPasswordMessage, setComplexPasswordMessage] = useState({
    lowerCase: "",
    upperCase: "",
    numeric: "",
    specialChar: "",
    passwordLength: ""
  });


  function checkpassword(password:any) {
    let strength = 0
    let upperCaseMessage = ""
    let lowerCaseMessage = ""
    let numericMessage = ""
    let specialCharMessage = ""
    let passwordLengthMessage = ""
    if (password.match(/[a-z]+/)) {
      strength= strength+1
    } else {
      lowerCaseMessage = "Atleast one lowercase character required"
    }
    if (password.match(/[A-Z]+/)) {
      strength= strength+1
    } else {
      upperCaseMessage = "Atleast one uppercase character required"
    }
    if (password.match(/[0-9]+/)) {
      strength= strength+1
    } else {
      numericMessage = "Atleast one numeric required"
    }
    if (password.match(/[$@#&!]+/)) {
      strength= strength+1
    } else {
      specialCharMessage = "Atleast one special character required"
    }
    if (password.length < 8) {
      passwordLengthMessage = "Minimum length of password is 8 characters"
    } else {
      strength = strength+1
    }

    setPasswordStrength(strength)
    setComplexPasswordMessage({
      lowerCase: lowerCaseMessage,
      upperCase: upperCaseMessage,
      numeric: numericMessage,
      specialChar: specialCharMessage,
      passwordLength: passwordLengthMessage
    });

  }

  const isPasswordChanged = useSelector(
    (state: RootState) => state.changePassword.isPasswordChanged
  );

  const search = useLocation().search;
  const [expires, setExpires] = useState(new URLSearchParams(search).get("expires"));

  useEffect(() => {
    dispatch(getEnvironmentsAction());
  },[dispatch])

  useEffect(() => {
    if (isPasswordChanged) {
      dispatch(getUserDetailsAction());
      setExpires("");
      dispatch(changePasswordReset());
      setTimeout(() => {
        navigate("/");
      }, 3000)
    }
  }, [isPasswordChanged, navigate,dispatch]);


  useEffect(() => {
    if(environment.REACT_APP_COMPLEX_PASSWORD) {
      setComplexPassword(environment.REACT_APP_COMPLEX_PASSWORD.status)
    }
  },[environment])

  const [showMsg, setShowMsg] = useState(false);

  const [pword, setPword] = useState("");
  const handlePword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPword(e.target.value);

    console.log(complexPassword);
    if(complexPassword) checkpassword(e.target.value);
  };
  const [cpword, setCpword] = useState("");
  const handleCpword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCpword(e.target.value);
  };

  const username = useSelector(
    (state: RootState) => state.userDetails.userName
  );
  const handleRegister = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (pword !== cpword) {
      dispatch(setChangePasswordError("Both passwords do not match"));
    } else if (pword === "") {
      dispatch(setChangePasswordError("Password cannot be blank"));
    } else if (complexPassword && passwordStrength*20 < 100) {
      dispatch(setChangePasswordError("Please enter a complex password"));
    } else {
      dispatch(handleChangePassword(username, pword));
      setShowMsg(true);
      setCpword("");
      setPword("");
      // dispatch(getEnvironmentsAction());
      // dispatch(getUserDetailsAction());
      dispatch(setComplexPasswordAction(true));
    }
  };

  return (
    <>
      <Header />
      <section className="add_user">
        <div className="container">
          <div className="flex">
            <div className="flex-1">
              {expires && (
                <Alert variant="danger" className="red">Your password has been expired please update your password</Alert>
              )}
              {showMsg && changePasswordErrors === "" && (
                <Alert variant="success" className="green">
                  Your password updated successfully. You will be redirected to dashboard in 3 Seconds
                </Alert>
              )}
              <form className="add_form" onSubmit={handleRegister}>
                <h1>
                  <span>Change Password</span>
                </h1>
                <p>Password * &nbsp;
                  {
                    complexPassword ?
                      <progress className={"prog" + passwordStrength * 20} max="100" value={passwordStrength * 20} id="meter"></progress>
                      :
                      ""
                  }
                </p>
                <Input
                  type="password"
                  name="password-field"
                  placeholder="Password"
                  value={pword}
                  onChange={handlePword}
                />
                {changePasswordErrors && (
                  <Alert variant="danger" className="red">{changePasswordErrors}</Alert>
                )}
                {complexPasswordMessage.lowerCase ? <p className="warning-alert">{complexPasswordMessage.lowerCase}</p> : ""}
                {complexPasswordMessage.upperCase ? <p className="warning-alert">{complexPasswordMessage.upperCase}</p> : ""}
                {complexPasswordMessage.numeric ? <p className="warning-alert">{complexPasswordMessage.numeric}</p> : ""}
                {complexPasswordMessage.specialChar ? <p className="warning-alert">{complexPasswordMessage.specialChar}</p> : ""}
                {complexPasswordMessage.passwordLength ? <p className="warning-alert">{complexPasswordMessage.passwordLength}</p> : ""}
                <p>Confirm Password *</p>
                <Input
                  type="password"
                  name="confirm-password-field"
                  placeholder="Confirm Password"
                  value={cpword}
                  onChange={handleCpword}
                />

                <button className="link marg-up-10" value="register">
                  Change Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;