import { Dispatch } from "react";
import { SET_RECONNECTION_STATUS } from "./actionTypes";

export const setReconnectionStatus = (status: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_RECONNECTION_STATUS,
      payload: { status },
    });
  };
};
