import { getActivityChannelUrl, getToken } from "../utils/Token";
import axios from "axios";
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: getToken(),
  },
};


export const getSubscribedTopics = async (username: string) => {
  config["headers"]["Authorization"] = getToken();
  const reqBody = {
    username,
  };

  // Repeative code written intentionally. Do not remove
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
    baseURL: activityChannelBaseUrl+"/TrainingRoom",
    withCredentials: true,
  });

  const apiResponse = await requestActivityChannel.post(
    "/getSubscribedTopics",
    reqBody,
    config
  );
  return apiResponse.data;
};

export const getAllTopics = async () => {
  config["headers"]["Authorization"] = getToken();

  // Repeative code written intentionally. Do not remove
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
    baseURL: activityChannelBaseUrl+"/TrainingRoom",
    withCredentials: true,
  });

  const apiResponse = await requestActivityChannel.get("/getTopics", config);
  return apiResponse.data;
};

export const subscribeTopic = async (username: string, topicId: any) => {
  config["headers"]["Authorization"] = getToken();
  const reqBody = {
    username,
    topicId,
  };

  // Repeative code written intentionally. Do not remove
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
    baseURL: activityChannelBaseUrl+"/TrainingRoom",
    withCredentials: true,
  });

  const apiResponse = await requestActivityChannel.post(
    "/subscribe",
    reqBody,
    config
  );
  return apiResponse.data;
};

export const unsubscribeTopic = async (username: string, topicId: any) => {
  config["headers"]["Authorization"] = getToken();
  const reqBody = {
    username,
    topicId,
  };

  // Repeative code written intentionally. Do not remove
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
    baseURL: activityChannelBaseUrl+"/TrainingRoom",
    withCredentials: true,
  });

  const apiResponse = await requestActivityChannel.post(
    "/unsubscribe",
    reqBody,
    config
  );
  return apiResponse.data;
};

export const getAllFeeds = async (topicIds: string[], pageNo: Number, myposts: boolean) => {
  config["headers"]["Authorization"] = getToken();

  // Repeative code written intentionally. Do not remove
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
    baseURL: activityChannelBaseUrl+"/TrainingRoom",
    withCredentials: true,
  });

  const apiResponse = await requestActivityChannel.post(
    "/getFeeds",
    { topicIds, pageNo, myposts },
    config
  );
  return apiResponse.data;
};

export const getAllTopicsExtended = async (username: string) => {
  config["headers"]["Authorization"] = getToken();
  const reqBody = {
    username,
  };

  // Repeative code written intentionally. Do not remove
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
    baseURL: activityChannelBaseUrl+"/TrainingRoom",
    withCredentials: true,
  });

  const apiResponse = await requestActivityChannel.post(
    "/getAllTopicsExtended",
    reqBody,
    config
  );
  return apiResponse.data;
};

export const editFeed = async (feedId: string,topicId:string,title:string,description:string, type:string) => {
  config['headers']['Authorization'] = getToken();
    let body = {
      id: feedId,
      topicId,
      title,
      description,
      type
    };

    // Repeative code written intentionally. Do not remove
    const activityChannelBaseUrl = getActivityChannelUrl();
    const requestActivityChannel = axios.create({
      baseURL: activityChannelBaseUrl+"/TrainingRoom",
      withCredentials: true,
    });

    const feedResponse = await requestActivityChannel.post("/updateFeed", body, config);
    return feedResponse.data;
};

export const getConnectionDetailsForActivityChannel = async (username: string) => {
  config["headers"]["Authorization"] = getToken();
  const body = {
    username,
  };

    // Repeative code written intentionally. Do not remove
    // because of localstorage
    const activityChannelBaseUrl = getActivityChannelUrl();
    const requestActivityChannel = axios.create({
      baseURL: activityChannelBaseUrl+"/TrainingRoom",
      withCredentials: true,
    });

  const connectionDetailsData = await requestActivityChannel.post(
    "/getConnectionDetailsforActivityChannel",
    body,
    config
  );

  return connectionDetailsData?.data?.connectionDetails;
};