import { useEffect } from "react";
import "./launchApp.css";
import { IConnectionDetails } from "../RoomPage/interfaces";
import { getConnectionDetails } from "../../services/rooms";
import { useParams } from "react-router-dom";

const LaunchApp = (props: any) => {
  const {roomname} = useParams();
  console.log(roomname);
  const openElectronApp = async () => {
    const connectionDetails: IConnectionDetails = await getConnectionDetails(roomname);

    let hostname = window.location.origin;

    if (hostname.includes("http://localhost")) {
      hostname = "http://localhost:8080";
    }

    if (hostname.includes("https://localhost")) {
      hostname = "https://localhost:8080";
    }

    let inProtocol =
      "electron-fiddle://" +
      hostname +
      "/" +
      connectionDetails.path +
      "/" +
      connectionDetails.iv +
      "/" +
      connectionDetails.digest +
      "/" +
      connectionDetails.payload;

    window.location.href = inProtocol; //Launch alternative, typically app download.
  };

  const downloadScreenControlApp = () => {
    window.location.href =
      "https://collaborationcdn.nyc3.cdn.digitaloceanspaces.com/Remote/Collaborationroom-Setup-3.5.0.exe";
    setTimeout(() => window.close(), 1000);
  };

  useEffect(() => {
    openElectronApp();
     
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="Download_app_page">
        <div className="download_header">
          <h1>
            Welcome to{" "}
            <b style={{ textDecoration: "underline" }}>CollaborationRoom</b>
          </h1>
        </div>
        <div className="download_msg">
          <h4>Click On Download To Download app</h4>
          <h6>If you have alreday installed Please Click on close button</h6>
        </div>
        <div className="download_form">
          <button
            className="link"
            onClick={downloadScreenControlApp}
            title="Download App"
          >
            Download
          </button>

          <button
            className="link"
            onClick={() => openElectronApp}
            title="Open app"
          >
            Launch App
          </button>
        </div>
      </div>
    </>
  );
};

export default LaunchApp;
