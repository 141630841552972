import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import { getAllSupervisorForManager } from "../services/getSupervisor";
import {
  GET_SUPERVISOR_MANAGER_FAILURE,
  GET_SUPERVISOR_MANAGER_SUCCESS,
} from "./actionTypes";

const setSupervisorDetails = (userData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_SUPERVISOR_MANAGER_SUCCESS,
      payload: userData,
    });
  };
};

const setError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_SUPERVISOR_MANAGER_FAILURE,
      payload: errorMessage,
    });
  };
};

export const getSupervisorActions = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const userData = await getAllSupervisorForManager();
      if (userData) {
        dispatch(setSupervisorDetails(userData));
      } else {
        dispatch(setError("Could not fetch supervisor details. Please try again"));
      }
      return userData;
    } catch (error: any) {
      setError(error?.message || "Could not fetch supervisors. Please try again");
    } finally {
      setLoading(false);
    }
  };
};

