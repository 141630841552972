import { useSelector, useDispatch } from "react-redux";
import RemotePeer from "../../Pages/RoomPage/RemotePeer";
import { RootState } from "../../redux";
import "./participants.css";
import NameAvatar from "./../NameAvatar";
import AudioVolumeMeter from "./../AudioVolumeMeter";
import { Button, Dropdown } from "react-bootstrap";

import SocketService from "../../services/socket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faHandPaper,
  faPhone,
  faMobileAlt,
  faSmile,
  faDeaf,
  faThumbsUp,
  faThumbsDown,
  faSignLanguage,
  faBell,
  faPizzaSlice,
  faStopwatch20,
  faCogs,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { checkForSupManAdmin } from "../../utils/checkForSupAdmin";
import { setorRemoveTempRoomUser } from "../../actions/tempRoomUser";
import SignalStrengthIndicator from "../SignalIndicator";

import {
  AGENT_TO_AGENT_PRIVATE_CALL,
  SUPERVISOR_CAN_OBSERVE,
} from "../../Pages/RoomPage/constants";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAgentToAgentStatus } from "../../services/adminSettings";
import { getFormattedMinutes } from "../../utils/getMinutesDifferenceFromTime";
import { toast } from "react-toastify";
import { socketConnectiontype } from "../../utils/constant";
interface breakPart {
  peerId: string;
  username: string;
}

interface IParticipantsProps {
  peers: RemotePeer[];
  handRaisedPeer: any;
  performRightShiftByOne: (id: string) => void;
  makePrivateCall: (id: any) => void;
  roomname: string;
  isPrivateCallOn: boolean;
  openentCallerPerson: string;
  isCallModal: boolean;
  localAudioPaused: boolean;
  objectDetect: string;
  breakParticipant: Array<breakPart>;
  handleShow: any;
  show: boolean;
  openentObserveeAudioId: string;
  audioAllowedInRoom: boolean | undefined;
  isScreenAudioShared: boolean | undefined;
  presenter: string;
  allowPrivateCallinSilentRoom: boolean | undefined;
  handleHandraise:(peerSessionId:string) => void;
}

const Participants: React.FC<IParticipantsProps> = (
  props: IParticipantsProps
) => {
  const socketInstance = new SocketService(
    socketConnectiontype.media
  ).getInstance(socketConnectiontype.media);

  let { peers = [] } = props;



  const firstName = useSelector(
    (state: RootState) => state.userDetails.firstName
  );

  const environments = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  );




  const privateCallPermission = environments.AGENT_TO_AGENT_PRIVATE_CALL
    ? environments.AGENT_TO_AGENT_PRIVATE_CALL.status
    : AGENT_TO_AGENT_PRIVATE_CALL;

  const supervisorCanObserve = environments.SUPERVISOR_CAN_OBSERVE
    ? environments.SUPERVISOR_CAN_OBSERVE.status
    : SUPERVISOR_CAN_OBSERVE;

  const lastName = useSelector(
    (state: RootState) => state.userDetails.lastName
  );
  const myRole = useSelector((state: RootState) => state.userDetails.role);

  const socketId = useSelector(
    (state: RootState) => state.userDetails.socketId
  );

  const room = useSelector((state:RootState) => state.roomsData)

  const dispatch = useDispatch();



  const myFullName = `${firstName} ${lastName}`;



  // if (
  //   props.handRaisedPeer &&
  //   props.handRaisedPeer.peerSessionId &&
  //   props.peers
  // ) {
  //   const opponentPeer = props.peers.filter(
  //     (peer: RemotePeer) =>
  //       peer.peerSessionId !== props.handRaisedPeer.peerSessionId
  //   );
  //   peers = [props.handRaisedPeer, ...opponentPeer];
  // }



  const handleRemoveParticipant = (
    peerSessionId: string,
    peerUsername: string,
    peerName: string
  ) => {
    socketInstance.sendMessage("REMOVE_USER", {
      roomname: props.roomname,
      peerSessionId: peerSessionId,
      peerUsername: peerUsername,
      peerName,
    });
  };

  const handlePromoteUser = (peerSessionId: string) => {
    socketInstance.sendMessage("PROMOTE_USER", {
      roomname: props.roomname,
      peerSessionId: peerSessionId,
    });
  };

  const handleStopObserveAgent = (
    openentObserveeAudioId: string,
    peerSessionId: string,
    openentObserverAudioId?: string
  ) => {
    if (
      (openentObserveeAudioId !== "" &&
        openentObserveeAudioId !== peerSessionId) ||
      (openentObserverAudioId !== "" && openentObserveeAudioId === "")
    ) {
      console.log("inside stop");
      return;
    }

    if (openentObserveeAudioId === peerSessionId) {
      socketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
        roomname: props.roomname,
        peerSessionId: peerSessionId,
        from: "stop",
      });
    } else {
      socketInstance.sendMessage("OBSERVE_AUDIO_AGENT", {
        roomname: props.roomname,
        peerSessionId: peerSessionId,
      });
    }
  };

  const addPeerIdForTempRoom = (
    peerSessionId: string,
    peerUsername: string
  ) => {
    dispatch(
      setorRemoveTempRoomUser(
        peerSessionId,
        peerUsername,
        props.breakParticipant
      )
    );

    // let existParticipant = props.breakParticipant.find(
    //   (val: breakPart) => val.peerId === peerSessionId
    // );

    // if (existParticipant) {
    //   props.setBreakParticipant((value: any) => {
    //     return value.filter((val: breakPart) => val.peerId !== peerSessionId);
    //   });
    // } else {
    //   props.setBreakParticipant([
    //     ...props.breakParticipant,
    //     { peerId: peerSessionId, username: peerUsername },
    //   ]);
    // }
  };

  const muteAll = () => {
    socketInstance.sendMessage("MUTE_ALL_PARTICIPANT", {
      roomname: props.roomname,
    });
  };

  const location = useLocation();

  useEffect(() => {
    agentStatus();
  }, [location]);

  async function agentStatus() {
    try {
      const statusData = await getAgentToAgentStatus();
      if (statusData) {
        localStorage.setItem("agentstatus", statusData.adminsettings.status);
      }
    } catch (error) {
      console.log("Error fetching Agent to Agent status: ", error);
    }
  }

  return (
    <ul style={{ position: "relative", height: "98%", overflowY: "auto" }}>
      {peers.length > 0 &&
        !props.isPrivateCallOn &&
        myRole.toLowerCase() !== "agent" ? (
        <li className="participant-item mute_all">
          <button
            className="link"
            type="submit"
            title="Mute All"
            onClick={() => {
              muteAll();
            }}
          >
            Mute All
          </button>
        </li>
      ) : null}
      {peers.map((peer: RemotePeer, index) => {
        return peer.isPrivateCallOn &&
          props.isPrivateCallOn &&
          props.openentCallerPerson === peer.peerSessionId ? (
          <li className="show_private_call" key={peer.peerSessionId}>
            <div
              className="name-role"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="participant-name">
                {" "}
                You are in private call with {peer.peerName}
              </div>
              <div
                className="participant-role end-private-call"
                title="end call"
                style={{ justifyContent: "center" }}
                onClick={() => {
                  props.makePrivateCall(peer.peerSessionId);
                }}
              >
                <FontAwesomeIcon icon={faPhone} style={{ fontSize: "1rem" }} />
              </div>
            </div>
          </li>
        ) : null;
      })}
      <li className="participant-item">
        <div className="pravatar bg-brand color-white">
          <NameAvatar text={myFullName} />
        </div>
        <div className="name-role">
          <div
            className="participant-name"
            title={myFullName}
            style={{ fontSize: "1vw" }}
          >
            {myFullName.length > 8
              ? myFullName.slice(0, 9) + ".."
              : myFullName}
            {checkForSupManAdmin(myRole, "equalTo") &&
              props.presenter !== socketId ? (
              <div className="dropdown-container">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="menu-dropdown"
                  ></Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-inner-container">
                    <Dropdown.Item
                      onClick={() => {
                        handlePromoteUser(socketId);
                      }}
                    >
                      Promote User
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : null}
          </div>
          <div className="participant-role">
            {myRole}
            {!props.localAudioPaused &&
              (props.audioAllowedInRoom ||
                checkForSupManAdmin(myRole, "equalTo")) ? (
              <AudioVolumeMeter id={socketId} view="row" />
            ) : null}
          </div>
        </div>
      </li>
      {peers.map((peer: RemotePeer, index) => {
        return (
          <div
            className="participant-item"
            style={{
              border: props.breakParticipant.find(
                (val: breakPart) => val.peerId === peer.peerSessionId
              )
                ? "2px Solid rgb(38, 163, 219)"
                : "none",
            }}
            key={peer.peerSessionId}
            onClick={(e) => {
              e.preventDefault();
              socketInstance.sendMessage("CHECK_CONSUMER", {
                peerSessionId: peer.peerSessionId,
              });

              if ((checkForSupManAdmin(myRole, "equalTo") && e.ctrlKey) || (checkForSupManAdmin(myRole, "equalTo") && e.metaKey)) {
                addPeerIdForTempRoom(peer.peerSessionId, peer.peerUsername);
              }
            }}
          >
            <div
              onClick={() =>
                checkForSupManAdmin(myRole, "equalTo") &&
                props.performRightShiftByOne(peer.peerSessionId)
              }
              className="pravatar bg-brand color-white"
            >
              <NameAvatar text={peer.peerName} />
            </div>
            <div
              onClick={() =>
                checkForSupManAdmin(myRole, "equalTo") &&
                props.performRightShiftByOne(peer.peerSessionId)
              }
              className="name-role"
            >
              {/* : "participant-name list-" + peer.expression */}
              <div
                className={!peer.faceVerificationSuccess && peer.isNotSamePerson && peer.showAlertInParticipant ? "participant-name list-facefalse" : "participant-name list-" + peer.expression}
                style={{ width: "100%" }}
              >
                <div
                  style={{ width: "40%", fontSize: "1vw" }}
                  title={peer.peerName}
                >
                  {peer.peerName.length > 8
                    ? peer.peerName.slice(0, 9) + ".."
                    : peer.peerName}
                  {/* {
                    peer.expression === 'angry' ? '😠' : peer.expression === 'happy' ? '😊' : peer.expression === 'neutral' ? '😐' : peer.expression === 'sad' ? '😢' : peer.expression === 'surprise' ? '😮' : ''
                  } */}

                </div>

                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {peer.emojiForHandAndLunchPeer === "BREAK" && (
                    <>
                      <FontAwesomeIcon icon={faBell} color="red" />
                      {checkForSupManAdmin(myRole, "equalTo") && (
                        <span className="time-span">
                          {
                            // @ts-ignore
                            getFormattedMinutes(peer.time, 2, " Hour ")
                          }
                        </span>
                      )}
                    </>
                  )}

                  {peer.emojiForHandAndLunchPeer === "LUNCH" && (
                    <>
                      <FontAwesomeIcon icon={faPizzaSlice} color="red" />
                      {checkForSupManAdmin(myRole, "equalTo") && (
                        <span className="time-span">
                          {
                            // @ts-ignore
                            getFormattedMinutes(peer.time, 2, " Hour ")
                          }
                        </span>
                      )}
                    </>
                  )}

                  {peer.emojiForIconPeer === "TECHNICALISSUE" && (
                    <FontAwesomeIcon icon={faCogs} color="red" />
                  )}

                  {peer.isPrivateCallOn &&
                    localStorage.getItem("agentstatus") === "true" && (
                      <li title={"In Private Call"}>
                        <FontAwesomeIcon icon={faCircle} color="red" />
                      </li>
                    )}

                  {/* {checkForSupManAdmin(myRole, "equalTo") ? ( */}

                  {
                    peer.emojiForHandAndLunchPeer === "HANDRAISED" && (
                      // <div>
                      <FontAwesomeIcon icon={faHandPaper} color="red" />
                    )
                    // </div>
                  }
                  {
                    peer.emojiForIconPeer === "CLAP" && (
                      // <div>
                      <FontAwesomeIcon icon={faSignLanguage} color="red" />
                    )
                    // </div>
                  }
                  {
                    peer.emojiForIconPeer === "SMILE" && (
                      // <div>
                      <FontAwesomeIcon icon={faSmile} color="red" />
                    )
                    // </div>
                  }

                  {
                    peer.emojiForIconPeer === "YES" && (
                      // <div>
                      <FontAwesomeIcon icon={faThumbsUp} color="red" />
                    )
                    // </div>
                  }

                  {peer.isPrivateCallOn &&
                    localStorage.getItem("agentstatus") === "false" && (
                      <li title={"In Private Call"}>
                        <FontAwesomeIcon icon={faCircle} color="red" />
                      </li>
                    )}

                  {
                    peer.emojiForIconPeer === "NO" && (
                      // <div>
                      <FontAwesomeIcon icon={faThumbsDown} color="red" />
                    )
                    // </div>
                  }

                  {
                    peer.emojiForIconPeer === "CANNOTHEAR" && (
                      // <div>
                      <FontAwesomeIcon icon={faDeaf} color="red" />
                    )
                    // </div>
                  }

                  {
                    peer.emojiForIconPeer === "BERIGHTBACK" && (
                      <>
                        <FontAwesomeIcon icon={faStopwatch20} color="red" />
                        {checkForSupManAdmin(myRole, "equalTo") && (
                          <span className="time-span">
                            {
                              // @ts-ignore
                              getFormattedMinutes(peer.time, 2, " Hour ")
                            }
                          </span>
                        )}
                      </>
                    )
                    // </div>
                  }

                  {
                    peer.networkStrength !== "" && (
                      // <div>
                      <SignalStrengthIndicator
                        strength={peer.networkStrength}
                      />
                    )
                    // </div>
                  }
                </div>
                {/* ) : null} */}

                {checkForSupManAdmin(myRole, "equalTo") && (
                  <div className="dropdown-container" style={{ width: "7%" }}>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="menu-dropdown"
                      ></Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-inner-container">
                        <Dropdown.Item
                          onClick={() => {
                            handleRemoveParticipant(
                              peer.peerSessionId,
                              peer.peerUsername,
                              peer.peerName
                            );
                          }}
                        >
                          Remove Participant
                        </Dropdown.Item>
                        {myRole.toLowerCase() !== "Agent" &&
                          props.presenter !== peer.peerSessionId ? (
                          <Dropdown.Item
                            onClick={() => {
                              handlePromoteUser(peer.peerSessionId);
                            }}
                          >
                            Promote User
                          </Dropdown.Item>
                        ) : null}
                        {peer.emojiForIconPeer !== "BERIGHTBACK" && peer.emojiForHandAndLunchPeer !== "BREAK" && peer.emojiForHandAndLunchPeer !== "LUNCH" &&
                          !props.isPrivateCallOn &&
                          !props.isCallModal &&
                          (
                            (props.audioAllowedInRoom || props.audioAllowedInRoom === undefined) ||
                            (!props.audioAllowedInRoom && props.allowPrivateCallinSilentRoom)
                          ) ? (
                          <Dropdown.Item
                            onClick={() => {
                              if (
                                (peer.isPrivateCallOn &&
                                  props.isPrivateCallOn &&
                                  props.openentCallerPerson === peer.peerSessionId) ||
                                (!peer.isPrivateCallOn && !props.isPrivateCallOn)
                              ) {
                                if (props.isScreenAudioShared) {
                                  toast(
                                    "You are sharing your Screen with Audio. Please Stop screen or share screen without audio to make private call"
                                  );
                                  return;
                                }
                                if (!peer.isPrivateCallOn && !peer.isCallModalOn)
                                  props.makePrivateCall(peer.peerSessionId);
                              }
                            }}
                          >
                            {props.isPrivateCallOn &&
                              peer.isPrivateCallOn &&
                              props.openentCallerPerson === peer.peerSessionId
                              ? "Stop Private Call"
                              : props.isPrivateCallOn && !peer.isPrivateCallOn
                                ? "Your private call on"
                                : peer.isPrivateCallOn ||
                                  (!peer.isPrivateCallOn && peer.isCallModalOn)
                                  ? "Already in Call"
                                  : "Private Call"}
                          </Dropdown.Item>
                        ) : null
                        }

                        {!props.isPrivateCallOn &&
                          !props.isCallModal &&
                          peer.peerAudioPaused &&
                          !peer.isCallModalOn &&
                          !peer.isPrivateCallOn &&
                          peer.peerType === "Agent" &&
                          supervisorCanObserve ? (
                          <Dropdown.Item
                            onClick={(e: any) => {
                              e.preventDefault();
                              handleStopObserveAgent(
                                props.openentObserveeAudioId,
                                peer.peerSessionId,
                                peer.openentObserverAudioId
                              );
                            }}
                          >
                            {props.openentObserveeAudioId === peer.peerSessionId
                              ? "Stop Objserving"
                              : props.openentObserveeAudioId !== "" &&
                                props.openentObserveeAudioId !==
                                peer.peerSessionId
                                ? "Can't Observe"
                                : peer.openentObserverAudioId !== ""
                                  ? "Already Observed"
                                  : "Observe"}
                          </Dropdown.Item>
                        ) : null}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}

                {myRole === "Agent" && (
                  <div className="dropdown-container">
                    {!props.isPrivateCallOn &&
                      !props.isCallModal &&
                      (privateCallPermission || peer.peerType !== "Agent") &&
                      (
                        (props.audioAllowedInRoom || props.audioAllowedInRoom === undefined) ||
                        (!props.audioAllowedInRoom && props.allowPrivateCallinSilentRoom)
                      ) ? (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="menu-dropdown"
                        ></Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-inner-container">
                          <Dropdown.Item
                            onClick={() => {
                              if (
                                (peer.isPrivateCallOn &&
                                  props.isPrivateCallOn &&
                                  props.openentCallerPerson ===
                                  peer.peerSessionId) ||
                                (!peer.isPrivateCallOn &&
                                  !props.isPrivateCallOn)
                              ) {
                                // if (
                                //   peer.screenAudioStream &&
                                //   peer.peerScreenStream
                                // ) {
                                //   toast(
                                //     "Can't do private call because user is sharing system audio"
                                //   );
                                //   return;
                                // }
                                if (
                                  !peer.isPrivateCallOn &&
                                  !peer.isCallModalOn
                                )
                                  props.makePrivateCall(peer.peerSessionId);
                              }
                            }}
                          >
                            {props.isPrivateCallOn &&
                              peer.isPrivateCallOn &&
                              props.openentCallerPerson === peer.peerSessionId
                              ? "Stop Private Call"
                              : props.isPrivateCallOn && !peer.isPrivateCallOn
                                ? "Your private call on"
                                : peer.isPrivateCallOn ||
                                  (!peer.isPrivateCallOn && peer.isCallModalOn)
                                  ? "Already in Call"
                                  : "Private Call"}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : null}
                  </div>
                )}
              </div>
              <div className="participant-role">
                {peer.peerType}
                {!peer.peerAudioPaused &&
                  checkForSupManAdmin(myRole, "notEqualTo") ? (
                  <AudioVolumeMeter id={peer.peerSessionId} view="row" />
                ) : null}
                {checkForSupManAdmin(myRole, "equalTo") ? (
                  <div style={{ display: "flex" }}>
                    {peer.objectDetect === "cell-phone" && (
                      <FontAwesomeIcon
                        icon={faMobileAlt}
                        color="red"
                        style={{ margin: "4px 5px" }}
                      />
                    )}
                    {!peer.peerAudioPaused ? (
                      <AudioVolumeMeter id={peer.peerSessionId} view="row" />
                    ) : null}
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{ margin: "4px 5px" }}
                    />{" "}
                    {peer.numfaces}

                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
      {
        ( checkForSupManAdmin(myRole, "equalTo") &&
          props.breakParticipant.length > 0 &&
          !props.show) ? (
        <div className="btn_room_temp_container">
          <Button
            className="pill btn_for_temp_room"
            onClick={() => {
              props.handleShow(true);
            }}
          >
            Move Participants
          </Button>
        </div>
      ) : null}
    </ul>
  );
};

export default Participants;
