import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearControlId } from "../../actions/screenControlAction";
import SocketService from "../../services/socket";
import Video from "../Video";
import {
  mediaTypeConstant,
  resolutionConstant,
  socketConnectiontype,
} from "../../utils/constant";
import { RootState } from "../../redux";

const socketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);
let clearTime: any;
let scrollVal = { x: 0, y: 0 };

const ScreenControl = (props: any) => {
  let screenDiv = useRef({} as HTMLDivElement);
  const currentUserName = useSelector(
    (state: RootState) => state.userDetails.userName
  );
  const openentPeerId = useSelector(
    (state: RootState) => state.screenControlReducer.openentPeerId
  );
  const dispatch = useDispatch();

  const submitButton = () => {
    socketInstance.sendMessage("CONTROL_DISCONNECT_BY_SUPERVISORUSER", {
      openentPeerUsername: props.name,
    });
    dispatch(clearControlId());
  };

  const gettingMousePosition = (e: React.MouseEvent) => {
    // @ts-ignore
    let modalPosX = Math.ceil(document.querySelector("#video_screen_control").getBoundingClientRect().left
    );
    // @ts-ignore
    let modalPosY = Math.ceil(document.querySelector("#video_screen_control").getBoundingClientRect().top
    );
    let x = e.pageX - modalPosX + 1;
    let y = e.pageY - modalPosY + 2;

    const perY = ((y * 100) / screenDiv.current.clientHeight).toFixed(2);
    const perX = ((x * 100) / screenDiv.current.clientWidth).toFixed(2);

    return { perX, perY };
  };

  const mouseMove = (e: React.MouseEvent) => {
    const getPosInPercentage = gettingMousePosition(e);

    socketInstance.sendMessage("CONTROL_ACTION", {
      type: "mouse-move",
      data: { x: getPosInPercentage.perX, y: getPosInPercentage.perY },
    });
  };

  const leftClick = (e: React.MouseEvent) => {
    e.preventDefault();
    socketInstance.sendMessage("CONTROL_ACTION", {
      type: "mouse-left-click",
      data: {},
    });
  };

  const type = (e: React.KeyboardEvent) => {
    socketInstance.sendMessage("CONTROL_ACTION", {
      type: "keyboard-press",
      data: { key: e.key.toLowerCase() },
    });
  };

  const mouseScroll = (e: React.WheelEvent) => {
    scrollVal = { x: scrollVal.x + e.deltaX, y: scrollVal.y + e.deltaY };
    clearTimeout(clearTime);
    clearTime = setTimeout(
      () =>
        socketInstance.sendMessage("CONTROL_ACTION", {
          type: "mouse-scroll",
          data: { x: scrollVal.x, y: scrollVal.y },
        }),
      300
    );
  };
  const rightClick = (e: React.MouseEvent) => {
    e.preventDefault();
    socketInstance.sendMessage("CONTROL_ACTION", {
      type: "mouse-right-click",
      data: {},
    });
  };

  useEffect(() => {
    // @ts-ignore
    document.addEventListener("keyup", type);

    socketInstance.sendMessage(
      "SETTING_SCALE_RESOLUTION_DOWN_FOR_SELECTED_MEDIA_TYPE",
      {
        peerSessionId: openentPeerId,
        resolution: resolutionConstant.high,
        mediaType: mediaTypeConstant.screen,
        currentUserName,
        screenControl: true,
      }
    );
    // Remove event listeners on cleanup
    return () => {
      // @ts-ignore
      document.removeEventListener("keyup", type);
      socketInstance.sendMessage(
        "SETTING_SCALE_RESOLUTION_DOWN_FOR_SELECTED_MEDIA_TYPE",
        {
          peerSessionId: openentPeerId,
          resolution: resolutionConstant.low,
          mediaType: mediaTypeConstant.screen,
          currentUserName,
          screenControl: true,
        }
      );
    };
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @ts-ignore
  // @ts-ignore
  return (
    <Modal show={true} className="screen_modal_container">
      <Modal.Body
      // onKeyUp={(e: React.KeyboardEvent) => type(e)}
      >
        <div
          className="feedback_modal_main screen_main"
          style={{ minHeight: "300px" }}
        >
          <div className="video-audio-container">
            <div
              ref={screenDiv}
              id="video_screen_control"
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
              }}
              onMouseMove={(e: React.MouseEvent) => {
                mouseMove(e);
              }}
              onClick={(e: React.MouseEvent) => {
                leftClick(e);
              }}
              onContextMenu={(e: React.MouseEvent) => {
                rightClick(e);
              }}
              onWheel={(e: React.WheelEvent) => mouseScroll(e)}
            >
              <Video
                id={props.id}
                name={props.name}
                srcObject={props.largeVideo}
                className={`large-video white-border`}
                screenControl={true}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              margin: "2px",
            }}
            className="modal_link"
          >
            <button
              className="link"
              onClick={(e) => {
                e.stopPropagation();
                submitButton();
              }}
            >
              Disconnect
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ScreenControl;
