import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../Pages/Login";
import Dashboard from "../Pages/Dashboard";
import RoomPage from "../Pages/RoomPage/RoomPage";
import "react-toastify/dist/ReactToastify.css";
import ChangePassword from "../Pages/ChangePassword";
import { useDispatch } from "react-redux";
import Container from "../components/whiteboard/container/Container";
import { handleLogin } from "../actions/loginActions";
import PrivateRoute from "../components/privateroute/PrivateRoute";
import LaunchApp from "../Pages/DownloadApp/launchApp";
import DownloadApp from "../Pages/DownloadApp/downloadApp";
import AutoLogin from "../Pages/AutoLogin/autoLogin";
import PasswordReset from "../Pages/PasswordReset";
import FaceRegistration from '../components/FaceRegistration'
import FaceVerification from '../components/FaceVerification'

function App() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(handleLogin());
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace={true} />} />
      <Route path="/login" element={<Login />} />

      <Route path="/autologin/:username" element={<AutoLogin />} />
      <Route path="/passwordreset/:resetPasswordToken" element={<PasswordReset />}/>
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path="/launchApp/:roomname"
        element={
          <PrivateRoute>
            <LaunchApp />
          </PrivateRoute>
        }
      />
      <Route
        path="/downloadApp/:roomname"
        element={
          <PrivateRoute>
            <DownloadApp />
          </PrivateRoute>
        }
      />

      
      {/* face_registration */}

      
      <Route
        path="/room/:roomname"
        element={
          <PrivateRoute>
            <RoomPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/face_registration"
        element={
          <PrivateRoute>
            <FaceRegistration />
          </PrivateRoute>
        }
      />
      <Route
        path="/face_verfication"
        element={
          <PrivateRoute>
            <FaceVerification />
          </PrivateRoute>
        }
      />
      <Route
        path="/change_password"
        element={
          <PrivateRoute>
            <ChangePassword />
          </PrivateRoute>
        }
      />
      
      <Route
        path="/room/whiteboard/:roomname"
        element={
          <PrivateRoute>
            <Container />
          </PrivateRoute>
        }
      />
      </Routes>
  );
}

export default App;