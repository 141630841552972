import RemotePeer from "./RemotePeer";
import Video from "./../../components/Video";
import NameAvatar from "./../../components/NameAvatar";
import Audio from "./../../components/Audio";
import ParticipantCard from "./../../components/ParticipantCard";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useState, useEffect } from "react";
import AudioVolumeMeter from "../../components/AudioVolumeMeter";
import useRenderingPipeline from "../../hooks/useRenderingPipeline";
import { SourcePlayback } from "../../helpers/sourceHelper";
import ScreenControl from "../../components/ScreenControl";
import { roomViewModeConst } from "../../utils/constant";
import { Dropdown } from "react-bootstrap";
import { getMultipleSupervisorsStatus } from "../../services/adminSettings";
import { checkForSupManAdmin } from "../../utils/checkForSupAdmin";

interface screenPeerType {
  peerId: string;
  peerUsername: string;
}

// @ts-ignore
const InstructorScreen = (props) => {
  const {
    firstInstructorScreen,
    firstInstructorVideo,
    secondInstructorScreen,
    pauseUnPauseVideo,
    localVideoPaused,
    pauseUnPauseAudio,
    localAudioPaused,
    getPagePeers,
    remotePeers,
    getPageNumbers,
    socketId,
    swapScreen,
    shareUnshareScreen,
    shareUnshareScreenTwo,
    currentPageNumber,
    changePage,
    screenStopped,
    screenTwoStopped,
    localAudioStream,
    localVideoStream,
    shouldShowControls,
    changeViewMode,
    makePrivateCall,
    signalStrength,
    amISuperVisor,
    roomname,
    isPrivateCallOn,
    sideBarOpen,
    presenter,
    instructor,
    getVideoOnScreen,
    addStreamOfAudioFile,
    addStreamOfVideoFile,
    localScreenStream,
    isCallModal,
    openentObserveeAudioId,
    screenResumeForCollabPeer,
    isScreenAudioShared,
    handleHandraise
  } = props;

  const firstName = useSelector(
    (state: RootState) => state.userDetails.firstName
  );

  const lastName = useSelector(
    (state: RootState) => state.userDetails.lastName
  );

  const openentPeerId = useSelector(
    (state: RootState) => state.screenControlReducer.openentPeerId
  );

  const verification = useSelector(
    (state: RootState) => state.verifyFaceReducer.faceVerification
  );
  const recurrentCheck = useSelector(
    (state: RootState) => state.verifyFaceReducer.recurrentCheck
  );
  const wrongPersonCheck = useSelector(
    (state: RootState) => state.verifyFaceReducer.wrongPerson
  );
  const viewMode = useSelector((state: RootState) => state.roomsData.viewMode);

  // console.log("DAFAULT", verification, recurrentCheck, wrongPersonCheck,firstName,lastName);
  // useEffect(() => {
  //   console.log("LOLO", { verification, recurrentCheck, wrongPersonCheck })
  // }, [verification, recurrentCheck, wrongPersonCheck])

  const currentUserName =
    firstName && lastName ? `${firstName} ${lastName}` : "";

  const currentUserRole = useSelector(
    (state: RootState) => state.userDetails.role
  );

  const isVideoFile = useSelector(
    (state: RootState) => state.audioVideoFileReducer.isVideoFile
  );

  const screenPeer = useSelector(
    (state: RootState) => state.collabReducer.screenCollabPeer
  );

  const screenPeerArray = useSelector(
    (state: RootState) => state.collabReducer.screenCollabPeerArray
  );

  const [multipleSupervisors, setMultipleSupervisors] = useState();

  useEffect(() => {
    multipleSupervisorsStatus();
  }, []);

  // useEffect(() => {
  //   console.log({ wrongPersonCheck, verification, recurrentCheck })
  //   // alert("Broooooooo!!")
  // }, [wrongPersonCheck, verification, recurrentCheck])

  const multipleSupervisorsStatus = async () => {
    try {
      const statusData = await getMultipleSupervisorsStatus();
      if (statusData) {
        setMultipleSupervisors(statusData.adminsettings.status);
      }
    } catch (error) {
      console.log("Error fetching Multiple Supervisors status: ", error);
    }
  };

  const noOp = () => {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [roomName, setRoomName] = useState("" as string);
  // const [isVideo, setIsVideo] = useState(true);
  const videoOption = useSelector(
    (state: RootState) => state.videoOption.videoOption
  );

  const [sourcePlayback, setSourcePlayback] = useState<SourcePlayback>();

  const handleSwapScreen = (Id: string) => {
    swapScreen(Id);
  };
  const pages = getPageNumbers();

  useEffect(() => {
    setRoomName(roomname);
  }, [roomname]);

  let getFlexWidthAndHeight = (peersLength: number, itemNo: number) => {
    let height;
    let width;

    if (peersLength === 7 && itemNo > 3) {
      width = 35;
      height = 32;
    } else if (
      (peersLength === 13 && itemNo > 4) ||
      (peersLength === 14 && itemNo > 8)
    ) {
      width = 28;
      height = 22;
    } else if (peersLength === 1) {
      width = 95;
      height = 100;
    } else if (peersLength === 2 || peersLength === 4) {
      width = 50;
      height = peersLength === 4 ? 47 : 96;
    } else if (peersLength === 3 || (peersLength >= 5 && peersLength <= 9)) {
      width = 33;
      height = peersLength === 3 ? 100 : peersLength <= 6 ? 47 : 32;
    } else if (peersLength <= 16 && peersLength > 9) {
      width = 23;
      height = peersLength <= 12 ? 30 : 22;
    }

    return { height, width };
  };

  const {
    // pipeline,
    // backgroundImageRef,
    canvasRef,
    canvasDisplayRef,
    // fps,
    // durations: [resizingDuration, inferenceDuration, postProcessingDuration],
  } = useRenderingPipeline(
    // @ts-ignore
    sourcePlayback,
    props.segmentationConfig,
    props.tflite,
    localVideoStream && !localVideoPaused
  );

  let videoClassName =
    videoOption === "blur" || videoOption === "background" ? "none" : "";

  return (
    <div
      className={sideBarOpen ? "instructor-container" : "instructor-containerb"}
    >
      <div className={`students-grid`}>
        {remotePeers.map((remotePeer: RemotePeer) => {
          return remotePeer.peerAudioStream ? (
            <Audio
              key={remotePeer.peerSessionId}
              srcObject={remotePeer.peerAudioStream}
            />
          ) : null;
        })}

        {remotePeers.map((remotePeer: RemotePeer) => {
          return remotePeer.peerAudioFileStream ? (
            <Audio
              key={remotePeer.peerSessionId}
              srcObject={remotePeer.peerAudioFileStream}
            />
          ) : null;
        })}

        {remotePeers.map((remotePeer: RemotePeer) => {
          return remotePeer.screenAudioStream ? (
            <Audio
              key={remotePeer.peerSessionId}
              srcObject={remotePeer.screenAudioStream}
            />
          ) : null;
        })}

        {screenPeer.peerId !== "" ? (
          <div className="collab_container">
            {screenPeerArray.length > 0 ? (
              <Dropdown>
                <Dropdown.Toggle variant="success" id="menu-dropdown">
                  {screenPeer.peerUsername} sharing screen
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-inner-container">
                  {screenPeerArray.map((peer: screenPeerType) => {
                    return (
                      <Dropdown.Item
                        key={peer.peerId + 1}
                        onClick={() => {
                          screenResumeForCollabPeer(peer);
                        }}
                      >
                        {peer.peerUsername}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            ) : null}

            {remotePeers.map((peer: RemotePeer) => (
              <>
                {peer.peerSessionId === screenPeer.peerId ? (
                  <div className="collab_Slides">
                    {peer.peerScreenStream ? (
                      <Video
                        id={peer.peerSessionId}
                        name={peer.peerUsername}
                        srcObject={peer.peerScreenStream}
                        className={`collab_screen`}
                        peer={peer}
                      />
                    ) : null}
                  </div>
                ) : null}
              </>
            ))}
            <div className="collab_column">
              {getPagePeers().map((peer: RemotePeer) => (
                <>
                  {peer.peerVideoStream && !peer.peerVideoPaused ? (
                    <div className="collab_video_container">
                      <div className={`collab_video`}>
                        <Video
                          id={peer.peerSessionId}
                          name={peer.peerUsername}
                          srcObject={peer.peerVideoStream}
                          peer={peer}
                        />

                        {!peer.peerAudioPaused ? (
                          <AudioVolumeMeter
                            id={peer.peerSessionId}
                            view="col"
                          />
                        ) : null}
                      </div>
                      <p className="collab_peer_name">{peer.peerUsername}</p>
                    </div>
                  ) : (
                    <div className="collab_video_container">
                      <div className={`collab_video`}>
                        <NameAvatar
                          text={peer.peerName}
                          className="collab_avatar"
                        />
                        {!peer.peerAudioPaused ? (
                          <AudioVolumeMeter
                            id={peer.peerSessionId}
                            view="col"
                          />
                        ) : null}
                      </div>
                      <p className="collab_peer_name">{peer.peerUsername}</p>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        ) : (
          <ul
            className={`student-grid-row student_wrp_3
          `}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              {checkForSupManAdmin(currentUserRole, "notEqualTo") &&
              props.presenterForAgent === socketId &&
              viewMode !== roomViewModeConst.collabMode ? (
                <p
                  className="presenter"
                  style={{ color: "red", width: "100%", margin: "0" }}
                >
                  <b>You are currently the presenter</b>
                </p>
              ) : instructor && viewMode !== roomViewModeConst.collabMode ? (
                <p className="presenter" style={{ width: "100%", margin: "0" }}>
                  {instructor.peerName} is the presenter
                </p>
              ) : presenter === socketId &&
                viewMode !== roomViewModeConst.collabMode ? (
                <p
                  className="presenter"
                  style={{ color: "red", width: "100%", margin: "0" }}
                >
                  <b>You are currently the presenter</b>
                </p>
              ) : null}
            </div>

            {/* eslint-disable-next-line array-callback-return */}
            {getPagePeers().map((peer: RemotePeer, index: number) => {
              // Show all Supervisors to Agents & All Participants to Supervisors
              // Handle meeting room flow as it is in multipleSupervisors Case & supervisor room flow (video display)
              // Handle Promote user display case in case of MultipleSupervisors
              if (
                (checkForSupManAdmin(peer.peerType, "equalTo") ||
                  checkForSupManAdmin(currentUserRole, "equalTo") ||
                  viewMode === "meeting" ||
                  presenter === peer.peerSessionId ||
                  (presenter === peer.peerSessionId &&
                    checkForSupManAdmin(peer.peerType, "notEqualTo"))) &&
                (multipleSupervisors ||
                  currentUserRole !== "Agent" ||
                  viewMode === "meeting")
              ) {
                let largeVideo = undefined,
                  smallVideo = undefined,
                  secondScreen = undefined;

                let flexObj = getFlexWidthAndHeight(
                  getPagePeers().length,
                  index + 1
                );

                // assumpation video => video,  second => first screen, third => second screen
                if (peer.isVideoSwapped === "") {
                  let allStream = getVideoOnScreen(peer);
                  largeVideo = allStream.largeVideo;
                  smallVideo = allStream.smallVideo;
                  secondScreen = allStream.secondScreen;
                } else if (peer.isVideoSwapped === "secondthird") {
                  largeVideo = peer.peerScreenStream;
                  secondScreen = peer.peerScreenTwoStream;
                  smallVideo = undefined;
                } else if (peer.isVideoSwapped === "thirdsecond") {
                  largeVideo = peer.peerScreenTwoStream;
                  secondScreen = peer.peerScreenStream;
                  smallVideo = undefined;
                } else if (peer.isVideoSwapped === "videosecond") {
                  largeVideo = peer.peerVideoStream;
                  smallVideo = peer.peerScreenStream;
                  secondScreen = undefined;
                } else if (peer.isVideoSwapped === "secondvideo") {
                  largeVideo = peer.peerScreenStream;
                  smallVideo = peer.peerVideoStream;
                  secondScreen = undefined;
                } else if (peer.isVideoSwapped === "videosecondthird") {
                  largeVideo = peer.peerVideoStream;
                  secondScreen = peer.peerScreenStream;
                  smallVideo = peer.peerScreenTwoStream;
                } else if (peer.isVideoSwapped === "secondthirdvideo") {
                  largeVideo = peer.peerScreenStream;
                  secondScreen = peer.peerScreenTwoStream;
                  smallVideo = peer.peerVideoStream;
                } else if (peer.isVideoSwapped === "thirdvideosecond") {
                  largeVideo = peer.peerScreenTwoStream;
                  secondScreen = peer.peerVideoStream;
                  smallVideo = peer.peerScreenStream;
                } else if (peer.isVideoSwapped === "thirdvideo") {
                  largeVideo = peer.peerScreenTwoStream;
                  secondScreen = peer.peerVideoStream;
                  smallVideo = undefined;
                } else if (peer.isVideoSwapped === "videothird") {
                  largeVideo = peer.peerVideoStream;
                  secondScreen = peer.peerScreenTwoStream;
                  smallVideo = undefined;
                }

                return (
                  <li
                    className={`${
                      peer.expression
                    } student-grid-item participants-${getPagePeers().length}`}
                    key={peer.peerSessionId}
                    style={{
                      width: flexObj.width + "%",
                      height: flexObj.height + "%",
                    }}
                  >
                    {openentPeerId === peer.peerSessionId ? (
                      <ScreenControl
                        largeVideo={peer.peerScreenStream}
                        id={socketId}
                        name={peer.peerUsername}
                        roomname={roomname}
                      />
                    ) : null}

                    <div className="video-audio-container">
                      {largeVideo ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <Video
                            id={peer.peerSessionId}
                            name={peer.peerUsername}
                            srcObject={largeVideo}
                            className={`large-video white-border`}
                            peer={peer}
                          />
                          {!peer.peerAudioPaused ? (
                            <AudioVolumeMeter
                              id={peer.peerSessionId}
                              view="col"
                            />
                          ) : null}
                        </div>
                      ) : (
                        <>
                          <NameAvatar
                            text={peer.peerName}
                            className="large-avatar"
                          />
                          {!peer.peerAudioPaused ? (
                            <AudioVolumeMeter
                              id={peer.peerSessionId}
                              view="col"
                            />
                          ) : null}
                        </>
                      )}

                      <div className="small-video">
                        {secondScreen && (
                          <div className="small-video-inner-one">
                            <Video
                              id={peer.peerSessionId}
                              name={peer.peerUsername}
                              srcObject={secondScreen}
                              peer={peer}
                            />
                          </div>
                        )}
                        {smallVideo && (
                          <div className="small-video-inner-two">
                            <Video
                              id={peer.peerSessionId}
                              name={peer.peerUsername}
                              srcObject={smallVideo}
                              peer={peer}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {viewMode !== roomViewModeConst.collabMode &&
                    true ? (
                      <ParticipantCard
                        shouldShowExitButton={false}
                        name={peer.peerName}
                        role={peer.peerType}
                        shouldShowRole={false}
                        shouldShowAvatar={false}
                        shouldShowNameRole={true}
                        pauseUnpauseVideo={pauseUnPauseVideo}
                        pauseUnPauseAudio={pauseUnPauseAudio}
                        audioPaused={peer.peerAudioPaused}
                        videoPaused={peer.peerVideoPaused}
                        id={peer.peerSessionId}
                        swapScreen={handleSwapScreen}
                        shareUnshareScreen={amISuperVisor && shareUnshareScreen}
                        shareUnshareScreenTwo={
                          amISuperVisor && shareUnshareScreenTwo
                        }
                        shouldShowSwapScreen={
                          (peer.peerScreenTwoStream &&
                            peer.peerVideoStream &&
                            !peer.peerVideoPaused) ||
                          (peer.peerScreenStream &&
                            peer.peerVideoStream &&
                            !peer.peerVideoPaused) ||
                          (peer.peerScreenStream && peer.peerScreenTwoStream) ||
                          (peer.peerScreenStream &&
                            peer.peerScreenTwoStream &&
                            peer.peerVideoStream)
                        }
                        screenStopped={peer.peerScreenStopped}
                        screenTwoStopped={peer.peerscreenTwoStopped}
                        alignControlsToRight={true}
                        // @ts-ignore
                        videoStream={amISuperVisor && peer.peerVideoStream}
                        // @ts-ignore
                        audioStream={amISuperVisor && peer.peerAudioStream}
                        shouldShowControls={false}
                        changeViewMode={noOp}
                        isPrivateCallButtonVisible={amISuperVisor}
                        makePrivateCall={makePrivateCall}
                        isPrivateCallOn={peer.isPrivateCallOn}
                        privateCallPeerOne={peer.privateCallPeerOne}
                        shouldShowSignalStrength={true}
                        signalStrength={signalStrength}
                        //hardcoding as false as we dont need face detection on instructor screen
                        isFaceDetectionEnabled={false}
                        islocalVideo={false}
                        roomname={roomName}
                        isOwnPrivateCallOn={isPrivateCallOn}
                        peerUserName={peer.peerUsername}
                        emojiForIconPeer={peer.emojiForIconPeer}
                        emojiForHandAndLunchPeer={peer.emojiForHandAndLunchPeer}
                        isCallModal={isCallModal}
                        openentObserveeAudioId={openentObserveeAudioId}
                        peerOpenentObserverAudioId={peer.openentObserverAudioId}
                        peerIsCallModal={peer.isCallModalOn}
                        isScreenAudioShared={isScreenAudioShared}
                        handleHandraise={handleHandraise}
                      />
                    ) : null}
                  </li>
                );
              }
            })}
          </ul>
        )}
      </div>
      <ul className="pagination">
        {pages &&
          pages.length &&
          pages.length > 1 &&
          pages.map((pageNumber: number) => {
            return (
              <li
                key={pageNumber}
                onClick={() => changePage(pageNumber)}
                className="page-number-item"
                style={
                  pageNumber === currentPageNumber
                    ? { background: "rgb(38, 163, 219)" }
                    : { background: "black" }
                }
              />
            );
          })}
      </ul>
      <div className="instructor-local-screen-participant-card-video-container">
        <div className="instructor-local-screen-video-container">
          <div className="instructor-local-screen-video-inner-container">
            {/* recurrentCheck ? admin_video_hight : verification ? "admin_video_hight verify_border" : */}
            {/* verification ? "admin_video_hight verify_border" : wrongPersonCheck ? "admin_video_hight other_person" : !recurrentCheck ? "admin_video_hight " : "admin_video_hight un-verify_border" */}
            <div
              className={
                firstInstructorVideo &&
                localVideoStream &&
                !localVideoPaused &&
                recurrentCheck
                  ? "admin_video_hight verify_border"
                  : firstInstructorVideo &&
                    localVideoStream &&
                    !localVideoPaused &&
                    !verification
                  ? "admin_video_hight un-verify_border"
                  : firstInstructorVideo &&
                    localVideoStream &&
                    !localVideoPaused &&
                    wrongPersonCheck
                  ? "admin_video_hight other_person"
                  : "admin_video_hight"
              }
            >
              {firstInstructorVideo && localVideoStream && !localVideoPaused ? (
                [
                  <Video
                    id={socketId}
                    srcObject={firstInstructorVideo}
                    className={
                      firstInstructorVideo?.id === localVideoStream?.id &&
                      !localVideoPaused
                        ? `face-detection-node white-border ${videoClassName}`
                        : `white-border ${videoClassName}`
                    }
                    onLoad={setSourcePlayback}
                  />,
                  (videoOption === "blur" || videoOption === "background") &&
                    props.segmentationConfig && (
                      <>
                        <canvas
                          // The key attribute is required to create a new canvas when switching
                          // context mode
                          key={props.segmentationConfig.pipeline}
                          ref={canvasDisplayRef}
                          style={{ height: "100%" }}
                        />
                      </>
                    ),
                ]
              ) : (
                <NameAvatar text={currentUserName} className="large-avatar" />
              )}
            </div>

            <div className="instructor-control-icon-container">
              <ParticipantCard
                privateCallPeerOne={""}
                shouldShowRole={false}
                shouldShowNameRole={false}
                name={currentUserName}
                role={currentUserRole}
                pauseUnpauseVideo={pauseUnPauseVideo}
                pauseUnPauseAudio={pauseUnPauseAudio}
                addStreamOfAudioFile={addStreamOfAudioFile}
                addStreamOfVideoFile={addStreamOfVideoFile}
                audioPaused={localAudioPaused}
                videoPaused={localVideoPaused}
                id={socketId}
                swapScreen={handleSwapScreen}
                shareUnshareScreen={shareUnshareScreen}
                shareUnshareScreenTwo={shareUnshareScreenTwo}
                shouldShowSwapScreen={false}
                shouldShowAvatar={true}
                screenStopped={screenStopped}
                screenTwoStopped={screenTwoStopped}
                alignControlsToRight={false}
                videoStream={localVideoStream}
                audioStream={localAudioStream}
                shouldShowExitButton={true}
                shouldShowControls={shouldShowControls}
                changeViewMode={changeViewMode}
                isPrivateCallButtonVisible={false}
                shouldShowSignalStrength={true}
                signalStrength={signalStrength}
                //hardcoding as false as we dont need face detection on instructor screen
                isFaceDetectionEnabled={false}
                islocalVideo={true}
                roomname={roomName}
                localScreenStream={localScreenStream}
                tflite={props.tflite}
                isScreenAudioShared={isScreenAudioShared}
                handleHandraise={handleHandraise}
              />

              {/* <canvas
              id="canvas"
              style={{ display: "none", marginRight: 10 }}
            ></canvas> */}
              {localVideoStream &&
                !localVideoPaused &&
                firstInstructorVideo &&
                (videoOption === "blur" || videoOption === "background") &&
                props.segmentationConfig && (
                  <canvas
                    // The key attribute is required to create a new canvas when switching
                    // context mode
                    id="blur"
                    style={{ display: "none" }}
                    key={props.segmentationConfig.pipeline}
                    ref={canvasRef}
                    // width={640}
                    // height={480}
                  />
                )}
            </div>

            <div className="admin_video_hight">
              {firstInstructorScreen && !isVideoFile ? (
                <Video
                  id={socketId}
                  srcObject={firstInstructorScreen}
                  className={`white-border`}
                  muted={true}
                />
              ) : null}
            </div>

            {secondInstructorScreen && (
              <div className="admin_video_hight">
                <Video
                  id={socketId}
                  srcObject={
                    secondInstructorScreen ? secondInstructorScreen : null
                  }
                  className={`white-border`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorScreen;
