import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Webcam from "react-webcam";
import Header from "../Header";
import Inst1 from "../../assets/images/inst1.png";
import Inst2 from "../../assets/images/inst2.png";
import Inst3 from "../../assets/images/inst3.png";
import Check from "../../assets/images/check.png";
import Pending from "../../assets/images/pending.png"
import Cross from "../../assets/images/cross.png";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import {
  setFaceRegistrationAction,
  checkIsRegistered,
  userRejectedAction,
} from "../../actions/faceRegistrationAction";
import { SET_REGISTER_COUNT, SET_REGISTRATION_SUCCESS } from "../../actions/actionTypes";
import { getEnvironmentsAction } from "../../actions/environmentActions";

function FaceRegistration() {
  const webcamRef = React.useRef(null);
  // const [capturedImage, setCapturedImage] = React.useState(null);
  const [isRegistered, setIsRegistered] = React.useState(false);
  const dispatch = useDispatch();

  const username = useSelector(
    (state: RootState) => state.userDetails.userName
  );
  const client = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  ); //.STORE_DETECTION_IMAGES.value
  const [isRejected, setIsRejected] = React.useState<boolean>(false);
  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  // const [registerCount, setRegisterCount] = React.useState(0);
  const [removeExisting, setRemoveExisting] = React.useState(false);
  const registered = useSelector(
    (state: RootState) => state.faceRegistration.isRegistered
  );
  const registerCount = useSelector(
    (state: RootState) => state.faceRegistration.falseRegisterCount
  );

  const rejected = useSelector(
    (state: RootState) => state.faceRegistration.isRejected
  );

  const registrationURL = useSelector((state: RootState) => state.environmentsReducers.environments && state.environmentsReducers.environments.REACT_APP_FACE_REGISTRATION ? state.environmentsReducers.environments.REACT_APP_FACE_REGISTRATION.value : '')
  const spacesConfig = useSelector((state: RootState) => state.environmentsReducers.environments && state.environmentsReducers.environments.REACT_APP_SPACES_CONFIG ? state.environmentsReducers.environments.REACT_APP_SPACES_CONFIG.data[0] : '')
  const companyName = useSelector((state: RootState) => state.environmentsReducers.environments && state.environmentsReducers.environments.REACT_APP_COMPANY_NAME ? state.environmentsReducers.environments.REACT_APP_COMPANY_NAME.value : '')
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const verified = useSelector((state: RootState) => state.userDetails.isUserVerified)
  const elem = document.querySelector("#webcam");
  const captureImage = () => {
    // @ts-ignore
    const imageSrc = webcamRef.current.getScreenshot();

    if (!isRegistered) {
      client.STORE_DETECTION_IMAGES &&
        client.STORE_DETECTION_IMAGES.value &&
        dispatch(
          setFaceRegistrationAction(
            registrationURL,
            username,
            client.STORE_DETECTION_IMAGES.value,
            imageSrc,
            removeExisting,
            spacesConfig,
            companyName
          )
        );

    } else {
      client.STORE_DETECTION_IMAGES &&
        client.STORE_DETECTION_IMAGES.value &&
        dispatch(
          setFaceRegistrationAction(
            registrationURL,
            username,
            client.STORE_DETECTION_IMAGES.value,
            imageSrc,
            removeExisting,
            spacesConfig,
            companyName
          )
        );
    }

  };

  useEffect(() => {
    if (registered) {
      setIsRegistered(true);
      setRemoveExisting(true);
    }
  }, [registered]);

  useEffect(() => {
    dispatch(userRejectedAction(username));
    dispatch(getEnvironmentsAction());
  }, [dispatch, username]);

  useEffect(() => {

    setIsRejected(rejected);

  }, [rejected]);

  useEffect(() => {
    if (verified) {
      setIsVerified(true);
    }
  }, [verified])

  useEffect(() => {
    if (isLoading) {
      elem?.classList.add(styles.webcam);
    } else {
      elem?.classList.remove(styles.webcam);
    }
  }, [elem?.classList, isLoading]);

  useEffect(() => {

    dispatch(checkIsRegistered(username));
  }, [dispatch, username]);


  useEffect(() => {
    dispatch({
      type: SET_REGISTER_COUNT,
      payload: {
        count: 0
      }
    })
  }, [dispatch])

  return (
    <>
      <Header />

      <div className={styles.main_container}>
        {isRejected ? (
          <Alert variant="danger" className={styles.info_rejected}>
            *Your Face Verification has been declined by the
            Administrator/Supervisor. Kindly proceed with re-registration,
            adhering to the provided instructions.
          </Alert>
        ) : (
          ""
        )}
        <div className={styles.container}>
          <div className={styles.cam_container}>
            {!isRegistered && !isVerified ? (
              <React.Fragment>
                <Webcam
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  // className={styles.webcam}
                  ref={webcamRef}
                  id="webcam"
                  style={{
                    border: "solid 6px #26a3db",
                    borderRadius: "10px",

                    width: "80%",
                  }}
                  screenshotFormat={"image/jpeg"}
                />
                {registerCount >= 3 ? (
                  <p className={styles.errormessage}>
                    Please Contact Your Director/Manager to Register or Try
                    Again By Refreshing and Realign your face.
                  </p>
                ) : (
                  ""
                )}

                <button
                  className={styles.btn}
                  style={{
                    opacity: registerCount >= 3 ? 0.7 : 1,
                    cursor: registerCount >= 3 ? "auto" : "pointer ",
                  }}
                  disabled={registerCount >= 3}
                  onClick={captureImage}
                >
                  Capture / Register
                </button>
              </React.Fragment>
            ) :

              isRegistered && !isVerified ? (
                <React.Fragment>
                  <div className={styles.pending}>
                    <img
                      src={Pending}
                      alt="success-img"
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                  {
                    !isRejected &&
                    <p className={styles.pending_msg}>
                      Correctly Scanned Image, Awaiting Approval
                    </p>
                  }
                </React.Fragment>
              ) :

                (
                  <React.Fragment>
                    <div className={styles.success_circle}>
                      <img
                        src={Check}
                        alt="success-img"
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                    <p className={styles.success_msg}>
                      Face Registration Successfull!!!
                    </p>
                  </React.Fragment>
                )}
          </div>
          <div className={styles.instructions_container}>
            <h3>Instructions:</h3>

            <ul>
              <li>
                Click the image once it is aligned in the boundary
              </li>
              <li>
                Preferably look for a plain background
              </li>
              <li>
                No light reflections in your glasses in case you wear glasses
              </li>
              <li>
                Below for your reference on how to position your face within the boundary
              </li>
            </ul>
            <div className={styles.images_container}>
              <div className={styles.cont}>
                <img src={Inst2} alt="cross-img" />
                <img className={styles.sign} src={Cross} alt="" />
              </div>
              <div className={styles.cont}>
                <img src={Inst1} alt="check-img" />
                <img className={styles.sign} src={Check} alt="" />
              </div>
              <div className={styles.cont}>
                <img src={Inst3} alt="cross-img" />
                <img className={styles.sign} src={Cross} alt="" />
              </div>
            </div>
            {isRegistered ? (
              <button
                className={`${styles.btn} ${styles.btn1} `}
                onClick={() => {
                  setIsRegistered(false);
                  dispatch({
                    type: SET_REGISTRATION_SUCCESS,
                    payload: {
                      isRegistered: false,
                      status: "",
                    },
                  });
                  // setRegisterCount(0);
                  setIsRejected(false);
                  setIsVerified(false);
                }}
              >
                Register Again
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FaceRegistration;
