import React, { useState, useEffect } from "react";
import Board from "../board/Board";
import sliderBackground from "../../../assets/images/slider-background.svg";
import "./style.css";
import {
  faLock,
  faLockOpen,
  faTrash,
  faCheck,
  faPencilAlt,
  faEraser,
  faPenAlt,
  faFont,
  faImage,
  faSave,
  faAngleLeft,
  faAngleRight,
  faUndo,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import SocketService from "../../../services/socket";
import { useParams, useNavigate } from "react-router-dom";
import { faCircle, faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IConnectionDetails } from "../../../Pages/RoomPage/interfaces";
import { getConnectionDetails } from "../../../services/rooms";
import { toast } from "react-toastify";
import { checkForSupManAdmin } from "../../../utils/checkForSupAdmin";
import { socketConnectiontype } from "../../../utils/constant";
import { useDispatch,useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { getUserDetailsAction } from "../../../actions/userActions";


// let socket :SocketIOClient.Socket | undefined = io("http://localhost:5000");

const socketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

const Container = (props: any) => {
  const [strokeColor, setStrokeColor] = useState("#000000");
  const [strokeWidth, setStrokeWidth] = useState("5");
  const [selectTool, setSelectTool] = useState({
    line: false,
    pen: true,
    circle: false,
    rectangle: false,
    eraser: false,
    stickyNote: false,
    textBox: false,
  });
  const [saveImage, setSaveImage] = useState(false);
  const [clearCanvas, setClearCanvas] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [stickNoteColor, setStickyNoteColor] = useState("#f2f542");
  const [dropIndicator, setDropIndicator] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  const params = useParams();

  const [backgroundImage, setBackgroundImage] = useState(
    [] as Array<{ id: string; img: string }>
  );
  const [readForInstructor, setReadForInstructor] = useState(false as boolean);
  const [uploadFiles, setUploadFiles] = useState({});
  const [showAllButton, setShowAllButton] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const role = useSelector((state:RootState) => state.userDetails.role)

  let messageSubscriber: any;

  const showToast = (message: string) => {
    toast(message);
  };

  const faCircleIcon = faCircle as IconProp;
  const faSquareIcon = faSquare as IconProp;
  // useEffect(()=>{
  //   if(socket !== undefined){
  //     socket.on("clearCanvas",(data:{isClearCanvas:boolean})=>{
  //       setClearCanvas(data.isClearCanvas);
  //     })

  //   }

  // },[socket])

  useEffect(() => {
    // props.getUserDetails(props.match.params.roomname);
    dispatch(getUserDetailsAction());
    window.addEventListener("dragover", function (e) {
      e.preventDefault();
    });

    window.addEventListener("drop", function (e) {
      e.preventDefault();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    messageSubscriber = socketInstance
      .getMessages()
      .subscribe((message: string) => {
        processMessage(JSON.parse(message));
      });

    const fetchConnectionDetails = async () => {
      try {
        const connectionDetails: IConnectionDetails =
          await getConnectionDetails(params?.roomname);
        let hostname = window.location.origin;

        if (hostname.includes("http://localhost")) {
          hostname = "http://localhost:8080";
        }

        if (hostname.includes("https://localhost")) {
          hostname = "https://localhost:8080";
        }

        socketInstance.connectToSocket(
          hostname,
          connectionDetails.path,
          connectionDetails.payload,
          connectionDetails.iv,
          connectionDetails.digest,
          "",
          true
        );
      } catch (err) {
        showToast("OOPS! Room Name was not valid");
        navigate("/login");
      }
    };

    fetchConnectionDetails();

    return () => {
      messageSubscriber.unsubscribe();
      socketInstance.closeSocketConnection();
    };
  }, []);

  const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const processMessage = async (message: any) => {
    if (message.data === undefined) return;

    const data = message.data;
    switch (message.type) {
      case "clearCanvas":
        setClearCanvas(data.isClearCanvas);
        break;
      case "readOnly":
        readOnlyForAgent();
        break;
      case "readWriteOnly":
        readWriteOnlyForAgent();
        break;
      default:
        break;
    }
  };

  const readOnlyForAgent = () => {
    if (checkForSupManAdmin(role, "notEqualTo")) {
      setReadOnly(true);
      setSelectTool((value) => ({
        line: false,
        pen: false,
        circle: false,
        rectangle: false,
        eraser: false,
        stickyNote: false,
        textBox: false,
      }));
    }
  };

  const readWriteOnlyForAgent = () => {
    if (checkForSupManAdmin(role, "notEqualTo")) {
      setReadOnly(false);
      setSelectTool((value) => ({
        line: false,
        pen: true,
        circle: false,
        rectangle: false,
        eraser: false,
        stickyNote: false,
        textBox: false,
      }));
    }
  };

  const uploadImage = async (e: any) => {
    if (e.dataTransfer) {
      if (e.dataTransfer.files.length) {
        e.preventDefault();
        e.stopPropagation();
        if (!e.dataTransfer.files[0]) return;

        let filename = e.dataTransfer.files[0].name;
        const fileSize = e.dataTransfer.files[0].size / (1024 * 1024);

        if (fileSize <= 5) {
          const fileExtensionArray = filename.split(".");
          let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];

          fileExtension = fileExtension.toLowerCase();

          if (
            fileExtension === "jpg" ||
            fileExtension === "jpeg" ||
            fileExtension === "png"
          ) {
            const formData = new FormData();
            formData.append("productImage", e.dataTransfer.files[0]);

            let id = new Date() + "";
            setBackgroundImage([
              { id, img: URL.createObjectURL(e.dataTransfer.files[0]) },
            ]);

            setUploadFiles(e.dataTransfer.files[0]);
          }
        }
      }
    }

    setDropIndicator(false);
  };

  return (
    <>
      {role === "" ? null : (
        <>
          {/*-Whiteboard container -!*/}
          <div
            id="whiteboardContainer"
            onDragEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDropIndicator(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDropIndicator(false);
            }}
            onDrop={(e) => {
              if (readOnly) return;
              uploadImage(e);
            }}
          >
            <Board
              strokeColor={strokeColor}
              strokeWidth={strokeWidth}
              selectTool={selectTool}
              clearCanvas={clearCanvas}
              setClearCanvas={setClearCanvas}
              stickyNoteColor={stickNoteColor}
              dropIndicator={dropIndicator}
              setDropIndicator={setDropIndicator}
              saveImage={saveImage}
              setSaveImage={setSaveImage}
              readOnly={readOnly}
              backgroundImage={backgroundImage}
              setBackgroundImage={setBackgroundImage}
              // currentUserRole={props.currentUserRole}
              uploadFiles={uploadFiles}
              b64toBlob={b64toBlob}
              readOnlyForAgent={readOnlyForAgent}
              readWriteOnlyForAgent={readWriteOnlyForAgent}
              setReadForInstructor={setReadForInstructor}
              setSelectTool={setSelectTool}
            />
          </div>
          {/*-Toolbar -!*/}
          <div
            id="toolbar"
            style={{ position: "absolute", top: "10px", left: "10px" }}
          >
            {checkForSupManAdmin(role, "equalTo") ? (
              <div
                className="btn-group"
                style={{ display: showAllButton ? "block" : "none" }}
              >
                {readForInstructor ? (
                  <button
                    id="whiteboardLockBtn"
                    style={{ backgroundColor: "orange" }}
                    title="View and Write"
                    type="button"
                    onClick={() => {
                      // setReadOnly(false);
                      // setSelectTool((value) => ({
                      //   line: false,
                      //   pen: true,
                      //   circle: false,
                      //   rectangle: false,
                      //   eraser: false,
                      //   stickyNote: false,
                      //   textBox: false,
                      // }));

                      socketInstance.sendMessage("readWriteOnly", {
                        tool: "readWriteOnly",
                        readOnly: false,
                      });
                      setReadForInstructor(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </button>
                ) : null}
                {!readForInstructor ? (
                  <button
                    id="whiteboardUnlockBtn"
                    title="View Only"
                    type="button"
                    onClick={(e) => {
                      socketInstance.sendMessage("readOnly", {
                        tool: "readOnly",
                        readOnly: true,
                      });
                      setReadForInstructor(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faLockOpen} />
                  </button>
                ) : null}
              </div>
            ) : null}
            <div
              className={`btn-group whiteboard-edit-group ${
                readOnly ? "group-disabled" : ""
              }`}
              style={{ display: showAllButton ? "block" : "none" }}
            >
              <button
                id="whiteboardTrashBtn"
                title="Clear the whiteboard"
                type="button"
                style={{ display: confirmDelete ? "none" : "block" }}
                onClick={() => setConfirmDelete(true)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
              <button
                style={{ display: !confirmDelete ? "none" : "block" }}
                id="whiteboardTrashBtnConfirm"
                title="Confirm clear..."
                type="button"
                onClick={() => {
                  setClearCanvas(true);
                  setConfirmDelete(false);
                  socketInstance?.sendMessage("clearCanvas", {
                    isClearCanvas: true,
                  });
                }}
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
              <button
                id="whiteboardUndoBtn"
                title="Undo your last step"
                type="button"
                onClick={() => {
                  socketInstance.sendMessage("undo", {});
                }}
              >
                <FontAwesomeIcon icon={faUndo} />
              </button>
              <button
                id="whiteboardRedoBtn"
                title="Redo your last undo"
                type="button"
                onClick={() => {
                  socketInstance.sendMessage("redo", {});
                }}
              >
                <FontAwesomeIcon icon={faRedo} />
              </button>
            </div>
            <div
              className={`btn-group whiteboard-edit-group ${
                readOnly ? "group-disabled" : ""
              }`}
              style={{ display: showAllButton ? "block" : "none" }}
            >
              <button
                title="Take the pen"
                type="button"
                className="whiteboard-tool"
                onClick={(e) => {
                  setSelectTool((value) => ({
                    line: false,
                    pen: true,
                    circle: false,
                    rectangle: false,
                    eraser: false,
                    stickyNote: false,
                    textBox: false,
                  }));
                }}
                style={
                  selectTool.pen
                    ? { background: "#dfdfdf" }
                    : { background: "transparent" }
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
              <button
                title="draw a line"
                type="button"
                className="whiteboard-tool"
                onClick={(e) =>
                  setSelectTool((value) => ({
                    line: true,
                    pen: false,
                    circle: false,
                    rectangle: false,
                    eraser: false,
                    stickyNote: false,
                    textBox: false,
                  }))
                }
                style={
                  selectTool.line
                    ? {
                        background: "#dfdfdf",
                        paddingBottom: "8px",
                        paddingTop: "6px",
                      }
                    : {
                        background: "transparent",
                        paddingBottom: "8px",
                        paddingTop: "6px",
                      }
                }
              >
                ╱
              </button>
              <button
                title="draw a rectangle"
                type="button"
                className="whiteboard-tool"
                onClick={(e) =>
                  setSelectTool((value) => ({
                    line: false,
                    pen: false,
                    circle: false,
                    rectangle: true,
                    eraser: false,
                    stickyNote: false,
                    textBox: false,
                  }))
                }
                style={
                  selectTool.rectangle
                    ? {
                        background: "#dfdfdf",
                      }
                    : {
                        background: "transparent",
                      }
                }
              >
                <FontAwesomeIcon icon={faSquareIcon} />
              </button>
              <button
                title="draw a circle"
                type="button"
                className="whiteboard-tool"
                onClick={(e) =>
                  setSelectTool((value) => ({
                    line: false,
                    pen: false,
                    circle: true,
                    rectangle: false,
                    eraser: false,
                    stickyNote: false,
                    textBox: false,
                  }))
                }
                style={
                  selectTool.circle
                    ? { background: "#dfdfdf" }
                    : { background: "transparent" }
                }
              >
                <FontAwesomeIcon icon={faCircleIcon} />
              </button>
              <button
                title="take the eraser"
                type="button"
                className="whiteboard-tool"
                onClick={(e) =>
                  setSelectTool((value) => ({
                    line: false,
                    pen: false,
                    circle: false,
                    rectangle: false,
                    eraser: true,
                    stickyNote: false,
                    textBox: false,
                  }))
                }
                style={
                  selectTool.eraser
                    ? { background: "#dfdfdf" }
                    : { background: "transparent" }
                }
              >
                <FontAwesomeIcon icon={faEraser} />
              </button>
            </div>
            <div
              className={`btn-group whiteboard-edit-group ${
                readOnly ? "group-disabled" : ""
              }`}
              style={{ display: showAllButton ? "block" : "none" }}
            >
              <button style={{ width: "190px", cursor: "default" }}>
                <div
                  className="activeToolIcon"
                  style={{
                    position: "absolute",
                    top: "2px",
                    left: "2px",
                    fontSize: "0.6em",
                  }}
                >
                  <FontAwesomeIcon icon={faPenAlt} />
                </div>
                <img
                  alt="slider"
                  style={{
                    position: "absolute",
                    left: "11px",
                    top: "16px",
                    height: "14px",
                    width: "130px",
                  }}
                  src={sliderBackground}
                />
                <input
                  title="Thickness"
                  id="whiteboardThicknessSlider"
                  style={{
                    position: "absolute",
                    left: "9px",
                    width: "130px",
                    top: "15px",
                    border: "none",
                  }}
                  type="range"
                  min={1}
                  max={50}
                  value={strokeWidth}
                  onChange={(e) => {
                    setStrokeWidth(e.target.value);
                  }}
                />

                <input
                  type="color"
                  id="whiteboardColorpicker"
                  style={{
                    position: "absolute",
                    left: "155px",
                    top: "10px",
                    width: "28px",
                    height: "23px",
                    borderRadius: "3px",
                    border: "1px solid darkgrey",
                    padding: "0",
                  }}
                  onChange={(e) => {
                    setStrokeColor(e.target.value);
                  }}
                  value={strokeColor}
                />
              </button>
            </div>
            <div
              className={`btn-group whiteboard-edit-group ${
                readOnly ? "group-disabled" : ""
              }`}
              style={{ display: showAllButton ? "block" : "none" }}
            >
              {/* <button
                title="write text"
                type="button"
                className="whiteboard-tool"
                onClick={(e) =>
                  setSelectTool((value) => ({
                    line: false,
                    pen: false,
                    circle: false,
                    rectangle: false,
                    eraser: false,
                    stickyNote: false,
                    textBox: true,
                  }))
                }
                style={
                  selectTool.textBox
                    ? { background: "#dfdfdf" }
                    : { background: "transparent" }
                }
              >
                <FontAwesomeIcon icon={faFont} />
              </button> */}
              <button
                title="place a sticky note"
                type="button"
                className="whiteboard-tool"
                onClick={(e) =>
                  setSelectTool((value) => ({
                    line: false,
                    pen: false,
                    circle: false,
                    rectangle: false,
                    eraser: false,
                    stickyNote: true,
                    textBox: false,
                  }))
                }
                style={
                  selectTool.stickyNote
                    ? { background: "#dfdfdf" }
                    : { background: "transparent" }
                }
              >
                <FontAwesomeIcon icon={faFont} />
              </button>

              <button
                id="textboxBackgroundColorPickerBtn"
                style={{
                  display:
                    selectTool.stickyNote || selectTool.textBox
                      ? "block"
                      : "none",
                }}
              >
                <input
                  type="color"
                  // id="textboxBackgroundColorPicker"
                  style={{
                    width: "26px",
                    height: "23px",
                    borderRadius: "3px",
                    border: "1px solid darkgrey",
                    left: "-4px",
                    top: "-2px",
                    position: "relative",
                    background: `${stickNoteColor}`,
                  }}
                  onChange={(e) => setStickyNoteColor(e.target.value)}
                  value={stickNoteColor}
                />
              </button>
            </div>

            <div
              className="btn-group"
              style={{ display: showAllButton ? "block" : "none" }}
            >
              <button
                id="saveAsImageBtn"
                title="Save whiteboard as image"
                type="button"
                onClick={() => setSaveImage(true)}
              >
                <FontAwesomeIcon icon={faImage} />
                <FontAwesomeIcon
                  style={{
                    position: "absolute",
                    top: "3px",
                    left: "2px",
                    color: "#000000",
                    fontSize: "0.5em",
                  }}
                  icon={faSave}
                />
              </button>

              {/* <button
            style={{ position: "relative" }}
            id="saveAsJSONBtn"
            title="Save whiteboard as JSON"
            type="button"
          >
            <FontAwesomeIcon icon={faFileAlt} />
            <FontAwesomeIcon
              style={{
                position: "absolute",
                top: "3px",
                left: "2px",
                color: "#000000",
                fontSize: "0.5em",
              }}
              icon={faSave}
            />

          </button> */}
              {/* <button
            id="shareWhiteboardBtn"
            title="share whiteboard"
            type="button"
            onClick={() => setShowShareOption(true)}
          >
            <FontAwesomeIcon icon={faShareSquare} />
          </button> */}
            </div>
            <div className="btn-group minGroup">
              <button
                style={{ width: "100%", padding: "11px 11px" }}
                id="minMaxBtn"
                title="hide buttons"
                type="button"
                onClick={() => setShowAllButton((value) => !value)}
              >
                <FontAwesomeIcon
                  id="minBtn"
                  style={{
                    position: "relative",
                    left: "-5px",
                    display: showAllButton ? "block" : "none",
                  }}
                  icon={faAngleLeft}
                />
                <FontAwesomeIcon
                  id="maxBtn"
                  style={{
                    position: "relative",
                    left: "-5px",
                    display: !showAllButton ? "block" : "none",
                  }}
                  icon={faAngleRight}
                />
              </button>
            </div>
          </div>

          {/* <div
            id="shareWhiteboardDialog"
            className={`${showShareOption ? "" : "displayNone"}`}
          >
            <div className="shareWhiteboardDialogContent">
              <button
                className="shareWhiteboardDialogItem"
                id="shareWhiteboardDialogCopyReadOnlyLink"
              >
                <i className="fa fa-lock" />
                &nbsp;Share read-only link
              </button>
              <button
                className={`shareWhiteboardDialogItem ${
                  showShareOption ? "" : "displayNone"
                }`}
                id="shareWhiteboardDialogCopyReadWriteLink"
              >
                <i className="fa fa-lock-open" />
                &nbsp;Share read/write link
              </button>
              <button
                className="shareWhiteboardDialogItem"
                id="shareWhiteboardDialogGoBack"
                onClick={(e) => setShowShareOption(false)}
              >
                <b>Go back to the whiteboard</b>
              </button>
              <p
                className="shareWhiteboardDialogItem displayNone"
                id="shareWhiteboardDialogMessage"
              />
            </div>
          </div> */}
        </>
      )}
    </>
  );
};


export default Container;
