import {
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
  faHandPaper,
  faDesktop,
  faCamera,
  faUsers,
  faStopwatch20,
  faDeaf,
  faUser,
  faSmile,
  faSignLanguage,
  faThumbsUp,
  faThumbsDown,
  faChalkboardTeacher,
  faPhoneVolume,
  faComments,
  faMouse,
  faPizzaSlice,
  faBell,
  faUpload,
  faBinoculars,
  faCogs,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";
import { TFLite } from "../../Pages/RoomPage/interfaces";
import { SegmentationConfig } from "../../helpers/segmentationHelper";
import { SourcePlayback } from "../../helpers/sourceHelper";
import useRenderingPipeline from "../../hooks/useRenderingPipeline";
import NameAvatar from "./../NameAvatar";
import Video from "./../Video";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
// @ts-ignore
import LogoutImage from "../../assets/images/logout.svg";
import { useNavigate } from "react-router-dom";
import SignalStrengthIndicator from "./../../components/SignalIndicator";
import SocketService from "../../services/socket";
import React, { useEffect, useState, useRef } from "react";
import { checkForSupManAdmin } from "../../utils/checkForSupAdmin";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { setVideoOption } from "../../actions/videoEditOption";
import FeedbackModal from "./FeedbackModal";
import { data } from "../../utils/FeedbackData";
import { clearTempRoomUser } from "../../actions/tempRoomUser";
import { setControlId } from "../../actions/screenControlAction";
import LunchBreakModal from "./lunchBreakModal";
import ModalAudioVideo from "../ModalAudioVideo";
import BackgroundModal from "./BackgroundModal/BackgroundModal";
import { backgroundModalOpen } from "../../actions/backgroundAction";
import { roomViewModeConst, socketConnectiontype } from "../../utils/constant";
import {
  REACT_APP_SCREENCONTROL,
  SUPERVISOR_CAN_OBSERVE,
  OTHER_CAMERA_ACCESS_TO_SUPERVISOR,
  AGENT_MIC_ACCESS,
  AGENT_SCREENS_ACCESS,
  VIDEO_BACKGROUND_BLUR,
  VIDEO_BACKGROUND_IMAGES,
} from "../../Pages/RoomPage/constants";
import { setCameraBackground } from "../../actions/userActions";
import { toast } from "react-toastify";
import { getMinuteDifference } from "../../utils/getMinutesDifferenceFromTime";
import { setActivityModalOpen } from "../../actions/activityChannelAction";
import { setReconnectionStatus } from "../../actions/reconnectionAction";
import { AGENT_CAM_ALWAYS_ON } from "../../Pages/RoomPage/constants";

interface IParticipantCard {
  name: string;
  role: string;
  audioPaused: boolean;
  videoPaused: boolean;
  pauseUnpauseVideo: (id: number) => void;
  pauseUnPauseAudio: (id: number) => void;
  id: any;
  showVideo?: boolean;
  videoStream?: MediaStream;
  swapScreen?: (id: any) => void;
  shareUnshareScreen?: (id: any) => void;
  shareUnshareScreenTwo?: (id: any) => void;
  shouldShowSwapScreen?: any;
  smallRightVideo?: MediaStream;
  smallLeftVideo?: MediaStream;
  screenStopped: boolean;
  screenTwoStopped: boolean;
  alignControlsToRight?: boolean;
  audioStream?: MediaStream;
  shouldShowRole?: boolean;
  shouldShowAvatar?: boolean;
  shouldShowNameRole?: boolean;
  shouldShowExitButton?: boolean;
  shouldShowControls?: boolean;
  changeViewMode: () => void;
  isPrivateCallButtonVisible?: boolean;
  makePrivateCall?: (id: any) => void;
  shouldShowSignalStrength?: boolean;
  isPrivateCallOn?: boolean;
  privateCallPeerOne?: any;
  signalStrength?: string;
  isFaceDetectionEnabled?: boolean;
  islocalVideo?: boolean;
  roomname: string;
  isOwnPrivateCallOn?: boolean;
  segmentationConfig?: SegmentationConfig;
  tflite?: TFLite;
  peerUserName?: string;
  emojiForIconPeer?: string;
  emojiForHandAndLunchPeer?: string;
  addStreamOfAudioFile?: (peerId: string, id: string) => void;
  addStreamOfVideoFile?: (peerId: string) => void;
  localScreenStream?: any;
  isCallModal?: boolean;
  openentObserveeAudioId?: string;
  peerOpenentObserverAudioId?: string;
  peerIsCallModal?: boolean;
  isScreenAudioShared?: boolean;
  audioAllowedInRoom?: boolean;
  handleHandraise?: (peerSessionId: string) => void;
}

interface IControlProps {
  handleHandraise?: (peerSessionId: string) => void
  privateCallPeerOne?: any;
  audioPaused: boolean;
  videoPaused: boolean;
  pauseUnpauseVideo: (id: number) => void;
  pauseUnPauseAudio: (id: number) => void;
  id: any;
  changeViewMode: () => void;
  //
  showVideo?: boolean;
  swapScreen?: (id: any) => void;
  shareUnshareScreen?: (id: any) => void;
  shareUnshareScreenTwo?: (id: any) => void;
  shouldShowSwapScreen?: any;
  smallRightVideo?: MediaStream;
  smallLeftVideo?: MediaStream;
  screenStopped: boolean;
  screenTwoStopped: boolean;
  alignControlsToRight?: boolean;
  shouldShowExitButton?: boolean;
  shouldShowControls?: boolean;
  //
  audioStream?: any;
  videoStream?: any;

  isPrivateCallButtonVisible?: boolean;
  makePrivateCall?: (id: any) => void;
  shouldShowSignalStrength?: boolean;
  isPrivateCallOn?: boolean;
  signalStrength?: string;
  roomname: string;
  currentUserRole: string;
  isOwnPrivateCallOn?: boolean;
  role?: string;
  peerUserName?: string;
  emojiForIconPeer?: string;
  emojiForHandAndLunchPeer?: string;
  addStreamOfAudioFile?: (peerId: string, id: string) => void;
  addStreamOfVideoFile?: (peerId: string) => void;
  localScreenStream?: any;
  isCallModal?: boolean;
  openentObserveeAudioId?: string;
  peerOpenentObserverAudioId?: string;
  peerIsCallModal?: boolean;
  tflite?: TFLite;
  isScreenAudioShared: IParticipantCard["isScreenAudioShared"];
  audioAllowedInRoom: IParticipantCard["audioAllowedInRoom"];
}

const socketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

const Controls = (props: IControlProps) => {
  const {
    audioPaused = false,
    videoPaused = false,
    pauseUnpauseVideo,
    pauseUnPauseAudio,
    id,
    videoStream,
    swapScreen,
    shareUnshareScreen,
    shareUnshareScreenTwo,
    shouldShowSwapScreen = false,
    screenStopped = true,
    screenTwoStopped = true,
    audioStream,
    shouldShowExitButton = false,
    // shouldShowControls = false,
    changeViewMode,
    shouldShowSignalStrength = false,
    roomname,
    currentUserRole,
    role,
    emojiForIconPeer = "",
    emojiForHandAndLunchPeer = "",
    addStreamOfAudioFile,
    addStreamOfVideoFile,
    isScreenAudioShared,
    audioAllowedInRoom
  } = props;

  const [networkStrength, setNetworkStrength] = useState("EXCELLENT");
  // The thrice below for feedback modal
  const [exportModal, setExportModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [posValue, setPosValue] = useState({ x: 0, y: 0 });

  const [emojiForIcon, setEmojiForIcon] = useState("");
  const [emojiForHandAndLunch, setEmojiForHandAndLunch] = useState("");

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [videoOffOnforBreakOrLunch, setVideoOffOnforBreakOrLunch] =
    useState(false);
  const [videoOffOnforBrb, setVideoOffOnforBrb] = useState(false);
  const [lunchOrBreakStartTime, setLunchOrBreakStartTime] = useState("");

  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const socketId = useSelector(
    (state: RootState) => state.userDetails.socketId
  );

  const isAudioFile = useSelector(
    (state: RootState) => state.audioVideoFileReducer.isAudioFile
  );

  const viewMode = useSelector((state: RootState) => state.roomsData.viewMode);
  const environments = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  );
  const screenControl = environments.REACT_APP_SCREENCONTROL
    ? environments.REACT_APP_SCREENCONTROL.status
    : REACT_APP_SCREENCONTROL;

  const AgentCameraAccess = environments.OTHER_CAMERA_ACCESS_TO_SUPERVISOR
    ? environments.OTHER_CAMERA_ACCESS_TO_SUPERVISOR.status
    : OTHER_CAMERA_ACCESS_TO_SUPERVISOR;

  const supervisorCanObserve = environments.SUPERVISOR_CAN_OBSERVE
    ? environments.SUPERVISOR_CAN_OBSERVE.status
    : SUPERVISOR_CAN_OBSERVE;

  const AgentScreenAccess = environments.AGENT_SCREENS_ACCESS
    ? environments.AGENT_SCREENS_ACCESS.status
    : AGENT_SCREENS_ACCESS;

  const AgentMicAccess = environments.AGENT_MIC_ACCESS
    ? environments.AGENT_MIC_ACCESS.status
    : AGENT_MIC_ACCESS;

  const agentCamAlwaysOn = environments.AGENT_CAM_ALWAYS_ON
    ? environments.AGENT_CAM_ALWAYS_ON.status
    : AGENT_CAM_ALWAYS_ON;

  const blurOption = environments.VIDEO_BACKGROUND_BLUR
    ? environments.VIDEO_BACKGROUND_BLUR.status
    : VIDEO_BACKGROUND_BLUR;

  const customBgImg = environments.VIDEO_BACKGROUND_IMAGES
    ? environments.VIDEO_BACKGROUND_IMAGES.status
    : VIDEO_BACKGROUND_IMAGES;

  var lunchBreakTimeInterval: any = useRef();

  useEffect(() => {
    let networkMonitoringInterval: NodeJS.Timeout;
    if (id === socketId) {
      networkMonitoringInterval = setInterval(() => {
        MeasureConnectionSpeed();
      }, 15000);
    }

    const MeasureConnectionSpeed = () => {
      var imageAddr = "https://eu.httpbin.org/stream-bytes/500000";

      var downloadSize = 500000; //bytes

      let startTime: number, endTime: number;
      startTime = new Date().getTime();
      let cacheBuster = "?nnn=" + startTime;
      fetch(imageAddr + cacheBuster, {
        credentials: "include",
        cache: "no-store",
        mode: "no-cors",
      })
        .then((response) => {
          endTime = new Date().getTime();
          showResults();
        })
        .catch((e) => {
          setNetworkStrength("WEAK");
        });

      function showResults() {
        let duration: number = (endTime - startTime) / 1000;
        let bitsLoaded: number = downloadSize * 8;
        let speedBps = (bitsLoaded / duration).toFixed(2);
        let speedKbps = (parseInt(speedBps) / 1024).toFixed(2);
        let speedMbps = (parseInt(speedKbps) / 1024).toFixed(2);
        if (parseInt(speedMbps) < 2.5) {
          setNetworkStrength("WEAK");
        } else if (parseInt(speedMbps) < 7.5) {
          setNetworkStrength("OKAY");
        } else if (parseInt(speedMbps) < 12.5) {
          setNetworkStrength("GREAT");
        } else {
          setNetworkStrength("EXCELLENT");
        }
      }
    };
    return () => {
      clearInterval(networkMonitoringInterval);
    };
  }, [id, socketId]);

  const navigate = useNavigate();

  useEffect(() => {
    if (id === socketId && currentUserRole === "Agent") {
      socketInstance.sendMessage("NETWORK_STATUS", {
        networkStrength,
        peerSessionId: socketId,
      });
    }
  }, [currentUserRole, id, networkStrength, socketId]);

  const goToDashboard = () => {
    dispatch(clearTempRoomUser());
    navigate("/dashboard");
  };

  const userName = useSelector(
    (state: RootState) => state.userDetails.userName
  );

  const firstName = useSelector(
    (state: RootState) => state.userDetails.firstName
  );

  const openModal = async () => {
    setExportModal(exportModal ? false : true);
    setShowDropdown(false);
  };

  const openDropdown = async () => {
    if (!showDropdown) {
      socketInstance.sendMessage("FEEDBACK_IMG_UPLOAD", {
        peerSessionId: id,
        parentRole: currentUserRole,
        parentUsername: userName,
      });
    }
    setShowDropdown((value) => !value);
  };

  const handleSubmit = (feedbackValue: string) => {
    socketInstance.sendMessage("FEEDBACK_IMG_UPLOAD_API", {
      peerSessionId: id,
      comment: feedbackValue,
    });
    setExportModal(false);
    setShowDropdown(false);
  };

  function handleClickOutside(event: any) {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as any).contains(event.target)
    ) {
      setShowDropdown(false);
    }
  }

  const generateControlId = () => {
    dispatch(setControlId(id));
    socketInstance.sendMessage("SENDING_REQUEST_FOR_CONTROL_SCREEN", {
      openentPeerSessionId: id,
      userName: userName,
      openentUserName: props.peerUserName,
      peerSessionId: socketId,
    });
  };

  const handleStopObserveAgent = (
    openentObserveeAudioId: any,
    peerSessionId: any,
    openentObserverAudioId?: string
  ) => {
    if (
      (openentObserveeAudioId !== "" &&
        openentObserveeAudioId !== peerSessionId) ||
      (openentObserverAudioId !== "" && openentObserveeAudioId === "")
    ) {
      console.log("inside stop");
      return;
    }

    if (openentObserveeAudioId === peerSessionId) {
      socketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
        roomname: roomname,
        peerSessionId: peerSessionId,
        from: "stop",
      });
    } else {
      socketInstance.sendMessage("OBSERVE_AUDIO_AGENT", {
        roomname: roomname,
        peerSessionId: peerSessionId,
      });
    }
  };

  const roomsData = useSelector((state: RootState) => state.roomsData.rooms);
  const reconnectionStatus = useSelector(
    (state: RootState) => state.reconnectionReducer.reconnectionStatus
  );

  const [roomType, setRoomType] = useState("");

  const findRoomType = () => {
    for (let room of roomsData) {
      if (room.roomname === roomname) {
        setRoomType(room.roomtype);
      }
    }
  };

  useEffect(() => {
    findRoomType();
  });

  useEffect(() => {
    if (reconnectionStatus) {
      setExportModal(false);
      setShowDropdown(false);
      setPosValue({ x: 0, y: 0 });
      setIsUploadModalOpen(false);

      console.log("reconnection status in participant card");

      if (emojiForIcon !== "") {
        handleEmojiForIcon(emojiForIcon);
      }

      if (emojiForHandAndLunch !== "") {
        handleEmojiForHandAndLunch(emojiForHandAndLunch);
      }

      dispatch(setReconnectionStatus(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reconnectionStatus]);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside, true);
      setVideoOffOnforBreakOrLunch(false);
      setVideoOffOnforBrb(false);
    };
  }, []);

  // Handling Smile,clap etc
  const handleEmojiForIcon = (value: string) => {
    clearInterval(lunchBreakTimeInterval.current);
    if (value === "BERIGHTBACK") {
      let tempTime = "";
      if (lunchOrBreakStartTime === "") {
        tempTime = new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });

        setLunchOrBreakStartTime(tempTime);
      } else {
        tempTime = lunchOrBreakStartTime;
      }
      let timeDiff = getMinuteDifference(tempTime);

      socketInstance.sendMessage("EMOJI_FOR_ICON", {
        roomname: roomname,
        type: value,
        time: timeDiff,
        flag: "start",
        peerSessionId: socketId,
      });

      lunchBreakTimeInterval.current = window.setInterval(async () => {
        let timeDiff1 = getMinuteDifference(tempTime);
        await socketInstance.sendMessage("UPDATE_TIMER_LUNCH_BREAK_BRB", {
          roomname: roomname,
          type: value,
          time: timeDiff1,
          peerSessionId: socketId,
        });
      }, 60000);
    } else {
      let flag = "";
      if (value === "endBrb") {
        flag = value;
        value = "";

        setLunchOrBreakStartTime("");
      }
      socketInstance.sendMessage("EMOJI_FOR_ICON", {
        roomname: roomname,
        type: value,
        time: "",
        flag,
        peerSessionId: socketId,
      });
    }
    setEmojiForIcon(value);

    if (value === "BERIGHTBACK") {
      if (!videoPaused) {
        setVideoOffOnforBrb(true);
        pauseUnpauseVideo(socketId);
      }
    } else if (videoOffOnforBrb) {
      setVideoOffOnforBrb(false);
      pauseUnpauseVideo(socketId);
    }
  };

  // Handling Lunch, Break and handRaised etc
  const handleEmojiForHandAndLunch = async (value: string) => {
    clearInterval(lunchBreakTimeInterval.current);

    setEmojiForHandAndLunch(value);
    if (value === "BREAK" || value === "LUNCH") {
      let tempTime = "";
      if (lunchOrBreakStartTime === "") {
        tempTime = new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
        setLunchOrBreakStartTime(tempTime);
      } else {
        tempTime = lunchOrBreakStartTime;
      }
      let timeDiff = getMinuteDifference(tempTime);
      await socketInstance.sendMessage("EMOJI_FOR_HANDANDLUNCH", {
        roomname: roomname,
        type: value,
        time: timeDiff,
        flag: "start",
        peerSessionId: socketId,
      });

      lunchBreakTimeInterval.current = window.setInterval(async () => {
        let timeDiff1 = getMinuteDifference(tempTime);
        await socketInstance.sendMessage("UPDATE_TIMER_LUNCH_BREAK_BRB", {
          roomname: roomname,
          type: value,
          time: timeDiff1,
          peerSessionId: socketId,
        });
      }, 60000);
    } else {
      let flag = "";
      if (value === "endBREAK" || value === "endLUNCH") {
        flag = value;
        value = "";

        setLunchOrBreakStartTime("");
      }
      await socketInstance.sendMessage("EMOJI_FOR_HANDANDLUNCH", {
        roomname: roomname,
        type: value,
        time: "",
        flag,
        peerSessionId: socketId,
      });
      if (socketId) {
// @ts-ignore
        props.handleHandraise(socketId)
      }
    }

    if (value === "BREAK" || value === "LUNCH") {
      if (!videoPaused) {
        setVideoOffOnforBreakOrLunch(true);
        pauseUnpauseVideo(socketId);
      }
    } else if (videoOffOnforBreakOrLunch) {
      setVideoOffOnforBreakOrLunch(false);
      pauseUnpauseVideo(socketId);
    }
  };

  const handleUploadModal = () => {
    if (isScreenAudioShared) {
      toast(
        `Screen share already using system audio. To share audio, stop screen share or share screen without audio.`
      );
    } else {
      setIsUploadModalOpen((value) => !value);
    }
  };

  const handleDropDownVideo = (e: any) => {
    if (!props.tflite) {
      toast(
        "There is some issue with background modal loading. Please refresh page."
      );
      return;
    }
    if (e === "virtual") {
      dispatch(backgroundModalOpen());
      return;
    }
    dispatch(setVideoOption(e));
    dispatch(setCameraBackground(userName, e, ""));
  };

  return (
    <>
      <ul className="control-icons">
        {videoStream &&
          emojiForHandAndLunchPeer === "" &&
          shouldShowExitButton &&
          ((!agentCamAlwaysOn &&
            checkForSupManAdmin(currentUserRole, "notEqualTo")) ||
            (checkForSupManAdmin(currentUserRole, "equalTo") &&
              shouldShowExitButton)) ? (
          <li
            title={videoPaused ? "Turn Video on" : "Turn Video off"}
            onClick={() => pauseUnpauseVideo(id)}
          >
            <FontAwesomeIcon icon={videoPaused ? faVideoSlash : faVideo} />
          </li>
        ) : null}

        {videoStream && AgentCameraAccess && !shouldShowExitButton ? (
          <li
            title={videoPaused ? "Turn Video on" : "Turn Video off"}
            onClick={() => pauseUnpauseVideo(id)}
          >
            <FontAwesomeIcon icon={videoPaused ? faVideoSlash : faVideo} />
          </li>
        ) : null}

        {!videoPaused &&
          videoStream &&
          shouldShowExitButton &&
          (blurOption || customBgImg) ? (
          <li
            title={"Video Option"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "20px",
            }}
          >
            <DropdownButton
              as={ButtonGroup}
              style={{ background: "none", margin: "0" }}
              id={`drop_blur`}
              drop={"up"}
              variant="secondary"
              title={``}
              onSelect={handleDropDownVideo}
            >
              {(blurOption || customBgImg) && (
                <Dropdown.Item eventKey="normal">Normal</Dropdown.Item>
              )}
              {blurOption && (
                <Dropdown.Item eventKey="blur">Blur</Dropdown.Item>
              )}
              {customBgImg && (
                <Dropdown.Item eventKey="virtual">
                  Virtual Background
                </Dropdown.Item>
              )}
            </DropdownButton>
          </li>
        ) : null}

        {audioStream && shouldShowExitButton && (audioAllowedInRoom) ? (
          <li
            title={audioPaused ? "Turn Mic on" : "Turn Mic off"}
            onClick={() => pauseUnPauseAudio(id)}
          >
            <FontAwesomeIcon
              icon={audioPaused ? faMicrophoneSlash : faMicrophone}
            />
          </li>
        ) : null}

        {audioStream && AgentMicAccess && !shouldShowExitButton && (audioAllowedInRoom) ? (
          <li
            title={audioPaused ? "Turn Mic on" : "Turn Mic off"}
            onClick={() => pauseUnPauseAudio(id)}
          >
            <FontAwesomeIcon
              icon={audioPaused ? faMicrophoneSlash : faMicrophone}
            />
          </li>
        ) : null}

        {(shareUnshareScreen &&
          emojiForHandAndLunchPeer === "" &&
          AgentScreenAccess) ||
          shouldShowExitButton ? (
          <li
            title={screenStopped ? "Share screen" : "Unshare screen"}
            //@ts-ignore
            onClick={() => shareUnshareScreen(id)}
          >
            <FontAwesomeIcon
              color={screenStopped ? "" : "green"}
              icon={screenStopped ? faDesktop : faDesktop}
            />
          </li>
        ) : null}
        {(!screenStopped || !screenTwoStopped) &&
          !screenControl &&
          ((shareUnshareScreenTwo &&
            emojiForHandAndLunchPeer === "" &&
            AgentScreenAccess &&
            viewMode !== roomViewModeConst.collabMode) ||
            (shouldShowExitButton && shareUnshareScreenTwo && viewMode !== roomViewModeConst.collabMode)) ? (
          <div style={{ position: "relative", display: "flex" }}>
            <li
              title={screenTwoStopped ? "Share screen2" : "Unshare screen2"}
              onClick={() => shareUnshareScreenTwo(id)}
            >
              <div className="screen-num">2</div>
              <FontAwesomeIcon
                className="screen-two-icon"
                color={screenTwoStopped ? "" : "green"}
                icon={screenTwoStopped ? faDesktop : faDesktop}
              />
            </li>
          </div>
        ) : null}

        {screenControl &&
          checkForSupManAdmin(currentUserRole, "equalTo") &&
          shareUnshareScreen &&
          !screenStopped &&
          id !== socketId ? (
          <li
            title={screenStopped ? "Control screen" : ""}
            onClick={(e) => {
              generateControlId();
            }}
          >
            <FontAwesomeIcon
              color={screenStopped ? "" : "green"}
              icon={faMouse}
            />
          </li>
        ) : null}

        {shouldShowSwapScreen && viewMode !== roomViewModeConst.collabMode ? (
          <div>
            {swapScreen && !shouldShowExitButton && (
              <li
                onClick={() => swapScreen(id)}
                title={"Swap camera with Screen"}
              >
                <FontAwesomeIcon icon={faCamera} />
              </li>
            )}
          </div>
        ) : null}

        {/* Hand Raise */}
        {(checkForSupManAdmin(currentUserRole, "notEqualTo") && viewMode === roomViewModeConst.collabMode)? (
          <li
            onClick={() =>
              handleEmojiForHandAndLunch(
                emojiForHandAndLunch === "HANDRAISED" ? "" : "HANDRAISED"
              )
            }
            title={
              emojiForHandAndLunch === "HANDRAISED"
                ? "Unraise hand"
                : "Raise hand"
            }
          >
            {emojiForHandAndLunch === "HANDRAISED" ? (
              <FontAwesomeIcon icon={faHandPaper} color="red" />
            ) : (
              <FontAwesomeIcon icon={faHandPaper} />
            )}
          </li>
        ) : null}

        {/* Smile && unsmile */}
        {(checkForSupManAdmin(currentUserRole, "notEqualTo") ||
          roomType === "meeting") &&
          shouldShowExitButton ? (
          <li
            onClick={() =>
              handleEmojiForIcon(emojiForIcon === "SMILE" ? "" : "SMILE")
            }
            title={emojiForIcon === "SMILE" ? "UnSmile" : "Smile"}
          >
            {emojiForIcon === "SMILE" ? (
              <FontAwesomeIcon icon={faSmile} color="red" />
            ) : (
              <FontAwesomeIcon icon={faSmile} />
            )}
          </li>
        ) : null}

        {/* can't hear you && can hear you */}
        {(checkForSupManAdmin(currentUserRole, "notEqualTo") ||
          roomType === "meeting") &&
          shouldShowExitButton ? (
          <li
            onClick={() =>
              handleEmojiForIcon(
                emojiForIcon === "CANNOTHEAR" ? "" : "CANNOTHEAR"
              )
            }
            title={
              emojiForIcon === "CANNOTHEAR"
                ? "Can Hear you!"
                : "Can't Hear you?"
            }
          >
            {emojiForIcon === "CANNOTHEAR" ? (
              <FontAwesomeIcon icon={faDeaf} color="red" />
            ) : (
              <FontAwesomeIcon icon={faDeaf} />
            )}
          </li>
        ) : null}

        {/* Be Right Back */}
        {(checkForSupManAdmin(currentUserRole, "notEqualTo") ||
          roomType === "meeting" ||
          roomType === "supervisor") &&
          shouldShowExitButton ? (
          <li
            onClick={() =>
              handleEmojiForIcon(
                emojiForIcon === "BERIGHTBACK" ? "backFromBrb" : "BERIGHTBACK"
              )
            }
            title={
              emojiForIcon === "BERIGHTBACK" ? "Be Right Back" : "Be Right Back"
            }
          >
            {emojiForIcon === "BERIGHTBACK" ? (
              <FontAwesomeIcon icon={faStopwatch20} color="red" />
            ) : (
              <FontAwesomeIcon icon={faStopwatch20} />
            )}
          </li>
        ) : null}

        {/* Message Yes */}
        {(checkForSupManAdmin(currentUserRole, "notEqualTo") ||
          roomType === "meeting") &&
          shouldShowExitButton ? (
          <li
            onClick={() =>
              handleEmojiForIcon(emojiForIcon === "YES" ? "" : "YES")
            }
            title={emojiForIcon === "YES" ? "!Yes" : "Yes"}
          >
            {emojiForIcon === "YES" ? (
              <FontAwesomeIcon icon={faThumbsUp} color="red" />
            ) : (
              <FontAwesomeIcon icon={faThumbsUp} />
            )}
          </li>
        ) : null}

        {/* No message */}
        {(checkForSupManAdmin(currentUserRole, "notEqualTo") ||
          roomType === "meeting") &&
          shouldShowExitButton ? (
          <li
            onClick={() =>
              handleEmojiForIcon(emojiForIcon === "NO" ? "" : "NO")
            }
            title={emojiForIcon === "NO" ? "!no" : "No"}
          >
            {emojiForIcon === "NO" ? (
              <FontAwesomeIcon icon={faThumbsDown} color="red" />
            ) : (
              <FontAwesomeIcon icon={faThumbsDown} />
            )}
          </li>
        ) : null}

        {/* Clap or unclap */}
        {(checkForSupManAdmin(currentUserRole, "notEqualTo") ||
          roomType === "meeting") &&
          shouldShowExitButton ? (
          <li
            onClick={() =>
              handleEmojiForIcon(emojiForIcon === "CLAP" ? "" : "CLAP")
            }
            title={emojiForIcon === "CLAP" ? "!Clap" : "Clap"}
          >
            {emojiForIcon === "CLAP" ? (
              <FontAwesomeIcon icon={faSignLanguage} color="red" />
            ) : (
              <FontAwesomeIcon icon={faSignLanguage} />
            )}
          </li>
        ) : null}

        {/* Lunch */}
        {(checkForSupManAdmin(currentUserRole, "notEqualTo") ||
          roomType === "meeting" ||
          roomType === "supervisor") &&
          shouldShowExitButton ? (
          <li
            onClick={() =>
              handleEmojiForHandAndLunch(
                emojiForHandAndLunch === "LUNCh" ? "" : "LUNCH"
              )
            }
            title={emojiForHandAndLunch === "LUNCH" ? "!Lunch" : "Lunch"}
          >
            {emojiForHandAndLunch === "LUNCH" ? (
              <FontAwesomeIcon icon={faPizzaSlice} color="red" />
            ) : (
              <FontAwesomeIcon icon={faPizzaSlice} />
            )}
          </li>
        ) : null}

        {/* Break */}
        {(checkForSupManAdmin(currentUserRole, "notEqualTo") ||
          roomType === "meeting" ||
          roomType === "supervisor") &&
          shouldShowExitButton ? (
          <li
            onClick={() =>
              handleEmojiForHandAndLunch(
                emojiForHandAndLunch === "BREAK" ? "" : "BREAK"
              )
            }
            title={emojiForHandAndLunch === "BREAK" ? "!Break" : "Break"}
          >
            {emojiForHandAndLunch === "BREAK" ? (
              <FontAwesomeIcon icon={faBell} color="red" />
            ) : (
              <FontAwesomeIcon icon={faBell} />
            )}
          </li>
        ) : null}

        {/* Technical issue */}
        {(checkForSupManAdmin(currentUserRole, "notEqualTo") ||
          roomType === "meeting" ||
          roomType === "supervisor") &&
          shouldShowExitButton ? (
          <li
            onClick={() =>
              handleEmojiForIcon(
                emojiForIcon === "TECHNICALISSUE" ? "" : "TECHNICALISSUE"
              )
            }
            title={
              emojiForIcon === "TECHNICALISSUE"
                ? "Technical issue"
                : "Technical issue"
            }
          >
            {emojiForIcon === "TECHNICALISSUE" ? (
              <FontAwesomeIcon icon={faCogs} color="red" />
            ) : (
              <FontAwesomeIcon icon={faCogs} />
            )}
          </li>
        ) : null}

        {addStreamOfAudioFile &&
          checkForSupManAdmin(currentUserRole, "equalTo") &&
          shouldShowExitButton ? (
          <div style={{ position: "relative", display: "flex" }}>
            <li
              title={"Upload video and audio"}
              onClick={() => handleUploadModal()}
            >
              <FontAwesomeIcon
                className="screen-two-icon"
                color={!isAudioFile ? "" : "green"}
                icon={faUpload}
              />
            </li>
          </div>
        ) : null}

        {id === socketId ? (
          <div style={{ position: "relative", display: "flex" }}>
            <li
              title="Activity"
              onClick={() => dispatch(setActivityModalOpen(true))}
            >
              <FontAwesomeIcon icon={faImage} />
            </li>
          </div>
        ) : null}

        {role === "Agent" &&
          checkForSupManAdmin(currentUserRole, "equalTo") &&
          !props.isOwnPrivateCallOn &&
          !props.isCallModal &&
          props.audioPaused &&
          !props.peerIsCallModal &&
          !props.isPrivateCallOn &&
          supervisorCanObserve ? (
          <li
            onClick={(e: any) => {
              e.preventDefault();
              handleStopObserveAgent(
                props.openentObserveeAudioId,
                id,
                props.peerOpenentObserverAudioId
              );
            }}
            title={"Observe"}
            id="observerBtn"
          >
            {props.openentObserveeAudioId === id ? (
              <FontAwesomeIcon icon={faBinoculars} color="green" />
            ) : props.openentObserveeAudioId !== "" &&
              props.openentObserveeAudioId !== id ? (
              <FontAwesomeIcon icon={faBinoculars} color="red" />
            ) : props.peerOpenentObserverAudioId !== "" ? (
              <FontAwesomeIcon icon={faBinoculars} color="red" />
            ) : (
              <FontAwesomeIcon icon={faBinoculars} color="black" />
            )}
          </li>
        ) : null}

        {
          // shouldShowControls
          false &&
          (viewMode === roomViewModeConst.collabMode ? (
            <li title="Switch to lecture mode" onClick={changeViewMode}>
              <FontAwesomeIcon icon={faUsers} />
            </li>
          ) : (
            <li title="Switch to collaboration mode" onClick={changeViewMode}>
              <FontAwesomeIcon icon={faUser} />
            </li>
          ))
        }

        {checkForSupManAdmin(currentUserRole, "equalTo") &&
          shouldShowExitButton ? (
          <li
            title="Open Whiteboard"
            onClick={async () => {
              socketInstance.sendMessage("SEND_CHAT_MESSAGE", {
                message: `${window.location.protocol}//${window.location.hostname === "localhost"
                    ? window.location.hostname + ":3000"
                    : window.location.hostname
                  }/room/whiteboard/${roomname}`,
                chatType: "EVERYONE",
                senderUsername: userName,
                receiverUsername: null,
                receiverSessionId: null,
                receiverName: null,
                senderName: firstName,
              });

              await socketInstance.sendMessage("NEW_WHITEBOARD", {
                roomname: roomname,
              });

              // window.open(`/room/whiteboard/${roomname}`,"_blank");
            }}
          >
            <FontAwesomeIcon icon={faChalkboardTeacher} />
          </li>
        ) : null}

        {shouldShowExitButton && (
          <li
            className="logout"
            onClick={goToDashboard}
            title="Go to dashboard"
          >
            <img
              src={LogoutImage}
              alt="Go to dashboard"
              width="15"
              height="15"
              className="logout-image"
            />
          </li>
        )}
        {/* {(isPrivateCallButtonVisible && isOwnPrivateCallOn && isPrivateCallOn) ||
      (isPrivateCallButtonVisible &&
        !isOwnPrivateCallOn &&
        !isPrivateCallOn) ? (
        <li
          title={isPrivateCallOn ? "End private call" : "Make a private call"}
        >
          {isPrivateCallOn ? (
            <FontAwesomeIcon
              icon={faPhoneSlash}
              onClick={() => privateCall(id)}
            />
          ) : (
            <FontAwesomeIcon icon={faPhone} onClick={() => privateCall(id)} />
          )}
        </li>
      ) : null} */}

        {shouldShowSignalStrength && id === socketId && (
          <li title="Signal strength">
            {/* @ts-ignore */}
            <SignalStrengthIndicator strength={networkStrength} />
          </li>
        )}

        {emojiForIconPeer === "SMILE" ? (
          <li title={"Smile"}>
            <FontAwesomeIcon icon={faSmile} color="green" />
          </li>
        ) : null}

        {emojiForIconPeer === "CANNOTHEAR" ? (
          <li title={"Can hear you"}>
            <FontAwesomeIcon icon={faDeaf} color="green" />
          </li>
        ) : null}

        {emojiForIconPeer === "CLAP" ? (
          <li title={"Clap"}>
            <FontAwesomeIcon icon={faSignLanguage} color="green" />
          </li>
        ) : null}

        {emojiForIconPeer === "BERIGHTBACK" ? (
          <li title={"Be Right Back"}>
            <FontAwesomeIcon icon={faStopwatch20} color="green" />
          </li>
        ) : null}

        {emojiForIconPeer === "YES" ? (
          <li title={"Yes"}>
            <FontAwesomeIcon icon={faThumbsUp} color="green" />
          </li>
        ) : null}

        {emojiForIconPeer === "NO" ? (
          <li title={"No"}>
            <FontAwesomeIcon icon={faThumbsDown} color="green" />
          </li>
        ) : null}

        {emojiForIconPeer === "TECHNICALISSUE" ? (
          <li title={"TECHNICAL ISSUE"}>
            <FontAwesomeIcon icon={faCogs} color="green" />
          </li>
        ) : null}

        {emojiForHandAndLunchPeer === "LUNCH" ? (
          <li title={"On Lunch"}>
            <FontAwesomeIcon icon={faPizzaSlice} color="green" />
          </li>
        ) : null}

        {emojiForHandAndLunchPeer === "BREAK" ? (
          <li title={"On Break"}>
            <FontAwesomeIcon icon={faBell} color="green" />
          </li>
        ) : null}

        {checkForSupManAdmin(currentUserRole, "equalTo") &&
          role === "Agent" &&
          !videoPaused ? (
          <li
            title={"Feedback"}
            onClick={(e) => {
              openDropdown();
              setPosValue({ x: e.clientX, y: e.clientY });
            }}
            className="dropdown"
          >
            <FontAwesomeIcon
              icon={faComments}
              color="black"
              className="dropbtn"
            />
            <div ref={wrapperRef} className="dropdown-content">
              {showDropdown ? (
                <>
                  {data.map((m) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSubmit(m);
                      }}
                    >
                      {m}
                    </button>
                  ))}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openModal();
                    }}
                  >
                    Other
                  </button>
                </>
              ) : null}
            </div>
          </li>
        ) : null}
      </ul>

      {exportModal ? (
        <FeedbackModal
          show={exportModal}
          handleShow={setExportModal}
          handleSubmit={handleSubmit}
          handleDropdown={setShowDropdown}
          posValue={posValue}
        />
      ) : null}

      {emojiForHandAndLunch !== "" || emojiForIcon !== "" ? (
        <LunchBreakModal
          lunchAndBreak={emojiForHandAndLunch}
          handleForLunchAndBreak={handleEmojiForHandAndLunch}
          currentUserName={userName}
          emojisEvents={emojiForIcon}
          handleForEmojis={handleEmojiForIcon}
        />
      ) : null}

      {isUploadModalOpen ? (
        <ModalAudioVideo
          show={isUploadModalOpen}
          handleShow={handleUploadModal}
          shareUnshareScreen={shareUnshareScreen}
          peerId={id}
          addStreamOfAudioFile={addStreamOfAudioFile}
          pauseUnPauseAudio={pauseUnPauseAudio}
          isAudioPaused={audioPaused}
          addStreamOfVideoFile={addStreamOfVideoFile}
          localAudioStream={audioStream}
          localVideoStream={props.localScreenStream}
        />
      ) : null}
    </>
  );
};

const ParticipantCard = React.memo((props: IParticipantCard) => {
  const {
    name,
    role,
    audioPaused = false,
    videoPaused = false,
    pauseUnpauseVideo,
    pauseUnPauseAudio,
    id,
    videoStream,
    showVideo = false,
    swapScreen,
    shareUnshareScreen,
    shareUnshareScreenTwo,
    shouldShowSwapScreen = false,
    smallRightVideo = undefined,
    smallLeftVideo = undefined,
    screenStopped = true,
    screenTwoStopped = true,
    alignControlsToRight = false,
    privateCallPeerOne = "",
    audioStream,
    shouldShowRole = true,
    shouldShowAvatar = false,
    shouldShowNameRole = false,
    shouldShowExitButton = false,
    shouldShowControls = false,
    changeViewMode,

    isPrivateCallButtonVisible = false,
    makePrivateCall,
    isPrivateCallOn = false,
    shouldShowSignalStrength = false,
    signalStrength = "EXCELLENT",
    isFaceDetectionEnabled = false,
    islocalVideo = false,
    roomname,
    isOwnPrivateCallOn,
    emojiForIconPeer = "",
    emojiForHandAndLunchPeer = "",
    addStreamOfAudioFile,
    addStreamOfVideoFile,
    localScreenStream,
    isCallModal,
    openentObserveeAudioId,
    peerOpenentObserverAudioId,
    peerIsCallModal,
    isScreenAudioShared,
    audioAllowedInRoom,
  } = props;

  const [sourcePlayback, setSourcePlayback] = useState<SourcePlayback>();

  const isBackgroundModalOpen = useSelector(
    (state: RootState) => state.backgroundReducer.isBackgroundModalOpen
  );
  const currentUserRole = useSelector(
    (state: RootState) => state.userDetails.role
  );

  const videoOption = useSelector(
    (state: RootState) => state.videoOption.videoOption
  );

  const viewMode = useSelector((state: RootState) => state.roomsData.viewMode);

  const {
    // pipeline,
    // backgroundImageRef,
    canvasRef,
    canvasDisplayRef,
    // fps,
    // durations: [resizingDuration, inferenceDuration, postProcessingDuration],
  } = useRenderingPipeline(
    // @ts-ignore
    sourcePlayback,
    props.segmentationConfig,
    props.tflite,
    showVideo
  );

  let videoClassName =
    videoOption === "blur" || videoOption === "background" ? "none" : "";

  return (
    <div className="participant-card-container">
      {viewMode === roomViewModeConst.lectureMode ? (
        <div className={`participant-details-${currentUserRole}`}>
          {showVideo && videoStream ? (
            [
              <Video
                id={id}
                name={name}
                srcObject={videoStream}
                className={
                  isFaceDetectionEnabled && islocalVideo
                    ? `face-detection-node local-video small-participant-video white-border ${videoClassName}`
                    : `local-video small-participant-video  white-border ${videoClassName}`
                }
                onLoad={setSourcePlayback}
              />,
              (videoOption === "blur" || videoOption === "background") &&
              props.segmentationConfig && (
                <>
                  <canvas
                    // The key attribute is required to create a new canvas when switching
                    // context mode
                    key={props.segmentationConfig.pipeline}
                    ref={canvasDisplayRef}
                  />
                </>
              ),
            ]
          ) : shouldShowAvatar ? (
            <NameAvatar
              text={name}
              className={`${currentUserRole}-screen-avatar`}
            />
          ) : null}
        </div>
      ) : null}
      {(alignControlsToRight || smallRightVideo) && (
        <div
          //Changed by SM on 1/28/2020 to make width be 30%
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
          className=" audio-control flex height100"
        >
          {shouldShowNameRole ? (
            <div className={`participant-name-type-${currentUserRole}`}>
              {emojiForHandAndLunchPeer === "HANDRAISED" ? (
                <h6 className="participant-name red">
                  {name}
                  {privateCallPeerOne && (
                    <>
                      <FontAwesomeIcon icon={faPhoneVolume} />
                      {privateCallPeerOne}
                    </>
                  )}
                </h6>
              ) : (
                <h6 className="participant-name">
                  {name}
                  {"    "}
                  {privateCallPeerOne && (
                    <>
                      <FontAwesomeIcon icon={faPhoneVolume} />
                      {"    "}
                      {privateCallPeerOne}
                    </>
                  )}
                </h6>
              )}
              {shouldShowRole && <p className="participant-type">{role}</p>}
            </div>
          ) : null}
          {alignControlsToRight && (
            <Controls
              isPrivateCallButtonVisible={isPrivateCallButtonVisible}
              audioPaused={audioPaused}
              videoPaused={videoPaused}
              pauseUnpauseVideo={pauseUnpauseVideo}
              pauseUnPauseAudio={pauseUnPauseAudio}
              addStreamOfAudioFile={addStreamOfAudioFile}
              addStreamOfVideoFile={addStreamOfVideoFile}
              id={id}
              showVideo={showVideo}
              swapScreen={swapScreen}
              shareUnshareScreen={shareUnshareScreen}
              shareUnshareScreenTwo={shareUnshareScreenTwo}
              shouldShowExitButton={shouldShowExitButton}
              shouldShowControls={shouldShowControls}
              changeViewMode={changeViewMode}
              shouldShowSwapScreen={shouldShowSwapScreen}
              smallRightVideo={smallRightVideo}
              smallLeftVideo={smallLeftVideo}
              screenStopped={screenStopped}
              screenTwoStopped={screenTwoStopped}
              alignControlsToRight={alignControlsToRight}
              audioStream={audioStream}
              videoStream={videoStream}
              makePrivateCall={makePrivateCall}
              isPrivateCallOn={isPrivateCallOn}
              shouldShowSignalStrength={shouldShowSignalStrength}
              signalStrength={signalStrength}
              roomname={roomname}
              currentUserRole={currentUserRole}
              isOwnPrivateCallOn={isOwnPrivateCallOn}
              role={role}
              peerUserName={props.peerUserName}
              emojiForIconPeer={emojiForIconPeer}
              emojiForHandAndLunchPeer={emojiForHandAndLunchPeer}
              localScreenStream={localScreenStream}
              isCallModal={isCallModal}
              openentObserveeAudioId={openentObserveeAudioId}
              peerOpenentObserverAudioId={peerOpenentObserverAudioId}
              peerIsCallModal={peerIsCallModal}
              tflite={props.tflite}
              isScreenAudioShared={isScreenAudioShared}
              audioAllowedInRoom={audioAllowedInRoom}
              handleHandraise={props.handleHandraise}
            />
          )}
          {smallLeftVideo && (
            <Video
              id={id}
              name={name}
              srcObject={smallLeftVideo}
              className={
                isFaceDetectionEnabled && islocalVideo
                  ? `face-detection-node small-right-video white-border`
                  : `small-right-video white-border`
              }
            />
          )}

          {smallRightVideo && (
            <Video
              id={id}
              name={name}
              srcObject={smallRightVideo}
              className={
                isFaceDetectionEnabled && islocalVideo
                  ? `face-detection-node small-right-video white-border`
                  : `small-right-video white-border`
              }
            />
          )}
        </div>
      )}

      {!alignControlsToRight && (
        <Controls
          isPrivateCallButtonVisible={isPrivateCallButtonVisible}
          audioPaused={audioPaused}
          videoPaused={videoPaused}
          pauseUnpauseVideo={pauseUnpauseVideo}
          pauseUnPauseAudio={pauseUnPauseAudio}
          addStreamOfAudioFile={addStreamOfAudioFile}
          addStreamOfVideoFile={addStreamOfVideoFile}
          id={id}
          showVideo={showVideo}
          swapScreen={swapScreen}
          shareUnshareScreen={shareUnshareScreen}
          shareUnshareScreenTwo={shareUnshareScreenTwo}
          shouldShowSwapScreen={shouldShowSwapScreen}
          smallRightVideo={smallRightVideo}
          smallLeftVideo={smallLeftVideo}
          screenStopped={screenStopped}
          screenTwoStopped={screenTwoStopped}
          alignControlsToRight={alignControlsToRight}
          shouldShowExitButton={shouldShowExitButton}
          shouldShowControls={shouldShowControls}
          shouldShowSignalStrength={shouldShowSignalStrength}
          signalStrength={signalStrength}
          changeViewMode={changeViewMode}
          audioStream={audioStream}
          videoStream={videoStream}
          makePrivateCall={makePrivateCall}
          isPrivateCallOn={isPrivateCallOn}
          roomname={roomname}
          currentUserRole={currentUserRole}
          isOwnPrivateCallOn={isOwnPrivateCallOn}
          peerUserName={props.peerUserName}
          emojiForIconPeer={emojiForIconPeer}
          emojiForHandAndLunchPeer={emojiForHandAndLunchPeer}
          localScreenStream={localScreenStream}
          tflite={props.tflite}
          isScreenAudioShared={isScreenAudioShared}
          audioAllowedInRoom={audioAllowedInRoom}
          handleHandraise={props.handleHandraise}
        />
      )}
      <canvas id="canvas" style={{ display: "none", marginRight: 10 }}></canvas>
      {showVideo &&
        videoStream &&
        (videoOption === "blur" || videoOption === "background") &&
        props.segmentationConfig && (
          <canvas
            // The key attribute is required to create a new canvas when switching
            // context mode
            className="face-detection-canvas"
            id="blur"
            style={{ display: "none" }}
            key={props.segmentationConfig.pipeline}
            ref={canvasRef}
          // width={640}
          // height={480}
          />
        )}

      {isBackgroundModalOpen ? (
        <BackgroundModal
          canvasDisplayRef={canvasDisplayRef}
          segmentationConfig={props.segmentationConfig}
        />
      ) : null}
    </div>
  );
});

export default ParticipantCard;
