import { Dispatch } from "react";
import {
  GET_PENDING_USERS,
  GET_PENDING_USERS_FAILURE,
  SET_VERIFICATION_SUCCESS,
  SET_VERIFICATION_FAILURE,
  SET_REJECTION_SUCCESS,
  SET_REJECTION_FAILURE,
  SET_VERIFY_FACE,
  SET_RECURRENT_CHECK,
  SET_WRONG_PERSON,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_COUNT
} from "../actions/actionTypes";
import {
  getPendingVerification,
  verifyUsers,
  rejectUsers,
} from "../services/faceRegistration";
import { setLoading } from "./loaderActions";
import { toast } from "react-toastify";


export const getPendingVerificationAction = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const pendingUsersResponse = await getPendingVerification();
      if (pendingUsersResponse.success) {
        dispatch(getPendingUsersSuccess(pendingUsersResponse.users));
        dispatch(getNotificationSuccess(pendingUsersResponse?.count))
      } else {
        dispatch(
          getPendingUsersFailure(
            pendingUsersResponse.success,
            "Cannot Fetch Users"
          )
        );
        dispatch(getNotificationFailure());
      }
    } catch (err: any) {
      dispatch(getPendingUsersFailure(false, err.message));
      dispatch(getNotificationFailure());
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const getPendingUsersSuccess = (users: Array<any>) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_PENDING_USERS,
      payload: {
        users,
      },
    });
  };
};

const getPendingUsersFailure = (status: boolean, message: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_PENDING_USERS_FAILURE,
      payload: {
        status,
        message,
      },
    });
  };
};

export const verifyUsersAction = (users: Array<string>) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const verifyUsersResponse = await verifyUsers(users);
      if (verifyUsersResponse.success) {
        dispatch(setUserVerficationSuccess(verifyUsersResponse.success, users));
        toast(verifyUsersResponse?.message);
      } else {
        dispatch(setUserVerificationFailure(verifyUsersResponse.success, ""));
      }
    } catch (err: any) {
      dispatch(setUserVerificationFailure(false, err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setUserVerficationSuccess = (status: boolean, users: string[]) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_VERIFICATION_SUCCESS,
      payload: {
        status,
        users,
      },
    });
  };
};

const setUserVerificationFailure = (status: boolean, error: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_VERIFICATION_FAILURE,
      payload: {
        status,
        error,
      },
    });
  };
};

export const rejectUsersAction = (users: Array<string>) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const verifyUsersResponse = await rejectUsers(users);
      if (verifyUsersResponse.success) {
        dispatch(setUserRejectionSuccess(verifyUsersResponse.success, users));
        toast(verifyUsersResponse?.message);
      } else {
        dispatch(setUserRejectionFailure(verifyUsersResponse.success, ""));
      }
    } catch (err: any) {
      dispatch(setUserRejectionFailure(false, err.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setUserRejectionSuccess = (status: boolean, users: string[]) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_REJECTION_SUCCESS,
      payload: {
        status,
        users,
      },
    });
  };
};

const setUserRejectionFailure = (status: boolean, error: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_REJECTION_FAILURE,
      payload: {
        status,
        error,
      },
    });
  };
};

export const verifyFace = (verification: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_VERIFY_FACE,
      payload: verification,
    });
  };
};

export const setRecurrentCheck = (verification: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_RECURRENT_CHECK, payload: verification });
  };
};

export const wrongPersonCheck = (check: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_WRONG_PERSON, payload: check });
  };
};

export const getNotificationSuccess = (count: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_NOTIFICATION_SUCCESS,
      payload: {
        count: count,
        success: true,
      },
    });
  };
};

export const getNotificationFailure = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_NOTIFICATION_FAILURE,
    });
  };
};
export const updateNotificationCount = (count:number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: UPDATE_NOTIFICATION_COUNT,
      payload:{
        count
      }
    });
  };
};

// UPDATE_NOTIFICATION_COUNT