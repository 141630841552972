import {
  GET_ROOMS_SUCCESS,
  GET_ROOMS_FAILURE,
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_FAILURE,
  SET_CONNECTION_DETAILS,
  CLEAR_REDUX_DATA,
  SET_VIEW_MODE,
  SET_ROOM_LEAVE_ON_ICE_RESTART,
  SET_CURRENT_VIEW_MODE,
  SET_SCALE_RESOLUTION,
  REMOVE_SCALE_RESOLUTION,
  SET_AUDIO_ALLOWED,
  SET_PRIVATE_CALL_ALLOWED,
} from "../actions/actionTypes";

export interface scaleResultion {
  HIGH: number;
  LOW: number;
  maxBitrate: number;
}

const initialState = {
  rooms: [],
  successMessage: "",
  failureMessage: "",
  connectionDetails: {},
  viewMode: "",
  roomLeaveName: "",
  currentRoom: "",
  screenScale: {} as scaleResultion,
  videoScale: {} as scaleResultion,
  audioAllowedInRoom: true,
  allowPrivateCallinSilentRoom: true
};
interface IAction {
  type: string;
  payload: any;
}

const roomsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_ROOMS_SUCCESS: {
      return {
        ...state,
        rooms: action.payload?.rooms,
      };
    }
    case GET_ROOMS_FAILURE: {
      return {
        ...state,
        failureMessage: action.payload?.errorMessage,
      };
    }
    case SET_ROOM_LEAVE_ON_ICE_RESTART: {
      return {
        ...state,
        roomLeaveName: action.payload,
      };
    }
    case CREATE_ROOM_SUCCESS: {
      return {
        ...state,
        successMessage: "Created room successfully",
      };
    }

    case CREATE_ROOM_FAILURE: {
      return {
        ...state,
        failureMessage: action.payload,
      };
    }
    case SET_CONNECTION_DETAILS: {
      return {
        ...state,
        connectionDetails: action.payload,
      };
    }
    case SET_VIEW_MODE: {
      return {
        ...state,
        viewMode: action.payload,
      };
    }
    case SET_AUDIO_ALLOWED: {
      return {
        ...state,
        audioAllowedInRoom: action.payload,
      };
    }
    case SET_PRIVATE_CALL_ALLOWED: {
      return {
        ...state,
        allowPrivateCallinSilentRoom: action.payload,
      };
    }
    case SET_CURRENT_VIEW_MODE: {
      return {
        ...state,
        viewMode: action.payload.currentViewMode,
        currentRoom: action.payload.currentRoom,
      };
    }
    case SET_SCALE_RESOLUTION: {
      return {
        ...state,
        screenScale: {
          ...action.payload.screenScale,
          HIGH: parseFloat(action.payload.screenScale.HIGH),
          LOW: parseFloat(action.payload.screenScale.LOW),
        },
        videoScale: {
          ...action.payload.videoScale,
          HIGH: parseFloat(action.payload.screenScale.HIGH),
          LOW: parseFloat(action.payload.screenScale.LOW),
        },
      };
    }
    case REMOVE_SCALE_RESOLUTION: {
      return {
        ...state,
        screenScale: {},
        videoScale: {},
      };
    }
    case CLEAR_REDUX_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default roomsReducer;
