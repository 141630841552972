import "./styles.css";

interface IAvatarProps {
  className?: string;
  text?: string;
}

const Avatar = (props: IAvatarProps) => {
  const getInitials = (name: string) => {
    const firstLastUserNames = name.split(" ");
    const firstLastName = firstLastUserNames.filter((_, index) => index < 2);
    const initials = firstLastName.map((name) => name[0]);
    return initials.join("");
  };

  const { className = "", text = "" } = props;

  return (
    <div className={className}>
      <p className={`centered`}>{getInitials(text)}</p>
    </div>
  );
};

export default Avatar;
