"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateID = exports.subtractNailUpReferenceStats = exports.applyPatchForRTT = exports.exposeApplicationStatsForPC = exports.restoreOriginalConsoleMethods = exports.setConsoleLevel = exports.getHardwareInfo = exports.validateEvent = exports.validateRating = exports.isRoomIdOrPeerIdChanged = exports.countOfValidConnections = exports.validateConfig = exports.getConnectionData = exports.dumpStream = exports.map2obj = exports.mangleChromeStats = exports.deltaCompression = exports.getSdkLogWithoutTrace = exports.getSdkLogger = exports.logPrefix = void 0;
var GPU = require("./detect-gpu");
var stats_1 = require("./stats");
var sdk_1 = require("./sdk");
var getCurrTimeStamp = function () {
    var date = new Date();
    var dateStr = ("00" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + date.getDate()).slice(-2) +
        "/" +
        date.getFullYear() +
        " " +
        ("00" + date.getHours()).slice(-2) +
        ":" +
        ("00" + date.getMinutes()).slice(-2) +
        ":" +
        ("00" + date.getSeconds()).slice(-2) +
        ":" +
        ("000" + date.getMilliseconds()).slice(-3);
    return dateStr;
};
var logPrefix = function (level) {
    if (level === void 0) { level = "info"; }
    return level === "error"
        ? [
            "".concat(getCurrTimeStamp(), " %cwatchRTC %cERROR"),
            "background: ".concat("gold", "; color: black; padding: 2px 0.5em; border-radius: 0.5em;"),
            "background: ".concat("red", "; color: white; padding: 2px 0.5em; border-radius: 0.5em;"),
        ]
        : [
            "".concat(getCurrTimeStamp(), " %cwatchRTC"),
            "background: ".concat("gold", "; color: black; padding: 2px 0.5em; border-radius: 0.5em;"),
        ];
};
exports.logPrefix = logPrefix;
var logLevels = ["debug", "info", "error"];
var sdkLogger = function (log, getLogLevel) {
    if (log === void 0) { log = console.log; }
    return function (level, message, object) {
        var allowed = getLogLevel();
        var allowedValue = logLevels.indexOf(allowed);
        var currentValue = logLevels.indexOf(level);
        if (allowed === "silent" || currentValue < allowedValue) {
            return;
        }
        var args = __spreadArray(__spreadArray([], (0, exports.logPrefix)(level), true), [message], false);
        if (object) {
            args.push(object);
        }
        log.apply(void 0, args);
    };
};
exports.getSdkLogger = sdkLogger.bind(null, console.log);
exports.getSdkLogWithoutTrace = sdkLogger.bind(null, (_a = console._original_log) !== null && _a !== void 0 ? _a : console.log);
// apply a delta compression to the stats report. Reduces size by ~90%.
// To reduce further, report keys could be compressed.
// Muly: maybe can improve using https://github.com/AsyncBanana/microdiff?utm_source=tldrnewsletter
var deltaCompression = function (oldStats, newStats) {
    oldStats = oldStats || {};
    newStats = newStats || {};
    newStats = JSON.parse(JSON.stringify(newStats));
    Object.keys(newStats).forEach(function (id) {
        var report = newStats[id];
        delete report.id;
        if (!oldStats[id]) {
            return;
        }
        Object.keys(report).forEach(function (name) {
            if (report.timestamp && typeof report.timestamp === "string") {
                report.timestamp = new Date(report.timestamp).getTime();
            }
            if (report[name] === oldStats[id][name]) {
                delete newStats[id][name];
            }
            else if (Array.isArray(report[name])) {
                // Arrays get serialized if anything changed.
                if (JSON.stringify(report[name]) === JSON.stringify(oldStats[id][name])) {
                    delete newStats[id][name];
                }
            }
            if (Object.keys(report).length === 0) {
                delete newStats[id];
            }
            else if (Object.keys(report).length === 1 && report.timestamp) {
                delete newStats[id];
            }
        });
    });
    var timestamp = -Infinity;
    Object.keys(newStats).forEach(function (id) {
        var report = newStats[id];
        if (report.timestamp > timestamp) {
            timestamp = report.timestamp;
        }
    });
    Object.keys(newStats).forEach(function (id) {
        var report = newStats[id];
        if (report.timestamp === timestamp) {
            report.timestamp = 0;
        }
    });
    newStats.timestamp = timestamp;
    return newStats;
};
exports.deltaCompression = deltaCompression;
var mangleChromeStats = function (pc, response) {
    var standardReport = {};
    var reports = response.result();
    reports.forEach(function (report) {
        var standardStats = {
            id: report.id,
            timestamp: report.timestamp.getTime(),
            type: report.type,
        };
        report.names().forEach(function (name) {
            standardStats[name] = report.stat(name);
        });
        standardReport[standardStats.id] = standardStats;
    });
    return standardReport;
};
exports.mangleChromeStats = mangleChromeStats;
// transforms a maplike to an object. Mostly for getStats +
// JSON.parse(JSON.stringify())
var map2obj = function (m) {
    if (!m.entries) {
        return m;
    }
    var o = {};
    m.forEach(function (v, k) {
        o[k] = v;
    });
    return o;
};
exports.map2obj = map2obj;
var dumpStream = function (stream) {
    return {
        id: stream.id,
        tracks: stream.getTracks().map(function (track) {
            return {
                id: track.id,
                kind: track.kind,
                label: track.label,
                enabled: track.enabled,
                muted: track.muted,
                readyState: track.readyState, // `live` or `ended`
            };
        }),
    };
};
exports.dumpStream = dumpStream;
var getConnectionData = function (type, key, proxyURL) {
    var localUrl = "".concat(type == "ws" ? "ws" : "http", "://watchrtc-serverdev.spearline.dev");
    var stagingUrl = "".concat(type == "ws" ? "wss" : "https", "://watchrtc.spearline.dev");
    var productionUrl = "".concat(type == "ws" ? "wss" : "https", "://watchrtc-server.cyara.com");
    if (proxyURL && type === "http") {
        if (proxyURL.includes("wss://")) {
            proxyURL = proxyURL.replace("wss://", "https://");
        }
        else {
            proxyURL = proxyURL.replace("ws://", "http://");
        }
    }
    var splitted = key.split(":");
    if (key.indexOf("local") !== -1) {
        return {
            url: proxyURL || localUrl,
            key: splitted[1],
        };
    }
    else if (key.indexOf("staging") !== -1) {
        return {
            url: proxyURL || stagingUrl,
            key: splitted[1],
        };
    }
    else if (key.indexOf("production") !== -1) {
        return {
            url: proxyURL || productionUrl,
            key: splitted[1],
        };
    }
    else {
        return {
            url: proxyURL || productionUrl,
            key: splitted[0],
        };
    }
};
exports.getConnectionData = getConnectionData;
var validateConfig = function (config) {
    if (!(config === null || config === void 0 ? void 0 : config.rtcApiKey)) {
        (0, sdk_1.sdkLog)("debug", "config.rtcApiKey or config.rtcToken need to be provided.");
        return false;
    }
    if (!(config === null || config === void 0 ? void 0 : config.rtcRoomId) || !(config === null || config === void 0 ? void 0 : config.rtcPeerId)) {
        (0, sdk_1.sdkLog)("debug", "config.rtcRoomId or config.rtcPeerId is empty.");
        return false;
    }
    return true;
};
exports.validateConfig = validateConfig;
var countOfValidConnections = function (openChannels) {
    return Object.keys(openChannels).filter(function (id) { return openChannels[id].validConnection; }).length;
};
exports.countOfValidConnections = countOfValidConnections;
var isRoomIdOrPeerIdChanged = function (watchrtcIdentifiers, watchrtcConfig) {
    var changed = false;
    if (watchrtcConfig.rtcRoomId &&
        watchrtcIdentifiers.rtcRoomId &&
        watchrtcIdentifiers.rtcRoomId !== watchrtcConfig.rtcRoomId) {
        changed = true;
    }
    if (watchrtcConfig.rtcPeerId &&
        watchrtcIdentifiers.rtcPeerId &&
        watchrtcIdentifiers.rtcPeerId !== watchrtcConfig.rtcPeerId) {
        changed = true;
    }
    if (changed) {
        (0, sdk_1.sdkLog)("debug", "maybeOpenWebsocketConnection. rtcRoomId or rtcPeerId has been changed", {
            old: {
                rtcRoomId: watchrtcIdentifiers.rtcRoomId,
                rtcPeerId: watchrtcIdentifiers.rtcPeerId,
            },
            new: {
                rtcRoomId: watchrtcConfig.rtcRoomId,
                rtcPeerId: watchrtcConfig.rtcPeerId,
            },
        });
    }
    return changed;
};
exports.isRoomIdOrPeerIdChanged = isRoomIdOrPeerIdChanged;
var validateRating = function (rating) {
    if (!rating) {
        (0, sdk_1.sdkLog)("error", "rating parameter is required");
        return false;
    }
    if (typeof rating !== "number" || rating < 1 || rating > 5) {
        (0, sdk_1.sdkLog)("error", "rating parameter should be number from 1 to 5");
        return false;
    }
    return true;
};
exports.validateRating = validateRating;
var validateEvent = function (event, channels) {
    if (!event) {
        (0, sdk_1.sdkLog)("error", "event parameter is required");
        return false;
    }
    if (!["global", "local", "log"].includes(event.type)) {
        (0, sdk_1.sdkLog)("error", "event.type should be either \"global\" or \"local\" or \"log\"");
        return false;
    }
    if (event.parameters && typeof event.parameters !== "object") {
        (0, sdk_1.sdkLog)("error", "event.parameters should be JSON object\"");
        return false;
    }
    if (event.pc && !(event.pc.__rtcStatsId in channels)) {
        (0, sdk_1.sdkLog)("error", "event.pc should be a RTCPeerConnection used in the call\"");
        return false;
    }
    return true;
};
exports.validateEvent = validateEvent;
var getHardwareInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
    function number_format(num) {
        return (Math.round(num * 100) / 100).toFixed(2);
    }
    var loadBenchmarks, gpuInfo, result, memory;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (typeof window === "undefined") {
                    return [2 /*return*/];
                }
                loadBenchmarks = function (fileName) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, new Promise(function (resolve) {
                                    try {
                                        var data = require("./gpu-benchmarks/".concat(fileName));
                                        data.shift();
                                        return resolve(data);
                                    }
                                    catch (err) {
                                        (0, sdk_1.sdkLog)("error", "Error. getHardwareInfo. loadBenchmarks", { err: err.stack, fileName: fileName });
                                        resolve([]);
                                    }
                                })];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    });
                }); };
                return [4 /*yield*/, GPU.getGPUTier({
                        override: { loadBenchmarks: loadBenchmarks },
                    })];
            case 1:
                gpuInfo = _a.sent();
                result = __assign(__assign({}, gpuInfo), { cores_count: window.navigator.hardwareConcurrency });
                memory = window.performance["memory"];
                if (memory) {
                    result.jsHeapSizeLimit = number_format(memory.jsHeapSizeLimit / 1048576);
                    result.totalJSHeapSize = number_format(memory.totalJSHeapSize / 1048576);
                    result.usedJSHeapSize = number_format(memory.usedJSHeapSize / 1048576);
                }
                return [2 /*return*/, result];
        }
    });
}); };
exports.getHardwareInfo = getHardwareInfo;
var patchConsoleConfig = {
    originalMethodPrefix: "_original_",
    methodsToPatch: ["log", "debug", "info", "warn", "error"],
    level: "",
};
var patchConsoleMethod = function (methodName, trace) {
    var originalMethod = console[methodName];
    if (!originalMethod) {
        return;
    }
    console[patchConsoleConfig.originalMethodPrefix + methodName] = originalMethod;
    console[methodName] = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (patchConsoleConfig.level && args.length && trace) {
            var currentMethodIndex = patchConsoleConfig.methodsToPatch.indexOf(methodName);
            var configMethodIndex = patchConsoleConfig.methodsToPatch.indexOf(patchConsoleConfig.level);
            var shouldTraceMethod = currentMethodIndex >= 0 && currentMethodIndex >= configMethodIndex;
            if (shouldTraceMethod) {
                var text = stringifyArgs(args);
                trace({ data: ["log", null, { type: methodName, text: text }] });
            }
        }
        originalMethod.apply(console, args);
    };
};
var setConsoleLevel = function (level, trace) {
    if (level && patchConsoleConfig.methodsToPatch.includes(level) && level !== patchConsoleConfig.level) {
        var currentIndex = patchConsoleConfig.methodsToPatch.indexOf(level);
        var previousIndex = patchConsoleConfig.methodsToPatch.indexOf(patchConsoleConfig.level);
        if (previousIndex < 0) {
            for (var _i = 0, _a = patchConsoleConfig.methodsToPatch.slice(currentIndex); _i < _a.length; _i++) {
                var methodName = _a[_i];
                patchConsoleMethod(methodName, trace);
            }
        }
        else if (currentIndex < previousIndex) {
            for (var _b = 0, _c = patchConsoleConfig.methodsToPatch.slice(currentIndex, previousIndex); _b < _c.length; _b++) {
                var methodName = _c[_b];
                patchConsoleMethod(methodName, trace);
            }
        }
        patchConsoleConfig.level = level;
    }
};
exports.setConsoleLevel = setConsoleLevel;
var restoreOriginalConsoleMethods = function () {
    if (!("console" in window)) {
        return;
    }
    for (var _i = 0, _a = patchConsoleConfig.methodsToPatch; _i < _a.length; _i++) {
        var methodName = _a[_i];
        var originalMethod = console[patchConsoleConfig.originalMethodPrefix + methodName];
        if (!originalMethod) {
            continue;
        }
        console[methodName] = originalMethod.bind(console);
        delete console[patchConsoleConfig.originalMethodPrefix + methodName];
    }
};
exports.restoreOriginalConsoleMethods = restoreOriginalConsoleMethods;
var exposeApplicationStatsForPC = function (id, previous, current, mappedTradk) {
    return {
        peer: (0, stats_1.getTransportFromStats)(id, current),
        streams: (0, stats_1.getStreamsFromStats)(id, previous, current, mappedTradk),
    };
};
exports.exposeApplicationStatsForPC = exposeApplicationStatsForPC;
var stringifyArgs = function (args) {
    try {
        return args.map(function (item) { return (typeof item === "string" ? item : JSON.stringify(item, replacerFunc())); }).join(" ");
    }
    catch (_a) {
        // do not log anything to not go into loop
        // can fail with Illegal Invocation of JSON.stringify
        // assumption - when customer also uses custom logger and binds another context
        // so just ignore it
        return "";
    }
};
// avoid "Converting circular structure to JSON" error while JSON.stringifying
var replacerFunc = function () {
    var visited = new WeakSet();
    return function (_key, value) {
        if (typeof value === "object" && value !== null) {
            if (visited.has(value)) {
                return;
            }
            visited.add(value);
        }
        return value;
    };
};
var applyPatchForRTT = function (oldStats, newStats) {
    oldStats = oldStats || {};
    newStats = newStats || {};
    var hasOldStats = true;
    if (Object.keys(oldStats).length === 0) {
        hasOldStats = false;
    }
    var keys = Object.keys(newStats);
    var reportKeys = keys.filter(function (key) { return newStats[key].type === "remote-outbound-rtp" || newStats[key].type === "remote-inbound-rtp"; });
    reportKeys.forEach(function (reportKey) {
        // case for Firefox/Safari
        if (!("roundTripTime" in newStats[reportKey])) {
            newStats[reportKey].roundTripTime = NaN;
        }
        else {
            // case Chrome
            var statsHaveRTTMeasurements = "roundTripTimeMeasurements" in newStats[reportKey] &&
                Boolean(oldStats[reportKey]) &&
                "roundTripTimeMeasurements" in oldStats[reportKey];
            if (hasOldStats && statsHaveRTTMeasurements) {
                if (newStats[reportKey].roundTripTimeMeasurements === oldStats[reportKey].roundTripTimeMeasurements) {
                    newStats[reportKey].roundTripTime = NaN;
                }
            }
        }
    });
    return newStats;
};
exports.applyPatchForRTT = applyPatchForRTT;
var subtractNailUpReferenceStats = function (referenceStats, newStats) {
    var _loop_1 = function (key, report) {
        if (key in referenceStats) {
            var referenceReport_1 = referenceStats[key];
            switch (report.type) {
                case "inbound-rtp":
                    [
                        "bytesReceived",
                        "packetsReceived",
                        "packetsLost",
                        "framesDecoded",
                        "keyFramesDecoded",
                        "framesRendered",
                        "framesDropped",
                        "qpSum",
                        "totalDecodeTime",
                        "totalInterFrameDelay",
                        "totalSquaredInterFrameDelay",
                        "pauseCount",
                        "totalPausesDuration",
                        "freezeCount",
                        "totalFreezesDuration",
                        "headerBytesReceived",
                        "packetsDiscarded",
                        "fecBytesReceived",
                        "fecPacketsReceived",
                        "fecPacketsDiscarded",
                        "firCount",
                        "pliCount",
                        "totalProcessingDelay",
                        "nackCount",
                        "jitterBufferEmittedCount",
                        "totalSamplesReceived",
                        "concealedSamples",
                        "silentConcealedSamples",
                        "concealmentEvents",
                        "totalAudioEnergy",
                        "totalSamplesDuration",
                        "framesReceived",
                        "framesAssembledFromMultiplePackets",
                        "totalAssemblyTime",
                        "retransmittedPacketsReceived",
                        "retransmittedBytesReceived",
                    ].forEach(function (stat) {
                        if (stat in report) {
                            report[stat] = Math.max(report[stat] - referenceReport_1[stat], 0);
                        }
                    });
                    break;
                case "outbound-rtp":
                    [
                        "packetsSent",
                        "bytesSent",
                        "headerBytesSent",
                        "retransmittedPacketsSent",
                        "retransmittedBytesSent",
                        "totalEncodedBytesTarget",
                        "framesSent",
                        "hugeFramesSent",
                        "framesEncoded",
                        "keyFramesEncoded",
                        "qpSum",
                        "totalEncodeTime",
                        "totalPacketsSendDelay",
                        "qualityLimitationResolutionChanges",
                        "qualityLimitationDurations",
                        "nackCount",
                        "firCount",
                        "pliCount",
                    ].forEach(function (stat) {
                        if (stat in report) {
                            if (stat === "qualityLimitationDurations") {
                                report[stat].bandwidth = Math.max(report[stat].bandwidth - referenceReport_1[stat].bandwidth, 0);
                                report[stat].cpu = Math.max(report[stat].cpu - referenceReport_1[stat].cpu, 0);
                                report[stat].other = Math.max(report[stat].other - referenceReport_1[stat].other, 0);
                                report[stat].none = Math.max(report[stat].none - referenceReport_1[stat].none, 0);
                            }
                            else {
                                report[stat] = Math.max(report[stat] - referenceReport_1[stat], 0);
                            }
                        }
                    });
                    break;
                case "remote-inbound-rtp":
                    ["packetsReceived", "packetsLost", "totalRoundTripTime", "roundTripTimeMeasurements"].forEach(function (stat) {
                        if (stat in report) {
                            report[stat] = Math.max(report[stat] - referenceReport_1[stat], 0);
                        }
                    });
                    break;
                case "remote-outbound-rtp":
                    ["packetsSent", "bytesSent", "totalRoundTripTime", "roundTripTimeMeasurements"].forEach(function (stat) {
                        if (stat in report) {
                            report[stat] = Math.max(report[stat] - referenceReport_1[stat], 0);
                        }
                    });
                    break;
                case "media-source":
                    [
                        "totalAudioEnergy",
                        "totalSamplesDuration",
                        "droppedSamplesDuration",
                        "droppedSamplesEvents",
                        "totalCaptureDelay",
                        "totalSamplesCaptured",
                        "frames",
                    ].forEach(function (stat) {
                        if (stat in report) {
                            report[stat] = Math.max(report[stat] - referenceReport_1[stat], 0);
                        }
                    });
                    break;
                case "media-playout":
                    [
                        "synthesizedSamplesDuration",
                        "synthesizedSamplesEvents",
                        "totalSamplesDuration",
                        "totalPlayoutDelay",
                        "totalSamplesCount",
                    ].forEach(function (stat) {
                        if (stat in report) {
                            report[stat] = Math.max(report[stat] - referenceReport_1[stat], 0);
                        }
                    });
                    break;
                case "peer-connection":
                    ["dataChannelsOpened", "dataChannelsClosed"].forEach(function (stat) {
                        if (stat in report) {
                            report[stat] = Math.max(report[stat] - referenceReport_1[stat], 0);
                        }
                    });
                    break;
                case "data-channel":
                    ["messagesSent", "bytesSent", "messagesReceived", "bytesReceived"].forEach(function (stat) {
                        if (stat in report) {
                            report[stat] = Math.max(report[stat] - referenceReport_1[stat], 0);
                        }
                    });
                    break;
                case "transport":
                    ["packetsSent", "packetsReceived", "bytesSent", "bytesReceived", "selectedCandidatePairChanges"].forEach(function (stat) {
                        if (stat in report) {
                            report[stat] = Math.max(report[stat] - referenceReport_1[stat], 0);
                        }
                    });
                    break;
                case "candidate-pair":
                    [
                        "packetsSent",
                        "packetsReceived",
                        "bytesSent",
                        "bytesReceived",
                        "totalRoundTripTime",
                        "requestsReceived",
                        "requestsSent",
                        "responsesReceived",
                        "responsesSent",
                        "consentRequestsSent",
                        "packetsDiscardedOnSend",
                        "bytesDiscardedOnSend",
                    ].forEach(function (stat) {
                        if (stat in report) {
                            report[stat] = Math.max(report[stat] - referenceReport_1[stat], 0);
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    };
    for (var _i = 0, _a = Object.entries(newStats); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], report = _b[1];
        _loop_1(key, report);
    }
    return newStats;
};
exports.subtractNailUpReferenceStats = subtractNailUpReferenceStats;
var generateID = function () {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};
exports.generateID = generateID;
