class RemotePeer {
  peerSessionId: string;
  peerUsername: string;
  audioConsumer: any;
  audioFileConsumer: any;
  peerName: string;
  peerType: string;
  peerAudioProducing: boolean;
  peerVideoProducing: boolean;
  peerScreenProducing: boolean;
  peerScreenTwoProducing: boolean;
  peerAudioFileProducing: boolean;
  peerAudioPaused: boolean;
  peerVideoPaused: boolean;
  peerScreenStopped: boolean;
  peerAudioFilePaused: boolean;
  peerscreenTwoStopped: boolean;
  peerVideoStream: MediaStream | undefined;
  peerAudioStream: MediaStream | undefined;
  peerScreenStream: MediaStream | undefined;
  peerScreenTwoStream: MediaStream | undefined;
  peerAudioFileStream: MediaStream | undefined;
  expression: string;
  objectDetect: string;
  numfaces: string;
  isVideoSwapped: string;
  isPrivateCallOn: boolean;
  emojiForIconPeer: string;
  emojiForHandAndLunchPeer: string;
  faceVerificationSuccess:boolean;
  showAlertInParticipant:boolean;
  isNotSamePerson:boolean;
  privateCallPeerOne?: any;
  isCallModalOn?: boolean;
  networkStrength?: string;
  openentObserverAudioId?: string;
  openentObserveeAudioId?: string;
  time?: string;
  screenAudioStream: MediaStream | undefined;
  screenAudioPaused: boolean;
  systemAudioConsumer: any;
  audioSystemProducing: boolean;
  systemAudioProducerRole?: string;

  constructor(
    peerSessionId: string,
    peerUsername: string,
    peerName: string,
    peerType: string,
    peerAudioProducing: boolean,
    peerVideoProducing: boolean,
    peerScreenProducing: boolean,
    peerScreenTwoProducing: boolean,
    peerAudioFileProducing: boolean,
    expression: string,
    objectDetect: string,
    numfaces: string,
    isPrivateCallOn: boolean,
    emojiForIconPeer: string,
    emojiForHandAndLunchPeer: string,
    audioSystemProducing: boolean,
    privateCallPeerOne?: any,
    isCallModalOn?: boolean,
    networkStrength?: string,
    openentObserverAudioId?: string,
    openentObserveeAudioId?: string,
    time?: string,
    systemAudioProducerRole?: string,

  ) {
    this.peerSessionId = peerSessionId;
    this.peerUsername = peerUsername;
    this.peerName = peerName;
    this.peerType = peerType;
    this.peerAudioProducing = peerAudioProducing;
    this.peerVideoProducing = peerVideoProducing;
    this.peerScreenProducing = peerScreenProducing;
    this.peerScreenTwoProducing = peerScreenTwoProducing;
    this.peerAudioFileProducing = peerAudioFileProducing;
    this.peerAudioPaused = false;
    this.peerVideoPaused = false;
    this.peerAudioFilePaused = false;
    this.peerScreenStopped = true;
    this.peerscreenTwoStopped = true;
    this.isVideoSwapped = "";
    this.expression = "neutral";
    this.numfaces = "0";
    this.isPrivateCallOn = isPrivateCallOn;
    this.emojiForHandAndLunchPeer = emojiForHandAndLunchPeer;
    this.emojiForIconPeer = emojiForIconPeer;
    this.privateCallPeerOne = privateCallPeerOne;
    this.isCallModalOn = isCallModalOn;
    this.faceVerificationSuccess = false;
    this.isNotSamePerson = false;
    this.showAlertInParticipant = false;
    this.objectDetect = objectDetect;
    this.networkStrength = networkStrength;
    this.openentObserveeAudioId = openentObserveeAudioId;
    this.openentObserverAudioId = openentObserverAudioId;
    this.time = time;
    this.screenAudioPaused = false;
    this.audioSystemProducing = audioSystemProducing
    this.systemAudioProducerRole = systemAudioProducerRole;

  }
}

export default RemotePeer;
