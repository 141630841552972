import "./strengths.css";

const strengths = {
  EXCELLENT: "EXCELLENT",
  GREAT: "GREAT",
  OKAY: "OKAY",
  WEAK: "WEAK",
};

const SignalStrengthIndicator = (props: any) => {
  const signalStrength = props.strength ? props.strength : strengths.EXCELLENT;
  const numBars = 4;
  let fill = 0;
  if (signalStrength.toUpperCase() === strengths.EXCELLENT) {
    fill = numBars;
  } else if (signalStrength.toUpperCase() === strengths.GREAT) {
    fill = numBars - 1;
  } else if (signalStrength.toUpperCase() === strengths.OKAY) {
    fill = numBars - 2;
  } else if (signalStrength.toUpperCase() === strengths.WEAK) {
    fill = numBars - 3;
  }

  return (
    <>
      <i className="icon__signal-strength signal-4">
        {[...Array(numBars)].map((e, i) => (
          <span
            key={i}
            className={"bar-" + (i + 1) + (i < fill ? " fill" : " ")}
          ></span>
        ))}
      </i>
    </>
  );
};

export default SignalStrengthIndicator;
