import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faPizzaSlice, faStopwatch20 } from "@fortawesome/free-solid-svg-icons";
import SocketService from "../../services/socket";
import { getFormattedMinutes, timeToMinutes } from "../../utils/getMinutesDifferenceFromTime";
import { socketConnectiontype } from "../../utils/constant";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { playBeepForLunchBrbExtend } from "../../utils/AudioVideoDeviceUtils";
import { LUNCH_TIME_LIMIT_MINUTES, BREAK_TIME_LIMIT_MINUTES, BRB_TIME_LIMIT_MINUTES } from "../../Pages/RoomPage/constants";

interface reminderProps {
  lunchAndBreak: string;
  handleForLunchAndBreak?: (value: string) => void;
  currentUserName: string;
  emojisEvents: string;
  handleForEmojis?: (value: string) => void;
}

const LunchBreakModal = (props: reminderProps) => {
  const [time, setTime] = useState("Just now");
  const [breakLimitCrossed, setBreakLimitCrossed] = useState(false);
  const [lunchLimitCrossed, setLunchLimitCrossed] = useState(false);
  const [brbLimitCrossed, setBrbLimitCrossed] = useState(false);

  const environments = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  );

  const lunchTimeLimitStatus = environments.LUNCH_TIME_LIMIT_MINUTES
  ? environments.LUNCH_TIME_LIMIT_MINUTES.status
  : LUNCH_TIME_LIMIT_MINUTES.status;

  const lunchTimeLimit = environments.LUNCH_TIME_LIMIT_MINUTES
  ? environments.LUNCH_TIME_LIMIT_MINUTES.value
  : LUNCH_TIME_LIMIT_MINUTES.value;

  const breakTimeLimitStatus = environments.BREAK_TIME_LIMIT_MINUTES
  ? environments.BREAK_TIME_LIMIT_MINUTES.status
  : BREAK_TIME_LIMIT_MINUTES.status;

  const breakTimeLimit = environments.BREAK_TIME_LIMIT_MINUTES
  ? environments.BREAK_TIME_LIMIT_MINUTES.value
  : BREAK_TIME_LIMIT_MINUTES.value;

  const brbTimeLimitStatus = environments.BRB_TIME_LIMIT_MINUTES
  ? environments.BRB_TIME_LIMIT_MINUTES.status
  : BRB_TIME_LIMIT_MINUTES.status;

  const brbTimeLimit = environments.BRB_TIME_LIMIT_MINUTES
  ? environments.BRB_TIME_LIMIT_MINUTES.value
  : BRB_TIME_LIMIT_MINUTES.value;


  let messageSubscriber: any;

  const socketInstance = new SocketService(
    socketConnectiontype.media
  ).getInstance(socketConnectiontype.media);

  const handleModalClose = () => {
    if (props.handleForLunchAndBreak && (props.lunchAndBreak === 'LUNCH' || props.lunchAndBreak === 'BREAK')) {
      props.handleForLunchAndBreak("end" + props.lunchAndBreak);
      setTime("Just now");
      setLunchLimitCrossed(false);
      setBreakLimitCrossed(false);
    }

    if (props.handleForEmojis && props.emojisEvents === "BERIGHTBACK") {
      props.handleForEmojis("endBrb");
      setTime("Just now");
      setBrbLimitCrossed(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    messageSubscriber = socketInstance
      .getMessages()
      .subscribe((message: string) => {
        processMessage(JSON.parse(message));
      });
    return () => {
      messageSubscriber.unsubscribe();
      // socketInstance.closeSocketConnection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processMessage = async (message: any) => {

    switch (message.type) {
      case "EMOJI_FOR_HANDANDLUNCH":
        if (props.currentUserName === message.peerUsername) {
          //@ts-ignore
          setTime(getFormattedMinutes(message.time, 2, ' Hour '));

          if (message.action && message.action === "LUNCH") {
            if (lunchTimeLimitStatus && timeToMinutes(message.time) > +lunchTimeLimit) {
              setLunchLimitCrossed(true);
              playBeepForLunchBrbExtend();
            }
          }
          if (message.action && message.action === "BREAK") {
            if (breakTimeLimitStatus && timeToMinutes(message.time) > +breakTimeLimit) {
              setBreakLimitCrossed(true);
              playBeepForLunchBrbExtend();
            }
          }

        }
        break;

      case "EMOJI_FOR_ICON":
        if (props.currentUserName === message.peerUsername) {
          //@ts-ignore
          setTime(getFormattedMinutes(message.time, 2, ' Hour '));

          if (message.action && message.action === "BERIGHTBACK") {
            if (brbTimeLimitStatus && timeToMinutes(message.time) > +brbTimeLimit) {
              setBrbLimitCrossed(true);
              playBeepForLunchBrbExtend();
            }
          }

        }
        break;

      default:
        break;
    }
  }

  return (
    <Modal
      show={props.lunchAndBreak === "LUNCH" || props.lunchAndBreak === "BREAK" || props.emojisEvents === "BERIGHTBACK"}
      onHide={handleModalClose}
      // className="feedback_modal_container"
      style={{ border: "0px solid black", width: "40%", margin: "auto" }}
    >
      <Modal.Header

        style={{
          backgroundColor: "rgb(38, 163, 219)",
          margin: "0",
          padding: "6px",
        }}
      >
        <button className="modal-close-btn" onClick={handleModalClose}>
          X
        </button>
        <Modal.Title
          style={{ color: "black", fontSize: "1.1rem", fontWeight: "bold" }}
        >
          {props.lunchAndBreak === "LUNCH"
            ? "Lunch Time"
            : props.lunchAndBreak === "BREAK"
              ? "Break Time"
              : props.emojisEvents === "BERIGHTBACK"
                ? "Be Right Back"
                : ""}{" "}

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="feedback_modal_main">
          <div className="message_export_data">
            <div className="filters-container">
              {props.lunchAndBreak === "LUNCH" ? (
                <>
                  <FontAwesomeIcon
                    icon={faPizzaSlice}
                    style={{ fontSize: "2.5rem" }}
                  />
                  <h4> On Lunch from {time} </h4>
                  {lunchLimitCrossed && <div className="red">Your Time limit for Lunch is exceeded. It is set to {lunchTimeLimit} minute.</div>}

                </>
              ) : null}

              {props.emojisEvents === "BERIGHTBACK" ? (
                <>
                  <FontAwesomeIcon
                    icon={faStopwatch20}
                    style={{ fontSize: "2.5rem" }}
                  />
                  <h4> Be Right Back {time}</h4>
                  {brbLimitCrossed && <div className="red">Your Time limit for Be Right Back is exceeded. It is set to {brbTimeLimit} minute</div>}
                </>
              ) : null}

              {props.lunchAndBreak === "BREAK" ? (
                <>
                  <FontAwesomeIcon
                    icon={faBell}
                    style={{ fontSize: "2.5rem" }}
                  />
                  <h4> On Break from {time}</h4>
                  {breakLimitCrossed && <div className="red">Your Time limit for Break is exceeded. It is set to {breakTimeLimit} minute</div>}
                </>
              ) : null}
            </div>
          </div>

          <div
            className="mt-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <button
              className="link"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleModalClose();
              }}
            >
              Back Now
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LunchBreakModal;
