import {
  SET_PEER_VOLUME,
  REMOVE_PEER,
  CLEAR_REDUX_DATA
} from "../actions/actionTypes";

const initialState = {};

interface IAction {
  type: string;
  payload: any;
}

const peerVolumes = (state = initialState, action: IAction) =>
{
	switch (action.type)
	{
		case SET_PEER_VOLUME:
		{
			const { peerId } = action.payload;
			const dBs = action.payload.volume < -100 ? -100 : action.payload.volume;

			return { ...state, [peerId]: dBs };
		}

		case REMOVE_PEER:
		{
			const { peerId } = action.payload;
			const newState: any = { ...state };

			delete newState[peerId];

			return newState;
		}

		case CLEAR_REDUX_DATA:
		{
        	return initialState
      	}

		default:
			return state;
	}
};

export default peerVolumes;
