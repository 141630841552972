import {
    GET_SUPERVISOR_MANAGER_FAILURE,
    GET_SUPERVISOR_MANAGER_SUCCESS,
    CLEAR_REDUX_DATA,
  } from "../actions/actionTypes";
  
  const initialState = {
    allSupervisor: "",
   
    errorMessage: "",
  
  };
  interface IAction {
    type: string;
    payload: any;
  }
  
  const supervisorReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
      case GET_SUPERVISOR_MANAGER_SUCCESS: {
        return {
          ...state,
          allSupervisor: action.payload?.supervisors,
        
        };
      }
      case GET_SUPERVISOR_MANAGER_FAILURE: {
        return {
          ...state,
          errorMessage: action.payload?.errorMessage,
        };
      }
      case CLEAR_REDUX_DATA:{
        return initialState
      }
      default: {
        return state;
      }
    }
  };
  
  export default supervisorReducer;
  