import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import { getRooms, createRoom } from "../services/rooms";
import {
  GET_ROOMS_SUCCESS,
  GET_ROOMS_FAILURE,
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_FAILURE,
  SET_VIEW_MODE,
  SET_ROOM_LEAVE_ON_ICE_RESTART,
  SET_CURRENT_VIEW_MODE,
  SET_SCALE_RESOLUTION,
  REMOVE_SCALE_RESOLUTION,
  SET_AUDIO_ALLOWED,
  SET_PRIVATE_CALL_ALLOWED,
} from "../actions/actionTypes";
import { scaleResultion } from "../reducers/roomsReducer";
// import { toast } from "react-toastify";

const setRoomDetails = (roomData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_ROOMS_SUCCESS,
      payload: roomData,
    });
  };
};

const setRoomError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_ROOMS_FAILURE,
      payload: errorMessage,
    });
  };
};

export const getRoomsAction = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const roomData = await getRooms();
      if (roomData) {
        dispatch(setRoomDetails(roomData));
      } else {
        dispatch(
          setRoomError("Could not fetch room details. Please try again")
        );
      }
      return roomData;
    } catch (error: any) {
      setRoomError(
        error?.message || "Could not fetch room details. Please try again"
      );
    } finally {
      setLoading(false);
    }
  };
};

export const setRoomOnRestartIce = (roomName: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_ROOM_LEAVE_ON_ICE_RESTART,
      payload: roomName,
    });
  };
};

const createRoomSuccess = (roomName: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: CREATE_ROOM_SUCCESS,
      payload: roomName,
    });
  };
};

const createRoomFailure = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: CREATE_ROOM_FAILURE,
      error: errorMessage,
    });
  };
};

export const addRoom = (roomName: string, maxParticipants: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const createRoomData = await createRoom(roomName, maxParticipants);
      if (createRoomData) {
        dispatch(createRoomSuccess(roomName));
      } else {
        dispatch(createRoomFailure("Could not create room. Please try again"));
      }
      return createRoomData;
    } catch (error: any) {
      createRoomFailure(
        error ? error?.message : "Could not create room. Please try again"
      );
    } finally {
      setLoading(false);
    }
  };
};

export const setViewMode = (roomtype: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_VIEW_MODE,
      payload: roomtype,
    });
  };
};

export const setAudioAllowed = (audioAllowedInRoom: boolean | undefined) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_AUDIO_ALLOWED,
      payload: audioAllowedInRoom,
    });
  };
};

export const setPrivateCallAllowed = (allowPrivateCallinSilentRoom: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_PRIVATE_CALL_ALLOWED,
      payload: allowPrivateCallinSilentRoom,
    });
  };
};

export const setCurrentViewMode = (
  currentViewMode: string,
  currentRoom: string
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_CURRENT_VIEW_MODE,
      payload: {
        currentViewMode,
        currentRoom,
      },
    });
  };
};

export const setScaleResolution = (
  screenScale: scaleResultion,
  videoScale: scaleResultion
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_SCALE_RESOLUTION,
      payload: {
        screenScale,
        videoScale,
      },
    });
  };
};

export const remvoeScaleResolution = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: REMOVE_SCALE_RESOLUTION,
    });
  };
};
