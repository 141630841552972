import { Modal } from "react-bootstrap";

interface reminderProps {
  show: boolean;
  handleShow: any;
}

const ReminderModal = (props: reminderProps) => {
  const handleModalClose = () => {
    props.handleShow();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleModalClose}
      // className="feedback_modal_container"
      style={{ border: "0px solid black", width: "40%", margin: "auto" }}
    >
      <Modal.Header
        
        style={{
          backgroundColor: "rgb(38, 163, 219)",
          margin: "0",
          padding: "6px",
        }}
      >
         <button className="modal-close-btn" onClick={handleModalClose}>
            X
          </button>
        <Modal.Title
          style={{ color: "black", fontSize: "1.1rem", fontWeight: "bold" }}
        >
          Welcome
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="feedback_modal_main">
          <div className="message_export_data">
            <div className="filters-container">
              <h5>Remember To Turn on your Video and Screen</h5>
            </div>
          </div>

          <div
            className="mt-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <button
              className="link"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleModalClose();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReminderModal;
