import { toast } from "react-toastify";

export function handleFailureResponse (response: any) {
    switch (response?.message) {
        case "PEER_NOT_FOUND": 
            toast("The user is not available, please try again later");
            break;

        case "PROCESSING_FAILURE":
            toast("An error has occurred, please try again later.");
            break;

        default:
            toast("An error has occurred, please try again later.");

    }
}