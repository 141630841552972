import request from "./index";
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const saveLayoutSetting = async (
  username: string,
  type: string,
  data: Object
) => {

  const reqBody = {
    username,
    type,
    data,
 }

  const response = await request.post("/savelayoutsetting", reqBody, config);
  return response.data;
};

export const getLayoutSetting = async (username: string, type: string) => {
  const reqBody = {
    username,
    type,
  };

  const response = await request.post("/getLayoutSetting", reqBody, config);
  return response.data;
};
