import { SET_LOADING,CLEAR_REDUX_DATA } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
};
interface IAction {
  type: string;
  payload: any;
}

const loaderReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_LOADING: { 
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case CLEAR_REDUX_DATA:{
      return initialState
    }

    default: {
      return state;
    }
  }
};

export default loaderReducer;
