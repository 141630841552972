import { Component } from "react";
import { WithRouter } from "./withrouter";
import { toast } from "react-toastify";
// import * as faceapi from "@vladmandic/face-api";
import arraymove from "array-move";
import hark from "hark";
// import { setInterval } from "timers";
import SocketService from "../../services/socket";
import "./RoomPage.css";
import { getConnectionDetails } from "../../services/rooms";
import { Device, types as mediasoupTypes } from "mediasoup-client";
import {
  VIDEO_KSVC_ENCODINGS,
  VIDEO_SIMULCAST_ENCODINGS_SM,
  SCREEN_SHARING_SIMULCAST_ENCODINGS,
  REACT_APP_EMOTION_DETECTION,
  REACT_APP_FACE_DETECTION,
  REACT_APP_FACE_VERIFICATION,
  REACT_APP_CELLPHONE_DETECTION,
  REACT_APP_DETECTION_TOKEN,
  STORE_DETECTION_IMAGES,
  AGENT_CAM_AUTO_ON,
  EMOTION_DETECTION_ADMIN_CONTROL,
  AGENT_CAM_ALWAYS_ON,
  VIDEO_BACKGROUND_BLUR,
  VIDEO_BACKGROUND_IMAGES,
  BRB_TIME_LIMIT_MINUTES,
  BREAK_TIME_LIMIT_MINUTES,
  LUNCH_TIME_LIMIT_MINUTES,
  REACT_APP_HEAD_POSE,
  SCREEN_SHARE_HEIGHT,
  SCREEN_SHARE_WIDTH,
} from "./constants";

import RemotePeer from "./RemotePeer";
import { upload, uploadFeedbackImg } from "../../services/faceScreenshot";
import {
  getAudioStream,
  getVideoStream,
  getScreenStream,
  checkVideoDevices,
  playChatNotification,
} from "../../utils/AudioVideoDeviceUtils";
import { connect } from "react-redux";
import { getUserDetailsAction, setMySocketId } from "../../actions/userActions";
import { setPeerVolume, removePeer } from "../../actions/peerVolumeActions";
import { Dispatch } from "redux";
import Sidebar from "./../../components/Sidebar";
import { Alert, Button } from "react-bootstrap";
import {
  IRoomPageProps,
  IRoomPageState,
  IConnectionDetails,
  TFLite,
} from "./interfaces";
import { RtpCapabilities } from "mediasoup-client/lib/types";
import {
  clearChatData,
  setFullMessageList,
  setMessageList,
  setReconnectioStatusForChat,
  setSelectedChatUser,
} from "../../actions/chatActions";

import StudentScreen from "./StudentScreen";
import InstructorScreen from "./InstructorScreen";
import config from "../../config";
import Loader from "../../components/Loader";
// import { isSessionActive } from "./../../services/login";
import watchRTC from "@testrtc/watchrtc-sdk";
import CallModal from "../../components/callModal/Index";
import { handleFailureResponse } from "../../utils/HelperFunctions";
import { getTFLiteModelFileName } from "../../helpers/segmentationHelper";

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Howl, Howler } from "howler";
import { checkForSupManAdmin } from "../../utils/checkForSupAdmin";
import {
  addNotifications,
  addNotifyEndTime,
  addNotificationsUsingSingle,
} from "../../actions/notificationActions";
import {
  setorRemoveTempRoomUser,
  clearTempRoomUser,
} from "../../actions/tempRoomUser";
import { RootState } from "../../redux";
import { clearControlId } from "../../actions/screenControlAction";
import ReminderModal from "./reminderModal";
import {
  addHighResUser,
  clearControlAudioVideoFile,
  removeHighResUserStreamType,
  setAudioFile,
  setVideoFile,
} from "../../actions/audioVideoFilesAction";
import { setVideoOption } from "../../actions/videoEditOption";
import {
  mediaTypeConstant,
  notificationByType,
  resolutionConstant,
  roomViewModeConst,
  socketConnectiontype,
} from "../../utils/constant";
import {
  addAllCollabScreenPeerWhileConnected,
  addCollabScreenPeer,
  addCollabScreenPeerFromArray,
  clearCollabScreenData,
  removeCollabScreenPeer,
  removeCollabScreenPeerFromArray,
} from "../../actions/collabAction";
import {
  setViewMode,
  setRoomOnRestartIce,
  setCurrentViewMode,
  remvoeScaleResolution,
} from "../../actions/roomActions";
import { detect } from "../../services/faceScreenshot";
// import { ignoreElements } from "rxjs/operators";
import {
  backgroundModalClose,
  setBackgroundImage,
} from "../../actions/backgroundAction";
import { getMultipleSupervisorsStatus } from "../../services/adminSettings";
import WebRTCIssueDetector, {
  QualityLimitationsIssueDetector,
  FramesDroppedIssueDetector,
  FramesEncodedSentIssueDetector,
  InboundNetworkIssueDetector,
  OutboundNetworkIssueDetector,
  NetworkMediaSyncIssueDetector,
  AvailableOutgoingBitrateIssueDetector,
  UnknownVideoDecoderImplementationDetector,
  IssueDetectorResult,
  NetworkScores,
} from "webrtc-issue-detector";
import { getLayoutSettingActions } from "../../actions/layoutSettingActions";
import {
  clearActivityReducer,
  setActivityModalOpen,
  setActivityParentId,
} from "../../actions/activityChannelAction";
import { setReconnectionStatus } from "../../actions/reconnectionAction";
import { convertIntoBoolean } from "../../utils/getBoolean";
import {
  verifyFace,
  setRecurrentCheck,
  wrongPersonCheck,
} from "../../actions/faceVerificationAction";
import { timeToMinutes } from "../../utils/getMinutesDifferenceFromTime";
import { processIPfromWebrtc } from "../../services/webrtcipaddress.service";
import arrayMove from "array-move";

// Setup the new Howl.
// const sound = new Howl({
//   src: ["https://cdn.collaborationroom.ai/tones/pop.mp3"],
// });
// import Human from '@vladmandic/human'; // points to @vladmandic/human/dist/human.esm.js

const socketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

let peer_connection: any;

const OFFER_OPTIONS = {
  offerToReceiveAudio: 0,
  offerToReceiveVideo: 1,
};

// @ts-ignore
// const pageSizeL = parseInt(process.env.REACT_APP_PAGE_SIZE) || 10;
// // @ts-ignore
// const pageSizeC = parseInt(process.env.REACT_APP_PAGE_SIZE_COLLAB) || 5;
// const faceSeconds = parseInt(process.env.REACT_APP_FACESECONDS!, 10);
// const enableScreenshot = process.env.REACT_APP_ENABLESCREENSHOT;

declare function createTFLiteModule(): Promise<TFLite>;
declare function createTFLiteSIMDModule(): Promise<TFLite>;

const sleep = (ms: any) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

let cellphoneTimeout = {} as any;

let screen_num_object = {} as any;
let replaceVideoTimeout: ReturnType<typeof setTimeout>;

interface screenPeerType {
  peerId: string;
  peerUsername: string;
}

class RoomPage extends Component<IRoomPageProps, IRoomPageState> {
  roomname: string;
  device!: mediasoupTypes.Device;
  messageSubscriber: any;
  publishTransport!: mediasoupTypes.Transport;
  subscribeTransport!: mediasoupTypes.Transport;
  audioProducer!: mediasoupTypes.Producer;
  screenProducer!: mediasoupTypes.Producer;
  screenTwoProducer!: mediasoupTypes.Producer;
  screenAudioProducer!: mediasoupTypes.Producer;
  audioFileProducer!: mediasoupTypes.Producer;
  videoProducer!: mediasoupTypes.Producer;
  roomSocket!: SocketIOClient.Socket;
  videoDeviceFound = false;
  connectionStatus = "disconnected";
  remotePeers: RemotePeer[] = [];
  initialState: IRoomPageState;
  frstart: any;
  sessionActiveTimeout: any;
  faceCountZero: any;
  faceCountTwo: any;
  networkMonitoringInterval: any;
  private _hark: any;
  _harkStream: any;
  worker: any;
  objectDetectionWorker: any;
  pageSizeL: any;
  pageSizeC: any;
  faceSeconds: any;
  enableScreenshot: any;
  screenControl: any;
  faceDetect: any;
  isRecordingEnabled: any;
  videoKSVCEncoding: any;
  videoSimulcastEncodingSm: any;
  screenSharingSimulcastEncoding: any;
  emotionDetection: any;
  detectionToken: any;
  storeDetectionImages: any;
  cellphoneFaceDetectSeconds: any;
  screenHeight: any;
  screenWidth: any;
  cameraAutoOn: any;
  emotionDetectionAdminControl: any;
  headPoseDetect: any;
  faceVerification: any;
  cellphoneDetection: any;
  faceDetectionFlag: any;
  faceDetectionUrl: any;
  lunchTimeLimitStatus: boolean;
  lunchTimeLimit: string;
  breakTimeLimitStatus: boolean;
  breakTimeLimit: string;
  brbTimeLimitStatus: boolean;
  brbTimeLimit: string;
  // @ts-ignore
  username: string;
  remoteArrayExcecuted = false;
  systemAudioFromElectron: any;
  observerModeOn = false;
  audioAllowedInRoom: boolean | undefined;
  multipleSupervisors: boolean | undefined;
  componentRef: any = 0;
  webRtcIssueDetector: any;
  reconnectionStatusInRoompage = false;
  isreconnectionStatusVideo = false;
  isreconnectionStatusAudio = false;
  isreconnectionStatusScreenForElectron = false;
  agentCamAlwaysOn: boolean;
  blurOption: boolean;
  customBgImg: boolean;
  amIsharingScreen: boolean;
  allowPrivateCallinSilentRoom: boolean | undefined;
  videoPauseAlertInterval: any;

  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.roomname = this.props?.params.roomname;
    this.componentCleanup = this.componentCleanup.bind(this);
    this.frstart = null;
    this.faceCountZero = 0;
    this.worker = null;
    this.objectDetectionWorker = null;
    this.faceCountTwo = 0;
    // Local mic hark
    this._hark = null;
    // Local MediaStream for hark
    this._harkStream = null;

    this.networkMonitoringInterval = null;
    this.initialState = {
      localVideoStream: undefined,
      localAudioStream: undefined,
      localScreenStream: undefined,
      localScreenTwoStream: undefined,
      localAudioFileStream: undefined,
      remotePeers: [],
      handRaisedPeer: {},
      errorMessages: [],
      pageNumber: 1,
      localVideoPaused: true,
      localAudioPaused: true,
      localScreenStopped: true,
      localScreenStoppedTwo: true,
      isScreenAudioShared: false,
      localAudioFilePause: false,
      localAudioFileStopped: true,
      isSwapScreenIconShow: false,
      expression: "neutral",
      objectDetect: "",
      current_id: "",
      isLoading: false,
      signalStrength: "EXCELLENT",
      avgScore: 0,
      presenter: "",
      isPrivateCallOn: false,
      privateCallPeerOne: "",
      openentCallerPerson: "",
      callModalOption: {
        isCallModal: false,
        idForMakingCall: "",
        messageForCall: "",
        callAcceptedButton: false,
        firstLetter: "",
        peerSessionIdRemote: "",
      },
      sideBarOpen: true,
      sideBarCount: 0,
      segmentationConfig: {
        model: "meet",
        backend: "wasmSimd",
        inputResolution: "256x144",
        pipeline: "canvas2dCpu",
      },
      tflite: undefined,
      tfliteSIMD: undefined,
      selectedTFLite: undefined,
      isSIMDSupported: false,
      feedbackImageData: {
        file: "",
        agentUsername: "",
        parentRole: "",
        parentUsername: "",
        room: "",
      },
      reminderState: false,
      peerSessionIdCellPhone: new Set(),
      openentObserverAudioId: "",
      openentObserveeAudioId: "",
      imCaller: false,
      isSidebarOpen: true,
      consecutiveFalseCounters: 0,
      participantAlertCounter: 0,
      presenterForAgent: "",
      screenControlUsername: "",
    };
    this.state = this.initialState;
    // @ts-ignore
    this.pageSizeL = this.props.environment.REACT_APP_PAGE_SIZE
      ? parseInt(this.props.environment.REACT_APP_PAGE_SIZE.value)
      : 10;
    // @ts-ignore
    this.pageSizeC = this.props.environment.REACT_APP_PAGE_SIZE_COLLAB
      ? parseInt(this.props.environment.REACT_APP_PAGE_SIZE_COLLAB.value)
      : 7;
    //@ts-ignore
    this.faceSeconds = this.props.environment.REACT_APP_FACESECONDS
      ? parseInt(this.props.environment.REACT_APP_FACESECONDS.value, 10)
      : 5;
    //@ts-ignore
    this.enableScreenshot = this.props.environment.REACT_APP_ENABLESCREENSHOT
      ? this.props.environment.REACT_APP_ENABLESCREENSHOT.status
      : false;
    //@ts-ignore
    this.screenControl = this.props.environment.REACT_APP_SCREENCONTROL
      ? this.props.environment.REACT_APP_SCREENCONTROL.status
      : false;

    this.screenControl = this.props.enableDesktopApp
      ? this.props.enableDesktopApp
      : this.screenControl;

    //@ts-ignore
    // this.faceDetect = this.props.environment.REACT_APP_FACE_DETECTION
    //   ? this.props.environment.REACT_APP_FACE_DETECTION.status
    //   : false;
    //@ts-ignore
    this.isRecordingEnabled = this.props.environment.REACT_APP_RECORDING_ENABLED
      ? this.props.environment.REACT_APP_RECORDING_ENABLED.status
      : false;
    //@ts-ignore
    this.videoKSVCEncoding = this.props.environment.VIDEO_KSVC_ENCODINGS
      ? this.props.environment.VIDEO_KSVC_ENCODINGS.data
      : VIDEO_KSVC_ENCODINGS;
    //@ts-ignore
    this.videoSimulcastEncodingSm = this.props.environment
      .VIDEO_SIMULCAST_ENCODINGS_SM
      ? this.props.environment.VIDEO_SIMULCAST_ENCODINGS_SM.data
      : VIDEO_SIMULCAST_ENCODINGS_SM;

    this.screenSharingSimulcastEncoding = this.props.environment
      .SCREEN_SHARING_SIMULCAST_ENCODINGS
      ? this.props.environment.SCREEN_SHARING_SIMULCAST_ENCODINGS.data
      : SCREEN_SHARING_SIMULCAST_ENCODINGS;

    //@ts-ignore
    this.emotionDetection = this.props.environment.REACT_APP_EMOTION_DETECTION
      ? this.props.environment.REACT_APP_EMOTION_DETECTION.status
      : REACT_APP_EMOTION_DETECTION;

    this.faceDetectionFlag = this.props.environment.REACT_APP_FACE_DETECTION
      ? this.props.environment.REACT_APP_FACE_DETECTION.status
      : REACT_APP_FACE_DETECTION;

    this.faceDetectionUrl =
      this.props.environment.REACT_APP_FACE_DETECTION &&
      this.props.environment.REACT_APP_FACE_DETECTION.value;

    this.faceVerification = this.props.environment.REACT_APP_FACE_VERIFICATION
      ? this.props.environment.REACT_APP_FACE_VERIFICATION.status
      : REACT_APP_FACE_VERIFICATION;

    this.cellphoneDetection = this.props.environment
      .REACT_APP_CELLPHONE_DETECTION
      ? this.props.environment.REACT_APP_CELLPHONE_DETECTION.status
      : REACT_APP_CELLPHONE_DETECTION;

    //@ts-ignore
    this.detectionToken = this.props.environment.REACT_APP_DETECTION_TOKEN
      ? this.props.environment.REACT_APP_DETECTION_TOKEN.value
      : REACT_APP_DETECTION_TOKEN;
    //@ts-ignore
    this.storeDetectionImages = this.props.environment.STORE_DETECTION_IMAGES
      ? this.props.environment.STORE_DETECTION_IMAGES
      : STORE_DETECTION_IMAGES;
    //@ts-ignore
    this.cellphoneFaceDetectSeconds = this.props.environment
      .CELLPHONE_FACE_DETECTION_SECONDS
      ? this.props.environment.CELLPHONE_FACE_DETECTION_SECONDS.value
      : 10;
    //@ts-ignore
    this.screenHeight = this.props.environment.SCREEN_SHARE_HEIGHT
      ? this.props.environment.SCREEN_SHARE_HEIGHT.value
      : SCREEN_SHARE_HEIGHT;
    //@ts-ignore
    this.screenWidth = this.props.environment.SCREEN_SHARE_WIDTH
      ? this.props.environment.SCREEN_SHARE_WIDTH.value
      : SCREEN_SHARE_WIDTH;
    //@ts-ignore
    this.cameraAutoOn = this.props.environment.AGENT_CAM_AUTO_ON
      ? this.props.environment.AGENT_CAM_AUTO_ON.status
      : AGENT_CAM_AUTO_ON;

    //@ts-ignore
    this.emotionDetectionAdminControl = this.props.environment
      .EMOTION_DETECTION_ADMIN_CONTROL
      ? this.props.environment.EMOTION_DETECTION_ADMIN_CONTROL.status
      : EMOTION_DETECTION_ADMIN_CONTROL;
    this.headPoseDetect = this.props.environment.REACT_APP_HEAD_POSE
      ? this.props.environment.REACT_APP_HEAD_POSE.status
      : REACT_APP_HEAD_POSE;
    this.agentCamAlwaysOn = this.props.environment.AGENT_CAM_ALWAYS_ON
      ? this.props.environment.AGENT_CAM_ALWAYS_ON.status
      : AGENT_CAM_ALWAYS_ON;

    this.blurOption = this.props.environment.VIDEO_BACKGROUND_BLUR
      ? this.props.environment.VIDEO_BACKGROUND_BLUR.status
      : VIDEO_BACKGROUND_BLUR;

    this.customBgImg = this.props.environment.VIDEO_BACKGROUND_IMAGES
      ? this.props.environment.VIDEO_BACKGROUND_IMAGES.status
      : VIDEO_BACKGROUND_IMAGES;

    this.lunchTimeLimitStatus = this.props.environment.LUNCH_TIME_LIMIT_MINUTES
      ? this.props.environment.LUNCH_TIME_LIMIT_MINUTES.status
      : LUNCH_TIME_LIMIT_MINUTES.status;

    this.lunchTimeLimit = this.props.environment.LUNCH_TIME_LIMIT_MINUTES
      ? this.props.environment.LUNCH_TIME_LIMIT_MINUTES.value
      : LUNCH_TIME_LIMIT_MINUTES.value;

    this.breakTimeLimitStatus = this.props.environment.BREAK_TIME_LIMIT_MINUTES
      ? this.props.environment.BREAK_TIME_LIMIT_MINUTES.status
      : BREAK_TIME_LIMIT_MINUTES.status;

    this.breakTimeLimit = this.props.environment.BREAK_TIME_LIMIT_MINUTES
      ? this.props.environment.BREAK_TIME_LIMIT_MINUTES.value
      : BREAK_TIME_LIMIT_MINUTES.value;

    this.brbTimeLimitStatus = this.props.environment.BRB_TIME_LIMIT_MINUTES
      ? this.props.environment.BRB_TIME_LIMIT_MINUTES.status
      : BRB_TIME_LIMIT_MINUTES.status;

    this.brbTimeLimit = this.props.environment.BRB_TIME_LIMIT_MINUTES
      ? this.props.environment.BRB_TIME_LIMIT_MINUTES.value
      : BRB_TIME_LIMIT_MINUTES.value;
    this.amIsharingScreen = false;

    this.multipleSupervisorsStatus();

    this.webRtcIssueDetector = new WebRTCIssueDetector({
      detectors: [
        new QualityLimitationsIssueDetector(),
        new FramesDroppedIssueDetector(),
        new FramesEncodedSentIssueDetector(),
        new InboundNetworkIssueDetector(),
        new OutboundNetworkIssueDetector(),
        new NetworkMediaSyncIssueDetector(),
        new AvailableOutgoingBitrateIssueDetector(),
        new UnknownVideoDecoderImplementationDetector(),
      ],
      getStatsInterval: 30_000, // set custom stats parsing interval
      onIssues: (payload: IssueDetectorResult) => {
        // callback for detected issues handling
        // Pass issue details to BE for futher processing
        console.log("WID webrtc issue: ", payload);
        socketInstance.sendMessage("WID_ISSUE_DETECTED", {
          roomname: this.roomname,
          username: this.props.userName,
          payload,
        });
      },
      onNetworkScoresUpdated: (payload: NetworkScores) => {
        // callback for networks score updates handling
        // Pass Network Score to BE for futher processing'
        console.log("WID network score update: ", payload);
        if (payload.outbound !== undefined || payload.inbound !== undefined) {
          socketInstance.sendMessage("WID_NETWORK_SCORE", {
            roomname: this.roomname,
            username: this.props.userName,
            payload,
          });
        }
      },
      ignoreSSRCList: [
        // in case you need to skip some ssrc from parsing, add its numbers to the array
      ],
    });
  }

  componentCleanup() {
    // this will hold the cleanup code
    socketInstance.socket = undefined;
    this.props.setMySocketId("");
    console.log("componentCleanup");
  }

  componentDidMount() {
    this.username = this.props.userName;
    this.props.getUserDetails(this.roomname);
    this.props.getLayoutSettingActions(this.props.userName, "room");
    this.props.setActivityParentId("whole-room");
    this.messageSubscriber = socketInstance
      .getMessages()
      .subscribe((message: string) => {
        this.processMessage(JSON.parse(message));
      });
    this.props.setCurrentViewMode(this.props.viewMode, "");
    const fetchConnectionDetails = async () => {
      const connectionDetails: IConnectionDetails = await getConnectionDetails(
        this.roomname
      );

      this.setState({
        reminderState: true,
      });

      this.setState({});
      // this.setState({
      //   sideBarOpen:this.props.isSidebarOpen
      // })

      let hostname = window.location.origin;

      if (hostname.includes("http://localhost")) {
        hostname = "http://localhost:8080";
      }

      if (hostname.includes("https://localhost")) {
        hostname = "https://localhost:8080";
      }

      console.log(
        hostname,
        connectionDetails.path,
        connectionDetails.payload,
        connectionDetails.iv,
        connectionDetails.digest,
        this.props.viewMode,
        "realage"
      );

      if (!socketInstance.socket) {
        socketInstance.connectToSocket(
          hostname,
          connectionDetails.path,
          connectionDetails.payload,
          connectionDetails.iv,
          connectionDetails.digest,
          this.props.viewMode
        );
      }

      if (this.props.troubleshoot) {
        watchRTC.init();

        watchRTC.setConfig({
          rtcApiKey: `staging:${process.env.REACT_APP_RTC_API_KEY}`,
          rtcRoomId: this.roomname + this.props.userName,
          rtcPeerId: this.props.userName,
          rtcTags: [
            this.props.currentUserRole,
            this.props.userName,
            this.roomname,
          ],
        });
      }
    };
    if (this.username === "") {
      console.log("username not found");
      this.goToDashboard();
    } else {
      fetchConnectionDetails();
    }

    // if (!this.worker) this.worker = new Worker("/js/worker.js");
    // if (!this.objectDetectionWorker)
    //   this.objectDetectionWorker = new Worker("/js/objectDetectionWorker.js");
    //
    // this.objectDetectionWorker.postMessage({
    //   message: "loadModel",
    //   cdnUrl: config.cdnUrl,
    // });

    // this.worker.addEventListener("message", (msg: any) => {
    //   const detections = msg.data?.result;
    //   // socketInstance.sendMessage("FACE_DETECTED", {
    //   //   detections,
    //   //   emotionDetection: config.humanApi.face.emotion.enabled,
    //   //   time: Date.now(),
    //   // });
    //   if (detections) this.faceException(detections.face);
    // });
    //
    this.loadTFLite();

    // this.loadTFLiteModel();
    // this.objectDetectionWorker.addEventListener("message", (msg: any) => {
    //   const result = msg.data;
    // socketInstance.sendMessage("CELLPHONE_DETECTED", {
    //   ...result,
    // });
    // });

    // window.addEventListener("beforeunload", this.onUnMount); // remove the event handler for normal unmounting
    navigator.mediaDevices.addEventListener("devicechange", () => {
      this.detectConnectedDevice();
    });
    this.componentRef = 1;

    // WID
    // start collecting getStats() and detecting issues
    this.webRtcIssueDetector.watchNewPeerConnections();

    this.audioAllowedInRoom = this.props.audioAllowedInRoom
    this.allowPrivateCallinSilentRoom = this.props.allowPrivateCallinSilentRoom;
  }

  componentWillUnmount() {
    console.log("willunmount");
    this.username = "";
    if (replaceVideoTimeout) {
      clearTimeout(replaceVideoTimeout);
    }
    this.disconnectLocalHark();
    // clearInterval(this.frstart?._id);
    clearInterval(this.frstart);
    this.props.setVideoOption("normal");
    this.props.setViewMode("");
    this.props.clearChatData();
    // this.props.removeScaleResolution();
    this.props.verifyFace(true);
    this.props.wrongPersonCheck(false);
    this.props.setRecurrentCheck(false);
    this.worker?.terminate();
    this.objectDetectionWorker?.terminate();

    // clearInterval(this.networkMonitoringInterval);
    // clearTimeout(this.sessionActiveTimeout);
    this.props.setSelectedChatUser(null, null, null, "EVERYONE");
    if (this.props.controlId !== "") {
      socketInstance.sendMessage("CONTROL_DISCONNECT_BY_SUPERVISORUSER", {});
      this.props.clearControlId();
    }
    this.stopTrack();

    this.messageSubscriber.unsubscribe();
    socketInstance.closeSocketConnection();
    socketInstance.socket = undefined;
    this.props.setMySocketId("");
    window.removeEventListener("beforeunload", this.onUnMount); // remove the event handler for normal unmounting
    navigator.mediaDevices.removeEventListener("devicechange", () => {
      this.detectConnectedDevice();
    });
    this.componentRef = null;

    socketInstance.reconnectFlushState();

    // stop collecting WebRTC stats and issues detection
    this.webRtcIssueDetector.stopWatchingNewPeerConnections();
  }

  componentDidUpdate(prevProps: IRoomPageProps, prevState: IRoomPageState) {
    if (prevProps.isSidebarOpen !== this.props.isSidebarOpen) {
      this.setState({ sideBarOpen: this.props.isSidebarOpen });
    }

    if (
      prevState.tflite !== this.state.tflite ||
      prevState.tfliteSIMD !== this.state.tfliteSIMD ||
      prevState.isSIMDSupported !== this.state.isSIMDSupported ||
      prevState.segmentationConfig.model !==
      this.state.segmentationConfig.model ||
      prevState.segmentationConfig.backend !==
      this.state.segmentationConfig.backend ||
      prevState.segmentationConfig.inputResolution !==
      this.state.segmentationConfig.inputResolution
    ) {
      this.loadTFLiteModel();
    }

    if (prevProps.videoOption !== this.props.videoOption) {
      setTimeout(() => {
        let track;
        if (this.props.videoOption === "normal") {
          const node: HTMLVideoElement = document.getElementsByClassName(
            "face-detection-node"
          )[0] as HTMLVideoElement;
          // @ts-ignore
          track = node?.srcObject?.getVideoTracks()[0];
        } else {
          const node = document.getElementById("blur");

          // @ts-ignore
          track = node?.captureStream()?.getVideoTracks()[0];
        }
        this.replaceVideoTrack(track);
      }, 200);
    }

    /*
     * THIS CASE IS FOR REMOTE PEERS. FOR CURRENT PEER, IT IS HANDELED IN
     * shareUnshareScreen() Function. For Default case, check getEncodings()
     * function & lowering res for Current peer is handeled in produceType = "SCREEN_PRODUCE";
     * block of "produce" method.
     */
    if (prevProps.screenCollabPeerArray !== this.props.screenCollabPeerArray) {
      const { screenCollabPeerArray, viewMode } = this.props;

      if (viewMode === roomViewModeConst.collabMode) {
        // Lower the video resolution because screen share started
        if (
          !prevProps.screenCollabPeerArray.length &&
          screenCollabPeerArray.length
        ) {
          this.scaleDownResolutionMethod(
            mediaTypeConstant.video,
            false,
            resolutionConstant.low
          );

          // Higher the video resolution because no one is sharing screen
        } else if (
          prevProps.screenCollabPeerArray.length &&
          !screenCollabPeerArray.length
        ) {
          if (this.amIsharingScreen) {
            this.scaleDownResolutionMethod(
              mediaTypeConstant.video,
              false,
              resolutionConstant.low
            );
          } else {
            this.scaleDownResolutionMethod(
              mediaTypeConstant.video,
              false,
              resolutionConstant.high
            );
          }
        }
      }
    }
  }

  onUnMount() {
    this.disconnectLocalHark();
    // clearInterval(this.frstart?._id);
    clearInterval(this.frstart);
    this.props.setVideoOption("normal");
    this.worker?.terminate();
    this.objectDetectionWorker?.terminate();
    // clearInterval(this.networkMonitoringInterval);
    // clearTimeout(this.sessionActiveTimeout);

    this.props.setSelectedChatUser(null, null, null, "EVERYONE");

    if (this.props.controlId !== "") {
      socketInstance.sendMessage("CONTROL_DISCONNECT_BY_SUPERVISORUSER", {});
      this.props.clearControlId();
    }

    if (this.messageSubscriber) {
      this.messageSubscriber.unsubscribe();
    }

    if (socketInstance) {
      socketInstance.closeSocketConnection();
    }

    socketInstance.reconnectFlushState();
  }

  stopTrack = () => {
    if (this.state.localVideoStream) {
      this.state.localVideoStream.getVideoTracks()[0].stop();
    }
    if (this.videoProducer) {
      this.videoProducer.close();
      // @ts-ignore
      this.videoProducer = null;
    }

    if (this.audioProducer) {
      this.audioProducer.close();
      // @ts-ignore
      this.audioProducer = null;
    }
    if (this.state.localAudioStream) {
      this.state.localAudioStream.getAudioTracks().forEach(function (track) {
        track.stop();
      });
    }
  };

  replaceVideoTrack = async (track: MediaStreamTrack) => {
    if (track) await this.videoProducer.replaceTrack({ track: track.clone() });
  };

  replaceAudioTrack = async (track: MediaStreamTrack) => {
    if (track) await this.audioProducer.replaceTrack({ track: track.clone() });
  };

  combineSystemAndMicAudioTracks = (
    systemAudioTrack: MediaStreamTrack,
    micAudioTrack: MediaStreamTrack
  ): MediaStreamTrack => {
    var systemAudioStream = new MediaStream();
    systemAudioStream.addTrack(systemAudioTrack);

    var micAudioStream = new MediaStream();
    micAudioStream.addTrack(micAudioTrack);

    const audioContext = new AudioContext();

    const systemAudioIn =
      audioContext.createMediaStreamSource(systemAudioStream);
    const micAudioIn = audioContext.createMediaStreamSource(micAudioStream);

    const dest = audioContext.createMediaStreamDestination();

    systemAudioIn.connect(dest);
    micAudioIn.connect(dest);

    const finalStream = dest.stream;

    return finalStream.getAudioTracks()[0];
  };

  loadTFLite = async () => {
    if (createTFLiteModule) {
      createTFLiteModule().then((result) => {
        this.setState({
          tflite: result,
        });
      });
      try {
        const createdTFLiteSIMD = await createTFLiteSIMDModule();
        this.setState({
          tfliteSIMD: createdTFLiteSIMD,
          isSIMDSupported: true,
        });
      } catch (error) {
        console.warn("Failed to create TFLite SIMD WebAssembly module.", error);
      }
    }
  };

  loadTFLiteModel = async () => {
    if (
      !this.state.tflite ||
      (this.state.isSIMDSupported && !this.state.tfliteSIMD) ||
      (!this.state.isSIMDSupported &&
        this.state.segmentationConfig.backend === "wasmSimd") ||
      (this.state.segmentationConfig.model !== "meet" &&
        this.state.segmentationConfig.model !== "mlkit")
    ) {
      return;
    }

    this.setState({
      selectedTFLite: undefined,
    });

    const newSelectedTFLite =
      this.state.segmentationConfig.backend === "wasmSimd"
        ? this.state.tfliteSIMD
        : this.state.tflite;

    if (!newSelectedTFLite) {
      throw new Error(
        `TFLite backend unavailable: ${this.state.segmentationConfig.backend}`
      );
    }

    const modelFileName = getTFLiteModelFileName(
      this.state.segmentationConfig.model,
      this.state.segmentationConfig.inputResolution
    );

    const modelResponse = await fetch(
      `${config.cdnUrl}/models/${modelFileName}.tflite`
    );

    const model = await modelResponse.arrayBuffer();

    const modelBufferOffset = newSelectedTFLite._getModelBufferMemoryOffset();
    console.log("Model buffer memory offset:", modelBufferOffset);
    console.log("Loading model buffer...");
    newSelectedTFLite.HEAPU8.set(new Uint8Array(model), modelBufferOffset);
    console.log(
      "_loadModel result:",
      newSelectedTFLite._loadModel(model.byteLength)
    );

    console.log("Input height:", newSelectedTFLite._getInputHeight());
    console.log("Input width:", newSelectedTFLite._getInputWidth());
    console.log("Input channels:", newSelectedTFLite._getInputChannelCount());

    console.log(
      "Output memory offset:",
      newSelectedTFLite._getOutputMemoryOffset()
    );
    console.log("Output height:", newSelectedTFLite._getOutputHeight());
    console.log("Output width:", newSelectedTFLite._getOutputWidth());
    console.log("Output channels:", newSelectedTFLite._getOutputChannelCount());

    this.setState({
      selectedTFLite: newSelectedTFLite,
    });
  };

  callModalOpenAndClose = () => {
    this.setState({
      callModalOption: {
        ...this.state.callModalOption,
        isCallModal: !this.state.callModalOption.isCallModal,
      },
    });
  };

  notifyMe = (message: string) => {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      toast.error("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      new Notification(message);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          new Notification(message);
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  };

  getRouterRtpCaps = async () => {
    try {
      const data = (await socketInstance.sendMessage(
        "GET_ROUTER_RTP_CAPABILITIES",
        {
          roomname: this.roomname,
        }
      )) as RtpCapabilities;
      await this.loadDevice(data);
    } catch (error) {
      this.setState({});
      console.error(error);
    }
  };

  loadDevice = async (
    routerRtpCapabilities: mediasoupTypes.RtpCapabilities
  ): Promise<void> => {
    try {
      this.device = new Device();
    } catch (error: any) {
      if (error.name === "UnsupportedError") {
        const message = "Browser not supported";
        console.error(message);
        this.addErrorMessage(message);
      }
    }
    if (this.device && !this.device.loaded) {
      await this.device.load({ routerRtpCapabilities });
    }
  };

  createPublishingTransport = async () => {
    if (this.publishTransport) {
      this.publishTransport.close();
    }
    if (this.device && !this.device.loaded) {
      await this.getRouterRtpCaps();
    }
    let publishTransportData: any;
    try {
      publishTransportData = (await socketInstance.sendMessage(
        "CREATE_PRODUCER_TRANSPORT",
        {
          forceTcp: false,
          rtpCapabilities: this.device.rtpCapabilities,
          roomname: this.roomname,
        }
      )) as mediasoupTypes.TransportOptions;
      publishTransportData.iceServers = [];
      publishTransportData.iceServers.push({
        urls: "stun:stun.l.google.com:19302",
      });
      if (publishTransportData.turnIps) {
        publishTransportData.turnIps.forEach((turnIp: string) => {
          publishTransportData.iceServers.push({
            urls: `turn:${turnIp.trim()}:443?transport=tcp`,
            credential: publishTransportData.turnPassword,
            username: publishTransportData.turnUserId,
          });
          publishTransportData.iceServers.push({
            urls: `turn:${turnIp.trim()}:443?transport=udp`,
            credential: publishTransportData.turnPassword,
            username: publishTransportData.turnUserId,
          });
        });
      }
      //SM for testing
      //publishTransportData.iceTransportPolicy = "relay";
    } catch (error: any) {
      console.error(error);
      this.addErrorMessage(error.message);
    }

    this.publishTransport =
      this.device.createSendTransport(publishTransportData);
    this.publishTransport.on(
      "connect",
      async (
        { dtlsParameters }: mediasoupTypes.TransportOptions,
        callback: () => void,
        errback: (err: Error) => void
      ) => {
        try {
          await socketInstance.sendMessage("CONNECT_PRODUCER_TRANSPORT", {
            dtlsParameters,
            roomname: this.roomname,
          });
          callback();
        } catch (error: any) {
          errback(error);
        }
      }
    );

    this.publishTransport.on(
      "produce",
      async (
        {
          kind,
          rtpParameters,
          appData,
        }: {
          kind: any;
          rtpParameters: RTCRtpParameters;
          appData: Record<string, string>;
        },
        callback: (value: object) => void,
        errback: (err: Error) => void
      ) => {
        try {
          let produceType;
          if (appData.kind === "video") {
            produceType = "VIDEO_PRODUCE";
          } else if (
            appData.kind === "audio" &&
            appData.audioNum !== "System"
          ) {
            produceType = "AUDIO_PRODUCE";
          } else if (
            appData.kind === "audio" &&
            appData.audioNum === "System"
          ) {
            produceType = "SCREEN_AUDIO_PRODUCE";
          } else {
            produceType = "SCREEN_PRODUCE";

            // Lower the Video Resolution of current Peer because starting screen share
            if (this.props.viewMode === roomViewModeConst.collabMode) {
              this.scaleDownResolutionMethod(
                mediaTypeConstant.video,
                false,
                resolutionConstant.low
              );
            }
            this.amIsharingScreen = true;
          }
          const produceTypeData = {
            roomname: this.roomname,
            rtpParameters,
            kind: kind,
          };

          const produceTypeTwoAudioData = {
            roomname: this.roomname,
            rtpParameters,
            kind: kind,
            audioNum: appData.audioNum,
          };

          const produceScreenTwoTypeData = {
            roomname: this.roomname,
            rtpParameters,
            kind: kind,
            screenNum: appData.screenNum,
          };
          const producerId = await socketInstance.sendMessage(
            produceType,
            appData.screenNum
              ? produceScreenTwoTypeData
              : appData.audioNum
                ? produceTypeTwoAudioData
                : produceTypeData
          );

          callback({ producerId });
        } catch (error: any) {
          console.error(error);
          errback(error);
        }
      }
    );

    this.publishTransport.on("connectionstatechange", async (state: string) => {
      switch (state) {
        case "connecting":
          console.log("ice connecting for publishing");
          break;
        case "connected":
          console.log("iceconnected  for publishing");
          let webrtcIPfetched = localStorage.getItem("webrtcipfetched");

          if (webrtcIPfetched === "false") {
            const stats = await this.publishTransport.getStats();
            let publicIPofUser;
            stats.forEach((stat, key) => {
              if (
                stat.type === "local-candidate" &&
                stat.candidateType === "srflx" &&
                stat.url
              ) {
                console.log("JD IN SRFLX", stat);
                publicIPofUser = stat.ip;
                processIPfromWebrtc(publicIPofUser);
              } else {
                // Localhost case (for dev)
                if (
                  stat.type === "local-candidate" &&
                  stat.candidateType === "prflx"
                ) {
                  console.log("JD IN PRFLX", stat);
                  publicIPofUser = stat.ip;
                  processIPfromWebrtc(publicIPofUser);
                }
              }
            });
          } else {
            console.warn(
              "Not Fetching IP ADDRESS from webrtc because its already fetched."
            );
          }

          // TODO set the local stream into the localvideo tag or audio tag based
          // on camera present or not
          break;
        case "disconnected":
          console.log("disconnection resgister");
          if (this.roomSocket && this.roomSocket.connected) {
            console.log("iceDisconnected  for publishing");
            const iceParameters = (await socketInstance.sendMessage(
              "RESTART_ICE_PRODUCER",
              {
                roomname: this.roomname,
              }
            )) as mediasoupTypes.IceParameters;

            // console.log("iceDisconnected");
            // this.props.setRoomOnRestartIce(this.roomname);

            if (this.publishTransport) {
              this.publishTransport.restartIce({ iceParameters });
            }

            // this.props.clearTempRoomUser();

            // this.props.history.push("/dashboard");
          }
          break;
        case "failed":
          if (this.publishTransport) this.publishTransport.close();
          // Please show an error message. if case failed, out streams are not reaching to server.
          this.addErrorMessage(
            "Publish Transport Connection state failed. Stream not reaching the server"
          );
          break;
        default:
          break;
      }
    });

    this.startVideo();
    // Allow Audio for Everyone based on SilentRoom(AudioAllowedInRoom) setting
    if (this.audioAllowedInRoom || this.audioAllowedInRoom === undefined || (!this.audioAllowedInRoom && this.allowPrivateCallinSilentRoom)) {
      if (!this.audioAllowedInRoom && this.allowPrivateCallinSilentRoom) {
        toast(`Mic will be muted. Private call allowed.`);
      }
      this.startAudio();
    } else {
      toast(`Audio is not allowed in this room`);
    }

    // this.startScreen();
    if (checkForSupManAdmin(this.props.currentUserRole, "notEqualTo")) {
      if (convertIntoBoolean(this.screenControl)) {
        socketInstance.sendMessage("REQUEST_OFFER", {
          roomname: this.roomname,
        });
      }
    }
  };

  getEncodings = () => {
    return [
      {
        maxBitrate: this.props.videoScale.maxBitrate,
        scaleResolutionDownBy:
          (checkForSupManAdmin(this.props.currentUserRole, "equalTo") &&
            this.props.viewMode === roomViewModeConst.lectureMode) ||
            this.roomSocket.id === this.state.presenterForAgent ||
            (this.props.viewMode === roomViewModeConst.collabMode &&
              !this.props.screenCollabPeerArray.length) // If no one sharing screen then produce HIGH RES Video in collab room
            ? this.props.videoScale.HIGH
            : this.props.videoScale.LOW,
      },
    ];

    // let encodings: Record<string, string | number>[];
    // const firstVideoCodec = this.device.rtpCapabilities.codecs?.find(
    //   (c: mediasoupTypes.RtpCodecCapability) => {
    //     return c.kind === "video";
    //   }
    // );
    // if (firstVideoCodec?.mimeType.toLowerCase() === "video/vp9") {
    //   encodings = this.videoKSVCEncoding;
    // } else {
    //   encodings = this.videoSimulcastEncodingSm;
    // }
    // return encodings;
  };

  getEncodingsForScreen = () => {
    return [
      {
        maxBitrate: this.props.screenScale.maxBitrate,
        scaleResolutionDownBy:
          checkForSupManAdmin(this.props.currentUserRole, "equalTo") ||
            this.props.viewMode === roomViewModeConst.collabMode ||
            this.roomSocket.id === this.state.presenterForAgent
            ? this.props.screenScale.HIGH
            : this.props.screenScale.LOW,
      },
    ];
  };

  startVideo = async () => {
    try {
      this.videoDeviceFound = await checkVideoDevices();
      if (this.videoDeviceFound) {
        if (this.videoProducer) {
          this.videoProducer.close();
        }

        if (this.props.activeVideoDevice !== "") {
          const localVideoStream = await getVideoStream(
            this.props.activeVideoDevice
          );

          this.videoProducer = await this.publishTransport.produce({
            track: localVideoStream.getVideoTracks()[0],
            encodings: this.getEncodings(),
            zeroRtpOnPause: true,
            codecOptions: {
              videoGoogleMaxBitrate: 1000,
            },
            // Removing h264
            //      codec: this.device?.rtpCapabilities?.codecs?.find(
            //      (codec) => codec.mimeType.toLowerCase() === "video/h264"
            //  ),
            appData: { kind: "video" },
          });

          if (this.videoProducer && this.videoProducer.rtpSender) {
            let videoParams = this.videoProducer.rtpSender.getParameters();

            //@ts-ignore

            videoParams.encodings[0].priority = "high";
            videoParams.degradationPreference = "maintain-resolution";

            await this.videoProducer.rtpSender.setParameters(videoParams);
          }

          // // @ts-ignore
          // if(this.videoProducer.rtpParameters.rtcp.cname) {
          //   // @ts-ignore
          //   watchRTC.mapStream(this.videoProducer.rtpParameters.rtcp.cname, this.props.userName);
          //   console.log('video producer this',this.videoProducer.rtpParameters.rtcp);
          // }

          // const publishStats = await this.publishTransport.getStats();
          // const obj = Object.fromEntries(publishStats);
          // const objKeys = Object.keys(obj);
          // const filteredKey = objKeys.filter((key) =>
          //   key.includes("RTCMediaStream_")
          // );

          // const RTCMediaStream = filteredKey[0];
          // const streamIdentifier = RTCMediaStream.split("_");

          // if (this.props.troubleshoot && streamIdentifier[1]) {
          //   watchRTC.mapStream(streamIdentifier[1], this.props.userName);
          // }

          // if (this.props.currentUserRole !== "Supervisor") {
          // await this.loadModels();
          // await this.faceDetection();
          // }
          // pause only when recording is not enabled
          if (!this.isRecordingEnabled) {
            this.videoProducer.pause();
            this.setState({ localVideoPaused: true });
          }
          this.setState(
            {
              localVideoStream,
            },
            () => {
              if (
                this.isreconnectionStatusVideo &&
                // !this.cameraAutoOn ||
                checkForSupManAdmin(this.props.currentUserRole, "equalTo")
              ) {
                this.pauseUnPauseVideo(this.roomSocket.id);
                this.isreconnectionStatusVideo = false;
              }
            }
          );
        }
        // Automatically Turn ON video of agent on room enter IF this setting is enabled from admin (AGENT_CAM_AUTO_ON)
        if (
          checkForSupManAdmin(this.props.currentUserRole, "notEqualTo") &&
          (this.cameraAutoOn || this.agentCamAlwaysOn)
        ) {
          this.pauseUnPauseVideo(this.roomSocket.id);
        }
      }
    } catch (error) {
      console.error(error);
      // this.addErrorMessage(error.message);
      // Please make sure to handle this error
    }
  };

  startAudio = async () => {
    try {
      if (this.audioProducer) {
        this.audioProducer.close();
      }

      if (this.props.activeAudioDevice !== "") {
        const localAudioStream = await getAudioStream(
          this.props.activeAudioDevice
        );

        const track = localAudioStream.getAudioTracks()[0].clone();

        const {
          opusStereo = false,
          opusDtx = true,
          opusFec = true,
          opusPtime = 20,
          opusMaxPlaybackRate = 96000,
        } = config.centralAudioOptions;
        this.audioProducer = await this.publishTransport.produce({
          track,
          zeroRtpOnPause: true,
          codecOptions: {
            opusStereo,
            opusDtx,
            opusFec,
            opusPtime,
            opusMaxPlaybackRate,
          },
          appData: { kind: "audio" },
        });

        // // @ts-ignore
        // if(this.audioProducer.rtpParameters.rtcp.cname) {
        //   console.log('audio producer this',this.audioProducer.rtpParameters.rtcp);
        //   // @ts-ignore
        //   watchRTC.mapStream(this.audioProducer.rtpParameters.rtcp.cname, this.props.userName);
        // }

        // const publishStats = await this.publishTransport.getStats();
        // const obj = Object.fromEntries(publishStats);
        // const objKeys = Object.keys(obj);
        // const filteredKey = objKeys.filter((key) =>
        //   key.includes("RTCMediaStream_")
        // );
        // const RTCMediaStream = filteredKey[0];
        // const streamIdentifier = RTCMediaStream.split("_");

        // if (this.props.troubleshoot && streamIdentifier[1]) {
        //   watchRTC.mapStream(streamIdentifier[1], this.props.userName);
        // }

        //pause only when recording is disabled
        if (!this.isRecordingEnabled) {
          this.audioProducer.pause();
          this.setState({ localAudioPaused: true });
        }
        this.setState(
          {
            localAudioStream,
          },
          () => {
            if (this.isreconnectionStatusAudio) {
              this.pauseUnPauseAudio(this.roomSocket.id);
              this.isreconnectionStatusAudio = false;
            }
          }
        );
      } else {
        socketInstance.sendMessage("CREATE_REMOTE_PEERS", {});
      }
    } catch (error) {
      console.error(error);
      // TODO AMIT
      // Please make sure to handle this error
    }
  };

  startAudioForFile = async (id: string, peerSessionId: string) => {
    try {
      if (this.audioFileProducer) {
        this.audioFileProducer.close();
      }

      const audioEl = document.getElementById(id) as any;

      const localAudioFileStream = audioEl.captureStream();
      // const localAudioStream = localScreenStream.getVideoTracks()[0].clone();

      // var ctx = new AudioContext();
      // // create an source node from the <video>
      // var source = ctx.createMediaElementSource(audioEl);
      // // now a MediaStream destination node
      // var stream_dest = ctx.createMediaStreamDestination();
      // // connect the source to the MediaStream
      // source.connect(stream_dest);

      // const localAudioStream = stream_dest.stream;

      this.setState({
        localAudioFileStream,
        localAudioFilePause: true,
      });

      this.props.setAudioFile(true);
      const track = localAudioFileStream.getAudioTracks()[0].clone();

      const {
        opusStereo = false,
        opusDtx = true,
        opusFec = true,
        opusPtime = 20,
        opusMaxPlaybackRate = 96000,
      } = config.centralAudioOptions;
      this.audioFileProducer = await this.publishTransport.produce({
        track,
        zeroRtpOnPause: true,
        codecOptions: {
          opusStereo,
          opusDtx,
          opusFec,
          opusPtime,
          opusMaxPlaybackRate,
        },
        appData: { kind: "audio", audioNum: "File" },
      });

      socketInstance.sendMessage("AUDIO_RESUME", {
        roomname: this.roomname,
        peerSessionId,
        audioNum: "File",
      });
    } catch (error) {
      console.error(error);
      // TODO AMIT
      // Please make sure to handle this error
    }
  };

  startVideoForFile = async () => {
    try {
      if (this.screenProducer) {
        this.screenProducer.close();
      }

      if (this.screenAudioProducer) {
        this.screenAudioProducer.close();
        this.setState({
          isScreenAudioShared: false,
        });
      }

      const videoEl = document.getElementById("videosss") as any;

      const localScreenStream = videoEl.captureStream();
      const localScreenTrack = localScreenStream.getVideoTracks()[0];
      // localScreenTrack.onended = () => {
      //   socketInstance.sendMessage("SCREEN_STOP", {
      //     roomname: this.roomname,
      //   });
      //   localScreenTrack.stop();
      //   if (this.screenProducer) this.screenProducer.close();
      //   this.setState({
      //     localScreenStopped: true,
      //     localScreenStream: undefined,
      //   });

      //   this.props.setVideoFile(false);
      // };
      this.setState({
        localScreenStopped: false,
        localScreenStream,
      });
      this.props.setVideoFile(true);

      //@ts-ignore
      // let encodings = this.screenSharingSimulcastEncoding.map((encoding) => ({
      //   ...encoding,
      //   dtx: true,
      // }));

      this.screenProducer = await this.publishTransport.produce({
        track: localScreenTrack,
        // encodings: undefined,
        codecOptions:
          this.props.currentUserRole === "Agent"
            ? {
              videoGoogleMaxBitrate: 1000,
            }
            : undefined,
        codec: this.device?.rtpCapabilities?.codecs?.find(
          (codec) => codec.mimeType.toLowerCase() === "video/vp8"
        ),
        appData: { kind: "screen" },
      });
    } catch (error: any) {
      console.error(error);
      // TODO AMIT
      // Please make sure to handle this error
    }
  };

  // TODO AMIT
  // Please set all the streams in the state for own user.
  startScreen = async (remoteStreamEvent?: any) => {
    try {
      if (this.screenProducer) {
        this.screenProducer.close();
      }

      if (this.screenAudioProducer) {
        this.screenAudioProducer.close();
        // this.setState({
        //   isScreenAudioShared: false,
        // });
      }

      let localScreenStream;
      if (remoteStreamEvent) {
        localScreenStream = remoteStreamEvent.streams[0];
      } else {
        console.log(this.reconnectionStatusInRoompage, "");
        if (this.reconnectionStatusInRoompage) {
          localScreenStream = this.state.localScreenStream;
        } else {
          localScreenStream = await getScreenStream(
            // this.screenWidth,
            SCREEN_SHARE_WIDTH,
            SCREEN_SHARE_HEIGHT
            // this.screenHeight,
          );
        }
      }

      const localScreenTrack = localScreenStream.getVideoTracks()[0].clone();

      if (localScreenStream.getAudioTracks().length) {
        const localScreenAudioTrack = localScreenStream
          .getAudioTracks()[0]
          .clone();
        const {
          opusStereo = false,
          opusDtx = true,
          opusFec = true,
          opusPtime = 20,
          opusMaxPlaybackRate = 96000,
        } = config.centralAudioOptions;

        this.screenAudioProducer = await this.publishTransport.produce({
          track: localScreenAudioTrack,
          zeroRtpOnPause: true,
          codecOptions: {
            opusStereo,
            opusDtx,
            opusFec,
            opusPtime,
            opusMaxPlaybackRate,
          },
          appData: { kind: "audio", audioNum: "System" },
        });
        this.setState({
          isScreenAudioShared: true,
        });

        localScreenAudioTrack.onended = () => {
          socketInstance.sendMessage("SYSTEM_AUDIO_STOP", {
            roomname: this.roomname,
          });
          localScreenAudioTrack.stop();

          if (this.screenAudioProducer) {
            this.screenAudioProducer.close();
          }

          this.setState({
            isScreenAudioShared: false,
          });
        };
      }

      // Create a producer for screen
      this.screenProducer = await this.publishTransport.produce({
        track: localScreenTrack,
        encodings: this.getEncodingsForScreen(),
        codecOptions:
          this.props.currentUserRole === "Agent"
            ? {
              videoGoogleMaxBitrate: 1000,
            }
            : undefined,
        codec: this.device?.rtpCapabilities?.codecs?.find(
          (codec) => codec.mimeType.toLowerCase() === "video/vp8"
        ),
        appData: { kind: "screen" },
      });

      localScreenTrack.onended = () => {
        this.amIsharingScreen = false;
        socketInstance.sendMessage("SCREEN_STOP", {
          roomname: this.roomname,
        });
        localScreenTrack.stop();
        if (this.screenProducer) this.screenProducer.close();

        this.setState({
          localScreenStopped: true,
          localScreenStream: undefined,
        });

        // In collab room, keep the lower res if others are sharing screen
        // otherwise change it to High res in case no one is sharing screen
        if (this.props.viewMode === roomViewModeConst.collabMode) {
          if (!this.props.screenCollabPeerArray.length) {
            this.scaleDownResolutionMethod(
              mediaTypeConstant.video,
              false,
              resolutionConstant.high
            );
          } else {
            this.scaleDownResolutionMethod(
              mediaTypeConstant.video,
              false,
              resolutionConstant.low
            );
          }
        }
      };

      if (this.screenProducer && this.screenProducer.rtpSender) {
        let screenParams = this.screenProducer.rtpSender.getParameters();

        //@ts-ignore

        screenParams.encodings[0].priority = "high";
        screenParams.degradationPreference = "maintain-resolution";

        await this.screenProducer.rtpSender.setParameters(screenParams);
      }

      this.setState({
        localScreenStopped: false,
        localScreenStream,
      });

      //@ts-ignore
      // let encodings = this.screenSharingSimulcastEncoding.map((encoding) => ({
      //   ...encoding,
      //   dtx: true,
      // }));
    } catch (error: any) {
      console.error(error);
      // TODO AMIT
      // Please make sure to handle this error
    }
  };

  startScreenTwo = async (remoteStreamEvent?: any) => {
    try {
      if (this.screenTwoProducer) {
        this.screenTwoProducer.close();
      }

      let localScreenTwoStream;
      if (remoteStreamEvent) {
        localScreenTwoStream = remoteStreamEvent.streams[0];
      } else {
        if (this.reconnectionStatusInRoompage) {
          localScreenTwoStream = this.state.localScreenTwoStream;
        } else {
          localScreenTwoStream = await getScreenStream(
            // this.screenWidth,
            SCREEN_SHARE_WIDTH,
            SCREEN_SHARE_HEIGHT
            // this.screenHeight,
          );
        }
      }

      if (localScreenTwoStream.getAudioTracks().length) {
        toast(
          "For the 1st screen, both screen and audio can be shared. For the 2nd screen, only screen sharing is allowed."
        );
      }

      const localScreenTwoTrack = localScreenTwoStream
        .getVideoTracks()[0]
        .clone();
      localScreenTwoTrack.onended = () => {
        socketInstance.sendMessage("SCREEN_STOP", {
          roomname: this.roomname,
          screenNum: "Two",
        });
        localScreenTwoTrack.stop();
        if (this.screenTwoProducer) this.screenTwoProducer.close();
        this.setState({
          localScreenStoppedTwo: true,
          localScreenTwoStream: undefined,
        });
      };
      this.setState({
        localScreenStoppedTwo: false,
        localScreenTwoStream: localScreenTwoStream,
      });

      //@ts-ignore
      // let encodings = this.screenSharingSimulcastEncoding.map((encoding) => ({
      //   ...encoding,
      //   dtx: true,
      // }));
      this.screenTwoProducer = await this.publishTransport.produce({
        track: localScreenTwoTrack,
        encodings: this.getEncodingsForScreen(),
        codecOptions:
          this.props.currentUserRole === "Agent"
            ? {
              videoGoogleMaxBitrate: 1000,
            }
            : undefined,
        codec: this.device?.rtpCapabilities?.codecs?.find(
          (codec) => codec.mimeType.toLowerCase() === "video/vp8"
        ),
        appData: { kind: "screen", screenNum: "Two" },
      });

      if (this.screenTwoProducer && this.screenTwoProducer.rtpSender) {
        let screenTwoParams = this.screenTwoProducer.rtpSender.getParameters();

        //@ts-ignore

        screenTwoParams.encodings[0].priority = "high";
        screenTwoParams.degradationPreference = "maintain-resolution";

        await this.screenTwoProducer.rtpSender.setParameters(screenTwoParams);
      }
    } catch (error: any) {
      console.error(error);
      // TODO AMIT
      // Please make sure to handle this error
    }
  };

  onIceStateChange = async (event: any) => {
    try {
      // Add your code here
    } catch (e) {
      console.log("[onIceStateChange] Exception " + e);
    }
  };

  onIceCandidate = async (event: any) => {
    try {
      if (!event.candidate) {
        // Looks like end of the candidates
        return;
      }

      console.log(
        "[onIceCandidate] candidate " + JSON.stringify(event.candidate)
      );

      socketInstance.sendMessage("CANDIDATE_FROM_FE", {
        roomname: this.roomname,
        jsep: event.candidate,
      });
    } catch (e) {
      console.log("[onIceCandidate] Exception " + e);
    }
  };

  makePrivateCall = async (peerSessionId: string) => {
    const rp = this.getPeerFromRemotePeersArray(peerSessionId);
    let response: any;
    if (rp) {
      if (!rp.isPrivateCallOn) {
        let observeflag = false;

        if (this.state.openentObserverAudioId !== "") {
          observeflag = true;
          socketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
            roomname: this.roomname,
            peerSessionId: this.state.openentObserverAudioId,
            from: "privateagent",
          });
        } else if (this.state.openentObserveeAudioId !== "") {
          observeflag = true;
          socketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
            roomname: this.roomname,
            peerSessionId: this.state.openentObserveeAudioId,
            from: "privatesupervisor",
          });
        }

        this.setState({ imCaller: true });
        response = await socketInstance.sendMessage("MAKE_PRIVATE_CALL", {
          roomname: this.roomname,
          callAt: new Date(),
          peerSessionId,
          observeflag,
        });

        if (response?.type === "EMPTY_CALLBACK") {
          this.setState({
            callModalOption: {
              ...this.state.callModalOption,
              messageForCall: `${rp.peerName.split(" ")[0]}`,
              firstLetter: `${rp.peerName.split(" ")[0][0]}`,
              callAcceptedButton: false,
              isCallModal: true,
              peerSessionIdRemote: peerSessionId,
            },
          });
        } else if (response?.type === "FAILURE") {
          handleFailureResponse(response);
        }
      } else {
        response = await socketInstance.sendMessage("END_PRIVATE_CALL", {
          roomname: this.roomname,
          peerSessionId,
        });

        if (response) {
          if (response?.type === "FAILURE") {
            toast(`Something error please try again`);
          }
        }
      }
      //HANDLE FAILURE RESPONSE

      // this.setState({ remotePeers: this.remotePeers });
    }
  };

  callAccepted = async (peerSessionId: string) => {
    const rp = this.getPeerFromRemotePeersArray(peerSessionId);
    let response: any;
    if (rp) {
      if (!rp.isPrivateCallOn) {
        response = await socketInstance.sendMessage("START_PRIVATE_CALL", {
          roomname: this.roomname,
          peerSessionId,
          callAt: new Date(),
        });

        if (response) {
          if (response?.type === "FAILURE") {
            handleFailureResponse(response);
          }

          if (response?.type === "EMPTY_CALLBACK") {
            this.setState({
              callModalOption: {
                ...this.state.callModalOption,
                callAcceptedButton: false,
              },
            });
          }
        }
      }
    }
  };

  callRejected = async (peerSessionId: string, typeAction: string) => {
    // const rp = this.getPeerFromRemotePeersArray(peerSessionId);
    let response: any;
    response = await socketInstance.sendMessage("CALL_REJECTED", {
      roomname: this.roomname,
      peerSessionId,
      typeAction,
    });

    if (response) {
      if (response?.type === "EMPTY_CALLBACK") {
        this.setState({
          callModalOption: {
            ...this.state.callModalOption,
            isCallModal: false,
            messageForCall: "",
            callAcceptedButton: false,
            firstLetter: "",
            idForMakingCall: "",
            peerSessionIdRemote: "",
          },
        });
      }
    }
  };

  createSubscribingTransport = async () => {
    if (this.subscribeTransport) {
      this.subscribeTransport.close();
    }
    // this.setState({ presenterChanged: false })
    let subscribeTransportData: any;
    try {
      subscribeTransportData = await socketInstance.sendMessage(
        "CREATE_CONSUMER_TRANSPORT",
        {
          forceTcp: false,
          roomname: this.roomname,
        }
      );
      subscribeTransportData.iceServers = [];
      subscribeTransportData.iceServers.push({
        urls: "stun:stun.l.google.com:19302",
      });
      if (subscribeTransportData.turnIps) {
        subscribeTransportData.turnIps.forEach((turnIp: string) => {
          subscribeTransportData.iceServers.push({
            urls: `turn:${turnIp.trim()}:443?transport=tcp`,
            credential: subscribeTransportData.turnPassword,
            username: subscribeTransportData.turnUserId,
          });
          subscribeTransportData.iceServers.push({
            urls: `turn:${turnIp.trim()}:443?transport=udp`,
            credential: subscribeTransportData.turnPassword,
            username: subscribeTransportData.turnUserId,
          });
        });
      }
      //subscribeTransportData.iceTransportPolicy = "relay";
    } catch (error) {
      // TODO AMIT
      // Please make sure to handle this error
    }

    this.subscribeTransport = this.device.createRecvTransport(
      subscribeTransportData
    );

    this.subscribeTransport.on(
      "connect",
      async (
        { dtlsParameters }: mediasoupTypes.TransportOptions,
        callback: () => void,
        errback: () => void
      ) => {
        try {
          await socketInstance.sendMessage("CONNECT_CONSUMER_TRANSPORT", {
            dtlsParameters: dtlsParameters,
            roomname: this.roomname,
          });
          callback();
        } catch (error) {
          errback();
        }
      }
    );

    this.subscribeTransport.on(
      "connectionstatechange",
      async (state: string) => {
        switch (state) {
          case "connecting":
            console.log("connnecting ice consumer ");
            break;
          case "connected":
            console.log("connnect ice consumer ");

            break;
          case "disconnected":
            if (this.roomSocket.connected) {
              console.log("disconnected ice consumer ");
              const iceParameters = (await socketInstance.sendMessage(
                "RESTART_ICE_CONSUMER",
                {
                  roomname: this.roomname,
                }
              )) as mediasoupTypes.IceParameters;
              if (this.subscribeTransport) {
                this.subscribeTransport.restartIce({ iceParameters });
              }
            }
            break;
          case "failed":
            if (this.subscribeTransport) this.subscribeTransport.close();
            // TODO AMIT
            // Please show an error message. if case failed, streams are not coming from server.
            break;
          default:
            break;
        }
      }
    );
  };

  consumeStreamsInPausedMode = async (peer: RemotePeer) => {
    if (peer.peerAudioProducing) {
      await this.consumeAudioStream(peer);
    }
    if (peer.peerVideoProducing) {
      await this.consumeVideoStream(peer);
    }
    if (peer.peerScreenProducing) {
      await this.consumeScreenStream(peer);
    }
    if (peer.peerScreenTwoProducing) {
      await this.consumeScreenTwoStream(peer);
    }
    if (peer.peerAudioFileProducing) {
      await this.consumeAudioFileStream(peer);
    }

    if (peer.audioSystemProducing) {
      await this.consumeSystemAudioStream(peer);
    }
  };

  consumeAudioStream = async (peer: RemotePeer) => {
    try {
      if ((this.device && !this.device.loaded) || !this.device) {
        // await this.getRouterRtpCaps();
        console.log("-----------audio----------", "device not found");
        await sleep(5000);
      }
      const rtpCapabilities = this.device.rtpCapabilities;
      const remoteAudioStream = new MediaStream();
      const remoteAudioStreamData = (await socketInstance.sendMessage(
        "AUDIO_CONSUME",
        {
          roomname: this.roomname,
          peerToBeConsumedSessionId: peer.peerSessionId,
          rtpCapabilities,
        }
      )) as any;
      const { audioConsumerObj } = remoteAudioStreamData;
      if (audioConsumerObj) {
        if (
          audioConsumerObj.producerPaused ||
          audioConsumerObj.peerAudioPause
        ) {
          peer.peerAudioPaused = true;
        }
        const { id, producerId, kind, rtpParameters } = audioConsumerObj;
        const audioConsumer: any = await this.subscribeTransport.consume({
          id,
          producerId,
          kind,
          rtpParameters,
        });

        // const transportStats = await this.subscribeTransport.getStats();
        // const obj = Object.fromEntries(transportStats);
        // const objKeys = Object.keys(obj);
        // const filteredKey = objKeys.filter(key => key.includes('RTCMediaStream_'));
        // const RTCMediaStream = filteredKey[0];
        // const streamIdentifier = RTCMediaStream.split('_');
        //
        // console.log('audio consume stats', obj);
        // console.log('audio stream Identifier',obj,streamIdentifier[1]);

        if (this.props.troubleshoot && rtpParameters.rtcp.cname) {
          watchRTC.mapStream(rtpParameters.rtcp.cname, peer.peerUsername);
        }
        remoteAudioStream.addTrack(audioConsumer.track);
        peer.peerAudioStream = remoteAudioStream;
        peer.audioConsumer = audioConsumer;
        this.addPeerToArrayIfDoesNotExists(peer);

        audioConsumer.volume = 0;

        const stream = new MediaStream();

        stream.addTrack(audioConsumer.track);

        if (!stream.getAudioTracks()[0]) {
          throw new Error(
            "request.newConsumer | given stream has no audio track"
          );
        }

        audioConsumer.hark = hark(stream, { play: false });

        audioConsumer.hark.on("volume_change", (volume: number) => {
          volume = Math.round(volume);

          if (audioConsumer && volume !== audioConsumer.volume) {
            audioConsumer.volume = volume;

            this.props.setPeerVolume(peer.peerSessionId, volume);
          }
        });

        this.setState({ remotePeers: this.remotePeers });
      }
    } catch (e) {
      toast.dark(
        "Cannot Consume Audio Stream of " + peer.peerUsername + " Properly",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  consumeAudioFileStream = async (peer: RemotePeer) => {
    try {
      if ((this.device && !this.device.loaded) || !this.device) {
        // await this.getRouterRtpCaps();
        console.log("-----------audio----------", "device not found");
        await sleep(5000);
      }
      const rtpCapabilities = this.device.rtpCapabilities;
      const remoteAudioStream = new MediaStream();
      const remoteAudioStreamData = (await socketInstance.sendMessage(
        "AUDIO_CONSUME",
        {
          roomname: this.roomname,
          peerToBeConsumedSessionId: peer.peerSessionId,
          rtpCapabilities,
          audioNum: "File",
        }
      )) as any;
      const { audioConsumerObj } = remoteAudioStreamData;
      if (audioConsumerObj) {
        if (audioConsumerObj.producerPaused) {
          peer.peerAudioFilePaused = true;
        }
        const { id, producerId, kind, rtpParameters } = audioConsumerObj;
        const audioConsumer: any = await this.subscribeTransport.consume({
          id,
          producerId,
          kind,
          rtpParameters,
        });
        remoteAudioStream.addTrack(audioConsumer.track);
        peer.peerAudioFileStream = remoteAudioStream;
        peer.audioFileConsumer = audioConsumer;
        this.addPeerToArrayIfDoesNotExists(peer);

        audioConsumer.volume = 0;

        const stream = new MediaStream();

        stream.addTrack(audioConsumer.track);

        if (!stream.getAudioTracks()[0]) {
          throw new Error(
            "request.newConsumer | given stream has no audio track"
          );
        }

        audioConsumer.hark = hark(stream, { play: false });

        audioConsumer.hark.on("volume_change", (volume: number) => {
          volume = Math.round(volume);

          if (audioConsumer && volume !== audioConsumer.volume) {
            audioConsumer.volume = volume;

            this.props.setPeerVolume(peer.peerSessionId, volume);
          }
        });

        this.setState({ remotePeers: this.remotePeers });
      }
    } catch (e) {
      toast.dark(
        "Cannot Consume Audio File Stream of " +
        peer.peerUsername +
        " Properly",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  consumeSystemAudioStream = async (peer: RemotePeer) => {
    try {
      if ((this.device && !this.device.loaded) || !this.device) {
        // await this.getRouterRtpCaps();
        console.log("-----------screen audio----------", "device not found");
        await sleep(5000);
      }
      const rtpCapabilities = this.device.rtpCapabilities;
      const remoteAudioStream = new MediaStream();
      const remoteAudioStreamData = (await socketInstance.sendMessage(
        "SCREEN_AUDIO_CONSUME",
        {
          roomname: this.roomname,
          peerToBeConsumedSessionId: peer.peerSessionId,
          rtpCapabilities,
          audioNum: "System",
        }
      )) as any;
      const { audioConsumerObj } = remoteAudioStreamData;
      if (audioConsumerObj) {
        if (audioConsumerObj.producerPaused) {
          peer.screenAudioPaused = true;
        }
        const { id, producerId, kind, rtpParameters } = audioConsumerObj;
        const audioConsumer: any = await this.subscribeTransport.consume({
          id,
          producerId,
          kind,
          rtpParameters,
        });
        remoteAudioStream.addTrack(audioConsumer.track);
        peer.screenAudioStream = remoteAudioStream;
        peer.systemAudioConsumer = audioConsumer;
        this.addPeerToArrayIfDoesNotExists(peer);

        audioConsumer.volume = 0;

        const stream = new MediaStream();

        stream.addTrack(audioConsumer.track);

        if (!stream.getAudioTracks()[0]) {
          throw new Error(
            "request.newConsumer | given stream has no audio track"
          );
        }

        audioConsumer.hark = hark(stream, { play: false });

        audioConsumer.hark.on("volume_change", (volume: number) => {
          volume = Math.round(volume);

          if (audioConsumer && volume !== audioConsumer.volume) {
            audioConsumer.volume = volume;

            this.props.setPeerVolume(peer.peerSessionId, volume);
          }
        });

        this.setState({ remotePeers: this.remotePeers });
      } else {
        return;
      }
    } catch (e) {
      toast.dark(
        "Cannot Consume Screen Audio Stream of " +
        peer.peerUsername +
        " Properly",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  consumeVideoStream = async (peer: RemotePeer) => {
    try {
      if ((this.device && !this.device.loaded) || !this.device) {
        // await this.getRouterRtpCaps();
        console.log("-----------video----------", "device not found");
        await sleep(5000);
      }
      const rtpCapabilities = this.device.rtpCapabilities;
      const remoteVideoStream = new MediaStream();
      const remoteVideoStreamData = (await socketInstance.sendMessage(
        "VIDEO_CONSUME",
        {
          roomname: this.roomname,
          peerToBeConsumedSessionId: peer.peerSessionId,
          rtpCapabilities,
        }
      )) as any;
      const { videoConsumerObj } = remoteVideoStreamData;
      if (videoConsumerObj) {
        if (videoConsumerObj.producerPaused) {
          peer.peerVideoPaused = true;
        }
        const { id, producerId, kind, rtpParameters } = videoConsumerObj;
        const videoConsumer = await this.subscribeTransport.consume({
          id,
          producerId,
          kind,
          rtpParameters,
        });

        // const transportStats = await this.subscribeTransport.getStats();
        // const obj = Object.fromEntries(transportStats);
        // const objKeys = Object.keys(obj);
        // const filteredKey = objKeys.filter(key => key.includes('RTCMediaStream_'));
        // const RTCMediaStream = filteredKey[0];
        // const streamIdentifier = RTCMediaStream.split('_');
        //
        // console.log('video consume stats', obj);
        // console.log('video stream Identifier',obj,streamIdentifier[1]);
        // console.log('rtp parameters', rtpParameters);

        if (this.props.troubleshoot && rtpParameters.rtcp.cname) {
          watchRTC.mapStream(rtpParameters.rtcp.cname, peer.peerUsername);
        }

        remoteVideoStream.addTrack(videoConsumer.track);
        peer.peerVideoStream = remoteVideoStream;

        // if (this.props.screenCollabPeerArray.length > 0) {
        //   socketInstance.sendMessage("CHANGE_LAYER", {
        //     peerSessionIds: [peer.peerSessionId],
        //     mediaType: "VIDEO",
        //     spatialLayer: 0,
        //   });
        // }

        this.addPeerToArrayIfDoesNotExists(peer);
        this.setState({ remotePeers: this.remotePeers });
      }
    } catch (e) {
      toast.dark(
        "Cannot Consume Video Stream of " + peer.peerUsername + " Properly",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  consumeScreenStream = async (peer: RemotePeer) => {
    try {
      if ((this.device && !this.device.loaded) || !this.device) {
        // await this.getRouterRtpCaps();
        console.log("-----------screen----------", "device not found");
        await sleep(5000);
      }
      const rtpCapabilities = this.device.rtpCapabilities;
      const remoteScreenStream = new MediaStream();
      const remoteScreenStreamData = (await socketInstance.sendMessage(
        "SCREEN_CONSUME",
        {
          roomname: this.roomname,
          peerToBeConsumedSessionId: peer.peerSessionId,
          rtpCapabilities,
        }
      )) as any;
      const { screenConsumerObj } = remoteScreenStreamData;
      if (screenConsumerObj) {
        peer.peerScreenStopped = false;
        if (screenConsumerObj.producerPaused) {
          peer.peerScreenStopped = true;
        }
        const { id, producerId, kind, rtpParameters } = screenConsumerObj;
        const screenConsumer = await this.subscribeTransport.consume({
          id,
          producerId,
          kind,
          rtpParameters,
        });
        remoteScreenStream.addTrack(screenConsumer.track);
        peer.peerScreenStream = remoteScreenStream;
        // if (
        //   this.props.currentUserRole !== "Agent" &&
        //   this.props.viewMode !== roomViewModeConst.collabMode
        // ) {
        //   socketInstance.sendMessage("CHANGE_LAYER", {
        //     peerSessionIds: [peer.peerSessionId],
        //     mediaType: "SCREEN",
        //     spatialLayer: 0,
        //   });
        // }
        this.addPeerToArrayIfDoesNotExists(peer);
        this.setState({ remotePeers: this.remotePeers });
      }
    } catch (e) {
      toast.dark(
        "Cannot Consume Screen Stream of " + peer.peerUsername + " Properly",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  consumeScreenTwoStream = async (peer: RemotePeer) => {
    try {
      if ((this.device && !this.device.loaded) || !this.device) {
        // await this.getRouterRtpCaps();
        console.log("-----------screenTwo-----------", "device not found");
        await sleep(5000);
      }
      const rtpCapabilities = this.device.rtpCapabilities;
      const remoteScreenStream = new MediaStream();
      const remoteScreenStreamData = (await socketInstance.sendMessage(
        "SCREEN_CONSUME",
        {
          roomname: this.roomname,
          peerToBeConsumedSessionId: peer.peerSessionId,
          screenNum: "Two",
          rtpCapabilities,
        }
      )) as any;
      const { screenConsumerObj } = remoteScreenStreamData;
      if (screenConsumerObj) {
        peer.peerscreenTwoStopped = false;
        if (screenConsumerObj.producerPaused) {
          peer.peerscreenTwoStopped = true;
        }
        const { id, producerId, kind, rtpParameters } = screenConsumerObj;
        const screenConsumer = await this.subscribeTransport.consume({
          id,
          producerId,
          kind,
          rtpParameters,
        });
        remoteScreenStream.addTrack(screenConsumer.track);
        peer.peerScreenTwoStream = remoteScreenStream;
        // if (this.props.currentUserRole !== "Agent") {
        //   socketInstance.sendMessage("CHANGE_LAYER", {
        //     peerSessionIds: [peer.peerSessionId],
        //     mediaType: "SCREENTWO",
        //     spatialLayer: 0,
        //   });
        // }
        this.addPeerToArrayIfDoesNotExists(peer);
        this.setState({ remotePeers: this.remotePeers });
      }
    } catch (e) {
      toast.dark(
        "Cannot Consume Screen Stream of " + peer.peerUsername + " Properly",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  addPeerToArrayIfDoesNotExists = (peer: RemotePeer) => {
    if (socketInstance?.socket?.id !== peer.peerSessionId) {
      let peerExists = false,
        index;
      for (let i = 0; i < this.remotePeers.length; i++) {
        if (peer.peerSessionId === this.remotePeers[i].peerSessionId) {
          peerExists = true;
          index = i;
          break;
        }
      }
      if (!peerExists) {
        this.remotePeers.push(peer);
      } else {
        if (index) {
          this.remotePeers[index] = peer;
        }
      }
      this.setState({ remotePeers: this.remotePeers }); //, () => {
      //   // Make Resolution lower in case of max participants. Executes whole array
      //   if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
      //     if (
      //       this.state.remotePeers.length >= LOW_RESOLUTION_PARTICIPANT_COUNT
      //     ) {
      //       this.changeLayerForMaxParticipantsArray(this.state.remotePeers, 0);
      //     }
      //   }
      // });
    }
  };

  removePeerFromArrayIfExists = (peerSessionId: string) => {
    for (let i = 0; i < this.remotePeers.length; i += 1) {
      if (this.remotePeers[i].peerSessionId === peerSessionId) {
        if (
          this.remotePeers[i].audioConsumer &&
          this.remotePeers[i].audioConsumer.hark != null
        ) {
          this.remotePeers[i].audioConsumer.hark.stop();
        }
        this.remotePeers.splice(i, 1);
        this.setState({ remotePeers: this.remotePeers }, () => {
          // // Increase Resoltion in case of less Participants. Executes whole array
          // if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
          //   if (
          //     this.state.remotePeers.length < LOW_RESOLUTION_PARTICIPANT_COUNT
          //   ) {
          //     this.changeLayerForMaxParticipantsArray(
          //       this.state.remotePeers,
          //       1
          //     );
          //   }
          // }

          const currentPageSessionIds = this.getPagePeers().map(
            (peer: RemotePeer) => peer.peerSessionId
          );
          this.resumeConsumersForPeer(currentPageSessionIds);
        });
        break;
      }
    }
  };

  getPeerFromRemotePeersArray = (peerSessionId: string) => {
    let peer = undefined;
    for (let i = 0; i < this.remotePeers.length; i++) {
      if (peerSessionId === this.remotePeers[i].peerSessionId) {
        peer = this.remotePeers[i];
        break;
      }
    }
    return peer;
  };

  shareUnshareScreen = (peerSessionId: string) => {
    if (this.roomSocket.id === peerSessionId) {
      if (
        !this.state.localScreenStopped &&
        !this.reconnectionStatusInRoompage
      ) {
        if (this.screenProducer) {
          this.amIsharingScreen = false;
          this.screenProducer.close();
          const track = this.state.localScreenStream?.getVideoTracks()[0];
          track?.stop();

          if (convertIntoBoolean(this.screenControl)) {
            if (this.props.currentUserRole === "Agent") {
              socketInstance.sendMessage("CLOSE_SCREEN_SHARE_APP", {});
              if (!this.state.localScreenStoppedTwo) {
                this.shareUnshareScreenTwo(peerSessionId);
              }
            }
          }
          socketInstance.sendMessage("SCREEN_STOP", {
            roomname: this.roomname,
          });
          this.setState({
            localScreenStream: undefined,
            localScreenStopped: true,
          });

          if (this.screenAudioProducer) {
            this.screenAudioProducer.close();
            const trackScreenAudio =
              this.state.localScreenStream?.getAudioTracks()[0];
            trackScreenAudio?.stop();
            socketInstance.sendMessage("SYSTEM_AUDIO_STOP", {
              roomname: this.roomname,
            });
            this.setState({
              isScreenAudioShared: false,
            });
          }
          /**
           * Adjusts video resolution based on the current view mode and number of collaboration peers sharing screen.
           * In collaboration mode (collabMode), the resolution is scaled down depending on the
           * presence of collaboration peers sharing screens. If no peers are sharing screen, the resolution is set to high.
           * Otherwise, it is set to low. This is used to optimize video quality and performance
           * based on the current collaboration context.
           */
          if (this.props.viewMode === roomViewModeConst.collabMode) {
            if (!this.props.screenCollabPeerArray.length) {
              console.log("JD44 HIGHR");
              this.scaleDownResolutionMethod(
                mediaTypeConstant.video,
                false,
                resolutionConstant.high
              );
            } else {
              console.log("JD44 LOWR");
              this.scaleDownResolutionMethod(
                mediaTypeConstant.video,
                false,
                resolutionConstant.low
              );
            }
          }
        }
      } else {
        if (convertIntoBoolean(this.screenControl)) {
          // this.openElectronApp();
          if (this.props.currentUserRole === "Agent") {
            console.log(
              "in to shareunshare else block for electron",
              this.isreconnectionStatusScreenForElectron
            );
            socketInstance.sendMessage("IS_ELECTRON_CONNECTED", {
              roomname: this.roomname,
              reconnect: this.isreconnectionStatusScreenForElectron,
            });
            this.isreconnectionStatusScreenForElectron = false;
          } else {
            this.startScreen();
          }
        } else {
          this.startScreen();
        }
      }
    } else {
      const peer = this.getPeerFromRemotePeersArray(peerSessionId);
      if (peer) {
        if (peer.peerScreenStopped) {
          socketInstance.sendMessage("START_SCREEN_SHARE", {
            roomname: this.roomname,
            peerSessionId,
          });
        } else {
          socketInstance.sendMessage("STOP_SCREEN_SHARE", {
            roomname: this.roomname,
            peerSessionId,
          });
        }
        this.setState({ remotePeers: this.remotePeers });
      }
    }
  };
  shareUnshareScreenTwo = (peerSessionId: string) => {
    if (this.roomSocket.id === peerSessionId) {
      if (
        !this.state.localScreenStoppedTwo &&
        !this.reconnectionStatusInRoompage
      ) {
        if (this.screenTwoProducer) {
          this.screenTwoProducer.close();
          const track = this.state.localScreenTwoStream?.getVideoTracks()[0];
          track?.stop();
          console.log("TIPKA 888", this.props.screenCollabPeerArray.length);
          socketInstance.sendMessage("SCREEN_STOP", {
            roomname: this.roomname,
            screenNum: "Two",
          });
          this.setState({
            localScreenTwoStream: undefined,
            localScreenStoppedTwo: true,
          });
        }
      } else {
        this.startScreenTwo();
      }
    } else {
      const peer = this.getPeerFromRemotePeersArray(peerSessionId);
      if (peer) {
        if (peer.peerscreenTwoStopped) {
          socketInstance.sendMessage("START_SCREEN_SHARE", {
            roomname: this.roomname,
            peerSessionId,
            screenNum: "Two",
          });
        } else {
          socketInstance.sendMessage("STOP_SCREEN_SHARE", {
            roomname: this.roomname,
            peerSessionId,
            screenNum: "Two",
          });
        }
        this.setState({ remotePeers: this.remotePeers });
      }
    }
  };

  addStreamOfAudioFile = (peerSessionId: string, id: string) => {
    if (this.roomSocket.id === peerSessionId) {
      if (this.state.localAudioFileStream) {
        if (this.audioFileProducer) {
          this.audioFileProducer.close();
          const track = this.state.localAudioFileStream?.getAudioTracks()[0];
          track?.stop();
          socketInstance.sendMessage("AUDIO_STOP", {
            roomname: this.roomname,
          });
          this.setState({
            localAudioFileStream: undefined,
            localAudioFilePause: false,
          });

          this.props.setAudioFile(false);
        }
      } else {
        this.startAudioForFile(id, peerSessionId);
      }
    }
  };

  addStreamOfVideoFile = (peerSessionId: string) => {
    if (this.roomSocket.id === peerSessionId) {
      if (!this.state.localScreenStopped) {
        if (this.screenProducer) {
          this.screenProducer.close();
          const track = this.state.localScreenStream?.getVideoTracks()[0];
          track?.stop();

          if (this.screenAudioProducer) {
            this.screenAudioProducer.close();
            const screenAudioTrack =
              this.state.localScreenStream?.getAudioTracks()[0];
            screenAudioTrack?.stop();
            this.setState({
              isScreenAudioShared: false,
            });
          }

          if (convertIntoBoolean(this.screenControl)) {
            socketInstance.sendMessage("CLOSE_SCREEN_SHARE_APP", {});
            if (!this.state.localScreenStoppedTwo) {
              this.shareUnshareScreenTwo(peerSessionId);
            }
          }

          socketInstance.sendMessage("SCREEN_STOP", {
            roomname: this.roomname,
          });
          this.setState({
            localScreenStream: undefined,
            localScreenStopped: true,
          });
          if (!this.props.isVideoFile) {
            this.startVideoForFile();
            this.addStreamOfAudioFile(peerSessionId, "videosss");
          } else {
            this.props.setVideoFile(false);
          }
        }
      } else {
        this.startVideoForFile();
        this.addStreamOfAudioFile(peerSessionId, "videosss");
      }
    }
  };

  pauseLocalMic = () => {
    try {
      if (this.audioProducer && this.state.localAudioPaused === false) {
        this.audioProducer.pause();
        this.setState({ localAudioPaused: true });
        socketInstance.sendMessage("AUDIO_PAUSE", {
          roomname: this.roomname,
          peerSessionId: this.roomSocket.id,
        });
        this.disconnectLocalHark();
      }
    } catch (e) {
      console.error("cannot pause local mic :" + e);
    }
  };

  unpauseLocalMic = () => {
    try {
      if (this.audioProducer && this.state.localAudioPaused === true) {
        if (this.state.openentObserverAudioId !== "") {
          socketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
            roomname: this.roomname,
            peerSessionId: this.state.openentObserverAudioId,
            from: "unmuteagent",
          });
        }

        this.audioProducer.resume();
        this.setState({ localAudioPaused: false });
        socketInstance.sendMessage("AUDIO_RESUME", {
          roomname: this.roomname,
          peerSessionId: this.roomSocket.id,
        });
        const track = this.audioProducer.track;
        this.connectLocalHark(track);
      }
    } catch (error) {
      console.error("cannot UnPause local mic :" + error);
    }
  };

  pauseUnPauseAudio = (peerSessionId: string) => {
    if (this.roomSocket.id === peerSessionId) {
      if (!this.state.localAudioPaused) {
        this.pauseLocalMic();
      } else {
        this.unpauseLocalMic();
      }
    } else {
      const peer = this.getPeerFromRemotePeersArray(peerSessionId);

      if (peer) {
        if (peer.peerAudioPaused) {
          socketInstance.sendMessage("AUDIO_RESUME", {
            roomname: this.roomname,
            peerSessionId,
          });
        } else {
          socketInstance.sendMessage("AUDIO_PAUSE", {
            roomname: this.roomname,
            peerSessionId,
          });
        }
        peer.peerAudioPaused = !peer.peerAudioPaused;
        this.setState({ remotePeers: this.remotePeers });
      }
    }
  };

  addErrorMessage = (message: string) => {
    this.setState({ errorMessages: this.state.errorMessages.concat(message) });
  };

  pauseUnPauseVideo = async (peerSessionId: string) => {
    if (this.roomSocket.id === peerSessionId) {
      if (!this.state.localVideoPaused) {
        if (this.videoProducer) {
          // clearInterval(this.frstart?._id);
          clearInterval(this.frstart);
          if (this.props.currentUserRole === "Agent") {
            socketInstance.sendMessage("FACE_DETECTED", {
              detections: [],
              time: Date.now(),
              videoPause: true,
            });
            this.videoPauseAlertInterval = setTimeout(() => {
              socketInstance.sendMessage("GET_ALERT_NOTIFICATION", {
                msg:"OFF",
                time: Date.now()
              })
              toast.info("Your Camera is Off");
            }, 60000)

            // socketInstance.sendMessage("CELLPHONE_DETECTED", {
            //   class: "",
            //   score: "",
            // });
          }
          this.videoProducer.pause();
          this.setState({ localVideoPaused: true });
          socketInstance.sendMessage("VIDEO_PAUSE", {
            roomname: this.roomname,
            peerSessionId,
          });

        }
      } else {
        this.videoProducer.resume();
        this.setState(
          () => ({
            localVideoPaused: false,
          }),
          () => {
            if (this.props.currentUserRole === "Agent") {
              if (this.faceDetectionFlag) this.faceDetection();
            }
          }
        );
        socketInstance.sendMessage("GET_ALERT_NOTIFICATION", {
          msg:"ON",
          time: Date.now()
        })
        replaceVideoTimeout = setTimeout(() => {
          this.replaceVideoStream();
        }, 200);
        clearInterval(this.videoPauseAlertInterval)
        socketInstance.sendMessage("VIDEO_RESUME", {
          roomname: this.roomname,
          peerSessionId,
        });

      }
    } else {
      const peer = this.getPeerFromRemotePeersArray(peerSessionId);
      if (peer) {
        if (peer.peerVideoPaused) {
          socketInstance.sendMessage("VIDEO_RESUME", {
            roomname: this.roomname,
            peerSessionId,
          });
        } else {
          socketInstance.sendMessage("VIDEO_PAUSE", {
            roomname: this.roomname,
            peerSessionId,
          });
        }
        peer.peerVideoPaused = !peer.peerVideoPaused;
        peer.isVideoSwapped = "";
        this.setState({ remotePeers: this.remotePeers });
      }
    }
  };

  async openElectronApp() {
    let hostname = window.location.origin;

    if (hostname.includes("http://localhost")) {
      hostname = "http://localhost:8080";
    }

    if (hostname.includes("https://localhost")) {
      hostname = "https://localhost:8080";
    }
    // window
    //   .open(`${window.location.origin}/launchApp/${this.roomname}`, "_blank")
    //   ?.focus();

    window.open(
      `${window.location.origin}/launchApp/${this.roomname}`,
      "pagename",
      `resizable,height=300,width=350`
    );

    setTimeout(() => {
      let value = localStorage.getItem(this.props.userName + "screencontrol");
      if (value === this.props.userName) {
        this.startScreen();
        localStorage.removeItem(this.props.userName + "screencontrol");
      }
    }, 3000);
  }

  connectLocalHark(track: MediaStreamTrack | null) {
    console.log('connectLocalHark() [track:"%o"]', track);

    this._harkStream = new MediaStream();

    const newTrack = track?.clone();

    this._harkStream.addTrack(newTrack);

    if (newTrack) newTrack.enabled = true;

    this._hark = hark(this._harkStream, {
      play: false,
      interval: 100,
      threshold: -50,
      history: 100,
    });

    this._hark.lastVolume = -100;

    this._hark.on("volume_change", (volume: number) => {
      // Update only if there is a bigger diff
      if (
        this.audioProducer &&
        Math.abs(volume - this._hark.lastVolume) > 0.5
      ) {
        // Decay calculation: keep in mind that volume range is -100 ... 0 (dB)
        // This makes decay volume fast if difference to last saved value is big
        // and slow for small changes. This prevents flickering volume indicator
        // at low levels
        if (volume < this._hark.lastVolume) {
          volume =
            this._hark.lastVolume -
            Math.pow(
              (volume - this._hark.lastVolume) / (100 + this._hark.lastVolume),
              2
            ) *
            10;
        }

        this._hark.lastVolume = volume;

        this.props.setPeerVolume(this.roomSocket.id, volume);
      }
    });

    this._hark.on("speaking", () => { });

    this._hark.on("stopped_speaking", () => {
      this.props.setPeerVolume(this.roomSocket.id, -100);
    });
  }

  disconnectLocalHark() {
    if (this.state.imCaller) {
      socketInstance.sendMessage("PRIVATE_CALL_ENDED_ACKNOWLEDGE", {
        callEndAt: new Date(),
        callAction: "USER_DISCONNECTED",
      });
      this.setState({ imCaller: false });
    }
    if (this._harkStream != null) {
      let [track] = this._harkStream.getAudioTracks();

      track.stop();
      track = null;

      this._harkStream = null;
    }

    if (this._hark != null) this._hark.stop();
  }
  faceException = (detections: any) => {
    if (this.enableScreenshot === "true") {
      if (detections.length === 0) {
        this.faceCountZero = this.faceCountZero + 1;
      } else this.faceCountZero = 0;
      if (detections.length === 2) {
        this.faceCountTwo = this.faceCountTwo + 1;
      } else this.faceCountTwo = 0;
      if (this.faceCountZero === this.faceSeconds) {
        this.capture(0);
        this.faceCountZero = 0;
      }
      if (this.faceCountTwo === this.faceSeconds) {
        this.capture(2);
        this.faceCountTwo = 0;
      }
    }
  };

  capture = (numfaces: number) => {
    let canvas: any = document.getElementById("canvas");
    const video: any = document.getElementsByClassName(
      "face-detection-node"
    )[0];
    if (canvas && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      canvas.toBlob((blob: any) => {
        // const img = new Image();
        // img.src = window.URL.createObjectURL(blob);
        // document.body.appendChild(img);
        upload(blob, this.props.userName, numfaces.toString());
      });
    }
  };

  webWorker = async (input: any, image: any, canvas: any) => {
    let human = { ...config.humanApi };
    human.face.emotion.enabled = this.emotionDetection;
    console.log(human);
    this.worker.postMessage(
      {
        image: image.data.buffer,
        width: canvas.width,
        height: canvas.height,
        userConfig: human,
      },
      [image.data.buffer]
    );
  };

  objectDetectionWebWorker = async (input: any, image: any, canvas: any) => {
    this.objectDetectionWorker.postMessage(
      {
        image: image.data.buffer,
        width: canvas.width,
        height: canvas.height,
      },
      [image.data.buffer]
    );
  };
  // this.storeDetectionImages.status.toString(),
  detectApi = async (image: any) => {
    const features = [];
    if (this.headPoseDetect) {
      features.push("head_pose");
    }

    if (this.faceDetectionFlag) {
      features.push("face_detection");
    }

    if (
      this.faceVerification &&
      this.props.faceVerificationEnabled &&
      this.props.isUserVerified
    ) {
      features.push("face_verification");
    }

    if (this.emotionDetectionAdminControl) {
      features.push("emotion_recognition");
    }

    if (this.cellphoneDetection) {
      features.push("cellphone_detection");
    }

    if (
      !this.faceDetectionFlag &&
      !this.faceVerification &&
      !this.emotionDetectionAdminControl &&
      !this.cellphoneDetection
    ) {
      features.length = 0;
    }
    const featuresString = JSON.stringify(features);

    try {
      // const d = new Date();
      // let time = d.getTime();
      // const id = `${this.props.userName}_${time}`;

      const detectResponse = await detect(
        this.faceDetectionUrl,
        this.props.userName,
        image,
        this.storeDetectionImages.value,
        featuresString,
        this.detectionToken
      );
      if (detectResponse.status === "success") {
        // console.log(
        //   "detection",
        //   detectResponse.n_person,
        //   detectResponse.n_phone
        // );
        // if (detectResponse?.errors) {

        //   if (detectResponse?.errors.face_verification) {
        //     toast.error(detectResponse.errors.face_verification);
        //   }
        //   if (detectResponse?.errors.face_detection) {
        //     toast.error(detectResponse.errors.face_detection);
        //   }
        //   if (detectResponse.errors.emotion_recognition) {
        //     toast.error(detectResponse.errors.emotion_recognition);
        //   }
        //   if (detectResponse.errors.cellphone_detection) {
        //     toast.error(detectResponse.errors.cellphone_detection);
        //   }
        // }
        let emotion;
        if (this.emotionDetectionAdminControl) {
          emotion = detectResponse.results.emotion_recognition;
        } else {
          emotion = "turned_off";
        }

        let headpose;
        if (this.headPoseDetect) {
          headpose = detectResponse.results.head_pose;
        } else {
          headpose = "turned_off";
        }

        socketInstance.sendMessage("FACE_DETECTED", {
          detections: {
            face: detectResponse.results.face_detection,
            phone: detectResponse.results.cellphone_count,
            emotion,
            headpose,
          },
          emotionDetection: this.emotionDetectionAdminControl,
          time: Date.now(),
        });

        if (
          detectResponse.results.face_detection >= 1 &&
          detectResponse.results.face_verification !== undefined &&
          !detectResponse.results.face_verification
        ) {
          console.log(
            "Coming Brooooooo",
            detectResponse.results.face_verification
          );
          this.props.wrongPersonCheck(true);
          this.setState((prevState) => ({
            participantAlertCounter: prevState.participantAlertCounter + 1,
          }));
        } else {
          this.props.wrongPersonCheck(false);
          this.setState({ participantAlertCounter: 0 });
        }

        if (this.faceVerification) {
          socketInstance.sendMessage("VERIFY_FACE", {
            detections: {
              face: detectResponse.results.face_detection,
              faceVerification: detectResponse.results.face_verification,
              participantAlertCounter: this.state.participantAlertCounter,
            },
            time: Date.now(),
          });
        }

        // if (detectResponse.results.cellphone_detection === 0) {
        //   this.setState({
        //     checkCellphoneDetected: false
        //   })
        // }

        if (
          detectResponse.results.cellphone_count >= 1 &&
          detectResponse.results.cellphone_detection === true
        ) {
          let cellPhoneConfidence;
          if (detectResponse.results.max_cellphone_confidence) {
            cellPhoneConfidence =
              detectResponse.results.max_cellphone_confidence;
          }

          //&& !this.state.checkCellphoneDetected
          console.log("cell-phone is called");
          socketInstance.sendMessage("CELLPHONE_DETECTED", {
            class: "cell-phone",
            score: detectResponse.results.cellphone_detection,
            cellPhoneConfidence: cellPhoneConfidence,
          });
          // this.setState({
          //   checkCellphoneDetected: true
          // })
        }

        if (detectResponse.results.face_verification.toString().length > 0) {
          this.props.setRecurrentCheck(
            detectResponse.results.face_verification
          );
        }

        if (
          detectResponse.results.face_verification.toString().length > 0 &&
          !detectResponse.results.face_verification
        ) {
          console.log({
            "COming Here": detectResponse.results.face_verification,
          });
          this.setState((prevState) => ({
            consecutiveFalseCounters: prevState.consecutiveFalseCounters + 1,
          }));
          if (this.state.consecutiveFalseCounters + 1 === 6) {
            this.props.verifyFace(detectResponse.results.face_verification);
            toast.error(
              "Please make sure your face is clearly detected and you are in a well light room"
            );
            this.setState({ consecutiveFalseCounters: 0 });
          }
        } else {
          this.setState({ consecutiveFalseCounters: 0 });
          this.props.verifyFace(true);
        }

        // if (apiResponse === false) {
        //   setConsecutiveFalseCount((prevCount) => prevCount + 1);
        //   if (consecutiveFalseCount + 1 === 5) {
        //     setIsStateTrue(true);
        //   }
        // } else {
        //   setConsecutiveFalseCount(0); // Reset the count on a true response
        // }
      }
    } catch (error: any) {
      console.log("error while detection", error.message);
    }
  };
  faceDetection = async () => {
    if (checkForSupManAdmin(this.props.currentUserRole, "notEqualTo")) {
      this.frstart = setInterval(() => {
        let canvas: any = document.getElementById("canvas");
        const input: any = document.getElementsByClassName(
          "face-detection-node"
        )[0];

        if (input) {
          canvas.width = input.videoWidth;
          canvas.height = input.videoHeight;
          const offscreen = document.createElement("canvas");
          offscreen.width = canvas.width;
          offscreen.height = canvas.height;
          const ctx = offscreen.getContext("2d");
          ctx?.drawImage(input, 0, 0, canvas.width, canvas.height);
          const imageData = offscreen.toDataURL("image/jpeg");
          // const data = ctx?.getImageData(0, 0, canvas.width, canvas.height);
          // const objectDetectionData = ctx?.getImageData(
          //   0,
          //   0,
          //   canvas.width,
          //   canvas.height
          // );

          // console.log("canvas image", imageData);
          this.detectApi(imageData);
        }
        // perform detection in worker
        // this.webWorker(input, data, canvas);
        // if (process.env.REACT_APP_MOBILE_DETECTION === "true") {
        //   this.objectDetectionWebWorker(input, objectDetectionData, canvas);
        // }
      }, this.cellphoneFaceDetectSeconds * 1000);
    }
  };

  pauseConsumersForPeer = async (peerSessionIdsArray: string[]) => {
    await socketInstance.sendMessage("PAUSE_CONSUMERS_FOR_PEER", {
      roomname: this.roomname,
      consumersToBePaused: peerSessionIdsArray,
    });
  };

  resumeConsumersForPeer = async (
    peerSessionIdsArray: string[],
    collabPeerId = ""
  ) => {
    // getting scrreen peer id from redux for collab mode
    let collabScreenPeerId =
      collabPeerId !== "" ? collabPeerId : this.props.screenCollabPeer.peerId;
    let peerOnAnotherPage = false;

    // checking if peer on second page who sharing screen so than consume particular screen peer by everyone
    if (
      this.props.viewMode === roomViewModeConst.collabMode &&
      collabScreenPeerId !== ""
    ) {
      peerOnAnotherPage = !peerSessionIdsArray.includes(collabScreenPeerId);
    }

    await socketInstance.sendMessage("RESUME_CONSUMERS_FOR_PEER", {
      roomname: this.roomname,
      consumersToBeResumed: peerSessionIdsArray,
      collabScreenPeerId,
      peerOnAnotherPage,
    });
    this.setState({ remotePeers: this.remotePeers });
  };

  moveElementToStart(array:any, fromIndex:any) {
    if (fromIndex < 0 || fromIndex >= array.length) {
      throw new Error("Index out of bounds");
    }

    // Make a copy of the array to avoid mutating the original array
    const newArray = [...array];

    // Remove the element from the original position
    const [movedElement] = newArray.splice(fromIndex, 1);

    // Insert the element at the start of the array
    newArray.unshift(movedElement);

    return newArray;
  }


  handleHandRaise = (peerSessionId: string) => {
    // let mediaReducer = getMediaReducer();
    // let meetingReducer = getMeetingRoomReducer();

    // if (!constantMethod.checkHigherRole(currentUserRole)) {
    //   return;
    // }

    let pageSize = 0;
    if (
      this.props.viewMode === roomViewModeConst.collabMode &&
      this.props.screenCollabPeer.peerId !== ""
    ) {
      pageSize = this.pageSizeC;
    } else {
      pageSize = this.pageSizeL;
    }
    // Determine page size based on room mode
    // if (
    //   roomReducer.viewMode === roomViewModeConst.collabMode &&
    //   meetingReducer.screenCollabPeer.peerId !== ""
    // ) {
    //   pageSize =
    //     +environmentReducer.environments.REACT_APP_PAGE_SIZE_COLLAB.value;
    // } else {
    //   pageSize = +environmentReducer.environments.REACT_APP_PAGE_SIZE.value;
    // }

    if (peerSessionId === socketInstance?.socket?.id) {
      return;
    }

    const peerIndex = this.remotePeers.findIndex(
      (remotePeer: RemotePeer) => remotePeer.peerSessionId === peerSessionId
    );

    if (peerIndex === -1) {
      console.log("Peer not found in remotePeersId list");
      return;
    }

    const updatedPeers = this.moveElementToStart(this.remotePeers, peerIndex);

    this.setState({remotePeers:updatedPeers})

    if (peerIndex < pageSize) {
      return;
    }
    const currentPagePeers = this.getPagePeers();
    // const updatedPeers = arrayMoveImmutable(
    //   this.remotePeers,
    //   peerIndex,
    //   0
    // );
    const peersessionIds = currentPagePeers.map(peer => peer.peerSessionId);
    this.resumeConsumersForPeer(peersessionIds);
    const filteredPeerIdsForPaused = updatedPeers.filter(peer => !currentPagePeers.includes(peer))
    this.pauseConsumersForPeer(filteredPeerIdsForPaused);
    // if (this.remotePeers && this.remotePeers.length >= pageSize) {
    //   // create a sub array
    //   let peerFoundInCurrentPage = false;
    //   for (let i = 0; i < currentPagePeers.length; i++) {
    //     if (currentPagePeers[i].peerSessionId === peerSessionId) {
    //       peerFoundInCurrentPage = true;
    //       break;
    //     }
    //   }

    //   if (!peerFoundInCurrentPage) {
    //     // find peers index first

    //     arraymove.mutate(this.remotePeers, peerIndex, 0);
    //     this.setState({ remotePeers: this.remotePeers }, () => {
    //       // const updatedPeersonPage = this.getPagePeers();
    //       // const updatedPagePeerSessionIds = updatedPeersonPage.map(
    //       //   (currentPagePeer: RemotePeer) => currentPagePeer.peerSessionId
    //       // );
    //       this.resumeConsumersForPeer([peerSessionId]);
    //       const peerToBePaused =
    //         this.state.remotePeers[pageSize - 1].peerSessionId;
    //       this.pauseConsumersForPeer([peerToBePaused]);
    //     });
    //   }
    // }

    // // Find the index of peerUserId in the remotePeersId list
    // const peerIndex = mediaReducer.remotePeersId.findIndex(
    //   (remotePeerUserId: string) => remotePeerUserId === peerUserId
    // );

    // // If peerUserId is not found, return early
    // if (peerIndex === -1) {
    //   console.log("Peer not found in remotePeersId list");
    //   return;
    // }

    // // Move the peer to the first position in the remotePeersId list
    // const updatedPeers = arrayMoveImmutable(
    //   mediaReducer.remotePeersId,
    //   peerIndex,
    //   0
    // );

    // console.log({ updatedPeers });
    // dispatch(setRemotePeersAllId(updatedPeers));

    // if (peerIndex < pageSize) {
    //   return;
    // }

    // // Refresh mediaReducer after dispatch
    // mediaReducer = store.getState().media;
    // const currentPagePeers = getPagePeers();

    // // Resume the stream for the first peer (moved peer)
    // streamInstance.resumeConsumersForPeer(currentPagePeers);
    // const filteredPeerIdsForPaused = updatedPeers.filter(peer => !currentPagePeers.includes(peer))
    // // Pause streams for peers that were moved outside the first page
    // // for (let i = pageSize; i < updatedPeers.length; i++) {
    // // const peerToBePaused = updatedPeers[i];
    // streamInstance.pauseConsumersForPeer(filteredPeerIdsForPaused);
    // }

  };

  performRightShiftByOne = (peerSessionId: string) => {
    if (checkForSupManAdmin(this.props.currentUserRole, "notEqualTo")) {
      return;
    }
    let pageSize = 0;
    if (
      this.props.viewMode === roomViewModeConst.collabMode &&
      this.props.screenCollabPeer.peerId !== ""
    ) {
      pageSize = this.pageSizeC;
    } else {
      pageSize = this.pageSizeL;
    }
    if (peerSessionId === socketInstance?.socket?.id) {
      return;
    }
    if (this.remotePeers && this.remotePeers.length >= pageSize) {
      // create a sub array
      const currentPagePeers = this.getPagePeers();
      let peerFoundInCurrentPage = false;
      for (let i = 0; i < currentPagePeers.length; i++) {
        if (currentPagePeers[i].peerSessionId === peerSessionId) {
          peerFoundInCurrentPage = true;
          break;
        }
      }

      if (!peerFoundInCurrentPage) {
        // find peers index first
        const peerIndex = this.remotePeers.findIndex(
          (remotePeer: RemotePeer) => remotePeer.peerSessionId === peerSessionId
        );
        arraymove.mutate(this.remotePeers, peerIndex, 0);
        this.setState({ remotePeers: this.remotePeers }, () => {
          // const updatedPeersonPage = this.getPagePeers();
          // const updatedPagePeerSessionIds = updatedPeersonPage.map(
          //   (currentPagePeer: RemotePeer) => currentPagePeer.peerSessionId
          // );
          this.resumeConsumersForPeer([peerSessionId]);
          const peerToBePaused =
            this.state.remotePeers[pageSize - 1].peerSessionId;
          this.pauseConsumersForPeer([peerToBePaused]);
        });
      }
    }
  };

  notificationForObservation = (
    notificationType: string,
    observerUsername: any
  ) => {
    if (notificationType === "unmuteagent") {
      toast(
        `${observerUsername} has been unmuted Your observation has been end with ${observerUsername}`
      );
    } else if (notificationType === "disconnect") {
      toast(
        `${observerUsername} has been disconnected Your observation has been end with ${observerUsername}`
      );
    } else if (notificationType === "privatesupervisor") {
      toast(
        `You are trying or receiving private call, Your observation has been end with ${observerUsername}`
      );
    } else if (notificationType === "privateagent") {
      toast(
        `${observerUsername} is trying or receiving private call, Your observation has been end with ${observerUsername}`
      );
    }
  };

  replaceAudioStream = async (deviceId: string) => {
    const audioStream = await getAudioStream(deviceId);
    this.setState({ localAudioStream: audioStream });

    if (this.audioProducer) {
      this.audioProducer.replaceTrack({
        track: audioStream.getAudioTracks()[0],
      });
    }
  };

  scaleDownResolutionMethod = async (
    mediaType: string,
    allStream: boolean,
    resolution: string
  ) => {
    if (
      this.videoProducer &&
      this.videoProducer.rtpSender &&
      (mediaType === mediaTypeConstant.video || allStream)
    ) {
      let videoParams = this.videoProducer.rtpSender.getParameters();

      //@ts-ignore
      videoParams.encodings[0].scaleResolutionDownBy = this.props.videoScale[resolution];
      // videoParams.encodings[0].priority = "high";
      // videoParams.degradationPreference = "maintain-resolution";

      await this.videoProducer.rtpSender.setParameters(videoParams);
    }

    if (
      this.screenProducer &&
      this.screenProducer.rtpSender &&
      (mediaType === mediaTypeConstant.screen || allStream)
    ) {
      let screenParams = this.screenProducer.rtpSender.getParameters();

      //@ts-ignore
      screenParams.encodings[0].scaleResolutionDownBy = this.props.videoScale[resolution];
      // screenParams.encodings[0].priority = "high";
      // screenParams.degradationPreference = "maintain-resolution";
      // // params.encodings[1].maxBitrate = 20000;

      await this.screenProducer.rtpSender.setParameters(screenParams);
    }

    if (
      this.screenTwoProducer &&
      this.screenTwoProducer.rtpSender &&
      (mediaType === mediaTypeConstant.screentwo || allStream)
    ) {
      let screenTwoparams = this.screenTwoProducer.rtpSender.getParameters();

      //@ts-ignore
      screenTwoparams.encodings[0].scaleResolutionDownBy = this.props.videoScale[resolution];
      // screenTwoparams.encodings[0].priority = "high";
      // screenTwoparams.degradationPreference = "maintain-resolution";

      await this.screenTwoProducer.rtpSender.setParameters(screenTwoparams);
    }
  };

  replaceVideoStream = async (deviceId?: string) => {
    if (
      (this.props.videoOption === "blur" ||
        this.props.videoOption === "background") &&
      this.state.selectedTFLite
    ) {
      const node = document.getElementById("blur");
      // @ts-ignore
      const track = node?.captureStream()?.getVideoTracks()[0];
      if (track) this.replaceVideoTrack(track);
    } else {
      const videoStream = await getVideoStream(this.props.activeVideoDevice);
      this.setState({ localVideoStream: videoStream }, () => {
        if (
          // custom image
          this.props.backgroundActivate.backgroundType === "background" &&
          this.state.selectedTFLite &&
          this.customBgImg
        ) {
          this.props.setBackgroundImage(this.props.backgroundActivate.url);
          this.props.setVideoOption(
            this.props.backgroundActivate.backgroundType
          );
        } else if (
          this.state.selectedTFLite &&
          this.props.backgroundActivate.backgroundType === "blur" &&
          this.blurOption
        ) {
          // blur
          this.props.setVideoOption(
            this.props.backgroundActivate.backgroundType
          );
        } else {
          // normal
          this.props.setVideoOption("normal");
        }
      });

      if (this.videoProducer) {
        this.videoProducer.replaceTrack({
          track: videoStream.getVideoTracks()[0].clone(),
        });
      }
    }
  };

  getChatHistory = async () => {
    return await socketInstance.sendMessage("GET_CHAT_HISTORY", {
      roomname: this.roomname,
      chatType: "EVERYONE",
    });
  };

  getLimitedChatHistory = async () => {
    return await socketInstance.sendMessage("GET_LIMITED_CHAT_HISTORY", {
      // roomname: this.roomname,
      // chatType: "EVERYONE",
    });
  };

  getJoinedPeersData = async () => {
    const joinedPeers = (await socketInstance.sendMessage("GET_JOINED_PEERS", {
      roomname: this.roomname,
    })) as Record<string, Record<string, string | boolean>[]>;
    if (
      joinedPeers &&
      joinedPeers.peersArray &&
      joinedPeers.peersArray.length > 0
    ) {
      await Promise.all(
        joinedPeers.peersArray.map(async (peer) => {
          const remotePeer = new RemotePeer(
            peer.peerSessionId as string,
            peer.peerUsername as string,
            peer.peerName as string,
            peer.peerType as string,
            peer.peerAudioProducing as boolean,
            peer.peerVideoProducing as boolean,
            peer.peerScreenProducing as boolean,
            peer.peerScreenTwoProducing as boolean,
            peer.peerAudioFileProducing as boolean,
            peer.expression as string,
            peer.objectDetect as string,
            peer.numfaces as string,
            peer.isPrivateCallOn as boolean,
            peer.emojiForIconPeer as string,
            peer.emojiForHandAndLunchPeer as string,
            peer.audioSystemProducing as boolean,
            peer.privateCallPeer as any,
            peer.isCallModalOn as boolean,
            peer.networkStrength as string,
            peer.openentObserverAudioId as string,
            peer.openentObserveeAudioId as string
          );
          await this.consumeStreamsInPausedMode(remotePeer);
        })
      );
    }
  };

  handRaisedToTop = (handRaisePeer: any) => {
    const opponentPeer = this.remotePeers.filter(
      (peer: any) => peer.peerSessionId !== handRaisePeer.peerSessionId
    );
    this.remotePeers = [handRaisePeer, ...opponentPeer];
    this.setState({ remotePeers: this.remotePeers }, () =>
      console.log(this.state.remotePeers)
    );
  };

  processMessage = async (message: any) => {
    switch (message.type) {
      // Not necessary now since we are keeping the express at peer level

      case "CHAT_MESSAGE":
        if (message.message.senderSessionId !== socketInstance.socket?.id) {
          if (!this.state.sideBarOpen) {
            // sound.play();
            // Howler.volume(0.2);
            this.setState({ sideBarCount: this.state.sideBarCount + 1 });
          }

          // if (message.recieverId) {
          //   this.notifyMe(
          //     `Received Message from ${message.message.senderUsername}`
          //   );
          // }
        }
        this.props._setMessageList(message.message);
        break;

      case "NOTIFICATIONS_LEAVE_AND_ENTER":
        if (message.username !== this.props.userName) {
          this.props.addNotificationsUsingSingle(
            message.startDate,
            message.msg,
            message.username,
            notificationByType.leaveEnter,
            message?.peerName
          );

          if (message.isLunchBrbBreakOn && message.isLunchBrbBreakOn !== "") {
            this.props.addNotifyEndTime(
              Date.now(),
              message.username,
              message.isLunchBrbBreakOn
            );
          }
        }
        break;
      case "SETTING_BITRATE_FOR_ALL_STREAM_EXCEPT_AUDIO":
        if (this.videoProducer && this.videoProducer.rtpSender) {
          let videoParams = this.videoProducer.rtpSender.getParameters();
          if (message.streamType === "HIGH") {
            videoParams.encodings[0].maxBitrate = 10000000;
          } else if (message.streamType === "MID") {
            videoParams.encodings[0].maxBitrate = 600000;
          } else {
            videoParams.encodings[0].maxBitrate = 40000;
          }

          await this.videoProducer.rtpSender.setParameters(videoParams);
        }

        if (this.screenProducer && this.screenProducer.rtpSender) {
          let screenParams = this.screenProducer.rtpSender.getParameters();
          if (message.streamType === "HIGH") {
            screenParams.encodings[0].maxBitrate = 10000000;
          } else if (message.streamType === "MID") {
            screenParams.encodings[0].maxBitrate = 600000;
          } else {
            screenParams.encodings[0].maxBitrate = 40000;
          }
          // // params.encodings[1].maxBitrate = 20000;

          await this.screenProducer.rtpSender.setParameters(screenParams);
        }

        if (this.screenTwoProducer && this.screenTwoProducer.rtpSender) {
          let screenTwoparams =
            this.screenTwoProducer.rtpSender.getParameters();
          if (message.streamType === "HIGH") {
            screenTwoparams.encodings[0].maxBitrate = 10000000;
          } else if (message.streamType === "MID") {
            screenTwoparams.encodings[0].maxBitrate = 600000;
          } else {
            screenTwoparams.encodings[0].maxBitrate = 40000;
          }

          await this.screenTwoProducer.rtpSender.setParameters(screenTwoparams);
        }
        break;

      case "SETTING_SCALE_RESOLUTION_DOWN_FOR_SELECTED_MEDIA_TYPE":
        if (
          this.props.currentUserRole === "Agent" &&
          this.state.presenterForAgent !== this.roomSocket.id
        ) {
          let screenLowFalse = false;
          if (
            message.screenControl &&
            this.state.screenControlUsername !== "" &&
            this.state.screenControlUsername !== message.userName
          ) {
            screenLowFalse = true;
          } else if (message.screenControl) {
            if (message.resolution === resolutionConstant.high) {
              this.setState({ screenControlUsername: message.userName });
            } else {
              this.setState({ screenControlUsername: "" });
              for (var key in this.props.highResUser) {
                if (this.props.highResUser.hasOwnProperty(key)) {
                  if (this.props.highResUser[key].includes(message.mediaType)) {
                    screenLowFalse = true;
                    break;
                  }
                }
              }
            }
          } else {
            if (this.props.highResUser[message.userName]) {
              let userData = this.props.highResUser[message.userName];

              if (userData.includes(message.mediaType)) {
                const index = userData.indexOf(message.mediaType);

                if (index > -1) {
                  // only splice array when item is found
                  userData.splice(index, 1); // 2nd parameter means remove one item only

                  this.props.removeHighResUserStreamType(
                    message.userName,
                    userData
                  );
                }
                if (
                  message.mediaType === mediaTypeConstant.screen &&
                  this.state.screenControlUsername !== ""
                ) {
                  screenLowFalse = true;
                }

                // eslint-disable-next-line @typescript-eslint/no-redeclare
                for (var key in this.props.highResUser) {
                  if (this.props.highResUser.hasOwnProperty(key)) {
                    if (
                      this.props.highResUser[key].includes(message.mediaType)
                    ) {
                      screenLowFalse = true;
                      break;
                    }
                  }
                }
              } else {
                this.props.addHighResUser(message.userName, [
                  ...userData,
                  message.mediaType,
                ]);
              }
            } else {
              this.props.addHighResUser(message.userName, [message.mediaType]);
            }
          }

          if (!screenLowFalse) {
            this.scaleDownResolutionMethod(
              message.mediaType,
              false,
              message.resolution
            );
          }
        }
        break;

      case "RESTRICTED_APP_ALERT":
        if (this.props.currentUserRole !== "Agent") {
          this.props.addNotificationsUsingSingle(
            message.startDate,
            message.msg,
            message.username,
            notificationByType.restrictedApps,
            message?.peerName
          );

          toast(message.msg);
          this.notifyMe(message.msg);
        }
        break;

      case "CREATE_REMOTE_PEERS":
        let createRemotepeer = this.getPeerFromRemotePeersArray(
          message.peerSessionId
        );

        if (!createRemotepeer) {
          createRemotepeer = new RemotePeer(
            message.peerSessionId,
            message.peerUsername,
            message.peerName,
            message.peerType,
            false,
            false,
            false,
            false,
            false,
            "",
            "",
            "",
            false,
            "",
            "",
            false,
            "",
            false,
            "",
            "",
            ""
          );
          this.addPeerToArrayIfDoesNotExists(createRemotepeer);
        }

        break;
      // case "CHANGE_VIEW_MODE": {
      //   // this.remotePeers = [];
      //   const toastId = toast.info(`Changing view mode, please wait`, {
      //     // position: "top-left",
      //     autoClose: false,
      //     closeOnClick: false,
      //     closeButton: false,
      //     draggable: false,
      //   });
      //   const previousPagePeers = this.getPagePeers().map(
      //     (peer) => peer.peerSessionId
      //   );
      //   await this.pauseConsumersForPeer(previousPagePeers);
      //   this.setState(
      //     { roomViewMode: message.viewMode, pageNumber: 1 },
      //     async () => {
      //       if (
      //         message.viewMode === roomViewModeConst.collabMode ||
      //         checkForSupManAdmin(this.props.currentUserRole, "equalTo")
      //       ) {
      //         const pagePeersSessionIds = this.getPagePeers().map(
      //           (peer: RemotePeer) => peer.peerSessionId
      //         );
      //         await this.resumeConsumersForPeer(pagePeersSessionIds);
      //       } else {
      //         const pagePeersSessionIds: Array<string> = [];
      //         this.remotePeers.forEach((peer) => {
      //           if (checkForSupManAdmin(peer.peerType, "equalTo")) {
      //             pagePeersSessionIds.push(peer.peerSessionId as string);
      //           }
      //         });
      //         await this.resumeConsumersForPeer(pagePeersSessionIds);
      //       }
      //       this.setState({ remotePeers: this.remotePeers });
      //       toast.dismiss(toastId);
      //       toast.info(`View mode has been changed`);
      //       // if (message.viewMode === roomViewModeConst.collabMode) {
      //       // this.getJoinedPeersData();
      //       // } else {
      //       //   this.getJoinedPeersData();
      //       // }
      //     }
      //   );

      //   break;
      // }

      case "NEW_WHITEBOARD":
        window.open(`/room/whiteboard/${this.roomname}`, "_blank");
        break;

      case "AUDIO_LEVEL_CHANGE":
        // if (message.peerSessionId) {
        //   let volume = message.volume;
        // }
        break;

      case "NEW_PRESENTER":
        if (this.props.currentUserRole === "Agent") {
          let presenterPeer = this.getPeerFromRemotePeersArray(
            this.state.presenter
          );

          let promotePeer = this.getPeerFromRemotePeersArray(
            message.peerSessionId
          );

          if (this.state.presenterForAgent === this.roomSocket.id) {
            if (this.props.currentUserRole === "Agent") {
              let video = false;
              let screen = false;
              let screenTwo = false;
              let allThreeGet = false;

              // eslint-disable-next-line @typescript-eslint/no-redeclare
              for (var key in this.props.highResUser) {
                if (this.props.highResUser.hasOwnProperty(key)) {
                  if (
                    this.props.highResUser[key].includes(
                      mediaTypeConstant.screen
                    )
                  ) {
                    screen = true;
                  }
                  if (
                    this.props.highResUser[key].includes(
                      mediaTypeConstant.screentwo
                    )
                  ) {
                    screenTwo = true;
                  }
                  if (
                    this.props.highResUser[key].includes(
                      mediaTypeConstant.video
                    )
                  ) {
                    video = true;
                  }

                  if (video && screen && screenTwo) {
                    allThreeGet = true;
                    break;
                  }
                }
              }

              if (!allThreeGet) {
                if (!video) {
                  this.scaleDownResolutionMethod(
                    mediaTypeConstant.video,
                    false,
                    resolutionConstant.low
                  );
                }
                if (!screen) {
                  this.scaleDownResolutionMethod(
                    mediaTypeConstant.screen,
                    false,
                    resolutionConstant.low
                  );
                }
                if (!screenTwo) {
                  this.scaleDownResolutionMethod(
                    mediaTypeConstant.screentwo,
                    false,
                    resolutionConstant.low
                  );
                }
              }
            }
          }

          if (this.state.presenter === undefined) {
            this.setState({
              presenter: message.peerSessionId,
            });
          }

          if (!this.roomSocket || promotePeer) {
            this.resumeConsumersForPeer([message.peerSessionId]);
            this.setState({
              presenter: message.peerSessionId,
              presenterForAgent: "",
            });
          }

          if (this.roomSocket.id === message.peerSessionId) {
            // for agent who has been promoted
            this.setState({ presenterForAgent: message.peerSessionId });
            this.scaleDownResolutionMethod("", true, resolutionConstant.high);
          }

          if (presenterPeer) {
            if (!this.multipleSupervisors) {
              // if Already promoted user is agent
              if (this.roomSocket.id === message.peerSessionId) {
                if (checkForSupManAdmin(presenterPeer.peerType, "notEqualTo")) {
                  this.resumeConsumersForPeer([message.allInstructor[0]]);
                  this.setState({
                    presenter: message.allInstructor[0],
                  });
                  this.pauseConsumersForPeer([presenterPeer.peerSessionId]);
                }
              } else if (
                message.peerSessionId !== presenterPeer.peerSessionId
              ) {
                this.pauseConsumersForPeer([presenterPeer.peerSessionId]);
              }
            } else {
              if (checkForSupManAdmin(presenterPeer.peerType, "notEqualTo")) {
                this.pauseConsumersForPeer([presenterPeer.peerSessionId]);
                this.setState({ presenter: "" });
              }
            }
          }
        } else {
          this.performRightShiftByOne(message.peerSessionId);
          this.setState({ presenter: message.peerSessionId });
        }

        break;

      case "NEW_PRESENTER_PROMOTED_AGENT":
        if (this.props.currentUserRole === "Agent") {
          if (
            this.state.presenterForAgent === this.roomSocket.id &&
            this.state.presenter === message.peerSessionId &&
            !this.multipleSupervisors
          ) {
            this.resumeConsumersForPeer([message.allInstructor[0]]);
            this.setState({
              presenter: message.allInstructor[0],
            });
          }
        }

        break;

      case "CALL_RECIEVING":
        if (message.peers) {
          const rp = this.getPeerFromRemotePeersArray(message.peers.remoteID);
          const rp2 = this.getPeerFromRemotePeersArray(message.peers.ownID);

          if (rp) {
            rp.isCallModalOn = true;
          }

          if (rp2) {
            rp2.isCallModalOn = true;
          }

          if (message.peers.ownID === this.roomSocket.id) {
            if (!message.observeflag) {
              if (this.state.openentObserverAudioId !== "") {
                socketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
                  roomname: this.roomname,
                  peerSessionId: this.state.openentObserverAudioId,
                  from: "privateagent",
                });
              } else if (this.state.openentObserveeAudioId !== "") {
                socketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
                  roomname: this.roomname,
                  peerSessionId: this.state.openentObserveeAudioId,
                  from: "privatesupervisor",
                });
              }
            }
            this.setState({
              callModalOption: {
                ...this.state.callModalOption,
                isCallModal: true,
                messageForCall: `${rp?.peerName.split(" ")[0]}`,
                firstLetter: `${rp?.peerName.split(" ")[0][0]}`,
                callAcceptedButton: true,
                idForMakingCall: message.peers.remoteID,
              },
            });

            // if (document.hidden !== undefined && document.hidden) {
            toast(`${rp?.peerName} calling...`);
            this.notifyMe(`${rp?.peerName} calling...`);
            // }
          }

          this.setState({ remotePeers: this.remotePeers });
        }
        break;

      case "CALL_REJECTED":
        if (message.peers) {
          const rp = this.getPeerFromRemotePeersArray(message.peers.remoteID);
          const rp2 = this.getPeerFromRemotePeersArray(message.peers.ownID);

          if (rp) {
            rp.isCallModalOn = false;
          }

          if (rp2) {
            rp2.isCallModalOn = false;
          }

          if (message.peers.ownID === this.roomSocket.id) {
            this.setState({
              callModalOption: {
                ...this.state.callModalOption,
                isCallModal: false,
                messageForCall: "",
                callAcceptedButton: false,
                firstLetter: "",
                idForMakingCall: "",
                peerSessionIdRemote: "",
              },
            });
            if (message.peers.typeAction === "CALL_REJECTED") {
              toast(`Call rejected by ${rp?.peerName.split(" ")[0]}`);
              this.notifyMe(`Call rejected by ${rp?.peerName.split(" ")[0]}`);
            } else if (message.peers.typeAction === "CALL_DID_NOT_PICKUP") {
              toast(`${rp?.peerName.split(" ")[0]} is not available right now`);
              this.notifyMe(
                `${rp?.peerName.split(" ")[0]} is not available right now`
              );
            }
          }
          if (message.peers.remoteID === this.roomSocket.id) {
            if (message.peers.typeAction === "CALL_DID_NOT_PICKUP") {
              toast(`Missed private call from ${rp2?.peerName.split(" ")[0]}`);
              this.notifyMe(
                `Missed private call from ${rp2?.peerName.split(" ")[0]}`
              );
            }
          }
          this.setState({ remotePeers: this.remotePeers });
        }
        break;

      case "CALL_DISCONNECTED":
        if (message.peers) {
          const rp2 = this.getPeerFromRemotePeersArray(message.peers.ownID);

          if (rp2) {
            rp2.isCallModalOn = false;
          }

          if (this.state.imCaller) {
            socketInstance.sendMessage("PRIVATE_CALL_ENDED_ACKNOWLEDGE", {
              callEndAt: new Date(),
              callAction: "USER_DISCONNECTED",
            });
            this.setState({ imCaller: false });
          }

          if (message.peers.ownID === this.roomSocket.id) {
            this.setState({
              callModalOption: {
                ...this.state.callModalOption,
                isCallModal: false,
                messageForCall: "",
                callAcceptedButton: false,
                firstLetter: "",
              },
            });
            // LATER CHANGE MESSAGE
            // toast(
            //   `${message.peers.openentPeerName.split(" ")[0]} has network issue`
            // );
          }
          this.setState({ remotePeers: this.remotePeers });
        }
        break;

      case "OBSERVER_AUDIO_STARTED":
        if (message.observeeId && message.observerId) {
          let observeePeer = this.getPeerFromRemotePeersArray(
            message.observeeId
          );
          let observerPeer = this.getPeerFromRemotePeersArray(
            message.observerId
          );

          if (observeePeer) {
            observeePeer.openentObserverAudioId = message.observerId;
          }

          if (observerPeer) {
            observerPeer.openentObserveeAudioId = message.observeeId;
          }

          socketInstance.sendMessage("PAUSE_AUDIO_CONSUMERS_FOR_PEER", {
            roomname: this.roomname,
            consumersToBePaused: [message.observeeId, message.observerId],
          });

          // @ts-ignore
          if (message.observeeId === this.roomSocket.id) {
            this.setState({
              openentObserverAudioId: message.observerId,
            });

            // Process Audio that we got with screen stream from electron
            this.processAudioOfAgent();

            this.audioProducer.resume();
          }
          this.observerModeOn = true;

          if (message.observerId === this.roomSocket.id) {
            this.setState({
              openentObserveeAudioId: message.observeeId,
            });
          }
        }
        break;

      case "STOP_OBSERVER_AUDIO":
        if (message.observeeId && message.observerId) {
          let observeePeer = this.getPeerFromRemotePeersArray(
            message.observeeId
          );
          let observerPeer = this.getPeerFromRemotePeersArray(
            message.observerId
          );

          if (observeePeer) {
            observeePeer.openentObserverAudioId = "";
          }

          if (observerPeer) {
            observerPeer.openentObserveeAudioId = "";
          }

          // @ts-ignore
          if (message.observeeId === this.roomSocket.id) {
            this.setState({
              openentObserverAudioId: "",
            });
            if (message.from !== "unmuteagent") {
              this.audioProducer.pause();
            }

            // Replace Audio track from 'elctron audio' to local 'mic audio'
            if (this.state.localAudioStream) {
              let track = this.state.localAudioStream.getAudioTracks()[0];
              this.replaceAudioTrack(track);
            }
          }
          this.observerModeOn = false;

          if (message.observerId === this.roomSocket.id) {
            this.setState({
              openentObserveeAudioId: "",
            });

            this.notificationForObservation(
              message.from,
              observeePeer?.peerName
            );
          }

          // socketInstance.sendMessage("RESUME_AUDIO_CONSUMERS_OF_OBSERVEE", {
          //   roomname: this.roomname,
          //   observeeId: message.observeeId,
          // });
        }
        break;

      case "MUTE_ALL_PARTICIPANT":
        if (
          !this.state.localAudioPaused &&
          !this.state.isPrivateCallOn &&
          this.state.openentObserverAudioId === ""
        ) {
          this.pauseUnPauseAudio(this.roomSocket.id);
          toast(`All participants have muted`);
        }
        break;

      case "PRIVATE_CALL_STARTED":
        if (message.peers) {
          let peer1 = this.getPeerFromRemotePeersArray(message.peers[0]);
          let peer2 = this.getPeerFromRemotePeersArray(message.peers[1]);

          if (peer1) {
            peer1.privateCallPeerOne = peer2?.peerName;
            peer1.isPrivateCallOn = true;
          }
          if (peer2) {
            peer2.privateCallPeerOne = peer1?.peerName;
            peer2.isPrivateCallOn = true;
          }
          if (
            message.peers[0] === this.roomSocket.id ||
            message.peers[1] === this.roomSocket.id
          ) {
            this.setState({ isPrivateCallOn: true });
            this.setState({ privateCallPeerOne: peer1?.peerName });
            let peerName = peer1 ? peer1.peerName : peer2?.peerName;
            let peerId = peer1 ? peer1.peerSessionId : peer2?.peerSessionId;
            this.setState({ openentCallerPerson: peerId as string });
            this.unpauseLocalMic();
            this.notifyMe(`You are now in a private call with ${peerName}`);
            toast(`You are now in a private call with ${peerName}`);
          }

          let socketData: any = {
            roomname: this.roomname,
            consumersToBePaused: message.peers,
          };
          if (message.peers[0] === this.roomSocket.id) {
            socketData.callAt = new Date();
          }
          socketInstance.sendMessage(
            "PAUSE_AUDIO_CONSUMERS_FOR_PEER",
            socketData
          );

          this.setState({ remotePeers: this.remotePeers }, () => { });
        }
        break;

      case "PRIVATE_CALL_ENDED":
        if (message.privateStoppedPeer) {
          let peer1 = this.getPeerFromRemotePeersArray(
            message.privateStoppedPeer[0]
          );
          let peer2 = this.getPeerFromRemotePeersArray(
            message.privateStoppedPeer[1]
          );
          if (peer1) {
            peer1.privateCallPeerOne = "";
          }
          if (peer2) {
            peer2.privateCallPeerOne = "";
          }
          if (
            message.privateStoppedPeer[0] === this.roomSocket.id ||
            message.privateStoppedPeer[1] === this.roomSocket.id
          ) {
            if (!this.state.localAudioPaused) {
              this.pauseUnPauseAudio(this.roomSocket.id);
            }
            this.setState({ isPrivateCallOn: false });
            this.setState({ openentCallerPerson: "" });
            this.setState({ privateCallPeerOne: "" });
            this.setState({
              callModalOption: {
                ...this.state.callModalOption,
                isCallModal: false,
                messageForCall: "",
                callAcceptedButton: false,
                firstLetter: "",
              },
            });
            let peerName = peer1 ? peer1.peerName : peer2?.peerName;
            this.notifyMe(`Your private call with ${peerName} has ended`);
            this.pauseLocalMic();
            toast(`Your private call with ${peerName} has ended`);
            if (this.state.imCaller) {
              socketInstance.sendMessage("PRIVATE_CALL_ENDED_ACKNOWLEDGE", {
                callEndAt: new Date(),
              });
              this.setState({ imCaller: false });
            }
          }

          if (peer1) {
            peer1.isPrivateCallOn = false;
            peer1.isCallModalOn = false;
          }

          if (peer2) {
            peer2.isPrivateCallOn = false;
            peer2.isCallModalOn = false;
          }
          this.setState({ remotePeers: this.remotePeers });
        }
        break;

      case "PRIVATE_CALL_DISCONNECTED":
        if (message.peerPrivateId) {
          let peer1 = this.getPeerFromRemotePeersArray(message.peerPrivateId);

          if (message.peerPrivateId === this.roomSocket.id) {
            this.setState({ isPrivateCallOn: false });
            this.pauseLocalMic();
            this.notifyMe(
              `Your private call with ${message.disconnectedPeerName} has ended`
            );
            toast(
              `Your private call with ${message.disconnectedPeerName} has ended`
            );
          }

          if (peer1) {
            peer1.privateCallPeerOne = "";
            peer1.isPrivateCallOn = false;
          }
          this.setState({ remotePeers: this.state.remotePeers });
        }
        break;

      case "SHIFT_TO_ANOTHER_ROOM":
        localStorage.setItem(this.props.userName, message.roomname);
        localStorage.setItem(
          this.props.userName + "viewMode",
          message.viewMode
        );
        // window.open(`../room/${newRoomname}`, "_blank");
        // window.close();
        this.props?.navigate("/dashboard");
        toast("you are being shifted to " + message.roomname);
        break;

      case "CONTROL_DISCONNECT_BY_USER":
        this.props.clearControlId();
        break;

      case "ALREADY_ON_CONTROL_SCREEN":
        toast(
          "can't take control screen, already has control access by someone"
        );
        this.props.clearControlId();
        break;

      case "FACE_DETECTED":
        const faceDetectedPeer = this.getPeerFromRemotePeersArray(
          message.peerSessionId
        );
        if (faceDetectedPeer) {
          let notificationState = this.props.notifications;

          let notificationStatePeer = notificationState[message.peerUsername];

          let notificationStatePeerNotiType = notificationStatePeer
            ? notificationStatePeer[notificationByType.multipleFaces]
            : null;

          let peerFaceDetect =
            notificationStatePeerNotiType &&
            notificationStatePeerNotiType.find(
              (value) => message.peerUsername === value.username
            );

          if (
            notificationStatePeerNotiType &&
            notificationStatePeerNotiType?.length &&
            peerFaceDetect?.endTime === 0 &&
            (peerFaceDetect.numfaces !== message.numfaces || message.videoPause)
          ) {
            this.props.addNotifyEndTime(
              message.time,
              message.peerUsername,
              notificationByType.multipleFaces
            );
          }

          if (
            message.numfaces !== 1 &&
            message.numfaces !== 0 &&
            !message.videoPause &&
            ((notificationStatePeerNotiType &&
              notificationStatePeerNotiType?.length <= 0) ||
              peerFaceDetect?.numfaces !== message.numfaces ||
              peerFaceDetect?.endTime !== 0)
          ) {
            this.props.addNotifications(
              message.numfaces,
              message.time,
              0,
              message.msg,
              message.peerUsername,
              notificationByType.multipleFaces,
              message?.peerName
            );
          }
          // }

          faceDetectedPeer.expression = message.expression;
          faceDetectedPeer.numfaces = message.numfaces;
          this.setState({ remotePeers: this.remotePeers });
        }
        break;

      case "VERIFY_FACE":
        const verifyFacePeer = this.getPeerFromRemotePeersArray(
          message.peerSessionId
        );
        if (verifyFacePeer) {
          verifyFacePeer.faceVerificationSuccess =
            message.faceVerificationSuccess;
          verifyFacePeer.isNotSamePerson = message.isNotSamePerson;
          verifyFacePeer.showAlertInParticipant =
            message.showAlertInParticipant;
        }

        this.setState({ remotePeers: this.remotePeers });

        break;

      case "CELLPHONE_DETECTED":
        const cellPhoneDetectedPeer = this.getPeerFromRemotePeersArray(
          message.peerSessionId
        );
        if (cellPhoneDetectedPeer) {
          if (cellphoneTimeout[message.peerSessionId]) {
            clearTimeout(cellphoneTimeout[message.peerSessionId]);
          }

          console.log(
            "TIMEOUT",
            message.peerUsername,
            this.props.userName,
            this.state.peerSessionIdCellPhone,
            this.state.peerSessionIdCellPhone.has(message.peerSessionId),
            typeof this.state.peerSessionIdCellPhone.has(message.peerSessionId)
          );
          if (
            message.peerUsername !== this.props.userName &&
            !this.state.peerSessionIdCellPhone.has(message.peerSessionId)
          ) {
            this.state.peerSessionIdCellPhone.add(message.peerSessionId);

            this.props.addNotificationsUsingSingle(
              message.startDate,
              "Cell phone has been detected",
              message.peerUsername,
              notificationByType.cellPhone,
              message?.peerName
            );
          }
          cellPhoneDetectedPeer.objectDetect = message.cellphone;
          this.setState({ remotePeers: this.remotePeers });
          cellphoneTimeout[message.peerSessionId] = null;

          cellphoneTimeout[message.peerSessionId] = setTimeout(() => {
            console.log("TIMEOUT");
            cellPhoneDetectedPeer.objectDetect = "";
            this.setState({ remotePeers: this.remotePeers });
            this.state.peerSessionIdCellPhone.delete(message.peerSessionId);
          }, (parseInt(this.cellphoneFaceDetectSeconds) + 3) * 1000);
        }
        break;






      case "GET_ALERT_NOTIFICATION":{
        console.log({message})
        // peerName
        // :
        // "Student Ten"
        // peerSessionId
        // :
        // "uczLK3vFnDKMfUVwAAAD"
        // peerType
        // :
        // "Agent"
        // peerUsername
        // :
        // "STUDENT10"
        // time
        // :
        // 1725274774775
        // type
        // :
        // "GET_ALERT_NOTIFICATION"

        this.props.addNotificationsUsingSingle(
          message.time,
          `${message.peerUsername}'s camera is ${message.msg}`,
          message.peerUsername,
          notificationByType.camera,
          message?.peerName
        );


        this.notifyMe(`${message.peerUsername}'s camera is ${message.msg}`)





        break;
      }

      case "SOCKET_CONNECTED":
        console.log("SOCKET_CONNECTED", socketInstance.ifReconnect);
        if (socketInstance.ifReconnect) {
          console.log(`Network unstable, reconnecting`);

          socketInstance.ifReconnect = false;
          this.reconnectionStatusInRoompage = true;
          if (!this.state.localAudioPaused) {
            this.isreconnectionStatusAudio = true;
          }

          if (
            convertIntoBoolean(this.screenControl) &&
            checkForSupManAdmin(this.props.currentUserRole, "notEqualTo")
          ) {
            this.isreconnectionStatusScreenForElectron = true;
          }
          if (!this.state.localVideoPaused) {
            this.isreconnectionStatusVideo = true;
          }
          this.props.clearControlId();
          this.props.clearChatData();
          this.props.clearCollabScreenData();
          this.props.clearActivityReducer();
          this.props.setVideoOption("normal");
          this.props.backgroundModalClose();
          this.props.setReconnectioStatusForChat(true);

          // this.props.setReconnectionStatus(true);

          if (this.publishTransport) {
            this.publishTransport.close();
            //@ts-ignore
            this.publishTransport = null;
          }
          if (this.subscribeTransport) {
            this.subscribeTransport.close();
            //@ts-ignore
            this.subscribeTransport = null;
          }

          if (this.audioProducer) {
            this.audioProducer.close();
          }
          if (this.screenProducer) {
            this.screenProducer.close();
          }
          if (this.screenTwoProducer) {
            this.screenTwoProducer.close();
          }

          if (this.audioFileProducer) {
            this.audioFileProducer.close();
          }
          if (this.videoProducer) {
            this.videoProducer.close();
          }
          if (this.screenAudioProducer) {
            this.screenAudioProducer.close();
          }

          if (this.device) {
            //@ts-ignore
            this.device = null;
          }
          this.remotePeers = [];
        }



        console.log(
          convertIntoBoolean(this.screenControl),
          "checking screen control"
        );

        this.setState(
          (state) => {
            if (
              (!convertIntoBoolean(this.screenControl) ||
                checkForSupManAdmin(this.props.currentUserRole, "equalTo")) &&
              this.reconnectionStatusInRoompage
            ) {
              return {
                ...this.initialState,
                localScreenStream: this.props.isVideoFile
                  ? undefined
                  : state.localScreenStream,
                localScreenTwoStream: state.localScreenTwoStream,
                localScreenStopped: this.props.isVideoFile
                  ? true
                  : state.localScreenStopped,
                localScreenStoppedTwo: state.localScreenStoppedTwo,
                segmentationConfig: state.segmentationConfig,
                tflite: state.tflite,
                tfliteSIMD: state.tfliteSIMD,
                selectedTFLite: state.selectedTFLite,
                isSIMDSupported: state.isSIMDSupported,
                sideBarOpen: this.props.isSidebarOpen,
              };
            } else {
              return {
                ...this.initialState,
                segmentationConfig: state.segmentationConfig,
                tflite: state.tflite,
                tfliteSIMD: state.tfliteSIMD,
                selectedTFLite: state.selectedTFLite,
                isSIMDSupported: state.isSIMDSupported,
                sideBarOpen: this.props.isSidebarOpen,
              };
            }
          },
          async () => {
            this.props.clearControlAudioVideoFile();
            const presenter = message.presenter;

            // add all screen peer id into redux while joining and data getting from backend
            this.props.addAllCollabScreenPeerWhileConnected(
              message.screenCollabPeerArray
            );
            // this.props.getLayoutSettingActions(this.props.userName,"room");
            this.setState({ presenter: presenter });
            this.roomSocket = socketInstance.socket as SocketIOClient.Socket;
            this.props.setMySocketId(this.roomSocket.id);
            socketInstance.disconnectSocketId = this.roomSocket.id;

            this.connectionStatus = "connected";
            //get Room view mode
            // const viewModeDetails = (await socketInstance.sendMessage(
            //   "GET_VIEW_MODE",
            //   {
            //     roomname: this.roomname,
            //   }
            // )) as Record<string, string>;
            // if (viewModeDetails) {
            //   this.setState({ roomViewMode: viewModeDetails.viewMode });
            // }
            //set recording enabled/disabled on server
            socketInstance.sendMessage("IS_RECORDING_ENABLED", {
              recordingEnabled: this.isRecordingEnabled,
            });

            // get chat history
            this.getLimitedChatHistory().then((messagesResponse: any) => {
              const messageArray = messagesResponse.chatMessageArray;
              // @ts-ignore
              let messageList = [];
              if (messageArray.length) {
                messageList = messageArray.map(
                  // @ts-ignore
                  (messageObj: string) => {
                    const parseMessageObj = JSON.parse(messageObj);
                    return parseMessageObj;
                  }
                );
                this.props._setFullMessageList(messageList);
                this.props.setReconnectioStatusForChat(false);
              }
            });

            // const messagesResponse = (await this.getChatHistory()) as any;
            // const messageArray = messagesResponse.chatMessageArray;
            // // @ts-ignore
            // let messageList = [];
            // if (messageArray.length) {
            //   messageList = messageArray.map(
            //     // @ts-ignore
            //     (messageObj: string) => {
            //       const parseMessageObj = JSON.parse(messageObj);
            //       return parseMessageObj;
            //     }
            //   );
            // }

            this.props.setPeerVolume(this.roomSocket.id, -100);

            // this.props._setFullMessageList(messageList);
            await this.getRouterRtpCaps();
            await this.createPublishingTransport();
            await this.createSubscribingTransport();
            socketInstance.sendMessage("NOTIFICATIONS_LEAVE_AND_ENTER", {
              msg: `${this.props?.currentUserName ? this.props.currentUserName : this.props.userName} has joined the room`,
            });

            const joinedPeers = (await socketInstance.sendMessage(
              "GET_JOINED_PEERS",
              { roomname: this.roomname }
            )) as Record<string, Record<string, string | boolean>[]>;

            console.log(
              "socket_connected array",
              joinedPeers.peersArray.length
            );
            if (
              joinedPeers &&
              joinedPeers.peersArray &&
              joinedPeers.peersArray.length > 0
            ) {
              await Promise.all(
                joinedPeers.peersArray.map(async (peer) => {
                  const remotePeer = new RemotePeer(
                    peer.peerSessionId as string,
                    peer.peerUsername as string,
                    peer.peerName as string,
                    peer.peerType as string,
                    peer.peerAudioProducing as boolean,
                    peer.peerVideoProducing as boolean,
                    peer.peerScreenProducing as boolean,
                    peer.peerScreenTwoProducing as boolean,
                    peer.peerAudioFileProducing as boolean,
                    peer.expression as string,
                    peer.objectDetect as string,
                    peer.numfaces as string,
                    peer.isPrivateCallOn as boolean,
                    peer.emojiForIconPeer as string,
                    peer.emojiForHandAndLunchPeer as string,
                    peer.audioSystemProducing as boolean,
                    peer.privateCallPeer as any,
                    peer.isCallModalOn as boolean,
                    peer.networkStrength as string,
                    peer.openentObserverAudioId as string,
                    peer.openentObserveeAudioId as string,
                    peer.time as string
                  );
                  if (!peer.peerAudioProducing) {
                    this.addPeerToArrayIfDoesNotExists(remotePeer);
                  }
                  if (!peer.audioSystemProducing) {
                    this.addPeerToArrayIfDoesNotExists(remotePeer);
                  }
                  await this.consumeStreamsInPausedMode(remotePeer);
                })
              );

              if (!this.agentCamAlwaysOn && (this.state.localVideoPaused && this.props.currentUserRole === "Agent")) {

                this.videoPauseAlertInterval = setTimeout(() => {
                  socketInstance.sendMessage("GET_ALERT_NOTIFICATION", {
                    msg:"OFF",
                    time: Date.now()
                  })
                  toast.info("Your Camera is Off");

                }, 60000)

              }






              if (
                this.props.viewMode === roomViewModeConst.collabMode ||
                checkForSupManAdmin(this.props.currentUserRole, "equalTo")
              ) {
                const pagePeersSessionIds = this.getPagePeers().map(
                  (peer: RemotePeer) => peer.peerSessionId
                );
                await this.resumeConsumersForPeer(pagePeersSessionIds);
              } else if (
                this.multipleSupervisors &&
                this.props.currentUserRole === "Agent"
              ) {
                const pagePeersSessionIds: Array<string> = [];
                joinedPeers.peersArray.forEach((peer) => {
                  if (peer.peerType !== "Agent") {
                    pagePeersSessionIds.push(peer.peerSessionId as string);
                  } else {
                    // If Agent is promoted and currently is a presenter. Handle this case here
                    if (presenter === peer.peerSessionId) {
                      pagePeersSessionIds.push(peer.peerSessionId as string);
                    }
                  }
                });
                await this.resumeConsumersForPeer(pagePeersSessionIds);
              } else {
                const pagePeersSessionIds: Array<string> = [];
                if (this.state.presenter !== "") {
                  pagePeersSessionIds.push(this.state.presenter);
                }
                await this.resumeConsumersForPeer(pagePeersSessionIds);
              }
              this.setState({ remotePeers: this.remotePeers });
            }

            console.log(
              "checking reconnction for roompage",
              this.reconnectionStatusInRoompage
            );
            if (this.reconnectionStatusInRoompage) {
              console.log("into reconnection condition", this.screenControl);
              if (
                convertIntoBoolean(this.screenControl) &&
                checkForSupManAdmin(this.props.currentUserRole, "notEqualTo")
              ) {
                console.log(
                  "into screen share electron reconnection",
                  this.screenControl
                );
                this.shareUnshareScreen(this.roomSocket.id);
              }
              if (!this.state.localScreenStopped) {
                this.shareUnshareScreen(this.roomSocket.id);
              }
              if (!this.state.localScreenStoppedTwo) {
                this.shareUnshareScreenTwo(this.roomSocket.id);
              }

              this.reconnectionStatusInRoompage = false;
              this.props.setReconnectionStatus(true);
            }










          }
        );

        break;

      case "SEND_REQUEST_SCREEN":
        if (message.username === this.props.userName) {
          // socketInstance.sendMessage("REQUEST_OFFER", {
          //   roomname: this.roomname,
          // });
        }
        break;

      case "IS_ELECTRON_CONNECTED":
        if (message.username === this.props.userName) {
          if (message.isElectronConnected) {
            console.log("electron is connected");
            socketInstance.sendMessage("REQUEST_OFFER", {
              roomname: this.roomname,
            });
          } else {
            console.log("electron is not connected");
            this.openElectronApp();
          }
        } else {
          console.log("else part for  is electron connected");
        }
        break;

      case "CHECK_CONSUMER":
        console.log(message.status);

        break;

      case "SCREEN_SOCKET_CONNECTED_FOR_FE":
        if (message.username === this.props.userName) {
          if (this.publishTransport) {
            if (this.props.currentUserRole === "Agent") {
              socketInstance.sendMessage("REQUEST_OFFER", {
                roomname: this.roomname,
              });
            }
          }
        }
        break;

      case "GET_SCREEN_NUMBER":
        if (message.username === this.props.userName) {
          screen_num_object = message.data;
          socketInstance.sendMessage("SCREEN_NUM_STORED", {
            roomname: this.roomname,
          });
        }
        break;

      case "OFFER_FOR_SCREEN":
        if (message.username === this.props.userName) {
          try {
            // let PEERCONNECTION_CONFIGURATION: any;
            // PEERCONNECTION_CONFIGURATION = (await socketInstance.sendMessage("GET_COTURN_DETAILS", {}));
            // PEERCONNECTION_CONFIGURATION.iceServers = [];
            // PEERCONNECTION_CONFIGURATION.iceServers.push({
            //   urls: "stun:stun.l.google.com:19302",
            // });
            // if (PEERCONNECTION_CONFIGURATION.turnIps) {
            //   PEERCONNECTION_CONFIGURATION.turnIps.forEach((turnIp: string) => {
            //     PEERCONNECTION_CONFIGURATION.iceServers.push({
            //       urls: `turn:${turnIp.trim()}:443?transport=tcp`,
            //       credential: PEERCONNECTION_CONFIGURATION.turnPassword,
            //       username: PEERCONNECTION_CONFIGURATION.turnUserId,
            //     });
            //     PEERCONNECTION_CONFIGURATION.iceServers.push({
            //       urls: `turn:${turnIp.trim()}:443?transport=udp`,
            //       credential: PEERCONNECTION_CONFIGURATION.turnPassword,
            //       username: PEERCONNECTION_CONFIGURATION.turnUserId,
            //     });
            //   });
            // }

            let PEERCONNECTION_CONFIGURATION = {
              iceServers: [
                { urls: "stun:stun.l.google.com:19302" },
                {
                  urls: "turn:coturn.collaborationroom.ai",
                  credential: "collab123",
                  username: "collab",
                },
              ],
            };

            peer_connection = new RTCPeerConnection(
              PEERCONNECTION_CONFIGURATION
            );

            peer_connection.addEventListener("icecandidate", (e: any) =>
              this.onIceCandidate(e)
            );
            peer_connection.addEventListener("track", (e: any) => {
              if (e.track.kind === "audio") {
                this.systemAudioFromElectron = e;
                return;
              }
              if (e.streams.length > 0) {
                if (screen_num_object[e.streams[0].id] === "screen1") {
                  this.startScreen(e);
                } else if (
                  screen_num_object[e.streams[0].id] === "screen2" &&
                  this.props.viewMode !== roomViewModeConst.collabMode
                ) {
                  this.startScreenTwo(e);
                }
              }
            });

            peer_connection.addEventListener(
              "iceconnectionstatechange",
              (e: any) => this.onIceStateChange(e)
            );
            // We don't need to add any stream as we just need to receive it.
            peer_connection.setRemoteDescription(
              new RTCSessionDescription(message.data.jsep)
            );
            // @ts-ignore
            const answer = await peer_connection.createAnswer(OFFER_OPTIONS);
            await peer_connection.setLocalDescription(answer);

            socketInstance.sendMessage("ANSWER", {
              jsep: answer,
            });
          } catch (e) {
            console.log("[onOffer] Exception " + e);
          }
        } else {
          if (this.props.currentUserRole !== "Agent" && this.observerModeOn) {
            //Observe Agent audio automatically because electron is reconnected
            // Turn OFF the old observer
            document.getElementById("observerBtn")?.click();
            // Turn ON Observer Again
            setTimeout(() => {
              document.getElementById("observerBtn")?.click();
            }, 500);
          }
        }

        break;

      case "CANDIDATE_FROM_ELECTRON":
        if (message.username === this.props.userName && peer_connection) {
          try {
            console.log("[onCandidate] " + JSON.stringify(message));
            peer_connection.addIceCandidate(
              new RTCIceCandidate(message.data.jsep)
            );
          } catch (e) {
            console.log("[onCandidate] Exception " + e);
          }
        }

        break;
      case "REMOVE_ELECTRON_PEER":
      case "CLOSE_PEER_CONNECTION":
        if (message.username === this.props.userName) {
          if (peer_connection) {
            peer_connection.close();
            peer_connection = null;
            screen_num_object = {};
            if (!this.state.localScreenStopped && !this.props.isVideoFile) {
              this.shareUnshareScreen(this.roomSocket.id);
            }
          }
        }

        break;
      case "ERROR":
        this.addErrorMessage(message.message);
        if (
          message.message !== "Peer Already exists in room" &&
          message.message !==
          "You Already exists in Another Room, Can't Join Two Room at Same Time"
        )
          socketInstance.closeSocketConnection();
        break;

      case "OFFLINE":
        {
          let peer = this.getPeerFromRemotePeersArray(message.peerSessionId);
          if (peer) {
            let collabpeerId = this.props.screenCollabPeer.peerId;
            let collabpeerLength = this.props.screenCollabPeerArray.length;

            console.log(collabpeerId, collabpeerLength, "check collab peer");

            if (this.props.highResUser[message.peerUsername]) {
              this.props.removeHighResUserStreamType(message.peerUsername, []);
              if (this.props.currentUserRole === "Agent") {
                let video = false;
                let screen = false;
                let screenTwo = false;
                let allThreeGet = false;

                // eslint-disable-next-line @typescript-eslint/no-redeclare
                for (var key in this.props.highResUser) {
                  if (this.props.highResUser.hasOwnProperty(key)) {
                    if (
                      this.props.highResUser[key].includes(
                        mediaTypeConstant.screen
                      )
                    ) {
                      screen = true;
                    }
                    if (
                      this.props.highResUser[key].includes(
                        mediaTypeConstant.screentwo
                      )
                    ) {
                      screenTwo = true;
                    }
                    if (
                      this.props.highResUser[key].includes(
                        mediaTypeConstant.video
                      )
                    ) {
                      video = true;
                    }

                    if (video && screen && screenTwo) {
                      allThreeGet = true;
                      break;
                    }
                  }
                }

                if (!allThreeGet) {
                  if (!video) {
                    this.scaleDownResolutionMethod(
                      mediaTypeConstant.video,
                      false,
                      resolutionConstant.low
                    );
                  }
                  if (!screen) {
                    this.scaleDownResolutionMethod(
                      mediaTypeConstant.screen,
                      false,
                      resolutionConstant.low
                    );
                  }
                  if (!screenTwo) {
                    this.scaleDownResolutionMethod(
                      mediaTypeConstant.screentwo,
                      false,
                      resolutionConstant.low
                    );
                  }
                }
              }
            }

            if (message.peerUsername === this.state.screenControlUsername) {
              this.setState({ screenControlUsername: "" });
            }
            if (message.peerSessionId) {
              if (
                // message.peerSessionId !== this.state.current_id &&
                message.peerUsername !== this.props.userName &&
                message.peerUsername ===
                this.props.selectedChatUser.peerUserName
              ) {
                this.props.setSelectedChatUser(null, null, null, "EVERYONE");
                toast(`${message?.peerName} has left the room`);
              }

              this.setState({ handRaisedPeer: {} });
              socketInstance.sendMessage("REMOVE_CONSUMERS_FOR_PEER", {
                peerSessionId: message.peerSessionId,
              });
            }

            setTimeout(() => {
              this.props.removePeer(message.peerSessionId);
            }, 3000);

            let existParticipant = this.props.breakParticipant.find(
              (val: any) => val.peerId === message.peerSessionId
            );

            if (this.props.breakParticipant.length > 0 && existParticipant) {
              this.props.setorRemoveTempRoomUser(
                message.peerSessionId,
                message.peerUsername,
                this.props.breakParticipant
              );
            }

            // if screen shared peer and consume by peer has been disconnected
            if (collabpeerId !== "") {
              this.screenStopAndOfflinePeerForCollab(
                message.peerSessionId,
                "offline"
              );
            }

            console.log(
              collabpeerId,
              message.peerSessionId,
              "check condition of collab "
            );
            if (collabpeerId !== message.peerSessionId) {
              this.removePeerFromArrayIfExists(message.peerSessionId);
            }

            if (
              (collabpeerLength !== 1 ||
                collabpeerId !== message.peerSessionId) &&
              this.state.pageNumber > 1
            ) {
              if (this.getPagePeers().length === 0) {
                this.changePage(this.state.pageNumber - 1);
              }
            }
          } else {
            for (let i = 0; i < this.remotePeers.length; i += 1) {
              if (this.remotePeers[i].peerUsername === message.peerUsername) {
                this.remotePeers.splice(i, 1);
                this.setState({ remotePeers: this.remotePeers }, () => {
                  // // Increase Resoltion in case of less Participants. Executes whole array
                  // if (
                  //   checkForSupManAdmin(this.props.currentUserRole, "equalTo")
                  // ) {
                  //   if (
                  //     this.state.remotePeers.length <
                  //     LOW_RESOLUTION_PARTICIPANT_COUNT
                  //   ) {
                  //     this.changeLayerForMaxParticipantsArray(
                  //       this.state.remotePeers,
                  //       1
                  //     );
                  //   }
                  // }

                  const currentPageSessionIds = this.getPagePeers().map(
                    (peer: RemotePeer) => peer.peerSessionId
                  );
                  this.resumeConsumersForPeer(currentPageSessionIds);
                });
              }
            }
          }
        }
        break;

      case "PEER_STATUS_CHANGE":
        let peer = this.getPeerFromRemotePeersArray(message.peerSessionId);
        if (!peer) {
          peer = new RemotePeer(
            message.peerSessionId,
            message.peerUsername,
            message.peerName,
            message.peerType,
            false,
            false,
            false,
            false,
            false,
            message.expression,
            message.objectDetect,
            message.string,
            false,
            "",
            "",
            false,
            "",
            false,
            "",
            "",
            ""
          );
          this.addPeerToArrayIfDoesNotExists(peer);
        }
        switch (message.status) {
          case "AUDIO_PRODUCING":
            // this.addPeerToArrayIfDoesNotExists(peer);

            if (message.audioNum === "File") {
              peer.peerAudioProducing = true;
              this.setState({ remotePeers: this.remotePeers });
              await this.consumeAudioFileStream(peer);
            } else {
              peer.peerAudioProducing = true;
              this.setState({ remotePeers: this.remotePeers });
              await this.consumeAudioStream(peer);
            }

            break;
          // Sharing System Audio with Screen
          case "SCREEN_AUDIO_PRODUCING":
            if (message.audioNum === "System") {
              peer.audioSystemProducing = true;
              peer.systemAudioProducerRole = message.peerType;
              this.setState({ remotePeers: this.remotePeers });
              await this.consumeSystemAudioStream(peer);
            }
            break;

          case "FEEDBACK_IMG_UPLOAD":
            console.log("inside roompagetsx");
            if (message.agentUsername === this.props.userName) {
              console.log("inside roompagetsx 1");
              if (this.props.videoOption === "normal") {
                console.log("inside roompagetsx 2");
                let canvas: any = document.getElementById("canvas");
                const video: any = document.getElementsByClassName(
                  "face-detection-node"
                )[0];

                if (video) {
                  console.log("inside roompagetsx 2");
                  canvas.width = video.videoWidth;
                  canvas.height = video.videoHeight;
                  canvas
                    .getContext("2d")
                    .drawImage(
                      video,
                      0,
                      0,
                      video.videoWidth,
                      video.videoHeight
                    );
                  canvas.toBlob((blob: any) => {
                    console.log(blob);
                    // const img = new Image();
                    // img.src = window.URL.createObjectURL(blob);
                    // document.body.appendChild(img);
                    this.setState({
                      feedbackImageData: {
                        file: blob,
                        agentUsername: message.agentUsername,
                        parentRole: message.parentRole,
                        parentUsername: message.parentUsername,
                        room: message.room,
                      },
                    });
                  });
                } else {
                  console.log("inside roompagetsx 3");
                  // send error message to admin the feedback image cannot be captured
                }
              } else {
                console.log("inside roompagetsx 4");
                let canvas: any = document.getElementById("canvas");
                const input: any = document.getElementsByClassName(
                  "face-detection-node"
                )[0];

                if (input) {
                  canvas.width = input.videoWidth;
                  canvas.height = input.videoHeight;
                  const offscreen = document.createElement("canvas");
                  offscreen.width = canvas.width;
                  offscreen.height = canvas.height;
                  const ctx = offscreen.getContext("2d");
                  ctx?.drawImage(input, 0, 0, canvas.width, canvas.height);
                  console.log("inside roompagetsx 5");

                  offscreen.toBlob((blob: any) => {
                    this.setState({
                      feedbackImageData: {
                        file: blob,
                        agentUsername: message.agentUsername,
                        parentRole: message.parentRole,
                        parentUsername: message.parentUsername,
                        room: message.room,
                      },
                    });

                    // uploadFeedbackImg(blob, message.agentUsername, message.parentRole,message.parentUsername,message.room);
                  });
                }
              }
            }
            break;
          case "FEEDBACK_IMG_UPLOAD_API":
            if (message.agentUsername === this.props.userName) {
              const { file, agentUsername, parentRole, parentUsername, room } =
                this.state.feedbackImageData;
              uploadFeedbackImg(
                file,
                agentUsername,
                parentRole,
                parentUsername,
                room,
                message.comment
              );
            }
            break;
          case "VIDEO_PRODUCING":
            // this.addPeerToArrayIfDoesNotExists(peer);
            peer.peerVideoProducing = true;
            this.setState({ remotePeers: this.remotePeers });
            await this.consumeVideoStream(peer);

            if (
              checkForSupManAdmin(this.props.currentUserRole, "equalTo") ||
              this.props.viewMode === roomViewModeConst.collabMode
            ) {
              // peer.peerVideoProducing = true;
              // await this.consumeVideoStream(peer);
              const pagePeersSessionIds = this.getPagePeers().map(
                (peer: RemotePeer) => peer.peerSessionId
              );
              await this.resumeConsumersForPeer(pagePeersSessionIds);
            } else if (
              this.multipleSupervisors &&
              peer.peerType !== "Agent" &&
              this.props.currentUserRole === "Agent"
            ) {
              await this.resumeConsumersForPeer([peer.peerSessionId]);
            } else if (
              peer.peerSessionId === this.state.presenter &&
              this.props.currentUserRole === "Agent"
            ) {
              // peer.peerVideoProducing = true;
              // await this.consumeVideoStream(peer);
              await this.resumeConsumersForPeer([peer.peerSessionId]);
            }
            break;
          case "SCREEN_PRODUCING":
            // this.addPeerToArrayIfDoesNotExists(peer);
            if (message.screenNum === "Two") {
              peer.peerScreenTwoProducing = true;
              this.setState({ remotePeers: this.remotePeers });
              await this.consumeScreenTwoStream(peer);
            } else {
              peer.peerScreenProducing = true;
              this.setState({ remotePeers: this.remotePeers });
              await this.consumeScreenStream(peer);
            }

            peer.isVideoSwapped = "";
            // CollabMode
            if (this.props.viewMode === roomViewModeConst.collabMode) {
              // add screen producer id
              let screenPeerIdEmptyOrNot = this.props.screenCollabPeer.peerId;

              const pagePausePeersSessionIds = this.getPagePeers().map(
                (peer: RemotePeer) => peer.peerSessionId
              );

              // add screen peer id into redux
              await this.props.addCollabScreenPeer(
                peer.peerSessionId,
                peer.peerUsername
              );

              // if screen is not sharing by anyone
              if (screenPeerIdEmptyOrNot === "") {
                // getting all peer session id for pause

                const pagePeersSessionIds = this.getPagePeers().map(
                  (peer: RemotePeer) => peer.peerSessionId
                );

                const exactPausePeerSessionIds =
                  pagePausePeersSessionIds.filter(
                    (pagePausePeersSessionId) =>
                      !pagePeersSessionIds.includes(pagePausePeersSessionId)
                  );

                if (exactPausePeerSessionIds.length > 0) {
                  await this.pauseConsumersForPeer(exactPausePeerSessionIds);
                }

                // resume for 6 peer in one page

                await this.resumeConsumersForPeer(pagePeersSessionIds);

                // const allPeerSessionIds = this.remotePeers.map(
                //   (peer: RemotePeer) => peer.peerSessionId
                // );

                // socketInstance.sendMessage("CHANGE_LAYER", {
                //   peerSessionIds: allPeerSessionIds,
                //   mediaType: "VIDEO",
                //   spatialLayer: 0,
                // });
              }
            }
            // admin , supervisor and manager
            else if (
              checkForSupManAdmin(this.props.currentUserRole, "equalTo")
            ) {
              const pagePeersSessionIds = this.getPagePeers().map(
                (peer: RemotePeer) => peer.peerSessionId
              );
              await this.resumeConsumersForPeer(pagePeersSessionIds);
            }
            // Agent case. If multiple supervisor setting ON from Admin. show joining supervisors screen to agent
            else if (
              this.multipleSupervisors &&
              peer.peerType !== "Agent" &&
              this.props.currentUserRole === "Agent"
            ) {
              await this.resumeConsumersForPeer([peer.peerSessionId]);
            }
            // agent
            else if (
              peer.peerSessionId === this.state.presenter &&
              this.props.currentUserRole === "Agent"
            ) {
              await this.resumeConsumersForPeer([peer.peerSessionId]);
            }
            break;
          case "AUDIO_PAUSE":
            if (this.roomSocket.id !== message.peerSessionId) {
              const peerToPause = this.getPeerFromRemotePeersArray(
                message.peerSessionId
              );
              if (peerToPause) {
                peerToPause.peerAudioPaused = true;
                this.setState({ remotePeers: this.remotePeers });
              }
            } else {
              this.notifyMe(`You have been muted by the supervisor`);
              this.audioProducer.pause();
              this.setState({ localAudioPaused: true });
            }

            break;
          case "VIDEO_PAUSE":
            if (this.roomSocket.id !== message.peerSessionId) {
              const peerToPause = this.getPeerFromRemotePeersArray(
                message.peerSessionId
              );
              if (peerToPause) {
                peerToPause.peerVideoPaused = true;
                peerToPause.isVideoSwapped = "";
                this.setState({ remotePeers: this.remotePeers });
              }
            } else {
              this.notifyMe(`Your video has been paused by the supervisor`);

              // clearInterval(this.frstart?._id);
              clearInterval(this.frstart);
              socketInstance.sendMessage("FACE_DETECTED", {
                detections: [],
                time: Date.now(),
                videoPause: true,
              });
              // socketInstance.sendMessage("CELLPHONE_DETECTED", {
              //   class: "",
              //   score: "",
              // });
              this.videoProducer.pause();
              this.setState({ localVideoPaused: true });
              this.videoPauseAlertInterval = setTimeout(() => {
                socketInstance.sendMessage("GET_ALERT_NOTIFICATION", {
                  msg:"OFF",
                  time: Date.now()
                })
                toast.info("Your Camera is Off");
              }, 60000)
            }
            break;
          // case "SCREEN_PAUSE":
          //   {
          //     const peerToPause = this.getPeerFromRemotePeersArray(
          //       message.peerSessionId
          //     );
          //     if (peerToPause) {
          //       peerToPause.peerScreenPaused = true;
          //       this.setState({ remotePeers: this.remotePeers });
          //     }
          //   }
          //   break;
          case "AUDIO_RESUME":
            if (this.roomSocket.id !== message.peerSessionId) {
              const peerToResume = this.getPeerFromRemotePeersArray(
                message.peerSessionId
              );
              if (peerToResume) {
                if (message.audioNum === "File") {
                  peerToResume.peerAudioFilePaused = false;
                } else if (message.audioNum === "System") {
                  peerToResume.screenAudioPaused = false;
                } else {
                  peerToResume.peerAudioPaused = false;
                }
                this.setState({ remotePeers: this.remotePeers });
              }
            } else {
              if (this.state.openentObserverAudioId !== "") {
                socketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
                  roomname: this.roomname,
                  peerSessionId: this.state.openentObserverAudioId,
                  from: "unmuteagent",
                });
              }
              this.notifyMe(`Your mic has been resumed by the supervisor`);
              this.audioProducer.resume();
              this.setState({ localAudioPaused: false });

            }
            break;
          case "VIDEO_RESUME":
            if (this.roomSocket.id !== message.peerSessionId) {
              const peerToResume = this.getPeerFromRemotePeersArray(
                message.peerSessionId
              );
              if (peerToResume) {
                peerToResume.peerVideoPaused = false;
                peerToResume.isVideoSwapped = "";
                this.setState({ remotePeers: this.remotePeers });
              }
            } else {
              this.notifyMe(`Your video has been resumed by the supervisor`);
              this.videoProducer.resume();
              replaceVideoTimeout = setTimeout(() => {
                this.replaceVideoStream();
              }, 200);

              this.setState(
                () => ({
                  localVideoPaused: false,
                }),
                () => {
                  if (this.faceDetectionFlag) {
                    this.faceDetection();
                  }
                }
              );
              socketInstance.sendMessage("GET_ALERT_NOTIFICATION", {
                msg:"ON",
                time: Date.now()
              })
              clearInterval(this.videoPauseAlertInterval)
            }
            break;
          case "START_SCREEN_SHARE":
            if (message.screenNum === "Two") {
              this.startScreenTwo();
            } else {
              if (convertIntoBoolean(this.screenControl)) {
                // this.openElectronApp();
                if (this.props.currentUserRole === "Agent") {
                  socketInstance.sendMessage("IS_ELECTRON_CONNECTED", {
                    roomname: this.roomname,
                  });
                } else {
                  this.startScreen();
                }
              } else {
                this.startScreen();
              }
            }
            break;

          case "STOP_SCREEN_SHARE":
            if (message.screenNum === "Two") {
              if (this.screenTwoProducer) {
                const track =
                  this.state.localScreenTwoStream?.getVideoTracks()[0];
                track?.stop();
                this.setState({
                  localScreenStoppedTwo: true,
                  localScreenTwoStream: undefined,
                });
                socketInstance.sendMessage("SCREEN_STOP", {
                  roomname: this.roomname,
                  screenNum: "Two",
                });
              }
            } else {
              if (this.screenProducer) {
                const track = this.state.localScreenStream?.getVideoTracks()[0];
                track?.stop();
                this.setState({
                  localScreenStopped: true,
                  localScreenStream: undefined,
                });
                if (convertIntoBoolean(this.screenControl)) {
                  socketInstance.sendMessage("CLOSE_SCREEN_SHARE_APP", {});
                  if (!this.state.localScreenStoppedTwo) {
                    this.shareUnshareScreenTwo(message.peerSessionId);
                  }
                }

                socketInstance.sendMessage("SCREEN_STOP", {
                  roomname: this.roomname,
                });

                if (this.screenAudioProducer) {
                  const screenAudioTrack =
                    this.state.localScreenStream?.getAudioTracks()[0];
                  screenAudioTrack?.stop();
                  socketInstance.sendMessage("SYSTEM_AUDIO_STOP", {
                    roomname: this.roomname,
                  });
                  this.setState({
                    isScreenAudioShared: false,
                  });
                }
              }
            }
            break;
          case "SCREEN_STOP":
            if (this.roomSocket.id !== message.peerSessionId) {
              const peerToStop = this.getPeerFromRemotePeersArray(
                message.peerSessionId
              );
              if (message.screenNum === "Two") {
                if (peerToStop) {
                  peerToStop.peerscreenTwoStopped = true;
                  peerToStop.peerScreenTwoStream = undefined;
                  peerToStop.isVideoSwapped = "";
                  this.setState({ remotePeers: this.remotePeers });
                } else {
                  this.screenTwoProducer?.close();
                  this.setState({ localScreenStoppedTwo: true });
                }
              } else {
                if (peerToStop) {
                  // only for collab mode
                  if (this.props.viewMode === roomViewModeConst.collabMode) {
                    this.screenStopAndOfflinePeerForCollab(
                      peer.peerSessionId,
                      "stop"
                    );
                  }

                  peerToStop.peerScreenStopped = true;
                  peerToStop.peerScreenStream = undefined;
                  if (
                    peerToStop.isVideoSwapped.includes("video") &&
                    peerToStop.isVideoSwapped.includes("second") &&
                    peerToStop.isVideoSwapped.includes("third")
                  ) {
                    peerToStop.isVideoSwapped =
                      peerToStop.isVideoSwapped.replace("second", "");
                  } else {
                    peerToStop.isVideoSwapped = "";
                  }
                  this.setState({ remotePeers: this.remotePeers });
                } else {
                  this.screenProducer?.close();
                  this.screenAudioProducer?.close();
                  this.setState({ localScreenStopped: true });
                  this.setState({
                    isScreenAudioShared: false,
                  });
                }
              }
            }
            break;

          case "AUDIO_STOP":
            if (this.roomSocket.id !== message.peerSessionId) {
              const peerToStop = this.getPeerFromRemotePeersArray(
                message.peerSessionId
              );

              if (peerToStop) {
                peerToStop.peerAudioFileStream = undefined;
                peerToStop.peerAudioFilePaused = false;
                this.setState({ remotePeers: this.remotePeers });
              }
            }
            break;

          case "SYSTEM_AUDIO_STOP":
            if (this.roomSocket.id !== message.peerSessionId) {
              const peerToStop = this.getPeerFromRemotePeersArray(
                message.peerSessionId
              );

              if (peerToStop) {
                peerToStop.screenAudioStream = undefined;
                peerToStop.screenAudioPaused = false;
                this.setState({ remotePeers: this.remotePeers });
              }
            }
            break;
        }
        break;

      case "PEER_EXIT":
        this.goToDashboard();
        this.setState({ remotePeers: this.remotePeers });
        break;

      case "SCORE_CHANGE":
        if (message.avgScore === 10 && message.avgScore > 8) {
          this.setState({ signalStrength: "EXCELLENT" });
        } else if (message.avgScore < 8 && message.avgScore > 5) {
          this.setState({ signalStrength: "GREAT" });
        } else if (message.avgScore < 5 && message.avgScore > 3) {
          this.setState({ signalStrength: "OKAY" });
        } else {
          this.setState({ signalStrength: "WEAK" });
        }
        this.setState({ avgScore: message.avgScore });
        this.setState({ remotePeers: this.remotePeers });
        break;

      case "EMOJI_FOR_ICON":
        const iconPeer = this.getPeerFromRemotePeersArray(
          message.peerSessionId
        );
        if (iconPeer) {
          iconPeer.emojiForIconPeer = message.action;
          iconPeer.time = message.time;
          // perform a right shift if the peer does not exist on page 1..
          if (message.peerSessionId && message.action !== "")
            this.performRightShiftByOne(message.peerSessionId);

          this.setState({ remotePeers: this.remotePeers });

          // Add Alert for be right back
          if (message.action === "BERIGHTBACK" && message.flag === "start") {
            if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
              let timeStamp = Date.now();
              this.props.addNotifications(
                0,
                timeStamp,
                0,
                "Be Right Back",
                message.peerUsername,
                notificationByType.berightback,
                message?.peerName
              );
            }
          }

          if (message.action === "TECHNICALISSUE" && checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
            if (this.props?.agentHandRaiseandTechsupportPersonalization?.audio) {
              playChatNotification();
            }
            if (this.props?.agentHandRaiseandTechsupportPersonalization?.toast) {
              toast(`Technical Issue By ${message?.peerName}`);
            }
            if (this.props?.agentHandRaiseandTechsupportPersonalization?.desktop) {
            this.notifyMe(`Technical Issue By ${message?.peerName}`);
            }
            if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
              this.props.addNotifications(
                0,
                Date.now(),
                0,
                "Technical Issue",
                message.peerUsername,
                notificationByType.TechnicalIssue,
                message?.peerName
              );
            }
          }

          if (message.flag === "endBrb") {
            if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
              let timeStamp = Date.now();
              this.props.addNotifyEndTime(
                timeStamp,
                message.peerUsername,
                notificationByType.berightback
              );
            }
          }
          if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
            if (
              // BRB LIMIT EXCEEDED
              message.action === "BERIGHTBACK" &&
              this.brbTimeLimitStatus &&
              timeToMinutes(message.time) > +this.brbTimeLimit
            ) {
              toast(
                "Be RIght Back time limit exceeded for user " +
                  message?.peerName +
                  ". It was set to " +
                  this.brbTimeLimit +
                  " minute."
              );
            }
          }
        }
        break;

      case "EMOJI_FOR_HANDANDLUNCH":
        const handRaisedPeer = this.getPeerFromRemotePeersArray(
          message.peerSessionId
        );
        if (handRaisedPeer) {
          if (message.action === "HANDRAISED") {
            if (this.props?.agentHandRaiseandTechsupportPersonalization?.audio && checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
              playChatNotification();
            }
            if (this.props?.agentHandRaiseandTechsupportPersonalization?.toast) {
              toast(`Hand Raised By ${message?.peerName}`);
            }
            if (this.props?.agentHandRaiseandTechsupportPersonalization?.desktop) {
            this.notifyMe(`Hand Raised By ${message?.peerName}`);
            }
            this.setState({ handRaisedPeer: handRaisedPeer });
            if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
              this.props.addNotifications(
                0,
                Date.now(),
                0,
                "Hand Raised",
                message.peerUsername,
                notificationByType.handRaised,
                message?.peerName
              );
            }
            // this.handRaisedToTop(handRaisedPeer);
          } else if (message.action === "LUNCH" && message.flag === "start") {
            this.notifyMe(`${message.peerUsername} is on lunch`);
            if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
              this.props.addNotifications(
                0,
                Date.now(),
                0,
                "Lunch time",
                message.peerUsername,
                notificationByType.lunchTime,
                message?.peerName
              );
            }
          } else if (message.action === "BREAK" && message.flag === "start") {
            this.notifyMe(`${message.peerUsername} is on break`);
            if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
              this.props.addNotifications(
                0,
                Date.now(),
                0,
                "Break time",
                message.peerUsername,
                notificationByType.breakTime,
                message?.peerName

              );
            }
          } else if (message.flag === "endLUNCH") {
            if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
              this.props.addNotifyEndTime(
                Date.now(),
                message.peerUsername,
                notificationByType.lunchTime
              );
            }
          } else if (message.flag === "endBREAK") {
            if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
              this.props.addNotifyEndTime(
                Date.now(),
                message.peerUsername,
                notificationByType.breakTime
              );
            }
          } // Exceeded limit alerts of users for events LUNCH, BREAK & BRB (Shown Only to Higher roles)
          else if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
            if (
              message.action === "LUNCH" &&
              this.lunchTimeLimitStatus &&
              timeToMinutes(message.time) > +this.lunchTimeLimit
            ) {
              toast(
                "Lunch time limit exceeded for user " +
                  message?.peerName +
                  ". It was set to " +
                  this.lunchTimeLimit +
                  " minute."
              );
            }
            if (
              // BREAK LIMIT EXCEEDED
              message.action === "BREAK" &&
              this.breakTimeLimitStatus &&
              timeToMinutes(message.time) > +this.breakTimeLimit
            ) {
              toast(
                "Break time limit exceeded for user " +
                  message?.peerName +
                  ". It was set to " +
                  this.breakTimeLimit +
                  " minute."
              );
            }
          }

          handRaisedPeer.emojiForHandAndLunchPeer = message.action;
          handRaisedPeer.time = message.time;

          if (message.action === "BREAK" || message.action === "LUNCH") {
            handRaisedPeer.emojiForIconPeer = "";
          }
          // perform a right shift if the peer does not exist on page 1..
          if (message.peerSessionId && message.action !== "")
            this.performRightShiftByOne(message.peerSessionId);

          this.setState({ remotePeers: this.remotePeers });
        }

        break;

      case "NETWORK_STATUS":
        const networkPeer = this.getPeerFromRemotePeersArray(
          message.peerSessionId
        );
        if (networkPeer) {
          networkPeer.networkStrength = message.networkStrength;
          this.setState({ remotePeers: this.remotePeers });
        }
        break;

      case "RECONNECTED":
        this.setState(this.initialState);
        this.remotePeers = [];
        break;
      case "RECONNECTING":
        this.setState({ isLoading: true });
        this.remotePeers = [];
        break;

      default:
        break;
    }
  };

  removePeerInRedis = async () => {
    await socketInstance.sendMessage("REMOVE_PEER", { remove: true });
    socketInstance.closeSocketConnection();
    this.goToDashboard();
  };

  removePeerFromAnotherRoom = async () => {
    await socketInstance.sendMessage("REMOVE_PEER_FROM_ANOTHER_ROOM", {});
    socketInstance.closeSocketConnection();
    this.goToDashboard();
  };
  goToDashboard = () => {
    // this.props.history.push("/dashboard");
    this.props.componentRedirect();
  };

  changeViewMode = () => {
    if (this.props.viewMode === roomViewModeConst.collabMode) {
      socketInstance.sendMessage("CHANGE_VIEW_MODE", {
        roomname: this.roomname,
        viewMode: "LECTURE_MODE",
      });
    } else {
      socketInstance.sendMessage("CHANGE_VIEW_MODE", {
        roomname: this.roomname,
        viewMode: roomViewModeConst.collabMode,
      });
    }
  };

  getPagePeers = () => {
    if (
      this.props.viewMode !== roomViewModeConst.collabMode &&
      checkForSupManAdmin(this.props.currentUserRole, "notEqualTo") &&
      !this.multipleSupervisors
    ) {
      return this.remotePeers.filter((peer) => {
        return peer.peerSessionId === this.state.presenter;
      });
    }
    let pageSize = 0;

    if (
      this.props.viewMode === roomViewModeConst.collabMode &&
      this.props.screenCollabPeer.peerId !== ""
    ) {
      pageSize = this.pageSizeC;
    } else {
      pageSize = this.pageSizeL;
    }

    // Return Supervisor & Presenter peers only to Agents so that layout can be maintained on agent side
    if (
      this.multipleSupervisors &&
      this.props.viewMode !== "meeting" &&
      checkForSupManAdmin(this.props.currentUserRole, "notEqualTo")
    ) {
      let filteredPeers = [];
      for (let i = 0; i < this.remotePeers.length; i++) {
        if (
          checkForSupManAdmin(this.remotePeers[i].peerType, "equalTo") ||
          this.remotePeers[i].peerSessionId === this.state.presenter ||
          (this.state.presenter === this.remotePeers[i].peerSessionId &&
            checkForSupManAdmin(this.remotePeers[i].peerType, "notEqualTo"))
        ) {
          filteredPeers.push(this.remotePeers[i]);
        }
      }
      if (this.state.pageNumber === 1) {
        return filteredPeers.slice(0, pageSize - 1);
      }

      const startIndex = (this.state.pageNumber - 1) * (pageSize - 1);
      const endIndex = this.state.pageNumber * pageSize - this.state.pageNumber;
      return filteredPeers.slice(startIndex, endIndex);
      //return filteredPeers;
    }

    if (this.state.pageNumber === 1) {
      return this.remotePeers.slice(0, pageSize - 1);
    }

    // const startIndex = pageSize * (this.state.pageNumber - 1) - 1;
    const startIndex = (this.state.pageNumber - 1) * (pageSize - 1);
    const endIndex = this.state.pageNumber * pageSize - this.state.pageNumber;
    return this.remotePeers.slice(startIndex, endIndex);
  };

  getPageNumbers = () => {
    let pageSize = 0;
    if (
      this.props.viewMode === roomViewModeConst.collabMode &&
      this.props.screenCollabPeer.peerId !== ""
    ) {
      pageSize = this.pageSizeC;
    } else {
      pageSize = this.pageSizeL;
    }

    // Multiple supervisor case for agents only
    if (
      this.multipleSupervisors &&
      this.props.viewMode !== "meeting" &&
      checkForSupManAdmin(this.props.currentUserRole, "notEqualTo")
    ) {
      let filteredPeers = [];
      for (let i = 0; i < this.state.remotePeers.length; i++) {
        if (
          checkForSupManAdmin(this.state.remotePeers[i].peerType, "equalTo") ||
          this.state.remotePeers[i].peerSessionId === this.state.presenter ||
          (this.state.presenter === this.state.remotePeers[i].peerSessionId &&
            checkForSupManAdmin(
              this.state.remotePeers[i].peerType,
              "notEqualTo"
            ))
        ) {
          filteredPeers.push(this.state.remotePeers[i]);
        }
      }

      if (filteredPeers.length) {
        const pageLength = Math.ceil(
          // adding one to include the current user as well
          filteredPeers.length / (pageSize - 1)
        );
        return Array.from({ length: pageLength }, (_, i) => i + 1);
      }
      return [1];
    }

    if (this.state.remotePeers.length) {
      const pageLength = Math.ceil(
        // adding one to include the current user as well
        this.state.remotePeers.length / (pageSize - 1)
      );
      return Array.from({ length: pageLength }, (_, i) => i + 1);
    }
    return [1];
  };

  changePage = async (pageNumber: number) => {
    if (pageNumber === this.state.pageNumber) {
      return;
    }
    const previousPagePeers = this.getPagePeers().map(
      (peer) => peer.peerSessionId
    );

    this.setState({ pageNumber: pageNumber }, () => {
      const currentPageSessionIds = this.getPagePeers().map(
        (peer: RemotePeer) => peer.peerSessionId
      );
      this.resumeConsumersForPeer(currentPageSessionIds);
      this.pauseConsumersForPeer(previousPagePeers);
    });
  };

  closeReminderModal = () => {
    this.setState({
      reminderState: false,
    });
  };

  screenResumeForCollabPeer = async (peer: screenPeerType) => {
    await socketInstance.sendMessage(
      "RESUME_AND_PAUSE_CONSUMERS_SCREEN_FOR_COLLAB_PEER",
      {
        pausePeerId: this.props.screenCollabPeer.peerId,
        resumePeerId: peer.peerId,
      }
    );

    this.props.addCollabScreenPeerFromArray(peer.peerId, peer.peerUsername);

    this.setState({ remotePeers: this.remotePeers });
  };

  screenStopAndOfflinePeerForCollab = async (peerId: string, type: string) => {
    // checking peer who shared scrren and consuming
    if (this.props.screenCollabPeer.peerId === peerId) {
      //  multipule screen share and screen stop of consuming peer so remove partiular peer and resume next array screen peer
      if (this.props.screenCollabPeerArray.length > 1) {
        let collabArrayAfterRemoving = this.props.screenCollabPeerArray.filter(
          (value) => value.peerId !== peerId
        );
        await this.props.removeCollabScreenPeer(peerId);
        await this.props.addCollabScreenPeerFromArray(
          collabArrayAfterRemoving[0].peerId,
          collabArrayAfterRemoving[0].peerUsername
        );

        if (type === "offline") {
          this.removePeerFromArrayIfExists(peerId);
        } else {
          const currentPageSessionIds = this.getPagePeers().map(
            (peer: RemotePeer) => peer.peerSessionId
          );

          await this.resumeConsumersForPeer(
            currentPageSessionIds,
            collabArrayAfterRemoving[0].peerId
          );
        }
      } else {
        // only one peer shared screen and then peer stopped screen then it will continue for 9 peer in one page
        await this.props.removeCollabScreenPeer(peerId);
        const pauseCurrentPageSessionIds = this.getPagePeers().map(
          (peer: RemotePeer) => peer.peerSessionId
        );

        // await this.pauseConsumersForPeer(pauseCurrentPageSessionIds);

        this.setState(
          {
            pageNumber: 1,
          },
          async () => {
            const currentPageSessionIds = this.getPagePeers().map(
              (peer: RemotePeer) => peer.peerSessionId
            );

            const exactPausePeerSessionIds = pauseCurrentPageSessionIds.filter(
              (pagePausePeersSessionId) =>
                !currentPageSessionIds.includes(pagePausePeersSessionId)
            );

            if (exactPausePeerSessionIds.length > 0) {
              await this.pauseConsumersForPeer(exactPausePeerSessionIds);
            }

            if (type === "offline") {
              this.removePeerFromArrayIfExists(peerId);
            } else {
              await this.resumeConsumersForPeer(currentPageSessionIds);
            }

            // const allPeerSessionIds = this.remotePeers.map(
            //   (peer: RemotePeer) => peer.peerSessionId
            // );

            // socketInstance.sendMessage("CHANGE_LAYER", {
            //   peerSessionIds: allPeerSessionIds,
            //   mediaType: "VIDEO",
            //   spatialLayer: 1,
            // });
          }
        );
      }
    } else {
      // if peer is in collab array list but not consume so it will remove form array
      this.props.removeCollabScreenPeerFromArray(peerId);
    }
  };

  getVideoOnScreen = (peer: RemotePeer) => {
    let largeVideo = undefined;
    let smallVideo = undefined;
    let secondScreen = undefined;

    if (
      !peer.peerVideoPaused &&
      peer.peerScreenTwoStream &&
      peer.peerScreenStream
    ) {
      largeVideo = peer.peerScreenStream;
      smallVideo = peer.peerVideoStream;
      secondScreen = peer.peerScreenTwoStream;
    } else if (!peer.peerVideoPaused && peer.peerScreenStream) {
      largeVideo = peer.peerScreenStream;
      smallVideo = peer.peerVideoStream;
      secondScreen = undefined;
    } else if (!peer.peerVideoPaused && peer.peerScreenTwoStream) {
      largeVideo = peer.peerScreenTwoStream;
      secondScreen = peer.peerVideoStream;
      smallVideo = undefined;
    } else if (peer.peerScreenStream && peer.peerScreenTwoStream) {
      largeVideo = peer.peerScreenStream;
      secondScreen = peer.peerScreenTwoStream;
      smallVideo = undefined;
    } else if (!peer.peerVideoPaused) {
      largeVideo = peer.peerVideoStream;
      secondScreen = undefined;
      smallVideo = undefined;
    } else if (peer.peerScreenTwoStream) {
      largeVideo = peer.peerScreenTwoStream;
      secondScreen = undefined;
      smallVideo = undefined;
    } else if (peer.peerScreenStream) {
      largeVideo = peer.peerScreenStream;
      secondScreen = undefined;
      smallVideo = undefined;
    }

    return { largeVideo, smallVideo, secondScreen };
  };

  swapScreen = (peerSessionId: string) => {
    const peer = this.getPeerFromRemotePeersArray(peerSessionId);
    if (peer) {
      if (
        peer.peerVideoStream &&
        peer.peerScreenStream &&
        peer.peerScreenTwoStream &&
        !peer.peerVideoPaused
      ) {
        if (
          peer.isVideoSwapped === "secondthirdvideo" ||
          peer.isVideoSwapped === ""
        ) {
          peer.isVideoSwapped = "thirdvideosecond";
        } else if (peer.isVideoSwapped === "thirdvideosecond") {
          peer.isVideoSwapped = "videosecondthird";
        } else {
          peer.isVideoSwapped = "secondthirdvideo";
        }
      } else if (peer.peerScreenStream && peer.peerScreenTwoStream) {
        if (peer.isVideoSwapped === "thirdsecond") {
          peer.isVideoSwapped = "secondthird";
        } else {
          peer.isVideoSwapped = "thirdsecond";
        }
      } else if (peer.peerVideoStream && peer.peerScreenStream) {
        if (
          peer.isVideoSwapped === "secondvideo" ||
          peer.isVideoSwapped === ""
        ) {
          peer.isVideoSwapped = "videosecond";
        } else {
          peer.isVideoSwapped = "secondvideo";
        }
      } else if (peer.peerVideoStream && peer.peerScreenTwoStream) {
        if (
          peer.isVideoSwapped === "thirdvideo" ||
          peer.isVideoSwapped === ""
        ) {
          peer.isVideoSwapped = "videothird";
        } else {
          peer.isVideoSwapped = "thirdvideo";
        }
      }
    }
    this.setState({ remotePeers: this.remotePeers });
  };

  multipleSupervisorsStatus = async () => {
    try {
      const statusData = await getMultipleSupervisorsStatus();
      if (statusData) {
        this.multipleSupervisors = statusData.adminsettings.status;
      }
    } catch (error) {
      console.log("Error fetching weblink status: ", error);
    }
  };

  render() {
    if (this.state.errorMessages && this.state.errorMessages.length) {
      return (
        <div className="alert-error-container">
          {/* <Header
            handleActiveVideoDeviceChange={this.replaceVideoStream}
            handleActiveAudioDeviceChange={this.replaceAudioStream}
          /> */}
          {this.state.errorMessages.map((errorMessage: string) => (
            <div className="alert-inner-container">
              {errorMessage === "Peer Already exists in room" ? (
                <Alert
                  className="alert-text"
                  variant="danger"
                  key={errorMessage}
                >
                  {errorMessage} :: {this.roomname}
                </Alert>
              ) : (
                <Alert
                  className="alert-text"
                  variant="danger"
                  key={errorMessage}
                >
                  {errorMessage}
                </Alert>
              )}
              {errorMessage === "Peer Already exists in room" && (
                <div>
                  <p>
                    Are you sure you did not join the room in any other device
                    or tabs?
                  </p>
                  <p>If No, click on remove peer button.</p>
                  <button onClick={() => this.removePeerInRedis()}>
                    Remove Peer
                  </button>
                </div>
              )}
              {errorMessage ===
                "You Already exists in Another Room, Can't Join Two Room at Same Time" && (
                  <div>
                    <p>
                      <b>If you want to logout from the other room, click here</b>
                    </p>
                    <button onClick={() => this.removePeerFromAnotherRoom()}>
                      Remove Peer
                    </button>
                  </div>
                )}
            </div>
          ))}
        </div>
      );
    }

    const amIStudent = this.props.currentUserRole === "Agent";
    const amISuperVisor = checkForSupManAdmin(
      this.props.currentUserRole,
      "equalTo"
    );

    let largeVideo = undefined;
    let smallVideo = undefined;
    let secondScreen = undefined;

    const instructor = this.state.remotePeers.find((remotePeer: RemotePeer) => {
      return remotePeer.peerSessionId === this.state.presenter;
    });
    if (amIStudent && instructor) {
      let allStream = this.getVideoOnScreen(instructor);
      largeVideo = allStream.largeVideo;
      smallVideo = allStream.smallVideo;
      secondScreen = allStream.secondScreen;
    }

    let firstInstructorVideo: any,
      firstInstructorScreen: any,
      secondInstructorScreen: any;

    if (this.state.localScreenStream) {
      firstInstructorScreen = this.state.localScreenStream;
    }

    if (this.state.localScreenTwoStream) {
      secondInstructorScreen = this.state.localScreenTwoStream;
    }

    if (this.state.localVideoStream) {
      if (!this.state.localVideoPaused) {
        firstInstructorVideo = this.state.localVideoStream;
      } else {
        firstInstructorVideo = undefined;
      }
    }

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <div className="vh100 vw100 full-container">
        {/* <iframe id="l" width="1" height="1" style={{visibility:"hidden"}}></iframe> */}
        <div
          className="chat-room"
          id="whole-room"
          onDragEnter={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {amIStudent ? (
            this.props.viewMode === roomViewModeConst.collabMode ||
              this.multipleSupervisors ? (
              <InstructorScreen
                segmentationConfig={this.state.segmentationConfig}
                tflite={this.state.selectedTFLite}
                sideBarOpen={this.state.sideBarOpen}
                firstInstructorScreen={firstInstructorScreen}
                secondInstructorScreen={secondInstructorScreen}
                firstInstructorVideo={firstInstructorVideo}
                pauseUnPauseVideo={this.pauseUnPauseVideo}
                localVideoPaused={this.state.localVideoPaused}
                pauseUnPauseAudio={this.pauseUnPauseAudio}
                addStreamOfAudioFile={this.addStreamOfAudioFile}
                addStreamOfVideoFile={this.addStreamOfVideoFile}
                localAudioPaused={this.state.localAudioPaused}
                getPagePeers={this.getPagePeers}
                remotePeers={this.state.remotePeers}
                getPageNumbers={this.getPageNumbers}
                socketId={this.roomSocket ? this.roomSocket.id : undefined}
                shareUnshareScreen={this.shareUnshareScreen}
                shareUnshareScreenTwo={this.shareUnshareScreenTwo}
                signalStrength={this.state.signalStrength}
                swapScreen={this.swapScreen}
                currentPageNumber={this.state.pageNumber}
                changePage={this.changePage}
                screenStopped={this.state.localScreenStopped}
                screenTwoStopped={this.state.localScreenStoppedTwo}
                expression={this.state.expression}
                current_id={this.state.current_id}
                localAudioStream={this.state.localAudioStream}
                localVideoStream={this.state.localVideoStream}
                localScreenStream={this.state.localScreenStream}
                shouldShowControls={amISuperVisor}
                changeViewMode={this.changeViewMode}
                amISuperVisor={amISuperVisor}
                roomname={this.roomname}
                presenter={this.state.presenter}
                instructor={instructor}
                getVideoOnScreen={this.getVideoOnScreen}
                screenResumeForCollabPeer={this.screenResumeForCollabPeer}
                isScreenAudioShared={this.state.isScreenAudioShared}
                presenterForAgent={this.state.presenterForAgent}
                handleHandraise={this.handleHandRaise}
              />
            ) : (
              <>
                <StudentScreen
                  segmentationConfig={this.state.segmentationConfig}
                  tflite={this.state.selectedTFLite}
                  sideBarOpen={this.state.sideBarOpen}
                  largeVideo={largeVideo}
                  smallVideo={smallVideo}
                  secondScreen={secondScreen}
                  instructor={instructor}
                  presenter={this.state.presenter}
                  localVideoPaused={this.state.localVideoPaused}
                  localVideoStream={this.state.localVideoStream}
                  localAudioPaused={this.state.localAudioPaused}
                  pauseUnPauseVideo={this.pauseUnPauseVideo}
                  socketId={this.roomSocket ? this.roomSocket.id : undefined}
                  swapScreen={this.swapScreen}
                  pauseUnPauseAudio={this.pauseUnPauseAudio}
                  addStreamOfAudioFile={this.addStreamOfAudioFile}
                  addStreamOfVideoFile={this.addStreamOfVideoFile}
                  shareUnshareScreen={this.shareUnshareScreen}
                  shareUnshareScreenTwo={this.shareUnshareScreenTwo}
                  localScreenStream={this.state.localScreenStream}
                  localScreenTwoStream={this.state.localScreenTwoStream}
                  screenStopped={this.state.localScreenStopped}
                  screenTwoStopped={this.state.localScreenStoppedTwo}
                  remotePeers={this.state.remotePeers}
                  localAudioStream={this.state.localAudioStream}
                  signalStrength={this.state.signalStrength}
                  amISuperVisor={amISuperVisor}
                  roomname={this.roomname}
                  audioAllowedInRoom={this.audioAllowedInRoom}
                  presenterForAgent={this.state.presenterForAgent}
                />

                {/* <video
                  id="videosss"
                  src="/big_buck_bunny_trailer_480p.webm"
                  controls
                  onPlay={() => this.startScreen()}
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                /> */}
              </>
            )
          ) : (
            <InstructorScreen
              segmentationConfig={this.state.segmentationConfig}
              tflite={this.state.selectedTFLite}
              sideBarOpen={this.state.sideBarOpen}
              privateCallPeerOne={this.state.privateCallPeerOne}
              firstInstructorScreen={firstInstructorScreen}
              secondInstructorScreen={secondInstructorScreen}
              firstInstructorVideo={firstInstructorVideo}
              pauseUnPauseVideo={this.pauseUnPauseVideo}
              localVideoPaused={this.state.localVideoPaused}
              pauseUnPauseAudio={this.pauseUnPauseAudio}
              addStreamOfAudioFile={this.addStreamOfAudioFile}
              addStreamOfVideoFile={this.addStreamOfVideoFile}
              localAudioPaused={this.state.localAudioPaused}
              remotePeers={this.state.remotePeers}
              getPagePeers={this.getPagePeers}
              getPageNumbers={this.getPageNumbers}
              socketId={this.roomSocket ? this.roomSocket.id : undefined}
              shareUnshareScreen={this.shareUnshareScreen}
              shareUnshareScreenTwo={this.shareUnshareScreenTwo}
              swapScreen={this.swapScreen}
              currentPageNumber={this.state.pageNumber}
              changePage={this.changePage}
              screenStopped={this.state.localScreenStopped}
              screenTwoStopped={this.state.localScreenStoppedTwo}
              expression={this.state.expression}
              current_id={this.state.current_id}
              localAudioStream={this.state.localAudioStream}
              localVideoStream={this.state.localVideoStream}
              shouldShowControls={amISuperVisor}
              changeViewMode={this.changeViewMode}
              makePrivateCall={this.makePrivateCall}
              signalStrength={this.state.signalStrength}
              amISuperVisor={amISuperVisor}
              roomname={this.roomname}
              isPrivateCallOn={this.state.isPrivateCallOn}
              presenter={this.state.presenter}
              instructor={instructor}
              getVideoOnScreen={this.getVideoOnScreen}
              localScreenStream={this.state.localScreenStream}
              isCallModal={this.state.callModalOption.isCallModal}
              openentObserveeAudioId={this.state.openentObserveeAudioId}
              screenResumeForCollabPeer={this.screenResumeForCollabPeer}
              isScreenAudioShared={this.state.isScreenAudioShared}
              presenterForAgent={this.state.presenterForAgent}
              handleHandraise={this.handleHandRaise}
            />
          )}
          <Button
            className={
              this.state.sideBarOpen
                ? "sidebar-btn-open sidebar-btn"
                : "sidebar-btn"
            }
            onClick={() =>
              this.setState({
                sideBarOpen: !this.state.sideBarOpen,
                sideBarCount: 0,
              })
            }
          >
            {!this.state.sideBarOpen ? (
              <li title={"Open Sidebar"}>
                <FontAwesomeIcon color="#26a3db" icon={faAngleDoubleLeft} />
                {this.state.sideBarCount > 0 && (
                  <div className="sidebar-btn-indication">
                    {this.state.sideBarCount}
                  </div>
                )}
              </li>
            ) : (
              <li title={"Close Sidebar"}>
                <FontAwesomeIcon color="#26a3db" icon={faAngleDoubleRight} />
              </li>
            )}
          </Button>

          <Sidebar
            roomname={this.roomname}
            performRightShiftByOne={this.performRightShiftByOne}
            makePrivateCall={this.makePrivateCall}
            expression={this.state.expression}
            current_id={this.state.current_id}
            peers={this.state.remotePeers}
            handRaisedPeer={this.state.handRaisedPeer}
            isPrivateCallOn={this.state.isPrivateCallOn}
            openentCallerPerson={this.state.openentCallerPerson}
            isCallModal={this.state.callModalOption.isCallModal}
            refreshChat={this.state.sideBarOpen}
            localAudioPaused={this.state.localAudioPaused}
            objectDetect={this.state.objectDetect}
            openentObserveeAudioId={this.state.openentObserveeAudioId}
            audioAllowedInRoom={this.audioAllowedInRoom}
            isSideBarOpen={this.state.sideBarOpen}
            isScreenAudioShared={this.state.isScreenAudioShared}
            presenter={this.state.presenter}
            allowPrivateCallinSilentRoom={this.allowPrivateCallinSilentRoom}
            handleHandraise={this.handleHandRaise}
          />

          {this.state.reminderState ? (
            <ReminderModal
              show={this.state.reminderState}
              handleShow={this.closeReminderModal}
            />
          ) : null}

          {this.state.callModalOption.isCallModal ? (
            <CallModal
              callModalOpenAndClose={this.callModalOpenAndClose}
              callAccepted={this.callAccepted}
              callRejected={this.callRejected}
              isSideBarOpen={this.state.sideBarOpen}
              isPrivateCallOn={this.state.isPrivateCallOn}
              openentCallerPerson={this.state.openentCallerPerson}
              makePrivateCall={this.makePrivateCall}
              callModalOption={this.state.callModalOption}
            />
          ) : null}
        </div>
      </div>
    );
  }

  /* Consume High or Low Resolution based on @layer
   * @layer can be 0 or 1
   * Accepts Array of peer ids
   */
  // changeLayerForMaxParticipantsArray = (peerIds: any, layer: number) => {
  //   let peerSessionIds: any = [];
  //   for (let i = 0; i < peerIds.length; i++) {
  //     peerSessionIds.push(peerIds[i].peerSessionId);
  //   }
  //   socketInstance.sendMessage("CHANGE_LAYER", {
  //     peerSessionIds: peerSessionIds,
  //     mediaType: "VIDEO",
  //     spatialLayer: layer,
  //   });
  // };

  processAudioOfAgent = () => {
    //  setTimeout(() => {
    if (this.state.localAudioStream && this.systemAudioFromElectron) {
      const micAudioTrack = this.state.localAudioStream.getAudioTracks()[0];
      const systemAudioTrack = this.systemAudioFromElectron.track;

      const combinedAudioTrack = this.combineSystemAndMicAudioTracks(
        systemAudioTrack,
        micAudioTrack
      );

      this.replaceAudioTrack(combinedAudioTrack);
    }
    return;
    //  }, 7000);
  };

  detectConnectedDevice = () => {
    if (
      this.props.currentUserRole === "Agent" &&
      convertIntoBoolean(this.screenControl) &&
      this.componentRef
    ) {
      let headphonesConnected = false;
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        //@ts-ignore
        headphonesConnected = devices.filter((device) =>
          /audio\w+/.test(device.kind)
        );
        //  .find(device => device.label.toLowerCase().includes('head'));

        if (headphonesConnected) {
          this.shareUnshareScreen(this.roomSocket.id);
        }
      });
    }
  };

}

const getFullName = (firstName?: string, lastName?: string) => {
  return firstName && lastName ? `${firstName} ${lastName}` : "";
};

const mapStateToProps = (state: RootState) => {
  return {
    currentUserRole: state.userDetails.role,
    enableDesktopApp: state.userDetails.enableDesktopApp,
    currentUserName: getFullName(
      state.userDetails.firstName,
      state.userDetails.lastName
    ),
    firstName: state.userDetails.firstName,
    agentHandRaiseandTechsupportPersonalization:state.personalizationReducer.settings?.settings?.agentHandRaiseandTechsupport,
    userName: state.userDetails.userName,
    socketId: state.userDetails.socketId,
    activeVideoDevice: state.devicesData.activeVideoDevice,
    activeAudioDevice: state.devicesData.activeAudioDevice,
    videoOption: state.videoOption.videoOption,
    notifications: state.allNotification.notifications,
    breakParticipant: state.tempRoomUserReducer.breakParticipant,
    controlId: state.screenControlReducer.openentPeerId,
    isAudioFile: state.audioVideoFileReducer.isAudioFile,
    isVideoFile: state.audioVideoFileReducer.isVideoFile,
    troubleshoot: state.troubleshootReducer.troubleshoot,
    environment: state.environmentsReducers.environments,
    selectedChatUser: state.chatData.selectedChatUser,
    screenCollabPeer: state.collabReducer.screenCollabPeer,
    screenCollabPeerArray: state.collabReducer.screenCollabPeerArray,
    viewMode: state.roomsData.viewMode,
    backgroundActivate: state.userDetails.cameraBackground,
    roomsData: state.roomsData.rooms,
    isSidebarOpen: state.layoutReducer.layoutSettings?.data?.isSideBarOpen,
    screenScale: state.roomsData.screenScale,
    videoScale: state.roomsData.videoScale,
    faceVerificationEnabled: state.userDetails.faceVerification,
    isUserVerified: state.userDetails.isUserVerified,
    highResUser: state.audioVideoFileReducer.highResUser,
    audioAllowedInRoom: state.roomsData.audioAllowedInRoom,
    allowPrivateCallinSilentRoom: state.roomsData.allowPrivateCallinSilentRoom
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getUserDetails: (roomname: any) => dispatch(getUserDetailsAction(roomname)),
  _setFullMessageList: (messageList: any[]) =>
    dispatch(setFullMessageList(messageList)),
  _setMessageList: (message: String) => dispatch(setMessageList(message)),
  setPeerVolume: (peerId: string, volume: number) =>
    dispatch(setPeerVolume(peerId, volume)),
  setMySocketId: (socketId: string) => dispatch(setMySocketId(socketId)),
  removePeer: (peerId: string) => dispatch(removePeer(peerId)),
  setorRemoveTempRoomUser: (
    peerId: string,
    peerUsername: string,
    breakParticipant: any
  ) =>
    dispatch(setorRemoveTempRoomUser(peerId, peerUsername, breakParticipant)),
  addNotifications: (
    numfaces: Number,
    startTime: Number,
    endTime: Number,
    msg: string,
    username: string,
    notiType: string,
    fullname: string,
  ) =>
    dispatch(
      addNotifications(numfaces, startTime, endTime, msg, username, notiType,fullname)
    ),
  addNotifyEndTime: (endTime: Number, username: string, notiType: string) =>
    dispatch(addNotifyEndTime(endTime, username, notiType)),

  addNotificationsUsingSingle: (
    startTime: Number,
    msg: string,
    username: string,
    notiType: string,
    fullname:string,
  ) =>
    dispatch(addNotificationsUsingSingle(startTime, msg, username, notiType,fullname)),
  clearControlId: () => dispatch(clearControlId()),
  setAudioFile: (value: boolean) => dispatch(setAudioFile(value)),
  setVideoFile: (value: boolean) => dispatch(setVideoFile(value)),
  setVideoOption: (value: string) => dispatch(setVideoOption(value)),
  setSelectedChatUser: (
    peerSessionId: string | null,
    peerUserName: string | null,
    peerName: string | null,
    chatType: string
  ) =>
    dispatch(
      setSelectedChatUser(peerSessionId, peerUserName, peerName, chatType)
    ),

  addCollabScreenPeer: (peerId: string, peerUsername: string) =>
    dispatch(addCollabScreenPeer(peerId, peerUsername)),
  addCollabScreenPeerFromArray: (peerId: string, peerUsername: string) =>
    dispatch(addCollabScreenPeerFromArray(peerId, peerUsername)),
  removeCollabScreenPeer: (peerId: string) =>
    dispatch(removeCollabScreenPeer(peerId)),
  addAllCollabScreenPeerWhileConnected: (peerArray: Array<screenPeerType>) =>
    dispatch(addAllCollabScreenPeerWhileConnected(peerArray)),
  removeCollabScreenPeerFromArray: (peerId: string) =>
    dispatch(removeCollabScreenPeerFromArray(peerId)),
  setViewMode: (roomtype: string) => dispatch(setViewMode(roomtype)),
  setBackgroundImage: (url: string) => dispatch(setBackgroundImage(url)),
  clearChatData: () => dispatch(clearChatData()),
  clearTempRoomUser: () => dispatch(clearTempRoomUser()),
  setRoomOnRestartIce: (roomName: string) =>
    dispatch(setRoomOnRestartIce(roomName)),
  getLayoutSettingActions: (username: string, type: string) =>
    dispatch(getLayoutSettingActions(username, type)),
  setActivityParentId: (id: string) => dispatch(setActivityParentId(id)),
  setActivityModalOpen: (value: boolean) =>
    dispatch(setActivityModalOpen(value)),
  setReconnectionStatus: (value: boolean) =>
    dispatch(setReconnectionStatus(value)),
  clearCollabScreenData: () => dispatch(clearCollabScreenData()),
  clearControlAudioVideoFile: () => dispatch(clearControlAudioVideoFile()),
  setCurrentViewMode: (viewMode: string, roomName: string) =>
    dispatch(setCurrentViewMode(viewMode, roomName)),
  clearActivityReducer: () => dispatch(clearActivityReducer()),
  backgroundModalClose: () => dispatch(backgroundModalClose()),
  removeScaleResolution: () => dispatch(remvoeScaleResolution()),
  verifyFace: (verification: boolean) => dispatch(verifyFace(verification)),
  setRecurrentCheck: (verification: boolean) =>
    dispatch(setRecurrentCheck(verification)),
  wrongPersonCheck: (check: boolean) => dispatch(wrongPersonCheck(check)),
  addHighResUser: (username: string, streamType: Array<string>) =>
    dispatch(addHighResUser(username, streamType)),
  removeHighResUserStreamType: (username: string, streamType: Array<string>) =>
    dispatch(removeHighResUserStreamType(username, streamType)),
  setReconnectioStatusForChat: (status: boolean) =>
    dispatch(setReconnectioStatusForChat(status)),
});

// @ts-ignore
export default WithRouter(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(RoomPage)
);
