import { SET_REM_TEMP_ROOM_USER,CLEAR_REDUX_DATA, CLEAR_REM_TEMP_ROOM_USER } from "../actions/actionTypes";


interface breakPart {
  peerId: string;
  username: string;
}

const initialState = {
  breakParticipant:[] as Array<breakPart>
};
interface IAction {
  type: string;
  payload: any;
}

const tempRoomUserReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_REM_TEMP_ROOM_USER: {
      return {
        
        breakParticipant: action.payload,
      };
    }
    case CLEAR_REDUX_DATA:
    case CLEAR_REM_TEMP_ROOM_USER:{
      return initialState
    }

    default: {
      return state;
    }
  }
};

export default tempRoomUserReducer;
