const convertIntoBoolean = (value: String | Boolean) => {
  if (value === "true") {
    return true;
  } else if (value === true) {
    return true;
  } else {
    return false;
  }
};

export { convertIntoBoolean };
