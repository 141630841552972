import { Dispatch } from "react";
import { toast } from "react-toastify";
import { getAllBackgroundImages } from "../services/backgroundImage";
import {
  SET_STATIC_IMAGE_LINKS,
  SET_IMAGE_LINK,
  SET_BACKGROUND_MODAL_CLOSE,
  SET_BACKGROUND_MODAL_OPEN,
} from "./actionTypes";
import { setLoading } from "./loaderActions";

export const setStaticImageLinks = (company: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const backgroundImageData = await getAllBackgroundImages(company);
      if (backgroundImageData.success) {
        dispatch({
          type: SET_STATIC_IMAGE_LINKS,
          payload: backgroundImageData.backgroundImage,
        });
      } else if (!backgroundImageData.success) {
        toast(backgroundImageData.message);
      }

      return backgroundImageData;
    } catch (error: any) {
      toast(
        error?.response.data.message ||
          "Could not fetch room details. Please try again"
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const setBackgroundImage = (imageLink: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_IMAGE_LINK,
      payload: imageLink,
    });
  };
};

export const backgroundModalOpen = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_BACKGROUND_MODAL_OPEN,
    });
  };
};

export const backgroundModalClose = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_BACKGROUND_MODAL_CLOSE,
    });
  };
};
