import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import "./styles.css";
import {
  faBars,
  faClose,
  faCommentDots,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Point from "../../utils/Point";
import ActivityMain from "./ActivityMain";

import {
  setActivityModalMinimize,
  setActivityModalOpen,
  setActivityUnreadPostCount,
} from "../../actions/activityChannelAction";

interface PopopSize {
  height: number;
  width: number;
}

const ActivityChannel = (props: any) => {
  const [circlePosition, setCirclePosition] = React.useState({
    top: 100,
    left: 100,
  });

  const [activityModalPosition, setactivityModalPosition] = React.useState({
    top: 100,
    left: 100,
  });

  const initalModalConf = {
    height: window.innerHeight - 200,
    width: window.innerWidth - 200,
  };
  const [actvityConf, setactvityConf] =
    React.useState<PopopSize>(initalModalConf);
  const [resizeBoolean, setResizeBoolean] = useState(false);
  const unreadPostCount = useSelector(
    (state: RootState) => state.activityChannelReducer.unreadPostsCount
  );
  const dispatch = useDispatch();
  const isMinimize = useSelector(
    (state: RootState) => state.activityChannelReducer.isActivityModalMinimize
  );
  const parentId = useSelector(
    (state: RootState) => state.activityChannelReducer.parentId
  );
  const isModalActivityOpen = useSelector(
    (state: RootState) => state.activityChannelReducer.isActivityModalOpen
  );

  function circleDrag(e: any) {
    setCirclePosition({
      top: Point.fromEvent(e as any, parentId).y,
      left: Point.fromEvent(e as any, parentId).x,
    });
  }

  function activityDrag(e: any) {
    setactivityModalPosition({
      top: Point.fromEvent(e as any, parentId).y,
      left: Point.fromEvent(e as any, parentId).x,
    });
  }

  function circleEvent() {
    dispatch(setActivityModalMinimize(false));
    dispatch(setActivityUnreadPostCount(0))
  }

  function closeModal() {
    dispatch(setActivityModalMinimize(false));
    dispatch(setActivityModalOpen(false));
  }

  function minimizeModal() {
    dispatch(setActivityModalMinimize(true));
  }

  const resizeMouseDown = () => {
    setResizeBoolean(true);
  };

  const resizeMouseMove = (e: any) => {
    if (resizeBoolean) {
      if (
        e.clientX > window.innerWidth - 10 ||
        e.clientY > window.innerHeight - 10
      ) {
        setResizeBoolean(false);
      } else {
        setactvityConf((currentSize) => ({
          width: currentSize.width + e.movementX,
          height: currentSize.height + e.movementY,
        }));
      }
    }
  };

  const resizeMouseUp = () => {
    setResizeBoolean(false);
  };

  const mainMouseMove = (e: MouseEvent) => {
    if (resizeBoolean) {
      console.log("mouse move");
      resizeMouseMove(e);
    }
  };

  const mainMouseUp = (e: MouseEvent) => {
    console.log("mouse up");
    e.preventDefault();
    e.stopPropagation();
    resizeMouseUp();
  };

  useEffect(() => {
    let mainroomdiv = document.getElementById(parentId);

    if (resizeBoolean) {
      mainroomdiv?.addEventListener("mousemove", mainMouseMove);
      mainroomdiv?.addEventListener("mouseup", mainMouseUp);
    }
    return () => {
      mainroomdiv?.removeEventListener("mousemove", mainMouseMove);
      mainroomdiv?.removeEventListener("mouseup", mainMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizeBoolean]);

  return (
    <div className="activity_Main">
      {isMinimize ? (
        <span
          className="dot_activity"
          style={{
            top: `${circlePosition.top}px`,
            left: `${circlePosition.left}px`,
          }}
          draggable="true"
          onDragEnd={(e) => circleDrag(e)}
          onDrag={(e) => {
            setCirclePosition({
              top: Point.fromEvent(e as any, parentId).y,
              left: Point.fromEvent(e as any, parentId).x,
            });
          }}
          onClick={() => {
            circleEvent();
          }}
        >
          {unreadPostCount > 0 && <span
            className="unread-post-count"
          >
            <p>{unreadPostCount}</p>
          </span>}
          <div className="comment-icon-topics">
            <FontAwesomeIcon icon={faCommentDots} />
          </div>
          <span
            className="dot_inside_close"
            onClick={() => {
              closeModal();
            }}
          >
            <p><FontAwesomeIcon icon={faClose} /></p>
          </span>
        </span>
      ) : null}

      {isModalActivityOpen ? (
        <div
          className="activity_cont chat_modal_container"
          id="activity-modal"
          style={{
            top: `${activityModalPosition.top}px`,
            left: `${activityModalPosition.left}px`,
            width: `${actvityConf.width}px`,
            height: `${actvityConf.height}px`,
            minHeight: "500px",
            minWidth: "1100px",
            display: isMinimize ? "none" : "block",
          }}
          onMouseMove={(e) => {
            e.stopPropagation();
            resizeMouseMove(e);
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => {
            e.stopPropagation();
            resizeMouseUp();
          }}
        >
          <div className="popup_header" style={{ height: "50px" }}>
            {/* <p className="chat_btn" > */}
            <div className="activity-headers">
              <p
                className="chat-picker"
                draggable="true"
                onDragEnd={(e) => activityDrag(e)}
                onDrag={(e) => {
                  setactivityModalPosition({
                    top: Point.fromEvent(e as any, parentId).y,
                    left: Point.fromEvent(e as any, parentId).x,
                  });
                }}
              >
                <FontAwesomeIcon icon={faBars} />
              </p>

              <p className="activity-heading">Activity Channel</p>
              <div className="min_actions">
                <p
                  title="Minimize Activity Channel"
                  onClick={() => {
                    minimizeModal();
                  }}
                >
                  -
                </p>

                <p
                  title="Close Activity Channel"
                  className="cls-btn"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  X
                </p>
              </div>
            </div>
          </div>
          <div style={{ height: "91%", backgroundPositionY: "bottom" }}>
            <ActivityMain />
          </div>

          <FontAwesomeIcon
            icon={faSortDown}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "0px",
              fontSize: "2em",
              color: "gray",
              transform: "rotate(-45deg)",
              cursor: "se-resize",
              zIndex: 2,
            }}
            onMouseDown={(e) => {
              e.stopPropagation();

              resizeMouseDown();
            }}
            onMouseMove={(e) => {
              e.stopPropagation();
              resizeMouseMove(e);
            }}
            onMouseUp={(e) => {
              e.stopPropagation();
              resizeMouseUp();
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
            }}
            onDrag={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragEnd={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ActivityChannel;