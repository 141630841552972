import axios from "axios";

const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});


export const registerRequest = axios.create({
  baseURL: process.env.REACT_APP_REGISTER_URL,
  withCredentials: false,
});

export default request;