import React, { useEffect, useState, useRef } from "react";
import bgGrid from "../../../assets/images/bg_grid.png";
import Point from "../../../utils/Point";
import "./style.css";
import html2canvas from "html2canvas";
import ContentEditable from "react-contenteditable";
import SocketService from "../../../services/socket";
import { v4 as uuidv4 } from "uuid";
import {
  faExpandArrowsAlt,
  faPlusSquare,
  faSortDown,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { socketConnectiontype } from "../../../utils/constant";

interface toolInterface {
  line: boolean;
  circle: boolean;
  rectangle: boolean;
  pen: boolean;
  eraser: boolean;
  stickyNote: boolean;
  textBox: boolean;
}

interface Props {
  strokeColor: string;
  strokeWidth: string;
  selectTool: toolInterface;
  clearCanvas: boolean;
  setClearCanvas: (data: boolean) => any;
  stickyNoteColor: String;
  dropIndicator: boolean;
  setDropIndicator: (data: boolean) => any;
  saveImage: boolean;
  setSaveImage: (data: boolean) => any;
  readOnly: boolean;
  backgroundImage: Array<{ id: string; img: string }>;
  setBackgroundImage: (data: Array<{ id: string; img: string }>) => any;
  setSelectTool: (data: any) => void;
  uploadFiles: any;
  b64toBlob: any;
  readOnlyForAgent: () => void;
  readWriteOnlyForAgent: () => void;
  setReadForInstructor: (data: boolean) => any;
}

const socketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

const Board = ({
  strokeColor,
  strokeWidth,
  selectTool,
  setSelectTool,
  clearCanvas,
  setClearCanvas,
  stickyNoteColor,
  dropIndicator,
  setDropIndicator,
  saveImage,
  setSaveImage,
  readOnly,
  backgroundImage,
  setBackgroundImage,
  uploadFiles,
  b64toBlob,
  readOnlyForAgent,
  readWriteOnlyForAgent,
  setReadForInstructor,
}: Props) => {
  const [circleBoolean, setCircleBoolean] = useState(false);
  const [lineBoolean, setLineBoolean] = useState(false);
  const [rectBoolean, setRectBoolean] = useState(false);
  const [drawClick, setDrawClick] = useState(false);
  const [resizeBoolean, setResizeBoolean] = useState(false);
  const [rotateBoolean, setRotateBoolean] = useState(false);
  const [eraserClick, setEraserClick] = useState(false);
  const [resizeValue, setResizeValue] = useState("");
  const [startCoords, setStartCoords] = useState({} as Point);
  const [prevPos, setPrevPos] = useState({} as Point);
  const [ctx, setCtx] = useState({} as CanvasRenderingContext2D);
  const [svgLine, setSvgLine] = useState({} as SVGLineElement);
  const [svgCircle, setSvgCircle] = useState({} as SVGCircleElement);
  const [svgRect, setSvgRect] = useState({} as SVGRectElement);
  const [penSmoothLastCoords, setPenSmoothLastCoords] = useState(
    [] as Array<number>
  );

  const [sendPenData, setSendPenData] = useState(
    [] as Array<{
      coords: Array<number>;
      strokeWidth: string;
      strokeColor: string;
    }>
  );
  const [sendEraseData, setSendEraserData] = useState(
    [] as Array<{
      fromX: number;
      fromY: number;
      toX: number;
      toY: number;
      strokeWidth: string;
    }>
  );

  const [oldGCO, setOldGCO] = useState("");
  const [stickyNotesBox, setStickyNotesBox] = useState([] as Array<any>);
  const [textBosex, setTextBosex] = useState([] as Array<any>);
  const [backImgTopLeft, setBackImgTopLeft] = useState({ top: 200, left: 200 });
  const [selectStickyId, setSelectStickyId] = useState("");
  const [selectTextBoxId, SetSelectTextBoxId] = useState("");
  const [size, setSize] = useState({ x: 400, y: 300 });
  const [rotateDegree, setRotateDegree] = useState(0);

  let canvasRef = useRef({} as HTMLCanvasElement);
  let mouseOverlay = useRef({} as HTMLDivElement);
  let cursorContainer = useRef({} as HTMLDivElement);
  let textContainer = useRef({} as HTMLDivElement);
  let svgContainer = useRef({} as SVGSVGElement);
  let imgContiainer = useRef({} as HTMLDivElement);
  let messageSubscriber = useRef() as any;
  let timeout = useRef() as any;

  useEffect(() => {
    if (clearCanvas) {
      clearCanvasFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCanvas]);

  const clearCanvasFunc = () => {
    let ctx = canvasRef.current.getContext("2d") as CanvasRenderingContext2D;
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setStickyNotesBox([]);
    setTextBosex([]);

    setBackgroundImage([]);
    while (imgContiainer.current.childElementCount > 0) {
      imgContiainer.current.removeChild(imgContiainer.current.childNodes[0]);
    }
    setSendPenData([]);
    setPenSmoothLastCoords([]);

    setClearCanvas(false);
  };

  useEffect(() => {
    setSvgCircle(
      document.createElementNS(
        "http://www.w3.org/2000/svg",
        "circle"
      ) as SVGCircleElement
    );

    setSvgLine(
      document.createElementNS(
        "http://www.w3.org/2000/svg",
        "line"
      ) as SVGLineElement
    );
    setSvgRect(
      document.createElementNS(
        "http://www.w3.org/2000/svg",
        "rect"
      ) as SVGRectElement
    );
    setCtx(canvasRef.current.getContext("2d") as CanvasRenderingContext2D);
    window.addEventListener("resize", function () {
      // Handel resize
      if (canvasRef.current) {
        canvasRef.current.width = window.innerWidth;
        canvasRef.current.height = window.innerHeight; // Set new canvas height
      }

      clearTimeout(timeout.current);

      timeout.current = setTimeout(() => {
        socketInstance.sendMessage("GET_WHITEBOARD_CONTENT", {});
      }, 200);
    });

    canvasRef.current.height = window.innerHeight;
    canvasRef.current.width = window.innerWidth;

    messageSubscriber.current = socketInstance
      .getMessages()
      .subscribe((message: string) => {
        processMessage(JSON.parse(message));
      });

    return () => {
      messageSubscriber.current.unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const percentageToPixelForWidth = (num: string) => {
    return ((parseFloat(num) + 0.1) * window.innerWidth) / 100;
  };

  const percentageToPixelForHeight = (num: string) => {
    return ((parseFloat(num) + 0.1) * window.innerHeight) / 100;
  };

  const PixelToPercentageForWidth = (num: number) => {
    return ((num * 100) / window.innerWidth).toFixed(1);
  };

  const PixelToPercentageForHeight = (num: number) => {
    return ((num * 100) / window.innerHeight).toFixed(1);
  };

  const processMessage = async (message: any) => {
    if (message.data === undefined) return;
    const data = message.data;
    let contentType;
    let blob;
    let blobUrl;
    switch (message.type) {
      case "WHITEBOARD_CONTENT":
        clearCanvasFunc();
        message.data.map((val: any) => {
          switch (val.tool) {
            case "drawLine":
              let fromX = percentageToPixelForWidth(val.fromX);
              let fromY = percentageToPixelForHeight(val.fromY);
              let toX = percentageToPixelForWidth(val.toX);
              let toY = percentageToPixelForHeight(val.toY);

              drawPenLine(
                fromX,
                fromY,
                toX,
                toY,
                val.strokeWidth,
                val.strokeColor
              );
              break;
            case "drawPen":
              val.data.map(
                (val: {
                  coords: Array<number>;
                  strokeWidth: string;
                  strokeColor: string;
                }) => {
                  const { coords, strokeWidth, strokeColor } = val;
                  let sendxm1 = percentageToPixelForWidth(coords[0] + "");
                  let sendym1 = percentageToPixelForHeight(coords[1] + "");
                  let sendx0 = percentageToPixelForWidth(coords[2] + "");
                  let sendy0 = percentageToPixelForHeight(coords[3] + "");
                  let sendx1 = percentageToPixelForWidth(coords[4] + "");
                  let sendy1 = percentageToPixelForHeight(coords[5] + "");
                  let sendx2 = percentageToPixelForWidth(coords[6] + "");
                  let sendy2 = percentageToPixelForHeight(coords[7] + "");

                  drawPenSmoothLine(
                    [
                      sendxm1,
                      sendym1,
                      sendx0,
                      sendy0,
                      sendx1,
                      sendy1,
                      sendx2,
                      sendy2,
                    ],
                    strokeWidth,
                    strokeColor
                  );
                  return [];
                }
              );

              setSendPenData([]);

              break;
            case "drawCircle":
              {
                let fromX = percentageToPixelForWidth(val.fromX);
                let fromY = percentageToPixelForHeight(val.fromY);
                let radius = percentageToPixelForWidth(val.radius);

                drawCircle(
                  fromX,
                  fromY,
                  radius,
                  val.strokeWidth,
                  val.strokeColor
                );
              }

              break;
            case "drawRect":
              {
                let fromX = percentageToPixelForWidth(val.fromX);
                let fromY = percentageToPixelForHeight(val.fromY);
                let toX = percentageToPixelForWidth(val.toX);
                let toY = percentageToPixelForHeight(val.toY);

                drawRec(
                  fromX,
                  fromY,
                  toX,
                  toY,
                  val.strokeWidth,
                  val.strokeColor
                );
              }
              break;
            case "drawEraser":
              val.data.map(
                (val: {
                  fromX: number;
                  fromY: number;
                  toX: number;
                  toY: number;
                  strokeWidth: string;
                }) => {
                  let fromX = percentageToPixelForWidth(val.fromX + "");
                  let fromY = percentageToPixelForHeight(val.fromY + "");
                  let toX = percentageToPixelForWidth(val.toX + "");
                  let toY = percentageToPixelForHeight(val.toY + "");

                  drawEraserLine(fromX, fromY, toX, toY, val.strokeWidth);
                  return [];
                }
              );

              setSendEraserData([]);

              break;
            case "placeText":
              setTextBosex((value) => {
                return [
                  ...value,
                  {
                    ...val,
                    top: percentageToPixelForHeight(val.top),
                    left: percentageToPixelForWidth(val.left),
                  },
                ];
              });

              break;
            case "textInput":
              setTextBosex((valuePoint) =>
                valuePoint.map((point) => {
                  if (point.id === val.id) {
                    return {
                      ...point,
                      text: val.text,
                      fontSize: val.fontSize,
                      fontColor: val.fontColor,
                      background: val.background,
                    };
                  } else {
                    return { ...point };
                  }
                })
              );
              break;
            case "closeText":
              setTextBosex((valuePoint) =>
                valuePoint.filter((point) => point.id !== val.id)
              );
              break;
            case "dragTextInput":
              setTextBosex((valuePoint) =>
                valuePoint.map((point) => {
                  if (point.id === val.id) {
                    return {
                      ...point,
                      top: percentageToPixelForHeight(val.top + ""),
                      left: percentageToPixelForWidth(val.left + ""),
                    };
                  } else {
                    return { ...point };
                  }
                })
              );
              break;
            case "placeStickyNote":
              setStickyNotesBox((value) => {
                return [
                  ...value,
                  {
                    ...val,
                    top: percentageToPixelForHeight(val.top),
                    left: percentageToPixelForWidth(val.left),
                  },
                ];
              });

              break;

            case "stickInput":
              setStickyNotesBox((valuePoint) =>
                valuePoint.map((point) => {
                  if (point.id === val.id) {
                    return {
                      ...point,
                      text: val.text,
                      fontSize: val.fontSize,
                      fontColor: val.fontColor,
                      background: val.background,
                    };
                  } else {
                    return { ...point };
                  }
                })
              );

              break;

            case "closeSticky":
              setStickyNotesBox((valuePoint) =>
                valuePoint.filter((point) => point.id !== val.id)
              );

              break;

            case "dragStickyNote":
              setStickyNotesBox((valuePoint) =>
                valuePoint.map((point) => {
                  if (point.id === val.id) {
                    return {
                      ...point,
                      top: percentageToPixelForHeight(val.top + ""),
                      left: percentageToPixelForWidth(val.left + ""),
                    };
                  } else {
                    return { ...point };
                  }
                })
              );

              break;
            case "resizeStickyNote":
              setStickyNotesBox((valuePoint) =>
                valuePoint.map((point) => {
                  if (point.id === val.id) {
                    return {
                      ...point,
                      y: percentageToPixelForHeight(val.y + ""),
                      x: percentageToPixelForWidth(val.x + ""),
                    };
                  } else {
                    return { ...point };
                  }
                })
              );
              break;

            case "drawImgToBackground":
              contentType = "image/" + val.fileExtension;

              blob = b64toBlob(val.base64, contentType);
              blobUrl = URL.createObjectURL(blob);

              drawImgToBackground(
                blobUrl,
                percentageToPixelForWidth(val.width),
                percentageToPixelForHeight(val.height),
                percentageToPixelForWidth(val.left),
                percentageToPixelForHeight(val.top),
                val.rotationAngle
              );

              break;
            case "drawImgToCanvas":
              {
                let left = percentageToPixelForWidth(val.left);
                let width = percentageToPixelForWidth(val.width);
                let top = percentageToPixelForHeight(val.top);
                let height = percentageToPixelForHeight(val.height);

                let ctx = canvasRef.current.getContext(
                  "2d"
                ) as CanvasRenderingContext2D;
                contentType = "image/" + val.fileExtension;

                blob = b64toBlob(val.base64, contentType);
                blobUrl = URL.createObjectURL(blob);

                let img = document.createElement("img");
                img.crossOrigin = "anonymous";
                img.onload = function () {
                  let rotationAngle = val.rotationAngle ? val.rotationAngle : 0;
                  if (rotationAngle === 0) {
                    ctx.drawImage(img, left, top, width, height);
                  } else {
                    ctx.save();
                    ctx.translate(left + width / 2, top + height / 2);
                    ctx.rotate(rotationAngle);
                    ctx.drawImage(
                      img,
                      -(width / 2),
                      -(height / 2),
                      width,
                      height
                    );
                    ctx.restore();
                  }
                };

                img.src = blobUrl;
                setBackgroundImage([]);
              }

              break;
            case "readOnly":
              readOnlyForAgent();
              setReadForInstructor(true);
              break;
            case "readWriteOnly":
              readWriteOnlyForAgent();
              setReadForInstructor(true);
              break;
          }
          return [];
        });
        break;
      case "drawLine":
        {
          let fromX = percentageToPixelForWidth(data.fromX);
          let fromY = percentageToPixelForHeight(data.fromY);
          let toX = percentageToPixelForWidth(data.toX);
          let toY = percentageToPixelForHeight(data.toY);

          drawPenLine(
            fromX,
            fromY,
            toX,
            toY,
            data.strokeWidth,
            data.strokeColor
          );
        }
        break;

      case "drawPen":
        data.data.map(
          (val: {
            coords: Array<number>;
            strokeWidth: string;
            strokeColor: string;
          }) => {
            const { coords, strokeWidth, strokeColor } = val;
            let sendxm1 = percentageToPixelForWidth(coords[0] + "");
            let sendym1 = percentageToPixelForHeight(coords[1] + "");
            let sendx0 = percentageToPixelForWidth(coords[2] + "");
            let sendy0 = percentageToPixelForHeight(coords[3] + "");
            let sendx1 = percentageToPixelForWidth(coords[4] + "");
            let sendy1 = percentageToPixelForHeight(coords[5] + "");
            let sendx2 = percentageToPixelForWidth(coords[6] + "");
            let sendy2 = percentageToPixelForHeight(coords[7] + "");
            drawPenSmoothLine(
              [
                sendxm1,
                sendym1,
                sendx0,
                sendy0,
                sendx1,
                sendy1,
                sendx2,
                sendy2,
              ],
              strokeWidth,
              strokeColor
            );
            return [];
          }
        );

        setSendPenData([]);

        break;

      case "drawCircle":
        {
          let fromX = percentageToPixelForWidth(data.fromX);
          let fromY = percentageToPixelForHeight(data.fromY);
          let radius = percentageToPixelForWidth(data.radius);

          drawCircle(fromX, fromY, radius, data.strokeWidth, data.strokeColor);
        }

        break;

      case "drawRect":
        {
          let fromX = percentageToPixelForWidth(data.fromX);
          let fromY = percentageToPixelForHeight(data.fromY);
          let toX = percentageToPixelForWidth(data.toX);
          let toY = percentageToPixelForHeight(data.toY);

          drawRec(fromX, fromY, toX, toY, data.strokeWidth, data.strokeColor);
        }
        break;

      case "drawEraser":
        data.data.map(
          (val: {
            fromX: number;
            fromY: number;
            toX: number;
            toY: number;
            strokeWidth: string;
          }) => {
            let fromX = percentageToPixelForWidth(val.fromX + "");
            let fromY = percentageToPixelForHeight(val.fromY + "");
            let toX = percentageToPixelForWidth(val.toX + "");
            let toY = percentageToPixelForHeight(val.toY + "");

            drawEraserLine(fromX, fromY, toX, toY, val.strokeWidth);
            return [];
          }
        );

        setSendEraserData([]);

        break;

      case "placeText":
        setTextBosex((value) => [
          ...value,
          {
            ...data,
            top: percentageToPixelForHeight(data.top),
            left: percentageToPixelForWidth(data.left),
          },
        ]);

        break;

      case "textInput":
        setTextBosex((valuePoint) =>
          valuePoint.map((point) => {
            if (point.id === data.id) {
              return {
                ...point,
                text: data.text,
                fontSize: data.fontSize,
                fontColor: data.fontColor,
                background: data.background,
              };
            } else {
              return { ...point };
            }
          })
        );

        break;
      case "closeText":
        setTextBosex((valuePoint) =>
          valuePoint.filter((point) => point.id !== data.id)
        );

        break;

      case "dragTextInput":
        setTextBosex((valuePoint) =>
          valuePoint.map((point) => {
            if (point.id === data.id) {
              return {
                ...point,
                top: percentageToPixelForHeight(data.top + ""),
                left: percentageToPixelForWidth(data.left + ""),
              };
            } else {
              return { ...point };
            }
          })
        );

        break;

      case "placeStickyNote":
        setStickyNotesBox((value) => [
          ...value,
          {
            ...data,
            top: percentageToPixelForHeight(data.top),
            left: percentageToPixelForWidth(data.left),
          },
        ]);

        break;

      case "stickInput":
        setStickyNotesBox((valuePoint) =>
          valuePoint.map((point) => {
            if (point.id === data.id) {
              return {
                ...point,
                text: data.text,
                fontSize: data.fontSize,
                fontColor: data.fontColor,
                background: data.background,
              };
            } else {
              return { ...point };
            }
          })
        );

        break;

      case "closeSticky":
        setStickyNotesBox((valuePoint) =>
          valuePoint.filter((point) => point.id !== data.id)
        );

        break;

      case "dragStickyNote":
        setStickyNotesBox((valuePoint) =>
          valuePoint.map((point) => {
            if (point.id === data.id) {
              return {
                ...point,
                top: percentageToPixelForHeight(data.top + ""),
                left: percentageToPixelForWidth(data.left + ""),
              };
            } else {
              return { ...point };
            }
          })
        );

        break;

      case "resizeStickyNote":
        setStickyNotesBox((valuePoint) =>
          valuePoint.map((point) => {
            if (point.id === data.id) {
              return {
                ...point,
                y: percentageToPixelForHeight(data.y + ""),
                x: percentageToPixelForWidth(data.x + ""),
              };
            } else {
              return { ...point };
            }
          })
        );
        break;

      case "drawImgToBackground":
        contentType = "image/" + data.fileExtension;

        blob = b64toBlob(data.base64, contentType);
        blobUrl = URL.createObjectURL(blob);

        drawImgToBackground(
          blobUrl,
          percentageToPixelForWidth(data.width),
          percentageToPixelForHeight(data.height),
          percentageToPixelForWidth(data.left),
          percentageToPixelForHeight(data.top),
          data.rotationAngle
        );

        break;
      case "drawImgToCanvas":
        {
          let left = percentageToPixelForWidth(data.left);
          let width = percentageToPixelForWidth(data.width);
          let top = percentageToPixelForHeight(data.top);
          let height = percentageToPixelForHeight(data.height);

          let ctx = canvasRef.current.getContext(
            "2d"
          ) as CanvasRenderingContext2D;
          contentType = "image/" + data.fileExtension;

          blob = b64toBlob(data.base64, contentType);
          blobUrl = URL.createObjectURL(blob);

          let img = document.createElement("img");
          img.crossOrigin = "anonymous";
          img.onload = function () {
            let rotationAngle = data.rotationAngle ? data.rotationAngle : 0;
            if (rotationAngle === 0) {
              ctx.drawImage(img, left, top, width, height);
            } else {
              ctx.save();
              ctx.translate(left + width / 2, top + height / 2);
              ctx.rotate(rotationAngle);
              ctx.drawImage(img, -(width / 2), -(height / 2), width, height);
              ctx.restore();
            }
          };

          img.src = blobUrl;
          setBackgroundImage([]);
        }

        break;
      default:
        break;
    }
  };

  // Save whiteBoard as image
  const getImageDataBase64 = () => {
    let width = canvasRef.current.width;
    let height = canvasRef.current.height;
    let copyCanvas = document.createElement("canvas");
    copyCanvas.width = width;
    copyCanvas.height = height;
    let imageFormat = "png";
    let drawBackgroundGrid = false;

    let backGroundImg = new Image();
    backGroundImg.crossOrigin = "anonymous";
    backGroundImg.src = bgGrid;

    backGroundImg.onload = function () {
      let destCtx = copyCanvas.getContext("2d") as CanvasRenderingContext2D; //Draw the maincanvas to the exportcanvas

      if (imageFormat === "jpeg") {
        //Set white background for jpeg images
        destCtx.fillStyle = "#FFFFFF";
        destCtx.fillRect(0, 0, copyCanvas.width, copyCanvas.height);
      }

      destCtx.fillStyle = "#FFFFFF";
      destCtx.fillRect(0, 0, width, height);

      if (drawBackgroundGrid) {
        destCtx.globalAlpha = 0.8;
        var ptrn = destCtx.createPattern(
          backGroundImg,
          "repeat"
        ) as CanvasPattern; // Create a pattern with this image, and set it to "repeat".
        destCtx.fillStyle = ptrn;
        destCtx.fillRect(0, 0, copyCanvas.width, copyCanvas.height); // context.fillRect(x, y, width, height);
        destCtx.globalAlpha = 1;
      }

      let allImgInContainer = document.getElementsByClassName("imgInContainer");
      for (let i = 0; i < allImgInContainer.length; i++) {
        let img = allImgInContainer[i] as HTMLImageElement;

        let width = parseInt(img.style.width);
        let height = parseInt(img.style.height);
        let left = parseInt(img.style.left);
        let top = parseInt(img.style.top);
        destCtx.drawImage(img, left, top, width, height);
      }

      //Copy drawings
      destCtx.drawImage(canvasRef.current, 0, 0);

      let allTextBoxex = document.getElementsByClassName("textBox");
      let textBoxCnt = 0;

      for (let i = 0; i < allTextBoxex.length; i++) {
        textBoxCnt++;
        let textContainer = allTextBoxex[i] as HTMLDivElement;
        let left = Math.round(parseInt(textContainer.style.left) * 100) / 100;
        let top = Math.round(parseInt(textContainer.style.top) * 100) / 100;

        html2canvas(textContainer, {
          backgroundColor: "rgba(0,0,0,0)",
          removeContainer: true,
          // eslint-disable-next-line no-loop-func
        }).then(function (canvas) {
          destCtx.drawImage(canvas, left, top);
          textBoxCnt--;
          checkForReturn();
        });
      }

      const checkForReturn = () => {
        if (textBoxCnt === 0) {
          let url = copyCanvas.toDataURL("image/" + imageFormat);

          let w = window.open("about:blank") as Window; //Firefox will not allow downloads without extra window
          setTimeout(function () {
            //FireFox seems to require a setTimeout for this to work.
            var a = document.createElement("a");
            a.href = url;
            a.download = "whiteboard." + imageFormat;
            w.document.body.appendChild(a);
            a.click();
            w.document.body.removeChild(a);
            setTimeout(function () {
              w.close();
            }, 100);
            setSaveImage(false);
          }, 0);
        }
      };

      checkForReturn();
    };
  };

  useEffect(() => {
    if (saveImage) {
      getImageDataBase64();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveImage]);

  // Start all Function of Draw Line

  const LineMouseDown = (e: Event) => {
    const currentPos = Point.fromEvent(e);

    setStartCoords(currentPos);

    svgLine.setAttribute("stroke", "gray");
    svgLine.setAttribute("stroke-dasharray", "5, 5");
    svgLine.setAttribute("style", "fill-opacity:0.0;");
    svgLine.setAttribute("x1", currentPos.x + "");
    svgLine.setAttribute("y1", currentPos.y + "");
    svgLine.setAttribute("x2", currentPos.x + "");
    svgLine.setAttribute("y2", currentPos.y + "");
    svgLine.setAttribute("r", "0");

    svgContainer.current.append(svgLine);

    setPrevPos(currentPos);
    setLineBoolean(true);
  };

  const LineMouseMove = (e: Event) => {
    e.preventDefault();

    let currentPos = Point.fromEvent(e);

    let posToUse = currentPos;
    if (svgLine) {
      svgLine.setAttribute("x2", posToUse.x + "");
      svgLine.setAttribute("y2", posToUse.y + "");
    }
    setPrevPos(currentPos);
  };

  let drawPenLine = function (
    fromX: number,
    fromY: number,
    toX: number,
    toY: number,
    sWidth?: string,
    sColor?: string
  ) {
    let ctx = canvasRef.current.getContext("2d") as CanvasRenderingContext2D;

    ctx.beginPath();
    ctx.strokeStyle = sColor ? sColor : strokeColor;
    ctx.lineWidth = parseInt(sWidth ? sWidth : strokeWidth);
    ctx.lineCap = "round";
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.stroke();
    ctx.closePath();
  };

  const LineMouseUp = async (e: Event) => {
    if (svgContainer.current.childNodes.length <= 0) return;
    let currentPos = Point.fromEvent(e);
    // @ts-ignore
    ctx.globalCompositeOperation = oldGCO;

    drawPenLine(currentPos.x, currentPos.y, startCoords.x, startCoords.y);

    svgContainer.current.removeChild(svgContainer.current.childNodes[0]);
    await socketInstance.sendMessage("WHITEBOARD_ACTION", {
      tool: "drawLine",
      fromX: PixelToPercentageForWidth(currentPos.x),
      fromY: PixelToPercentageForHeight(currentPos.y),
      toX: PixelToPercentageForWidth(startCoords.x),
      toY: PixelToPercentageForHeight(startCoords.y),
      strokeWidth,
      strokeColor,
    });

    setLineBoolean(false);
  };

  // End Function of  Draw line here

  // Start All Function of Draw circle
  let drawCircle = function (
    fromX: number,
    fromY: number,
    radius: number,
    sWidth?: string,
    sColor?: string
  ) {
    let ctx = canvasRef.current.getContext("2d") as CanvasRenderingContext2D;
    ctx.beginPath();
    ctx.strokeStyle = sColor ? sColor : strokeColor;
    ctx.lineWidth = parseInt(sWidth ? sWidth : strokeWidth);
    ctx.lineCap = "round";
    ctx.arc(fromX, fromY, radius, 0, 2 * Math.PI, false);

    ctx.stroke();
  };

  const circleMouseDown = (e: any) => {
    const currentPos = Point.fromEvent(e);

    svgCircle.setAttribute("stroke", "gray");
    svgCircle.setAttribute("stroke-dasharray", "5, 5");
    svgCircle.setAttribute("style", "fill-opacity:0.0;");
    svgCircle.setAttribute("cx", currentPos.x + "");
    svgCircle.setAttribute("cy", currentPos.y + "");
    svgCircle.setAttribute("r", "0");
    svgContainer.current.append(svgCircle);
    // startCoords = currentPos;
    setStartCoords(currentPos);
    setPrevPos(currentPos);
  };

  const circleMouseMove = (e: any) => {
    if (startCoords) {
      e.preventDefault();
      let currentPos = Point.fromEvent(e);

      const r = currentPos.distTo(startCoords);
      if (svgCircle) {
        svgCircle.setAttribute("r", r + "");
      }
      setPrevPos(currentPos);
    }
  };

  const circleMouseUp = (e: any) => {
    if (svgContainer.current.childNodes.length <= 0) return;
    if (startCoords) {
      let currentPos = Point.fromEvent(e);

      setStartCoords(currentPos);
      const r = currentPos.distTo(startCoords);
      drawCircle(startCoords.x, startCoords.y, r);
      socketInstance.sendMessage("WHITEBOARD_ACTION", {
        tool: "drawCircle",
        fromX: PixelToPercentageForWidth(startCoords.x),
        fromY: PixelToPercentageForHeight(startCoords.y),
        radius: PixelToPercentageForWidth(r),
        strokeWidth,
        strokeColor,
      });

      svgContainer.current.removeChild(svgContainer.current.childNodes[0]);
    }
  };

  //  End  Function of Draw Circle

  // Start All Function of Rectangle

  var drawRec = function (
    fromX: number,
    fromY: number,
    toX: number,
    toY: number,
    sWidth?: string,
    sColor?: string
  ) {
    let ctx = canvasRef.current.getContext("2d") as CanvasRenderingContext2D;
    let X = toX - fromX;
    let Y = toY - fromY;
    ctx.beginPath();
    ctx.strokeStyle = sColor ? sColor : strokeColor;
    ctx.lineWidth = parseInt(sWidth ? sWidth : strokeWidth);
    ctx.lineCap = "round";

    ctx.rect(fromX, fromY, X, Y);

    ctx.stroke();
    ctx.closePath();
  };

  const RectMouseDown = (e: any) => {
    const currentPos = Point.fromEvent(e);

    svgRect.setAttribute("stroke", "gray");
    svgRect.setAttribute("stroke-dasharray", "5, 5");
    svgRect.setAttribute("style", "fill-opacity:0.0;");
    svgRect.setAttribute("x", currentPos.x + "");
    svgRect.setAttribute("y", currentPos.y + "");
    svgRect.setAttribute("width", "0");
    svgRect.setAttribute("height", "0");
    svgContainer.current.append(svgRect);
    setStartCoords(currentPos);
    setPrevPos(currentPos);
  };

  const RectMouseMove = (e: any) => {
    e.preventDefault();
    let currentPos = Point.fromEvent(e);

    if (svgRect) {
      const width = Math.abs(currentPos.x - startCoords.x);
      let height = Math.abs(currentPos.y - startCoords.y);

      const x = currentPos.x < startCoords.x ? currentPos.x : startCoords.x;
      const y = currentPos.y < startCoords.y ? currentPos.y : startCoords.y;
      svgRect.setAttribute("x", x + "");
      svgRect.setAttribute("y", y + "");

      svgRect.setAttribute("width", width + "");
      svgRect.setAttribute("height", height + "");
    }
    setPrevPos(currentPos);
  };

  const RectMouseUp = (e: any) => {
    if (svgContainer.current.childNodes.length <= 0) return;
    let currentPos = Point.fromEvent(e);
    // @ts-ignore
    ctx.globalCompositeOperation = oldGCO;

    if ((currentPos.x - startCoords.x) * (currentPos.y - startCoords.y) > 0) {
      currentPos = new Point(
        currentPos.x,
        startCoords.y + (currentPos.x - startCoords.x)
      );
    } else {
      currentPos = new Point(
        currentPos.x,
        startCoords.y - (currentPos.x - startCoords.x)
      );
    }

    drawRec(startCoords.x, startCoords.y, currentPos.x, currentPos.y);
    socketInstance.sendMessage("WHITEBOARD_ACTION", {
      tool: "drawRect",
      fromX: PixelToPercentageForWidth(startCoords.x),
      fromY: PixelToPercentageForHeight(startCoords.y),
      toX: PixelToPercentageForWidth(currentPos.x),
      toY: PixelToPercentageForHeight(currentPos.y),
      strokeColor,
      strokeWidth,
    });

    svgContainer.current.removeChild(svgContainer.current.childNodes[0]);
  };

  // End Function of Draw Rectangle

  // Start all Function of Draw Pen

  const pushPointSmoothPen = function (X: number, Y: number) {
    if (penSmoothLastCoords.length >= 8) {
      setPenSmoothLastCoords((value) => [
        value[2],
        value[3],
        value[4],
        value[5],
        value[6],
        value[7],
      ]);
    }
    setPenSmoothLastCoords((value) => [...value, X, Y]);
    if (penSmoothLastCoords.length >= 8) {
      drawPenSmoothLine(penSmoothLastCoords);
    }
  };

  const drawPenSmoothLine = function (
    coords: Array<number>,
    sWidth?: string,
    sColor?: string
  ) {
    let ctx = canvasRef.current.getContext("2d") as CanvasRenderingContext2D;

    let xm1 = coords[0];
    let ym1 = coords[1];
    let x0 = coords[2];
    let y0 = coords[3];
    let x1 = coords[4];
    let y1 = coords[5];
    let x2 = coords[6];
    let y2 = coords[7];

    let sendxm1 = parseFloat(PixelToPercentageForWidth(coords[0]));
    let sendym1 = parseFloat(PixelToPercentageForHeight(coords[1]));
    let sendx0 = parseFloat(PixelToPercentageForWidth(coords[2]));
    let sendy0 = parseFloat(PixelToPercentageForHeight(coords[3]));
    let sendx1 = parseFloat(PixelToPercentageForWidth(coords[4]));
    let sendy1 = parseFloat(PixelToPercentageForHeight(coords[5]));
    let sendx2 = parseFloat(PixelToPercentageForWidth(coords[6]));
    let sendy2 = parseFloat(PixelToPercentageForHeight(coords[7]));

    setSendPenData((value) => [
      ...value,
      {
        coords: [
          sendxm1,
          sendym1,
          sendx0,
          sendy0,
          sendx1,
          sendy1,
          sendx2,
          sendy2,
        ],
        strokeWidth,
        strokeColor,
      },
    ]);

    var length = Math.sqrt(Math.pow(x0 - x1, 2) + Math.pow(y0 - y1, 2));
    var steps = Math.ceil(length / 5);
    ctx.beginPath();
    ctx.strokeStyle = sColor ? sColor : strokeColor;
    ctx.lineWidth = parseInt(sWidth ? sWidth : strokeWidth);
    ctx.lineCap = "round";
    ctx.moveTo(x0, y0);
    if (steps === 0) {
      ctx.lineTo(x0, y0);
    }
    for (var i = 0; i < steps; i++) {
      var point = lanczosInterpolate(
        xm1,
        ym1,
        x0,
        y0,
        x1,
        y1,
        x2,
        y2,
        (i + 1) / steps
      );
      ctx.lineTo(point[0], point[1]);
    }

    ctx.stroke();
    ctx.closePath();
  };

  const lanczosInterpolate = function (
    xm1: number,
    ym1: number,
    x0: number,
    y0: number,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    a: number
  ) {
    var cm1 = lanczosKernel(1 + a);
    var c0 = lanczosKernel(a);
    var c1 = lanczosKernel(1 - a);
    var c2 = lanczosKernel(2 - a);
    var delta = (cm1 + c0 + c1 + c2 - 1) / 4;
    cm1 -= delta;
    c0 -= delta;
    c1 -= delta;
    c2 -= delta;
    return [
      cm1 * xm1 + c0 * x0 + c1 * x1 + c2 * x2,
      cm1 * ym1 + c0 * y0 + c1 * y1 + c2 * y2,
    ];
  };

  const lanczosKernel = function (x: number) {
    if (x === 0) {
      return 1.0;
    }
    return (
      (2 * Math.sin(Math.PI * x) * Math.sin((Math.PI * x) / 2)) /
      Math.pow(Math.PI * x, 2)
    );
  };

  const penMouseDown = (e: any) => {
    const currentPos = Point.fromEvent(e);
    setPenSmoothLastCoords([
      currentPos.x,
      currentPos.y,
      currentPos.x,
      currentPos.y,
      currentPos.x,
      currentPos.y,
    ]);
    setPrevPos(currentPos);
  };

  const penMouseMove = (e: any) => {
    e.preventDefault();
    let currentPos = Point.fromEvent(e);

    pushPointSmoothPen(currentPos.x, currentPos.y);
    setPrevPos(currentPos);
  };

  const penMouseUp = (e: any) => {
    let currentPos = Point.fromEvent(e);

    pushPointSmoothPen(currentPos.x, currentPos.y);
    if (sendPenData.length > 0) {
      socketInstance.sendMessage("WHITEBOARD_ACTION", {
        tool: "drawPen",
        data: sendPenData,
      });
    }
    setSendPenData([]);
  };

  // End All Function of Draw pen

  // Start All Function Eraser
  var drawEraserLine = function (
    fromX: number,
    fromY: number,
    toX: number,
    toY: number,
    sWidth?: string
  ) {
    let ctx = canvasRef.current.getContext("2d") as CanvasRenderingContext2D;
    let sendFromX = PixelToPercentageForWidth(fromX);
    let sendFromY = PixelToPercentageForHeight(fromY);
    let sendToX = PixelToPercentageForWidth(toX);
    let sendToY = PixelToPercentageForHeight(toY);

    setSendEraserData((value) => [
      ...value,
      {
        fromX: parseFloat(sendFromX),
        fromY: parseFloat(sendFromY),
        toX: parseFloat(sendToX),
        toY: parseFloat(sendToY),
        strokeWidth,
      },
    ]);

    ctx.beginPath();
    ctx.lineWidth = parseInt(sWidth ? sWidth : strokeWidth) * 2;
    ctx.lineCap = "round";
    ctx.lineTo(fromX, fromY);
    ctx.moveTo(toX, toY);
    ctx.strokeStyle = "rgba(0,0,0,1)";

    ctx.globalCompositeOperation = "destination-out";
    ctx.stroke();
    ctx.closePath();
    ctx.globalCompositeOperation = "source-over";
  };

  const eraserMouseDown = (e: any) => {
    const currentPos = Point.fromEvent(e);

    drawEraserLine(currentPos.x, currentPos.y, currentPos.x, currentPos.y);
  };

  const eraserMouseMove = (e: any) => {
    e.preventDefault();

    let currentPos = Point.fromEvent(e);

    drawEraserLine(currentPos.x, currentPos.y, prevPos.x, prevPos.y);

    setPrevPos(currentPos);
  };

  //  End All Function Eraser

  const ShapeOnMouseDown = (e: any) => {
    if (selectTool.line === true) {
      LineMouseDown(e);
    } else if (selectTool.circle === true) {
      setStartCoords(new Point(0, 0));
      setPrevPos(new Point(0, 0));
      setCircleBoolean(true);
      circleMouseDown(e);
    } else if (selectTool.rectangle) {
      setStartCoords(new Point(0, 0));
      setPrevPos(new Point(0, 0));
      setRectBoolean(true);
      RectMouseDown(e);
    } else if (selectTool.pen === true) {
      setStartCoords(new Point(0, 0));
      setPrevPos(new Point(0, 0));
      setDrawClick(true);
      penMouseDown(e);
    } else if (selectTool.eraser === true) {
      setStartCoords(new Point(0, 0));
      setPrevPos(new Point(0, 0));
      setEraserClick(true);
      eraserMouseDown(e);
    }
  };

  const shapeOnMouseMove = (e: any) => {
    if (selectTool.line === true) {
      if (lineBoolean) {
        LineMouseMove(e);
      }
    } else if (selectTool.circle === true) {
      if (circleBoolean) {
        circleMouseMove(e);
      }
    } else if (selectTool.rectangle) {
      if (rectBoolean) {
        RectMouseMove(e);
      }
    } else if (selectTool.pen === true) {
      if (drawClick) {
        penMouseMove(e);
      }
    } else if (selectTool.eraser === true) {
      if (eraserClick) {
        eraserMouseMove(e);
      }
    }

    if (rotateBoolean) {
      const arrow = document.querySelector("#dragImage");
      const arrowCenter = getCenter(arrow);
      const angle = Math.atan2(
        e.clientY - arrowCenter.y,
        e.clientX - arrowCenter.x
      );
      setRotateDegree(angle);
    }
    if (resizeBoolean) {
      resizeMouseMove(e);
    }
  };

  const shapeOnMouseUp = (e: any) => {
    if (!rotateBoolean && !resizeBoolean) {
      if (selectTool.line === true) {
        LineMouseUp(e);
      } else if (selectTool.circle === true) {
        circleMouseUp(e);
        setCircleBoolean(false);
      } else if (selectTool.rectangle) {
        RectMouseUp(e);
        setRectBoolean(false);
      } else if (selectTool.pen === true) {
        penMouseUp(e);
        setDrawClick(false);
      } else if (selectTool.eraser === true) {
        setEraserClick(false);
        if (sendEraseData.length > 0) {
          socketInstance.sendMessage("WHITEBOARD_ACTION", {
            tool: "drawEraser",
            data: sendEraseData,
          });
        }

        setSendEraserData([]);
      }
    }

    if (rotateBoolean) {
      setRotateBoolean(false);
    } else if (resizeBoolean) {
      resizeMouseUp();
    }
  };

  const shapeMouseLeave = () => {
    setLineBoolean(false);
    setCircleBoolean(false);
    setRectBoolean(false);
    setDrawClick(false);
    setEraserClick(false);
    setRotateBoolean(false);
    resizeMouseUp();
  };

  function getCenter(element: any) {
    const { left, top, width, height } = element.getBoundingClientRect();
    return { x: left + width / 2, y: top + height / 2 };
  }
  useEffect(() => {
    setOldGCO(ctx.globalCompositeOperation);
    ctx.strokeStyle = strokeColor;
    ctx.lineWidth = parseInt(strokeWidth);
    if (selectStickyId) {
      setStickyNotesBox((valuePoint) =>
        valuePoint.map((point) => {
          if (point.id === selectStickyId) {
            return {
              ...point,
              fontSize: parseInt(strokeWidth) / 2 + "",
              fontColor: strokeColor,
            };
          } else {
            return { ...point };
          }
        })
      );
    }

    if (selectTextBoxId) {
      setTextBosex((valuePoint) =>
        valuePoint.map((point) => {
          if (point.id === selectTextBoxId) {
            return {
              ...point,
              fontSize: parseInt(strokeWidth) / 2 + "",
              fontColor: strokeColor,
            };
          } else {
            return { ...point };
          }
        })
      );
    }
  }, [ctx, selectStickyId, selectTextBoxId, strokeColor, strokeWidth]);

  useEffect(() => {
    if (selectStickyId) {
      setStickyNotesBox((valuePoint) =>
        valuePoint.map((point) => {
          if (point.id === selectStickyId) {
            return {
              ...point,
              background: stickyNoteColor,
            };
          } else {
            return { ...point };
          }
        })
      );
    }

    if (selectTextBoxId) {
      setTextBosex((valuePoint) =>
        valuePoint.map((point) => {
          if (point.id === selectTextBoxId) {
            return {
              ...point,
              background: stickyNoteColor,
            };
          } else {
            return { ...point };
          }
        })
      );
    }
  }, [selectStickyId, selectTextBoxId, stickyNoteColor]);

  const drawImgToCanvas = (
    url: string,
    width: number,
    height: number,
    left: number,
    top: number,
    rotationAngle: number
  ) => {
    let img = document.createElement("img");
    img.crossOrigin = "anonymous";
    img.onload = function () {
      rotationAngle = rotationAngle ? rotationAngle : 0;
      if (rotationAngle === 0) {
        ctx.drawImage(img, left, top, width, height);
      } else {
        ctx.save();
        ctx.translate(left + width / 2, top + height / 2);
        ctx.rotate(rotationAngle);
        ctx.drawImage(img, -(width / 2), -(height / 2), width, height);
        ctx.restore();
      }
    };

    img.src = url;
    setBackgroundImage([]);
  };

  const drawImgToBackground = (
    url: string,
    width: number,
    height: number,
    left: number,
    top: number,
    rotationAngle: number
  ) => {
    let img = document.createElement("img");
    img.className = "imgInContainer";
    img.crossOrigin = "anonymous";
    img.style.width = width + "px";
    img.style.height = height + "px";
    img.style.position = "absolute";
    img.style.top = top + "px";
    img.style.left = left + "px";
    img.style.transform = `rotate(${rotationAngle}rad)`;
    img.src = url === "" ? backgroundImage[0].img : url;

    imgContiainer.current.append(img);

    setBackgroundImage([]);
  };

  const resizeMouseDown = (e: any) => {
    setResizeBoolean(true);
  };

  const resizeMouseMove = (e: any) => {
    if (resizeBoolean) {
      if (resizeValue !== "img") {
        setStickyNotesBox((valuePoint) =>
          valuePoint.map((point) => {
            if (point.id === resizeValue) {
              return {
                ...point,
                x: parseInt(point.x) + e.movementX + "",
                y: parseInt(point.y) + e.movementY + "",
              };
            } else {
              return { ...point };
            }
          })
        );
      } else if (resizeValue === "img") {
        setSize((currentSize) => ({
          x: currentSize.x + e.movementX,
          y: currentSize.y + e.movementY,
        }));
      }
    }
  };

  const resizeMouseUp = () => {
    if (resizeValue !== "img" && resizeBoolean) {
      let stickValue = stickyNotesBox.find((value) => value.id === resizeValue);

      socketInstance.sendMessage("WHITEBOARD_ACTION", {
        tool: "resizeStickyNote",
        x: PixelToPercentageForWidth(stickValue.x),
        y: PixelToPercentageForHeight(stickValue.y),
        id: resizeValue,
      });
    }
    setResizeBoolean(false);
    setResizeValue("");
  };

  const rotateMouseDown = (e: any) => {
    setRotateBoolean(true);
  };

  const rotateMouseUp = (e: any) => {
    setRotateBoolean(false);
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          left: "0px",
          top: "0",
          backgroundImage: `url(${bgGrid})`,
          height: "100%",
          width: "100%",
          opacity: "0.4",
        }}
      ></div>
      <div
        ref={imgContiainer}
        style={{
          position: "absolute",
          left: "0px",
          top: "0px",
          height: "100%",
          width: "100%",
          opacity: "1",
        }}
      ></div>
      <canvas
        id="whiteboardCanvas"
        ref={canvasRef}
        style={{
          position: "absolute",
          left: "0px",
          top: 0,
          cursor: "crosshair",
          opacity: "1",
        }}
      ></canvas>
      <svg
        id="svgContainer"
        ref={svgContainer}
        style={{ position: "absolute", top: "0px", left: "0px" }}
        width="100%"
        height="100%"
      ></svg>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          border: "7px dashed gray",
          textAlign: "center",
          top: "0px",
          left: "0px",
          color: "gray",
          fontSize: "23em",
          display: `${dropIndicator ? "block" : "none"}`,
        }}
      >
        <FontAwesomeIcon icon={faPlusSquare} />
      </div>

      <div
        ref={cursorContainer}
        style={{
          position: "absolute",
          left: "0px",
          top: "0px",
          height: "100%",
          width: "100%",
        }}
      ></div>
      <div
        className="textcontainer"
        ref={textContainer}
        style={{
          position: "absolute",
          left: "0px",
          top: "0px",
          height: "100%",
          width: "100%",
          cursor: "text",
        }}
      ></div>
      <div>
        <div
          id="mouseOverlay"
          ref={mouseOverlay}
          onDragEnter={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onMouseDown={(e) => ShapeOnMouseDown(e)}
          onMouseMove={(e) => shapeOnMouseMove(e)}
          onMouseUp={(e) => {
            e.preventDefault();
            e.stopPropagation();
            shapeOnMouseUp(e);
            setDropIndicator(false);
          }}
          onMouseLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
            shapeMouseLeave();

            setDropIndicator(false);
          }}
          onClick={(e) => {
            if (selectTool.textBox) {
              let uuId = uuidv4();
              setTextBosex((value) => [
                ...value,
                {
                  id: uuId,
                  top: Point.fromEvent(e as any).y + "",
                  left: Point.fromEvent(e as any).x + "",
                  background: stickyNoteColor,
                  fontColor: strokeColor,
                  text: "Text",
                  fontSize: parseInt(strokeWidth) / 2 + "",
                },
              ]);

              socketInstance.sendMessage("WHITEBOARD_ACTION", {
                tool: "placeText",
                id: uuId,
                top: PixelToPercentageForHeight(Point.fromEvent(e as any).y),
                left: PixelToPercentageForWidth(Point.fromEvent(e as any).x),
                background: stickyNoteColor,
                fontColor: strokeColor,
                text: "Text",
                fontSize: parseInt(strokeWidth) / 2 + "",
              });
            } else if (selectTool.stickyNote) {
              let uuIdStick = uuidv4();
              setStickyNotesBox((value) => [
                ...value,
                {
                  id: uuIdStick,
                  top: Point.fromEvent(e as any).y + "",
                  left: Point.fromEvent(e as any).x + "",
                  background: stickyNoteColor,
                  fontColor: strokeColor,
                  text: "Text",
                  fontSize: parseInt(strokeWidth) / 2 + "",
                  x: "200",
                  y: "200",
                },
              ]);

              socketInstance.sendMessage("WHITEBOARD_ACTION", {
                tool: "placeStickyNote",
                id: uuIdStick,
                top: PixelToPercentageForHeight(Point.fromEvent(e as any).y),
                left: PixelToPercentageForWidth(Point.fromEvent(e as any).x),
                background: stickyNoteColor,
                fontColor: strokeColor,
                text: "Text",
                fontSize: parseInt(strokeWidth) / 2 + "",
                x: "200",
                y: "200",
              });

              setSelectTool(() => {
                return {
                  ...selectTool,
                  stickyNote: false,
                  pen: true,
                };
              });
            } else {
              SetSelectTextBoxId("");
              setSelectStickyId("");
            }
          }}
          style={{
            cursor: `${rotateBoolean ? "grabbing" : "crosshair"}`,
            position: "absolute",
            left: "0px",
            top: "0px",
            height: "100%",
            width: "100%",
          }}
        >
          {!(
            Object.keys(stickyNotesBox).length === 0 &&
            stickyNotesBox.constructor === Object
          ) &&
            stickyNotesBox.map((value) => (
              <div
                key={value.id}
                className="textBox stickyNote"
                onClick={(e) => {
                  e.stopPropagation();
                  if (readOnly) return;
                  SetSelectTextBoxId("");
                  setSelectStickyId(value.id);
                }}
                onMouseMove={(e) => {
                  e.stopPropagation();
                  shapeOnMouseMove(e);
                  resizeMouseMove(e);
                }}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => {
                  e.stopPropagation();
                  resizeMouseUp();
                }}
                style={{
                  fontFamily: "Monospace",
                  position: "absolute",
                  height: `${value.y}px`,
                  width: `${value.x}px`,
                  top: `${value.top}px`,
                  left: `${value.left}px`,
                  backgroundColor: `${value.background}`,
                  cursor: "text",
                  outline: `${
                    selectStickyId === value.id && !readOnly
                      ? "2px black dashed"
                      : "none"
                  }`,
                }}
              >
                <ContentEditable
                  disabled={!readOnly ? false : true}
                  html={value.text}
                  className="textContent"
                  style={{
                    outline: "none",
                    fontSize: `${value.fontSize + "em"}`,
                    cursor: "text",
                    color: `${value.fontColor}`,
                    minWidth: "50px",
                    minHeight: "50px",
                    paddingTop: "25px",
                    lineHeight: "1em",
                  }}
                  onChange={(e) => {
                    setStickyNotesBox((valuePoint) =>
                      valuePoint.map((point) => {
                        if (point.id === value.id) {
                          socketInstance.sendMessage("WHITEBOARD_ACTION", {
                            ...point,
                            tool: "stickInput",
                            text: e.target.value,
                          });
                          return { ...point, text: e.target.value };
                        } else {
                          return { ...point };
                        }
                      })
                    );
                  }}
                  tagName="div"
                />
                <div
                  title="remove textbox"
                  className="removeIcon"
                  draggable="true"
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    top: "-2px",
                    right: "8px",
                    fontSize: "1rem",
                    display: `${
                      selectStickyId === value.id && !readOnly
                        ? "block"
                        : "none"
                    }`,
                  }}
                  onClick={(e) => {
                    setStickyNotesBox((valuePoint) =>
                      valuePoint.filter((point) => point.id !== value.id)
                    );

                    socketInstance.sendMessage("WHITEBOARD_ACTION", {
                      tool: "closeSticky",
                      id: value.id,
                    });
                  }}
                >
                  x
                </div>

                <div
                  title="move textbox"
                  className="moveIcon"
                  draggable="true"
                  onClick={(e) => e.stopPropagation()}
                  onDragEnd={(e) => {
                    e.stopPropagation();
                    setStickyNotesBox((valuePoint) =>
                      valuePoint.map((point) => {
                        if (point.id === value.id) {
                          return {
                            ...point,
                            top: Point.fromEvent(e as any).y + "",
                            left: Point.fromEvent(e as any).x + "",
                          };
                        } else {
                          return { ...point };
                        }
                      })
                    );

                    socketInstance.sendMessage("WHITEBOARD_ACTION", {
                      tool: "dragStickyNote",
                      top: PixelToPercentageForHeight(
                        Point.fromEvent(e as any).y
                      ),
                      left: PixelToPercentageForWidth(
                        Point.fromEvent(e as any).x
                      ),
                      id: value.id,
                    });
                  }}
                  onDrag={(e) => {
                    e.stopPropagation();
                    setStickyNotesBox((valuePoint) =>
                      valuePoint.map((point) => {
                        if (point.id === value.id) {
                          return {
                            ...point,
                            top: Point.fromEvent(e as any).y + "",
                            left: Point.fromEvent(e as any).x + "",
                          };
                        } else {
                          return { ...point };
                        }
                      })
                    );
                  }}
                  style={{
                    position: "absolute",
                    cursor: "move",
                    top: "-3px",
                    left: "5px",
                    fontSize: "0.6em",
                    color: "black",
                    display: `${
                      selectStickyId === value.id && !readOnly
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  <FontAwesomeIcon icon={faExpandArrowsAlt} />
                </div>

                <FontAwesomeIcon
                  icon={faSortDown}
                  style={{
                    position: "absolute",
                    bottom: "-4px",
                    right: "2px",
                    fontSize: "2em",
                    color: "gray",
                    transform: "rotate(-45deg)",
                    cursor: "se-resize",
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    setResizeValue(value.id);
                    resizeMouseDown(e);
                  }}
                  onMouseMove={(e) => {
                    e.stopPropagation();
                    resizeMouseMove(e);
                  }}
                  onMouseUp={(e) => {
                    e.stopPropagation();
                    resizeMouseUp();
                  }}
                  onMouseLeave={(e) => {
                    e.stopPropagation();
                  }}
                  onDrag={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDragEnd={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDragStart={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </div>
            ))}

          {!(
            Object.keys(textBosex).length === 0 &&
            textBosex.constructor === Object
          ) &&
            textBosex.map((value) => (
              <div
                key={value.id}
                className="textBox"
                onClick={(e) => {
                  e.stopPropagation();
                  if (readOnly) return;
                  setSelectStickyId("");
                  SetSelectTextBoxId(value.id);
                }}
                onMouseMove={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
                style={{
                  fontFamily: "Monospace",
                  position: "absolute",
                  top: `${value.top}px`,
                  left: `${value.left}px`,
                  backgroundColor: `${value.background}`,
                  cursor: "text",
                  outline: `${
                    selectTextBoxId === value.id && !readOnly
                      ? "2px black dashed"
                      : "none"
                  }`,
                }}
              >
                <ContentEditable
                  disabled={!readOnly ? false : true}
                  html={value.text}
                  className="textContent"
                  style={{
                    outline: "none",
                    fontSize: `${value.fontSize + "em"}`,
                    cursor: "text",
                    color: `${value.fontColor}`,
                    minWidth: "50px",
                    minHeight: "50px",
                    paddingTop: "25px",
                    lineHeight: "1rem",
                  }}
                  onChange={(e) => {
                    setTextBosex((valuePoint) =>
                      valuePoint.map((point) => {
                        if (point.id === value.id) {
                          socketInstance.sendMessage("WHITEBOARD_ACTION", {
                            ...point,
                            tool: "textInput",
                            text: e.target.value,
                          });
                          return { ...point, text: e.target.value };
                        } else {
                          return { ...point };
                        }
                      })
                    );
                  }}
                  tagName="div"
                />
                <div
                  title="remove textbox"
                  className="removeIcon"
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    top: "-4px",
                    right: "2px",
                    color: "black",
                    fontSize: "0.9rem",
                    display: `${
                      selectTextBoxId === value.id && !readOnly
                        ? "block"
                        : "none"
                    }`,
                  }}
                  onClick={(e) => {
                    setTextBosex((valuePoint) =>
                      valuePoint.filter((point) => point.id !== value.id)
                    );

                    socketInstance.sendMessage("WHITEBOARD_ACTION", {
                      tool: "closeText",
                      id: value.id,
                    });
                  }}
                >
                  x
                </div>

                <div
                  title="move textbox"
                  className="moveIcon"
                  draggable="true"
                  onDragEnd={(e) => {
                    setTextBosex((valuePoint) =>
                      valuePoint.map((point) => {
                        if (point.id === value.id) {
                          return {
                            ...point,
                            top: Point.fromEvent(e as any).y + "",
                            left: Point.fromEvent(e as any).x + "",
                          };
                        } else {
                          return { ...point };
                        }
                      })
                    );

                    socketInstance.sendMessage("WHITEBOARD_ACTION", {
                      tool: "dragTextInput",
                      top: PixelToPercentageForHeight(
                        Point.fromEvent(e as any).y
                      ),
                      left: PixelToPercentageForWidth(
                        Point.fromEvent(e as any).x
                      ),
                      id: value.id,
                    });
                  }}
                  onDrag={(e) => {
                    setTextBosex((valuePoint) =>
                      valuePoint.map((point) => {
                        if (point.id === value.id) {
                          return {
                            ...point,
                            top: Point.fromEvent(e as any).y + "",
                            left: Point.fromEvent(e as any).x + "",
                          };
                        } else {
                          return { ...point };
                        }
                      })
                    );
                  }}
                  style={{
                    position: "absolute",
                    cursor: "move",
                    top: "-4px",
                    left: "3px",
                    fontSize: "0.6em",
                    color: "black",
                    display: `${
                      selectTextBoxId === value.id && !readOnly
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  <FontAwesomeIcon icon={faExpandArrowsAlt} />
                </div>
              </div>
            ))}

          {backgroundImage.length > 0 ? (
            <div
              className="dragMe"
              id="dragImage"
              style={{
                border: "2px dashed gray",
                position: "absolute",
                left: `${backImgTopLeft.left}px`,
                top: `${backImgTopLeft.top}px`,
                minWidth: "160px",
                minHeight: "100px",
                width: `${size.x}px`,
                height: `${size.y}px`,
                cursor: `${rotateBoolean ? "dragging" : "move"}`,
                transform: `rotate(${rotateDegree}rad)`,
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img
                style={{ width: `${size.x}px`, height: `${size.y}px` }}
                alt="img"
                src={backgroundImage[0].img}
                draggable={resizeBoolean || rotateBoolean ? false : true}
                onMouseLeave={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => {
                  e.stopPropagation();
                  setRotateBoolean(false);
                  resizeMouseUp();
                }}
                onMouseMove={(e) => {
                  e.stopPropagation();
                  shapeOnMouseMove(e);
                  resizeMouseMove(e);
                }}
                onDragEnd={(e) => {
                  if (rotateBoolean) return;
                  setBackImgTopLeft({
                    top: Point.fromEvent(e as any).y,
                    left: Point.fromEvent(e as any).x,
                  });
                }}
                onDrag={(e) => {
                  if (rotateBoolean) return;
                  setBackImgTopLeft({
                    top: Point.fromEvent(e as any).y,
                    left: Point.fromEvent(e as any).x,
                  });
                }}
              />
              <div style={{ position: "absolute", right: "5px", top: "3px" }}>
                <button
                  style={{
                    margin: "0px 0px",
                    background: "#03a9f4",
                    padding: "5px",
                    marginTop: "3px",
                    color: "white",
                  }}
                  className="addToCanvasBtn btn btn-default"
                  onClick={(e) => {
                    const fileExtensionArray = uploadFiles.name.split(".");
                    let fileExtension =
                      fileExtensionArray[fileExtensionArray.length - 1];

                    drawImgToCanvas(
                      backgroundImage[0].img,
                      size.x,
                      size.y,
                      backImgTopLeft.left,
                      backImgTopLeft.top,
                      rotateDegree
                    );

                    const reader = new FileReader();
                    reader.onload = function () {
                      const result = reader.result as string;
                      const base64 = result.replace(/.*base64,/, "");
                      socketInstance.sendMessage("WHITEBOARD_ACTION", {
                        tool: "drawImgToCanvas",
                        base64: base64,
                        width: PixelToPercentageForWidth(size.x),
                        height: PixelToPercentageForHeight(size.y),
                        left: PixelToPercentageForWidth(backImgTopLeft.left),
                        top: PixelToPercentageForHeight(backImgTopLeft.top),
                        rotationAngle: rotateDegree,
                        fileExtension: fileExtension,
                      });
                    };
                    reader.readAsDataURL(uploadFiles);
                  }}
                >
                  Draw to canvas
                </button>
                <button
                  style={{
                    margin: "0px 0px",
                    background: "#03a9f4",
                    padding: "5px",
                    marginTop: "3px",
                    color: "white",
                  }}
                  className="addToBackgroundBtn btn btn-default"
                  onClick={(e) => {
                    const fileExtensionArray = uploadFiles.name.split(".");
                    let fileExtension =
                      fileExtensionArray[fileExtensionArray.length - 1];

                    drawImgToBackground(
                      backgroundImage[0].img,
                      size.x,
                      size.y,
                      backImgTopLeft.left,
                      backImgTopLeft.top,
                      rotateDegree
                    );

                    const reader = new FileReader();
                    reader.onload = function () {
                      const result = reader.result as string;
                      const base64 = result.replace(/.*base64,/, "");
                      socketInstance.sendMessage("WHITEBOARD_ACTION", {
                        tool: "drawImgToBackground",
                        base64: base64,
                        width: PixelToPercentageForWidth(size.x),
                        height: PixelToPercentageForHeight(size.y),
                        left: PixelToPercentageForWidth(backImgTopLeft.left),
                        top: PixelToPercentageForHeight(backImgTopLeft.top),
                        rotationAngle: rotateDegree,
                        fileExtension: fileExtension,
                      });
                    };
                    reader.readAsDataURL(uploadFiles);
                  }}
                >
                  Add to background
                </button>
                <button
                  style={{
                    margin: "0px 0px",
                    background: "#03a9f4",
                    padding: "5px",
                    marginTop: "3px",
                    color: "white",
                  }}
                  className="xCanvasBtn btn btn-default"
                  onClick={(e) => {
                    setBackgroundImage([]);
                  }}
                >
                  x
                </button>
              </div>

              <FontAwesomeIcon
                icon={faSortDown}
                style={{
                  position: "absolute",
                  bottom: "-4px",
                  right: "2px",
                  fontSize: "2em",
                  color: "gray",
                  transform: "rotate(-45deg)",
                  cursor: "se-resize",
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  setResizeValue("img");
                  resizeMouseDown(e);
                }}
                onMouseMove={(e) => {
                  e.stopPropagation();

                  resizeMouseMove(e);
                }}
                onMouseUp={(e) => {
                  e.stopPropagation();
                  resizeMouseUp();
                }}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                }}
                onDrag={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDragEnd={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDragStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />

              <div
                className="rotationHandle"
                style={{
                  position: "absolute",
                  bottom: "-30px",
                  left: "0px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faUndo}
                  style={{ cursor: "grab" }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    rotateMouseDown(e);
                  }}
                  onMouseUp={(e) => {
                    e.stopPropagation();
                    rotateMouseUp(e);
                  }}
                  onDrag={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDragEnd={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDragStart={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default React.memo(Board);
