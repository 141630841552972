import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getAllBackgroundImages = async (company: string) => {
  const reqBody = qs.stringify({
    company,
  });
  const allBackgroundImage = await request.post(
    "/getAllBackgroundImageFe",
    reqBody,
    config
  );
  return allBackgroundImage.data;
};
