import { OPEN_MODAL, CLOSE_MODAL,CLEAR_REDUX_DATA } from "../actions/actionTypes";

const initialState = {
  isOpen: false,
  current_username: "",
};
interface IAction {
  type: string;
  current_username: string;
  payload: object;
}

const modalReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case OPEN_MODAL: {
      if (action.current_username){
      return {
        ...state,
        isOpen: true,
        current_username: action.current_username,
      };
    }
    else{
      return {
        ...state,
        isOpen: true,
      };
    }
  }
    case CLOSE_MODAL: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case CLEAR_REDUX_DATA:{
      return initialState
    }
    default: {
      return state;
    }
  }
};

export default modalReducer;
