import { Dispatch } from "react";
import {
  CLEAR_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_END_TIME,
  SET_NOTIFICATIONS_FOR_SINGLE,
} from "../actions/actionTypes";
// import { toast } from "react-toastify";

export const addNotifications = (
  numfaces: Number,
  startTime: Number,
  endTime: Number,
  msg: string,
  username: string,
  notiType: string,
  fullname: string,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_NOTIFICATIONS,
      payload: { numfaces, startTime, endTime, msg, username, notiType, fullname },
    });
  };
};

export const addNotifyEndTime = (
  endTime: Number,
  username: string,
  notiType: string
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_NOTIFICATIONS_END_TIME,
      payload: { endTime, username, notiType },
    });
  };
};

export const addNotificationsUsingSingle = (
  startTime: Number,
  msg: string,
  username: string,
  notiType: string,
  fullname:string,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_NOTIFICATIONS_FOR_SINGLE,
      payload: { startTime, msg, username, notiType,fullname },
    });
  };
};

export const clearNotificationsAlert = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: CLEAR_NOTIFICATIONS,
    });
  };
};
