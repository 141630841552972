import React, { useEffect, useState, useRef } from "react";
import RemotePeer from "../../Pages/RoomPage/RemotePeer";
import Tab from "../tabs/Tab";
// import Tabs from "./../tabs";
import Participants from "../Participants";
import Chat from "./../Chat";
import "./sidebar.css";
import Notifications from "../Notifications";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import TempRoomModal from "./tempRoomModal";
import { clearNotificationsAlert } from "../../actions/notificationActions";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ReactSplit, { SplitDirection } from "@devbookhq/splitter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faComment,

  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { saveLayoutSettingActions } from "../../actions/layoutSettingActions";
// import ReactSplitPane from 'react-split-pane';
// import 'react-split-pane/dist/react-split-pane.css';
import { getLayoutSettingActions } from "../../actions/layoutSettingActions";
import { CLEAR_LAYOUT_SETTING } from "../../actions/actionTypes";
import Point from "../../utils/Point";

// import { saveChatSpecificationAction, getChatSpecificationAction } from "../../actions/chatSpecificationAction";

interface ISideBarProps {
  peers: RemotePeer[];
  handRaisedPeer: {};
  performRightShiftByOne: (id: string) => void;
  makePrivateCall: (id: any) => void;
  roomname: string;
  current_id: string;
  expression: string;
  isPrivateCallOn: boolean;
  openentCallerPerson: string;
  isCallModal: boolean;
  refreshChat: boolean;
  localAudioPaused: boolean;
  objectDetect: string;
  openentObserveeAudioId: string;
  audioAllowedInRoom: boolean | undefined;
  isSideBarOpen: boolean;
  isScreenAudioShared: boolean;
  presenter: string;
  allowPrivateCallinSilentRoom: boolean | undefined;
  handleHandraise:(peerSessionId:string) => void;
}

interface PopopSize {
  height: number;
  width: number;
}

interface ModalLayout {
  isOpen: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
}
interface LayoutDatatype {
  username: string;
  resizeValues: Array<any>;
  chatModal: ModalLayout;
  alertModal: ModalLayout;
  participantModal: ModalLayout;
  isSideBarOpen: boolean;
}

let layoutObject: LayoutDatatype = {
  username: "",
  resizeValues: [],
  chatModal: {
    isOpen: false,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  alertModal: {
    isOpen: false,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  participantModal: {
    isOpen: false,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  isSideBarOpen: true,
};

const Sidebar = (props: ISideBarProps) => {
  const { peers = [] } = props;
  // const role = useSelector((state: RootState) => state.userDetails.role);

  const breakParticipant = useSelector(
    (state: RootState) => state.tempRoomUserReducer.breakParticipant
  );

  const userName = useSelector(
    (state: RootState) => state.userDetails.userName
  );

  const layoutData = useSelector(
    (state: any) => state.layoutReducer.layoutSettings
  );

  // const chatSettings = useSelector((state:RootState) => state.chatSpecificationReducer.settings);

  // const layoutObject = layoutData[0];
  const dispatch = useDispatch();
  const [valueObject, setValueObject] =
    React.useState<LayoutDatatype>(layoutObject);
  const [alertChat, setAlertChat] = useState<boolean>(false);
  const [showRoomModal, setShowRoomModal] = useState<boolean>(false);
  const [showChatModal, setShowChatModal] = useState<boolean>(false);
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const [showParticipantModal, setShowParticipantModal] =
    useState<boolean>(false);
  const [resizeBoolean, setResizeBoolean] = useState(false);
  const [resizeValue, setResizeValue] = useState("");
  const chatModalRef = useRef<HTMLDivElement>(null);
  const alertModalRef = useRef<HTMLDivElement>(null);
  const participantModalRef = useRef<HTMLDivElement>(null);
  const valueObjectRefCheck = useRef(valueObject);
  const [totalMessageCount, setTotalMessageCount] = useState<number>(0);
  const [hasMessagesBeenSent, setHasMessagesBeenSent] = useState(false);
  const initalModalConf = {
    height: 400,
    width: 400,
  };













  const [chatConf, setChatConf] = React.useState<PopopSize>(initalModalConf);
  const [chatPosition, setChatPosition] = React.useState({
    top: 100,
    left: 100,
  });
  const [alertPosition, setAlertPosition] = React.useState({
    top: 120,
    left: 110,
  });
  const [participantPosition, setParticipantPosition] = React.useState({
    top: 130,
    left: 120,
  });
  const [alertConf, setAlertConf] = React.useState<PopopSize>(initalModalConf);
  const [participantConf, setParticipantConf] =
    React.useState<PopopSize>(initalModalConf);

  // Width in percentage
  const PixelToPercentageForWidth = (num: number) => {
    return ((num * 100) / window.innerWidth).toFixed(1);
  };

  // height in percentage
  const PixelToPercentageForHeight = (num: number) => {
    return ((num * 100) / window.innerHeight).toFixed(1);
  };

  // Resize Tabs Initial Values
  const [sizes, setSizes] = useState([
    (1 / 3) * 100,
    (1 / 3) * 100,
    (1 / 3) * 100,
  ]);

  // Setting the Resize Values
  function handleResize(gutterIdx: number, allSizes: number[]) {
    if (alertChat && allSizes[0] > 25) {
      setAlertChat(false);
    }

    const values = resetValuesOfTabs(sizes);
    const test = [allSizes[0], allSizes[1], 100 - (allSizes[0] + allSizes[1])];
    if (values === 100) {
      setSizes(test);
    } else {
      setSizes(test);
    }
  }


  // const iconClass = "fa-solid fa-arrow-up-right-from-square";

  // Setting values of Sidebar true/false
  useEffect(() => {
    setValueObject((prev) => ({
      ...prev,
      isSideBarOpen: props.isSideBarOpen,
    }));
  }, [props.isSideBarOpen]);

  useEffect(() => {
    return () => {
      dispatch(clearNotificationsAlert());
    };
  }, [dispatch]);

  const tabChat = {
    title: "Chat",
    className: "chat-content",
  };

  const tabAlerts = {
    title: "Alerts",
    className: "alert-content",
  };

  const tabParticipants = {
    title: "Participants",
    className: "participants-content",
  };

  /// Popup Handlers
  const handleChatPopup = () => {
    setShowChatModal(!showChatModal);
  };

  const handleAlertPopup = () => {
    setShowAlertModal(!showAlertModal);
  };

  const handleParticipantPopup = () => {
    setShowParticipantModal(!showParticipantModal);
  };


  // Save Layout on UnMount + beforeunload Event Configuration
  useEffect(() => {
    return () => {
      dispatch({
        type: CLEAR_LAYOUT_SETTING,
      });
      dispatch(
        saveLayoutSettingActions(userName, "room", valueObjectRefCheck.current)
      );
    };
  }, [dispatch, userName]);

  // Caching the Value of Value object for component unmount
  useEffect(() => {
    valueObjectRefCheck.current = valueObject;
  }, [valueObject]);

  // Get settings on Initial Render
  useEffect(() => {
    dispatch(getLayoutSettingActions(userName, "room"));
  }, [dispatch, userName]);

  // Setting the Values of Layout Settings saved
  useEffect(() => {
    if (layoutData) {
      setValueObject((prev) => ({
        ...prev,
        username:
          layoutData && layoutData.data ? layoutData.username : userName,
        resizeValues:
          layoutData && layoutData.data ? layoutData.data.resizeValues : [],
        chatModal: {
          ...prev.chatModal,
          isOpen:
            layoutData && layoutData.data
              ? layoutData.data.chatModal.isOpen
              : false,
          x: layoutData && layoutData.data ? layoutData.data.chatModal.x : 0,
          y: layoutData && layoutData.data ? layoutData.data.chatModal.y : 0,
          width:
            layoutData && layoutData.data ? layoutData.data.chatModal.width : 0,
          height:
            layoutData && layoutData.data
              ? layoutData.data.chatModal.height
              : 0,
        },
        alertModal: {
          ...prev.alertModal,
          isOpen:
            layoutData && layoutData.data
              ? layoutData.data.alertModal.isOpen
              : false,
          x: layoutData && layoutData.data ? layoutData.data.alertModal.x : 0,
          y: layoutData && layoutData.data ? layoutData.data.alertModal.y : 0,
          width:
            layoutData && layoutData.data
              ? layoutData.data.alertModal.width
              : 0,
          height:
            layoutData && layoutData.data
              ? layoutData.data.alertModal.height
              : 0,
        },
        participantModal: {
          ...prev.participantModal,
          isOpen:
            layoutData && layoutData.data
              ? layoutData.data.participantModal.isOpen
              : false,
          x:
            layoutData && layoutData.data
              ? layoutData.data.participantModal.x
              : 0,
          y:
            layoutData && layoutData.data
              ? layoutData.data.participantModal.y
              : 0,
          width:
            layoutData && layoutData.data
              ? layoutData.data.participantModal.width
              : 0,
          height:
            layoutData && layoutData.data
              ? layoutData.data.participantModal.height
              : 0,
        },
      }));
      if (layoutData.data) {
        if (
          layoutData.data.resizeValues &&
          layoutData.data.resizeValues.length > 0
        ) {
          const sum = resetValuesOfTabs(layoutData.data.resizeValues);

          if (sum === 100) {
            setSizes(layoutData.data.resizeValues);
          } else {
            let values = [
              layoutData.data.resizeValues[0],
              layoutData.data.resizeValues[1],
              100 -
              (layoutData.data.resizeValues[0] +
                layoutData.data.resizeValues[1]),
            ];
            setSizes(values);
          }
        }
        setShowChatModal(layoutData.data.chatModal.isOpen);
        setShowAlertModal(layoutData.data.alertModal.isOpen);
        setShowParticipantModal(layoutData.data.participantModal.isOpen);
      }
    }
  }, [layoutData, userName]);

  // Positioning and Conf. of Participant Tab
  useEffect(() => {
    if (
      layoutData &&
      layoutData.data &&
      participantModalRef &&
      participantModalRef.current &&
      layoutData.data.participantModal.isOpen
    ) {
      // @ts-ignore
      participantModalRef.current.parentElement.parentElement.style.top =
        layoutData.data.participantModal.y
          ? `${PixelToPercentageForHeight(layoutData.data.participantModal.y)}%`
          : `${PixelToPercentageForHeight(participantPosition.top)}%`;
      // @ts-ignore
      participantModalRef.current.parentElement.parentElement.style.left =
        layoutData.data.participantModal.x
          ? `${PixelToPercentageForWidth(layoutData.data.participantModal.x)}%`
          : `${PixelToPercentageForWidth(participantPosition.left)}%`;

      const participantModalParent = participantModalRef.current.parentElement;
      if (participantModalParent) {
        const grandParent = participantModalParent.parentElement;
        if (grandParent) {
          const width = layoutData.data.participantModal.width
            ? `${layoutData.data.participantModal.width}px`
            : `${participantConf.width}px`;
          grandParent.style.width = width;

          const height = layoutData.data.participantModal.height
            ? `${layoutData.data.participantModal.height}px`
            : `${participantConf.height}px`;
          grandParent.style.height = height;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutData, participantModalRef.current]);

  // Positioning and Conf. of Alert Tab
  useEffect(() => {
    if (
      layoutData &&
      layoutData.data &&
      alertModalRef &&
      alertModalRef.current &&
      layoutData.data.alertModal.isOpen
    ) {
      // @ts-ignore
      alertModalRef.current.parentElement.parentElement.style.top = layoutData
        .data.alertModal.y
        ? `${PixelToPercentageForHeight(layoutData.data.alertModal.y)}%`
        : `${PixelToPercentageForHeight(alertPosition.top)}%`;
      // @ts-ignore
      alertModalRef.current.parentElement.parentElement.style.left = layoutData
        .data.alertModal.x
        ? `${PixelToPercentageForWidth(layoutData.data.alertModal.x)}%`
        : `${PixelToPercentageForWidth(alertPosition.left)}%`;

      const alertModalParent = alertModalRef.current.parentElement;
      if (alertModalParent) {
        const grandParent = alertModalParent.parentElement;
        if (grandParent) {
          const width = layoutData.data.alertModal.width
            ? `${layoutData.data.alertModal.width}px`
            : `${alertConf.width}px`;
          grandParent.style.width = width;

          const height = layoutData.data.alertModal.height
            ? `${layoutData.data.alertModal.height}px`
            : `${alertConf.height}px`;
          grandParent.style.height = height;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutData, alertModalRef.current]);

  //  Positioning and Conf. of Chat Tab
  useEffect(() => {
    if (
      layoutData &&
      layoutData.data &&
      chatModalRef &&
      chatModalRef.current &&
      layoutData.data.chatModal.isOpen
    ) {
      // @ts-ignore
      chatModalRef.current.parentElement.parentElement.style.top = layoutData
        .data.chatModal.y
        ? `${PixelToPercentageForHeight(layoutData.data.chatModal.y)}%`
        : `${PixelToPercentageForHeight(chatPosition.top)}%`;
      // @ts-ignore
      chatModalRef.current.parentElement.parentElement.style.left = layoutData
        .data.chatModal.x
        ? `${PixelToPercentageForWidth(layoutData.data.chatModal.x)}%`
        : `${PixelToPercentageForWidth(chatPosition.left)}%`;

      const chatModalParent = chatModalRef.current.parentElement;
      if (chatModalParent) {
        const grandParent = chatModalParent.parentElement;
        if (grandParent) {
          const width = layoutData.data.chatModal.width
            ? `${layoutData.data.chatModal.width}px`
            : `${chatConf.width}px`;
          grandParent.style.width = width;

          const height = layoutData.data.chatModal.height
            ? `${layoutData.data.chatModal.height}px`
            : `${chatConf.height}px`;
          grandParent.style.height = height;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutData, chatModalRef.current]);

  // Handling onChange Values of Participant Tab width and height
  useEffect(() => {
    setValueObject((prev) => ({
      ...prev,
      participantModal: {
        ...prev.participantModal,
        width: participantConf.width,
        height: participantConf.height,
      },
    }));
  }, [participantConf.width, participantConf.height]);

  // Handling onChange Values of Alert Tab width and height
  useEffect(() => {
    setValueObject((prev) => ({
      ...prev,
      alertModal: {
        ...prev.alertModal,
        width: alertConf.width,
        height: alertConf.height,
      },
    }));
    // console.log(window, window.innerHeight, alertConf.height);
  }, [alertConf.width, alertConf.height]);

  // Handling onChange Values of Chat Tab width and height
  useEffect(() => {
    setValueObject((prev) => ({
      ...prev,
      chatModal: {
        ...prev.chatModal,
        width: chatConf.width,
        height: chatConf.height,
      },
    }));
  }, [chatConf.width, chatConf.height]);

  // Show Chat Modal Modal Listener
  useEffect(() => {
    setValueObject((prev) => ({
      ...prev,
      chatModal: {
        ...prev.chatModal,
        isOpen: showChatModal,
      },
    }));
    if (chatModalRef && chatModalRef.current && showChatModal) {
      // @ts-ignore
      chatModalRef.current.parentElement.parentElement.style.top =
        PixelToPercentageForHeight(chatPosition.top);
      // @ts-ignore
      chatModalRef.current.parentElement.parentElement.style.left =
        PixelToPercentageForWidth(chatPosition.left);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChatModal]);

  // Show Alert Modal Modal Listener
  useEffect(() => {
    setValueObject((prev) => ({
      ...prev,

      alertModal: {
        ...prev.alertModal,
        isOpen: showAlertModal,
      },
    }));
    if (alertModalRef && alertModalRef.current && showAlertModal) {
      // @ts-ignore
      alertModalRef.current.parentElement.parentElement.style.top =
        PixelToPercentageForHeight(alertPosition.top);
      // @ts-ignore
      alertModalRef.current.parentElement.parentElement.style.left =
        PixelToPercentageForWidth(alertPosition.left);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlertModal]);

  // Show Participant Modal Modal Listener
  useEffect(() => {
    setValueObject((prev) => ({
      ...prev,

      participantModal: {
        ...prev.participantModal,
        isOpen: showParticipantModal,
      },
    }));
    if (
      participantModalRef &&
      participantModalRef.current &&
      showParticipantModal
    ) {
      // @ts-ignore
      participantModalRef.current.parentElement.parentElement.style.top =
        PixelToPercentageForHeight(participantPosition.top);
      // @ts-ignore
      participantModalRef.current.parentElement.parentElement.style.left =
        PixelToPercentageForWidth(participantPosition.left);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showParticipantModal]);

  // Resize Values Useeffect
  useEffect(() => {
    let size = [33.33333333333333, 33.33333333333333, 33.33333333333333];

    if (sizes !== size) {
      setValueObject((prev) => ({
        ...prev,
        resizeValues: sizes,
      }));
    }
  }, [sizes]);

  // check to start Resize
  const resizeMouseDown = (e: any) => {
    setResizeBoolean(true);
  };

  const resizeMouseMove = (e: any) => {
    if (resizeBoolean) {
      if (resizeValue === "chat") {
        setChatConf((currentSize) => ({
          width:
            currentSize.width + e.movementX > window.innerWidth
              ? window.innerWidth - 100
              : currentSize.width + e.movementX,
          height:
            currentSize.height + e.movementY > window.innerHeight
              ? window.innerHeight - 100
              : currentSize.height + e.movementY,
        }));
      } else if (resizeValue === "alert") {
        setAlertConf((currentSize) => ({
          width:
            currentSize.width + e.movementX > window.innerWidth
              ? window.innerWidth - 100
              : currentSize.width + e.movementX,
          height:
            currentSize.height + e.movementY > window.innerHeight
              ? window.innerHeight - 100
              : currentSize.height + e.movementY,
        }));
      } else if (resizeValue === "participant") {
        setParticipantConf((currentSize) => ({
          width:
            currentSize.width + e.movementX > window.innerWidth
              ? window.innerWidth - 100
              : currentSize.width + e.movementX,
          height:
            currentSize.height + e.movementY > window.innerHeight
              ? window.innerHeight - 100
              : currentSize.height + e.movementY,
        }));
      }
    }
  };

  const resetValuesOfTabs = (arr: number[]) => {
    const sum = Math.round(
      arr.reduce((acc: any, next: any) => {
        return acc + next;
      }, 0)
    );
    return sum;
  };

  const resizeMouseUp = () => {
    setResizeBoolean(false);
    setResizeValue("");
  };

  const mainMouseMove = (e: MouseEvent) => {
    if (resizeBoolean) {
      resizeMouseMove(e);
    }
  };
  const mainMouseUp = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    resizeMouseUp();
  };
  const mainMouseLeave = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    resizeMouseUp();
  };

  useEffect(() => {
    let mainroomdiv = document.getElementById("whole-room");
    if (resizeBoolean) {
      mainroomdiv?.addEventListener("mousemove", mainMouseMove);
      mainroomdiv?.addEventListener("mouseup", mainMouseUp);
      mainroomdiv?.addEventListener("mouseleave", mainMouseLeave);
    }
    return () => {
      mainroomdiv?.removeEventListener("mousemove", mainMouseMove);
      mainroomdiv?.removeEventListener("mouseup", mainMouseUp);
      mainroomdiv?.removeEventListener("mouseleave", mainMouseLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizeBoolean]);

  function chatDrag(e: any) {
    setChatPosition({
      top: Point.fromEvent(e as any, "whole-room").y,
      left: Point.fromEvent(e as any, "whole-room").x,
    });

    setValueObject((prev) => ({
      ...prev,
      chatModal: {
        ...prev.chatModal,
        y: Point.fromEvent(e as any, "whole-room").y,
        x: Point.fromEvent(e as any, "whole-room").x,
      },
    }));
  }

  function alertDrag(e: any) {
    setAlertPosition({
      top: Point.fromEvent(e as any, "whole-room").y,
      left: Point.fromEvent(e as any, "whole-room").x,
    });

    setValueObject((prev) => ({
      ...prev,
      alertModal: {
        ...prev.alertModal,
        y: Point.fromEvent(e as any, "whole-room").y,
        x: Point.fromEvent(e as any, "whole-room").x,
      },
    }));
  }

  function participantDrag(e: any) {
    setParticipantPosition({
      top: Point.fromEvent(e as any, "whole-room").y,
      left: Point.fromEvent(e as any, "whole-room").x,
    });

    setValueObject((prev) => ({
      ...prev,
      participantModal: {
        ...prev.participantModal,
        y: Point.fromEvent(e as any, "whole-room").y,
        x: Point.fromEvent(e as any, "whole-room").x,
      },
    }));
  }

  console.log({})

  return (
    <div
      className="sidebar-container"
      style={{ width: props.refreshChat ? "auto" : "0" }}
    >
      {showChatModal && (
        <div
          className="modal_cont chat_modal_container"
          id="chat-modal"
          style={{
            width: chatConf.width,
            height: chatConf.height,
            top: `${chatPosition.top}px`,
            left: `${chatPosition.left}px`,
          }}
          onMouseMove={(e) => {
            e.stopPropagation();
            resizeMouseMove(e);
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => {
            e.stopPropagation();
            resizeMouseUp();
          }}
        >
          <div className="popup_header" style={{ height: "30px" }}>
            {/* <p className="chat_btn" > */}
            <p
              className="chat_btn"
              draggable="true"
              onDragEnd={(e) => chatDrag(e)}
              onDrag={(e) => {
                setChatPosition({
                  top: Point.fromEvent(e as any, "whole-room").y,
                  left: Point.fromEvent(e as any, "whole-room").x,
                });
              }}
              ref={chatModalRef}
            >
              <FontAwesomeIcon icon={faBars} />
            </p>
            <p>Chat {hasMessagesBeenSent && totalMessageCount !== 0 && <FontAwesomeIcon
              icon={faComment}
              color="red"
              style={{
                fontSize: "1em",
                position: "relative",
                left: "10px",
              }}
            />} </p>
            <p className="cross_icon" onClick={() => setShowChatModal(false)}>
              X
            </p>
          </div>
          <div style={{ height: "91%", backgroundPositionY: "bottom" }}>
            <Chat
              peers={peers}
              refreshChat={props.refreshChat}
              setAlertChat={setAlertChat}
              check={"pop"}
              setTotalMessageCount={setTotalMessageCount}
              setHasMessagesBeenSent={setHasMessagesBeenSent}
            />
          </div>
          <FontAwesomeIcon
            icon={faSortDown}
            style={{
              position: "absolute",
              bottom: "0px",
              right: "2px",
              fontSize: "2em",
              color: "gray",
              transform: "rotate(-45deg)",
              cursor: "se-resize",
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              setResizeValue("chat");
              resizeMouseDown(e);
            }}
            onMouseMove={(e) => {
              e.stopPropagation();
              resizeMouseMove(e);
            }}
            onMouseUp={(e) => {
              e.stopPropagation();
              resizeMouseUp();
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
            }}
            onDrag={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragEnd={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </div>
      )}

      {showAlertModal && (
        <div
          className="modal_cont alert_modal_container"
          id="alert-modal"
          style={{
            width: alertConf.width,
            height: alertConf.height,
            top: `${alertPosition.top}px`,
            left: `${alertPosition.left}px`,
          }}
          onMouseMove={(e) => {
            e.stopPropagation();

            resizeMouseMove(e);
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => {
            e.stopPropagation();
            resizeMouseUp();
          }}
        >
          <div className="popup_header" style={{ height: "30px" }}>
            {/* <p className="alert_btn" > */}
            <p
              className="alert_btn"
              draggable="true"
              onDragEnd={(e) => alertDrag(e)}
              onDrag={(e) => {
                setAlertPosition({
                  top: Point.fromEvent(e as any, "whole-room").y,
                  left: Point.fromEvent(e as any, "whole-room").x,
                });
              }}
              ref={alertModalRef}
            >
              <FontAwesomeIcon icon={faBars} />
            </p>
            <p>
              Alert <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </p>
            <p className="cross_icon" onClick={() => setShowAlertModal(false)}>
              x
            </p>
          </div>
          <div style={{ height: "91%", backgroundPositionY: "bottom" }}>
            <Notifications />
          </div>
          <FontAwesomeIcon
            icon={faSortDown}
            style={{
              position: "absolute",
              bottom: "0px",
              right: "2px",
              fontSize: "2em",
              color: "gray",
              transform: "rotate(-45deg)",
              cursor: "se-resize",
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              setResizeValue("alert");
              resizeMouseDown(e);
            }}
            onMouseMove={(e) => {
              e.stopPropagation();
              resizeMouseMove(e);
            }}
            onMouseUp={(e) => {
              e.stopPropagation();
              resizeMouseUp();
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
            }}
            onDrag={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragEnd={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </div>
      )}

      {showParticipantModal && (
        <div
          className="modal_cont participant_modal_container"
          id="participant-modal"
          style={{
            width: participantConf.width,
            height: participantConf.height,
            top: `${participantPosition.top}px`,
            left: `${participantPosition.left}px`,
          }}
          onMouseMove={(e) => {
            e.stopPropagation();

            resizeMouseMove(e);
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => {
            e.stopPropagation();
            resizeMouseUp();
          }}
        >
          <div className="popup_header" style={{ height: "30px" }}>
            <p
              className="participant_btn"
              draggable="true"
              onDragEnd={(e) => participantDrag(e)}
              onDrag={(e) => {
                setParticipantPosition({
                  top: Point.fromEvent(e as any, "whole-room").y,
                  left: Point.fromEvent(e as any, "whole-room").x,
                });
              }}
              ref={participantModalRef}
            >
              <FontAwesomeIcon icon={faBars} />
            </p>
            <p>
              Participant{" "}
              <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </p>
            <p
              className="cross_icon"
              onClick={() => setShowParticipantModal(false)}
            >
              x
            </p>
          </div>
          <div style={{ height: "91%", backgroundPositionY: "bottom" }}>
            <Participants
              peers={peers}
              handRaisedPeer={props.handRaisedPeer}
              performRightShiftByOne={props.performRightShiftByOne}
              makePrivateCall={props.makePrivateCall}
              roomname={props.roomname}
              isPrivateCallOn={props.isPrivateCallOn}
              openentCallerPerson={props.openentCallerPerson}
              isCallModal={props.isCallModal}
              localAudioPaused={props.localAudioPaused}
              objectDetect={props.objectDetect}
              breakParticipant={breakParticipant}
              handleShow={setShowRoomModal}
              show={showRoomModal}
              openentObserveeAudioId={props.openentObserveeAudioId}
              audioAllowedInRoom={props.audioAllowedInRoom}
              isScreenAudioShared={props.isScreenAudioShared}
              presenter={props.presenter}
              allowPrivateCallinSilentRoom={props.allowPrivateCallinSilentRoom}
              handleHandraise={props.handleHandraise}
            />
          </div>
          <FontAwesomeIcon
            icon={faSortDown}
            style={{
              position: "absolute",
              bottom: "0px",
              right: "2px",
              fontSize: "2em",
              color: "gray",
              transform: "rotate(-45deg)",
              cursor: "se-resize",
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              setResizeValue("participant");
              resizeMouseDown(e);
            }}
            onMouseMove={(e) => {
              e.stopPropagation();
              resizeMouseMove(e);
            }}
            onMouseUp={(e) => {
              e.stopPropagation();
              resizeMouseUp();
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
            }}
            onDrag={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragEnd={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </div>
      )}

      <Accordion defaultActiveKey="0" className="accordion_container">
        <ReactSplit
          direction={SplitDirection.Vertical}
          onResizeFinished={handleResize}
          initialSizes={sizes}
        >
          <Card className="collapsible-card card_container card_chat_container">
            <Accordion.Collapse
              eventKey="0"
              className="options_heading"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <>
                <p style={{ position: "relative" }}>
                  Chat{" "}
                  <svg
                    onClick={handleChatPopup}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                  </svg>


                </p>
                {alertChat ? (
                  <div>
                    <FontAwesomeIcon
                      icon={faComment}
                      color="red"
                      style={{
                        fontSize: "1em",
                        position: "relative",
                        left: "10px",
                      }}
                    />
                  </div>
                ) : null}
              </>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="0" className="acc_collapse">
              <Tab {...tabChat}>
                <Chat
                  peers={peers}
                  refreshChat={props.refreshChat}
                  setAlertChat={setAlertChat}
                  check={"tab"}
                  setTotalMessageCount={setTotalMessageCount}
                  setHasMessagesBeenSent={setHasMessagesBeenSent}
                />
              </Tab>
            </Accordion.Collapse>
          </Card>
          <Card className="collapsible-card card_container card_alert_container">
            <Accordion.Collapse eventKey="1" className="options_heading">
              <p>
                Alerts{" "}
                <svg
                  onClick={handleAlertPopup}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                </svg>
              </p>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1" className="alert_collapse">
              <Tab {...tabAlerts}>
                <Notifications />
              </Tab>
            </Accordion.Collapse>
          </Card>
          <Card
            className="collapsible-card card_container card_participant_container"
            style={{}}
          >
            <Accordion.Collapse eventKey="0" className="options_heading">
              <p>
                Participants{" "}
                <svg
                  onClick={handleParticipantPopup}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                </svg>
              </p>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="0" className="participant_collapse">
              <Tab {...tabParticipants}>
                <Participants
                  peers={peers}
                  handRaisedPeer={props.handRaisedPeer}
                  performRightShiftByOne={props.performRightShiftByOne}
                  makePrivateCall={props.makePrivateCall}
                  roomname={props.roomname}
                  isPrivateCallOn={props.isPrivateCallOn}
                  openentCallerPerson={props.openentCallerPerson}
                  isCallModal={props.isCallModal}
                  localAudioPaused={props.localAudioPaused}
                  objectDetect={props.objectDetect}
                  breakParticipant={breakParticipant}
                  handleShow={setShowRoomModal}
                  show={showRoomModal}
                  openentObserveeAudioId={props.openentObserveeAudioId}
                  audioAllowedInRoom={props.audioAllowedInRoom}
                  isScreenAudioShared={props.isScreenAudioShared}
                  presenter={props.presenter}
                  allowPrivateCallinSilentRoom={props.allowPrivateCallinSilentRoom}
                  handleHandraise={props.handleHandraise}
                />
              </Tab>
            </Accordion.Collapse>
          </Card>
        </ReactSplit>
      </Accordion>

      <TempRoomModal
        show={showRoomModal}
        handleShow={setShowRoomModal}
        participantList={breakParticipant}
        roomname={props.roomname}
      />
    </div>
  );
};
export default Sidebar;
