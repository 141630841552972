import { Dispatch } from "react";
import { SET_TROUBLESHOOT } from "./actionTypes";

export const setTroubleshootAction = (troubleshoot: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_TROUBLESHOOT,
      payload: { troubleshoot },
    });
  };
};
