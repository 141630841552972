import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import { saveLayoutSetting, getLayoutSetting } from "../services/saveLayout";

import {
  SAVE_LAYOUT_SETTING_FAILURE,
  SAVE_LAYOUT_SETTING_SUCCESS,
  GET_LAYOUT_SETTING_FAILURE,
  GET_LAYOUT_SETTING_SUCCESS,
} from "./actionTypes";
import { toast } from "react-toastify";

export const getLayoutSettingActions = (username: string, type: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const getLayoutSettingResp = await getLayoutSetting(username, type);
      if (getLayoutSettingResp.status) {
        dispatch(setLayoutSetting(getLayoutSettingResp.settings));
      }
    } catch (err: any) {
      setLayoutSettingError(err.message);
    } finally {
      setLoading(false);
    }
  };
};

const setLayoutSetting = (settings: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_LAYOUT_SETTING_SUCCESS,
      payload: settings,
    });
  };
};

const setLayoutSettingError = (errorMessage: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_LAYOUT_SETTING_FAILURE,
      payload: errorMessage,
    });
  };
};

export const saveLayoutSettingActions = (
  username: string,
  type: string,
  data: any
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      if (data.username && data.resizeValues.length > 0) {
        const saveLayout = await saveLayoutSetting(username, type, data);

        if (saveLayout.status) {
          dispatch(savelayoutSettingSuccess(saveLayout.status));
          // toast("Layout Saved Successfully")
        } else {
          dispatch(saveLayoutSettingFailure(saveLayout.message));
          toast(saveLayout.message)
        }
      }
    } catch (err: any) {
      dispatch(saveLayoutSettingFailure(err.message));
      toast(err?.message)
    } finally {
      setLoading(false);
    }
  };
};

const savelayoutSettingSuccess = (isSaved: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SAVE_LAYOUT_SETTING_SUCCESS,
      payload: isSaved,
    });
  };
};

const saveLayoutSettingFailure = (msg: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SAVE_LAYOUT_SETTING_FAILURE,
      payload: msg,
    });
  };
};
