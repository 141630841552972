import {
  SET_ACTIVITY_CHANNEL_MODAL_MINIMIZING,
  CLEAR_REDUX_DATA,
  SET_ACTIVITY_CHANNEL_MODAL_OPEN_AND_CLOSE,
  SET_ACTIVITY_CHANNEL_PARENT_ID,
  CLEAR_ACTIVITY_CHANNEL,
  SET_UNREAD_POSTS_COUNT,
} from "../actions/actionTypes";

const initialState = {
  isActivityModalMinimize: false,
  isActivityModalOpen: false,
  parentId: "",
  unreadPostsCount: 0,
};
interface IAction {
  type: string;
  payload: any;
}

const activityChannelReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_ACTIVITY_CHANNEL_MODAL_MINIMIZING: {
      return {
        ...state,
        isActivityModalMinimize: action.payload,
      };
    }

    case SET_ACTIVITY_CHANNEL_MODAL_OPEN_AND_CLOSE: {
      return {
        ...state,
        isActivityModalOpen: action.payload,
      };
    }
    case SET_ACTIVITY_CHANNEL_PARENT_ID: {
      return {
        ...state,
        parentId: action.payload,
      };
    }
    case CLEAR_REDUX_DATA:
    case CLEAR_ACTIVITY_CHANNEL: {
      return initialState;
    }
    case SET_UNREAD_POSTS_COUNT: {
      return {
        ...state,
        unreadPostsCount: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default activityChannelReducer;
