import {
    GET_LOGO_SUCCESS,
    GET_LOGO_FAILURE,
    CLEAR_ADDLOGO_DATA
} from "../actions/actionTypes";

const initialState = {
    isLogoUpdated: false,
    logoUrl: "",
    error: "",
};
interface IAction {
    type: string;
    payload: any;
}

const logoReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case GET_LOGO_SUCCESS: {
            return {
                ...state,
                isLogoUpdated: true,
                logoUrl: action.payload.logoUrl
            };
        }
        case GET_LOGO_FAILURE: {
            return {
                ...state,
                isLogoUpdated: false,
                error: action.payload.error,
            };
        }
        case CLEAR_ADDLOGO_DATA:{
            return initialState
        }
        default: {
            return state;
        }
    }
};

export default logoReducer;
