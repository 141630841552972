import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import SocketServiceSingleton from "../../services/socket";
import "./index.css";
import {
  setTyperName,
  setFullMessageList,
  setSelectedChatUser,
  prependMessageList
} from "./../../actions/chatActions";
import NameAvatar from "./../NameAvatar";
// import Participants from "../Participants";
import RemotePeer from "../../Pages/RoomPage/RemotePeer";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import Chatinput from "./chatinput";
import { AGENT_TO_AGENT_CHAT } from "../../Pages/RoomPage/constants";
import { toast } from "react-toastify";
import moment from "moment";
import { timeZoneFormat } from "../../utils/datetimeformat";
import { socketConnectiontype } from "../../utils/constant";
import { playChatNotification, playMentionsNotification } from "../../utils/AudioVideoDeviceUtils";
import { getPersonalizationAction } from "../../actions/personalizationActions";
import { notifyMe } from "../../utils/desktopNotification";

const socketInstance = new SocketServiceSingleton(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);
// const AlwaysScrollToBottom = () => {
//   const elementRef = useRef();
//   console.log("Neeraj");
//   // @ts-ignore
//   useEffect(() => {
//     if (elementRef && elementRef.current) {
//       // @ts-ignore
//       elementRef.current.scrollIntoView({
//         // behavior: "smooth",
//         block: "nearest",
//         inline: "start",
//       });
//     }
//   }, []);
//   // @ts-ignore
//   return <div ref={elementRef} />;
// };

interface IChatProps {
  peers: RemotePeer[];
  refreshChat: boolean;
  setAlertChat: (value: any) => any;
  check: string;
  setTotalMessageCount: any;
  setHasMessagesBeenSent: any;
}

interface IpeerObj {
  peerSessionId: string | null;
  chatType: string;
  peerName: string | null;
  peerUserName: string | null;
}

interface IPeerListProps {
  peers: RemotePeer[];
  changeSelectedChatUser: any;
  selectedChatUser: IpeerObj;
  getChatHistory: (peerObj: IpeerObj) => void;
  senderMessageCounts: any;
  setSenderMessageCounts: any;
  updateMessageCount: any;
  setHasMessagesBeenSent: any;
}


const PeerList = (props: IPeerListProps) => {
  const environments = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  );
  const userRole = useSelector((state: RootState) => state.userDetails.role);
  // const permissions = useSelector((state:RootState) => state.chatData.permissions);
  const agentChatPermission = environments.AGENT_TO_AGENT_CHAT
    ? environments.AGENT_TO_AGENT_CHAT.status
    : AGENT_TO_AGENT_CHAT;
  const dispatch = useDispatch();
  const dropdownPeer = (
    peerSessionId: string | null,
    peerUserName: string | null,
    peerName: string | null,
    chatType: string
  ) => {
    // const peerValue = {
    //   peerSessionId: peerSessionId,
    //   peerUserName: peerUserName,
    //   peerName: peerName,
    //   chatType: chatType,
    // };

    dispatch(
      setSelectedChatUser(peerSessionId, peerUserName, peerName, chatType)
    );

    // props.getChatHistory(peerValue);
  };

  const { peers = [] } = props;



  // @ts-ignore
  return (
    <div>
      {/*<Dropdown  show={userRole==="Agent" && environments.AGENT_TO_AGENT_CHAT.status===false ? false : undefined}>*/}
      <Dropdown>
        <Dropdown.Toggle variant="success" id="menu-dropdown">
          {props.selectedChatUser.peerName
            ? props.selectedChatUser.peerName
            : "EVERYONE"}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-inner-container">
          <Dropdown.Item
            onClick={() => {
              props.updateMessageCount("EVERYONE", true);

              dropdownPeer(null, null, null, "EVERYONE");
            }}
          >
            EVERYONE
            {
              props.senderMessageCounts['EVERYONE'] > 0 ?
                <FontAwesomeIcon
                  icon={faComment}
                  color="red"
                  style={{
                    fontSize: "1em",
                    position: "relative",
                    left: "10px",
                  }}
                /> : ""
            }
          </Dropdown.Item>
          {agentChatPermission === false && userRole === "Agent"
            ? // eslint-disable-next-line array-callback-return
            peers.map((peer) => {
              if (peer.peerType !== "Agent") {
                return (
                  <Dropdown.Item
                    key={peer.peerSessionId}
                    onClick={() => {
                      props.updateMessageCount(peer.peerUsername, true);

                      dropdownPeer(
                        peer.peerSessionId,
                        peer.peerUsername,
                        peer.peerName,
                        "PRIVATE"
                      );
                    }}
                  >
                    {peer.peerName}
                    {
                      props.senderMessageCounts[peer.peerUsername] > 0 ?
                        <FontAwesomeIcon
                          icon={faComment}
                          color="red"
                          style={{
                            fontSize: "1em",
                            position: "relative",
                            left: "10px",
                          }}
                        /> : ""
                    }
                  </Dropdown.Item>
                );
              }
            })
            : peers.map((peer) => {
              return (
                <Dropdown.Item
                  key={peer.peerSessionId}
                  onClick={() => {
                    props.updateMessageCount(peer.peerUsername, true);

                    dropdownPeer(
                      peer.peerSessionId,
                      peer.peerUsername,
                      peer.peerName,
                      "PRIVATE"
                    );
                  }}
                >
                  {peer.peerName}

                  {
                    props.senderMessageCounts[peer.peerUsername] > 0 ?
                      <FontAwesomeIcon
                        icon={faComment}
                        color="red"
                        style={{
                          fontSize: "1em",
                          position: "relative",
                          left: "10px",
                        }}
                      /> : ""
                  }
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

// let chatContainer: any;

const Chat = (props: IChatProps) => {
  const { peers = [] } = props;
  const dispatch = useDispatch();
  const firstName = useSelector(
    (state: RootState) => state.userDetails.firstName
  );
  const lastName = useSelector(
    (state: RootState) => state.userDetails.lastName
  );
  const { format } = timeZoneFormat();

  let chatContainer = useRef<HTMLUListElement>(null);

  const userName = useSelector(
    (state: RootState) => state.userDetails.userName
  );

  const allMessages = useSelector((state: RootState) => state.chatData.messageList);
  const chatReconnectStatus = useSelector((state: RootState) => state.chatData.reconnect);


  const [loading] = useState(false);
  const [countMessage, setCountMessage] = useState(0);
  const [showMessageNotification, setShowMessageNotification] =
    useState<boolean>(false);
  const [firstTimeScrolling, setFirstTimeScrolling] = useState(true);
  const [scrollState, setScrollState] = useState<number>(0);

  const selectedChatUser = useSelector(
    (state: RootState) => state.chatData.selectedChatUser
  );
  const chatPersonalization = useSelector((state: RootState) => state.personalizationReducer.settings?.settings?.chat);
  const mentionsPersonalization = useSelector((state: RootState) => state.personalizationReducer.settings?.settings?.mentions)
  // const userData = useSelector((state: RootState) => state.userDetails);
  const chatData = useSelector((state:RootState) => state.chatData);

  const [senderMessageCounts, setSenderMessageCounts] = useState<{ [senderName: string]: number }>({});
  // const [messages, setMessages] = useState<any>([]);
  // const [selectedChatUser, changeSelectedChatUser] = useState({
  //   peerSessionId: null,
  //   chatType: "EVERYONE",
  //   peerName: null,
  //   peerUserName: null,
  // });
  useEffect(() => {


    // Calculate the sum of all message counts
    const sum = Object.values(senderMessageCounts).reduce((acc, count) => acc + count, 0);
    props.setTotalMessageCount(sum);
    if (sum === 0) {
      props.setHasMessagesBeenSent(false);
    }
  }, [props, senderMessageCounts]);


  const [scrollToBottom, setScrollToBottom] = useState(false);
  let timeout = useRef() as any;

  useEffect(() => {
    dispatch(getPersonalizationAction(userName))

  }, [dispatch, userName])
  const updateMessageCount = (senderName: string, resetToZero: boolean = false) => {
    if (resetToZero) {
      setSenderMessageCounts((prevCounts) => ({
        ...prevCounts,
        [senderName]: 0,
      }));
    } else {
      setSenderMessageCounts((prevCounts) => ({
        ...prevCounts,
        [senderName]: (prevCounts[senderName] || 0) + 1,
      }));
      props.setHasMessagesBeenSent(true);
    }
  };

  const changePeerChatUser = (chatDetails: any) => {
    dispatch(
      setSelectedChatUser(
        chatDetails.peerSessionId,
        chatDetails.peerUserName,
        chatDetails.peerName,
        chatDetails.chatType
      )
    );
  };

  const getChatHistory = async (chatDetails: any) => {
    if (chatDetails) {
      let messagesResponse;
      if (chatDetails.chatType === "PRIVATE") {
        messagesResponse = (await socketInstance.sendMessage(
          "GET_CHAT_HISTORY",
          {
            chatType: "PRIVATE",
          }
        )) as any;
      } else {
        messagesResponse = (await socketInstance.sendMessage(
          "GET_CHAT_HISTORY",
          {
            chatType: "EVERYONE",
          }
        )) as any;
      }
      const messageArray = messagesResponse.chatMessageArray;
      // @ts-ignore
      let messageList = [];
      if (messageArray.length) {
        messageList = messageArray.map(
          // @ts-ignore
          (messageObj: string) => {
            const parseMessageObj = JSON.parse(messageObj);
            return parseMessageObj;
          }
        );
      }
      dispatch(setFullMessageList(messageList));
    }
  };

  const getLimitedChatHistory = async (timeStamp: number, offset: number) => {
    return await socketInstance.sendMessage("GET_LIMITED_CHAT_HISTORY", {
      // roomname: this.roomname,
      // chatType: "EVERYONE",
      timeStamp,
      offset,
    });
  };

  // const typerData = useSelector((state: RootState) => state.chatData.typerData);

  // const typingTimer = useCallback(
  //   (typerData: {}) => {
  //     dispatch(setTyperName(typerData));
  //     return setTimeout(() => {
  //       dispatch(setTyperName({}));
  //     }, 3000);
  //   },
  //   [dispatch]
  // );

  useEffect(() => {
    if (scrollState <= 0 && !scrollToBottom && firstTimeScrolling) {
      setScrollToBottom(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatContainer.current?.scrollHeight]);

  // @ts-ignore


  useEffect(() => {
    const processMessage = async (message: any) => {
      switch (message.type) {
        case "TYPING":
          dispatch(setTyperName(message));
          break;
        case "STOP_TYPING":
          // dispatch(resetTypingState());
          dispatch(setTyperName({}))
          break;
        case "CHAT_MESSAGE":

          let mesg = message.message as Record<string, any>;
          // Show Notification in case of Mention
          if (mesg) {
            let shouldNotifyAudio = false;
            let shouldNotifyToast = false;
            let shouldNotifyDesktop = false;

            // Use regex to find matches and determine if notifications should be sent.
            mesg.message.replace(/\[(.+?)\]\((.+?)\)/g, (match: string, taggedUser: string, url: string) => {
              if (userName === taggedUser && mentionsPersonalization) {
                shouldNotifyAudio = true; // Flag to play audio notification
                shouldNotifyToast = true; // Flag to show toast notification
                shouldNotifyDesktop = true; // Flag to show desktop notification
              }
              return match; // Return match to continue with the replacement without affecting the text.
            });

            // Play audio notification if needed
            if (shouldNotifyAudio && mentionsPersonalization?.audio) {
              playMentionsNotification();
              shouldNotifyAudio = false; // Reset the flag
            }

            // Show toast notification if needed
            if (shouldNotifyToast && mentionsPersonalization?.toast) {
              const toastId = mesg.chatType === 'PRIVATE' ? "3" : "4";
              const toastMessage = `${mesg?.senderName} Mentioned you in ${mesg.chatType === 'PRIVATE' ? 'Private Chat' : 'EVERYONE'}`;
              toast(toastMessage, { toastId });
              shouldNotifyToast = false; // Reset the flag
            }

            // Show desktop notification if needed
            if (shouldNotifyDesktop && mentionsPersonalization?.desktop) {
              const notificationMessage = `${mesg?.senderName} Mentioned you in ${mesg.chatType === 'PRIVATE' ? 'Private Chat' : 'EVERYONE'}`;
              notifyMe(notificationMessage);
              shouldNotifyDesktop = false; // Reset the flag
            }
          }



          if (chatContainer.current) {
            let lastElementChild = chatContainer.current.lastElementChild;

            //@ts-ignore
            let lastOffsetHeight = lastElementChild?.offsetHeight;

            if (
              chatContainer.current.clientHeight <= 10 &&
              message.message.senderSessionId !== socketInstance.socket?.id
            ) {
              props.setAlertChat(true);

            }

            if (
              Math.floor(
                chatContainer.current.scrollHeight -
                chatContainer.current.scrollTop -
                chatContainer.current.offsetHeight
              ) <=
              lastOffsetHeight * 2
            ) {

              setScrollToBottom(true);
            } else if (
              (message.message.senderSessionId !== socketInstance.socket?.id) && (chatContainer.current.scrollHeight !== chatContainer.current.offsetHeight)
            ) {

              setShowMessageNotification(true);
              setCountMessage((countMessage) => countMessage + 1);

            }
          }

          let msg = message.message as Record<string, any>;

          if (msg && msg.senderUsername !== userName && chatPersonalization) {

            if (chatPersonalization?.audio) {
              playChatNotification();
            }

            if (chatPersonalization?.toast) {
              msg.chatType === 'PRIVATE' ? toast(`New Message From ${msg?.senderName}`, { toastId: "1" }) : toast(`New Message in EVERYONE from ${msg?.senderName}`, { toastId: "2" })
            }

            if (chatPersonalization?.desktop) {
              msg.chatType === 'PRIVATE' ? notifyMe(`New Message From ${msg?.senderName}`) : notifyMe(`New Message in EVERYONE from ${msg?.senderName}`)
            }
          }

          if (msg.chatType === "PRIVATE" && msg.senderSessionId !== socketInstance.socket?.id && selectedChatUser.chatType === "EVERYONE") {
            changePeerChatUser({
              peerSessionId: msg.senderSessionId,
              chatType: msg.chatType,
              peerName: msg.senderName,
              peerUserName: msg.senderUsername,
            });
          }

          if (msg && msg.chatType === 'PRIVATE' && msg.senderUsername !== userName) {
            updateMessageCount(msg.senderUsername)
          } else if (msg && msg.chatType === 'EVERYONE' && msg.senderUsername !== userName) {
            updateMessageCount('EVERYONE');
          }
          break;
        default:
          break;
      }
    };
    const messageSubscriber = socketInstance
      .getMessages()
      .subscribe((message: string) => {
        processMessage(JSON.parse(message));
      });

    return () => {
      if (messageSubscriber) {
        messageSubscriber.unsubscribe();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedChatUser,
    userName,
    dispatch,
    chatContainer.current,
    chatPersonalization,
    mentionsPersonalization
  ]);
  const [messageList, setMessageList] = useState<any[]>([]);

  useEffect(() => {
    let filteredMessages = [...allMessages];
    // var newMessages: any;
    if (selectedChatUser.peerSessionId) {
      filteredMessages = filteredMessages.filter((message: any) => (message.senderUsername === userName && message.receiverUsername === selectedChatUser.peerUserName) || (message.senderUsername === selectedChatUser.peerUserName && message.chatType === 'PRIVATE'))
    }
    setMessageList(filteredMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMessages, selectedChatUser, userName])



  useEffect(() => {
    if (chatContainer.current) {
      const isUserMessage = messageList.length > 0 && messageList[messageList.length - 1].senderUsername === userName;

      if (isUserMessage) {
        chatContainer.current.scrollTop = chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
      }
    }
  }, [messageList, userName]);



  useEffect(() => {
    if (scrollToBottom && chatContainer.current && scrollState <= 0) {
      chatContainer.current.scrollTop =
        chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
      setScrollToBottom(false);
    }

  }, [scrollState, scrollToBottom]);

  // useEffect(() => {
  //   if (scrollState > 0) {
  //     chatContainer.scrollTop = scrollState;
  //   }
  // }, [scrollState]);

  // useEffect(() => {
  //   if (messages.length <= 0) {
  //     // const peerValueString = JSON.stringify({
  //     //   peerSessionId: "",
  //     //   peerUserName: "",
  //     //   peerName: "",
  //     //   chatType: "EVERYONE",
  //     // });
  //     // const peerValue = JSON.parse(peerValueString);
  //     // getChatHistory(peerValue);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.refreshChat]);
  function checkLuhn(cardNo: string) {
    //used Luhn10 algo for validating and filtering credit cards.
    let nDigits = cardNo.length;

    let nSum = 0;
    let isSecond = false;
    for (let i = nDigits - 1; i >= 0; i--) {
      //@ts-ignore
      let d = cardNo[i].charCodeAt() - "0".charCodeAt();

      if (isSecond === true) d = d * 2;

      // We add two digits to handle
      // cases that make two digits
      // after doubling
      //@ts-ignore
      nSum += parseInt(d / 10, 10);
      nSum += d % 10;

      isSecond = !isSecond;
    }
    return nSum % 10 === 0;
  }
  const sendMessage = (input: string) => {
    if (input) {
      // eslint-disable-next-line no-control-regex
      let amex = new RegExp("\b?([0-9]{4})[ -]?([0-9]{6})[ -]?([0-9]{5})[ -]?\b?"); //credit card with spaces 4-6-5.
      // eslint-disable-next-line no-control-regex
      let visa = new RegExp("\b?(([0-9]{4}[ -]?([0-9]{4})[ -]?([0-9]{4})[ -]?([0-9]{4})[ -]?))\b?"); //credit cards with spaces 4.
      // eslint-disable-next-line no-control-regex
      let cup2 = new RegExp("\b?(([0-9]{6})[ -]([0-9]{13})[ -]?)\b?"); //credit card with spaces 6-13.
      // eslint-disable-next-line no-control-regex
      let diners1 = new RegExp("\b?(([0-9]{5}[ -]?([0-9]{4})[ -]?([0-9]{5})[ -]?))\b?"); //credit card with spaces 5-4-5.
      // let visa = new RegExp("\b?(([0-9]{4}[ -a-zA-Z]?([0-9]{4})[ -a-zA-Z]?([0-9]{4})[ -a-zA-Z]?([0-9]{4})[ -a-zA-Z]?))\b?");
      let gettingCreditCard: any;
      if (visa.test(input)) {
        gettingCreditCard = input.match(visa) || [];
      } else if (amex.test(input)) {
        gettingCreditCard = input.match(amex) || [];
      } else if (diners1.test(input)) {
        gettingCreditCard = input.match(diners1) || [];
      } else if (cup2.test(input)) {
        gettingCreditCard = input.match(cup2) || [];
      }
      //@ts-ignore
      if (
        gettingCreditCard &&
        gettingCreditCard.length > 0 &&
        checkLuhn(gettingCreditCard[0].replaceAll(" ", "").replaceAll("-", ""))
      ) {
        toast("Can't share personal information: Can't send a message!");
        return;
      }

      if (selectedChatUser.peerSessionId) {
        // private chat
        socketInstance.sendMessage("SEND_CHAT_MESSAGE", {
          message: input,
          chatType: "PRIVATE",
          senderUsername: userName,
          receiverUsername: selectedChatUser.peerUserName,
          receiverSessionId: selectedChatUser.peerSessionId,
          receiverName: selectedChatUser.peerName,
          senderName: `${firstName} ${lastName}`,
        });
      } else {
        socketInstance.sendMessage("SEND_CHAT_MESSAGE", {
          message: input,
          chatType: "EVERYONE",
          senderUsername: userName,
          receiverUsername: null,
          receiverSessionId: null,
          receiverName: null,
          senderName: `${firstName} ${lastName}`,
        });
      }
    }
  };


  const handleScrollState = async (e: any) => {
    if (chatReconnectStatus) {
      return;
    }
    const target = e.target;

    if (firstTimeScrolling) {
      setFirstTimeScrolling(false);
    }

    if (target.scrollTop === 0) {
      // setLoading(true);

      let curScrollPos = target.scrollTop;
      let oldScroll = target.scrollHeight - target.clientHeight;
      // console.log("Messages", messages);
      const messagesResponse: any = await getLimitedChatHistory(
        // @ts-ignore
        allMessages[0]?.timeStamp - 1,
        0
      );
      // console.log("DEBUG",{firstTimeScrolling ,target ,oldScroll,messageList})
      const messageArray = messagesResponse.chatMessageArray;
      // @ts-ignore
      let newMessageList = [];
      if (messageArray.length) {
        newMessageList = messageArray.map(
          // @ts-ignore
          (messageObj: string) => {
            const parseMessageObj = JSON.parse(messageObj);
            return parseMessageObj;
          }
        );
      }
      dispatch(prependMessageList(newMessageList));

      let newScroll = target.scrollHeight - target.clientHeight;
      target.scrollTop = curScrollPos + (newScroll - oldScroll);

      // setLoading(false);
    }

    if (target.scrollTop <= 2) return;

    if (
      target.scrollHeight -
      Math.floor(target.scrollTop + target.clientHeight) <=
      5 &&
      showMessageNotification
    ) {
      setShowMessageNotification(false);
      setCountMessage(0);
      props.setAlertChat(false);
    }

    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setScrollState(
        Math.floor(
          target?.scrollHeight - target?.scrollTop - target?.clientHeight
        ) > target?.lastElementChild?.offsetHeight
          ? target?.scrollTop
          : 0
      );
    }, 200);
  };

  function markdownToHtml(text: string): string {
    // Function to escape HTML
    const escapeHtml = (unsafeText: string) => {
      return unsafeText
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    };

    // Convert bold and italic text but escape the inner text
    text = text.replace(/\*\*(.+?)\*\*/g, (match, p1) => `<strong>${escapeHtml(p1)}</strong>`);
    text = text.replace(/\*(.+?)\*/g, (match, p1) => `<em>${escapeHtml(p1)}</em>`);

    // Convert links and escape the label
    text = text.replace(/\[(.+?)\]\((.+?)\)/g, (match, label, url) => {
      label = escapeHtml(label); // Escape label
      // Ensure URL is valid and doesn't start with 'javascript:'
      try {
        const sanitizedUrl = new URL(url).toString();
        if (/^javascript:/i.test(sanitizedUrl)) {
          return label; // Return the label without making it a link
        }
        return `<a href="#" rel="noopener noreferrer">${label}</a>`;
      } catch (e) {
        return `<a href="#" rel="noopener noreferrer">${label}</a>`;
      }
    });

    return text;
  }

  return (
    <div className="chat-container">
      <PeerList
        peers={peers}
        selectedChatUser={selectedChatUser}
        changeSelectedChatUser={changePeerChatUser}
        getChatHistory={getChatHistory}


        senderMessageCounts={senderMessageCounts}
        setSenderMessageCounts={setSenderMessageCounts}
        updateMessageCount={updateMessageCount}
        setHasMessagesBeenSent={props.setHasMessagesBeenSent}
      />
      {loading && <div>loading...</div>}
      {!chatReconnectStatus ?
        <ul
        style={{position:"relative"}}
        className="chat-message-container"
        onScroll={(e: any) => {
          handleScrollState(e);
        }}
        ref={chatContainer}
      >
        {messageList.length > 0 && messageList.map((message: any, keyValue: number) => {
          const unixTime: any = message?.timeStamp;
          const timeStamp = new Date(unixTime);
          if (message.senderUsername === userName) {
            return (
              <li key={message.messageId + keyValue} className="my-message">
                {message.joinedRoom ? (
                  <div className="room_joined_msg_main">
                    <div className="room_joined">
                      {/* <div className="name">{message.senderName as string}</div> */}
                      <div className="time-stamp">
                        {moment(timeStamp).format(format)}
                      </div>
                    </div>

                    <div className="room_joined_others_message_string">
                      {(message.message as string).replace(
                        userName + " has",
                        "You have"
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="chat-message-header-own">
                      <div className="chatType">
                        {message.chatType === "PRIVATE" && !selectedChatUser.peerSessionId ? (
                          <>
                            {" "}
                            ({message.chatType as string} message to{" "}
                            {message.receiverUsername})
                          </>
                        ) : null}
                      </div>
                      <div className="name">You</div>
                    </div>
                    <div className="mymessage-string">
                      <span dangerouslySetInnerHTML={{ __html: markdownToHtml(message.message as string) }}></span>
                      {/* {message.message as string} */}
                      <div className="time-stamp">
                        {moment(timeStamp).format(format)}
                      </div>
                    </div>
                  </div>
                )}
              </li>
            );
          } else {
            return (
              <li
                key={message.messageId + keyValue}
                className="others-message"
              >
                {message.joinedRoom ? (
                  <div className="room_joined_msg_main">
                    <div className="room_joined">
                      {/* <div className="name">{message.senderName as string}</div> */}
                      <div className="time-stamp">
                        {moment(timeStamp).format(format)}
                      </div>
                    </div>

                    <div className="room_joined_others_message_string">
                      {/* {message.message as string} */}
                      <span dangerouslySetInnerHTML={{ __html: markdownToHtml(message.message as string) }}></span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div style={{ display: "flex", marginRight: 10 }}>
                      <NameAvatar
                        text={message.senderName as string}
                        className="pravatar bg-brand color-white"
                      />
                    </div>
                    <div>
                      <div className="chat-message-header">
                        <div className="name">
                          {message.senderName as string}
                        </div>
                        <div className="chatType">
                          {message.chatType === "PRIVATE" && !selectedChatUser.peerSessionId ? (
                            <>
                              {" "}
                              ({message.chatType as string} message from{" "}
                              {message.senderUsername})
                            </>
                          ) : null}
                        </div>
                      </div>

                      <div className="others-message-string">
                        <span dangerouslySetInnerHTML={{ __html: markdownToHtml(message.message as string) }}></span>
                        {/* {message.message as string} */}
                        <div className="time-stamp">
                          {moment(timeStamp).format(format)}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </li>
            );
          }
        })}

        {/* {Object.entries(typerData).length > 0 &&
            (typerData.TypingType === "PRIVATE" ? (
              typerData.receiverUsername === userName && (
                <Typer peerName={typerData.peerName} />
              )
            ) : (
              <Typer peerName={typerData.peerName} />
             ))}  */}
        {/* {scrollState > 0 ? null : <AlwaysScrollToBottom />} */}
      </ul> : null}

      {(showMessageNotification || (chatData.typerData.receiverUsername === userName && chatData.selectedChatUser.peerUserName === chatData.typerData.peerName && chatData.typerData.TypingType === "PRIVATE")) && <div style={{ display: "flex", position: "absolute", bottom: "3rem", justifyContent: "center", alignItems: "center", width: "100%",height:"3rem", backgroundColor:"#f9f9fb" }}>
        <div style={{ width: "40%" }}>
          {(chatData.typerData.receiverUsername === userName && chatData.selectedChatUser.peerUserName === chatData.typerData.peerName && chatData.typerData.TypingType === "PRIVATE") &&
            (<div className={`msgtyping`}>
              <span className={`circle scaling`}></span>
              <span className={`circle scaling`}></span>
              <span className={`circle scaling`}></span>
            </div>)}
        </div>
        <div style={{ width: "60%" }}>
          {showMessageNotification ? (
            <div
              className="typer_styles"
              style={{ cursor: "pointer" }}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                if (chatContainer.current)
                  chatContainer.current.scrollTop =
                    chatContainer.current.scrollHeight -
                    chatContainer.current.clientHeight;
              }}
            >
              <div className="chat-bubble">
                <div className="typing">
                  <FontAwesomeIcon icon={faComment} color="black" />
                  {countMessage > 0 ? " " + countMessage : ""}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>}
      <Chatinput onlineUsers={peers} sendMessage={sendMessage} />
    </div>
  );
};

export default Chat;
