import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const sendResetPwdLink = async (email: string) => {
  const reqBody = qs.stringify({
    email,
  });
  const passwordResetResponse = await request.post(
    "/forgotpwd",
    reqBody,
    config
  );
  return passwordResetResponse.data;
};

export const checkTokenValid = async (email: string, token: string) => {
  const reqBody = qs.stringify({
    email,
    token,
  });
  const checkTokenValidResponse = await request.post(
    "forgotpwdtoken_check",
    reqBody,
    config
  );
  return checkTokenValidResponse.data;
};

export const changePassword = async (token: any, password: string) => {
  const reqBody = qs.stringify({
    token,
    password,
  });
  const changePasswordResponse = await request.post(
    "forgotpwdreset",
    reqBody,
    config
  );
  return changePasswordResponse.data;
};
