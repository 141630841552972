import { useLocation, useNavigate, useParams } from "react-router-dom";

export const WithRouter = (Component: any) => {
  function CallBack(props: any) {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    function componentRedirect() {
      window.location.href = "/dashboard";
    }

    return (
      <Component
        {...props}
        location={location}
        params={params}
        navigate={navigate}
        componentRedirect={componentRedirect}
      />
    );
  }
  return CallBack;
};