import request from "./index";
import qs from "querystring";

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const login = async (
  email?: string,
  password?: string,
  ipaddress?: string,
  geoLocationDetails?: any,
  ipdata?:any,
 // geoLocationDetails?: Record<string, Record<string, string>>
) => {
  let requestBody = qs.stringify({
    username: email,
    password,
    ipaddress,
    ipdata
  });
  if (geoLocationDetails) {
    requestBody = qs.stringify({
      username: email,
      password,
      latitude: geoLocationDetails.latitude,
      longitude: geoLocationDetails.longitude,
      fromApi: geoLocationDetails.fromApi ? geoLocationDetails.fromApi : false,
      ipaddress,
      ipdata : JSON.stringify(ipdata)
    });
  }

  const response = await request.post("/login", requestBody, config);
  if (response.data.success) {
    localStorage.setItem("webrtcipfetched", "false");
  }
  return response.data;
};

export const verifyOTP = async (
  email?: string,
  OTP?: string
) => {
  let requestBody = qs.stringify({
    username: email,
    OTP,
    password: 'mfalogin',
  });

  const response = await request.post("/verifyotp", requestBody, config);
  return response.data;
};

export const logout = async () => {
  const response = await request.get("/logout");
  return response.data;
};

export const loginActiveTime = async () => {
  const response = await request.get("/loginActiveTime");
  return response.data;
};

export const logoutActiveTime = async () => {
  try {
  const response = await request.get("/logoutActiveTime");
  return response.data;
  } catch (err:any) {
    console.error(err.message);
  }
};

interface isSessionActiveResp {
  message: string;
  success: boolean;
}

export const isSessionActive = async (): Promise<isSessionActiveResp> => {
  const response = await request.get("/isSessionActive");
  return response.data;
};