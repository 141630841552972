import {Dispatch} from "react";
import {getLogo} from "../services/appLogo";
import {
    CLOSE_MODAL,
  GET_LOGO_SUCCESS,
  GET_LOGO_FAILURE,
    } from "./actionTypes";

export const getLogoAction = () => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const addLogoResponse = await getLogo();
            console.log(addLogoResponse);
            if (addLogoResponse) {
                dispatch(addLogoSuccess(addLogoResponse));
            } else {
                dispatch(
                    addLogoFailure("Could not get logo. Please try again")
                );
            }
            dispatch({ type: CLOSE_MODAL });
            return addLogoResponse;
        } catch (error: any) {
            addLogoFailure(
                error ? error?.message : "Could not get logo. Please try again"
            );
        }
    };
};

const addLogoSuccess = (addLogoResponse:any) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: GET_LOGO_SUCCESS,
            payload: addLogoResponse
        });
    };
};

const addLogoFailure = (errorMessage: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: GET_LOGO_FAILURE,
            error: errorMessage,
        });
    };
};
