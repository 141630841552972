import request from "./index";
import { getToken } from "../utils/Token";
import axios from "axios";
import { ABSTRACT_API_URL } from "../utils/constant";

const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: '',
    },
};

export const processIPfromWebrtc = async (ipaddress: string) => {

    let response = await axios(
        ABSTRACT_API_URL + '&ip_address=' + ipaddress,
        {
            timeout: 2000,
            method: "GET",
        }
    );

    config["headers"]["Authorization"] = getToken();
    const body = {
        abstractAPIresponse: response.data,
    };

    // const result = await
    request.post("/savelocationwebrtc", body, config);
    localStorage.setItem("webrtcipfetched", "true");
    return;
};

