export const setPeerVolume = (peerId: string, volume: number) =>
	({
		type    : 'SET_PEER_VOLUME',
		payload : { peerId, volume }
	});

export const removePeer = (peerId: string) =>
	({
		type    : 'REMOVE_PEER',
		payload : { peerId }
	});

