import { SET_VERIFY_FACE, SET_RECURRENT_CHECK,SET_WRONG_PERSON } from "../actions/actionTypes";


interface IAction {
  type: string;
  payload: any;
}

const initialState = {
  faceVerification: true,
  recurrentCheck: false,
  wrongPerson:false,
};

const verifyFaceReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_VERIFY_FACE: {
      return {
        ...state,
        faceVerification: action.payload,
      };
    }

    case SET_RECURRENT_CHECK: {
      return {
        ...state,
        recurrentCheck: action.payload,
      };
    }

    case SET_WRONG_PERSON:{
      return{
        ...state,
        wrongPerson: action.payload
      }
    }

    default: {
      return state;
    }
  }
};

export default verifyFaceReducer;
