import request from "./index";
import qs from "querystring";

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getRooms = async () => {
  const userDetailsResponse = await request.get("/getRooms");
  return userDetailsResponse.data;
};

export const getAllUsersCountByRoom = async () => {
  const userDetailsResponse = await request.get("/getAllUsersCountByRoom");
  return userDetailsResponse.data;
};

export const createRoom = async (roomName: string, maxParticipants: number) => {
  const reqBody = qs.stringify({
    roomname: roomName,
    maxParticipantCount: maxParticipants,
  });
  return await request.post("/createRoom", reqBody, config);
};

export const getConnectionDetails = async (roomname: any) => {
  const reqBody = qs.stringify({
    roomname: roomname,
  });
  const connectionDetailsData = await request.post(
    "/getConnectionDetails",
    reqBody,
    config
  );
  return connectionDetailsData?.data?.connectionDetails;
};
