import {
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  CLEAR_REDUX_DATA,
  SET_SOCKETID,
  SET_CAMERA_BACKGROUND,
  GET_CAMERA_BACKGROUND,
  SET_USERDETAILS_REGISTERED
} from "../actions/actionTypes";

const initialState = {
  userName: "",
  firstName: "",
  lastName: "",
  role: "",
  errorMessage: "",
  socketId: "",
  bussunit: "",
  passwordUpdatedOn: "",
  isComplexPassword: true,
  enableDesktopApp: false,
  faceVerification: false,
  isUserVerified:false,
  isUserRegistered: false,
  cameraBackground: { backgroundType: "normal", url: "" },
};
interface IAction {
  type: string;
  payload: any;
}

const userDetailsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userName: action.payload?.username,
        firstName: action.payload?.firstName,
        lastName: action.payload?.lastName,
        role: action.payload?.role,
        bussunit: action.payload?.bussunit,
        passwordUpdatedOn: action.payload?.passwordUpdatedOn,
        isComplexPassword: action.payload?.complexPassword,
        enableDesktopApp: action.payload?.enableDesktopApp,
        faceVerification: action.payload?.faceVerification,
        isUserVerified:action.payload?.isVerified,
        isUserRegistered:action.payload?.isRegistered
      };
    }

    case SET_USERDETAILS_REGISTERED: {
      return {
        ...state,
        isUserRegistered:action.payload
      };
    }
    case GET_USER_DETAILS_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload?.errorMessage,
      };
    }
    case SET_SOCKETID: {
      let socketId = action.payload.socketId;
      return {
        ...state,
        socketId: socketId,
      };
    }
    case SET_CAMERA_BACKGROUND: {
      return {
        ...state,
        cameraBackground: { ...action.payload },
      };
    }
    case GET_CAMERA_BACKGROUND: {
      return {
        ...state,
        cameraBackground: { ...action.payload },
      };
    }
    case CLEAR_REDUX_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default userDetailsReducer;
