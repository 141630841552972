import {
  ADD_COLLAB_SCREEN_PEER,
  REMOVE_COLLAB_SCREEN_PEER,
  EMPTY_COLLAB_DATA,
  CLEAR_REDUX_DATA,
  ADD_COLLAB_SCREEN_PEER_FROM_ARRAY,
  ADD_COLLAB_DATA_ON_SOCKET_CONNECTED,
  REMOVE_COLLAB_SCREEN_PEER_FROM_ARRAY,
} from "../actions/actionTypes";

interface screenPeerType {
  peerId: string;
  peerUsername: string;
}

interface collabType {
  screenCollabPeer: screenPeerType;
  screenCollabPeerArray: Array<screenPeerType>;
}

const initialState: collabType = {
  screenCollabPeer: { peerId: "", peerUsername: "" },
  screenCollabPeerArray: [],
};

interface IAction {
  type: string;
  payload: any;
}

const collabReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case ADD_COLLAB_SCREEN_PEER: {
      return {
        ...state,
        screenCollabPeer:
          state.screenCollabPeer.peerId !== ""
            ? { ...state.screenCollabPeer }
            : { ...action.payload },
        screenCollabPeerArray: [
          ...state.screenCollabPeerArray,
          { ...action.payload },
        ],
      };
    }

    case ADD_COLLAB_SCREEN_PEER_FROM_ARRAY: {
      return {
        ...state,
        screenCollabPeer: { ...action.payload },
      };
    }

    case ADD_COLLAB_DATA_ON_SOCKET_CONNECTED: {
      return {
        screenCollabPeer:
          action.payload.length > 0
            ? {
                peerId: action.payload[0].peerId,
                peerUsername: action.payload[0].peerUsername,
              }
            : { peerId: "", peerUsername: "" },
        screenCollabPeerArray: [...action.payload],
      };
    }

    case REMOVE_COLLAB_SCREEN_PEER: {
      return {
        screenCollabPeer: { peerId: "", peerUsername: "" },
        screenCollabPeerArray: state.screenCollabPeerArray.filter(
          (value) => value.peerId !== action.payload.peerId
        ),
      };
    }
    case REMOVE_COLLAB_SCREEN_PEER_FROM_ARRAY: {
      return {
        screenCollabPeer: { ...state.screenCollabPeer },
        screenCollabPeerArray: state.screenCollabPeerArray.filter(
          (value) => value.peerId !== action.payload.peerId
        ),
      };
    }

    case CLEAR_REDUX_DATA:
    case EMPTY_COLLAB_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default collabReducer;
